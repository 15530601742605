import { createAsyncThunk } from '@reduxjs/toolkit'

/**
 * The fetchFeaturedCreators async thunk is called with the publisherId
 * and returns a list of featured creators to the publisher
 * [{
 *      username: 'dad',
 *      name: 'Dad',
 *      avatar_url: '',
 * }]
 * @name fetchActionEventsThunk
 * @memberof FeaturedCreatorsFeature.FeaturedCreatorsSlice.FeaturedCreatorsThunks
 */
const fetchFeaturedCreators = createAsyncThunk(
    'featuredCreators/fetchFeaturedCreators',
    async () => {
        const response = {
            data: [{
                username: 'maggieantalek',
                name: 'Maggie Antalek',
                avatar_url: 'https://images.pico.tools/production/logo_1648064021051_165.jpeg',
            }, {
                username: 'hookedbybrianna',
                name: 'hookedbybrianna',
                avatar_url: 'https://images.pico.tools/production/logo_1651845662556_680.jpeg',
            }, {
                username: 'margiemays',
                name: 'Margie Mays',
                avatar_url: 'https://images.pico.tools/production/logo_1636588321191_84.jpeg',
            }, {
                username: 'kamo988',
                name: 'kamo988',
                avatar_url: 'https://images.pico.tools/production/logo_1641967895460_811.png',
            }, {
                username: 'croak',
                name: 'Croak',
                avatar_url: 'https://images.pico.tools/production/logo_1642191243937_334.jpeg',
            }, {
                username: 'alicewaters',
                name: 'Alice Waters',
                avatar_url: 'https://images.pico.tools/production/logo_1636381371423_798.jpeg',
            },]
        }
        return response.data
    }
)

export default fetchFeaturedCreators
