import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
} from '../dependencies'

const fetchTagsByCreator = createAsyncSaga(
    `${name}/fetchTagsByCreator`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_PUBLISHER_TAGS(),
                headers: {
                    publisherid: publisherId,
                },
            })

            const tags = response?.tags || []

            const formattedTags = tags.map((tag: any) => ({
                id: tag?.id,
                editionId: tag?.nfc_edition_id,
                packId: tag?.nfc_pack_id,
                formId: tag?.form_id,
                publisherId: tag?.publisher_id,
                stripeInvoiceId: tag?.stripe_invoice_id,
                tagName: tag?.tag_name,
                gift: tag?.gift,
                customUrl: tag?.custom_url,
                engraving: tag?.engraving,
                shippedDate: tag?.shipped_at,
                deactivatedAt: tag?.deactivated_at,
                packagedAt: tag?.packaged_at,
                orderDate: tag?.created_at,
                updatedAt: tag?.updated_at,
                edition: {
                    id: tag?.edition?.id,
                    shopifySku: tag?.edition?.shopify_sku,
                    name: tag?.edition?.name,
                    type: tag?.edition?.type,
                    color: tag?.edition?.color,
                    engravable: tag?.edition?.engravable,
                    trial: tag?.edition?.trial,
                    sold: tag?.edition?.sold,
                    salesChannel: tag?.edition?.sales_channel,
                },
            }))

            yield fulfilled({ tags: formattedTags })
        } catch (error) {
            logger.error('fetchTagsByCreator error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default fetchTagsByCreator
