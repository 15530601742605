const actions = {}

actions.types = [
    'SET_TIME_DIMENSION',
    'GET_METRICS',
    'GET_METRICS_SUCCESS',
    'GET_METRICS_ERROR',

    'GET_RECURRING_PAYMENTS',
    'GET_RECURRING_PAYMENTS_SUCCESS',
    'GET_RECURRING_PAYMENTS_ERROR',
]

actions.creators = ({ types }) => ({
    getMetrics: (payload) => ({ type: types.GET_METRICS, payload }),
    getRecurringPayments: () => ({ type: types.GET_RECURRING_PAYMENTS }),
    setTimeDimension: (payload) => ({ type: types.SET_TIME_DIMENSION, payload }),
})

export default actions
