import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'

import endpoints from 'utils/lib/endpoints'

function* getAccountInfo({ selectors, types }) {
    try {
        const publisherid = yield select(selectors.getPublisherId)

        if (publisherid) {
            const { data } = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'get',
                    url: endpoints.MY_ORGANIZATION,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid
                    },
                    timeout: 30000
                }
            })

            yield put({
                type: types.GET_ACCOUNT_INFO_SUCCESS,
                payload: data
            })
        }
    } catch (error) {
        yield put({
            type: types.GET_ACCOUNT_INFO_ERROR,
            error
        })
    }
}

export default getAccountInfo
