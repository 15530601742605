import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import ModalModule from 'modules/modal'
import AppModule from 'modules/app'

function* createAccessToken({ types, payload }) {
    try {
        const { values } = payload
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.KEYS,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: values
            }
        })
        yield put({
            type: types.CREATE_ACCESS_TOKEN_SUCCESS,
            payload: data
        })


        yield put(
            ModalModule.creators.closeModal({
                key: 'generate_access_token',
                actionFinished: true
            })
        )
    } catch (e) {
        const message = e.response && e.response.data && e.response.data.message || e.message
        yield put(
            AppModule.creators.addNotification({
                message,
                type: 'danger'
            })
        )
        yield put({
            type: types.CREATE_ACCESS_TOKEN_ERROR,
            error: e
        })
    }
}

export default createAccessToken
