import { put, putResolve } from 'redux-saga/effects'
import Router from 'next/router'

import { REFRESH_TOKEN } from '@piconetworks/pkg-network-middleware'

function* useRefreshToken({ types, creators }) {
    try {
        yield putResolve({
            type: REFRESH_TOKEN
        })

        yield put({
            type: types.USE_REFRESH_TOKEN_SUCCESS
        })

        yield put(creators.postLogin())

    } catch (error) {
        yield put({
            type: types.USE_REFRESH_TOKEN_ERROR,
            error
        })

        Router.push('/logout')
    }
}

export default useRefreshToken
