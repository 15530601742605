import Router from 'next/router'
import { call, put } from 'redux-saga/effects'

import { openGlobalPaywall } from './index'

function* checkToOpenPaywall() {
    const showPlans = Router?.router?.query?.plans === 'true'

    if (!showPlans) {
        return
    }

    yield put(yield call(openGlobalPaywall))
}

export default checkToOpenPaywall
