import { putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import { isEmpty } from 'lodash'
import { getColumnsForCsv, getFiltered, getSorted } from './utils'
import AccountModule from 'modules/account'


function* exportCSV(action) {
    const { duck, payload } = action
    const { filtered, sorted, columns } = payload
    const params = {}
    const publisherid = yield select(AccountModule.selectors.getPublisherId)

    if (!isEmpty(sorted)) {
        params.sort = getSorted(sorted)
    }
    if (!isEmpty(filtered.filters)) {
        params.filtered = getFiltered(filtered)
    }
    if (!isEmpty(columns)) {
        params.columns = getColumnsForCsv(columns)
    }
    try {

        const {data} = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.USERS + '/export',
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {...params, type: 'csv'}
            }
        })

        yield put({
            type: duck.types.EXPORT_CSV_SUCCESS,
            payload: data
        })


    } catch (error) {
        yield put({
            type: duck.types.LOG_OUT_USER_ERROR,
            error
        })
    }
}

export default exportCSV
