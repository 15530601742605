import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

import logger from 'lib/logger'

function* getConversation({
    types,
    payload: {
        conversationId
    } = {}
}) {

    if (!conversationId) {
        throw new Error('Must pass conversation_id in payload!')
    }

    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'GET',
                url: `${endpoints.CONVERSATIONS}/thread/${conversationId}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
            },
        })

        if (data) {
            yield put({
                type: types.GET_CONVERSATION_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('Failed to get conversation')
        }
    } catch (e) {
        logger.error('getConversation error', {}, e)
        yield put({
            type: types.GET_CONVERSATION_ERROR,
            error: e
        })
    }
}

export default getConversation
