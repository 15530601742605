import {
    initialState,
    reducer,
    takes,
} from './slice'
import GoogleDriveSyncButton from './components/GoogleDriveSyncButton'


export default {
    initialState,
    reducer,
    takes,
}

export {
    GoogleDriveSyncButton,
}
