import destroyIntegration from './destroyIntegration'
import addIntegration from './addIntegration'
import getWebhooks from './getWebhooks'
import saveWebhooks from './saveWebhooks'
import deleteWebhook from './deleteWebhook'
import simulateWebhook from './simulateWebhook'
import getIntegrations from './getIntegrations'
import getZapierKey from './getZapierKey'
import generateZapierKey from './generateZapierKey'
import getEspFields from './getEspFields'
import saveEspFields from './saveEspFields'
import getESPLists from './getEspLists'
import installESP from './installEsp'
import openUninstallModal from './openUninstallModal'
import startStripeExpress from './startStripeExpress'
import loginStripeExpress from './loginStripeExpress'

const sagas = (duck) => ({
    * destroyIntegration(payload) {
        yield destroyIntegration({ ...payload, ...duck })
    },
    * getIntegrations() {
        yield getIntegrations({ ...duck })
    },
    * addIntegration(payload) {
        yield addIntegration({ ...payload, ...duck })
    },
    * getWebhooks() {
        yield getWebhooks({ ...duck })
    },
    * saveWebhooks(payload) {
        yield saveWebhooks({ ...payload, ...duck })
    },
    * deleteWebhook(payload) {
        yield deleteWebhook({ ...payload, ...duck })
    },
    * simulateWebhook(payload) {
        yield simulateWebhook({ ...payload, ...duck })
    },
    * getZapierKey(payload) {
        yield getZapierKey({ ...payload, ...duck })
    },
    * generateZapierKey(payload) {
        yield generateZapierKey({ ...payload, ...duck })
    },
    * getEspFields(payload) {
        yield getEspFields({ ...payload, ...duck })
    },
    * saveEspFields(payload) {
        yield saveEspFields({ ...payload, ...duck })
    },
    * getESPLists() {
        yield getESPLists({ ...duck })
    },
    * installESP(payload) {
        yield installESP({ ...duck, ...payload })
    },
    * openUninstallModal(payload) {
        yield openUninstallModal({ ...duck, ...payload })
    },
    * startStripeExpress() {
        yield startStripeExpress({ ...duck })
    },
    * loginStripeExpress() {
        yield loginStripeExpress({ ...duck })
    },
})

export default sagas
