const actions = {}

actions.types = [
    'GET_DASHBOARD_PRICING',
    'GET_DASHBOARD_PRICING_SUCCESS',
    'GET_DASHBOARD_PRICING_ERROR',
    'SAVE_DASHBOARD_PRICING',
    'SAVE_DASHBOARD_PRICING_SUCCESS',
    'SAVE_DASHBOARD_PRICING_ERROR',
    'GET_APPLICATION_FEES',
    'GET_APPLICATION_FEES_SUCCESS',
    'GET_APPLICATION_FEES_ERROR',
    'SAVE_APPLICATION_FEES',
    'SAVE_APPLICATION_FEES_SUCCESS',
    'SAVE_APPLICATION_FEES_ERROR',
    'ADD_CREATOR',
    'ADD_CREATOR_SUCCESS',
    'ADD_CREATOR_ERROR',
    'SET_TO_UNCLAIMED',
    'SET_TO_UNCLAIMED_SUCCESS',
    'SET_TO_UNCLAIMED_ERROR',
    'TRIGGER_SCRAPING',
    'TRIGGER_SCRAPING_SUCCESS',
    'TRIGGER_SCRAPING_ERROR',
]

actions.creators = ({ types }) => ({
    getDashboardPricing: () => ({ type: types.GET_DASHBOARD_PRICING }),
    saveDashboardPricing: (payload) => ({ type: types.SAVE_DASHBOARD_PRICING, payload }),
    getApplicationFees: () => ({ type: types.GET_APPLICATION_FEES }),
    saveApplicationFees: (payload) => ({ type: types.SAVE_APPLICATION_FEES, payload }),
    addCreator: (payload, callback) => ({ type: types.ADD_CREATOR, payload, callback }),
    setToUnclaimed: (payload, callback) => ({ type: types.SET_TO_UNCLAIMED, payload, callback }),
    triggerScraping: (payload, callback) => ({ type: types.TRIGGER_SCRAPING, payload, callback }),
})

export default actions
