import { useCallback } from 'react'
import { useCards, useEditions } from './dependencies'
import { useDispatch } from 'react-redux'
import { fetchAndSetActiveCardAndEdition, printStickers, printCustomStickers } from './slice'
import useDeliveryFailedPopup from './useDeliveryFailedPopup'

const useNfcEditor = (fetch = false) => {
    const dispatch = useDispatch()
    const cards = useCards(fetch)
    const editions = useEditions(fetch)

    const _fetchAndSetActiveCardAndEdition = useCallback((payload) => {
        dispatch(fetchAndSetActiveCardAndEdition(payload))
    }, [dispatch])

    const _printStickerOrder = useCallback((tagId) => {
        const tag = cards.selectById(tagId)
        const username = tag?.username
        const editionId = tag?.editionId
        const edition = editions.getEditionById(editionId)
        const quantity = edition?.packSize || 30

        dispatch(printStickers({
            tagId,
            username,
            quantity,
        }))
    }, [dispatch, cards.selectById, editions.getEditionById])

    const _printEditionStickers = useCallback((editionId) => {
        const editionTags = cards.activeEditionCards.map((tag) => {
            return ({
                id: tag?.id,
                short_code: tag?.username,
            })
        })

        dispatch(printStickers({
            tags: editionTags
        }))
    }, [dispatch, cards.activeEditionCards])


    const _printCustomSticker = useCallback(({ text, count, qrCodeOverrides = {} }) => {
        dispatch(printCustomStickers({
            text,
            count,
            qrCodeOverrides,
        }))
    }, [dispatch])

    return {
        ...cards,
        ...editions,
        fetchAndSetActiveCardAndEdition: _fetchAndSetActiveCardAndEdition,
        useDeliveryFailedPopup,
        printStickerOrder: _printStickerOrder,
        printEditionStickers: _printEditionStickers,
        printCustomSticker: _printCustomSticker,
    }
}

export default useNfcEditor
