import { all, call, put, take } from 'redux-saga/effects'

import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
    fetchEditions,
    fetchCards,
    fetchCardById,
    setActiveEditionId,
    setActiveCardId,
} from '../dependencies'

import downloadStickers from './downloadStickers'

const printStickers = createAsyncSaga(
    `${name}/printStickers`,
    function* ({
        payload: {
            publisherId = null,
            tagId = null,
            username = null,
            quantity = null,
            tags: _tags = [],
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!_tags.length) {
                const tags: { id: string, short_code: string }[] = []

                for (let i = 0; i < quantity; i++) {
                    tags.push({
                        id: tagId,
                        short_code: username,
                    })
                }    

                yield call(downloadStickers, tags)
            } else {
                yield call(downloadStickers, _tags)
            }

            yield fulfilled()
        } catch (error) {
            logger.error('printStickers error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default printStickers
