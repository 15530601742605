const actions = {}

actions.types = [
    'OPEN_MODAL',
    'OPEN_MODAL_SUCCESS',
    'OPEN_MODAL_ERROR',
    'CLOSE_MODAL'
]

actions.creators = ({ types }) => ({
    openModal: (payload) => ({ type: types.OPEN_MODAL, payload }),
    closeModal: (payload) => ({ type: types.CLOSE_MODAL, payload })
})

export default actions
