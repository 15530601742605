import { put, putResolve, call, select } from 'redux-saga/effects'
import { FORM_ERROR } from 'final-form'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'
import endpoints from 'utils/lib/endpoints'

function* saveWebsiteOptions({ types, payload, creators, callback }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: endpoints.DOMAINS,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    domains: [...payload]
                }
            }
        })
        yield put({
            type: types.SAVING_WEBSITE_SUCCESS,
            payload: {
                ...data
            }
        })

        yield put(creators.domainsHealthCheck())
        yield put(
            AppModule.creators.addNotification({
                message: 'Domains successfully updated',
                type: 'success'
            })
        )

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            const submissionError = {
                [FORM_ERROR]: error.response.data.message,
            }
            if (error.response.data.errors) {
                const fieldErrors = Object.assign(submissionError, ...error.response.data.errors)
                yield call(callback, fieldErrors)
            } else {
                yield call(callback, submissionError)
            }
        }
        yield put(
            AppModule.creators.addNotification({
                message: 'Invalid Domain',
                type: 'danger'
            })
        )
        yield put({
            type: types.SAVING_WEBSITE_ERROR,
            error
        })
    }
}

export default saveWebsiteOptions
