import { call, put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import Router from 'next/router'
import { PRODUCT_TYPES_NAME, PRODUCT_TYPES } from 'utils/constants/productEdit'
import { contributionBinPluralSlug } from 'features/ContributionLang'

import AccountModule from 'modules/account'
import appModule from 'modules/app'


function* updateProduct({ types, payload: { type, values } }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const id = Router?.router?.query?.id

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: `${endpoints.PRODUCT}/${id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...values,
                }
            }
        })

        const productPath = !!type?.includes('donation') ? yield call(contributionBinPluralSlug) : PRODUCT_TYPES[type]

        Router.push(`/products/${productPath}`)

        yield put(
            appModule.creators.addNotification({
                type: 'success',
                message: `${PRODUCT_TYPES_NAME[type]}: ${data.title} successfully updated`
            })
        )

        yield put({ type: types.UPDATE_PRODUCT_SUCCESS, payload: data })

    } catch (error) {
        yield put(
            appModule.creators.addNotification({
                type: 'danger',
                message: error.message
            })
        )
        yield put({ type: types.UPDATE_PRODUCT_ERROR, error })
    }
}

export default updateProduct


