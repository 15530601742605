import { toUTC } from 'utils/lib/dates'
import keys from 'lodash/keys'
import pickBy from 'lodash/pickBy'
import concat from 'lodash/concat'

const modifyAgePayload = (filter) => {
    if (['is_equal_to', 'is_not_equal_to'].includes(filter.filter_condition)) {
        return {
            filter_condition: filter.filter_condition === 'is_equal_to' ? 'is_between' : 'is_not_between',
            filter_value: [
                toUTC(filter.filter_value[0]).startOf('day').unix(),
                toUTC(filter.filter_value[1]).endOf('day').unix()
            ],
            filter_type: 'date',
            value: 'birthday'
        }
    } else if (['is_between', 'is_not_between'].includes(filter.filter_condition)) {
        return {
            filter_value: [
                toUTC(filter.filter_value_1).endOf('day').unix(),
                toUTC(filter.filter_value_2).subtract(1, 'year').startOf('day').unix(),
            ],
            filter_type: 'date',
            filter_condition: filter.filter_condition,
            value: 'birthday'
        }
    } else if (filter.filter_condition === 'is_greater_than' || filter.filter_condition === 'is_greater_than_or_equal_to') {
        return {
            filter_value: toUTC(filter.filter_value).startOf('day').unix(),
            filter_condition: 'is_greater_than',
            filter_type: 'date',
            value: 'birthday'
        }
    } else if (filter.filter_condition === 'is_less_than' || filter.filter_condition === 'is_less_than_or_equal_to') {
        return {
            filter_value: toUTC(filter.filter_value).endOf('day').unix(),
            filter_condition: 'is_less_than',
            filter_type: 'date',
            value: 'birthday'
        }
    } else if (filter.filter_condition === 'is_blank' || filter.filter_condition === 'is_not_blank') {
        return {
            filter_condition: filter.filter_condition,
            filter_type: 'date',
            value: 'birthday'
        }
    }
}

const modifyDateTypePayload = (filter) => {
    if (['is_between', 'is_not_between'].includes(filter.filter_condition)) {
        return {
            ...filter,
            filter_value: [
                toUTC(filter.filter_value[0]).unix(),
                toUTC(filter.filter_value[1]).unix(),
            ],
        }
    } else {
        return {
            ...filter,
            ...filter.filter_value && { filter_value: toUTC(filter.filter_value).unix() }
        }
    }
}

const modifyAddressPayload = (filter) => {
    const { filter_condition, filter_value } = filter
    if (['contains', 'does_not_contain', 'is_blank', 'is_not_blank'].includes(filter_condition)) {
        return {
            filter_type: 'group',
            filter_condition: ['contains', 'does_not_contain', 'is_not_blank'].includes(filter_condition) ? 'any' : 'all',
            filters: [{
                value: 'address1',
                filter_type: 'text',
                filter_condition,
                filter_value
            },
            {
                value: 'address2',
                filter_type: 'text',
                filter_condition,
                filter_value
            },
            {
                value: 'state',
                filter_type: 'text',
                filter_condition,
                filter_value
            },
            {
                value: 'country',
                filter_type: 'text',
                filter_condition,
                filter_value
            },
            {
                value: 'city',
                filter_type: 'text',
                filter_condition,
                filter_value
            }]
        }
    } else {
        return filter
    }
}

const modifySignupLocationPayload = (filter) => {
    const { filter_condition, filter_value } = filter
    if (['contains', 'does_not_contain', 'is_blank', 'is_not_blank'].includes(filter_condition)) {
        return {
            filter_type: 'group',
            filter_condition: ['contains', 'is_not_blank'].includes(filter_condition) ? 'any' : 'all',
            filters: [{
                value: 'signup_location.locality',
                filter_type: 'text',
                filter_condition,
                filter_value
            },
            {
                value: 'signup_location.admin_area',
                filter_type: 'text',
                filter_condition,
                filter_value
            },
            {
                value: 'signup_location.country',
                filter_type: 'text',
                filter_condition,
                filter_value
            }]
        }
    } else {
        return filter
    }
}


const modifyCheckboxPayload = (filter) => {
    const { filter_condition, filter_value, value, filter_following } = filter
    let condition
    let field_values

    if(filter_condition === 'include' && filter_following === 'all'){
        condition = 'is'
    }else if(filter_condition === 'include' && filter_following === 'any'){
        condition = 'contains'
    }else if(filter_condition === 'does_not_include' && filter_following === 'all'){
        condition = 'is_not'
    }else{
        condition = 'does_not_contains'
    }

    if(filter_value?.other && filter_value?.otherValue){
        field_values = concat(keys(pickBy(filter_value)).filter((val) => !(val === 'other' || val === 'otherValue')), filter_value.otherValue)
    }else{
        field_values = keys(pickBy(filter_value))
    }
    return {
        filter_type: 'text',
        filter_condition: condition,
        filter_value: field_values,
        value
    }
}

const modifyDropdownOrRadioPayload = (filter) => {
    const { filter_condition, filter_value, value, filter_value_custom } = filter
    return {
        filter_type: 'text',
        filter_condition,
        filter_value: filter_value === 'other' ? filter_value_custom : filter_value,
        value
    }
}

// For dollar-type fields, the backend expects the amount in cents, rather than dollars. However, the UI makes
// more sense in dollars, so we do this conversion here.
const modifyAmountTypePayload = (filter) => {
    const { filter_value } = filter

    return {
        ...filter,

        // filter_value is the actual number, value is the accessor
        filter_value: filter_value * 100,
    }
}

// see src/components/Contacts/List/Table/columns.js for the accessors
const amount_type_filters = [
    'current_purchase.amount',
    'payment_meta.latest_payment_amount',
    'payment_meta.total',
]
const modifyFilterPayload = (filter) => {
    if (filter.value === 'age') {
        return modifyAgePayload(filter)
    } else if (filter.filter_type === 'date') {
        return modifyDateTypePayload(filter)
    } else if (filter.value === 'location') {
        return modifyAddressPayload(filter)
    } else if (filter.filter_type === 'checkbox') {
        return modifyCheckboxPayload(filter)
    } else if (filter.filter_type === 'dropdown') {
        return modifyDropdownOrRadioPayload(filter)
    } else if (filter.value === 'signup_location') {
        return modifySignupLocationPayload(filter)
    } else if (amount_type_filters.includes(filter.value)) {
        return modifyAmountTypePayload(filter)
    } else {
        return filter
    }
}

export const getSorted = (sorted) => {
    let order = sorted[0]['desc'] ? 'desc' : 'asc'
    let field = sorted[0]['id']
    return `${order}(${field})`
}

export const getFiltered = (filtered) => {
    return {
        anyOrAll: filtered.anyOrAll,
        filters: filtered?.filters?.map?.((f) => modifyFilterPayload(f)) || []
    }
}

export const getColumnsForCsv = (columns) => {
    return columns.map((column) => column.accessor &&  { accessor: column.accessor, label: column.Header })
}
