import React from 'react'
import PropTypes from 'prop-types'

import { useFeatureManager, constants } from '../index'
import { useHasActiveSubscription } from 'features/platformAccess'

function FeatureManagerWrapper(props) {
    const children = props?.children

    const { hasFeature, getFeatureByLabel } = useFeatureManager()
    const { hasActiveSubscription } = useHasActiveSubscription()

    const hasHypePlugin = hasFeature(constants.GADGET_ACCESS_FEATURE) || hasFeature(constants.WIDGET_FEATURE)

    const childrenWithProps = React.Children.map(children, (child) => {
        const newProps = {
            ...props,
            hasHypePlugin,
            hasActiveSubscription,
            getFeatureByLabel,
        }
        if (React.isValidElement(child)) {
            return React.cloneElement(child, newProps)
        }
        return child
    })

    return (
        <>
            {childrenWithProps}
        </>
    )
}

function withFeatureManager(WrappedComponent) {
    return (props) => (
        <FeatureManagerWrapper {...props}>
            <WrappedComponent />
        </FeatureManagerWrapper>
    )
}

withFeatureManager.propTypes = {
    hasActiveSubscription: PropTypes.bool,
    hasHypePlugin: PropTypes.bool,
}

export default withFeatureManager
