import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Form as ReactFinalForm, Field } from 'react-final-form'
import { Text, Boutton as Button } from '@piconetworks/ui'
import Form from '@piconetworks/pkg-form'

import ActionModal from 'components/ActionModal'
import UsersModule from 'modules/users'

import styles from './Modals.module.scss'

class DeleteContact extends React.PureComponent {
    render() {
        const {
            dispatch,
            error,
            users,
            getModalText: { header, description, actionButtonText }
        } = this.props
        return (
            <ReactFinalForm
                onSubmit={(_values, _formApi, callback) => {
                    dispatch(UsersModule.creators.deleteContacts({ users, key: 'deleteAction' }, callback))
                }}
                render={({ handleSubmit, values, submitting, submitError }) => {
                    return (
                        <ActionModal.Wrapper type="danger" actionKey="deleteAction">
                            <form onSubmit={handleSubmit}>
                                <ActionModal.Header header={header} />
                                <ActionModal.Body>
                                    {submitError && (
                                        <Form.Feedback
                                            variant="danger"
                                            className="mb-4"
                                        >
                                            {submitError}
                                        </Form.Feedback>
                                    )}
                                    <Text.P>{description}</Text.P>
                                    <Field
                                        autoFocus
                                        component={Form.Input}
                                        name="goodbye"
                                        type="text"
                                        label="Type &quot;GOODBYE&quot; to confirm"
                                    />
                                </ActionModal.Body>
                                <ActionModal.Footer
                                    flexboxContainer={{
                                        justifyContent: 'end',
                                        alignItems: 'center'
                                    }}
                                    cancelButton={
                                        <Button
                                            className={cx(styles.cancel, { [styles.link]: !error })}
                                            variant={error ? 'secondary' : 'link'}
                                        >
                                            {error ? 'Got It' : 'Cancel'}
                                        </Button>
                                    }
                                    actionButton={
                                        <Button
                                            disabled={values.goodbye !== 'GOODBYE' || submitting}
                                            type="submit"
                                            className={cx(styles.action)}
                                            variant="danger"
                                        >
                                            {actionButtonText}
                                        </Button>
                                    }
                                />
                            </form>
                        </ActionModal.Wrapper>
                    )
                }}
            />
        )
    }
}

DeleteContact.propTypes = {
    dispatch: PropTypes.func,
    error: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]),
    getModalText: PropTypes.object,
    selectedUsers: PropTypes.array,
    users: PropTypes.array
}

const mapStateToProps = (state) => ({
    users: UsersModule.selectors.getSelectedUsers(state),
    getModalText: UsersModule.selectors.getModalText(state),
    error: UsersModule.selectors.getError(state)
})

export default connect(mapStateToProps)(DeleteContact)
