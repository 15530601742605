import { useSelector } from 'react-redux'

import SettingsModule from 'modules/settings'

const useUserId = () => {
    const userId = useSelector(SettingsModule.selectors.getMeDataUserId)

    return userId
}

export default useUserId
