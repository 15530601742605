const types = [
    'GET_ACCOUNT_INFO',
    'GET_ACCOUNT_INFO_SUCCESS',
    'GET_ACCOUNT_INFO_ERROR',
    'GET_ACCESS',
    'GET_ACCESS_SUCCESS',
    'GET_ACCESS_ERROR',
    'SET_PUBLISHER_ID'
]

const creators = ({ types }) => ({
    getAccountInfo: (payload) => ({ type: types.GET_ACCOUNT_INFO, ...payload }),
    getAccess: (payload) => ({ type: types.GET_ACCESS, ...payload }),
    setPublisherID: (payload) => ({ type: types.SET_PUBLISHER_ID, payload})
})

export {
    types,
    creators,
}
