/** @namespace FeatureManager.fetchTaxes */

import { createAsyncSaga, name, endpoints } from '../dependencies'

const fetchTaxes = createAsyncSaga(
    `${name}/fetchTaxes`,
    function* ({
        payload: {
            publisherId = null,
            productPricingId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!productPricingId) {
                throw new Error('productPricingId is required')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_TAX_RATE(productPricingId),
                headers: {
                    publisherid: publisherId,
                },
            })

            const taxRate = response?.tax_rate

            if (taxRate === null || taxRate === undefined) {
                throw new Error('tax rate could not be determined')
            }

            yield fulfilled(taxRate)
        } catch (error) {
            const defaultErrorMessage = error.message
            const zipCodeError = defaultErrorMessage.includes('zip')
            const taxError = defaultErrorMessage.includes('tax')
            const productPricingIdError = defaultErrorMessage.includes('productPricingId')

            let finalErrorMessage

            if (zipCodeError) {
                finalErrorMessage = 'We were unable to validate your zip code. Please update your payment method with the correct zip code or try a different card.'
            } else if (taxError) {
                finalErrorMessage = 'We were unable to calculate your sales tax rate. Please update your payment method or try a different card.'
            } else if (productPricingIdError) {
                finalErrorMessage = 'We could not find the product you are trying to purchase. Please try purchasing a different product.'
            } else {
                finalErrorMessage = defaultErrorMessage
            }

            yield rejected({
                error: finalErrorMessage,
            })
        }
    }
)

export default fetchTaxes
