import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'
import logger from 'lib/logger'
import OffersModule from 'modules/offers'
import AuthModule from 'modules/auth'
import AccountModule from 'modules/account'
import MessagesModule from 'modules/messages'

import { useNfcTags } from 'features/nfcEditor'
import { useFeatureManager, constants } from "features/FeatureManager"
import {
    SubmitButton,
    TitleBar,
    CancelButton,
    PopupContainer
} from "features/cartUI"
import usePublisherId from 'hooks/usePublisherId'
import useUserId from 'hooks/useUserId'

const postLogin = AuthModule.creators.postLogin
const tokenLogin = AuthModule.creators.tokenLogin
const getAccountInfo = AccountModule.creators.getAccountInfo
const authModuleTypes = AuthModule.types
const name = 'platformAccess'

export {
    name,
    endpoints,
    createAsyncSaga,
    logger,
    OffersModule,
    useFeatureManager,
    constants,
    SubmitButton,
    TitleBar,
    CancelButton,
    PopupContainer,
    usePublisherId,
    useUserId,
    useNfcTags,
    tokenLogin,
    postLogin,
    authModuleTypes,
    getAccountInfo,
    MessagesModule
}
