import { STANDARD_TYPE } from 'utils/constants/offers'
import OffersService from 'utils/services/Offers'
import OffersFormService from 'utils/services/Offers/form'

const selectors = ({ store, statuses }) => {
    try {
        const Offers = new OffersService(store, statuses)
        const OffersForm = new OffersFormService(store, statuses)
        return {
            getState: (state) => Offers.getState(state),
            getStatus: (state) => Offers.getState(state),
            getLoading: (state) => Offers.getLoading(state),
            getLandingPages: (state) => Offers.getLandingPages(state),
            getLandingPagesByType: (state) => Offers.getLandingPageByType(state),
            getStandardPages: (state) => Offers.getLandingPageByType(state)?.[`${STANDARD_TYPE}_page`]?.active || [],
            getLandingPageById: (state, id) => Offers.getLandingPageById(state, id),
            getActiveLandingPages: (state) => Offers.getActiveLandingPages(state),
            getActivePaymentLandingPages: (state) => Offers.getActivePaymentLandingPages(state),
            getPopups: (state) => Offers.getPopups(state),
            getPopupById: (state, id) => Offers.getPopupById(state, id),
            getInitialFormValues: (state, format, id) => OffersForm.getInitialFormValues(state, format, id),
            getConversionRate: (state) => Offers.getConversionRate(state)
        }            
    } catch (error) {
        return {}
    }
}

export default selectors
