import React, { useCallback } from 'react'
import Router from "next/router"
import Boutton from '@piconetworks/pkg-boutton'
import { Flexbox } from '@piconetworks/pkg-box'

import cx from 'classnames'
import styles from './PhoneNumberRequiredNotify.module.scss'

const PhoneNumberRequiredNotify = ({
    cancel = () => {},
    afterRedirect = () => {}
}) => {
    const goToBlasts = useCallback(() => {
        afterRedirect()
        Router.push('/messages/blasts?registerNumber=true')
    }, [afterRedirect])

    return (
        <div className={styles.popup}>
            <Flexbox.Container alignItems="center" className={styles.titlebar}>
                <div>
                    <span className={cx(styles.icon, 'icon-icon_warning')} />
                    <p>Register a number</p>
                </div>
            </Flexbox.Container>
            <div className={styles.popupContainer}>
                <div className={styles.popupDesc} >
                    Hype needs to assign a phone number to you before we notify your imported contacts that they have been added to your Blast list.
                    <br/>
                </div>
                <Boutton
                    variant="primary"
                    className={styles.button}
                    onClick={goToBlasts}
                >
                    Register a number
                </Boutton>
                <Boutton
                    variant="secondary"
                    className={styles.button}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        cancel()
                    }}
                >
                    Cancel
                </Boutton>
            </div>
        </div>
    )
}

export default PhoneNumberRequiredNotify
