import Modal from '@piconetworks/pkg-modal'
import cx from 'classnames'
import styles from './Popup.module.scss'

const Popup = ({
    children = null,
    onAfterClose = () => {},
    className = ''
}) => {
    return (
        <div onClick={(e) => {
            e.stopPropagation()
        }}>
            <Modal.Container
                centered={true}
                contentLabel="For screenreaders!"
                scrollable={false}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                setOpen={true}
                onAfterClose={onAfterClose}
                className={cx(styles.popup, className)}
                ariaHideApp={false} // this prevents a warning from React Modal
            >
                {children}
            </Modal.Container>
        </div>
    )
}

export default Popup
