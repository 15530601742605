import endpoints from 'utils/lib/endpoints'
import { getPublisherId, getAccessToken } from 'utils/lib/state'

export const getS3SignedUrl = async ({ bucket, file_name, file_type }) => {
    const access_token = getAccessToken()
    const headers = {
        'content-type': 'application/json',
        authorization: `Bearer ${access_token}`,
        publisherid: getPublisherId(),
    }
    const body = JSON.stringify({
        bucket,
        file_name,
        file_type,
    })

    const response = await fetch(endpoints.S3_UPLOAD_SIGN, { method: 'POST', headers, body })

    if (response?.status !== 200) {
        throw new Error('There is an issue while getting signed url')
    }

    return await response.json()
}

export const pushFileToS3 = async (signed_url, { file_content, file_type }) => {
    const response = await fetch(signed_url, {
        method: 'PUT',
        headers: {
            'content-type': file_type,
        },
        body: file_content,
    })

    if (response?.status !== 200) {
        throw new Error('There is an issue while uploading file to s3')
    }

    return response
}
