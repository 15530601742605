import { createSlice } from '@reduxjs/toolkit'

import { name } from '../dependencies'
import createUnclaimedGym from './createUnclaimedGym'
import updateUnclaimedGym from './updateUnclaimedGym'
import updateBusinessInformation from './updateBusinessInformation'
import bulkCreateGyms from './bulkCreateGyms'
const initialState = {
    inProgress: false,
    error: null
}

const salesToolsSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [createUnclaimedGym.pending]: (state) => {
            state.inProgress = true
            state.error = null
        },
        [createUnclaimedGym.fulfilled]: (state, { payload: cost }) => {
            state.inProgress = true
            state.error = null
        },
        [createUnclaimedGym.rejected]: (state, { payload: error }) => {
            state.inProgress = false
            state.error = error
        },
        [updateUnclaimedGym.pending]: (state) => {
            state.inProgress = true
            state.error = null
        },
        [updateUnclaimedGym.fulfilled]: (state, { payload: cost }) => {
            state.inProgress = true
            state.error = null
        },
        [updateUnclaimedGym.rejected]: (state, { payload: error }) => {
            state.inProgress = false
            state.error = error
        },
        [updateBusinessInformation.pending]: (state) => {
            state.inProgress = true
            state.error = null
        },
        [updateBusinessInformation.fulfilled]: (state, { payload: cost }) => {
            state.inProgress = true
            state.error = null
        },
        [updateBusinessInformation.rejected]: (state, { payload: error }) => {
            state.inProgress = false
            state.error = error
        },
        [bulkCreateGyms.pending]: (state) => {
            state.inProgress = true
            state.error = null
        },
        [bulkCreateGyms.fulfilled]: (state, { payload: cost }) => {
            state.inProgress = true
            state.error = null
        },
        [bulkCreateGyms.rejected]: (state, { payload: error }) => {
            state.inProgress = false
            state.error = error
        },
    },
})

// SELECTORS
const getSalesToolsState = (state) => state[name]

// const getGiveAccessInProgress = createDraftSafeSelector(
//     getsalesToolsState,
//     (salesTools) => salesTools?.giveAccessInProgress,
// )


// GENERAL
const takes = [
    ...createUnclaimedGym.takes,
    ...updateUnclaimedGym.takes,
    ...updateBusinessInformation.takes,
    ...bulkCreateGyms.takes
]

const salesToolsInitialState = {
    [name]: initialState,
}

const salesToolsReducer = {
    [name]: salesToolsSlice.reducer,
}

export {
    takes,
    salesToolsInitialState as initialState,
    salesToolsReducer as reducer,

    getSalesToolsState,
    bulkCreateGyms

}
