import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import AccountModule from 'modules/account'
import SettingsModule from 'modules/settings'

import { useFeatureManager } from 'features/FeatureManager'

const AccessControlV2 = ({
    // Only one of these items in the array called requiredFeatures is required to render
    // if we require multiple features to render a single thing,
    // then pass in an array of features inside this array
    // e.g. requiredFeatures={[['feature1', 'feature2'], 'feature3']}
    // if feature 3 is false, we require feature 1 and 2 to be true in order for the children to render
    // arrays can also take in boolean values like ['feature1', true || false]
    requiredFeatures = [],
    children = null,
    override = false,
    fallback = () => (<div
        requiredFeatures={requiredFeatures}
        style={{ display: 'none' }}
    />),
}) => {
    const publisherId = useSelector(AccountModule.selectors.getPublisherId)
    const userId = useSelector(SettingsModule.selectors.getMeDataUserId)

    const { hasFeature } = useFeatureManager({ publisherId, userId })

    const ownsAccess = requiredFeatures.some(req => {
        if (Array.isArray(req)) {
            return req.every(_req => hasFeature(_req) || _req === true)
        } else {
            return hasFeature(req) || req === true
        }
    })

    if (override || !requiredFeatures.length) {
        return children
    }

    return (
        ownsAccess
            ? children
            : (fallback && fallback())
    )
}

AccessControlV2.propTypes = {
    apps: PropTypes.array,
    children: PropTypes.node,
    fallback: PropTypes.func,
    override: PropTypes.bool,
}

const WrappedAccessControl = ({
    children = null,
    override = null,
    fallback = null,
    requiredFeatures = [],
}) => {

    return (
        <AccessControlV2
            requiredFeatures={requiredFeatures}
            override={override}
            fallback={fallback}
        >
            {children}
        </AccessControlV2>
    )
}

export default WrappedAccessControl
