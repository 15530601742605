import { put, putResolve, select, call } from 'redux-saga/effects'
import { FORM_ERROR } from 'final-form'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import AccountModule from 'modules/account'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'

function* saveMyAppearance({ payload, types, callback }) {
    const { notification = true, ...values } = payload
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: endpoints.MY_APPEARANCE,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...values,
                }
            }
        })
        yield put({
            type: types.SAVING_MY_APPEARANCE_SUCCESS,
            payload: data
        })
        yield put({
            type: AccountModule.types.GET_ACCOUNT_INFO_SUCCESS,
            payload: data
        })

        if (notification) {
            yield put(
                AppModule.creators.addNotification({
                    message: 'Appearance successfully updated',
                    type: 'success'
                })
            )
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            const submissionError = {
                [FORM_ERROR]: error.response.data.message,
            }
            if (error.response.data.errors) {
                const fieldErrors = Object.assign(submissionError, ...error.response.data.errors)
                yield call(callback, fieldErrors)
            } else {
                yield call(callback, submissionError)
            }
        }

        yield put(
            AppModule.creators.addNotification({
                message: 'Something went wrong!',
                type: 'danger'
            })
        )
        yield put({
            type: types.SAVING_MY_APPEARANCE_ERROR,
            error
        })
    }
}

export default saveMyAppearance
