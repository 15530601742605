import { calculateHypePluginPricing } from 'utils/lib/pricing'
import useUserCount from './useUserCount'

const useHypePluginPrice = () => {
    const userCount = useUserCount()

    return calculateHypePluginPricing(userCount) * 100
}

export default useHypePluginPrice
