import login from './login'
import logout from './logout'
import switchAccount from './switchAccount'
import tokenLogin from './tokenLogin'
import useRefreshToken from './useRefreshToken'
import resetRequest from './resetRequest'
import socialLogin from './socialLogin'
import resetClaim from './resetClaim'
import updatePassword from './updatePassword'
import loginFromOnboarding from './loginFromOnboarding'
import preLogout from './preLogout'
import postLogin from './postLogin'
import claimMagicLink from './claimMagicLink'

const sagas = (duck) => ({
    * login(payload) {
        yield login({ ...payload, ...duck })
    },
    * logout(payload) {
        yield logout({ ...payload, ...duck })
    },
    * switchAccount(payload) {
        yield switchAccount({ ...payload, ...duck })
    },
    * tokenLogin(payload) {
        yield tokenLogin({ ...payload, ...duck })
    },
    * useRefreshToken() {
        yield useRefreshToken({ ...duck })
    },
    * resetRequest(payload) {
        yield resetRequest({ ...payload, ...duck })
    },
    * socialLogin(payload) {
        yield socialLogin({ ...payload, ...duck })
    },
    * resetClaim(payload) {
        yield resetClaim({ ...payload, ...duck })
    },
    * updatePassword(payload) {
        yield updatePassword({ ...payload, ...duck })
    },
    * loginFromOnboarding(payload) {
        yield loginFromOnboarding({ ...payload, ...duck })
    },
    * postLogin(payload) {
        yield postLogin({ ...duck, ...payload })
    },
    * preLogout(payload) {
        yield preLogout({ ...duck, ...payload })
    },
    * claimMagicLink(payload) {
        yield claimMagicLink({ ...payload, ...duck })
    }
})

export default sagas
