let QRCodeStyling: any

if (typeof window !== "undefined") {
    import("qr-code-styling").then(_QRCodeStyling => {
      QRCodeStyling = _QRCodeStyling.default
    })
}

const hypeLogo = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABySURBVHgB7dXbCYAwEETRUSwkrVihWIFYiZZgCZZgBz7weyEjBAzcA/s3kCGQrAQAZTXyLPL1uUG3zClf9hmtfoQyEcpE3DKzPKMTdp/2IxnZXbX6ejMpI3fcs6mgQe8vnDuTDKyDCGUilIl08qwCgIpdMEsUNVVlRzwAAAAASUVORK5CYII='

const outputFilename = 'printout.png'

const fontFamily = 'Antarctica'
const fontSize = '8px'

const dotsPerInch = 300
const outputWidthInches = 8
const outputHeightInches = 12

const engravingWidth = 325
const engravingHeight = 218

const qrCodeWidth = 160
const qrCodeHeight = 160
const qrCodeMargin = 12

const qrCodeX = 30
const qrCodeY = 30

const shortCodeX = 48
const shortCodeY = 180

const brandWording = 'HYPE.CO'
const brandNameX = 134
const brandNameY = 180

const makeQR = async (data: string, overrides: any = {}) => {
    return new QRCodeStyling({
        width: qrCodeWidth,
        height: qrCodeHeight,
        margin: qrCodeMargin,
        image: hypeLogo,
        data: data,
        qrOptions: {
            typeNumber: '0',
            mode: 'Byte',
            errorCorrectionLevel: 'Q' 
        },
        imageOptions: {
            hideBackgroundDots: true,
            imageSize: 0.5,
            margin: 0 
        },
        dotsOptions: {
            type: 'square',
            color: '#000000' 
        },
        backgroundOptions: {
            color: '#FFFFFF00' 
        },
        dotsOptionsHelper: {
            colorType: {
                single: true,
                gradient: false 
            },
            gradient: {
                linear: true,
                radial: false,
                color1: '#000000',
                color2: '#000000',
                rotation: '0' 
            },            
        },
        cornersSquareOptions: {
            type: 'square',
            color: '#000000' 
        },
        cornersSquareOptionsHelper: {
            colorType: {
                single: true,
                gradient: false 
            },
            gradient: {
                linear: true,
                radial: false,
                color1: '#000000',
                color2: '#000000',
                rotation: '0' 
            },
        },
        cornersDotOptions: {
            type: 'square',
            color: '#000000' 
        },
        cornersDotOptionsHelper: {
            colorType: {
                single: true,
                gradient: false 
            },
            gradient: {
                linear: true,
                radial: false,
                color1: '#000000',
                color2: '#000000',
                rotation: '0' 
            },        
        },
        backgroundOptionsHelper: {
            colorType: {
                single: true,
                gradient: false 
            },
            gradient: {
                linear: true,
                radial: false,
                color1: '#ffffff',
                color2: '#ffffff',
                rotation: '0' 
            },
        },
        ...overrides,
    })
}

const convertQrToImage = (qrCode: any): Promise<HTMLImageElement> => new Promise(async (resolve, reject) => {
    try {
        const blob = await qrCode.getRawData('png')
        const dataUrl = URL.createObjectURL(blob)
        const img = new Image()

        img.onload = function() {
            URL.revokeObjectURL(dataUrl)
    
            resolve(img)
        }

        img.onerror = function(error) {
            reject(error)
        }
    
        img.src = dataUrl            
    } catch (error) {
        reject(error)
    }
})

const makeQrImage = async (qrData: string, qrCodeOverrides: any = {}) => {
    const qrCode = await makeQR(qrData, qrCodeOverrides)
    const img = await convertQrToImage(qrCode)

    return img
}

const getQrLink = (tagId: string) => {
    if (process.env.STAGE === 'development') {
        return `https://api.dev.pico.tools/nfc/tags/${tagId}`
    }

    return `https://${process.env.STAGE === 'production' ? '' : `${process.env.STAGE}.`}hyp.lk/nfc/${tagId}`
}

const createQrCanvas = async ({
    qrLink = `https://hype.co`,
    qrCodeOverrides = {},
    shortCode = '',
}) => {
    // create canvas
    const canvas = document.createElement('canvas')
    canvas.width = engravingWidth
    canvas.height = engravingHeight

    // create context
    const ctx = canvas.getContext('2d')

    // Draw short code
    ctx.font = `${fontSize} "${fontFamily}"`
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'left'
    ctx.fillText(shortCode, shortCodeX, shortCodeY)

    // Draw brand name
    ctx.font = `bold ${fontSize} "${fontFamily}"`
    ctx.fillText(brandWording, brandNameX, brandNameY)

    // Draw QR code
    const qrImg = await makeQrImage(qrLink, qrCodeOverrides)
    ctx.drawImage(qrImg, qrCodeX, qrCodeY)

    // return canvas
    return canvas
}

const createPrintCanvas = async (qrCanvasArray: HTMLCanvasElement[]) => {
    const printOutWidth = outputWidthInches * dotsPerInch
    const printOutHeight = outputHeightInches * dotsPerInch

    const printCanvas = document.createElement('canvas')
    printCanvas.width = printOutWidth
    printCanvas.height = printOutHeight
    const ctx = printCanvas.getContext('2d')

    let currentX = 0
    let currentY = 0

    for (const qrCanvas of qrCanvasArray) {
        if (currentX + engravingWidth > printOutWidth) {
            currentY += engravingHeight
            currentX = 0
        }

        ctx.drawImage(qrCanvas, currentX, currentY, engravingWidth, engravingHeight)

        currentX += engravingWidth
    }

    return printCanvas
}

const downloadPrintCanvas = async (printCanvas: HTMLCanvasElement) => {
    const pngDataUrl = printCanvas.toDataURL('image/png')
    const link = document.createElement('a')
    link.href = pngDataUrl
    link.download = outputFilename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const injectFonts = () => new Promise(async (resolve, reject) => {
    try {
        // Create the style element for @font-face definitions
        const styleElement = document.createElement('style')
        styleElement.textContent = `
            @font-face {
                font-family: 'Antarctica';
                src: url('/assets/fonts/Antarctica-VF.ttf') format('truetype');
                font-weight: normal;
                font-style: normal;
            }
            @font-face {
                font-family: 'Antarctica-Bold';
                src: url('/assets/fonts/Antarctica-VF.ttf') format('truetype');
                font-weight: bold;
                font-style: normal;
            }
        `
        document.head.appendChild(styleElement)

        // Create and inject a hidden div with two spans
        const divElement = document.createElement('div')
        divElement.style.position = 'absolute'
        divElement.style.height = '0'
        divElement.style.width = '0'
        divElement.style.top = '0'
        divElement.style.left = '0'
        divElement.style.overflow = 'hidden'

        const regSpan = document.createElement('span')
        regSpan.style.fontFamily = 'Antarctica'
        regSpan.textContent = 'reg'
        divElement.appendChild(regSpan)

        const boldSpan = document.createElement('span')
        boldSpan.style.fontFamily = 'Antarctica-Bold'
        divElement.appendChild(boldSpan)

        document.body.appendChild(divElement)

        // Load and watch the custom font
        const fontRegular = new FontFace('Antarctica', 'url(/assets/fonts/Antarctica-VF.ttf)', {
            weight: 'normal',
            style: 'normal'
        })
        
        const fontBold = new FontFace('Antarctica', 'url(/assets/fonts/Antarctica-VF.ttf)', {
            weight: 'bold',
            style: 'normal'
        })
    
        // Add the fonts to the document to start loading them
        document.fonts.add(fontRegular)
        document.fonts.add(fontBold)
    
        // Wait for both fonts to load
        await Promise.all([fontRegular.load(), fontBold.load()])

        resolve(true)
    } catch (error) {
        reject(error)
    }
})

const downloadStickers = async (
    tags: {
        id?: string,
        short_code?: string,
        text_override?: string,
    }[],
    qr_code_overrides?: any,
) => {
    await injectFonts()

    const qrCanvasArray = await Promise.all(tags.map(async (tag) => {
        if (tag?.text_override)  {
            return await createQrCanvas({
                qrLink: tag?.text_override,
                qrCodeOverrides: qr_code_overrides,
            })
        } else {
            return await createQrCanvas({
                qrLink: getQrLink(tag.id),
                shortCode: tag.short_code,
            })
        }
    }))

    const printCanvas = await createPrintCanvas(qrCanvasArray)

    await downloadPrintCanvas(printCanvas)    
}

export default downloadStickers

export {
    createQrCanvas,
}
