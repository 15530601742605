const selectors = ({ store, statuses }) => ({
    getNewsletters: (state) => state[store],
    getFreeNewsletters: (state) => state[store].free,
    getPreviewNewsletters: (state) => state[store].preview,
    getPaidNewsletters: (state) => state[store].paid,
    getLoading: (state) => state[store].status === statuses.LOADING,
    getNewsletter: (state) => state[store].selectedNewsletter,
    getError: (state) => state[store].error
})

export default selectors
