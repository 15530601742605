import usePublisherId from 'hooks/usePublisherId'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    createPack,
    fetchPack,
    fetchAllPacks,
    updatePack,
    addTagToPack,
    createPackForTag,
    removeTagFromPack,

    selectAllPacks,
    selectPackById,
    selectPackIds,
    fetchPackInProgress,
} from './slice'

const usePacks = () => {
    const _selectAllPacks = useSelector(selectAllPacks)
    const _selectPackById = useCallback((packId) => useSelector((state) => selectPackById(state, packId)), [])
    const _selectPackIds = useSelector(selectPackIds)
    const _fetchPackInProgress = useSelector(fetchPackInProgress)

    const dispatch = useDispatch()
    const publisherId = usePublisherId()

    const _fetchPack = useCallback((packId) => {
        if (_fetchPackInProgress) {
            return
        }

        dispatch(fetchPack({
            publisherId,
            packId,
        }))
    }, [ dispatch, publisherId, _fetchPackInProgress ])

    const _createPack = useCallback(({
        quantity,
    }) => {
        dispatch(createPack({
            publisherId,
            quantity,
        }))
    }, [ dispatch, publisherId ])

    const _fetchAllPacks = useCallback(() => {
        dispatch(fetchAllPacks({
            publisherId,
        }))
    }, [ dispatch, publisherId ])

    const _updatePack = useCallback(({
        packId,
        quantity,
    }) => {
        dispatch(updatePack({
            publisherId,
            packId,
            quantity,
        }))
    }, [ dispatch, publisherId ])

    const _addTagToPack = useCallback(({
        packId,
        tagId,
    }) => {
        dispatch(addTagToPack({
            publisherId,
            packId,
            tagId,
        }))
    }, [ dispatch, publisherId ])

    const _createPackForTag = useCallback(({
        tagId,
        quantity,
    }) => {
        dispatch(createPackForTag({
            publisherId,
            tagId,
            quantity,
        }))
    }, [ dispatch, publisherId ])

    const _removeTagFromPack = useCallback(({
        packId,
        tagId,
    }) => {
        dispatch(removeTagFromPack({
            publisherId,
            packId,
            tagId,
        }))
    }, [ dispatch, publisherId ])

    return {
        fetchPack: _fetchPack,
        createPack: _createPack,
        fetchAllPacks: _fetchAllPacks,
        updatePack: _updatePack,
        addTagToPack: _addTagToPack,
        createPackForTag: _createPackForTag,
        removeTagFromPack: _removeTagFromPack,

        selectAllPacks: _selectAllPacks,
        selectPackById: _selectPackById,
        selectPackIds: _selectPackIds,
        fetchPackInProgress: _fetchPackInProgress,
    }
}

export default usePacks
