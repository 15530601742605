import usePublisherId from 'hooks/usePublisherId'
import usePublisherUsername from 'hooks/usePublisherUsername'
import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'
import { select } from 'redux-saga/effects'
import AccountModule from 'modules/account'


function* getPublisherId() {
    const publisherId = yield select(AccountModule.selectors.getPublisherId)

    return publisherId
}

const name = 'salesTools'

export {
    name,

    endpoints,
    createAsyncSaga,

    usePublisherId,
    usePublisherUsername,

    getPublisherId
}
