import {
    createDraftSafeSelector,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit'

import { fetchApps, name } from '../dependencies'

import giveAccess from './giveAccess'
import getAccess from './getAccess'
import { put, takeEvery } from 'redux-saga/effects'

const accessRecordAdapter = createEntityAdapter({
    selectId: (card: any) => card?.id,
    sortComparer: (a: any, b: any) => a?.id - b?.id,
})

const accessRecordAdapterInitialState = accessRecordAdapter.getInitialState()

const initialState = {
    giveAccessInProgress: false,
    giveAccessSuccess: null,
    giveAccessError: null,

    getAccessInProgress: false,
    getAccessSuccess: null,
    getAccessError: null,

    ...accessRecordAdapterInitialState,
}

const adminAccessSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [giveAccess.pending]: (state) => {
            state.giveAccessInProgress = true
            state.giveAccessSuccess = initialState.giveAccessSuccess
            state.giveAccessError = initialState.giveAccessError
        },
        [giveAccess.fulfilled]: (state) => {
            state.giveAccessInProgress = false
            state.giveAccessSuccess = true
            state.giveAccessError = false
        },
        [giveAccess.rejected]: (state, { payload: error }) => {
            state.giveAccessInProgress = false
            state.giveAccessSuccess = false
            state.giveAccessError = error
        },
        [getAccess.pending]: (state, { payload }: any) => {
            accessRecordAdapter.upsertMany(state, payload?.accessRecords || [])
            state.getAccessInProgress = true
            state.getAccessSuccess = initialState.getAccessSuccess
            state.getAccessError = initialState.getAccessError
        },
        [getAccess.fulfilled]: (state, { payload: adminAccess }) => {
            state.getAccessInProgress = false
            state.getAccessSuccess = true
            state.getAccessError = false
        },
        [getAccess.rejected]: (state, { payload: error }) => {
            state.getAccessInProgress = false
            state.getAccessSuccess = false
            state.getAccessError = error
        },
    },
})

// SELECTORS
const getAdminAccessState = (state) => state[name]
const getGiveAccessInProgress = createDraftSafeSelector(
    getAdminAccessState,
    (adminAccess) => adminAccess?.giveAccessInProgress,
)

const getGiveAccessSuccess = createDraftSafeSelector(
    getAdminAccessState,
    (adminAccess) => adminAccess?.giveAccessSuccess,
)

const getGiveAccessError = createDraftSafeSelector(
    getAdminAccessState,
    (adminAccess) => adminAccess?.giveAccessError,
)

const getAdminAccessCacheString = createDraftSafeSelector(
    getAdminAccessState,
    (adminAccess) => `?${adminAccess?.adminAccessCacheString}`,
)

const getGetAccessInProgress = createDraftSafeSelector(
    getAdminAccessState,
    (adminAccess) => adminAccess?.getAccessInProgress,
)

const getGetAccessSuccess = createDraftSafeSelector(
    getAdminAccessState,
    (adminAccess) => adminAccess?.getAccessSuccess,
)

const getGetAccessError = createDraftSafeSelector(
    getAdminAccessState,
    (adminAccess) => adminAccess?.getAccessError,
)

const getAdminAccess = createDraftSafeSelector(
    getAdminAccessState,
    (adminAccess) => adminAccess?.adminAccess,
)

// GENERAL
const takes = [
    ...giveAccess.takes,
    ...getAccess.takes,
    takeEvery(giveAccess.fulfilled, function* (action: any) {
        yield put(fetchApps({ publisherId: action?.payload?.publisherId }))
    })
]

const adminAccessInitialState = {
    [name]: initialState,
}

const adminAccessReducer = {
    [name]: adminAccessSlice.reducer,
}

export {
    takes,
    adminAccessInitialState as initialState,
    adminAccessReducer as reducer,

    giveAccess,
    getAccess,

    getGiveAccessInProgress,
    getGiveAccessSuccess,
    getGiveAccessError,

    getGetAccessInProgress,
    getGetAccessSuccess,
    getGetAccessError,
}
