import React, { useEffect, useRef, useCallback } from 'react'
import { Field, useForm } from 'react-final-form'
import PropTypes from 'prop-types'
import intlTelInput from 'intl-tel-input'
import Form from '@piconetworks/pkg-form'
import styles from './ConfirmBusinessInformation.module.scss'

const PhoneField = ({
    fieldContainerProps,
}) => {
    const { change } = useForm()
    const phoneInputRef = useRef(null)

    useEffect(() => {
        if (phoneInputRef?.current) {
            const iti = intlTelInput(phoneInputRef.current, {
                preferredCountries: ['us'],
                utilsScript:
                    'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js'
            })

            window.iti = iti
        }
    }, [phoneInputRef.current])

    const number = window?.iti?.getNumber() || null

    const updateCountryCode = useCallback((phoneInput) => {
        if (window.iti.isValidNumber()) {
            const countryCodeOnly = window.iti.getNumber().replace(phoneInput, '')
            // console.log('country code', countryCodeOnly)
            change('country_code', countryCodeOnly)
        }
    }, [change])

    const shouldShowError = (phoneInput) => {
        // intl-input number validates numbers with letters if it fits the country's
        // number length, so we must check the raw input as well as the formatted input
        // they provide. shouldShowError is true if intl-input number validation does not pass
        // or input value contains anything other than +-() and numbers
        updateCountryCode(phoneInput)
        return (
            (number && !window.iti.isValidNumber()) ||
            !/^(?=.*[0-9])[- +()0-9]+$/.test(phoneInput)
        )
    }

    const resolvePhoneInputError = () => {
        switch (window.iti.getValidationError()) {
            case 1:
                return 'Invalid country code'
            case 2:
                return 'Number is too short'
            case 3:
                return 'Number is too long'
            case 5:
                return 'Number length is invalid'
            default:
                return 'Invalid number'
        }
    }
    return (
        <div className={styles.phoneInputContainer}>
            <div className={styles.field}>
                <Field
                    {...fieldContainerProps}
                    name='phone'
                    required
                    validate={(value) => shouldShowError(value) ? resolvePhoneInputError() : undefined}
                >
                    {({ meta, input, ...rest }) => (
                        <div
                            className={
                                styles[`phoneInput${meta.touched && meta.error ? '--error' : ''}`]
                            }
                        >
                            <Form.Input
                                {...rest}
                                meta={meta}
                                ref={phoneInputRef}
                                input={input}
                            />
                        </div>
                    )}
                </Field>
            </div>
        </div>
    )
}

PhoneField.propTypes = {
    fieldContainerProps: PropTypes.any,
}

const MemoizedPhoneField = React.memo(PhoneField)

MemoizedPhoneField.displayName = 'FieldSignupPhone'

export default MemoizedPhoneField
