import { useSelector } from 'react-redux'

import SettingsModule from 'modules/settings'

const useBillingError = () => {
    const error = useSelector(SettingsModule.selectors.getBillingError)

    return error
}

export default useBillingError
