import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'

function* getMyBusiness({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data, status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.MY_ORGANIZATION,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })

        if (status === 200) {
            yield put({
                type: types.GET_MY_ORGANIZATION_SUCCESS,
                payload: {
                    ...data
                }
            })
        } else if (data.error) {
            yield put({
                type: types.GET_MY_ORGANIZATION_ERROR,
                error: data.error
            })
            AppModule.creators.addNotification({
                message: 'Business couldn\'t get successfully.',
                type: 'danger'
            })
        } else {
            throw Error('An uncatched error occured.')
        }
    } catch (e) {
        yield put({
            type: types.GET_MY_ORGANIZATION_ERROR,
            error: e
        })
    }
}

export default getMyBusiness
