import { createAsyncSaga, name, endpoints } from '../dependencies'

const giveAccess = createAsyncSaga(
    `${name}/giveAccess`,
    function* ({
        payload: {
            publisherId = null,
            tierPricing = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            const response = yield request({
                method: 'post',
                url: endpoints.GIVE_ACCESS,
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    tier_pricing: tierPricing,
                    start: new Date('2016-05-06').toISOString(),
                    end: new Date('9999-12-31').toISOString(),
                },
                timeout: 15000,
            })
        
            yield fulfilled({
                publisherId,
            })
        } catch (error) {
            yield rejected({
                error: error.message,
            })
        }
    }
)

export default giveAccess
