import React, { useEffect } from 'react'
import cx from 'classnames'
import Form from '@piconetworks/pkg-form'
import { Flexbox } from '@piconetworks/pkg-box'
import styles from './Step.module.scss'
import { useForm, useFormState } from 'react-final-form'

const WizardStep = ({ title, children, setIsOpen }) => {

    const {
        dirtySinceLastSubmit,
        submitError,
        hasSubmitErrors,
        values
    } = useFormState()
    
    const { mutators } = useForm()
    // console.log('Step ERROR submitError', submitError, 'values', values, 'dirtySinceLastSubmit', dirtySinceLastSubmit)

    useEffect(() => {
        // console.log('dirtySinceLastSubmit', dirtySinceLastSubmit, 'hasSubmitErrors', hasSubmitErrors)
        if (dirtySinceLastSubmit && hasSubmitErrors) {
            mutators.unsetError()
        }
    }, [dirtySinceLastSubmit, hasSubmitErrors, mutators, values])
    return (
        <Flexbox.Container flexDirection='column' className={cx(styles.container, styles.modal)}>
            <Flexbox.Container
                alignItems='center'
                justifyContent='between'
                className={styles.headerWrapper}
            >
                <div className={styles.header}>
                    <h3 className={styles.title}>{title}</h3>
                </div>
                <button
                    className={styles.cancel}
                    type='button'
                    onClick={() => setIsOpen(false)}
                >
                    <span className={'icon-icon_close'} />
                </button>
            </Flexbox.Container>
            <Flexbox.Container className={styles.wizardContents} flexDirection='column' >
                {submitError && (
                    <Form.Feedback
                        variant="danger"
                        className="mb-4"
                    >
                        {submitError}
                    </Form.Feedback>
                )}
                {children}
            </Flexbox.Container>
        </Flexbox.Container>
    )
}

export default WizardStep
