import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { createWrapper } from 'next-redux-wrapper'
import { createNetworkMiddleware } from '@piconetworks/pkg-network-middleware'
import rootReducer from './rootReducer'
import persistedReducer from './persistedReducer'
import endpoints from '../utils/lib/endpoints'
import rootSaga from './rootSaga'
import AccountModule from './account'

let exportedStore

const sagaMiddleware = createSagaMiddleware()

const getPublisherId = (store) => AccountModule.selectors.getPublisherId(store)

const networkMiddleware = createNetworkMiddleware({
    tokenKey: 'default',
    concurrentRequests: 8,
    accessTokenAdapters: {
        response: ({ data }) => {
            return {
                access_token: data.token,
                refresh_token: data.refresh_token
            }
        }
    },
    refreshTokenAdapters: {
        request: (payload) => {
            const { refresh_token } = payload
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                refreshtoken: refresh_token
            }
            headers.publisherid = getPublisherId(exportedStore.getState())

            return {
                method: 'get',
                baseURL: endpoints.BASE_URL,
                url: endpoints.OAUTH.PUBLISHER_TOKEN,
                headers,
                timeout: 30000
            }
        },
        response: ({ data }) => {
            return {
                access_token: data.token,
                refresh_token: data.refresh_token
            }}
    }
})

export const makeStore = (preloadedState) => {
    let store
    const isServer = typeof window === 'undefined'

    const storeConfig = {
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: true,
                serializableCheck: false,
            }).concat(sagaMiddleware).concat(networkMiddleware),
        devTools: true,
        preloadedState
    }

    if (isServer) {
        storeConfig.reducer = rootReducer
        // console.log('storyConfig', storeConfig)
        store = configureStore(storeConfig)
    } else {
        const { persistStore } = require('redux-persist')
        storeConfig.reducer = persistedReducer
        store = configureStore(storeConfig)
        store.__persistor = persistStore(store)
    }

    store.sagaTask = sagaMiddleware.run(rootSaga)

    exportedStore = store

    return store
}

export const wrapper = createWrapper(makeStore)

export {
    exportedStore,
}
