import getMyBusiness from './account/getMyBusiness'
import saveMyBusiness from './account/saveMyBusiness'
import getMyProfile from './account/getMyProfile'
import saveProfile from './account/saveProfile'
import getMe from './account/getMe'
import createSocialLink from './account/createSocialLink'
import updateSocialLink from './account/updateSocialLink'
import deleteSocialLink from './account/deleteSocialLink'
import savePassword from './account/savePassword'
import getSocialLinks from './account/getSocialLinks'
import getBillingInformation from './billing/getBillingInformation'
import saveBillingInformation from './billing/saveBillingInformation'
import saveArticleLocking from './website/saveArticleLocking'
import getMyArticleLockingSettings from './website/getMyArticleLockingSettings'
import saveWebsiteOptions from './website/saveWebsiteOptions'
import getMyDomains from './website/getMyDomains'
import getUsername from './landingPages/getUsername'
import saveUsername from './landingPages/saveUsername'
import saveDefaultLandingPage from './landingPages/saveDefaultLandingPage'
import saveDefaultPaymentOffer from './landingPages/saveDefaultPaymentOffer'
import domainsHealthCheck from './website/domainsHealthCheck'
import doubleOptIn from './signup/doubleOptIn'
import updateRestrictedAccess from './signup/updateRestrictedAccess'
import getDoubleOptIn from './signup/getDoubleOptIn'
import getRestrictedAccess from './signup/getRestrictedAccess'
import getMyAppearance from './appearance/getMyAppearance'
import saveMyAppearance from './appearance/saveMyAppearance'
import getStaff from './teams/getStaff'
import inviteStaff from './teams/inviteStaff'
import removeStaff from './teams/removeStaff'
import getMyHiddenElements from './website/getMyHiddenElements'
import saveHiddenElements from './website/saveHiddenElements'
import getTaxonomies from './website/getTaxonomies'
import saveTaxonomies from './website/saveTaxonomies'
import updateHelpLink from './appearance/updateHelpLink'
import getFreeAccess from './website/freeAccess/getFreeAccess'
import createFreeAccess from './website/freeAccess/createFreeAccess'
import updateFreeAccess from './website/freeAccess/updateFreeAccess'
import archiveFreeAccess from './website/freeAccess/archiveFreeAccess'
import sortFreeAccessRulesOrder from './website/freeAccess/sortFreeAccessRulesOrder'
import unarchiveFreeAccess from './website/freeAccess/unarchiveFreeAccess'
import saveDefaultOffers from './website/saveDefaultOffers'
import saveArticleStructure from './website/saveArticleStructure'
import getMyContentRestrictionSettings from './website/getMyContentRestrictionSettings'
import saveContentRestriction from './website/saveContentRestriction'
import getAccessTokens from './website/getAccessTokens'
import createAccessToken  from './website/createAccessToken'
import archiveAccessToken from './website/archiveAccessToken'
import updateLoginOptions from './account/updateLoginOptions'
import deleteAccount from './account/deleteAccount'

const sagas = (duck) => ({
    * getAccessTokens() {
        yield getAccessTokens({ ...duck })
    },
    * createAccessToken(payload) {
        yield createAccessToken({ ...duck, ...payload })
    },
    * archiveAccessToken(payload) {
        yield archiveAccessToken({ ...duck, ...payload })
    },
    * getTaxonomies() {
        yield getTaxonomies({ ...duck })
    },
    * updateHelpLink(payload) {
        yield updateHelpLink({ ...duck, ...payload })
    },
    * saveTaxonomies(payload) {
        yield saveTaxonomies({ ...duck, ...payload })
    },
    * getMyAppearance(payload) {
        yield getMyAppearance({ ...payload, ...duck })
    },
    * saveMyAppearance(payload) {
        yield saveMyAppearance({ ...payload, ...duck })
    },
    * getMyBusiness(payload) {
        yield getMyBusiness({ ...payload, ...duck })
    },
    * saveMyBusiness(payload) {
        yield saveMyBusiness({ ...payload, ...duck })
    },
    * getSocialLinks(payload) {
        yield getSocialLinks({ ...payload, ...duck })
    },
    * createSocialLink(payload) {
        yield createSocialLink({ ...payload, ...duck })
    },
    * updateSocialLink(payload) {
        yield updateSocialLink({ ...payload, ...duck })
    },
    * deleteSocialLink(payload) {
        yield deleteSocialLink({ ...payload, ...duck })
    },
    * getMyProfile(payload) {
        yield getMyProfile({ ...payload, ...duck })
    },
    * saveProfile(payload) {
        yield saveProfile({ ...payload, ...duck })
    },
    * getMe(payload) {
        yield getMe({ ...payload, ...duck })
    },
    * savePassword(payload) {
        yield savePassword({ ...payload, ...duck })
    },
    * getBillingInformation(payload) {
        yield getBillingInformation({ ...payload, ...duck })
    },
    * saveBillingInformation(payload) {
        yield saveBillingInformation({ ...payload, ...duck })
    },
    * saveArticleLocking(payload) {
        yield saveArticleLocking({ ...payload, ...duck })
    },
    * getMyArticleLockingSettings(payload) {
        yield getMyArticleLockingSettings({ ...payload, ...duck })
    },
    * saveWebsiteOptions(payload) {
        yield saveWebsiteOptions({ ...payload, ...duck })
    },
    * getMyDomains(payload) {
        yield getMyDomains({ ...payload, ...duck })
    },
    * getUsername(payload) {
        yield getUsername({ ...payload, ...duck })
    },
    * saveUsername(payload) {
        yield saveUsername({ ...payload, ...duck })
    },
    * saveDefaultLandingPage(payload) {
        yield saveDefaultLandingPage({ ...payload, ...duck })
    },
    * saveDefaultPaymentOffer(payload) {
        yield saveDefaultPaymentOffer({ ...payload, ...duck })
    },
    * domainsHealthCheck(payload) {
        yield domainsHealthCheck({ ...payload, ...duck })
    },
    * doubleOptIn(payload) {
        yield doubleOptIn({ ...payload, ...duck })
    },
    * updateRestrictedAccess(payload) {
        yield updateRestrictedAccess({ ...payload, ...duck })
    },
    * getDoubleOptIn(payload) {
        yield getDoubleOptIn({ ...payload, ...duck })
    },
    * getRestrictedAccess(payload) {
        yield getRestrictedAccess({ ...payload, ...duck })
    },
    * getStaff() {
        yield getStaff({ ...duck })
    },
    * inviteStaff(payload) {
        yield inviteStaff({ ...payload, ...duck })
    },
    * removeStaff(payload) {
        yield removeStaff({ ...payload, ...duck })
    },
    * getMyHiddenElements(payload) {
        yield getMyHiddenElements({ ...payload, ...duck })
    },
    * saveHiddenElements(payload) {
        yield saveHiddenElements({ ...payload, ...duck })
    },
    * getFreeAccess() {
        yield getFreeAccess({ ...duck })
    },
    *  createFreeAccess(payload) {
        yield createFreeAccess({ ...payload, ...duck })
    },
    *  updateFreeAccess(payload) {
        yield updateFreeAccess({ ...payload, ...duck })
    },
    *  archiveFreeAccess(payload) {
        yield archiveFreeAccess({ ...payload, ...duck })
    },
    *  unarchiveFreeAccess(payload) {
        yield unarchiveFreeAccess({ ...payload, ...duck })
    },
    * sortFreeAccessRulesOrder(payload){
        yield sortFreeAccessRulesOrder({...payload, ...duck})
    },
    *  saveDefaultOffers(payload) {
        yield  saveDefaultOffers({ ...duck , ...payload})
    },
    * saveArticleStructure(payload) {
        yield saveArticleStructure({ ...payload , ...duck})
    },
    * saveContentRestriction(payload){
        yield saveContentRestriction({...payload, ...duck})
    },
    * getMyContentRestrictionSettings(payload){
        yield getMyContentRestrictionSettings({...payload, ...duck})
    },
    * updateLoginOptions(payload){
        yield updateLoginOptions({...payload, ...duck})
    },
    * deleteAccount(payload) {
        yield deleteAccount({ ...duck, ...payload })
    }
})

export default sagas
