import getBlasts from './getBlasts'
import createBlast from './createBlast'
import getFailedMessages from './getFailedMessages'
import enableMessaging from './enableMessaging'
import updateBlast from './updateBlast'
import {
    blastSent,
    blastErrored
} from './metricEvents'

import {
    getConversation,
    getConversations,
    startConversation,
    createMessage,
    getConversationBatch
} from './conversations'

const sagas = (duck) => ({
    *getBlasts(payload) {
        yield getBlasts({ ...duck, payload })
    },
    *updateBlast(payload) {
        yield updateBlast({ ...duck, payload })
    },
    *createBlast(payload) {
        yield createBlast({ ...duck, payload })
    },
    *getFailedMessages(payload) {
        yield getFailedMessages({ ...duck, payload })
    },
    *getConversation(payload) {
        yield getConversation({ ...duck, payload })
    },
    *getConversations() {
        yield getConversations({ ...duck })
    },
    *getConversationBatch(payload) {
        yield getConversationBatch({ ...duck, payload })
    },
    *startConversation(payload) {
        yield startConversation({ ...duck, payload })
    },
    *createMessage(payload) {
        yield createMessage({ ...duck, payload })
    },
    *enableMessaging(payload) {
        yield enableMessaging({ ...duck, payload })
    },
    *blastSent({ payload }) {
        yield blastSent({...duck, payload })
    },
    *blastErrored() {
        yield blastErrored({ ...duck })
    },
})

export default sagas
