/** @namespace FeatureManager.fetchApps */
import { createAsyncSaga, name, endpoints } from '../dependencies'

const fetchApps = createAsyncSaga(
    `${name}/fetchApps`,
    function* ({
        payload: { publisherId = null } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            const apps = yield request({
                method: 'get',
                url: endpoints.APPS,
                headers: {
                    publisherid: publisherId
                },
            })

            // find the app with the platform-access label
            // if none of it's tiers have a has_access value of true
            // then overwrite the tier with a label of "tier-00" by giving it a has_access value of true
            // this is to allow the user to have access to the platform
            // without having to have access to any of the tiers

            const platformAccessApp = apps.find((app) => app.label === 'platform-access')
            if (platformAccessApp) {
                const platformAccessTier = platformAccessApp.tiers.find((tier) => tier.label === 'tier-00')
                if (platformAccessTier) {
                    const hasAccess = platformAccessApp.tiers.some((tier) => tier.has_access)
                    if (!hasAccess) {
                        platformAccessTier.has_access = true
                    }
                }
            }

            yield fulfilled(apps)
        } catch (error) {
            yield rejected({
                error: error.message,
            })
            // making this a log because we can end up in an infinite loop for some reason
            console.log(error)
        }
    }
)

export default fetchApps
