import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import usePublisherId from 'hooks/usePublisherId'

import {
    fetchCards,
    cards,
    activeEditionId,
    activeCard,
    activeCardId,
    setActiveEditionId,
    setActiveCardId,
    activeEditionCards,
    updateTag,
    fetchTagsByCreator,
    getTagsByCreator,
    countTagsByCreator,
    createTag,
    updateTagError,
    countActiveTagsByCreator,
    getMaxTrialLengthByCreator,
    selectById,
    fetchNoEditionTags,
    noEditionTags,
} from './slice'

import { isGodmode } from './dependencies'

const useCards = (fetch = false) => {
    const _isGodmode = useSelector(isGodmode)

    const state = useSelector((state) => state)
    const dispatch = useDispatch()
    const publisherId = usePublisherId()

    const _activeEditionId = useSelector(activeEditionId)
    const _cards = useSelector(cards)
    const _activeEditionCards = useSelector(activeEditionCards)
    const _activeCard = useSelector(activeCard)
    const _activeCardId = useSelector(activeCardId)
    const _getTagsByCreator = useSelector((state) => getTagsByCreator(state, publisherId))
    const _countTagsByCreator = useSelector((state) => countTagsByCreator(state, publisherId))
    const _updateTagError = useSelector(updateTagError)
    const _activeCardCount = useSelector((state) => countActiveTagsByCreator(state, publisherId))
    const _maxTrialLength = useSelector((state) => getMaxTrialLengthByCreator(state, publisherId))
    const _noEditionTags = useSelector(noEditionTags)

    const _setActiveEditionId = useCallback((editionId) => {
        dispatch(setActiveEditionId(editionId))
    }, [dispatch])

    const _setActiveCardId = useCallback((cardId) => {
        dispatch(setActiveCardId(cardId))
    }, [dispatch])

    useEffect(() => {
        if (!_activeEditionId && _isGodmode) {

            dispatch(fetchNoEditionTags({ publisherId }))
            
            return
        }

        if (!fetch) {
            return
        }

        dispatch(fetchCards({ publisherId, editionId: _activeEditionId }))
    }, [
        fetch,
        dispatch,
        publisherId,
        _activeEditionId,
        _isGodmode,
    ])

    const _markAsShipped = useCallback((tagId) => {
        const shippedAt = new Date().getTime()

        dispatch(updateTag({
            publisherId,
            tagId,
            shippedAt,
        }))
    }, [
        dispatch,
        publisherId,
    ])

    const _deliveryFailed = useCallback((tagId, sendDeliveryFailedEmail = false) => {
        // launch confirmation popup
        dispatch(updateTag({
            publisherId,
            tagId,
            deliveryFailed: true,
            sendDeliveryFailedEmail,
        }))
    }, [
        dispatch,
        publisherId,
    ])

    const _addressUpdated = useCallback((tagId) => {
        dispatch(updateTag({
            publisherId,
            tagId,
            addressUpdated: true,
        }))
    }, [
        dispatch,
        publisherId,
    ])

    const _activate = useCallback((tagId) => {
        dispatch(updateTag({
            publisherId,
            tagId,
            activate: true,
        }))
    }, [
        dispatch,
        publisherId,
    ])

    const _deactivate = useCallback((tagId) => {
        dispatch(updateTag({
            publisherId,
            tagId,
            deactivate: true,
        }))
    }, [
        dispatch,
        publisherId,
    ])

    const _changeOwner = useCallback(({ tagId, newOwnerId }) => {
        if (newOwnerId === null) {
            dispatch(updateTag({
                publisherId,
                tagId,
                removeOwner: true,
            }))
        } else {
            dispatch(updateTag({
                publisherId,
                tagId,
                newOwnerId,
            }))
        }
    }, [
        dispatch,
        publisherId,
    ])

    const _setTagForm = useCallback(({ tagId, formId }) => {
        dispatch(updateTag({
            publisherId,
            tagId,
            formId,
        }))
    }, [
        dispatch,
        publisherId,
    ])

    const _reloadEdition = useCallback(() => {
        if (!_activeEditionId) {
            return
        }

        dispatch(fetchCards({ publisherId, editionId: _activeEditionId }))
    }, [
        dispatch,
        publisherId,
        _activeEditionId,
    ])

    const _fetchTagsByCreator = useCallback(() => {
        dispatch(fetchTagsByCreator({ publisherId }))
    }, [
        dispatch,
        publisherId,
    ])

    const _openStripeCustomer = useCallback((customerId) => {
        const isProd = process.env.NODE_ENV === 'production'

        const stripeBaseUrl = isProd ? 'https://dashboard.stripe.com/customers' : 'https://dashboard.stripe.com/test/customers'
        
        window.open(`${stripeBaseUrl}/${customerId}`, '_blank')
    }, [])

    const _updateTagEngraving = useCallback(({ tagId, engraving }) => {
        dispatch(updateTag({
            publisherId,
            tagId,
            engraving,
        }))
    }, [
        dispatch,
        publisherId,
    ])

    const _updateTagName = useCallback(({ tagId, tagName }) => {
        dispatch(updateTag({
            publisherId,
            tagId,
            tagName,
        }))
    }, [
        dispatch,
        publisherId,
    ])

    const _createTag = useCallback(({
        editionId,
        count = 1,
    }) => {
        dispatch(createTag({
            publisherId,
            editionId,
            count,
        }))
    }, [
        dispatch,
        publisherId,
    ])

    const _setTagCustomUrl = useCallback(({
        tagId,
        customUrl,
        requestToken = null,
    }) => {
        dispatch(updateTag({
            publisherId,
            tagId,
            customUrl,
            requestToken,
        }))
    }, [
        dispatch,
        publisherId,
    ])

    const _fetchNoEditionTags = useCallback(() => {
        dispatch(fetchNoEditionTags({ publisherId }))
    }, [
        dispatch,
        publisherId,
    ])

    const _selectById = useCallback((tagId) => selectById(state, tagId), [state])

    const _markAsPackagedWithOrderNumber = useCallback(({
        tagId,
        orderNumber,
        packagedAt,
    }) => {
        dispatch(updateTag({
            tagId,
            orderNumber,
            packagedAt,
            publisherId,
        }))
    }, [
        dispatch,
        publisherId,
    ])


    return {
        cards: _cards,
        activeCard: _activeCard,
        activeEditionId: _activeEditionId,
        setActiveEditionId: _setActiveEditionId,
        setActiveCardId: _setActiveCardId,
        activeEditionCards: _activeEditionCards,
        markAsShipped: _markAsShipped,
        activeCardId: _activeCardId,
        activate: _activate,
        deactivate: _deactivate,
        deliveryFailed: _deliveryFailed,
        addressUpdated: _addressUpdated,
        openStripeCustomer: _openStripeCustomer,
        reloadEdition: _reloadEdition,
        changeOwner: _changeOwner,
        fetchTagsByCreator: _fetchTagsByCreator,
        getTagsByCreator: _getTagsByCreator,
        setTagForm: _setTagForm,
        countTagsByCreator: _countTagsByCreator,
        createTag: _createTag,
        updateTagEngraving: _updateTagEngraving,
        updateTagName: _updateTagName,
        setTagCustomUrl: _setTagCustomUrl,
        updateTagError: _updateTagError,
        activeCardCount: _activeCardCount,
        maxTrialLength: _maxTrialLength,
        selectById: _selectById,
        fetchNoEditionTags: _fetchNoEditionTags,
        noEditionTags: _noEditionTags,
        markAsPackagedWithOrderNumber: _markAsPackagedWithOrderNumber,
    }
}

export default useCards
