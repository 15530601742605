import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import usePublisherId from 'hooks/usePublisherId'

import { fetchEditions, editions, selectById, createEdition, updateEdition } from './slice'

const useEditions = (fetch = false) => {
    const dispatch = useDispatch()
    const publisherId = usePublisherId()

    const _editions = useSelector(editions)

    const state = useSelector((state) => state)

    const _getEditionById = useCallback((id) => {
        return selectById(state, id)
    }, [state])

    const _createEdition = useCallback(({
        edition_id,
        shopifySku,
        name,
        type,
        color,
        trial,
        engravable,
        salesChannel,
    }) => {
        dispatch(createEdition({
            publisherId,
            edition_id,
            shopifySku,
            name,
            type,
            color,
            trial,
            engravable,
            salesChannel,
        }))
    }, [
        dispatch,
        publisherId,
    ])

    const _updateEdition = useCallback(({
        editionId,
        shopifySku,
        name,
        type,
        color,
        trial,
        engravable,
        salesChannel,
    }) => {
        dispatch(updateEdition({
            publisherId,
            editionId,
            shopifySku,
            name,
            type,
            color,
            trial,
            engravable,
            salesChannel,
        }))
    }, [
        dispatch,
        publisherId,
    ])
    useEffect(() => {
        if (!fetch) {
            return
        }

        dispatch(fetchEditions({ publisherId }))
    }, [
        fetch,
        dispatch,
        publisherId,
    ])

    return {
        editions: _editions,
        getEditionById: _getEditionById,
        createEdition: _createEdition,
        updateEdition: _updateEdition,
    }
}

export default useEditions
