import produce from 'immer'
import _ from 'lodash'

export const initialState = {
    actionPending: false,
    actionReady: true,
    actionFinished: false,
    modals: {}
}

const reducer = (state, { type, payload }, { types }) => {
    return produce(state, (draftState) => {
        switch (type) {
            case types.OPEN_MODAL:
                draftState.modals[payload.key] = false
                draftState.actionPending = true
                draftState.actionReady = false
                draftState.actionFinished = false
                break

            case types.OPEN_MODAL_SUCCESS:
                draftState.modals[payload.key] = true
                draftState.actionPending = false
                draftState.actionReady = true
                draftState.actionFinished = false
                break

            case types.CLOSE_MODAL: {
                draftState.actionFinished = !!payload.actionFinished
                draftState.actionReady = true
                if (payload.key) {
                    draftState.modals[payload.key] = false
                } else {
                    draftState.modals = _.mapValues(draftState.modals, () => false)
                }
                break

            }
            default:
                return draftState
        }
    })
}

export default reducer
