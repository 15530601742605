import { call } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, getPublisherId } from '../dependencies'

const updateSegment = createAsyncSaga(
    `${name}/updateSegment`,
    function* ({
        payload: {
            filters = null,
            name = null,
            id = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const publisherId = yield call(getPublisherId)

            const response = yield request({
                method: 'put',
                url: endpoints.SEGMENTS + `/${id}`,
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    id,
                    filters: JSON.stringify(filters),    
                    name
                }

            })

            
            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error.message,
            })
        }
    }
)

export default updateSegment
