import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'
import MessagesModule from 'modules/messages'
import account from 'modules/account'

const getPublisherId = account.selectors.getPublisherId
const name = 'composeBlast'

export {
    name,
    getPublisherId,
    endpoints,
    createAsyncSaga,
    MessagesModule
}
