import {
    initialState,
    reducer,
    takes,
} from './slice'

import usePacks from './usePacks'

export default {
    initialState,
    reducer,
    takes,
}

export {
    usePacks,
}
