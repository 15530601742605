import { put, select, putResolve } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'

import AccountModule from 'modules/account'

function* checkEditProduct({ types, payload }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { status, data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'GET',
                url: endpoints.PRODUCT_EDIT_APPROVE,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...payload,
                }
            }
        })

        if (status === 200) {
            yield put({
                type: types.CHECK_EDIT_PRODUCT_SUCCESS,
                payload: data.body.effected_user_count
            })

        } else {
            throw Error('An uncatched error occured.')
        }
    } catch (error) {
        yield put({ type: types.CHECK_EDIT_PRODUCT_ERROR, error })
    }
}

export default checkEditProduct
