import reducer, { initialState } from './reducer'
import actions from './actions'
import createDuck from '../baseDuck'
import sagas from './sagas'
import takes from './takes'
import selectors from './selectors'

export default createDuck({
    store: 'newsletters',
    initialState,
    path: '/newsletters'
}).extend({
    types: actions.types,
    reducer,
    creators: actions.creators,
    sagas,
    takes,
    selectors
})
