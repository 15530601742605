import produce from 'immer'
import { omit } from 'lodash'

export const initialState = {
    status: 'INITIATED',
    error: false,
    properties: [{
        section: 'Profile',
        value: 'address',
        label: 'Address',
        filterType: 'address',
        type: 'address'
    },
    {
        section: 'Profile',
        value: 'birthday',
        label: 'Birthday',
        filterType: 'date',
        type: 'date',
    },
    {
        section: 'Profile',
        value: 'first_name',
        label: 'First Name',
        filterType: 'short_answer',
        type: 'short_answer',
    },
    {
        section: 'Profile',
        value: 'gender',
        label: 'Gender',
        filterType: 'short_answer',
        type: 'short_answer'

    },
    {
        section: 'Profile',
        value: 'last_name',
        label: 'Last Name',
        filterType: 'short_answer',
        type: 'short_answer',
    },
    {
        section: 'Profile',
        value: 'phone_number',
        label: 'Phone Number',
        filterType: 'phone_number',
        type: 'phone',
    }]
}

const reducer = (state, action, { types, statuses }) =>
    produce(state, (draftState) => {
        switch (action.type) {

            case types.UPDATE_CUSTOM_PROPERTIES:
                draftState.status = statuses.LOADING
                draftState.error = false
                break

            case types.UPDATE_CUSTOM_PROPERTIES_SUCCESS:
                if (action.payload.isNew) {
                    // remove all isNew props from previously added ones
                    draftState.properties = state.properties.map(prop => omit(prop, 'isNew'))
                    draftState.properties.push({
                        ...action.payload,
                        value: action.payload.id,
                        section: 'Custom'
                    })
                } else {
                    const index = draftState.properties.findIndex((property) => property.id === action.payload.id)
                    draftState.properties[index] = {
                        ...action.payload,
                        section: 'Custom'
                    }
                }
                draftState.status = statuses.READY

                break

            case types.UPDATE_CUSTOM_PROPERTIES_ERROR:
                draftState.status = statuses.READY
                draftState.error = action.error
                break

            case types.DELETE_CUSTOM_PROPERTIES:
                draftState.status = statuses.LOADING
                draftState.error = false
                break

            case types.DELETE_CUSTOM_PROPERTIES_SUCCESS:
                const index = draftState.properties.findIndex((property) => property.id === action.payload)
                draftState.properties.splice(index, 1)
                draftState.status = statuses.READY
                break

            case types.DELETE_CUSTOM_PROPERTIES_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = action.error
                break

            case types.GET_PROPERTIES:
                draftState.status = statuses.LOADING
                draftState.error = false
                break

            case types.GET_PROPERTIES_SUCCESS:
                draftState.status = statuses.READY
                draftState.properties = [...initialState.properties, ...action.payload.payload]
                break

            case types.GET_PROPERTIES_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = action.error
                break

            default:
                return state
        }
    })

export default reducer
