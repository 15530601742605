import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

import confirmOneTimeCode from './confirmOneTimeCode'
import requestOneTimeCode from './requestOneTimeCode'

const initialState = {
    oneTimeCodeInProgress: false,
    confirmOneTimeCodeInProgress: false,
    oneTimeCodeSent: false,
    oneTimeCodeError: null,
    oneTimeCodeConfirmed: null,
    info: null,
    claim: null
}

const verifyWithOtpSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetOTP: (draftState: any) => {
            draftState.info = null
            draftState.oneTimeCodeInProgress = false
            draftState.oneTimeCodeSent = false
            draftState.oneTimeCodeError = null
            draftState.oneTimeCodeConfirmed = null
        }
    },
    extraReducers: builder => {
        builder.addCase(requestOneTimeCode.pending, (state) => {
            state.oneTimeCodeInProgress = true
            state.oneTimeCodeError = null
            state.oneTimeCodeConfirmed = null
        })
        .addCase(requestOneTimeCode.fulfilled, (state: any, { payload }: any) => {
            state.info = payload
            state.oneTimeCodeSent = true
            state.oneTimeCodeInProgress = false
            state.oneTimeCodeError = null
            state.oneTimeCodeConfirmed = null
        })
        .addCase(requestOneTimeCode.rejected, (state: any, { payload: error }: any) => {
            state.oneTimeCodeSent = null
            state.oneTimeCodeInProgress = false
            state.oneTimeCodeError = error
            state.oneTimeCodeConfirmed = null
        })
        .addCase(confirmOneTimeCode.pending, (state) => {
            state.oneTimeCodeConfirmed = null
            state.confirmOneTimeCodeInProgress = true
            state.oneTimeCodeError = null
        })
        .addCase(confirmOneTimeCode.fulfilled, (state: any, { payload }: any) => {
            state.claim = payload?.claim
            state.oneTimeCodeSent = false
            state.confirmOneTimeCodeInProgress = false
            state.oneTimeCodeError = null
            state.oneTimeCodeConfirmed = true
        })
        .addCase(confirmOneTimeCode.rejected, (state: any, { payload: error }: any) => {
            state.confirmOneTimeCodeInProgress = false
            state.oneTimeCodeError = error
            state.oneTimeCodeConfirmed = false
        })
    },
})

// SELECTORS
const getVerifyWithOtp = (state: any) => state[name]
const oneTimeCodeSent = createDraftSafeSelector(
    getVerifyWithOtp,
    (verifyWithOtp: any) => verifyWithOtp?.oneTimeCodeSent,
)
const oneTimeCodeError = createDraftSafeSelector(
    getVerifyWithOtp,
    (verifyWithOtp: any) => verifyWithOtp?.oneTimeCodeError,
)
const oneTimeCodeConfirmed = createDraftSafeSelector(
    getVerifyWithOtp,
    (verifyWithOtp: any) => verifyWithOtp?.oneTimeCodeConfirmed,
)
const oneTimeCodeInProgress = createDraftSafeSelector(
    getVerifyWithOtp,
    (verifyWithOtp: any) => verifyWithOtp?.oneTimeCodeInProgress,
)
const confirmOneTimeCodeInProgress = createDraftSafeSelector(
    getVerifyWithOtp,
    (verifyWithOtp: any) => verifyWithOtp?.confirmOneTimeCodeInProgress,
)

// GENERAL

const verifyWithOtpInitialState = {
    [name]: initialState,
}

const verifyWithOtpReducer = {
    [name]: verifyWithOtpSlice.reducer,
}

const verifyWithOtpActions = {
    ...verifyWithOtpSlice.actions
}

// EXPORTS
export default verifyWithOtpSlice.reducer

export {
    verifyWithOtpInitialState as initialState,
    verifyWithOtpReducer as reducer,
    verifyWithOtpActions as actions,

    requestOneTimeCode,
    confirmOneTimeCode,
    
    oneTimeCodeSent,
    oneTimeCodeError,
    oneTimeCodeInProgress,
    oneTimeCodeConfirmed,
    confirmOneTimeCodeInProgress,
}
