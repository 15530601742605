import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { name, getPublisherId, isGodmode, addNotification } from '../dependencies'

import fetchCards from './fetchCards'
import updateTag from './updateTag'
import fetchCardById from './fetchCardById'
import fetchTagsByCreator from './fetchTagsByCreator'
import fetchNoEditionTags from './fetchNoEditionTags'
import createTag from './createTag'

const cardsAdapter = createEntityAdapter({
    selectId: (card: any) => card?.id,
    sortComparer: (a: any, b: any) => a?.id - b?.id,
})

const initialState = cardsAdapter.getInitialState({
    fetchCardsError: null,
    fetchCardsInProgress: false,
    activeEditionId: null,
    activeCardId: null,

    updateTagSuccess: null,
    updateTagInProgress: false,
    updateTagError: null,

    fetchCardByIdSuccess: null,
    fetchCardByIdInProgress: false,
    fetchCardByIdError: null,

    fetchTagsByCreatorSuccess: null,
    fetchTagsByCreatorInProgress: false,
    fetchTagsByCreatorError: null,

    createTagSuccess: null,
    createTagInProgress: false,
    createTagError: null,

    fetchNoEditionTagsSuccess: null,
    fetchNoEditionTagsInProgress: false,
    fetchNoEditionTagsError: null,
})

const cardsSlice = createSlice({
    name,
    initialState,
    reducers: {
        setActiveEditionId: (state: any, { payload }: any) => {
            state.activeEditionId = payload
            state.activeCardId = null
        },
        setActiveCardId: (state: any, { payload }: any) => {
            state.activeCardId = payload
        },
    },
    extraReducers: {
        [fetchCards.pending]: (state: any, { payload }: any) => {
            state.fetchCardsInProgress = true
            state.fetchCardsError = null
        },
        [fetchCards.fulfilled]: (state: any, { payload }: any) => {
            cardsAdapter.upsertMany(state, payload?.cards || [])
            state.fetchCardsInProgress = false
            state.fetchCardsError = null

        },
        [fetchCards.rejected]: (state: any, { payload: error }: any) => {
            state.fetchCardsInProgress = false
            state.fetchCardsError = error
        },

        [updateTag.pending]: (state: any, { payload }: any) => {
            state.updateTagSuccess = null
            state.updateTagInProgress = true
            state.updateTagError = null
        },
        [updateTag.fulfilled]: (state: any, { payload }: any) => {
            cardsAdapter.upsertOne(state, payload?.tag || {})
            state.updateTagSuccess = true
            state.updateTagInProgress = false
            state.updateTagError = null

        },
        [updateTag.rejected]: (state: any, { payload: error }: any) => {
            state.updateTagSuccess = false
            state.updateTagInProgress = false
            state.updateTagError = error
        },

        [fetchCardById.pending]: (state: any, { payload }: any) => {
            state.fetchCardByIdSuccess = null
            state.fetchCardByIdInProgress = true
            state.fetchCardByIdError = null
        },
        [fetchCardById.fulfilled]: (state: any, { payload }: any) => {
            cardsAdapter.upsertOne(state, payload?.card || {})
            state.fetchCardByIdSuccess = true
            state.fetchCardByIdInProgress = false
            state.fetchCardByIdError = null

        },
        [fetchCardById.rejected]: (state: any, { payload: error }: any) => {
            state.fetchCardByIdSuccess = false
            state.fetchCardByIdInProgress = false
            state.fetchCardByIdError = error
        },

        [fetchTagsByCreator.pending]: (state: any, { payload }: any) => {
            state.fetchTagsByCreatorSuccess = null
            state.fetchTagsByCreatorInProgress = true
            state.fetchTagsByCreatorError = null
        },
        [fetchTagsByCreator.fulfilled]: (state: any, { payload }: any) => {
            cardsAdapter.upsertMany(state, payload?.tags || [])
            state.fetchTagsByCreatorSuccess = true
            state.fetchTagsByCreatorInProgress = false
            state.fetchTagsByCreatorError = null
        },
        [fetchTagsByCreator.rejected]: (state: any, { payload: error }: any) => {
            state.fetchTagsByCreatorSuccess = false
            state.fetchTagsByCreatorInProgress = false
            state.fetchTagsByCreatorError = error
        },

        [createTag.pending]: (state: any, { payload }: any) => {
            state.createTagSuccess = null
            state.createTagInProgress = true
            state.createTagError = null
        },
        [createTag.fulfilled]: (state: any, { payload }: any) => {
            state.createTagSuccess = true
            state.createTagInProgress = false
            state.createTagError = null
        },
        [createTag.rejected]: (state: any, { payload: error }: any) => {
            state.createTagSuccess = false
            state.createTagInProgress = false
            state.createTagError = error
        },

        [fetchNoEditionTags.pending]: (state: any, { payload }: any) => {
            state.fetchNoEditionTagsSuccess = null
            state.fetchNoEditionTagsInProgress = true
            state.fetchNoEditionTagsError = null
        },
        [fetchNoEditionTags.fulfilled]: (state: any, { payload }: any) => {
            cardsAdapter.upsertMany(state, payload?.tags || [])
            state.fetchNoEditionTagsSuccess = true
            state.fetchNoEditionTagsInProgress = false
            state.fetchNoEditionTagsError = null
        },
        [fetchNoEditionTags.rejected]: (state: any, { payload: error }: any) => {
            state.fetchNoEditionTagsSuccess = false
            state.fetchNoEditionTagsInProgress = false
            state.fetchNoEditionTagsError = error
        },
    },
})

const { setActiveEditionId, setActiveCardId } = cardsSlice.actions

// SELECTORS
const {
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
} = cardsAdapter.getSelectors((state: any) => state[name])

const getSelf = (state) => state[name]

const activeEditionId = createDraftSafeSelector(
    getSelf,
    (self: any) => self?.activeEditionId,
)

const activeCardId = createDraftSafeSelector(
    getSelf,
    (self: any) => self?.activeCardId,
)

const activeEditionCards = createDraftSafeSelector(
    [
        activeEditionId,
        selectAll,
    ],
    (activeEditionId, cards) => {
        return Object
            .values(cards)
            .filter((card: any) => card.editionId === activeEditionId)
    }
)

const noEditionTags = createDraftSafeSelector(
    selectAll,
    (cards) => {
        return Object
            .values(cards)
            .filter((card: any) => !card.editionId)
    }
)

const getTagsByCreator = createDraftSafeSelector(
    [
        selectAll,
        (state, publisherId) => publisherId,
    ],
    (cards, publisherId) => {
        return cards
            ?.filter((card: any) => card.publisherId === publisherId) || []
    },
)

const getActiveTagsByCreator = createDraftSafeSelector(
    [
        getTagsByCreator,
    ],
    (cards) => {
        return cards
            ?.filter((card: any) => card.deactivatedAt === null) || []
    },
)

const countActiveTagsByCreator = createDraftSafeSelector(
    [
        getActiveTagsByCreator,
    ],
    (cards) => cards?.length,
)

const getMaxTrialLengthByCreator = createDraftSafeSelector(
    [
        getActiveTagsByCreator,
    ],
    (cards) => {
        const maxTrialLength = cards.map((card: any) => card.edition.trial).sort((a, b) => b - a)[0]
        return maxTrialLength
    },
)
const countTagsByCreator = createDraftSafeSelector(
    [
        getTagsByCreator,
    ],
    (cards) => cards?.length,
)

// use selectById and activeCardId to get the active card
const activeCard = createDraftSafeSelector(
    activeCardId,
    (state) => state,
    (_activeCardId: string, state) => {
        return selectById(state, _activeCardId)
    },
)

const updateTagError = createDraftSafeSelector(
    getSelf,
    (self: any) => self?.updateTagError,
)

// GENERAL
const cardsInitialState = {
    [name]: initialState,
}

const cardsReducer = {
    [name]: cardsSlice.reducer,
}

function* refetchCards ({ payload }: any): any {
    const { editionId } = payload
    const publisherId = yield select(getPublisherId)


    const _isGodmode = yield select(isGodmode)

    yield put(
        yield call(fetchTagsByCreator, {
            publisherId,
        })
    )

    if (_isGodmode) {
        yield put(
            yield call(fetchCards, {
                publisherId,
                editionId,
            })
        )
    }

    yield put(
        yield call(addNotification, {
            message: `Card updated!`,
            type: 'success'
        })
    )
}

const takes = [
    ...fetchCards.takes,
    ...updateTag.takes,
    ...fetchCardById.takes,
    ...fetchTagsByCreator.takes,
    ...createTag.takes,
    ...fetchNoEditionTags.takes,
    takeEvery(updateTag.fulfilled, refetchCards),
    takeEvery(createTag.fulfilled, refetchCards),
]

// EXPORTS
export default cardsSlice.reducer

export {
    cardsInitialState as initialState,
    cardsReducer as reducer,
    takes,

    selectAll as cards,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
    activeEditionId,

    fetchCardById,
    fetchCards,
    updateTag,
    setActiveEditionId,
    setActiveCardId,
    activeEditionCards,
    activeCard,
    activeCardId,
    fetchTagsByCreator,
    getTagsByCreator,
    countTagsByCreator,
    createTag,
    updateTagError,
    getActiveTagsByCreator,
    countActiveTagsByCreator,
    getMaxTrialLengthByCreator,
    fetchNoEditionTags,
    noEditionTags,
}
