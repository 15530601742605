import React, { useCallback } from 'react'
import cx from 'classnames'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Overflow from '../../Overflow'
import ModalModule from 'modules/modal'
import UsersModule from 'modules/users'
import SettingsModule from 'modules/settings'

import styles from './Profile.module.scss'
import addTestId from 'utils/lib/addTestId'

import EmailReceipt from 'containers/Contacts/Modals/EmailReceipt'
import DeleteContact from 'containers/Contacts/Modals/DeleteContact'
import ViewSiteAsUser from '../../../containers/Contacts/Modals/ViewSiteAsUser'
import AccessControl from 'components/AccessControl'
import { useFeatureManager, constants } from 'features/FeatureManager'

import logger from 'lib/logger'
import AccountModule from 'modules/account'
import usePopup from 'hooks/usePopup'
import { PhoneNumberRequiredNotify } from 'features/userImport'

const openVcard = ({
    vcfString = '',
    fileName = '',
}) => {
    try {
        const blob = new Blob([vcfString], {type: 'text/x-vcard'})
        const downloadUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = downloadUrl
        a.download = fileName
        document.body.appendChild(a)
        a.click()
    } catch (error) {
        logger.error('openVcard error', {}, error)
    }

}

const getEmailNotificationButtonStatus = (user) => {
    return user?.imported_user &&
        user?.email.value &&
        user?.email.opt_in &&
        !user?.email.opt_in_notified
}
const getPhoneNotificationButtonStatus = (user) => {
    return user?.imported_user &&
        user?.phone.value &&
        !user?.phone.opt_in &&
        !user?.phone.opt_in_notified
}

const SettingsOverflow = ({ selection }) => {
    const dispatch = useDispatch()
    const showMenuOptions = useSelector(UsersModule.selectors.getMenuOptions)
    const _getUserType = useSelector(SettingsModule.selectors.getUserType)
    const _getSingleUser = useSelector(UsersModule.selectors.getSingleUser)
    const singleUserId = _getSingleUser?.id
    const _getSingleUserVcard = useSelector(UsersModule.selectors.getSingleUserVcard)
    const hasPhoneNumber = useSelector(AccountModule.selectors.hasPhoneNumber)

    const { hasFeature } = useFeatureManager()
    const hasHypePlugin = hasFeature(constants.GADGET_ACCESS_FEATURE) || hasFeature(constants.WIDGET_FEATURE)

    const godMode = _getUserType === 'god'

    const onClickSendReceipt = useCallback(() => {
        dispatch(UsersModule.creators.fetchSelectedUsers(selection))
        dispatch(ModalModule.creators.openModal({ key: 'emailReceipt' }))
    }, [dispatch, selection])

    const onClickLoginAsUser = useCallback(() => {
        dispatch(ModalModule.creators.openModal({ key: 'viewSiteAsUser' }))
    }, [dispatch])

    const onClickReindexUser = useCallback(() => {
        dispatch(UsersModule.creators.reindexUser({ user_state_id: selection[0] }))
    }, [dispatch, selection])

    const onClickLogUserOut = useCallback(() => {
        dispatch(UsersModule.creators.logoutUser())
    }, [dispatch])

    const onClickSaveContactInfo = useCallback(() => {
        if (!singleUserId || !_getSingleUserVcard) {
            return
        }

        openVcard({
            vcfString: _getSingleUserVcard,
            fileName: `${singleUserId}.vcf`
        })
    }, [_getSingleUserVcard, singleUserId])

    const onClickDeleteContacts = useCallback(() => {
        dispatch(UsersModule.creators.fetchSelectedUsers(selection))
        dispatch(ModalModule.creators.openModal({
            key: 'deleteAction'
        }))
    }, [dispatch, selection])

    const showEmailNotificationButton = getEmailNotificationButtonStatus(_getSingleUser)
    const showTextNotificationButton = getPhoneNotificationButtonStatus(_getSingleUser)

    const { setModalOpen, Popup: PhoneNumberRequiredNotifyPopup } = usePopup()
    const phoneNumberRequiredNotifyModalClosed = useCallback(() => {
        setModalOpen(false)
    }, [setModalOpen])

    const notifyUserImport = useCallback(({ notify_email, notify_phone }) => {
        if (notify_phone) {
            !hasPhoneNumber && setModalOpen(true)
            hasPhoneNumber && dispatch(UsersModule.creators.notifyUserImport({ single: true, notify_email: false, notify_phone, user_state_id: _getSingleUser?.user_state_id }))
        }
        if (notify_email) {
            dispatch(UsersModule.creators.notifyUserImport({ single: true, notify_email, notify_phone: false, user_state_id: _getSingleUser?.user_state_id }))
        }
    }, [dispatch, _getSingleUser, hasPhoneNumber, setModalOpen])

    return (
        <React.Fragment>
            <PhoneNumberRequiredNotifyPopup
                onAfterClose={phoneNumberRequiredNotifyModalClosed}
            >
                <PhoneNumberRequiredNotify afterRedirect={phoneNumberRequiredNotifyModalClosed} cancel={phoneNumberRequiredNotifyModalClosed}/>
            </PhoneNumberRequiredNotifyPopup>
            <DeleteContact selectedUsers={selection} />
            <AccessControl
                requiredFeatures={[constants.GADGET_ACCESS_FEATURE, constants.WIDGET_FEATURE]}
            >
                <EmailReceipt selectedUsers={selection} />
            </AccessControl>

            <ViewSiteAsUser action={(values) => UsersModule.creators.loginAsUser(values)} />
            <Overflow
                className={styles.unbold}
                menuOpenPosition="left"
                icon={<span className={cx(styles.icon, 'icon-icon_overflow')} />}
                data-testid={addTestId('profile-settings-overflow')}
            >
                {showEmailNotificationButton && (
                    <Overflow.Option onClick={() => notifyUserImport({ notify_email: true })}
                                     icon={<span className={cx(styles.icon, 'icon-icon_add_email')} />}>
                        Send email subscription notification
                    </Overflow.Option>
                )}
                {showTextNotificationButton && (
                    <Overflow.Option className={!hasPhoneNumber ? styles.disabled : ''} onClick={() => notifyUserImport({ notify_phone: true })}
                                     icon={<span className={cx(styles.icon, 'icon-icon_add_email')} />}>
                        Send text subscription notification
                    </Overflow.Option>
                )}
                {showMenuOptions.sendEmailReceipt && (
                    <AccessControl
                        requiredFeatures={[constants.GADGET_ACCESS_FEATURE, constants.WIDGET_FEATURE]}
                    >
                        <Overflow.Option
                            onClick={onClickSendReceipt}
                            icon={<span className={cx(styles.icon, 'icon-icon_newsletter')} />}
                            className={cx(styles.withIcon)}
                            data-testid={addTestId('manage-menu-send-receipt-button')}
                        >
                            Send Receipt
                        </Overflow.Option>
                    </AccessControl>
                )}

                {godMode && (
                    <React.Fragment>
                        <Overflow.Option
                            onClick={onClickLoginAsUser}
                            icon={<span className={cx(styles.icon, 'icon-explore')} />}
                            className={cx(styles.withIcon)}
                        >
                            Log in as a user
                        </Overflow.Option>
                    </React.Fragment>
                )}

                {godMode && (
                    <React.Fragment>
                        <Overflow.Option
                            onClick={onClickReindexUser}
                            icon={<span className={cx(styles.icon, 'icon-icon_reload')} />}
                            className={cx(styles.withIcon)}
                        >
                            Re-index user
                        </Overflow.Option>
                    </React.Fragment>
                )}

                {hasHypePlugin && <React.Fragment>
                    <Overflow.Option
                        icon={<span className={cx(styles.icon, 'icon-exit')} />}
                        className={cx(styles.withIcon)}
                        onClick={onClickLogUserOut}
                    >
                        Log user out
                    </Overflow.Option>
                </React.Fragment>}
                <React.Fragment>
                    <Overflow.Option
                        icon={<span className={cx(styles.icon, 'icon-icon_save_to_phone')} />}
                        className={cx(styles.withIcon)}
                        onClick={onClickSaveContactInfo}
                    >
                        Save contact info
                    </Overflow.Option>
                </React.Fragment>

                {showMenuOptions.deleteUser && (
                    <Overflow.Option
                        icon={<span className={cx(styles.icon, 'icon-icon_delete')} />}
                        onClick={onClickDeleteContacts}
                        className={cx(styles.withIcon, styles.danger)}
                    >
                        Delete contact…
                    </Overflow.Option>
                )}
            </Overflow>
        </React.Fragment>
    )
}

SettingsOverflow.propTypes = {
    dispatch: PropTypes.func,
    godMode: PropTypes.bool,
    selection: PropTypes.array,
    showMenuOptions: PropTypes.shape({
        deleteUser: PropTypes.bool,
        sendEmailReceipt: PropTypes.bool,
    })
}
const mapStateToProps = (state) => ({
    showMenuOptions: UsersModule.selectors.getMenuOptions(state),
    godMode: SettingsModule.selectors.getUserType(state) === 'god'
})

export default connect(mapStateToProps)(SettingsOverflow)
