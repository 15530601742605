import { useEffect } from 'react'
import Boutton from '@piconetworks/pkg-boutton'

import { usePlatformAccessApps, usePublisherUsername } from '../../dependencies'

import useAdminAccess from '../../hooks/useAdminAccess'

import styles from './AdminAccess.module.scss'

const AdminAccess = () => {
    const { getAccess, giveAccess } = useAdminAccess()

    const _usePlatformAccessApps = usePlatformAccessApps()

    const platformAccessPricings = Object.entries(_usePlatformAccessApps).reduce((acc, [key, value]) => {
        const pricings = value?.['pricings']
        const name = value?.['name']
        const hasAccess = value?.['has_access']

        pricings?.forEach((pricing) => {
            acc.push({
                id: pricing?.id,
                interval: pricing?.interval,
                interval_quantity: pricing?.interval_quantity,
                type: pricing?.type,
                name,
                hasAccess,
            })
        })

        return acc
    }, [])

    useEffect(() => {
        getAccess()
    },[ getAccess ])

    const publisherName = usePublisherUsername()

    return (
        <div className={styles.container}>
            <h2>Give access to {publisherName}:</h2>
            {platformAccessPricings.map((pricing) => {
                return (
                    <>
                        <Boutton
                            onClick={() => giveAccess(pricing?.id)}
                            variant="secondary"
                            disabled={pricing?.hasAccess}
                        >
                            {pricing?.name} ({pricing?.type})
                        </Boutton>
                    </>
                )
            })}
        </div>
    )
}

export default AdminAccess
