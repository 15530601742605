/** @namespace ThemePicker.getAllThemes */

import { createAsyncSaga, name, endpoints } from '../dependencies'

const getAllThemes = createAsyncSaga(
    `${name}/getAllThemes`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('no publisherId provided')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_ALL_THEMES,
                headers: {
                    publisherid: publisherId,
                },
            })
            
            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default getAllThemes
