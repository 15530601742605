import Boutton from '@piconetworks/pkg-boutton'
import styles from './Links.module.scss'

const UpgradeButton = ({
    onClick = () => { },
}: any) => {

    return (
        <>  
            <Boutton
                block={true}
                variant="primary"
                onClick={onClick}
                className={styles.upgradeButton}
            >
                Upgrade
            </Boutton>
        </>
    )
}

export default UpgradeButton
