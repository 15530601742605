import { useCallback } from 'react'
import NfcFormTooltip from './components/NfcFormTooltip'
import { useCards, useEditions } from './dependencies'
import onClickSetHypeCardPage from './onClickSetHypeCardPage'
import { useDispatch, useSelector } from 'react-redux'

const useNfcTags = (fetch = false) => {
    const cards = useCards(fetch)
    const editions = useEditions(fetch)

    const state = useSelector(state => state)
    const dispatch = useDispatch()

    const _onClickSetHypeCardPage = useCallback((id) => {
        onClickSetHypeCardPage(id, { state, dispatch })
    }, [state, dispatch])

    return {
        ...cards,
        ...editions,
        NfcFormTooltip,
        onClickSetHypeCardPage: _onClickSetHypeCardPage,
    }
}

export default useNfcTags
