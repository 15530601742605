import {
    initialState,
    reducer,
    takes,
    redeemClaim,
    openGlobalPaywall,
    closeGlobalPaywall,
} from './slice'
import usePlatformAccess from './hooks/usePlatformAccess'
import usePlatformAccessPaywall from './hooks/usePlatformAccessPaywall'
import useHasActiveSubscription from './hooks/useHasActiveSubscription'
import useAllowMorePages from './hooks/useAllowMorePages'
import withPlatformAccessPaywall from './HOCs/withPlatformAccessPaywall'
import useMessagingPaywall from './hooks/useMessagingPaywall'

import GlobalPaywall from './components/GlobalPaywall'
import checkToOpenPaywall from './slice/checkToOpenPaywall'

import usePlatformAccessApps from './hooks/usePlatformAccessApps'

export default {
    initialState,
    reducer,
    takes,
}

export {
    usePlatformAccess,
    usePlatformAccessPaywall,
    withPlatformAccessPaywall,
    useHasActiveSubscription,
    useAllowMorePages,
    redeemClaim,
    useMessagingPaywall,
    openGlobalPaywall,
    closeGlobalPaywall,
    GlobalPaywall,
    checkToOpenPaywall,
    usePlatformAccessApps,
}
