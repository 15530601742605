import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { constants } from 'features/FeatureManager'
import style from '../navigation.module.scss'

const useLinks = ({
    hasHypePlugin,
    accountInfo,
    hasPhoneNumber,
    isDesktop,
    isMobileLeftNav = false,
    hasPayments = false,
    paidDownloadsIsEnabled,
}) => {
    const [links, setLinks] = useState([])

    // Because MobileMainV2 (bottom nav) and LinksV2 (left nav) both pass isDesktop,
    // isMobileLeftNav determines what to show in the bottom vs the left nav on mobile.
    useEffect(() => {
        const _links = [
            ...(!hasHypePlugin && !isMobileLeftNav ? [
                {
                    link: '/home',
                    title: 'Home',
                    icon: (
                        <span
                            className={cx(style.mobileIcon, 'icon-home')}
                        >
                            <span className={cx('path1', style.iconActive)} />
                            <span
                                className={cx('path2', style.iconPassive)}
                            />
                        </span>
                    ),
                    badge: false,
                }] : []),
            ...(isDesktop || (!hasHypePlugin && isMobileLeftNav) ||(hasHypePlugin && !isMobileLeftNav) ? [
                {
                    link: '/performance',
                    title: 'Performance',
                    icon: (
                        <span className={cx(style.mobileIcon, 'icon-performance')}>
                            <span className={cx('path1', style.iconActive)} />
                            <span className={cx('path2', style.iconActive)} />
                            <span className={cx('path3', style.iconPassive)} />
                            <span className={cx('path4', style.iconPassive)} />
                        </span>
                    ),
                    badge: false,
                }] : []),
            ...(isDesktop || !isMobileLeftNav
                ? [
                    {
                        requiredFeatures: [true],
                        link: '/pages',
                        title: 'Site',
                        icon: (
                            <span className={cx(style.mobileIcon, 'icon-site')}>
                                <span className={cx('path1', style.iconActive)} />
                                <span className={cx('path2', style.iconPassive)} />
                            </span>
                        ),
                        badge: false,
                        fallback: {
                            link: `/pages/${accountInfo?.default_landing_page_id || 'signup'}`,
                            title: 'Site',
                            icon: (
                                <span className={cx(style.mobileIcon, 'icon-site')}>
                                    <span className={cx('path1', style.iconActive)} />
                                    <span className={cx('path2', style.iconPassive)} />
                                </span>
                            ),
                            badge: false,
                        },
                    }
                ]
                : []),
            ...(isDesktop || isMobileLeftNav ? [{
                requiredFeatures: [constants.GADGET_ACCESS_FEATURE, constants.WIDGET_FEATURE],
                link: '/plugin/popups',
                title: 'Plugin',
                icon: (
                    <span className={cx(style.mobileIcon, 'icon-website-nav')}>
                        <span className={cx('path1', style.iconActive)} />
                        <span className={cx('path2', style.iconActive)} />
                    </span>
                ),
                badge: false,
            }] : []),
            ...(isDesktop || isMobileLeftNav  ? [ {
                link: '/contacts',
                title: 'Contacts',
                icon: (
                    <span className={cx(style.mobileIcon, 'icon-contacts')}>
                        <span className={cx('path1', style.light, style.iconActive)} />
                        <span className={cx('path2', style.dark, style.iconPassive)} />
                        <span className={cx('path3', style.dark, style.iconPassive)} />
                    </span>
                ),
                badge: false,
            }] : []),
            ...(isDesktop || !isMobileLeftNav
                ? [
                    {
                        override: true,
                        requiredFeatures: [constants.MESSAGES_READ_ACCESS_FEATURE],
                        link: '/messages/blasts',
                        title: 'Messages',
                        icon: (
                            <>
                                <span className="icon-messages">
                                    <span className={cx('path1', style.light, style.iconActive)} />
                                    <span className={cx('path2', style.dark, style.iconPassive)} />
                                    {!hasPhoneNumber && (
                                        <span className={style.notificationAlert} />
                                    )}
                                </span>
                            </>
                        ),
                        badge: false,
                    }
                ]
                : []),
            ...(hasPayments && (isDesktop || !isMobileLeftNav) ? [{
                link: '/products/subscriptions',
                title: 'Products',
                icon: (
                    <span className={cx(style.mobileIcon, 'icon-products')}>
                        <span className={cx('path1', style.iconPassive)} />
                        <span className={cx('path2', style.iconActive)} />
                    </span>
                ),
                badge: false,
            }] : []),
            ...(isDesktop || !isMobileLeftNav
                ? [
                    {
                        link: '/accessories',
                        title: 'Accessories',
                        icon: <span className={cx(style.mobileIcon, 'icon-cards')} />,
                        badge: false,
                    }
                ]
                : []),
            ...(!isDesktop && isMobileLeftNav
                ? [
                    {
                        link: '/settings/account',
                        title: 'Settings',
                        icon: <span className={cx(style.mobileIcon, 'icon-settings')} />,
                        badge: false,
                    },
                ]
                : []),
            ...(!isDesktop && isMobileLeftNav
                ? [
                    {
                        link: '/settings/billing',
                        title: 'Billing',
                        icon: <span className={cx(style.mobileIcon, 'icon-billing')} />,
                        badge: false,
                    },
                ]
                : []),
            ...(paidDownloadsIsEnabled && isDesktop
                ? [
                    {
                        link: '/files',
                        title: 'Files',
                        icon: <span className={cx(style.mobileIcon, 'icon-download')} />,
                        badge: false,
                    },
                ]
                : []),
        ]

        setLinks(_links)
    }, [
        accountInfo,
        hasPhoneNumber,
        hasHypePlugin,
        isDesktop,
        isMobileLeftNav,
        paidDownloadsIsEnabled,
        hasPayments,
    ])
    
    return links
}

export default useLinks
