import {
    initialState,
    reducer,
    takes,
    fetchSegments,
    
} from './slice'

export default {
    initialState,
    reducer,
    takes,
}

export {
    fetchSegments
}
