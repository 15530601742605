const actions = {}

actions.types = [
    'ADD_NOTIFICATION',
    'UPDATE_NOTIFICATION',
    'REMOVE_NOTIFICATION',
    'CLEAR_NOTIFICATIONS',
    'UPDATE_PREFERENCES',
    'RESET_SOME'
]

actions.creators = ({ types }) => ({
    addNotification: (payload) => ({ type: types.ADD_NOTIFICATION, payload }),
    updateNotification: (payload) => ({ type: types.UPDATE_NOTIFICATION, payload }),
    removeNotification: (id) => ({ type: types.REMOVE_NOTIFICATION, id }),
    clearNotifications: () => ({ type: types.CLEAR_NOTIFICATIONS }),
    updatePreferences: (payload) => ({ type: types.UPDATE_PREFERENCES, payload }),
    resetSome: () => ({ type: types.RESET_SOME })
})

export default actions
