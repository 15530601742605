import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getEspFields({ types, payload }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        let createdListId
        if (payload.setListId) {
            const { data: { list_id } } = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'put',
                    url: `${endpoints.INTEGRATIONS_ESP}/lists`,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid
                    },
                    timeout: 30000,
                    data:{
                        list_id: payload.listId
                    }
                }
            })
            createdListId = list_id
        }

        let listId = payload.listId

        if (payload.setListId && createdListId) {
            listId = createdListId
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.INTEGRATIONS_ESP}/lists/fields${listId ? `?list_id=${listId}`: ''}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })

        if (payload.setListId && createdListId) {
            yield put({ type: types.GET_ESP_LISTS })
        }

        yield put({
            type: types.GET_ESP_FIELDS_SUCCESS,
            payload: {
                fields: data,
                listId
            }
        })
    } catch (e) {
        yield put({ type: types.GET_ESP_FIELDS_ERROR, error: e?.response?.data?.message})
    }
}

export default getEspFields
