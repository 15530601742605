import { call, put, putResolve, select } from 'redux-saga/effects'
import { FORM_ERROR } from 'final-form'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'

import AppModule from 'modules/app'
import AccountModule from 'modules/account'

function* saveDefaultPaymentOffer({ types, payload, callback }) {
    try {
        const { default_payment_landing_page_id } = payload
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: endpoints.MY_APPEARANCE,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    default_payment_landing_page_id
                }
            }
        })

        yield put({
            type: AccountModule.types.GET_ACCOUNT_INFO_SUCCESS,
            payload: data
        })
        yield put({
            type: types.SAVING_DEFAULT_PAYMENT_OFFER_SUCCESS,
        })
        yield put(
            AppModule.creators.addNotification({
                message: 'Default payment offer saved successfully',
                type: 'success'
            })
        )
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            const submissionError = {
                [FORM_ERROR]: error.response.data.message,
            }
            if (error.response.data.errors) {
                const fieldErrors = Object.assign(submissionError, ...error.response.data.errors)
                yield call(callback, fieldErrors)
            } else {
                yield call(callback, submissionError)
            }
        }
        yield put(
            AppModule.creators.addNotification({
                message: 'Something went wrong!',
                type: 'danger'
            })
        )
        yield put({
            type: types.SAVING_DEFAULT_PAYMENT_OFFER_ERROR,
            error
        })
    }
}

export default saveDefaultPaymentOffer
