import Script from 'next/script'
import React, { useEffect } from 'react'
// symbol observable is required to polyfill the difference between dev and prod locally
//
// eslint-disable-next-line
import SO from 'symbol-observable'
import App from 'next/app'
import { ReactReduxContext } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Loading } from '@piconetworks/ui'
import { wrapper } from 'modules/store'
import FeatureFlags from 'modules/feature-flags'

import ErrorBoundary from 'containers/ErrorBoundary'
import ScreenSizeProvider from 'utils/contexts/ScreenSize/provider'
import StyleDefinitions from 'utils/contexts/StyleDefinitions'
import HeadWithTagManager from 'containers/HeadWithTagManager'
import Track from 'components/Track'
// these get injected as style tags into the page

import '@piconetworks/pkg-boutton/dist/bundle.css'
import '@piconetworks/pkg-box/dist/bundle.css'
import '@piconetworks/pkg-card-brand-image/dist/bundle.css'
import '@piconetworks/pkg-choose-newsletter/dist/bundle.css'
import '@piconetworks/pkg-data/dist/bundle.css'
import '@piconetworks/pkg-date-picker/dist/bundle.css'
import '@piconetworks/pkg-field-email-password-login/dist/bundle.css'
import '@piconetworks/pkg-field-email-password-social-newsletters-login/dist/bundle.css'
import '@piconetworks/pkg-field-link/dist/bundle.css'
import '@piconetworks/pkg-field-otp/dist/bundle.css'
import '@piconetworks/pkg-field-select-donation/dist/bundle.css'
import '@piconetworks/pkg-field-select-group/dist/bundle.css'
import '@piconetworks/pkg-field-select-pass/dist/bundle.css'
import '@piconetworks/pkg-field-select-pledge/dist/bundle.css'
import '@piconetworks/pkg-field-select-subscription/dist/bundle.css'
import '@piconetworks/pkg-field-select-trial/dist/bundle.css'
import '@piconetworks/pkg-field-signup/dist/bundle.css'
import '@piconetworks/pkg-field-social-login/dist/bundle.css'
import '@piconetworks/pkg-field-text-signup/dist/bundle.css'
import '@piconetworks/pkg-field-text/dist/bundle.css'
import '@piconetworks/pkg-field-vcard/dist/bundle.css'
import '@piconetworks/pkg-form/dist/bundle.css'
import '@piconetworks/pkg-link/dist/bundle.css'
import '@piconetworks/pkg-menu-middleware/dist/bundle.css'
import '@piconetworks/pkg-notification/dist/bundle.css'
import '@piconetworks/pkg-loading/dist/bundle.css'
import '@piconetworks/pkg-nav-link/dist/bundle.css'
import '@piconetworks/pkg-otp/dist/bundle.css'
import '@piconetworks/pkg-page-link/dist/bundle.css'
import '@piconetworks/pkg-preview/dist/bundle.css'
import '@piconetworks/pkg-progress-bar/dist/bundle.css'
import '@piconetworks/pkg-remote-markdown/dist/bundle.css'
import '@piconetworks/pkg-select-newsletters/dist/bundle.css'
import '@piconetworks/pkg-social-links/dist/bundle.css'
import '@piconetworks/pkg-spacing/dist/bundle.css'
import '@piconetworks/pkg-text/dist/bundle.css'
import '@piconetworks/pkg-tooltip/dist/bundle.css'
import '@piconetworks/ui/dist/index.css'
import '@piconetworks/tag-generator/dist/bundle.css'

import '@piconetworks/pkg-toggle-bar/dist/bundle.css'
import '@piconetworks/pkg-modal/dist/bundle.css'
import '@piconetworks/theme-preview/dist/bundle.css'

import 'intl-tel-input/build/css/intlTelInput.css'
import 'react-dates/lib/css/_datepicker.css'
import 'tippy.js/dist/tippy.css'
import { ImportStatusButton } from 'features/userImport'
import { GlobalPaywall } from 'features/platformAccess'

const ComponentClass = ({
    router = {},
    store: { dispatch } = {},
    pageProps,
    component: Component,
}) => {
    const toEnable = router?.query?.enable_feature
    const toDisable = router?.query?.disable_feature

    const hashId = window.location.hash

    
    useEffect(() => {
        const scrollElement = hashId && document.querySelector(hashId)
        if (scrollElement) {
            // scroll to that elment accounting for additional offset
            // for sticky header
            const y = scrollElement.getBoundingClientRect().top + window.pageYOffset - 100
            window.scrollTo({ top: y, behavior: 'smooth' })
        }
    }, [hashId])

    useEffect(() => {
        if (toEnable) {
            dispatch(FeatureFlags.creators.enableFeatureFlag(toEnable))
        }
        if (toDisable) {
            dispatch(FeatureFlags.creators.disableFeatureFlag(toDisable))
        }
    }, [toEnable, toDisable, dispatch])

    return (
        <>
            <GlobalPaywall />
            <ImportStatusButton />
            <Component {...pageProps} />
        </>
    )
}

class DashboardApp extends App {
    componentDidMount() {
        if (typeof window !== 'undefined') {
            const CONFIG_BUILD_ID = localStorage.getItem('CONFIG_BUILD_ID')
            const VERSION = localStorage.getItem('VERSION')

            if (CONFIG_BUILD_ID !== process.env.CONFIG_BUILD_ID) {
                localStorage.setItem('CONFIG_BUILD_ID', process.env.CONFIG_BUILD_ID)
            }

            if (VERSION !== process.env.VERSION) {
                localStorage.setItem('VERSION', process.env.VERSION)
            }
        }
    }

    render() {
        const { Component, pageProps, router } = this.props
        if (router?.state?.pathname === '/scanner') {
            return <Component {...pageProps} />
        }
        return (
            <ErrorBoundary>
                <ReactReduxContext.Consumer>
                    {({ store }) => (
                        <StyleDefinitions>
                            <ScreenSizeProvider>
                                <PersistGate
                                    persistor={store.__persistor}
                                    loading={<Loading />}
                                >
                                    <Script id="my-script">{`
                                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                        })(window,document,'script','dataLayer','${process.env.GTM_TAG}');
                                    `}</Script>
                                    <HeadWithTagManager />
                                    <Track
                                        store={store}
                                        router={router}
                                    />
                                    <ComponentClass
                                        {...pageProps}
                                        component={Component}
                                        store={store}
                                        router={router}
                                    />
                                </PersistGate>
                            </ScreenSizeProvider>
                        </StyleDefinitions>
                    )}
                </ReactReduxContext.Consumer>
            </ErrorBoundary>
        )
    }
}

export default wrapper.withRedux(DashboardApp)
