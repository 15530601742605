import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import { Box } from '@piconetworks/ui'

import { useHasActiveSubscription, usePlatformAccess } from 'features/platformAccess'
import ScreenSizeContext from 'utils/contexts/ScreenSize/context'
import style from './SubscriptionBanner.module.scss'

const SubscriptionBanner = ({ showSubscriptionBanner = false }) => {
    const { openPlatformAccessPaywall, Paywall } = usePlatformAccess()

    return showSubscriptionBanner && (
        <ScreenSizeContext.Consumer>
            {({ isLgUp }) => {
                return (
                    <Box.Flexbox.Container
                        alignItems='center'
                        justifyContent='between'
                        className={cx(style.subscriptionBar, style.show)}
                        onClick={() => openPlatformAccessPaywall()}
                    >
                        {isLgUp ? 'This is a free version of Hype with limited features. Click to upgrade.' : 'Upgrade available. Tap for details.'}
                        <Paywall />
                    </Box.Flexbox.Container>
                )
            }}
        </ScreenSizeContext.Consumer>
    )
}

const useSubscriptionBanner = (delayShow = 2000) => {
    // after 2-second delay, show subscription banner if user is NOT at /settings/billing AND
    // (a) isn't paying for anything

    const { hasActiveSubscription } = useHasActiveSubscription()
    const { asPath } = useRouter()

    const isBillingPage = asPath.startsWith('/settings/billing')

    const showSubscriptionBanner = !isBillingPage && !hasActiveSubscription

    const [ _showSubscriptionBanner, setShowSubscriptionBanner ] = useState(null)

    useEffect(() => {
        let timeout
        if (showSubscriptionBanner !== _showSubscriptionBanner) {
            if (showSubscriptionBanner === false) {
                setShowSubscriptionBanner(showSubscriptionBanner)
            } else {
                timeout = setTimeout(() => {
                    setShowSubscriptionBanner(showSubscriptionBanner)
                }, delayShow)
            }
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [showSubscriptionBanner, _showSubscriptionBanner, setShowSubscriptionBanner, delayShow])

    return {
        showSubscriptionBanner: _showSubscriptionBanner,
        SubscriptionBanner: () => <SubscriptionBanner
            showSubscriptionBanner={_showSubscriptionBanner}
        />,
    }
}

export default SubscriptionBanner

export {
    useSubscriptionBanner,
}