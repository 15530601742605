import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { format } from '@piconetworks/pkg-currency'
import useHypePluginPrice from 'hooks/useHypePluginPrice'

import AppSummary from './AppSummary'
import CreditSummary from './CreditSummary'
import TaxSummary from './TaxSummary'
import AmountDue from './AmountDue'

import {
    fetchCredits,
    fetchTaxes,
    getCredits,
    // getFetchCreditsInProgress,
    // getFetchCreditsError,
    getTaxes,
    // getFetchTaxesInProgress,
    // getFetchTaxesError,
} from '../../../slice'
import { useEffect } from 'react'
import currency from 'currency.js'

import styles from './PaymentSummary.module.scss'
import { constants } from 'features/FeatureManager'

const getTaxString = (taxRate) => {
    try {
        return (taxRate * 100).toString()
    } catch (error) {
        return taxRate
    }
}

const PaymentSummary = ({
    app = null,
    productPricingId = null,
}) => {
    const creditAmount = useSelector(getCredits)
    // const fetchCreditsInProgress = useSelector(getFetchCreditsInProgress)
    // const fetchCreditsError = useSelector(getFetchCreditsError)
    const taxRate = useSelector(getTaxes)
    // const fetchTaxesInProgress = useSelector(getFetchTaxesInProgress)
    // const fetchTaxesError = useSelector(getFetchTaxesError)

    const dispatch = useDispatch()
    const appName = app?.name
    const publisherId = app?.publisherId

    const productPricing = app?.getPricingById(productPricingId)

    // This number should be calculated one if it is Hype Plugin
    let amount = productPricing?.amount
    if (app?.label === constants.HYPE_PLUGIN_TIER) {
        amount = useHypePluginPrice()
    }

    const appPrice = format({ amount, currencyCode: productPricing?.currency, symbol: true })
    const startDate = new Date()
    const endDate = dayjs(startDate).add(productPricing?.interval_quantity, productPricing?.interval)

    const taxAmount = currency(appPrice).multiply(taxRate).value
    
    const taxRateString = getTaxString(taxRate)
    const taxAmountString = currency(taxAmount).toString()

    useEffect(() => {
        dispatch(fetchTaxes({
            publisherId,
            productPricingId,
        }))
    }, [appPrice, dispatch, publisherId, productPricingId])

    useEffect(() => {
        dispatch(fetchCredits())
    }, [dispatch])

    return (
        <div className={styles.summary}>
            <AppSummary appName={appName} appPrice={appPrice} startDate={startDate} endDate={endDate} />
            <TaxSummary taxAmount={taxAmountString} taxRate={taxRateString} />
            <div className={styles.list}>
                {!!creditAmount && creditAmount > 0 && (
                    <CreditSummary appPrice={appPrice} creditAmount={creditAmount} taxAmount={taxAmount} />
                )}
                <AmountDue appPrice={appPrice} creditAmount={creditAmount} taxAmount={taxAmount} />
            </div>
        </div>
    )
}

export default PaymentSummary
