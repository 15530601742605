import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getUserNewsletters(action) {
    const { payload, duck } = action
    const { user_state_id } = payload

    const publisherid = yield select(AccountModule.selectors.getPublisherId)
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.USERS}/profile/${user_state_id}/newsletters`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })
        yield put({
            type: duck.types.GET_USER_NEWSLETTERS_SUCCESS,
            data
        })
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.GET_USER_NEWSLETTERS_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.GET_USER_NEWSLETTERS_ERROR,
                error
            })
        }
    }
}

export default getUserNewsletters
