import createDuck from '../baseDuck'
import { types, creators } from './actions'
import sagas from './sagas'
import reducer, { initialState } from './reducer'
import takes from './takes'
import selectors from './selectors'

export default createDuck({
    store: 'integrations',
    initialState,
    path: '/integrations'
}).extend({
    types,
    reducer,
    sagas,
    takes,
    creators,
    selectors
})
