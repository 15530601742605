/** 
 * Functions in the thunks file are the async actions used to perform
 * requests. They are exported as thunks, which are then dispatched
 * to the reducer and can be called from the components that import this
 */
import { createAsyncThunk } from '@reduxjs/toolkit'

import fetchActionEvents from './fetchActionEvents'
import createActionEvent from './createActionEvent'

/**
 * This function is used to create dynamic typePrefixed thunks.
 * @param {string} name
 * @param {object} selectors
 * @returns {{fetchActionEvents: function, createActionEvent: function}}
 */
const namedThunkGenerator = ({ name, selectors }) => {
    return {
        fetchActionEvents: createAsyncThunk(`${name}/fetchActionEvents`, (...args) => fetchActionEvents(...args)),
        createActionEvent: createAsyncThunk(`${name}/createActionEvent`, (...args) => createActionEvent(selectors, ...args)),
    }
}

export default namedThunkGenerator
