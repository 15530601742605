import { createAsyncSaga, name, endpoints } from '../dependencies'

const getAccess = createAsyncSaga(
    `${name}/getAccess`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_ACCESS,
                headers: {
                    publisherid: publisherId,
                },
                data: {},
                timeout: 15000,
            })

            const description = response?.description
            const errors = response?.errors
            const profile = response?.profile
            const subscriptions = response?.subscriptions
        
            yield fulfilled({
                accessRecords: subscriptions,
            })
        } catch (error) {
            yield rejected({
                error: error.message,
            })
        }
    }
)

export default getAccess
