import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

import logger from 'lib/logger'

function* getConversations({
    types,
}) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'GET',
                url: endpoints.CONVERSATIONS,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
            },
        })

        if (data) {
            yield put({
                type: types.GET_CONVERSATION_BATCH,
                conversationIds: data.map(convo => convo.id),
            })
        } else {
            throw new Error('Failed to get conversations')
        }
    } catch (e) {
        logger.error('getConversations error', {}, e)
        yield put({
            type: types.GET_CONVERSATIONS_ERROR,
            error: e
        })
    }
}

export default getConversations
