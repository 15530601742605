import { put, putResolve, select } from 'redux-saga/effects'
import Router from 'next/router'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* claimMagicLink({ creators, token_claim, types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'GET',
                baseURL: endpoints.BASE_URL,
                url: `${endpoints.OAUTH.PUBLISHER_UPDATE_EMAIL_WITH_CLAIM}?token_claim=${token_claim}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                timeout: 30000
            }
        })

        if (data) {
            yield put({
                type: types.CLAIM_MAGIC_LINK_SUCCESS,
                payload: {
                    ...data
                }
            })

            yield put(creators.postLogin())
            Router.push('/settings/account')
        }

    } catch (error) {
        yield put({
            type: types.CLAIM_MAGIC_LINK_ERROR,
            error
        })
    }
}

export default claimMagicLink
