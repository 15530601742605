import produce from 'immer'
import assign from 'lodash/assign'
import AuthDuck from '../auth'
import AppDuck from '../app'

export const initialState = {
    error: null,
    publisherId: null,
    status: 'INITIATED',
    info: {},
}

const reducer = (state, { type, payload, error }, { types, initialState, statuses }) => {
    return produce(state, draftState => {
        switch (type) {
            case AuthDuck.types.SOCIAL_LOGIN_SUCCESS:
            case AuthDuck.types.LOGIN_SUCCESS:
                draftState.publisherId = payload.publisher_id
                break

            case AuthDuck.types.SWITCH_ACCOUNT:
                draftState.status = statuses.LOADING
                draftState.info = initialState.info
                break

            case AuthDuck.types.SWITCH_ACCOUNT_SUCCESS:
                draftState.publisherId = payload.publisher_id
                draftState.status = statuses.READY
                break

            case types.GET_ACCOUNT_INFO:
                draftState.status = statuses.LOADING
                draftState.error = initialState.error
                draftState.publisherId = payload?.publisherId || draftState.publisherId
                break

            case types.GET_ACCOUNT_INFO_SUCCESS:
                draftState.status = statuses.READY
                draftState.info = payload
                break

            case types.GET_ACCOUNT_INFO_ERROR:
                draftState.status = statuses.READY
                draftState.error = error.message
                break

            case types.GET_ACCESS:
                draftState.status = statuses.LOADING
                draftState.error = initialState.error
                break

            case types.GET_ACCESS_SUCCESS:
                draftState.status = statuses.READY
                draftState.access = payload
                break

            case types.GET_ACCESS_ERROR:
                draftState.status = statuses.READY
                draftState.error = error.message
                break
                
            case types.SET_PUBLISHER_ID:
                draftState.publisherId = payload.id
                break


            case AppDuck.types.RESET_ALL:
                assign(draftState, initialState)
                break

            default:
                break
        }
    })
}

export default reducer
