import { createAsyncSaga, name, endpoints, logger } from '../dependencies'

const fetchAllPacks = createAsyncSaga(
    `${name}/fetchAllPacks`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_ALL_PACKS,
                headers: {
                    publisherid: publisherId,
                },
            })

            const packs = response

            if (!packs) {
                throw new Error('packs could not be found')
            }

            yield fulfilled(packs)
        } catch (error) {
            logger.error(error)
            yield rejected(error)
        }
    }
)

export default fetchAllPacks
