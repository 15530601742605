import { REQUEST } from '@piconetworks/pkg-network-middleware'
import {
    API,
    dependantSelectors
} from '../../dependencies'

import {
    IS_VALID_ACTION
} from '../../constants'

/**
 *
 * We should NOT post new events:
 * - If the task slug is invalid.
 * - If the task is already completed and we try to complete it.
 * - if the task is already ignored and we try to ignore it.
 * @memberof ProfileActionModule.ProfileActionSlice.ProfileActionSliceThunks
 * @param {string} action_slug
 * @param {Array} state
 * @param {string} status
 * @param {function} selectCompletedActions
 * @param {function} selectIgnoredActionEvents
 * @returns {boolean}
 */
const cancreateActionEvent = ({
    action_slug,
    state,
    status,
    selectors: { selectCompletedActions, selectIgnoredActionEvents },
}) => {
    const completedActions = selectCompletedActions(state)
    const ignoredTaskEvents = selectIgnoredActionEvents(state)

    // if we are updating a task event, we need to check if
    // it is already completed or already ignored
    const isAlreadyCompleted = !!completedActions.find(event =>
        event.action_slug === action_slug
    )

    const isAlreadyIgnored = !!ignoredTaskEvents.find(event =>
        event.action_slug === action_slug
    )

    if ((isAlreadyCompleted && status === 'completed')
        || (isAlreadyIgnored && status === 'ignored')
        || !IS_VALID_ACTION(action_slug)) {
        return false
    }

    return true
}

/**
 * @memberof ProfileActionModule.ProfileActionSlice.ProfileActionSliceThunks
 * @param {Object} selectors
 * @param {string} action_slug
 * @param {string} status
 * @param {function} getState
 * @param {function} dispatch
 * @returns {Promise<*>}
 */
const createActionEvent = async (selectors, {
    action_slug,
    status,
},{
    getState,
    dispatch,
}) => {
    const publisherid = dependantSelectors.getPublisherId(getState())

    const validTask = cancreateActionEvent({
        action_slug,
        state: getState(),
        status,
        selectors
    })

    if (validTask) {
        const { status: responseStatus, data } = await dispatch({
            type: REQUEST,
            config: {
                method: 'POST',
                url: API.API_URL,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                data: {
                    action_slug,
                    status,
                }
            }
        })

        if (responseStatus === 200) {
            return data
        } else {
            throw new Error('Creating profile action failed')
        }
    } else {
        throw new Error(`Task is already ${status} or does not exist.`)
    }
}

export default createActionEvent
