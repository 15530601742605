import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import Router from 'next/router'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* loginStripeExpress({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.STRIPE_EXPRESS_LOGIN,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
            }
        })

        yield put({
            type: types.LOGIN_STRIPE_EXPRESS_SUCCESS,
            payload: data
        })
        
        Router.push(data.url)
    } catch (error) {
        yield put({
            type: types.LOGIN_STRIPE_EXPRESS_ERROR,
            error
        })
    }
}

export default loginStripeExpress
