import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'

import AccountModule from 'modules/account'
import appModule from 'modules/app'

function* deleteProduct({ types, payload: id }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: `${endpoints.PRODUCT}/${id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
            }
        })

        if (status === 200) {
            yield put({
                type: types.DELETE_PRODUCT_SUCCESS,
                payload: id
            })
            yield put(
                appModule.creators.addNotification({
                    type: 'success',
                    message: 'Product deleted successfully'
                })
            )
        } else {
            throw Error('Something went wrong.')
        }
    } catch (error) {
        yield put(
            appModule.creators.addNotification({
                type: 'danger',
                message: error.message
            })
        )
        yield put({ type: types.DELETE_PRODUCT_ERROR, error })
    }
}

export default deleteProduct
