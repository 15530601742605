import Duck from 'extensible-duck'
import { colors } from '@piconetworks/pkg-functions'
import BillingService from 'utils/services/Billing'
import FreeAccessRulesService from 'utils/services/FreeAccessRules'

const selectors = ({ store, statuses }) => {
    const Billing = new BillingService(store, statuses)
    const FreeAccessRules = new FreeAccessRulesService(store, statuses)
    return ({
        getState: (state) => state[store],
        getStatus: (state) => state[store].status,
        getLoading: (state) => state[store].status === statuses.LOADING,
        getProfileData: (state) => state[store].profile?.data,
        getMeData: (state) => state[store].me?.data,
        getProfileLoading: (state) => state[store].profile.status === statuses.LOADING,
        getMeLoading: (state) => state[store].me.status === statuses.LOADING,
        getMyBusiness: (state) => state[store].myBusiness,
        getMyBusinessData: (state) => state[store].myBusiness.data,
        getFreeAccess: (state) => FreeAccessRules.getAll(state),
        getFreeAccessLoading: (state) => FreeAccessRules.getLoading(state),
        getFreeAccessById: (state, id) => FreeAccessRules.getActiveAccessRuleById(state, id),
        getInitialFormValues: (state, id) => FreeAccessRules.getInitialFormValues(state, id),
        getDefaultOffersLoading: (state) => state[store].defaultOffers.status === statuses.LOADING,
        getAccessTokens: (state) => state[store].accessTokens.data,
        getAccessTokensLoading: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state).accessTokens.status === statuses.LOADING
        }),
        getArticleStructureLoading: (state) => state[store].articleStructure.status === statuses.LOADING,
        getTaxonomies: new Duck.Selector((selectors) => (state) => {
            const taxonomies = selectors.getState(state).taxonomies.data
            return taxonomies
        }),
        getTaxonomiesAsArrayOfObjects: new Duck.Selector((selectors) => (state) => {
            const taxonomies = selectors.getState(state).taxonomies?.data
            return Array.isArray(taxonomies) ? taxonomies.map((taxonomy) => ({ taxonomy })) : []
        }),
        getUsername: (state) => state[store].username,
        getUsernameSlug: (state) => state[store].username.data.username,
        getDoubleOptIn: (state) => state[store].doubleOptIn,
        getRestrictedAccess: (state) => state[store].restrictedAccess,
        getMyAppearance: (state) => state[store].appearance,
        getLinkColor: (state) => state[store].appearance.data?.styling?.linkColor,
        getContrastColor: new Duck.Selector((selectors) => (state) => {
            const linkColor = selectors.getLinkColor(state)
            return linkColor ? colors.getContrastColor(linkColor) : null
        }),
        getStaff: (state) => state[store].staff,
        getMyHiddenElements: (state) => state[store].hiddenElements,
        getBilling: (state) => Billing.getAll(state),
        getActiveBillingStatuses: (state) => Billing.getActiveBillingStatuses(state),
        hasBillingInfoAlready: (state) => Billing.getBillingProfileIsActive({ state }),
        getBillingInfo: (state) => Billing.getInfoForBillingPage(state),
        getCreatorBillingInfo: (state) => Billing.getInfoForCreatorBillingPage(state),
        getBillingDefaultSource: (state) => Billing.getDefaultSource({ state }),
        getStripeAccountBalance: (state) => Billing.getAccountBalance({ state }),
        getBillingError: (state) => Billing.getBillingError(state),
        getBillingLoading: (state) => Billing.getLoading(state),
        getDomainsHealthCheckLoading: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state).domains.healthCheckLoading
        }),
        getMyProfile: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state)?.profile
        }),
        getMyProfileData: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state)?.profile?.data
        }),
        getMe: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state)?.me
        }),
        getMeDataUserId: new Duck.Selector((selectors) => (state) => {
            return selectors.getMeData(state)?.user_id
        }),
        getMeDataEmail: new Duck.Selector((selectors) => (state) => {
            return selectors.getMeData(state)?.email
        }),
        getMeDataHasPassword: new Duck.Selector((selectors) => (state) => {
            return selectors.getMeData(state)?.has_password
        }),
        getSocialLinks: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state).socialLinks
        }),
        getSocialLinksLoading: (state) => state[store].socialLinks.status === statuses.LOADING,
        getUserType: new Duck.Selector((selectors) => (state) => {
            return selectors.getMe(state)?.data?.user_type
        }),
        isGodmode: new Duck.Selector((selectors) => (state) => {
            return selectors.getMe(state)?.data?.user_type === 'god'
        }),
        isSalesMode: new Duck.Selector((selectors) => (state) => {
            return selectors.getMe(state)?.data?.user_type === 'sales' || selectors.getMe(state)?.data?.user_type === 'god'
        }),
        getMyDomains: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state).domains.data
        }),
        getMyArticleLockingSettings: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state).articleLocking
        }),
        getMyContentRestriction: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state).contentRestriction.data
        }),
        getMyContentRestrictionLoading: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state).contentRestriction.status === statuses.LOADING
        }),
        getMyPaymentHistory: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state).paymentHistory
        }),
        getIsBillingInformationStatusReady: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state).billingInformation.status === statuses.READY
        }),
        getDefaultSourceId: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state)?.billingInformation?.data?.default_source
        }),
        getSourcesArray: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state)?.billingInformation?.data?.sources
        }),
        getStatusesArray: new Duck.Selector((selectors) => (state) => {
            return selectors.getState(state)?.billingInformation?.data?.statuses || []
        }),
        getDefaultSourceFromSources: new Duck.Selector((selectors) => (state) => {
            const defaultSourceId = selectors.getDefaultSourceId(state)
            const sources = selectors.getSourcesArray(state)
            const defaultSource = sources?.find((source) => source?.id === defaultSourceId)

            return defaultSource
        }),
        publishersAvailable: new Duck.Selector((selectors) => (state) => {
            const meData = selectors.getMeData(state)
            const publications = meData?.publications

            return publications
        }),
    })
}


export default selectors
