
import { createSlice } from '@reduxjs/toolkit'

const DemoSlice = createSlice({
    name: 'demo',

    initialState: {
        hello: 'world'
    },

    reducers: {
        
    },

    extraReducers: {
    },
})

export default DemoSlice