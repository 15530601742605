import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'
import {
    name,
} from '../dependencies'

const status = {
    idle: 'idle',
    loading: 'loading',
    success: 'success',
    error: 'error',
}

const initialState = {
    status: status.idle,
    error: null,
    access_token: null,
    refresh_token: null,
}

const googleDriveSlice = createSlice({
    name,
    initialState,
    reducers: {
        setTokens: (state, { payload: { access_token, refresh_token }}) => {
            state.access_token = access_token
            state.refresh_token = refresh_token
        }
    },
    extraReducers: {},
})

// GENERAL
const GoogleDriveInitialState = {
    [name]: initialState,
}

const GoogleDriveReducer = {
    [name]: googleDriveSlice.reducer,
}

const takes = []

const {
    setTokens,
} = googleDriveSlice.actions

// SELECTORS
const getGoogleDriveStore = (state) => state?.[name]

const getGoogleDriveStoreStatus = createDraftSafeSelector(
    getGoogleDriveStore,
    (store) => store?.status
)

const getGoogleDriveStoreAccessToken = createDraftSafeSelector(
    getGoogleDriveStore,
    (store) => store?.access_token
)
const getGoogleDriveStoreRefreshToken = createDraftSafeSelector(
    getGoogleDriveStore,
    (store) => store?.refresh_token
)


const isLoadingGoogleDriveFiles = createDraftSafeSelector(
    getGoogleDriveStoreStatus,
    (status) => status === status.loading,
)

const getGoogleDriveFiles = createDraftSafeSelector(
    getGoogleDriveStore,
    (store) => store?.files
)

const getGoogleDriveError = createDraftSafeSelector(
    getGoogleDriveStore,
    (store) => store?.error
)

const getGoogleDriveNextPageToken = createDraftSafeSelector(
    getGoogleDriveStore,
    (store) => store?.nextPageToken
)

const getGoogleDriveQuery = createDraftSafeSelector(
    getGoogleDriveStore,
    (store) => store?.query
)

// EXPORTS
export default googleDriveSlice.reducer

export {
    GoogleDriveInitialState as initialState,
    GoogleDriveReducer as reducer,

    takes,

    setTokens,
    getGoogleDriveStore,
    getGoogleDriveStoreStatus,
    isLoadingGoogleDriveFiles,
    getGoogleDriveStoreAccessToken,
    getGoogleDriveStoreRefreshToken,
    getGoogleDriveFiles,
    getGoogleDriveError,
    getGoogleDriveNextPageToken,
    getGoogleDriveQuery,
}
