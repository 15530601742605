import { call, putResolve, put, select } from 'redux-saga/effects'
import Router from 'next/router'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'
import ErrorService from 'utils/services/ErrorService'
import BlocksModule from 'modules/blocks'
import SettingsModule from 'modules/settings'
import { getPublisherMetaFromOffer } from './createOffer'

function* updateOffer(action) {
    const { types, payload, callback } = action

    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const accountInfo = yield select(AccountModule.selectors.getAccountInfo)

        const id = Router?.router?.query?.id || payload.values.id

        // remove form_customizations with blank values
        if (payload?.values?.form_customizations) {
            payload.values.form_customizations = payload.values.form_customizations.filter(({ value }) => value !== null && value !== undefined && value !== '')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: `${endpoints.OFFERS}/${id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    ...payload.values
                }
            }
        })



        // only update these as needed
        const publisherMetaPayload = getPublisherMetaFromOffer(payload, accountInfo)

        if (Object.keys(publisherMetaPayload).length) {
            yield put(
                SettingsModule.creators.saveMyAppearance({
                    ...publisherMetaPayload,
                    notification: false
                })
            )
        }

        const isPopup = data.type.includes('popup')

        yield put({
            type: types.UPDATE_OFFER_SUCCESS,
            payload: {
                type: isPopup ? 'popups' : 'landing_pages',
                data
            }
        })

        yield put(
            BlocksModule.creators.getBlocks({
                payload: {
                    scope: 'creator',
                    formId: data.id,
                    publisherId: publisherid
                }
            })
        )

        yield put(
            AppModule.creators.addNotification({
                type: 'success',
                message:
                    `${isPopup ? 'Popup' : 'Page'} ${payload.activeOrArchived ? 'activated' : 'updated'} successfully.`
            })
        )

        yield call(callback)
    } catch (error) {
        const submissionError = new ErrorService(error).makeANiceSubmissionError()
        yield put(
            AppModule.creators.addNotification({
                message: 'There was a problem saving',
                type: 'danger'
            })
        )
        yield call(callback, submissionError)


        if (error?.response?.data?.message) {
            yield put({
                type: types.UPDATE_OFFER_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: types.UPDATE_OFFER_ERROR,
                error
            })
        }
    }
}

export default updateOffer
