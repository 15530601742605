/** @namespace CartUI.dependencies */

import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'

import SettingsModule from 'modules/settings'
import AnalyticsModule from 'modules/analytics'

import useEmail from 'hooks/useEmail'
import useBillingError from 'hooks/useBillingError'

const name = 'cartUI'

const getStripeAccountBalance = SettingsModule.selectors.getStripeAccountBalance
const saveBillingInformation = SettingsModule.creators.saveBillingInformation
const saveBillingInformationError = SettingsModule.creators.saveBillingInformationError
const useDefaultSource = SettingsModule.hooks.useDefaultSource
const resetBillingError = SettingsModule.creators.resetBillingError
const getBillingLoading = SettingsModule.selectors.getBillingLoading

const sendEvent = AnalyticsModule.creators.sendEvent

export {
    name,

    endpoints,
    createAsyncSaga,

    useEmail,
    useBillingError,

    resetBillingError,
    getStripeAccountBalance,
    saveBillingInformation,
    saveBillingInformationError,
    useDefaultSource,
    getBillingLoading,
    sendEvent,
}
