import cx from 'classnames'
import { loadStripe } from '@stripe/stripe-js'
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js'
import { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import CardBrandImage from '@piconetworks/pkg-card-brand-image'
import { every } from 'lodash'

import cardBrand from 'utils/lib/cardBrand'

import styles from './CreditCardEntry.module.scss'

const CreditCardEntry = ({
    onCreateToken = (data) => {},
    defaultSource = null,
    setSaveCreditCardForm = (ref) => {},
    setFormFulFilled = (disabled) => {},
    onStripeValidationError = (e = null) => {},
}) => {

    const [emptyFields, setEmptyFields] = useState<{
        cardNumber: boolean,
        expiryDate: boolean,
        cvc: boolean,
        zipCode: boolean
    }>({
        cardNumber: true,
        expiryDate: true,
        cvc: true,
        zipCode: true
    })

    const disabled = useMemo(() => {
        return [emptyFields.cardNumber, emptyFields.expiryDate, emptyFields.cvc, emptyFields.zipCode].includes(true)
    }
    , [
        emptyFields,
    ])

    const [brand, setBrand] = useState<string>('')

    const stripe = useStripe()
    const elements = useElements()

    const onCardNumberChange = useCallback((element) => {
        setBrand(element.brand)
        if (emptyFields.cardNumber !== element.empty) {
            setEmptyFields((prev) => ({
                ...prev,
                cardNumber: element.empty
            }))
        }
    }, [setBrand, emptyFields.cardNumber])

    const onCardExpiryElementChange = useCallback((element) => {
        if (emptyFields.expiryDate !== element.empty) {
            setEmptyFields((prev) => ({
                ...prev,
                expiryDate: element.empty
            }))
        }
    }, [setBrand, emptyFields.expiryDate])

    const onCardCvcElementChange = useCallback((element) => {
        if (emptyFields.cvc !== element.empty) {
            setEmptyFields((prev) => ({
                ...prev,
                cvc: element.empty
            }))
        }
    }, [setBrand, emptyFields.cvc])

    const onZipCodeElementChange = useCallback((element) => {
        if (emptyFields.zipCode !== !element.target.value) {
            setEmptyFields((prev) => ({
                ...prev,
                zipCode: !element.target.value
            }))
        }
    }, [setBrand, emptyFields.zipCode])

    const handleSubmit = useCallback(async (event) => {
        try {
            event.preventDefault()

            if (elements == null) {
                return
            }
            const zipCode = event.target.zip_code.value


            if (!zipCode){
                throw new Error('Zip code is required')
            }

            const optional_stripe_data = {
                address_zip: zipCode,
            }

            const data: any = await stripe
                .createToken(elements.getElement(CardNumberElement), optional_stripe_data)
                .catch(onStripeValidationError)

            if (data?.error?.message) {
                // stripe error object
                throw (data?.error)
            }

            onCreateToken(data)
        } catch (error) {
            if (error?.message) {
                // use it to show error messages in StatusBar component
                onStripeValidationError({
                    error: {
                        ...error,
                        message: error.message
                    }
                })
            }
        }
    }, [
        stripe,
        elements,
    ])

    const submitButtonRef = useRef()

    const isUpdating = !!defaultSource;

    const cardNumber = 'Card number'

    const cardExpiry = 'MM / YY'

    const cardCvc = 'CVC'

    const zipCode = 'Billing zip code'

    const placeholders = {
        cardNumber,
        cardExpiry,
        cardCvc,
        zipCode,
    }

    const stripeStyles = {
        style: {
            base: {
                color: "#120A20",
                fontSize: "16px",
                ...(!isUpdating && { "::placeholder": {
                    color: "#DDDCE0"
                }})
            }
        }
    }

    useEffect(() => {
        setSaveCreditCardForm(submitButtonRef)
    }, [submitButtonRef])

    useEffect(() => {
        setFormFulFilled(disabled)
    }, [disabled])

    useEffect(() => {
        // clear brand icon when component unmounts
        return () => {
            setBrand('')
        }
    }, [])

    return (
        <form onSubmit={handleSubmit} className={styles.form} id='credit-card-form'>
            <div className={styles.cardNumber}>
                <div className={styles.brand}>
                    <CardBrandImage brand={brand} style={{width: 40, height: 24}} />
                </div>
                <CardNumberElement
                    options={{
                        ...stripeStyles,
                        placeholder: placeholders?.cardNumber
                    }}
                    className={styles.input}
                    onChange={onCardNumberChange}
                />
            </div>
            <div className={styles.flex}>
                <CardExpiryElement
                    options={{
                        ...stripeStyles,
                        placeholder: placeholders?.cardExpiry
                    }}
                    className={styles.input}
                    onChange={onCardExpiryElementChange}
                />
                <CardCvcElement
                    options={{
                        ...stripeStyles,
                        placeholder: placeholders?.cardCvc
                    }}
                    className={styles.input}
                    onChange={onCardCvcElementChange}
                />
            </div>
            <input
                type="text"
                name="zip_code"
                className={cx(styles.input, {
                    [styles.noPlaceholder]: isUpdating
                })}
                placeholder={placeholders?.zipCode}
                onChange={onZipCodeElementChange}
            />
            <button style={{
                opacity: 0,
                position: 'absolute',
                height: '0px',
                width: '0px',
            }} ref={submitButtonRef} type="submit" form='credit-card-form' disabled={!stripe || !elements}>
                Pay
            </button>
        </form>
    )
}

const stripePromise = loadStripe(process.env.STRIPE_API_KEY)

const CreditCardEntryWrapper = ({
    onCreateToken = (data) => {},
    defaultSource = null,
    setSaveCreditCardForm = (ref) => {},
    setFormFulFilled = (disabled) => {},
    onStripeValidationError = (e) => {},
}) => {
    return (
        <Elements stripe={stripePromise}>
            <CreditCardEntry
                onCreateToken={onCreateToken}
                defaultSource={defaultSource}
                setSaveCreditCardForm={setSaveCreditCardForm}
                setFormFulFilled={setFormFulFilled}
                onStripeValidationError={onStripeValidationError}
            />
        </Elements>
    )
}

export default CreditCardEntryWrapper

    // Generic decline	4000000000000002	card_declined	generic_decline
    // Insufficient funds decline	4000000000009995	card_declined	insufficient_funds
    // Lost card decline	4000000000009987	card_declined	lost_card
    // Stolen card decline	4000000000009979	card_declined	stolen_card
    // Expired card decline	4000000000000069	expired_card	n/a
    // Incorrect CVC decline	4000000000000127	incorrect_cvc	n/a
    // Processing error decline	4000000000000119	processing_error	n/a
    // Incorrect number decline	4242424242424241	incorrect_number	n/a
