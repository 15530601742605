import addAndUpdateNotification from './addAndUpdateNotification'
import dismissNotification from './dismissNotification'

const sagas = (duck) => ({
    * addAndUpdateNotification(payload) {
        yield addAndUpdateNotification({ ...payload, ...duck })
    },
    * dismissNotification(payload) {
        yield dismissNotification({ ...payload, ...duck })
    }
})

export default sagas
