import React, { ReactElement } from 'react'
import styles from '../Themes.module.scss'
import cx from 'classnames'

type OptionProps = {
    input: {
        value?: string,
        checked?: boolean
    },
    onClick?: (e: any) => any,
    option?: ReactElement,
    children: ReactElement,
    className?: string
    disabled?: boolean,
}

const Option = ({
    input,
    className,
    children,
    disabled = false,
    onClick = () => {},
}: OptionProps) => {
    return (
        <label
            className={cx(styles.wrapper, { [styles.active]: input.checked || (!disabled && input.checked) }, className)}
            onClick={onClick}
        >
            <input type="radio" {...input} disabled={disabled} className={styles.option} />
            <div className={styles.child}>
                {React.cloneElement(children, {
                    className: cx({
                        [children?.props?.className]: children?.props?.className,
                        [styles.active]: !disabled && input.checked
                    }),
                    ...children?.props?.style && {
                        style: [
                            children?.props?.style
                        ]
                    }
                })}
            </div>
        </label>
    )
}

export default Option
