import { call, put } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, getPublisherId } from '../dependencies'
import app from 'modules/app'
import AuthModule from 'modules/auth'

const createUnclaimedGym = createAsyncSaga(
    `${name}/createUnclaimedGym`,
    function* ({
        fulfilled,
        rejected,
        request,
        payload: {
            username = "",
            instagram = null,
            tiktok = null,
            email = null,
            facebook = null,
            ...values
        } = {},
    }) {
        try {
            const publisherId = yield call(getPublisherId)


            if (!username) {
                throw Error('username is required')
            }

            if (!publisherId) {
                throw Error('publisherid must be provided')
            }

            const data = yield request({
                method: 'post',
                baseURL: endpoints.API_ENDPOINT,
                url: '/sales/unclaimed',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: {
                    username,
                    ...(instagram && { instagram }),
                    ...(facebook && { facebook }),
                    ...(email && { email }),
                    ...(tiktok && { tiktok }),
                    ...values
                }
            })


            yield put(
                app.creators.addNotification({
                    type: 'success',
                    message: 'Unclaimed account created!'
                })
            )

            yield put(AuthModule.creators.switchAccount({ newPublisherId: data.id }))
                
            yield fulfilled(fulfilled)
        } catch (error) {

            yield put(
                app.creators.addNotification({
                    type: 'danger',
                    message: error.message
                })
            )

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default createUnclaimedGym
