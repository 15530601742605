import { put, putResolve } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'

function* getDoubleOptIn({ types, payload }) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.DOUBLE_OPT_IN,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                timeout: 30000,
                data: {
                    ...payload
                }
            }
        })
        yield put({
            type: types.GET_DOUBLE_OPT_IN_SUCCESS,
            payload: {
                ...data.body
            }
        })
    } catch (e) {
        yield put({
            type: types.GET_DOUBLE_OPT_IN_ERROR,
            error: e
        })
    }
}

export default getDoubleOptIn
