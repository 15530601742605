const app_tiers = {
    TIER_40: 'tier-40',
    TIER_30: 'tier-30',
    TIER_20: 'tier-20',
    TIER_10: 'tier-10',
    TIER_00: 'tier-00',
}

const pages_allowed = {
    [app_tiers.TIER_40]: -1,
    [app_tiers.TIER_30]: -1,
    [app_tiers.TIER_20]: -1,
    [app_tiers.TIER_10]: 2,
    [app_tiers.TIER_00]: 1
}

const HYPE_PLUGIN_TIER = 'hype-plugin-tier'

const apps = {
    PLATFORM_ACCESS: 'platform-access',
}

export default {
    app_tiers,
    apps,
    pages_allowed,
    HYPE_PLUGIN_TIER
}