import { createSlice } from '@reduxjs/toolkit'

/**
 * @param {string} name
 * @param fetchActionEvents
 * @param createActionEvent
 * @returns {{initialState: Object, sliceInitialState: Object, reducer: Object, sliceReducer: Object}}
 */
const createProfileActions = ({ name, thunks: { fetchActionEvents, createActionEvent } }) => {
    const initialState = {
        data: [],
        loading: false,
        error: null,
    }

    const slice = createSlice({
        name,
        initialState,
        /**
         * reducers are defined in the slice folder
         * and allow us to generate associated actions
         * that offer "mutating" capabilities
         * RTK uses the immer library which generates new immutable state
         * @name Reducers
         * @memberof ProfileActionModule.ProfileActionSlice
         */
        reducers: {},
        /**
         * 'extra reducers' allow the slice to handle actions defined
         * elsewhere, and includes actions generated by createAsyncThunk
         * or potentially other slices
         * In RTK, the three possible states of a request are:
         * pending, fulfilled, and rejected
         * @name ExtraReducers
         * @memberof ProfileActionModule.ProfileActionSlice
         */
        extraReducers: {
            [fetchActionEvents.pending]: (state) => {
                state.data = []
                state.error = null
                state.loading = true
            },
            [fetchActionEvents.fulfilled]: (state, { payload }) => {
                state.data = payload
                state.error = null
                state.loading = false
            },
            [fetchActionEvents.rejected]: (state, { error }) => {
                state.data = []
                state.error = error.message
                state.loading = false
            },
            [createActionEvent.pending]: (state) => {
                state.error = null
                state.loading = true
            },
            [createActionEvent.fulfilled]: (state, { payload }) => {
                state.loading = false
                if (payload) {
                    const updatedIdx = state.data.findIndex(event =>
                        event.action_slug === payload.action_slug
                    )
                    if (updatedIdx !== -1) {
                        state.data[updatedIdx] = payload
                    } else {
                        state.data.push(payload)
                    }
                }
            },
            [createActionEvent.rejected]: (state, { error }) => {
                state.error = error.message
                state.loading = false
            },
        }
    })

    const sliceInitialState = {
        [name]: initialState,
    }

    const sliceReducer = {
        [name]: slice.reducer,
    }

    return {
        initialState,
        reducer: slice.reducer,
        sliceInitialState,
        sliceReducer,
    }
}

export default createProfileActions