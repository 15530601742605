import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
function* saveWebhooks({ types, payload }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: payload.isNew ? 'post': 'put',
                url: payload.isNew ? endpoints.WEBHOOKS : endpoints.WEBHOOKS + `/${payload.id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...payload,
                    scopes: payload.scopes || []
                }
            }
        })

        yield put({
            type: types.SAVE_WEBHOOK_SUCCESS,
            payload: data
        })

        yield put(
            AppModule.creators.addNotification({
                message: `Webhook successfully ${payload.isNew ? 'created' : 'updated'}`,
                type: 'success'
            })
        )
    } catch (e) {
        yield put(
            AppModule.creators.addNotification({
                message: 'Something went wrong',
                type: 'danger'
            })
        )
        yield put({ type: types.SAVE_WEBHOOK_ERROR, e })
    }
}

export default saveWebhooks
