import { call, put, putResolve } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import { FORM_ERROR } from 'final-form'

function* resetRequest({ payload, callback, types }) {
    try {
        const { email } = payload

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: endpoints.BASE_URL,
                url: endpoints.OAUTH.PUBLISHER_RESET_PASSWORD,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                timeout: 30000,
                data: {
                    email,
                    redirect_url: window.location.href
                }
            }
        })

        if (data) {
            yield put({
                type: types.RESET_PASSWORD_SUCCESS,
                payload: {
                    ...data
                }
            })
        }

    } catch (error) {

        const submissionError = {
            [FORM_ERROR]: error.message
        }

        yield call(callback, submissionError)

        yield put({
            type: types.RESET_PASSWORD_ERROR,
            error
        })
    }
}

export default resetRequest
