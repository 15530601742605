import { EFFECTED_USER_BEHAVIOR } from '../../constants/productEdit'
import orderBy from 'lodash/orderBy'

const sort = products => orderBy(products, ['updated_at'], ['desc'])

export default class ProductsService {
    constructor(store, statuses) {
        this.store = store
        this.statuses = statuses
    }

    getAll(state) {
        return state[this.store]?.products?.data
    }

    getProductById(state, id) {
        const products = this.getAll(state)
        return products?.find(p => p.id === id) || {}
    }

    getPasses(state) {
        const products = this.getAll(state)
        return sort(products?.filter(p => ['pass'].includes(p.type)))
    }

    getSubscriptions(state) {
        const products = this.getAll(state)
        return sort(
            products?.filter(p => ['subscription', 'group'].includes(p.type))
        )
    }

    getDonationBoxes(state) {
        const products = this.getAll(state)
        return sort(products?.filter(p => ['donation'].includes(p.type)))
    }

    getProductsInitiated(state) {
        return state[this.store].products?.status === this.statuses.INITIATED
    }

    getProductsLoading(state) {
        return state[this.store].products?.status === this.statuses.LOADING
    }

    getSelectedProductField(state) {
        return state[this.store].selectedProductField
    }

    getEffectedUsersCount(state, id){
        const product =  this.getProductById(state, id)
        const opts = product?.options || []
        return opts.map(({ members, interval }) => { return { members, interval } })
    }

    getEffectedUserBehaviours(state, id) {
        const effectedUserCounts =  this.getEffectedUsersCount(state, id)
        const MAX_EFFECTED_USER_COUNT_FOR_EDIT = 0
        return effectedUserCounts.map(({ members = 0, interval }) => {
            let effectedUserBehavior = ''
            if(members === 0) effectedUserBehavior = EFFECTED_USER_BEHAVIOR['SAVE']
            if(members > MAX_EFFECTED_USER_COUNT_FOR_EDIT) effectedUserBehavior = EFFECTED_USER_BEHAVIOR['CANT_SAVE']

            return { effectedUserBehavior, interval }
        })
    }
}
