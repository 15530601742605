import { putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'

function* deleteOffer(action) {
    const { types, payload } = action
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const id = payload.id

        const { status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'patch',
                url: `${endpoints.OFFERS}/${id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    archived: true,
                }
            },
        })

        const isPopup = payload.type.includes('popup')

        yield put({
            type: types.DELETE_OFFER_SUCCESS,
            payload: {
                type: isPopup ? 'popups' : 'landing_pages',
                data: id
            }
        })
        yield put(
            AppModule.creators.addNotification({
                type: 'success',
                message:
                    `${isPopup ? 'Popup' : 'Page'} archived successfully.`
            })
        )
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {

            yield put({
                type: types.DELETE_OFFER_ERROR,
                error: error.response.data
            })

            yield put(AppModule.creators.addNotification({
                type: 'warning',
                message: error.response.status === 422 ? 'You cannot archive default offers.' : 'There was a problem archiving the offer.'
            }))

        } else {
            yield put({
                type: types.DELETE_OFFER_ERROR,
                error
            })

            yield put(AppModule.creators.addNotification({
                type: 'warning',
                message: 'There was a problem archiving the offer.'
            }))
        }
    }
}

export default deleteOffer
