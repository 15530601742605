import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import SettingsModule from 'modules/settings'

function* getStaff({ types }) {
    try {
        const publisherId = yield select(AccountModule.selectors.getPublisherId)
        const currentUser = yield select(SettingsModule.selectors.getMe)

        let { data, status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.STAFF,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherId
                },
                timeout: 30000
            }
        })

        if (status === 200) {
            data = data.map(member => ({
                ...member,
                date_added: member.joined_at,
                you: (member?.user_id === currentUser?.data?.user_id)
            }))

            yield put({
                type: types.GET_STAFF_SUCCESS,
                payload: data
            })
        } else {
            throw Error('An uncatched error occured.')
        }
    } catch (e) {
        yield put({ type: types.GET_STAFF_ERROR, e })
    }
}

export default getStaff
