import { put, select, take, all } from 'redux-saga/effects'
import Router from 'next/router'

import AccountModule from 'modules/account'
import IntegrationsModule from 'modules/integrations'
import SettingsModule from 'modules/settings'
import FeatureFlagsModule from 'modules/feature-flags'
import AnalyticsModule from 'modules/analytics'

import logger from 'lib/logger'

function* postLogin({ types }) {
    try {
        const publisherId = yield select(AccountModule.selectors.getPublisherId)
        if (!publisherId) {
            throw Error('publisher id is required')
        }

        yield all([
            put(AccountModule.creators.getAccountInfo()),
            put(AccountModule.creators.getAccess()),
            put(SettingsModule.creators.getMe()),
            put(SettingsModule.creators.getMyProfile()),
            put(IntegrationsModule.creators.getIntegrations()),
            put(FeatureFlagsModule.creators.getFeatureFlags({
                publisherId
            })),
            put(SettingsModule.creators.getBillingInformation()),
            put({
                type: 'featureManager/fetchApps',
                payload: {
                    publisherId
                }
            })
        ])

        // We must listen to all resolved actions that we plan on selecting data from, otherwise the
        // data won't actually be there
        yield all([
            take([
                AccountModule.types.GET_ACCOUNT_INFO_ERROR,
                AccountModule.types.GET_ACCOUNT_INFO_SUCCESS,
            ]),
            take([
                SettingsModule.types.GET_ME_ERROR,
                SettingsModule.types.GET_ME_SUCCESS,
            ]),
            take([
                SettingsModule.types.GET_MY_PROFILE_ERROR,
                SettingsModule.types.GET_MY_PROFILE_SUCCESS,
            ]),
            take([
                FeatureFlagsModule.types.GET_FEATURE_FLAGS_ERROR,
                FeatureFlagsModule.types.GET_FEATURE_FLAGS_SUCCESS,
            ])
        ])

        const experiments = yield select(FeatureFlagsModule.selectors.getExperiments)

        // Call identify for both tiers, as long as not god mode user
        const tier = yield select(AccountModule.selectors.getAccessTier)
        const userType = yield select(SettingsModule.selectors.getUserType)
        const notGodMode = userType !== 'god'

        yield put({ type: types.POST_LOGIN_SUCCESS })

        if (notGodMode) {
            yield put(AnalyticsModule.creators.identifyUser({
                eventData: {
                    tier
                }
            }))
        }

        // Prevent dashboard_open from firing on every refresh which causes refresh_token_login to be called
        const alreadyOpened = localStorage.getItem('dashboard_opened')

        if (!alreadyOpened) {
            yield put({
                type: AnalyticsModule.types.SEND_EVENT,
                payload: {
                    eventType: 'dashboard_open',
                    eventData: {
                        experiments,
                    }
                }
            })

            localStorage.setItem('dashboard_opened', true)
        }
    } catch (error) {
        Router.push('/logout')
        yield put({
            type: types.POST_LOGIN_ERROR,
            error
        })
        logger.error('postLogin error', {}, error)
    }
}

export default postLogin
