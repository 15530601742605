import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* startUserImport(action) {
    const { duck } = action
    const publisherid = yield select(AccountModule.selectors.getPublisherId)

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: `${endpoints.USERS}/import`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {},
            }
        })

        yield put({
            type: duck.types.START_USER_IMPORT_SUCCESS,
            payload: data
        })
        
        yield put({
            type: duck.types.IMPORT_MODAL_OPEN,
            payload: data
        })
        // yield put(
        //     AppModule.creators.addNotification({
        //         message: 'Users imported successfully',
        //         type: 'success'
        //     })
        // )
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.START_USER_IMPORT_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.START_USER_IMPORT_ERROR,
                error
            })
        }
    }
}

export default startUserImport
