import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = ({ store, statuses }) => ({
    files: new Duck.Selector(() => (state) => state?.files),
    getFiles: new Duck.Selector((selectors) => createSelector(
        selectors.files,
        ({ files } = {}) => files
    )),
    getSelectedFile: new Duck.Selector((selectors) => createSelector(
        selectors.files,
        ({ selectedFile } = {}) => selectedFile
    )),
    getFileStackKeys: new Duck.Selector((selectors) => createSelector(
        selectors.files,
        ({ fileStackKeys } = {}) => fileStackKeys
    )),
    getLoading: (state) => state[store].status === statuses.LOADING || state[store].status === null,
})

export default selectors
