import { put } from 'redux-saga/effects'
import AccountModule from 'modules/account'
import { DASHBOARD_RESET } from 'modules/baseDuck'

function* preLogout({ types , payload}) {
    try {
        yield put({
            type: 'featureManager/initialize'
        })


        yield put({
            type: DASHBOARD_RESET
        })

        if (payload) {
            yield put(AccountModule.creators.setPublisherID({id: payload.publisher_id}))
        }

        localStorage.removeItem('dashboard_opened')

        yield put({
            type: types.PRE_LOGOUT_SUCCESS
        })
    } catch (error) {
        yield put({
            type: types.PRE_LOGOUT_ERROR,
            error
        })
    }
}

export default preLogout
