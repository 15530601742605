import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import { FieldSubmitError } from 'utils/errors'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getMyDomains({ types, payload }) {

    try {

        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.DOMAINS,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })

        yield put({
            type: types.GET_MY_DOMAINS_SUCCESS,
            payload: {
                ...data
            }
        })
    } catch (e) {
        if (e instanceof FieldSubmitError) {
            let fieldErrors = {}
            Object.keys(payload).map((key) => {
                if (e.field === key) {
                    fieldErrors[key] = e.message
                }
            })

            // NOTE!! We are passing SAVING_MY_PROFILE_SUCCESS here because Final Form expects
            // submit errors to come back via a *resolved* promise.

            yield put({ type: types.GET_MY_DOMAINS_ERROR, payload: fieldErrors })

            return fieldErrors
        }
    }
}

export default getMyDomains
