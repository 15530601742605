/** @namespace planCreditSummarySlice.fetchPlanCredits */

import { call } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, getPublisherId } from '../dependencies'

const fetchPlanCredits = createAsyncSaga(
    `${name}/fetchPlanCredits`,
    function* ({
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const publisherId = yield call(getPublisherId)

            const response = yield request({
                method: 'get',
                url: endpoints.GET_FULL_CREDIT_SUMMARY,
                headers: {
                    publisherid: publisherId,
                },
            })

            if (!response?.summary) {
                throw new Error('credit summary not provided')
            }

            yield fulfilled(response.summary)
        } catch (error) {
            yield rejected({
                error: error.message,
            })
        }
    }
)

export default fetchPlanCredits
