import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import {
    name,
    upsertEdition,
    fetchTagsByCreator,
} from '../dependencies'

import { put, takeEvery } from 'redux-saga/effects'

import fetchAndSetActiveCardAndEdition from './fetchAndSetActiveCardAndEdition'
import printStickers from './printStickers'
import printCustomStickers from './printCustomStickers'

const initialState = {
    fetchAndSetActiveCardAndEditionLoading: false,
    fetchAndSetActiveCardAndEditionError: null,
    fetchAndSetActiveCardAndEditionSuccess: null,

    printStickersLoading: false,
    printStickersError: null,
    printStickersSuccess: null,

    printCustomStickersLoading: false,
    printCustomStickersError: null,
    printCustomStickersSuccess: null,
}

const nfcEditorSlice = createSlice({
    name,
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchAndSetActiveCardAndEdition.pending]: (state: any, { payload }: any) => {
            state.fetchAndSetActiveCardAndEditionLoading = true
            state.fetchAndSetActiveCardAndEditionError = initialState.fetchAndSetActiveCardAndEditionError
            state.fetchAndSetActiveCardAndEditionSuccess = initialState.fetchAndSetActiveCardAndEditionSuccess
        },
        [fetchAndSetActiveCardAndEdition.fulfilled]: (state: any, { payload }: any) => {
            state.fetchAndSetActiveCardAndEditionLoading = false
            state.fetchAndSetActiveCardAndEditionError = false
            state.fetchAndSetActiveCardAndEditionSuccess = true
        },
        [fetchAndSetActiveCardAndEdition.rejected]: (state: any, { payload: error }: any) => {
            state.fetchAndSetActiveCardAndEditionLoading = false
            state.fetchAndSetActiveCardAndEditionError = error
            state.fetchAndSetActiveCardAndEditionSuccess= false
        },

        [printStickers.pending]: (state: any, { payload }: any) => {
            state.printStickersLoading = true
            state.printStickersError = initialState.printStickersError
            state.printStickersSuccess = initialState.printStickersSuccess
        },
        [printStickers.fulfilled]: (state: any, { payload }: any) => {
            state.printStickersLoading = false
            state.printStickersError = false
            state.printStickersSuccess = true
        },
        [printStickers.rejected]: (state: any, { payload: error }: any) => {
            state.printStickersLoading = false
            state.printStickersError = error
            state.printStickersSuccess= false
        },

        [printCustomStickers.pending]: (state: any, { payload }: any) => {
            state.printCustomStickersLoading = true
            state.printCustomStickersError = initialState.printCustomStickersError
            state.printCustomStickersSuccess = initialState.printCustomStickersSuccess
        },
        [printCustomStickers.fulfilled]: (state: any, { payload }: any) => {
            state.printCustomStickersLoading = false
            state.printCustomStickersError = false
            state.printCustomStickersSuccess = true
        },
        [printCustomStickers.rejected]: (state: any, { payload: error }: any) => {
            state.printCustomStickersLoading = false
            state.printCustomStickersError = error
            state.printCustomStickersSuccess= false
        },
    },
})

// SELECTORS
const getNfcEditor = (state: any) => state[name]

const fetchAndSetActiveCardAndEditionLoading = createDraftSafeSelector(
    getNfcEditor,
    (nfcEditor: any) => nfcEditor.fetchAndSetActiveCardAndEditionLoading,
)
const fetchAndSetActiveCardAndEditionError = createDraftSafeSelector(
    getNfcEditor,
    (nfcEditor: any) => nfcEditor.fetchAndSetActiveCardAndEditionError,
)
const fetchAndSetActiveCardAndEditionSuccess = createDraftSafeSelector(
    getNfcEditor,
    (nfcEditor: any) => nfcEditor.fetchAndSetActiveCardAndEditionSuccess,
)

const printStickersLoading = createDraftSafeSelector(
    getNfcEditor,
    (nfcEditor: any) => nfcEditor.printStickersLoading,
)
const printStickersError = createDraftSafeSelector(
    getNfcEditor,
    (nfcEditor: any) => nfcEditor.printStickersError,
)
const printStickersSuccess = createDraftSafeSelector(
    getNfcEditor,
    (nfcEditor: any) => nfcEditor.printStickersSuccess,
)

const printCustomStickersLoading = createDraftSafeSelector(
    getNfcEditor,
    (nfcEditor: any) => nfcEditor.printCustomStickersLoading,
)

const printCustomStickersError = createDraftSafeSelector(
    getNfcEditor,
    (nfcEditor: any) => nfcEditor.printCustomStickersError,
)

const printCustomStickersSuccess = createDraftSafeSelector(
    getNfcEditor,
    (nfcEditor: any) => nfcEditor.printCustomStickersSuccess,
)

// GENERAL
const NfcEditorInitialState = {
    [name]: initialState,
}

const NfcEditorReducer = {
    [name]: nfcEditorSlice.reducer,
}

const takes = [
    ...fetchAndSetActiveCardAndEdition.takes,
    ...printStickers.takes,
    ...printCustomStickers.takes,
    takeEvery(fetchTagsByCreator.fulfilled, function* (action: any) {
        const { tags } = action.payload

        for (const tag of tags) {
            const { edition } = tag

            if (edition) {
                const { id } = edition

                if (id) {
                    yield put(upsertEdition({ editionId: id, ...edition }))
                }
            }
        }
    })
]

// EXPORTS
export default nfcEditorSlice.reducer

export {
    NfcEditorInitialState as initialState,
    NfcEditorReducer as reducer,

    takes,

    fetchAndSetActiveCardAndEdition,
    fetchAndSetActiveCardAndEditionLoading,
    fetchAndSetActiveCardAndEditionError,
    fetchAndSetActiveCardAndEditionSuccess,

    printStickers,
    printStickersLoading,
    printStickersError,
    printStickersSuccess,

    printCustomStickers,
    printCustomStickersLoading,
    printCustomStickersError,
    printCustomStickersSuccess,
}
