/** @namespace FeatureManager.useCallToAction */

import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { getFeatures, getAppsTiers } from '../slice'

const useCallToAction = ({
    acceptedCtaSessionId = null,
    id = null,
    credits = false,
    featureLabels = [],
    appLabels = [],
    userId = null,
    publisherId = null,
    showOnStart = false,
    onPresented = (args = null) => {},
    onDeclined = (args = null) => {},
    onAccepted = (args = null) => {},
} = {}) => {
    const features = useSelector(getFeatures)
    const apps = useSelector(getAppsTiers)

    const [accepted, setAccepted] = useState(false)
    const [declined, setDeclined] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [ctaSessionId, setCtaSessionId] = useState(acceptedCtaSessionId)

    const getFeatureByLabel = useCallback(
        (featureLabel) => {
            const feature = features?.find(
                ({ label }) => label === featureLabel
            )

            return feature
        },
        [features]
    )

    const getAppTierByLabel = useCallback(
        (appLabel) => {
            const app = apps?.find(({ label }) => label === appLabel)

            return app
        },
        [apps]
    )

    const getAppById = useCallback(
        (appId) => {
            const app = apps?.find(({ id }) => id === appId)

            return app
        },
        [apps]
    )

    const startCtaSession = useCallback(() => {
        if (acceptedCtaSessionId) {
            return acceptedCtaSessionId
        }

        const uuid = uuidv4()

        setCtaSessionId(uuid)

        return uuid
    }, [setCtaSessionId, acceptedCtaSessionId])

    const ctaFeatures = featureLabels.reduce((acc, label) => {
        const feature = getFeatureByLabel(label)

        if (feature) {
            acc[label] = feature
        }

        return acc
    }, {})

    const ctaApps = appLabels.reduce((acc, label) => {
        const app = getAppTierByLabel(label)

        if (app) {
            acc[label] = app
        }

        return acc
    }, {})

    const relatedFeatureIds = Object.values(ctaFeatures).map(({ id }) => id)
    const relatedAppIds = Object.values(ctaApps).map(({ id }) => id)

    const show = useCallback(
        (force = false) => {
            setAccepted(false)
            setDeclined(false)
            setIsVisible(true)
            const sessionId = startCtaSession()
            onPresented?.({
                cta_id: id,
                cta_session_id: sessionId,
                feature_ids: relatedFeatureIds,
                app_ids: relatedAppIds,
                user_id: userId,
                publisher_id: publisherId,
            })
        },
        [setAccepted, setDeclined, setIsVisible, startCtaSession, onPresented]
    )

    const hide = useCallback(() => {
        setIsVisible(false)
    }, [setIsVisible])

    const accept = useCallback(() => {
        setAccepted(true)
        onAccepted?.({
            cta_id: id,
            cta_session_id: ctaSessionId,
            feature_ids: relatedFeatureIds,
            app_ids: relatedAppIds,
            user_id: userId,
            publisher_id: publisherId,
        })
    }, [setAccepted, onAccepted])

    const decline = useCallback(() => {
        setDeclined(true)
        onDeclined?.({
            cta_id: id,
            cta_session_id: ctaSessionId,
            feature_ids: relatedFeatureIds,
            app_ids: relatedAppIds,
            user_id: userId,
            publisher_id: publisherId,
        })
    }, [setDeclined, onDeclined])

    useEffect(() => {
        if (showOnStart && !isVisible) {
            show()
        }
    }, [showOnStart, show, isVisible])

    const response = {
        id,
        credits,
        features: ctaFeatures,
        apps: ctaApps,

        allFeatures: features,
        allApps: apps,

        accepted,
        declined,
        isVisible,
        ctaSessionId,

        show,
        hide,
        accept,
        decline,

        getFeatureByLabel,
        getAppById,
    }

    return response
}

export default useCallToAction
