import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST, GET_TOKEN } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getProductSiblings({ types, payload: id }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        let response
        const { status, data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'GET',
                url: `${endpoints.PRODUCT}/${id}/related`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
            }
        })

        response = data

        if (data?.custom_text?.filename) {
            const custom_text = yield putResolve({
                type: GET_TOKEN,
                config: {
                    method: 'GET',
                    url: data?.custom_text?.filename,
                }
            })
            response = {
                ...data,
                custom_text: {
                    filename: data?.custom_text?.filename,
                    json: {
                        feature_list: custom_text?.data?.feature_list,
                        description: custom_text?.data?.description,
                    }
                }
            }
        }

        if (status === 200) {
            yield put({
                type: types.GET_PRODUCT_SIBLINGS_SUCCESS,
                payload: response
            })

        } else {
            throw Error('An uncatched error occured.')
        }

    } catch (error) {
        yield put({ type: types.GET_PRODUCT_SIBLINGS_ERROR, error })
    }
}

export default getProductSiblings
