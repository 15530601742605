import createDuck from '../baseDuck'
import { types, creators } from './actions'
import reducer, { initialState } from './reducer'
import sagas from './sagas/index'
import selectors from './selectors'
import takes from './takes'

export default createDuck({
    store: 'auth',
    initialState
}).extend({
    types,
    reducer,
    sagas,
    takes,
    selectors,
    creators
})
