import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import ModalModule from 'modules/modal'
import AppModule from 'modules/app'

function* updateNewsletter({ types, payload }) {
    try {
        const {payload: newsletter} = payload

        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: endpoints.NEWSLETTERS + `/${newsletter.id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...newsletter
                }
            }
        })

        yield put({
            type: types.UPDATE_NEWSLETTER_SUCCESS,
            payload: data
        })

        yield put(
            ModalModule.creators.closeModal({
                key: 'newsletterForm'
            })
        )
        yield put({
            type: types.REMOVE_SELECTED_NEWSLETTER
        })

        yield put(
            AppModule.creators.addNotification({
                type: 'success',
                message: 'Newsletter updated successfully.'
            })
        )

    } catch (e) {

        yield put(
            AppModule.creators.addNotification({
                type: 'danger',
                message: 'Something went wrong.'
            })
        )

        yield put({
            type: types.UPDATE_NEWSLETTER_ERROR,
            error: e
        })
    }
}

export default updateNewsletter
