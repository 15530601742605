import memoize from 'lodash/memoize'
import { refundTypes } from 'utils/lib/money'

export const toNumber = (num) => (Number.parseFloat(num)).toFixed(2)
export const onlyIntegerParse = value => (isNaN(parseFloat(value)) ? '' : parseFloat(value))

const getValidatorIfConditionIsTrue = (validateIfThisIsTrue) => {
    return validateIfThisIsTrue ? (value) => (value ? undefined : 'Required') : () => { }
}
const findDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) != index)

const fieldValidations = {
    required: (value) => (value || typeof value === 'number' ? undefined : 'Required'),
    requiredConditional: (field) => (value, allValues) => (allValues[field] && !value && typeof value !== 'number' ? 'Required' : undefined),
    numbersPlusesAndHyphensOnly: (value) => {
        return value &&  /[^0-9-+,.\r]/.test(value) ? 'Invalid phone number' : undefined
    },
    minLength: (min) => (value) => (value && value.length < min ? `Must be ${min} characters or more` : undefined),
    maxLength: (max) => (value) => (value && (value.length <= max) ? undefined : `Must be ${max} characters or less`),
    maxLengthConditional: (max, field) => (value, allValues) => (allValues[field] && value && value.length > max ? `Must be ${max} characters or less` : undefined),
    unique: (array) => (value, _allValues, field) => {
        return field.modified && field.dirty && array.includes(value)
            ? 'There is already a property with this name, please select a different value.'
            : undefined
    },
    alphanumericConditional: (field) => (value, allValues) =>
        allValues[field] && value && !/^[a-z0-9_ ]+$/i.test(value) ? 'Must be alphanumeric value' : undefined,
    email: (value) =>
        value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value) ? 'Invalid email address' : undefined,
    mailto: (value) =>
        value && !/^mailto:[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value) ? 'Invalid email address' : undefined,
    state: (value) =>
        value &&
            !/^((A[LKZR])|(C[AOT])|(D[EC])|(FL)|(GA)|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EDAINSOT])|(N[EVHJMYCD])|(O[HKR])|(PA)|(RI)|(S[CD])|(T[NX])|(UT)|(V[TA])|(W[AVIY]))$/i.test(
                value
            )
            ? 'Invalid State'
            : undefined,
    zipCode: (value) => (value && !/^\d{5}$|^\d{5}-\d{4}$/i.test(value) ? 'Invalid Zip Code' : undefined),
    url: (value) => {
        return value &&
            !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,24}(:[0-9]{1,5})?(\/.*)?$/i.test(value)
            ? 'Invalid website address'
            : undefined
    },
    discordUrl: (value) => {
        return value &&
            !/^(http:\/\/www\.discord\.|https:\/\/www\.discord\.|http:\/\/discord\.|https:\/\/discord\.)/i.test(value)
            ? 'Invalid Discord URL'
            : undefined
    },
    linkedinUrl: (value) => {
        return value &&
            !/^(http:\/\/www\.linkedin\.|https:\/\/www\.linkedin\.|http:\/\/linkedin\.|https:\/\/linkedin\.)/i.test(value)
            ? 'Invalid LinkedIn URL'
            : undefined
    },
    httpRequired: (value) => (value && /^(http|https):\/\/[^ "]+$/.test(value) ? undefined : 'http or https required'),
    uniqueHook: (val, allValues) => {
        const webhooks = allValues.webhooks.map((item) => item && item.url)
        const duplicates = findDuplicates(webhooks)
        return duplicates[0] && duplicates[0] === val ? 'Endpoint already in use' : undefined
    },
    isUrlUnique: (val, allValues) => {
        const liveDomains = allValues.liveDomains.map((item) => item && item.domain)

        let allDomains = liveDomains

        if (allValues.testingDomains) {
            const testDomains = allValues.testingDomains.map((item) => item && item.domain)
            allDomains = liveDomains.concat(testDomains)
        }

        const duplicates = findDuplicates(allDomains)
        return duplicates[0] && duplicates[0] === val ? 'Domain already in use' : undefined
    },
    isOptionUnique: (val, allValues) => {
        const values = allValues.options.map((option) => option && option.value)
        const duplicates = findDuplicates(values)
        return duplicates[0] && duplicates[0] === val ? 'Option already in use' : undefined
    },
    isScopeUnique: (data) => (val, { webhooks }, field) => {
        if (field.modified && field.dirty && data) {
            const group = webhooks.filter((value) => value && value.url === data.url)
            const duplicates = findDuplicates(group.map((element) => element && element.scope))
            return duplicates[0] && duplicates[0] === val ? 'Hook already in use' : undefined
        }
    },
    minValue: memoize(
        (min, formattedMin) => value => {
            return toNumber(value) < toNumber(min) ? `Must be at least ${formattedMin}` : undefined
        }
    ),
    maxValue: memoize(
        (max, formattedMax) => (value) => {
            return value && (toNumber(value) - toNumber(max)) > 0 ? `Must be less than ${formattedMax}` : undefined
        }
    ),
    maxDecimals: memoize(
        (maxDecimals) => value => {
            if (!value || value.toString().split('.').length === 1 || value.toString().split('.')[1].length <= maxDecimals) {
                return undefined
            }

            else { return `Amount must only have ${maxDecimals} decimals` }
        }
    ),
    //min cannot be dynamic; if it is, this won't work as intended. Need to break lodash memoize cash on both arguments
    //by default, memoize only uses first arg; to cache both args, need to make a resolver
    minValIf: memoize(
        (refundType, min) => (value) => {
            if (refundType === refundTypes.CUSTOM) {
                return value && toNumber(value) < toNumber(min) ? `Must be at least $${min}` : undefined
            }
            else {
                return undefined
            }
        }
    ),
    maxValIf: memoize(
        (refundType, max) => (value) => {
            if (refundType === refundTypes.CUSTOM) {
                return value && (toNumber(value) - toNumber(max)) > 0 ? `Must be no more than $${max}` : undefined
            }
            else {
                return undefined
            }
        },
        (x, y) => `${x}${y}`
    ),
    // Validation helper for checking a value based on a duration interval i.e max 2 yrs = 104 weeks = 24 months
    // unitFieldName is the name of the field which describes the time unit
    // unitMaxMapping maps the units with the corresponding max values for those units i.e { year: 2, month: 24, week: 104 }
    maxValueIfInterval: memoize(
        (unitFieldName, unitFieldMapping, formattedMax) => (value, allValues) => {
            const currentUnit = allValues[unitFieldName]
            const currentMax = unitFieldMapping[currentUnit]

            return value && (toNumber(value) - toNumber(currentMax)) > 0 ? `Must be less than ${formattedMax}` : undefined
        }
    ),
    socialUsername: (social) => (value) =>
        value && !/[@]/.test(value)
            ? `Invalid ${social === 'instagram'
                ? 'Instagram username'
                : social === 'twitter'
                    ? 'X username'
                    : social === 'tiktok'
                        ? 'TikTok username'
                        : 'username'
            }`
            : undefined,
    ifNotForbiddenColor: (value) => {
        const forbidden_color = '#ffffff'
        if ((value || '').toLowerCase() === forbidden_color) {
            return `Please pick another color than ${value}`
        }

        return undefined
    },
    isHexCode: (value = '') => {
        let splitValue = value.slice(1, value.length)
        const isValidHex = value[0] === '#'
            && typeof splitValue === 'string'
            && splitValue.length === 6
            && !isNaN(Number('0x' + splitValue))
        if (!isValidHex) {
            return 'Please pick a valid hex code'
        }
        return undefined
    },
    alphaNumericString: (value) => (value && !/^[a-z0-9]+$/i.test(value) ? 'Usernames can only contain letters and numbers' : undefined),
}

const composeValidators = (...validators) => (value, allValues, meta) =>
    validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined)

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const asyncValidate = (values) => {
    const names = [
        'rachel',
        'michelle',
        'vora',
        'james',
        'wally',
        'matt',
        'renato',
        'jason',
        'nick',
        'julian',
        'andrew'
    ]
    return sleep(800).then(() => {
        if (names.includes(values.step2.username)) {
            throw {
                step2: {
                    username: 'That username is taken'
                }
            }
        }
    })
}

export { asyncValidate, composeValidators, fieldValidations, getValidatorIfConditionIsTrue }
