import produce from 'immer'

export const timeframeTypes = {
    TODAY: 'today',
    THIS_WEEK: 'this_week',
    THIS_MONTH: 'this_month',
    THIS_YEAR: 'this_year',
    LAST_7_DAYS: 'last_7_days',
    LAST_30_DAYS: 'last_30_days',
    LAST_90_DAYS: 'last_90_days',
    LAST_365_DAYS: 'last_365_days',
    ALL_TIME: 'all_time'
}

export const initialState = {
    status: 'INITIATED',
    error: false,
    time_dimension: timeframeTypes.LAST_7_DAYS,
    metrics: {},
    recurring: null
}

const reducer = (state, action, { types, statuses }) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case types.SET_TIME_DIMENSION:
                draftState.time_dimension = action.payload.value
                break
            case types.GET_RECURRING_PAYMENTS:
            case types.GET_METRICS:
                draftState.status = statuses.LOADING
                draftState.error = false
                break

            case types.GET_METRICS_SUCCESS:
                draftState.status = statuses.READY
                draftState.error = false
                draftState.metrics = action.payload
                break

            case types.GET_RECURRING_PAYMENTS_SUCCESS:
                draftState.status = statuses.READY
                draftState.error = false
                draftState.recurring = action.payload
                break
                        
            case types.GET_RECURRING_PAYMENTS_ERROR:
            case types.GET_METRICS_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = action.error
                break

            default:
                return state
        }
    })

export default reducer
