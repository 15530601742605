import { PICO_API_URL } from '@piconetworks/pkg-endpoints'

const API_ENDPOINT = PICO_API_URL()

const endpoints = {
    API_ENDPOINT,
    BASE_MOCK_URL: process.env.HAHA_NODE_ENV == 'development' ? process.env.BASE_MOCK_URL : 'https://app.staging.hype.co',
    BASE_URL: process.env.HAHA_NODE_ENV == 'development' ? process.env.BASE_URL : 'https://api.staging.pico.tools',
    OAUTH: {
        PUBLISHER: `${API_ENDPOINT}/oauth/publisher/`,
        PUBLISHER_LOGIN: `${API_ENDPOINT}/oauth/publisher/login`,
        PUBLISHER_TOKEN: `${API_ENDPOINT}/oauth/publisher/token`,
        PUBLISHER_UPDATE_PASSWORD: `${API_ENDPOINT}/oauth/publisher/update`,
        PUBLISHER_SWITCH: `${API_ENDPOINT}/oauth/publisher/switch`,
        PUBLISHER_RESET_PASSWORD: `${API_ENDPOINT}/oauth/publisher/reset`,
        PUBLISHER_UPDATE_EMAIL_WITH_CLAIM: `${API_ENDPOINT}/oauth/publisher/link`,
        USER: `${API_ENDPOINT}/oauth/user`,
        USER_TOKEN: `${API_ENDPOINT}/oauth/user/token`,
    },
    MAGIC_LINK: {
        BULK: `${API_ENDPOINT}/users/link`,
        INDIVIDUAL: (user_state_id) => `${API_ENDPOINT}/users/profile/${user_state_id}/link`
    },
    MEMBERSHIP: `${API_ENDPOINT}/membership`,
    USERS: `${API_ENDPOINT}/users`,
    OFFERS: `${API_ENDPOINT}/form`,
    MY_ORGANIZATION: `${API_ENDPOINT}/publisher`,
    SEGMENTS: `${API_ENDPOINT}/segments`,
    NEWSLETTERS: `${API_ENDPOINT}/newsletters`,
    MY_APPEARANCE: `${API_ENDPOINT}/publisher`,
    USERNAME: `${API_ENDPOINT}/publisher`,
    TAXONOMY: `${API_ENDPOINT}/publisher`,
    BILLING_INFORMATION: `${API_ENDPOINT}/billing`,
    BILLING_SOURCE: `${API_ENDPOINT}/billing/source`,
    CONNECT_GOOGLE: `${API_ENDPOINT}/connect/google/publisher?publisher_id=&redirect=false`,
    PASSWORD_UPDATE: `${API_ENDPOINT}/oauth/publisher/update`,
    DOMAINS: `${API_ENDPOINT}/domains`,
    INTEGRATIONS: `${API_ENDPOINT}/integrations`,
    INTEGRATIONS_ESP: `${API_ENDPOINT}/integrations/esp`,
    MAILCHIMP_LISTS: `${API_ENDPOINT}/integrations/mailchimp/lists`,
    STRIPE_EXPRESS_START: `${API_ENDPOINT}/integrations/stripe/express/onboarding/start?source=dashboard`,
    STRIPE_EXPRESS_RESUME: `${API_ENDPOINT}/integrations/stripe/express/onboarding/resume`,
    STRIPE_EXPRESS_LOGIN: `${API_ENDPOINT}/integrations/stripe/express/login`,
    MESSAGES: `${API_ENDPOINT}/publisher/messages`,
    PAYMENT_HISTORY: `${API_ENDPOINT}/billing`,
    PROFILE: `${API_ENDPOINT}/publisher/profile`,
    ME: `${API_ENDPOINT}/me`,
    STAFF: `${API_ENDPOINT}/teams`,
    WEBHOOKS: `${API_ENDPOINT}/webhooks/pico`,
    WHITELIST: `${API_ENDPOINT}/whitelist`,
    PLANS: `${API_ENDPOINT}/plans`,
    REACTIVATE_PLANS: `${API_ENDPOINT}/membership`,
    METRICS: `${API_ENDPOINT}/metrics`,
    FORM_METRICS: `${API_ENDPOINT}/metrics/forms`,
    REFUNDS: `${API_ENDPOINT}/refunds`,
    PRODUCT: `${API_ENDPOINT}/product`,
    FREE_ACCESS: `${API_ENDPOINT}/access_rules`,
    CONTENT_RESTRICTION: `${API_ENDPOINT}/lock`,
    CUSTOM_PROPERTIES: `${API_ENDPOINT}/custom_properties`,
    KEYS: `${API_ENDPOINT}/keys`,
    RECEIPTS: `${API_ENDPOINT}/receipts`,
    SOCIAL_LINKS: `${API_ENDPOINT}/social_links`,
    FILES: `${API_ENDPOINT}/files`,
    FILESTACK_SIGN: `${API_ENDPOINT}/files/sign/filestack`,
    S3_UPLOAD_SIGN: `${API_ENDPOINT}/files/sign/s3`,
    IFRAMELY_URL: (url) => `https://cdn.iframe.ly/api/oembed?url=${encodeURIComponent(url)}&key=${process.env.IFRAMELY_KEY_HASH}&omit_script=1`,
    LAUNCH_DARKLY_DASHBOARD: `https://app.launchdarkly.com/default/${process.env.HAHA_NODE_ENV}/features`,
    BROADCASTS: `${API_ENDPOINT}/broadcasts`,
    MESSAGING: `${API_ENDPOINT}/messaging`,
    MESSAGING_VERIFY: `${API_ENDPOINT}/messaging/verify`,
    SEARCH_USERS_META: `${API_ENDPOINT}/users/search/meta`,
    PROFILE_ACTION_EVENTS: `${API_ENDPOINT}/publisher/action/events`,
    CONVERSATIONS: `${API_ENDPOINT}/conversations`,
    APPS: `${API_ENDPOINT}/apps`,
    FEATURES: `${API_ENDPOINT}/features`,
    PURCHASE_APP: (pricing_id) => `${API_ENDPOINT}/billing/app/${pricing_id}`,
    CANCEL_APP: (pricing_id) => `${API_ENDPOINT}/billing/app/${pricing_id}`,
    LOGIN_OPTIONS: `${API_ENDPOINT}/publisher/auth_methods`,
    GET_TAX_RATE: (pricing_id) => `${API_ENDPOINT}/billing/tax_rate/${pricing_id}`,
    GET_FULL_CREDIT_SUMMARY: `${API_ENDPOINT}/credits`,
    GET_MESSAGE_ESTIMATE: `${API_ENDPOINT}/messaging/estimate`,
    GET_ALL_THEMES: `${API_ENDPOINT}/themes?display=true`,
    GET_THEME: (theme_id) => `${API_ENDPOINT}/themes/${theme_id}`,
    UPDATE_VCARD: `${API_ENDPOINT}/vcard`,
    GET_EDTION_BY_ID: (edition_id) => `${API_ENDPOINT}/nfc/editions/${edition_id}`,
    GET_EDTIONS: `${API_ENDPOINT}/nfc/editions`,
    CREATE_EDITION: `${API_ENDPOINT}/nfc/editions`,
    UPDATE_EDITION: (edition_id) => `${API_ENDPOINT}/nfc/editions/${edition_id}`,
    UPDATE_CARD: (tag_id) => `${API_ENDPOINT}/nfc/tags/${tag_id}`,
    CREATE_CARD: () => `${API_ENDPOINT}/nfc/tags`,
    GET_CARDS: () => `${API_ENDPOINT}/nfc/tags`,
    DELETE_ACCOUNT: `${API_ENDPOINT}/publisher/deleteAccount`,
    GET_TAG_BY_ID: (tag_id) => `${API_ENDPOINT}/nfc/tags/${tag_id}/details`,
    GET_PUBLISHER_TAGS: () => `${API_ENDPOINT}/publisher/tags`,
    REQUEST_CODE: `${API_ENDPOINT}/auth/publisher/code/init`,
    CONFIRM_CODE: `${API_ENDPOINT}/auth/publisher/code/confirm`,
    GENERATE_CLAIM: `${API_ENDPOINT}/auth/publisher/claim`,
    REDEEM_CLAIM: (claim) => `${API_ENDPOINT}/auth/publisher/claim/${claim}`,
    GET_ACCESS: `${API_ENDPOINT}/admin/access`,
    GIVE_ACCESS: `${API_ENDPOINT}/admin/access`,

    // NFC Pack
    ADD_TAG_TO_PACK: (packId, tagId) => `${API_ENDPOINT}/nfc/packs/${packId}/${tagId}`,
    UPDATE_PACK: (packId) => `${API_ENDPOINT}/nfc/packs/${packId}`,
    CREATE_PACK: `${API_ENDPOINT}/nfc/packs`,
    GET_ALL_PACKS: `${API_ENDPOINT}/nfc/packs`,
    GET_PACK_BY_ID: (packId) => `${API_ENDPOINT}/nfc/packs/${packId}`,
    REMOVE_TAG_FROM_PACK: (packId, tagId) => `${API_ENDPOINT}/nfc/packs/${packId}/${tagId}`,
}

export default endpoints
