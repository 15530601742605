import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => [
    takeEvery(types.CREATE_FILE, sagas.createFile),
    takeEvery(types.GET_FILES, sagas.getFiles),
    takeEvery(types.EDIT_FILE, sagas.editFile),
    takeEvery(types.DELETE_FILE, sagas.deleteFile),
    takeEvery(types.GET_FILE_STACK_KEYS, sagas.getFileStackKeys)
]

export default takes
