import React, { useContext } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Router from 'next/router'
import { Flexbox } from '@piconetworks/pkg-box'
import { P, Span } from '@piconetworks/pkg-text'
import { H1 } from '@piconetworks/pkg-heading'
import { Link, Loading } from '@piconetworks/ui'

import AccountModule from 'modules/account'
import AuthModule from 'modules/auth'
import UserModule from 'modules/users'
import SettingsModule from 'modules/settings'

import useExperiment from 'hooks/useExperiment'

import AppBar from 'components/AppBar'
import AppLayout from 'components/AppLayout'
import SettingsOverflow from 'components/Contacts/Profile/SettingsOverflow'
import { useSubscriptionBanner } from 'components/SubscriptionBanner'
import { fetchApps } from 'features/FeatureManager'
import Navigations from 'features/navigations'
import { BLASTS_SECTION } from 'utils/constants/messages'
import ScreenSizeContext from 'utils/contexts/ScreenSize/context'
import { formatPhoneNumber } from 'utils/lib/formatString'

import styles from './LoggedInScaffolding.module.scss'
import { GlobalPaywall } from 'features/platformAccess'

const paneRef = React.createRef()

export const PaneRefContext = React.createContext()

const LoggedInScaffolding = ({
    router,
    accountLoading,
    initialLoading = false,
    accountInfo,
    className,
    me,
    pageTitle,
    loading,
    isUserProfile = false,
    user,
    settingsLoading,
    optIns,
    children,
}) => {
    const dispatch = useDispatch()
    const { homepageUrl } = useExperiment('homepage')
    const redirectablePathnames = ['', '/',]
    const shouldRedirect =
        redirectablePathnames.includes(Router.router?.pathname) && Router.router?.pathname !== homepageUrl
    const { isLgUp } = useContext(ScreenSizeContext)

    const onSwitchCommunity = (newPublisherId) => {
        dispatch(AuthModule.creators.switchAccount({ newPublisherId }))

        if (!newPublisherId) {
            return
        }
        dispatch(fetchApps({ publisherId: newPublisherId }))
    }

    if (shouldRedirect && homepageUrl) {
        Router.push(homepageUrl)

        return <Loading />
    }

    if (initialLoading) {
        return <Loading />
    }

    if (loading) {
        return <Loading />
    }

    const data = me.data
    const email = data.email?.value || data._email?.value || data.email
    const isBlastSection = router?.asPath === `/messages/${BLASTS_SECTION}`

    let title
    if (isUserProfile) {
        title = (
            <H1 className={cx('ml-3 ml-md-4 mr-auto', styles.heading)}>
                <Flexbox.Container alignItems="center">
                    <Span className={cx('mr-auto', styles.secondary)}>
                        <Link className={styles.secondaryLink} onClick={() => Router.push('/contacts')}>
                            Contacts
                        </Link>
                        <span className={styles.divider}>&nbsp;/&nbsp;</span>
                    </Span>
                    {user?.user_state_id && (
                        <>
                            <P className={cx('mr-auto', styles.name)}>
                                {user.name.value || user.email.value || formatPhoneNumber(user.phone.value)}
                            </P>
                            <SettingsOverflow selection={[user.user_state_id]} />
                        </>
                    )}
                </Flexbox.Container>
            </H1>
        )
    } else {
        title = pageTitle
    }

    const MobileMainNavigation = Navigations.Component.MobileMainNavigationV2

    // TO DO: after 2-second delay, show subscription banner if user is NOT at /settings/billing AND
    // (a) does not have an active subscription (Essentials, Standard, Premium, Scale, or Hype Plugin) OR
    // (b) has a free trial that expires in <=72 hours
    const { showSubscriptionBanner, SubscriptionBanner } = useSubscriptionBanner()

    return (
        <PaneRefContext.Provider value={paneRef}>
            <Navigations.context.MenuProvider>
                <AppLayout pageTitle={pageTitle} banner={showSubscriptionBanner}>
                    {showSubscriptionBanner && <SubscriptionBanner />}
                    <AppBar
                        pageTitle={title}
                        isUserProfile={isUserProfile}
                        loading={accountLoading || settingsLoading}
                        optIns={optIns}
                        isBlastSection={isBlastSection}
                    />
                    <Flexbox.Container>
                        <Navigations.Component.Main
                            accountInfo={accountInfo}
                            accountName={me.data.full_name}
                            avatar={accountInfo.icon}
                            communityName={accountInfo.name}
                            email={email}
                            handleSwitch={onSwitchCommunity}
                            publishersAvailable={me.data.publications}
                        />
                        <div
                            className={cx(styles.main, className)}
                            ref={paneRef}
                        >
                            {children && React.cloneElement(children, accountInfo)}

                            {!isLgUp && (
                                <MobileMainNavigation
                                    accountInfo={accountInfo}
                                    accountName={me.data.full_name}
                                    avatar={accountInfo.icon}
                                    communityName={accountInfo.name}
                                    email={email}
                                    handleSwitch={onSwitchCommunity}
                                    publishersAvailable={me.data.publications}
                                />
                            )}
                        </div>
                    </Flexbox.Container>
                </AppLayout>
            </Navigations.context.MenuProvider>
        </PaneRefContext.Provider >
    )
}

LoggedInScaffolding.propTypes = {
    accountInfo: PropTypes.object,
    accountLoading: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dispatch: PropTypes.any,
    initialLoading: PropTypes.bool,
    isUserProfile: PropTypes.bool,
    loading: PropTypes.bool,
    me: PropTypes.object,
    optIns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pageTitle: PropTypes.string,
    router: PropTypes.object,
    settingsLoading: PropTypes.bool,
    user: PropTypes.object,
}

const mapStateToProps = (state) => ({
    accountInfo: AccountModule.selectors.getAccountInfo(state),
    accountLoading: AccountModule.selectors.getLoading(state),
    loading: AuthModule.selectors.getLoading(state),
    me: SettingsModule.selectors.getMe(state),
    settingsLoading: SettingsModule.selectors.getLoading(state),
    user: UserModule.selectors.getSingleUser(state),
    dashboardHomepage: false,
    optIns: UserModule.selectors.getUsersSmsOptIns(state),
})

export default connect(mapStateToProps, null)(LoggedInScaffolding)
