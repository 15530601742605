import { FORM_ERROR } from 'final-form'

export default class ErrorService {
    constructor(error) {
        this.error = error
        this.name = 'ErrorService'
        this.where = this.whereDidErrorHappen()
        this.message = this.getMessage()
        this.status = this.getStatus()
        this.date = new Date()
    }

    whereDidErrorHappen() {
        if (this.error.response) {
            // client received an error response (5xx, 4xx)
            return 'response'
        } else if (this.error.request) {
            // client never received a response, or request never left
            return 'request'
        } else {
            return 'somewhere else'
        }
    }

    getMessage() {
        switch (this.where) {
            case 'response':
                return this.error.response.data.message
            case 'request':
                return this.error.message
            default:
                return this.error.message
        }
    }

    getStatus() {
        switch (this.where) {
            case 'response':
                return this.error.response.status
            case 'request':
                return this.error.request.status
            default:
                return null
        }
    }

    makeItNice() {
        switch (this.status) {
            case 409:
                return 'Custom property name must be unique'
            case 405:
                if (this.message ==='Method Not Allowed') {
                    return 'There was a problem! Please contact support and tell them you received code 405.'
                }
                return this.message
            case 0:
                if (this.message?.indexOf('timeout of') !== -1) {
                    return 'Your internet connection appears to be too slow :('
                }
                return this.message

            default:
                if (this.message) {
                    return this.message
                }
                return 'There was a problem.'
        }
    }

    makeANiceSubmissionError() {
        const madeItNice = this.makeItNice()
        return {
            [FORM_ERROR]: madeItNice
        }

    }
}
