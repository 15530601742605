/** @namespace GuidesFeature.GuidesSlice */

import { createSlice } from '@reduxjs/toolkit'

import { fetchGuides } from './thunks'

export const name = 'guides'

export const initialState = {
    data: [],
    loading: false,
    error: null,
}

const guidesSlice = createSlice({
    name,
    initialState,
    extraReducers: {
        [fetchGuides.pending]: (state) => {
            state.error = null
            state.loading = true
        },
        [fetchGuides.fulfilled]: (state, { payload }) => {
            state.data = payload
            state.error = null
            state.loading = false
        },
        [fetchGuides.rejected]: (state, { error }) => {
            state.error = error
            state.loading = false
        },
    },
})

export const guidesSliceInitialState = {
    [name]: initialState,
}

export const guidesCreatorsSliceReducer = {
    [name]: guidesSlice.reducer,
}

export default guidesSlice.reducer
