import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* reindexUsers(action) {
    const { duck } = action
    const publisherid = yield select(AccountModule.selectors.getPublisherId)

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.USERS}/sync`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        yield put({
            type: duck.types.REINDEX_SUCCESS,
            data
        })

        yield put(
            AppModule.creators.addNotification({
                message: 'Reindex triggered successfully',
                type: 'success'
            })
        )
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.REINDEX_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.REINDEX_ERROR,
                error
            })
        }
    }
}

export default reindexUsers
