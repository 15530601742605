import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'
import logger from 'lib/logger'
import AccountModule from 'modules/account'
import { select } from 'redux-saga/effects'

function* getPublisherId() {
    const publisherId = yield select(AccountModule.selectors.getPublisherId)

    return publisherId
}

const name = 'googleDrive'

export {
    name,
    getPublisherId,

    endpoints,
    createAsyncSaga,
    logger
}
