import { STORE_TOKENS } from '@piconetworks/pkg-network-middleware'
import { call, put, putResolve, take } from 'redux-saga/effects'
import Router from 'next/router'
import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
    postLogin,
    getAccountInfo,
} from '../dependencies'

const redeemClaim = createAsyncSaga(
    `${name}/redeemClaim`,
    function* ({
        payload: {
            claim: _claim = null,
            redirectTo: _redirectTo = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!_claim) {
                throw new Error('claim is required')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.REDEEM_CLAIM(_claim),
            })

            const token = response.token
            const refresh_token = response.refresh_token
            const publisher_id = response.publisher_id

            yield putResolve({
                type: STORE_TOKENS,
                access_token: token,
                refresh_token: refresh_token
            })
        
            yield fulfilled({
                token,
                refresh_token,
            })

            yield put(yield call(getAccountInfo, { payload: { publisherId: publisher_id }}))
            yield put(yield call(postLogin))

            if (_redirectTo) {
                Router.push(`/${_redirectTo}` || '/')
            }
        } catch (error) {
            logger.error('redeemClaim error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default redeemClaim
