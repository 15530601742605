import { useSelector } from 'react-redux'
import React, { useCallback } from 'react'
import Link from '@piconetworks/pkg-link'
import Boutton from '@piconetworks/pkg-boutton'
import { Flexbox } from '@piconetworks/pkg-box'
import { constants, withFeatureManager } from 'features/FeatureManager'
import { usePlatformAccessPaywall } from 'features/platformAccess'

import cx from 'classnames'
import styles from './ImportNotify.module.scss'

import UsersModule from 'modules/users'

const getTitle = (isMessageCompose, totalNotifiableUserCount) => {
    const plural = totalNotifiableUserCount > 1
    return !isMessageCompose ? `Notify Imported Contact${plural ? 's' : ''}?` :
        'Some imported contacts have not received a subscription notification'
}

const getCancelLabel = (isMessageCompose) => {
    return !isMessageCompose ? 'I\'ll do it later' : 'Skip'
}

const getTotalNotifiableUserCount = (selectedUserType, notifiableImportedUserCount) => {
    const email = notifiableImportedUserCount?.email || 0
    const phone = notifiableImportedUserCount?.phone || 0

    switch (selectedUserType) {
        case 'any':
            return email + phone
        case 'email':
            return email
        case 'sms':
            return phone
        default:
            return 0
    }
}

const ImportNotify = ({
    cancel = () => {},
    notify = (payload) => payload,
    isMessageCompose = false,
    hasActiveSubscription = false,
    selectedUserType = 'any'
}) => {
    const notifiableImportedUserCount = useSelector(UsersModule.selectors.getNotifiableImportUserCount)
    const totalNotifiableUserCount = getTotalNotifiableUserCount(selectedUserType, notifiableImportedUserCount)
    const plural = totalNotifiableUserCount > 1
    const title = getTitle(isMessageCompose, totalNotifiableUserCount)
    const cancelLabel = getCancelLabel(isMessageCompose)
    const { openPlatformAccessPaywall, Paywall } = usePlatformAccessPaywall()

    const notifyUsers = useCallback( () => {
        if (hasActiveSubscription) {
            let payload = { notify_email: true, notify_phone: true }
            switch (selectedUserType) {
                case 'email':
                    payload = { notify_email: true, notify_phone: false}
                    break
                case 'sms':
                    payload = { notify_email: false, notify_phone: true}
                    break
            }

            notify(payload)
        } else {
            switch (selectedUserType) {
                case 'sms':
                    openPlatformAccessPaywall(constants.MESSAGE_WRITE_SMS_FEATURE)
                    break
                default:
                    openPlatformAccessPaywall(constants.MESSAGE_WRITE_EMAIL_FEATURE)
                    break
            }
        }
    }, [hasActiveSubscription, notify, openPlatformAccessPaywall, selectedUserType])

    return (
        <div className={styles.popup}>
            <Paywall />
            <Flexbox.Container alignItems="center" className={styles.titlebar}>
                <div>
                    {isMessageCompose && (<span className={cx(styles.icon, 'icon-icon_warning')} />)}
                    <p>{title}</p>
                </div>
            </Flexbox.Container>
            <div className={styles.popupContainer}>
                <div className={styles.popupDesc} >
                    Hype needs to notify your imported contacts that they’ve been subscribed to your list before you can send them an email or text message.
                    <br/>
                    <br/>
                    Imported contacts with phone numbers must further opt in to receiving text messages.
                    Click <Link href={"https://help.hype.co/hc/en-us/articles/17202126501011-Contact-Import-#h_01H44GS2S22E78Q8BDXN7S9K35"}>here</Link> to preview these notifications.
                </div>
                <Boutton
                    variant="primary"
                    className={styles.button}
                    onClick={notifyUsers}
                >
                    Notify {totalNotifiableUserCount} imported contact{plural ? 's' : ''}.
                </Boutton>
                <Boutton
                    variant="secondary"
                    className={styles.button}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        cancel()
                    }}
                >
                    {cancelLabel}
                </Boutton>
            </div>
        </div>
    )
}

export default withFeatureManager(ImportNotify)
