/** @namespace ProfileActionModule */
import namedThunkGenerator from './slice/thunks'
import createProfileActionsSelectors from './slice/selectors'
import createProfileActionSlice from './slice'

/**
 * @param {string} name
 * @returns {{slice: Object, selectors: Object, thunks: Object}}
 */
const namedModuleGenerator = ({ name }) => {
    const selectors = createProfileActionsSelectors({ name })
    const {
        selectLoading,
        selectError,
        selectActionEvents,
        selectIgnoredActionEvents,
        selectCompletedActions,
        selectIsAllActionsDismissed,
        selectWelcomeDismissed,
    } = selectors
    const { fetchActionEvents, createActionEvent } = namedThunkGenerator({ name, selectors })

    return {
        slice: createProfileActionSlice({ name,
            thunks: {
                fetchActionEvents,
                createActionEvent
            }
        }),
        selectors: {
            selectLoading,
            selectError,
            selectActionEvents,
            selectIgnoredActionEvents,
            selectCompletedActions,
            selectIsAllActionsDismissed,
            selectWelcomeDismissed
        },
        thunks: {
            fetchActionEvents,
            createActionEvent,
        },
    }
}

export default namedModuleGenerator