import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import AccountModule from 'modules/account'
import endpoints from 'utils/lib/endpoints'

function* getMyAppearance({  types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.MY_APPEARANCE,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })
        yield put({
            type: types.GET_MY_APPEARANCE_SUCCESS,
            payload: data
        })
    } catch (error) {
        yield put({
            type: types.GET_MY_APPEARANCE_ERROR,
            error
        })
    }
}

export default getMyAppearance
