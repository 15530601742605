import reducer ,{ initialState } from './reducer'
import actions from './actions'
import createDuck from '../baseDuck'
import sagas from './sagas'
import takes from './takes'
import selectors from './selectors'

import useDefaultSource from './hooks/useDefaultSource'

const Settings = createDuck({
    store: 'settings',
    initialState,
    path: '/settings'
}).extend({
    types: actions.types,
    reducer,
    creators: actions.creators,
    sagas,
    takes,
    selectors,
})

Settings.hooks = {
    useDefaultSource,
}

export default Settings