import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Grid } from '@piconetworks/pkg-box'
import Boutton from '@piconetworks/pkg-boutton'
import { H1, H2, H5 } from '@piconetworks/pkg-heading'
import { P } from '@piconetworks/pkg-text'
import Picon from '@piconetworks/pkg-picon'

import { PlanCreditSummary } from '../dependencies'
import styles from './CreditsOverlay.module.scss'

import {
    hideCreditsModal,
    getCreditsModalVisible,
} from '../slice'
import { usePopup } from 'features/cartUI'


const CreditsOverlay = ({ className, notEnoughCredit, creditType = 'message' }) => {
    const dispatch = useDispatch()
    const { setModalOpen, Popup } = usePopup()
    const creditsModalVisible = useSelector(getCreditsModalVisible)

    useEffect(() => {
        setModalOpen(creditsModalVisible)
    }, [
        creditsModalVisible
    ])
    const onClickClose = useCallback((e) => {
        e.preventDefault()

        dispatch(hideCreditsModal())
    }, [dispatch])

    if (!creditsModalVisible) {
        return null
    }

    return (
        <Popup className={styles.creditPopup} onAfterClose={() => dispatch(hideCreditsModal())}>
            <div className={className}>
                <Grid.Container fluid={true} className={styles.header}>
                    <Grid.Row>
                        <Grid.Col className={styles.column}>
                            <H1 className={styles.title}>Texts</H1>
                        </Grid.Col>
                        <Grid.Col className={styles.column}>
                            <Boutton
                                type="button"
                                onClick={onClickClose}
                                className={styles.button}
                                icon={<Picon className={styles.closeIcon} icon='icon_close' size='12' />}
                            />
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Container>
                <div className={styles.container}>
                    <H2 className={styles.title}>Balance</H2>
                    <PlanCreditSummary
                        showMessagingUpgrade
                        creditType={creditType}
                        notEnoughCredit={notEnoughCredit}
                    />
                    <div className={styles.block}>
                        <H2 className={styles.title}>How it works</H2>
                        <P className={styles.description}>
                            Each Hype plan comes with a monthly allowance of text messages, starting with 150 texts in the Essentials plan. You can upgrade at any time to get more texts.
                        </P>
                        <P className={styles.description}>
                            A text is equivalent to one photo, one video, or one message up to 268 characters.
                        </P>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default CreditsOverlay
