import Container from './Container'
import Child from './Child'
import Header from './Header'
import Footer from './Footer'

const ContentSection = {
    Footer,
    Container,
    Child,
    Header
}

export default ContentSection
