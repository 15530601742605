import { createAsyncSaga, name } from '../dependencies'

const fetchVcard = createAsyncSaga(
    `${name}/fetchVcard`,
    function* ({
        payload: {
            vcardUrl = null,
        } = {},
        fulfilled,
        rejected,
    }) {
        try {
            if (!vcardUrl) {
                throw new Error('vcardUrl is required')
            }

            const response = yield fetch(`${vcardUrl}`)

            const text = yield response.text()

            if (!text.startsWith('BEGIN:VCARD')) {
                throw new Error('vcardUrl is not a vcard')
            }

            yield fulfilled(text)
        } catch (error) {
            yield rejected({
                error: error.message + 'vcardUrl: ' + vcardUrl,
            })
        }
    }
)

export default fetchVcard
