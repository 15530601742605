const types = [
    'ADD_INTEGRATION',
    'GET_WEBHOOKS',
    'GET_WEBHOOKS_SUCCESS',
    'GET_WEBHOOKS_ERROR',
    'DELETE_WEBHOOK',
    'DELETE_WEBHOOK_SUCCESS',
    'DELETE_WEBHOOK_ERROR',
    'SIMULATE_WEBHOOK',
    'SIMULATE_WEBHOOK_ERROR',
    'SAVE_WEBHOOK',
    'SAVE_WEBHOOK_SUCCESS',
    'SAVE_WEBHOOK_ERROR',
    'GET_INTEGRATIONS',
    'GET_INTEGRATIONS_SUCCESS',
    'GET_INTEGRATIONS_ERROR',
    'GET_ZAPIER_KEY',
    'GET_ZAPIER_KEY_SUCCESS',
    'GET_ZAPIER_KEY_ERROR',
    'GENERATE_ZAPIER_KEY',
    'GENERATE_ZAPIER_KEY_SUCCESS',
    'GENERATE_ZAPIER_KEY_ERROR',
    'UNINSTALL_INTEGRATION',
    'UNINSTALL_INTEGRATION_SUCCESS',
    'UNINSTALL_INTEGRATION_ERROR',
    'GET_ESP_FIELDS',
    'GET_ESP_FIELDS_SUCCESS',
    'GET_ESP_FIELDS_ERROR',
    'SAVE_ESP_FIELDS',
    'SAVE_ESP_FIELDS_SUCCESS',
    'SAVE_ESP_FIELDS_ERROR',
    'GET_ESP_LISTS',
    'GET_ESP_LISTS_SUCCESS',
    'GET_ESP_LISTS_ERROR',
    'INSTALL_ESP',
    'INSTALL_ESP_SUCCESS',
    'INSTALL_ESP_ERROR',
    'OPEN_UNINSTALL_MODAL',
    'OPEN_UNINSTALL_MODAL_SUCCESS',
    'OPEN_UNINSTALL_MODAL_ERROR',
    'START_STRIPE_EXPRESS',
    'START_STRIPE_EXPRESS_SUCCESS',
    'START_STRIPE_EXPRESS_ERROR',
    'LOGIN_STRIPE_EXPRESS',
    'LOGIN_STRIPE_EXPRESS_SUCCESS',
    'LOGIN_STRIPE_EXPRESS_ERROR',
]

const creators = ({ types }) => ({
    openUninstallModal: (payload) => ({ type: types.OPEN_UNINSTALL_MODAL, payload }),
    getWebhooks: () => ({ type: types.GET_WEBHOOKS }),
    saveWebhooks: (payload) => ({ type: types.SAVE_WEBHOOK, payload }),
    deleteWebhook: (payload) => ({ type: types.DELETE_WEBHOOK, payload }),
    simulateWebhook: (payload) => ({ type: types.SIMULATE_WEBHOOK, payload }),
    addIntegration: (payload) => ({ type: types.ADD_INTEGRATION, payload }),
    getIntegrations: () => ({ type: types.GET_INTEGRATIONS }),
    getZapierKey: () => ({ type: types.GET_ZAPIER_KEY }),
    generateZapierKey: (payload) => ({ type: types.GENERATE_ZAPIER_KEY, payload }),
    uninstallIntegration: (payload) => ({ type: types.UNINSTALL_INTEGRATION, payload }),
    getEspFields: (payload) => ({ type: types.GET_ESP_FIELDS, payload }),
    saveEspFields: (payload) => ({ type: types.SAVE_ESP_FIELDS, payload }),
    getESPLists: () => ({ type: types.GET_ESP_LISTS }),
    installESP: (payload) => ({ type: types.INSTALL_ESP, payload }),
    startStripeExpress: () => ({ type: types.START_STRIPE_EXPRESS }),
    loginStripeExpress: () => ({ type: types.LOGIN_STRIPE_EXPRESS }),
})

export { types, creators }
