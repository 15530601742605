import React from 'react'
import {  Field, useForm, useFormState } from 'react-final-form'
import { useCallback } from 'react'

import Link from '@piconetworks/pkg-link'
import OneTimePasscode from '@piconetworks/pkg-otp'

import { Flexbox, Grid } from '@piconetworks/pkg-box'
import Boutton from '@piconetworks/pkg-boutton'
import styles from './OTP.module.scss'
import WizardFeature from 'features/wizard'
import { useOtp } from 'features/verifyWithOtp'

const OTP = () => {
    const { submit, mutators } = useForm()
    const { values, hasValidationErrors, submitting, hasSubmitErrors, dirtySinceLastSubmit } = useFormState()
    const { sendOtp, confirmOtp } = useOtp()
    const { goToPreviousStep } = WizardFeature.Context.useWizard()
    const lastFourDigits = values.phone.substr(values.phone.length - 4)
    const phoneValue =  `${values?.country_code}${values?.phone}`
    const handleSubmit = useCallback(async () => {
        try {
            await confirmOtp({
                code: values.otp,
                phone: phoneValue
            })
            return submit()
            
        } catch (error) {
            mutators.setError(error.message)
        }
    }, [confirmOtp, mutators, submit, values])
    return (
        <Flexbox.Container flexDirection='column'>
            <p className={styles.text}>You will be given a new toll-free number from which you can send messages, but we first need to confirm your personal details.</p>
            <p className={styles.text}>Enter the code sent to your phone number ending in {lastFourDigits}. Didn’t receive a code? <Link variant='primary' onClick={(e) => sendOtp(phoneValue)}>Resend it</Link> or <Link variant='primary' onClick={(e) => goToPreviousStep(e)}>change</Link> your contact info.</p>
            <Grid.Row>
                <Field name='otp'>
                    {({input, meta}) => {
                        return (
                            <div>
                                <OneTimePasscode
                                    isDisabled={submitting}
                                    isErrored={meta.touched ? meta.error : ''}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    numInputs={6}
                                    setCurrentPasscode={(event, value) => input.onChange(value)}
                                    {...input}
                                />
                            </div>
                        )
                    }}
                </Field>
            </Grid.Row>
            <Flexbox.Child alignSelf='end'>
                <Boutton 
                    type='button' 
                    variant='primary' 
                    onClick={handleSubmit}
                    disabled={hasValidationErrors || submitting || (hasSubmitErrors && dirtySinceLastSubmit === false)}
                >
                    Confirm
                </Boutton>
            </Flexbox.Child>
        </Flexbox.Container>
    )
}

export default OTP