import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import cx from 'classnames'

import usePublisherId from 'hooks/usePublisherId'

import Tooltip from 'components/Tooltip'
import useNfcTags from '../useNfcTags'
import styles from './NfcFormTooltip.module.scss'

const NfcFormTooltip = ({ formId, placement = "top" }) => {
    const publisherId = usePublisherId()
    const dispatch = useDispatch()

    const { fetchTagsByCreator, getTagsByCreator } = useNfcTags()

    useEffect(() => {
        if (publisherId) {
            fetchTagsByCreator()
        }
    }, [publisherId, fetchTagsByCreator, dispatch])
    
    const formIds = getTagsByCreator?.map?.((tag) => tag.formId)
    const hypeCardInUse = formIds?.includes?.(formId)

    return hypeCardInUse && (
        <div className={styles.iconContainer}>
            <Tooltip
                label="Your Hype Card directs to this page"
                placement={placement}
                className={styles.iconContainer}
            >
                <span className={cx(styles.icon, 'icon-icon_nfc')} />
            </Tooltip>
        </div>
    )
}

export default NfcFormTooltip
