import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'
import fetchSegments from './fetchSegments'
import createSegment from './createSegment'
import updateSegment from './updateSegment'
import deleteSegment from './deleteSegment'

const segmentAdapter = createEntityAdapter({
    selectId: (segment: any) => segment?.id,
})

const initialState = segmentAdapter.getInitialState({
    loading: false,
    error: null,

})

const segmentSlice = createSlice({
    name,
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchSegments.pending]: (state: any, { payload }: any) => {
            state.loading = true
            state.error = null
        },
        [fetchSegments.fulfilled]: (state: any, { payload }: any) => {
            segmentAdapter.setAll(state, payload || [])
            state.loading = false
            state.error = null
        },
        [fetchSegments.rejected]: (state: any, { payload: error }: any) => {
            state.loading = false
            state.error = error
            segmentAdapter.removeAll(state)
        },
        [createSegment.pending]: (state: any) => {
            state.loading = true
            state.error = null
        },
        [createSegment.fulfilled]: (state: any, { payload }: any) => {
            segmentAdapter.upsertOne(state, payload)
            state.loading = false
            state.error = null
        },
        [createSegment.rejected]: (state: any, { payload: error }: any) => {
            state.loading = false
            state.error = error
        },
        [updateSegment.pending]: (state: any) => {
            state.loading = true
            state.error = null
        },
        [updateSegment.fulfilled]: (state: any, { payload }: any) => {
            segmentAdapter.upsertOne(state, payload)
            state.loading = false
            state.error = null
        },
        [updateSegment.rejected]: (state: any, { payload: error }: any) => {
            state.loading = false
            state.error = error
        },
        [deleteSegment.pending]: (state: any) => {
            state.loading = true
            state.error = null
        },
        [deleteSegment.fulfilled]: (state: any, { payload }: any) => {
            segmentAdapter.removeOne(state, payload)
            state.loading = false
            state.error = null
        },
        [deleteSegment.rejected]: (state: any, { payload: error }: any) => {
            state.loading = false
            state.error = error
        }
    },
})

// SELECTORS
const getSegments = (state: any) => state[name]

const {
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
} = segmentAdapter.getSelectors((state: any) => state[name])

const segmentsIsLoading = createDraftSafeSelector(
    getSegments,
    (segment: any) => segment.loading,
)
const segmentHasError = createDraftSafeSelector(
    getSegments,
    (segment: any) => segment.error,
)

// GENERAL
const SegmentsInitialState = {
    [name]: initialState,
}

const SegmentsReducer = {
    [name]: segmentSlice.reducer,
}

const takes = [
    ...fetchSegments.takes,
    ...createSegment.takes,
    ...updateSegment.takes,
    ...deleteSegment.takes
]

// EXPORTS
export default segmentSlice.reducer

export {
    SegmentsInitialState as initialState,
    SegmentsReducer as reducer,

    takes,

    fetchSegments,
    createSegment,
    updateSegment,
    deleteSegment,

    segmentsIsLoading,
    segmentHasError,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,

}
