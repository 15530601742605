import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'

import ModalModule from 'modules/modal'
import AccountModule from 'modules/account'

import endpoints from 'utils/lib/endpoints'

import logger from 'lib/logger'

function* editFile({
    duck,
    payload,
}) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: `${endpoints.FILES}/${payload.id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                data: payload
            }
        })

        if (data) {
            yield put({
                type: duck.types.EDIT_FILE_SUCCESS,
                payload: data,
            })

            yield put(
                ModalModule.creators.closeModal({
                    key: 'editFile',
                    actionFinished: true
                })
            )
        } else {
            throw new Error('Error editing file')
        }
    } catch (error) {
        logger.error('editFile error', {}, error)
        yield put({
            type: duck.types.EDIT_FILE_ERROR,
            error,
        })
    }
}

export default editFile
