import { call, put, take } from 'redux-saga/effects'
import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
} from '../dependencies'

const generateClaim = createAsyncSaga(
    `${name}/generateClaim`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            const response = yield request({
                method: 'post',
                url: endpoints.GENERATE_CLAIM,
                headers: {
                    publisherid: publisherId,
                },
            })

            const claim = response?.claim

            if (!claim) {
                throw new Error('no claim returned')
            }

            yield fulfilled({
                claim,
            })
        } catch (error) {
            logger.error('generateClaim error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default generateClaim
