import {
    initialState,
    reducer,
    takes,
    fetchCards,
    fetchCardById,
    setActiveEditionId,
    setActiveCardId,
    fetchTagsByCreator,
    getTagsByCreator,
    updateTag,
    countTagsByCreator,
    selectById,
} from './slice'

import useCards from './useCards'

export default {
    initialState,
    reducer,
    takes,
}

export {
    useCards,
    fetchCards,
    fetchCardById,
    setActiveEditionId,
    setActiveCardId,
    fetchTagsByCreator,
    getTagsByCreator,
    updateTag,
    countTagsByCreator,
    selectById,
}
