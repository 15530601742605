import { putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* sortFreeAccessRulesOrder({ types, payload }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { status, data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: `${endpoints.FREE_ACCESS}/order`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: { ...payload }
            }
        })

        if (status === 200) {
            yield put({
                type: types.SORT_FREE_ACCESS_RULES_ORDER_SUCCESS,
                payload: { type: payload.type, data: data.active}
            })
        }

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: types.SORT_FREE_ACCESS_RULES_ORDER_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: types.SORT_FREE_ACCESS_RULES_ORDER_ERROR,
                error
            })
        }
    }
}

export default sortFreeAccessRulesOrder
