import { PicoAnalytics } from '@piconetworks/pkg-pico-analytics'

const metrics_endpoint = process.env.METRICS_ENDPOINT || 'https://metrics.pico.tools/extract'
const segment_write_key = process.env.SEGMENT_WRITE_KEY

let analyticInstance = null

const getAnalytics = () => {
    try {
        if (analyticInstance !== null) {
            return analyticInstance
        }
    
        analyticInstance = PicoAnalytics({
            metrics_endpoint,
            context: 'dashboard',
            segment: { writeKey: segment_write_key },
        })
    
        return analyticInstance            
    } catch (error) {
        return null
    }
}

export default getAnalytics
