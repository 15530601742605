import { all, call, put, take } from 'redux-saga/effects'

import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
    fetchEditions,
    fetchCards,
    fetchCardById,
    setActiveEditionId,
    setActiveCardId,
} from '../dependencies'

import downloadStickers from './downloadStickers'

const printCustomStickers = createAsyncSaga(
    `${name}/printCustomStickers`,
    function* ({
        payload: {
            text = 'https://hype.co',
            count = 1,
            qrCodeOverrides = {},
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const tags: { id?: string, short_code?: string, text_override?: string }[] = []

            for (let i = 0; i < count; i++) {
                tags.push({
                    text_override: text,
                })
            }    

            yield call(downloadStickers, tags, qrCodeOverrides)

            yield fulfilled()
        } catch (error) {
            logger.error('printCustomStickers error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default printCustomStickers
