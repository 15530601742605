import { putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* fetchSelectedUserActivity(action) {
    const { payload, duck } = action
    try {
        if (payload.user_state_id) {
            const publisherid = yield select(AccountModule.selectors.getPublisherId)

            const user_state_id = payload.user_state_id
            const { data } = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'get',
                    url: `${endpoints.USERS}/profile/${user_state_id}/activity`,
                    timeout: 30000,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid
                    }
                }
            })

            yield put({
                type: duck.types.FETCH_SELECTED_USER_ACTIVITY_SUCCESS,
                payload: data
            })
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.FETCH_SELECTED_USER_ACTIVITY_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.FETCH_SELECTED_USER_ACTIVITY_ERROR,
                error
            })
        }
    }

}

export default fetchSelectedUserActivity
