import React from 'react'
import cx from 'classnames'
import { Box, Avatar } from '@piconetworks/ui'
import styles from '../../navigation.module.scss'
import addTestId from 'utils/lib/addTestId'
import Navigations from 'features/navigations'
import ScreenSizeContext from 'utils/contexts/ScreenSize/context'
import { useSelector } from 'react-redux'
import AccountModule from 'modules/account'
import { Item } from '@piconetworks/pkg-nav-link'

const MobileMenuButton = () => {
    const { isOpenMobileMenu, setIsOpenMobileMenu } = Navigations.context.useMenu()

    const accountInfo = useSelector(AccountModule.selectors.getAccountInfo)

    const renderMobileMenu = () => (
        <Item
            className={cx(styles.brandColor, 'd-xs-block', 'd-lg-none')}
            onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}
            data-testid={addTestId('pico-logo-click')}
        >
            <Box.Flexbox.Container alignItems="center">
                <Avatar className={styles.headerAvatar} size="40px" initials={accountInfo.name} src={accountInfo.icon} />
            </Box.Flexbox.Container>
        </Item>
    )

    return (
        <ScreenSizeContext.Consumer>{({ isLgUp }) => (isLgUp ? null : renderMobileMenu())}</ScreenSizeContext.Consumer>
    )
}

export default MobileMenuButton
