import { draftjsToMd } from 'draftjs-md-converter'
import {
    SIGNUP_TYPE,
    PAYMENT_TYPE,
    GROUP_TYPE,
    ACK_DEFAULT_PAYMENT_TYPE,
    ACK_DEFAULT_SIGNUP_TYPE,
    ACK_TRIAL_TYPE,
    ACK_DONATION_SINGLE_TYPE,
    ACK_DONATION_RECURRING_TYPE,
    ACK_PASS_TYPE
} from 'utils/constants/offers'
import { getProductTypes } from './formatFormValues'
import {
    ACK_BILLING_ADMIN,
    ACK_GROUP_MEMBER,
    BILLING_ADMIN_PAYMENT,
    BILLING_ADMIN_SIGNUP,
    GROUP_MEMBER_JOIN,
    GROUP_MEMBER_SIGNUP
} from 'utils/constants/groups'
import AWSUploadError from '../../../../utils/errors/AWSUploadError'
import { getS3SignedUrl, pushFileToS3 } from 'utils/lib/s3'

//init bucket

let bucketName = process.env.CUSTOM_TEXT_URL.replace(/(.*?)\/(customtext.*)/, "$2")

/*
 * Converts custom text fields (rich text, and acknowledgment text) to markdown,
 * uploads to s3, and then returns the uploaded url to send to API
 */
const getAndUploadMarkdown = async (values, publisher, hasTypePayment = true, duplicate = false) => {
    const {
        signup_rich_text: {
            value: signup_rich_text,
        } = {},
        payment_rich_text: {
            value: payment_rich_text,
        } = {},
        ack_default_payment: {
            value: ack_default_payment_rich_text,
        } = {},
        ack_default_signup: {
            value: ack_default_signup_rich_text,
        } = {},
        ack_trial: {
            value: ack_trial_rich_text,
        } = {},
        ack_donation_single: {
            value: ack_donation_single_rich_text,
        } = {},
        ack_donation_recurring: {
            value: ack_donation_recurring_rich_text,
        } = {},
        ack_pass: {
            value: ack_pass_rich_text
        } = {},
        billing_admin_payment_rich_text: {
            value: billing_admin_payment_rich_text
        } = {},
        billing_admin_signup_rich_text: {
            value: billing_admin_signup_rich_text
        } = {},
        group_member_join_rich_text: {
            value: group_member_join_rich_text
        } = {},
        group_member_signup_rich_text: {
            value: group_member_signup_rich_text
        } = {},
        ack_billing_admin: {
            value: ack_billing_admin_rich_text
        } = {},
        ack_group_member: {
            value: ack_group_member_rich_text
        } = {},
        products,
    } = values || {}

    const {
        isDonation,
        hasTrial,
        isPass,
        isDefault,
    } = getProductTypes(products)

    // convert current draftJs Form values to Mark down
    const signup_markdown_text = signup_rich_text && draftjsToMd(signup_rich_text)
    const payment_markdown_text = payment_rich_text && draftjsToMd(payment_rich_text)
    const billing_admin_payment_markdown_text = billing_admin_payment_rich_text && values.type.includes(GROUP_TYPE) && draftjsToMd(billing_admin_payment_rich_text)
    const billing_admin_signup_markdown_text = billing_admin_signup_rich_text && values.type.includes(GROUP_TYPE) && draftjsToMd(billing_admin_signup_rich_text)
    const group_member_join_markdown_text = group_member_join_rich_text && values.type.includes(GROUP_TYPE) && draftjsToMd(group_member_join_rich_text)
    const group_member_signup_markdown_text = group_member_signup_rich_text && values.type.includes(GROUP_TYPE) && draftjsToMd(group_member_signup_rich_text)
    const ack_default_payment_markdown_text = ack_default_payment_rich_text && (isDefault || hasTrial) && hasTypePayment && draftjsToMd(ack_default_payment_rich_text)
    const ack_default_signup_markdown_text = ack_default_signup_rich_text && values.type.includes(SIGNUP_TYPE) && draftjsToMd(ack_default_signup_rich_text)
    const ack_trial_markdown_text = hasTrial && ack_trial_rich_text && draftjsToMd(ack_trial_rich_text)
    const ack_donation_single_markdown_text = isDonation && ack_donation_single_rich_text && draftjsToMd(ack_donation_single_rich_text)
    const ack_donation_recurring_markdown_text = ack_donation_recurring_rich_text && isDonation && draftjsToMd(ack_donation_recurring_rich_text)
    const ack_pass_markdown_text = isPass && ack_pass_rich_text && draftjsToMd(ack_pass_rich_text)
    const ack_billing_admin_markdown_text = ack_billing_admin_rich_text && values.type.includes(GROUP_TYPE) && draftjsToMd(ack_billing_admin_rich_text)
    const ack_group_member_markdown_text = ack_group_member_rich_text && values.type.includes(GROUP_TYPE) && draftjsToMd(ack_group_member_rich_text)

    const getFileName = (type) => {
        if (!duplicate && values[type]?.file_name) {
            return values[type].file_name
        } else {
            return `${publisher.username}-${Date.now()}-offer-${type}-markdown.md`
        }
    }

    // create md files
    const signup_markdown_file =
        signup_markdown_text &&
        new File([signup_markdown_text], getFileName(`${SIGNUP_TYPE}_rich_text`))

    const payment_markdown_file =
        payment_markdown_text &&
        new File([payment_markdown_text], getFileName(`${PAYMENT_TYPE}_rich_text`))

    const ack_default_payment_markdown_file =
        ack_default_payment_markdown_text &&
        new File([ack_default_payment_markdown_text], getFileName(ACK_DEFAULT_PAYMENT_TYPE))

    const ack_default_signup_markdown_file =
        ack_default_signup_markdown_text &&
        new File([ack_default_signup_markdown_text], getFileName(ACK_DEFAULT_SIGNUP_TYPE))

    const ack_trial_markdown_file =
        ack_trial_markdown_text &&
        new File([ack_trial_markdown_text], getFileName(ACK_TRIAL_TYPE))

    const ack_donation_single_markdown_file =
        ack_donation_single_markdown_text &&
        new File([ack_donation_single_markdown_text], getFileName(ACK_DONATION_SINGLE_TYPE))

    const ack_donation_recurring_markdown_file =
        ack_donation_recurring_markdown_text &&
        new File([ack_donation_recurring_markdown_text], getFileName(ACK_DONATION_RECURRING_TYPE))

    // api handles the default type for pass as well
    const ack_pass_markdown_file =
        ack_pass_markdown_text &&
        new File([ack_pass_markdown_text], getFileName(ACK_DEFAULT_PAYMENT_TYPE))

    // group admin acknowledgment
    const ack_billing_admin_markdown_file =
        ack_billing_admin_markdown_text &&
        new File([ack_billing_admin_markdown_text], getFileName(ACK_BILLING_ADMIN))

    // group admin payment custom text
    const billing_admin_payment_markdown_file =
        billing_admin_payment_markdown_text &&
        new File([billing_admin_payment_markdown_text], getFileName(BILLING_ADMIN_PAYMENT.TYPE))

    // group admin signup custom text
    const billing_admin_signup_markdown_file =
        billing_admin_signup_markdown_text &&
        new File([billing_admin_signup_markdown_text], getFileName(BILLING_ADMIN_SIGNUP.TYPE))

    // group member acknowledgement
    const ack_group_member_markdown_file =
        ack_group_member_markdown_text &&
        new File([ack_group_member_markdown_text], getFileName(ACK_GROUP_MEMBER))

    // group member join custom text
    const group_member_join_markdown_file =
        group_member_join_markdown_text &&
        new File([group_member_join_markdown_text], getFileName(GROUP_MEMBER_JOIN.TYPE))

    // group member signup custom text
    const group_member_signup_markdown_file =
        group_member_signup_markdown_text &&
        new File([group_member_signup_markdown_text], getFileName(GROUP_MEMBER_SIGNUP.TYPE))

    let signup_rich_text_url,
        payment_rich_text_url,
        ack_default_payment_rich_text_url,
        ack_default_signup_rich_text_url,
        ack_trial_rich_text_url,
        ack_donation_single_rich_text_url,
        ack_donation_recurring_rich_text_url,
        ack_pass_rich_text_url,
        billing_admin_payment_rich_text_url,
        billing_admin_signup_rich_text_url,
        group_member_join_rich_text_url,
        group_member_signup_rich_text_url,
        ack_billing_admin_rich_text_url,
        ack_group_member_rich_text_url

    const file_type = 'text/markdown'

    // upload the markdown files if they exist
    for await (let { type, file } of [
        { type: SIGNUP_TYPE, file: signup_markdown_file },
        { type: PAYMENT_TYPE, file: payment_markdown_file },
        { type: ACK_DEFAULT_PAYMENT_TYPE, file: ack_default_payment_markdown_file },
        { type: ACK_DEFAULT_SIGNUP_TYPE, file: ack_default_signup_markdown_file },
        { type: ACK_TRIAL_TYPE, file: ack_trial_markdown_file },
        { type: ACK_DONATION_SINGLE_TYPE, file: ack_donation_single_markdown_file },
        { type: ACK_DONATION_RECURRING_TYPE, file: ack_donation_recurring_markdown_file },
        { type: ACK_PASS_TYPE, file: ack_pass_markdown_file },
        { type: ACK_BILLING_ADMIN, file: ack_billing_admin_markdown_file },
        { type: ACK_GROUP_MEMBER, file: ack_group_member_markdown_file },
        { type: BILLING_ADMIN_PAYMENT.TYPE, file: billing_admin_payment_markdown_file },
        { type: BILLING_ADMIN_SIGNUP.TYPE, file: billing_admin_signup_markdown_file },
        { type: GROUP_MEMBER_JOIN.TYPE, file: group_member_join_markdown_file },
        { type: GROUP_MEMBER_SIGNUP.TYPE, file: group_member_signup_markdown_file }
    ]) {
        if (file) {
            let location
            try {
                // get the pre signed url
                const { url, location: s3_location } = await getS3SignedUrl({ bucket: bucketName, file_name: file.name, file_type })
                location = s3_location

                // upload the file
                await pushFileToS3(url, { file_content: file, file_type })
            } catch (error) {
                throw new AWSUploadError({
                    message: 'There is an issue while uploading custom text file',
                    details: error
                })
            }

            switch (type) {
                case SIGNUP_TYPE:
                    signup_rich_text_url = location
                    break
                case PAYMENT_TYPE:
                    payment_rich_text_url = location
                    break
                case ACK_DEFAULT_PAYMENT_TYPE:
                    ack_default_payment_rich_text_url = location
                    break
                case ACK_DEFAULT_SIGNUP_TYPE:
                    ack_default_signup_rich_text_url = location
                    break
                case ACK_TRIAL_TYPE:
                    ack_trial_rich_text_url = location
                    break
                case ACK_DONATION_SINGLE_TYPE:
                    ack_donation_single_rich_text_url = location
                    break
                case ACK_DONATION_RECURRING_TYPE:
                    ack_donation_recurring_rich_text_url = location
                    break
                case ACK_PASS_TYPE:
                    ack_pass_rich_text_url = location
                    break
                case ACK_BILLING_ADMIN:
                    ack_billing_admin_rich_text_url = location
                    break
                case ACK_GROUP_MEMBER:
                    ack_group_member_rich_text_url = location
                    break
                case BILLING_ADMIN_PAYMENT.TYPE:
                    billing_admin_payment_rich_text_url = location
                    break
                case BILLING_ADMIN_SIGNUP.TYPE:
                    billing_admin_signup_rich_text_url = location
                    break
                case GROUP_MEMBER_JOIN.TYPE:
                    group_member_join_rich_text_url = location
                    break
                case GROUP_MEMBER_SIGNUP.TYPE:
                    group_member_signup_rich_text_url = location
                    break
            }
        }
    }

    return {
        signup_rich_text_url,
        payment_rich_text_url,
        ack_default_payment_rich_text_url,
        ack_default_signup_rich_text_url,
        ack_trial_rich_text_url,
        ack_donation_single_rich_text_url,
        ack_donation_recurring_rich_text_url,
        ack_pass_rich_text_url,
        ack_billing_admin_rich_text_url,
        ack_group_member_rich_text_url,
        billing_admin_payment_rich_text_url,
        billing_admin_signup_rich_text_url,
        group_member_join_rich_text_url,
        group_member_signup_rich_text_url
    }
}

export const getAndUploadTextBlockMarkdown = async (value, publisher) => {
    // convert current draftJs Form values to Mark down
    const markdown_text = draftjsToMd(value)

    const filename = `${publisher.username}-${Date.now()}-textblock-markdown.md`

    // create md files
    const file = new File([markdown_text], filename)
    const file_type = 'text/markdown'

    if (file) {
        try {
            // get the pre signed url
            const { url, location } = await getS3SignedUrl({ bucket: bucketName, file_name: file.name, file_type })

            // upload the file
            await pushFileToS3(url, { file_content: file, file_type })

            return location
        } catch (error) {
            throw new AWSUploadError({
                message: 'There is an issue while uploading product custom text file',
                details: error,
            })
        }
    }
}

export default getAndUploadMarkdown
