import reducer, { initialState } from './reducer'
import actions from './actions'
import createDuck from '../baseDuck'
import sagas from './sagas'
import selectors from './selectors'
import takes from './takes'

export default createDuck({
    store: 'modal',
    initialState,
    path: '/modal'
}).extend({
    types: actions.types,
    reducer,
    creators: actions.creators,
    sagas,
    selectors,
    takes
})
