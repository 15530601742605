import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import SettingsModule from '../index'

const useDefaultSource = ({
    onChange = (defaultSource = null) => {},
} = {}) => {
    const isBillingInformationStatusReady = useSelector(SettingsModule.selectors.getIsBillingInformationStatusReady)
    const defaultSourceFromSources = useSelector(SettingsModule.selectors.getDefaultSourceFromSources)

    const [defaultSource, setDefaultSource] = useState(null)

    useEffect(() => {
        if (isBillingInformationStatusReady && defaultSourceFromSources) {
            setDefaultSource(defaultSourceFromSources)
        }
    }, [
        isBillingInformationStatusReady,
        defaultSourceFromSources,
        setDefaultSource,
    ])

    useEffect(() => {
        onChange(defaultSource)
    }, [defaultSource])

    return defaultSource
}

export default useDefaultSource
