import { contributionBinSingularCapitalized } from 'features/ContributionLang'

export const DURATION_OPTIONS = [
    {
        value: 'day',
        label: 'day'
    },
    {
        value: 'week',
        label: 'week'
    },
    {
        value: 'month',
        label: 'month'
    },
    {
        value: 'year',
        label: 'year'
    },
]

export const UNIT_FIELD_MAPPING = {
    year: 2,
    month: 24,
    week: 104
}

export const PASS_TYPE = 'pass'

export const SUBSCRIPTION_TYPE = 'subscription'

export const PLEDGE_TYPE = 'pledge'

export const TRIAL_TYPE = 'trial'

export const INDIVIDUAL_SUBSCRIPTION_TYPE = 'individual'

export const GROUP_SUBSCRIPTION_TYPE = 'group'

export const DONATION_SUGGESTIONS = 'donation'

export const DEFAULT_CURRENCY = '$'

// used to classify the parent url of each product type
export const PRODUCT_TYPES = {
    [PASS_TYPE]: 'passes',
    [INDIVIDUAL_SUBSCRIPTION_TYPE]: 'subscriptions',
    [GROUP_SUBSCRIPTION_TYPE]: 'subscriptions',
    [DONATION_SUGGESTIONS]: 'donationboxes'
}

// when products are submitted to api, it expects these types
export const PRODUCT_TYPES_API = {
    [PASS_TYPE]: 'pass',
    [INDIVIDUAL_SUBSCRIPTION_TYPE]: 'subscription',
    [GROUP_SUBSCRIPTION_TYPE]: 'group',
    [DONATION_SUGGESTIONS]: 'donation'
}

export const PRODUCT_TYPES_NAME = {
    [PASS_TYPE]: 'Pass',
    [INDIVIDUAL_SUBSCRIPTION_TYPE]: 'Individual Subscription',
    [GROUP_SUBSCRIPTION_TYPE]: 'Group Subscription',
    [DONATION_SUGGESTIONS]: 'Tip Jar',
}

export const PRODUCT_TYPES_TABLE_DISPLAY = {
    [SUBSCRIPTION_TYPE]: 'Individual',
    [GROUP_SUBSCRIPTION_TYPE]: 'Group'
}

// all types of product options per product type
export const PRODUCT_OPTION_TYPES = {
    subscriptions: ['subscription', 'group-override', 'group'],
    donations: ['recurring-donation', 'single-donation'],
    passes: ['pass'],
}

export const EFFECTED_USER_BEHAVIOR = {
    SAVE: 'SAVE',
    CANT_SAVE: 'CANT_SAVE',
}
