import {
    put, putResolve, select, call
} from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import { PICO_API_URL } from '@piconetworks/pkg-endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
import { FORM_ERROR } from 'final-form'

import logger from 'lib/logger'

function* setToUnclaimed({ payload, callback }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { username } = payload

        if (!username) {
            throw Error('username is required')
        }
        if (!publisherid) {
            throw Error('publisherid must be provided')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                baseURL: PICO_API_URL(),
                url: '/setup/unclaimed',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherid,
                },
                timeout: 30000,
                data: {
                    username
                },
            },
        })

        yield put(
            AppModule.creators.addNotification({
                type: 'success',
                message:
                    `${data.username} set to unclaimed successfully.`
            })
        )

    } catch (error) {
        if (error?.response?.data?.message) {
            yield put(
                AppModule.creators.addNotification({
                    type: 'danger',
                    message:
                        `Something went wrong during the account update of ${payload.username}.`
                })
            )
            const submissionError = {
                [FORM_ERROR]: error.response.data.message,
            }
            if (error.response.data.errors) {
                const fieldErrors = Object.assign(submissionError, ...error.response.data.errors)
                yield call(callback, fieldErrors)
            } else {
                yield call(callback, submissionError)
            }
        } else {
            yield call(callback, {
                [FORM_ERROR]: error.message,
            })
        }

        logger.error('setToUnclaimed error', {}, error)
    }
}

export default setToUnclaimed
