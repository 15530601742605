import {
    initialState,
    reducer,
    takes,
    updateVcard,
    getVcardCacheString,
} from './slice'

import useVcard from './hooks/useVcard'

export default {
    initialState,
    reducer,
    takes,
}

export {
    updateVcard,
    getVcardCacheString,
    useVcard,
}
