import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Flexbox } from '@piconetworks/pkg-box'
import Boutton from '@piconetworks/pkg-boutton'
import Loading from '@piconetworks/pkg-loading'
import Form from '@piconetworks/pkg-form'

import styles from './NewBlastForm.module.scss'
import Overflow from 'components/Overflow'
import { areaCodes } from 'utils/lib/areaCodes'
import { Field, useForm, useFormState } from 'react-final-form'
import { useRegisterPhoneNumber } from 'features/registerPhoneNumber'

const RegisterPhoneNumber = () => {
    const { submit, mutators, resetFieldState, change } = useForm()
    const { values, submitting } = useFormState()
    const { getPhoneNumber, requestInProgress } = useRegisterPhoneNumber()
    const [ loading, setLoading ] = useState(false)

    const handleSkip = useCallback(async() => {
        setLoading(true)
        change('area_code_override', null)
        resetFieldState('area_code_override')
        return await handleSubmit()
    }, [handleSubmit, change, resetFieldState])

    const handleSubmit = useCallback(async() => {
        try {
            setLoading(true)
            const phoneValue =  `${values?.country_code}${values?.phone}`
            // Put all info there
            const payload = {
                business_street_address : values.address1,
                business_street_address2: values?.address2,
                business_city: values.city,
                business_state_province_region: values.state,
                business_postal_code: values.post_code,
                business_country: values.country,
                business_contact_firstname: values.first_name,
                business_contact_lastname: values.last_name,
                business_contact_email: values.email,
                business_contact_phone: phoneValue,
                business_name: values.business_name,
                ...values.area_code_override && {
                    area_code_override: values.area_code_override,
                }
            }
            await getPhoneNumber(payload)
            submit()
            setLoading(false)
        } catch (error) {
            mutators.setError(error.message)
        }
    }, [mutators, values, submit, getPhoneNumber])
    const typeaheadRef = React.createRef()
    return (
        <div
            className={styles.form}
        >
            <Flexbox.Container flexDirection='column' >
                <p className={styles.text}>If available, a phone number with your preferred area code will be registered for your account.</p>
                {loading ? <Loading /> :
                    <Field name='area_code_override' onChange={handleSubmit}>
                        {props => {
                            const { input, meta } = props
                            return (
                                <Form.Typeahead.Sync
                                    id={input.name}
                                    ref={typeaheadRef}
                                    autoFocus={true}
                                    labelKey='label'
                                    minLength={1}
                                    selectedAreaCode={input.value}
                                    placeholder='Specify area code (optional)'
                                    options={areaCodes.map((item) => ({ ...item, selected: item.label === input.value }))}
                                    selected={input.value}
                                    stayOpen={true}
                                    handleRenderMenu={(options) => {
                                        return (
                                            <Flexbox.Container flexDirection="column" className={styles.searchWrapper}>
                                                {options.map((option) => option.value && (
                                                    <Overflow.Option
                                                        key={option.value}
                                                        className={cx(styles.searchOption, { 
                                                            [styles.selected]: option.selected,
                                                        })}
                                                        onClick={() => {
                                                            input.onChange(option.label)
                                                            return handleSubmit()
                                                        }}
                                                    >
                                                        <Flexbox.Container flexDirection="column">
                                                            {option.label}
                                                        </Flexbox.Container>{' '}
                                                    </Overflow.Option>
                                                ))}
                                            </Flexbox.Container>
                                        )
                                    }}
                                />
                            )
                        }}
                    </Field>
                }
            </Flexbox.Container>
            
            <Flexbox.Container justifyContent='between' className={styles.footer}>
                <div></div>
                <Flexbox.Container alignItems='center' >

                    <div className={styles.positionRelative}>
                        <Boutton
                            type='button'
                            variant='secondary'
                            disabled={requestInProgress}
                            size='sm'
                            onClick={handleSkip}
                        >
                            Skip
                        </Boutton>
                    </div>


                </Flexbox.Container>
            </Flexbox.Container>

        </div>
    )
}

RegisterPhoneNumber.propTypes = {
    setPhoneNumberClaimed: PropTypes.func,
}

export default React.memo(RegisterPhoneNumber)
