import getOffers from './getOffers'
import getOffer from './getOffer'
import createOffer from './createOffer'
import deleteOffer from './deleteOffer'
import updateOffer from './updateOffer'
import restoreOffer from './restoreOffer'
import getConversionRate from './getConversionRate'
import sortOrder from './sortOrder'
import duplicateOffer from './duplicateOffer'

const sagas = (duck) => ({
    *getOffers(payload) {
        yield getOffers({ ...payload, ...duck })
    },
    *getOffer(payload) {
        yield getOffer({ ...payload, ...duck })
    },
    *createOffer(payload) {
        yield createOffer({ ...payload, ...duck })
    },
    *deleteOffer(payload) {
        yield deleteOffer({ ...payload, ...duck })
    },
    *restoreOffer(payload) {
        yield restoreOffer({ ...payload, ...duck })
    },
    *updateOffer(payload) {
        yield updateOffer({ ...payload, ...duck })
    },
    *getConversionRate(){
        yield getConversionRate({...duck})
    },
    *sortOrder(payload) {
        yield sortOrder({ ...payload, ...duck })
    },
    *duplicateOffer(payload) {
        yield duplicateOffer({ ...payload, ...duck })
    }
})

export default sagas
