import { put } from 'redux-saga/effects'

function* requestOpenModal(action) {
    const { payload, duck } = action
    // if we need to do anything before showing the modal, do it here
    try {
        yield put({
            type: duck.types.OPEN_MODAL_SUCCESS,
            payload
        })
    } catch (error) {
        yield put({
            type: duck.types.OPEN_MODAL_ERROR,
            error
        })
    }
}

export default requestOpenModal
