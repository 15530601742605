import { takeEvery, takeLatest } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.LOGIN, sagas.login),
    takeEvery(types.LOGOUT, sagas.logout),
    takeEvery(types.USE_REFRESH_TOKEN, sagas.useRefreshToken),
    takeLatest(types.TOKEN_LOGIN, sagas.tokenLogin),
    takeLatest(types.SOCIAL_LOGIN, sagas.socialLogin),
    takeLatest(types.SWITCH_ACCOUNT, sagas.switchAccount),
    takeLatest(types.RESET_PASSWORD, sagas.resetRequest),
    takeLatest(types.CLAIM_RESET_PASSWORD, sagas.resetClaim),
    takeLatest(types.UPDATE_PASSWORD, sagas.updatePassword),
    takeLatest(types.LOGIN_FROM_ONBOARDING, sagas.loginFromOnboarding),
    takeLatest(types.PRE_LOGOUT, sagas.preLogout),
    takeLatest(types.POST_LOGIN, sagas.postLogin),
    takeLatest(types.CLAIM_MAGIC_LINK, sagas.claimMagicLink)
])

export default takes
