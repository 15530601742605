const types = [
    'LOGIN',
    'LOGIN_SUCCESS',
    'LOGIN_ERROR',
    'SOCIAL_LOGIN',
    'SOCIAL_LOGIN_SUCCESS',
    'SOCIAL_LOGIN_ERROR',
    'LOGOUT',
    'LOGOUT_SUCCESS',
    'LOGOUT_ERROR',
    'USE_REFRESH_TOKEN',
    'USE_REFRESH_TOKEN_SUCCESS',
    'USE_REFRESH_TOKEN_ERROR',
    'TOKEN_LOGIN',
    'TOKEN_LOGIN_SUCCESS',
    'TOKEN_LOGIN_ERROR',
    'CLAIM_MAGIC_LINK',
    'CLAIM_MAGIC_LINK_SUCCESS',
    'CLAIM_MAGIC_LINK_ERROR',
    'CLAIM_RESET_PASSWORD',
    'CLAIM_RESET_PASSWORD_SUCCESS',
    'CLAIM_RESET_PASSWORD_ERROR',
    'REGISTER_CONFIRM',
    'REGISTER_CONFIRM_SUCCESS',
    'REGISTER_CONFIRM_ERROR',
    'SWITCH_ACCOUNT',
    'SWITCH_ACCOUNT_SUCCESS',
    'SWITCH_ACCOUNT_ERROR',
    'RESET_FORM',
    'RESET_PASSWORD',
    'RESET_PASSWORD_SUCCESS',
    'RESET_PASSWORD_ERROR',
    'UPDATE_PASSWORD',
    'UPDATE_PASSWORD_SUCCESS',
    'UPDATE_PASSWORD_ERROR',
    'LOGIN_FROM_ONBOARDING',
    'LOGIN_FROM_ONBOARDING_SUCCESS',
    'LOGIN_FROM_ONBOARDING_ERROR',
    'PRE_LOGOUT',
    'PRE_LOGOUT_SUCCESS',
    'PRE_LOGOUT_ERROR',
    'POST_LOGIN',
    'POST_LOGIN_SUCCESS',
    'POST_LOGIN_ERROR',
    'CREATE_CREATOR',
    'CREATE_CREATOR_SUCCESS',
    'CREATE_CREATOR_ERROR'
]

const creators = ({ types }) => ({
    login: (payload) => {
        return { type: types.LOGIN, ...payload }
    },
    socialLogin: (payload) => {
        return {type: types.SOCIAL_LOGIN, ...payload}
    },
    socialLoginError: (error) => {
        return { type: types.SOCIAL_LOGIN_ERROR, error}
    },
    logout: (payload) => {
        return { type: types.LOGOUT, ...payload }
    },
    switchAccount: (payload) => {
        return {
            type: types.SWITCH_ACCOUNT,
            ...payload
        }
    },
    tokenLogin: (payload) => {
        return {
            type: types.TOKEN_LOGIN,
            ...payload
        }
    },
    passwordResetClaim: (payload) => ({
        type: types.CLAIM_RESET_PASSWORD,
        ...payload,
    }),
    registerConfirm: (payload) => ({
        type: types.REGISTER_CONFIRM,
        ...payload,
    }),
    useRefreshToken: () => {
        return { type: types.USE_REFRESH_TOKEN }
    },
    resetForm: () => ({ type: types.RESET_FORM }),
    resetPassword: (payload, callback) => ({ type: types.RESET_PASSWORD, payload, callback }),
    updatePassword: (payload, callback) => ({ type: types.UPDATE_PASSWORD, payload, callback }),
    loginFromOnboarding: (payload) => ({ type: types.LOGIN_FROM_ONBOARDING, payload }),
    postLogin: (payload) => ({ type: types.POST_LOGIN, payload }),
    preLogout: (payload) => ({ type: types.PRE_LOGOUT, ...payload }),
    claimMagicLink: (payload) => ({ type: types.CLAIM_MAGIC_LINK, ...payload }),
})

export { types, creators }
