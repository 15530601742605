import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

import updateVcard from './updateVcard'
import fetchVcard from './fetchVcard'

const initialState = {
    vcardCacheString: Date.now(),
    updateVcardInProgress: false,
    updateVcardSuccess: null,
    updateVcardError: null,

    vcard: null,
    fetchVcardInProgress: false,
    fetchVcardSuccess: null,
    fetchVcardError: null,
}

const vcardSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [updateVcard.pending]: (state) => {
            state.updateVcardInProgress = true
            state.updateVcardSuccess = initialState.updateVcardSuccess
            state.updateVcardError = initialState.updateVcardError
        },
        [updateVcard.fulfilled]: (state) => {
            state.vcardCacheString = Date.now()
            state.updateVcardInProgress = false
            state.updateVcardSuccess = true
            state.updateVcardError = false
        },
        [updateVcard.rejected]: (state, { payload: error }) => {
            state.updateVcardInProgress = false
            state.updateVcardSuccess = false
            state.updateVcardError = error
        },
        [fetchVcard.pending]: (state) => {
            state.fetchVcardInProgress = true
            state.fetchVcardSuccess = initialState.fetchVcardSuccess
            state.fetchVcardError = initialState.fetchVcardError
        },
        [fetchVcard.fulfilled]: (state, { payload: vcard }) => {
            state.vcard = vcard
            state.fetchVcardInProgress = false
            state.fetchVcardSuccess = true
            state.fetchVcardError = false
        },
        [fetchVcard.rejected]: (state, { payload: error }) => {
            state.fetchVcardInProgress = false
            state.fetchVcardSuccess = false
            state.fetchVcardError = error
        },
    },
})

// SELECTORS
const getVcardState = (state) => state[name]
const getUpdateVcardInProgress = createDraftSafeSelector(
    getVcardState,
    (vCard) => vCard?.updateVcardInProgress,
)

const getUpdateVcardSuccess = createDraftSafeSelector(
    getVcardState,
    (vCard) => vCard?.updateVcardSuccess,
)

const getUpdateVcardError = createDraftSafeSelector(
    getVcardState,
    (vCard) => vCard?.updateVcardError,
)

const getVcardCacheString = createDraftSafeSelector(
    getVcardState,
    (vCard) => `?${vCard?.vcardCacheString}`,
)

const getFetchVcardInProgress = createDraftSafeSelector(
    getVcardState,
    (vCard) => vCard?.fetchVcardInProgress,
)

const getFetchVcardSuccess = createDraftSafeSelector(
    getVcardState,
    (vCard) => vCard?.fetchVcardSuccess,
)

const getFetchVcardError = createDraftSafeSelector(
    getVcardState,
    (vCard) => vCard?.fetchVcardError,
)

const getVcard = createDraftSafeSelector(
    getVcardState,
    (vCard) => vCard?.vcard,
)

// GENERAL
const takes = [
    ...updateVcard.takes,
    ...fetchVcard.takes,
]

const vcardInitialState = {
    [name]: initialState,
}

const vcardReducer = {
    [name]: vcardSlice.reducer,
}

export {
    takes,
    vcardInitialState as initialState,
    vcardReducer as reducer,

    updateVcard,
    fetchVcard,

    getUpdateVcardInProgress,
    getUpdateVcardSuccess,
    getUpdateVcardError,
    getVcardCacheString,

    getVcard,
    getFetchVcardInProgress,
    getFetchVcardSuccess,
    getFetchVcardError,
}
