import { useSelector } from "react-redux"
import { globalPaywallOpen } from "../slice"
import usePlatformAccessPaywall from "../hooks/usePlatformAccessPaywall"
import { useEffect } from "react"

const GlobalPaywall = () => {
    const _globalPaywallOpen = useSelector(globalPaywallOpen)
    const { Paywall, openPlatformAccessPaywall } =  usePlatformAccessPaywall()

    useEffect(() => {
        if (_globalPaywallOpen) {
            openPlatformAccessPaywall()
        }
    }, [_globalPaywallOpen, openPlatformAccessPaywall])

    return (
        <Paywall />
    )
}

export default GlobalPaywall
