import React from 'react'
import { put } from 'redux-saga/effects'
import { store } from 'react-notifications-component'
import { Notification } from '@piconetworks/ui'

function* addAndUpdateNotification({ types, payload }) {
    const { message, type } = payload

    // uses the RNC store that is not part of redux
    // the RNC store has a function you can subscribe to that doesn't play nicely with sagas called onRemoval fyi
    const notification_id = store.addNotification({
        message: message,
        type: type,
        content: (<Notification.Item message={message} variant={type} />),
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'slideInRight', 'fastest'],
        animationOut: ['animated', 'slideOutRight', 'fastest']
    })

    // updates the redux store
    yield put({
        type: types.UPDATE_NOTIFICATION,
        payload: {
            ...payload,
            id: notification_id,
            dismissAfter: payload.dismissAfter || 3000 // if not specified, dismiss after 3 seconds
        }
    })
}

export default addAndUpdateNotification
