import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
import ModalModule from 'modules/modal'
import UsersModule from 'modules/users'

function* updateSingleUser(action) {
    const { payload, duck } = action
    const { user_state_id } = payload

    const publisherid = yield select(AccountModule.selectors.getPublisherId)
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: `${endpoints.USERS}/profile/${user_state_id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data:{
                    ...payload
                }
            }
        })

        yield put({
            type: duck.types.UPDATE_SINGLE_USER_SUCCESS,
            data
        })

        yield put(
            ModalModule.creators.closeModal({
                key: 'updateMemberInfo',
            })
        )
        yield put(
            AppModule.creators.addNotification({
                message: 'Member Information Saved Successfully',
                type: 'success'
            })
        )
        yield put(
            UsersModule.creators.reindexUser({
                user_state_id,
            })
        )
    } catch (error) {
        yield put(
            ModalModule.creators.closeModal({
                key: 'updateMemberInfo',
            })
        )
        yield put(
            AppModule.creators.addNotification({
                message: 'Failed to update contact info',
                type: 'danger'
            })
        )
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.UPDATE_SINGLE_USER_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.UPDATE_SINGLE_USER_ERROR,
                error
            })
        }
    }
}

export default updateSingleUser

