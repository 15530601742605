import { putResolve, select, put } from 'redux-saga/effects'
import endpoints from 'utils/lib/endpoints'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
import AnalyticsModule from 'modules/analytics'

function* destroyIntegration({ payload, types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        let result = null
        if (payload.type === 'stripe') {
            const { id, cancelRecurringPayments } = payload
            const { data } = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'post',
                    baseURL: endpoints.BASE_URL,
                    url: `${endpoints.INTEGRATIONS}/destroy/monetization`,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid
                    },
                    data: {
                        id,
                        cancel_recurring_payments: cancelRecurringPayments === 'true'
                    },
                    timeout: 30000
                }
            })

            result = data
        } else {
            const { data } = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'delete',
                    baseURL: endpoints.BASE_URL,
                    url: `${endpoints.INTEGRATIONS}/${payload.id}`,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid
                    },
                    timeout: 30000
                }
            })

            result = data
        }

        yield put({
            type: types.UNINSTALL_INTEGRATION_SUCCESS,
            payload: {...result, type: payload.type || result.type}
        })


        yield put({
            type: AnalyticsModule.types.SEND_EVENT,
            payload: {
                eventType: 'app_install_change',
                eventData: {
                    app_name: payload.appName,
                    install_change: 'uninstall'
                }
            }
        })
    } catch (err) {
        yield put({
            type: types.UNINSTALL_INTEGRATION_ERROR,
            error: err
        })

        yield put(
            AppModule.creators.addNotification({
                message: err.message,
                type: 'danger'
            })
        )
    }
}

export default destroyIntegration
