import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* simulateWebhooks({ types, payload }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.WEBHOOKS + `/${payload.id}/test`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    type: payload.scope,
                    ...payload
                }
            }
        })

        yield put(
            AppModule.creators.addNotification({
                message: 'Test is successful',
                type: 'success'
            })
        )
    } catch (e) {
        yield put(
            AppModule.creators.addNotification({
                message: 'Test is failed',
                type: 'danger'
            })
        )
        yield put({ type: types.SIMULATE_WEBHOOK_ERROR, e })
    }
}

export default simulateWebhooks
