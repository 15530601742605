import { put } from 'redux-saga/effects'

import logger from 'lib/logger'

function* copyLandingPage({ types }) {
    try {
        yield put({
            type: types.SEND_EVENT,
            payload: {
                eventType: 'copy_landing_page'
            }
        })

    } catch (err) {
        logger.error('Error tracking copy landing page', {}, err)
    }
}

export default copyLandingPage
