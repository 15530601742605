/** @namespace FeatureManager.useFeatureManagerMetrics */

import { useDispatch } from 'react-redux'

import { sendEvent } from '../dependencies'

const useFeatureManagerMetrics = () => {
    const dispatch = useDispatch()

    const metrics = {
        presentedCallToAction(eventData) {
            dispatch(sendEvent({
                eventType: 'call_to_action:presented',
                eventData,
            }))
        },
        declinedCallToAction(eventData) {
            dispatch(sendEvent({
                eventType: 'call_to_action:declined',
                eventData,
            }))
        },
        acceptedCallToAction(eventData) {
            dispatch(sendEvent({
                eventType: 'call_to_action:accepted',
                eventData,
            }))
        },
        startedCart(eventData) {
            dispatch(sendEvent({
                eventType: 'cart:started',
                eventData,
            }))
        },
        completedCart(eventData) {
            dispatch(sendEvent({
                eventType: 'cart:completed',
                eventData,
            }))
        },
        dismissedCart(eventData) {
            dispatch(sendEvent({
                eventType: 'cart:dismissed',
                eventData,
            }))
        },
        failedCart(eventData) {
            dispatch(sendEvent({
                eventType: 'cart:failed',
                eventData,
            }))
        },
    }

    return metrics
}

export default useFeatureManagerMetrics
