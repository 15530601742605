import { putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getConversionRate({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.FORM_METRICS,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        yield put({
            type: types.GET_CONVERSION_RATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        yield put({
            type: types.GET_CONVERSION_RATE_ERROR,
            error
        })
    }
}

export default getConversionRate
