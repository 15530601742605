import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
    uuidv4,
    faker,
} from '../dependencies'

const fetchCardById = createAsyncSaga(
    `${name}/fetchCardById`,
    function* ({
        payload: {
            publisherId = null,
            tagId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            if (!tagId) {
                throw new Error('tagId is required')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_TAG_BY_ID(tagId),
                headers: {
                    publisherid: publisherId,
                },
            })

            if (!response?.id) {
                throw new Error('response?.id could not be found')
            }

            const formattedCard = {
                id: response?.id,
                editionId: response?.nfc_edition_id,
                packId: response?.nfc_pack_id,
                formId: response?.form_id,
                publisherId: response?.publisher_id,
                stripeInvoiceId: response?.stripe_invoice_id,
                tagName: response?.tag_name,
                gift: response?.gift,
                customUrl: response?.custom_url,
                engraving: response?.engraving,
                shippedDate: response?.shipped_at,
                deactivatedAt: response?.deactivated_at,
                packagedAt: response?.packaged_at,
                orderDate: response?.created_at,
                updatedAt: response?.updated_at,
                order: response?.order,
            }

            yield fulfilled({ card: formattedCard })
        } catch (error) {
            logger.error('fetchCards error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default fetchCardById
