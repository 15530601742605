import createNewsletter from './createNewsletters'
import deleteNewsletter from './deleteNewsletters'
import getNewsletters from './getNewsletters'
import updateNewsletter from './updateNewsletters'
import sortNewslettersOrder from './sortNewslettersOrder'

const sagas = (duck) => ({
    *getNewsletters() {
        yield getNewsletters({ ...duck })
    },
    *createNewsletter(payload) {1
        yield createNewsletter({ ...duck, payload })
    },
    *updateNewsletter(payload) {
        yield updateNewsletter({ ...duck, payload })
    },
    *deleteNewsletter(payload) {
        yield deleteNewsletter({ ...duck, payload })
    },
    *sortNewslettersOrder(payload) {
        yield sortNewslettersOrder({ ...duck, payload })
    }
})

export default sagas
