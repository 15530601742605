import { put, select } from 'redux-saga/effects'
import AnalyticsModule from 'modules/analytics'

import logger from 'lib/logger'

function* blastErrored({ selectors }) {
    try {
        const error = yield select(selectors.getError)
        yield put(
            AnalyticsModule.creators.sendEvent({
                eventType: 'blast:errored',
                eventData: {
                    blast_error: error?.message,
                },
            })
        )
    } catch (e) {
        logger.error('blastErrored error', {}, e)
    }
}

export default blastErrored
