const selectors = ({ store, statuses }) => ({
    getStatus: (state) => state[store].status,
    getLoading: (state) => state[store].status === statuses.LOADING,
    getPostLoginLoading: (state) => state[store].postLoginStatus === statuses.LOADING,
    getAuthStatus: (state) => state[store].isAuthed,
    getAccountInfo: (state) => state[store].accountInfo,
    getHasPassword: (state) => state[store].accountInfo.has_password || false,
    getErrorStatus: (state) => state[store].error,
    getInvitedStaff: (state) => state[store].invitedStaffInformation,
    getResetSent: (state) => state[store].resetSent,
    getPublisherToken: (state) => state._network.access_token
})

export default selectors
