import React from 'react'
import { useSelector } from 'react-redux'

import * as Text from '@piconetworks/pkg-text'
import { H2 } from '@piconetworks/pkg-heading'
import Form from '@piconetworks/pkg-form'
import Link from '@piconetworks/pkg-link'


import AccountModule from 'modules/account'

import SupportSettings from './Support'

import styles from './PaymentsUsage.module.scss'


const MonetizationUsage = () => {
    const account: { default_currency: string } = useSelector(AccountModule.selectors.getAccountInfo)

    return (
        <>
            <div className={styles.block}>
                <H2>Currency</H2>
                <Form.Input
                    input={{ value: account?.default_currency }}
                    disabled
                    className={styles.currency}
                />
                <Text.Small>
                    <Link href={'mailto:support@hype.co'} variant='primary'>
                        {' '}
                        Contact Hype support
                    </Link>{' '}
                    to request a different currency for your account.
                </Text.Small>
            </div>
            <div className={styles.block}>
                <SupportSettings />
            </div>

        </>
    )
}

export default MonetizationUsage
