import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import SettingsModule from 'modules/settings'

const StyleDefinitions = ({ children }) => {
    const linkColor = useSelector(SettingsModule.selectors.getLinkColor)
    const contrastColor = useSelector(SettingsModule.selectors.getContrastColor)

    return (
        <>
            <style>
                {`
                    :root {
                        --linkColor: #834eff;
                        --contrastColor: #fff;
                        --block-shadow: none;
                        --block-border-style: none;
                        --block-border-color: transparent;
                        --block-border-width: 0;
                        --block-border-radius: 0.5rem;
                        --page-font-body: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
                        --page-font-heading: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
                        --page-background-color: #834EFF;
                        --block-background-color: #FFFFFF;
                        --block-contrast-color: #000000;
                        --block-button-contrast-color: #FFFFFF;
                        --blockTextColorRgb: var(--block-contrast-color);
                    }

                    .customBrandColor {
                        --linkColor: ${linkColor};
                        --contrastColor: ${contrastColor};
                    }
                `}
            </style>
            {children}
        </>
    )
}

StyleDefinitions.propTypes = {
    children: PropTypes.node,
}

export default StyleDefinitions
