import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getFailedMessages({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.MESSAGES,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                // We only care about failed messages for now, since this is in our
                // admin UI for convenience (rather than hunting in the DB)
                params: {
                    state: 'failed'
                }
            }
        })

        if (data) {
            yield put({
                type: types.GET_FAILED_MESSAGES_SUCCESS,
                payload: data.sort((a, b) =>
                    new Date(a.created_at) - new Date(b.created_at)
                ),
            })
        } else {
            throw new Error('Failed to fetch failed messages')
        }
    } catch (e) {
        yield put({
            type: types.GET_FAILED_MESSAGES_ERROR,
            error: e
        })
    }
}

export default getFailedMessages
