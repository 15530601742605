import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import style from './Child.module.scss'

import { Box } from '@piconetworks/ui'

const Child = ({ className = '', children = null, ...props }) => {
    return (
        <Box.ContentPane.Child flexFill={false} className={cx(style.child, className)} {...props}>
            {children}
        </Box.ContentPane.Child>
    )
}

Child.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default Child
