import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'

export const formatWithoutEmoji = value =>
    value?.replace(/[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g, '')

export const formatWithoutSpaces = value =>
    value?.split(' ').join('-')

export const formatToLowerCase = value =>
    value?.toLowerCase()

const formatString = value => {
    if (value === undefined || value === '' || value === null) return undefined
    const withoutEmoji = formatWithoutEmoji(value)
    const withoutSpaces = formatWithoutSpaces(withoutEmoji)
    return formatToLowerCase(withoutSpaces)
}

export const generateURLFromUsername = (value, type) => {
    const username = value?.replace(/[@$]/g, '') || null
    switch (type) {
        case 'cash_app':
            return username
                ? `https://cash.app/$${username}`
                : null
        case 'etsy':
            return username
                ? `https://www.etsy.com/shop/${username}`
                : null
        case 'facebook':
            return username
                ? `https://www.facebook.com/${username}`
                : null
        case 'github':
            return username
                ? `https://github.com/${username}`
                : null
        case 'instagram':
            return username
                ? `https://www.instagram.com/${username}`
                : null
        case 'pinterest':
            return username
                ? `https://www.pinterest.com/${username}`
                : null
        case 'snapchat':
            return username
                ? `https://www.snapchat.com/add/${username}`
                : null
        case 'threads':
            return username
                ? `https://www.threads.net/@${username}`
                : null
        case 'tiktok':
            return username
                ? `https://www.tiktok.com/@${username}`
                : null
        case 'twitch':
            return username
                ? `https://www.twitch.tv/${username}`
                : null
        case 'twitter':
            return username
                ? `https://www.twitter.com/${username}`
                : null
        case 'venmo':
            return username
                ? `https://www.venmo.com/u/${username}`
                : null
        case 'youtube':
            return username
                ? `https://www.youtube.com/@${username}`
                : null
        default:
            return username
    }
}

export const generateMailtoFromEmail = (value) => {
    if (value) {
        return `mailto:${value}`
    } else {
        return undefined
    }
}

export const formatSocialLinks = (value, type) => {
    if (value) {
        switch (type) {
            case 'cash_app':
                return `${value.split('cash.app/')[1]}`
            case 'email':
                if (value.includes('mailto')) {
                    return `${value.split('mailto:')[1]}`
                } else {
                    return value
                }
            case 'etsy':
                return `@${value.split('etsy.com/shop/')[1]}`
            case 'facebook':
                return `@${value.split('facebook.com/')[1]}`
            case 'github':
                return `@${value.split('github.com/')[1]}`
            case 'instagram':
                return `@${value.split('instagram.com/')[1]}`
            case 'pinterest':
                return `@${value.split('pinterest.com/')[1]}`
            case 'snapchat':
                return `@${value.split('snapchat.com/add/')[1]}`
            case 'threads':
                return `${value.split('threads.net/')[1]}`
            case 'tiktok':
                return `${value.split('tiktok.com/')[1]}`
            case 'twitch':
                return `@${value.split('twitch.tv/')[1]}`
            case 'twitter':
                return `@${value.split('twitter.com/')[1]}`
            case 'venmo':
                return `@${value.split('venmo.com/u/')[1]}`
            case 'youtube':
                return `${value.split('youtube.com/')[1]}`
            default:
                return value
        }
    } else {
        return undefined
    }
}

export const prependHTTPSOnBlur = (value) => {
    if (value) {
        if (value.startsWith('https://')) {
            return value
        } else if (value.startsWith('http://')) {
            const withHTTPRemoved = value.split('http://')[1]
            return `https://${withHTTPRemoved}`
        } else {
            return `https://${value}`
        }
    } else {
        return undefined
    }
}

export const formatPhoneNumber = (numberString) => {
    if (numberString === null) return null

    const phoneUtil = PhoneNumberUtil.getInstance()

    // get only the number with appended area code
    const number = `+${(numberString || '').replace(/\D/g, '')}`

    try {
        if (number) {
            // number string needs to start with + for this to work
            const parsed = phoneUtil.parse(number, '')

            if (phoneUtil.isValidNumber(parsed)) {
                // format the number to how its supposed to look
                const formatted = phoneUtil.format(parsed, PhoneNumberFormat.INTERNATIONAL)

                return formatted
            } else {
                throw new Error('invalid phone number')
            }
        }
    } catch (e) {
        console.log(e)
        return numberString
    }
    return numberString
}


export default formatString
