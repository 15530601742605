import { name, endpoints, getPublisherId } from '../dependencies'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import { createAsyncThunk } from '@reduxjs/toolkit'

const requestPhoneNumber = createAsyncThunk(
    `${name}/requestPhoneNumber`,
    async (payload, thunkAPI) => {
        const { getState, dispatch } = thunkAPI
        try {
            const publisherId = getPublisherId(getState())

            const response = await dispatch({
                type: REQUEST,
                config: {
                    method: 'POST',
                    url: endpoints.MESSAGING_VERIFY,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid: publisherId,
                    },
                    data: payload
                }
            })
            console.log('response!', response)
            
            const { status, data } = response

            if (status === 200) {
                return data
            } else {
                throw new Error('Unable to enable messaging')
            }
        } catch (error) {
            console.log('inside of thunk', error.response.data, error.message)
            throw new Error(error.response.data.message || error.message)
        }
    }
)

export default requestPhoneNumber
