import getDashboardPricing from './getDashboardPricing'
import saveDashboardPricing from './saveDashboardPricing'
import getApplicationFees from './getApplicationFees'
import saveApplicationFees from './saveApplicationFees'
import addUnclaimedCreator from './addUnclaimedCreator'
import setToUnclaimed from './setToUnclaimed'
import triggerScraping from './triggerScraping'

const sagas = (duck) => ({
    *getDashboardPricing() {
        yield getDashboardPricing({ ...duck })
    },
    *saveDashboardPricing(payload) {
        yield saveDashboardPricing({ ...payload, ...duck })
    },
    *getApplicationFees() {
        yield getApplicationFees({ ...duck })
    },
    *saveApplicationFees(payload) {
        yield saveApplicationFees({ ...payload, ...duck })
    },
    * addUnclaimedCreator(payload) {
        yield addUnclaimedCreator({ ...payload, ...duck })
    },
    * setToUnclaimed(payload) {
        yield setToUnclaimed({ ...payload, ...duck })
    },
    * triggerScraping(payload) {
        yield triggerScraping({ ...payload, ...duck })
    }
})

export default sagas
