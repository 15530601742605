const actions = {}

actions.types = [
    'GET_BLASTS',
    'GET_BLASTS_SUCCESS',
    'GET_BLASTS_ERROR',

    'CREATE_BLAST',
    'CREATE_BLAST_SUCCESS',
    'CREATE_BLAST_ERROR',

    'GET_CONVERSATION',
    'GET_CONVERSATION_SUCCESS',
    'GET_CONVERSATION_ERROR',

    'GET_CONVERSATIONS',
    'GET_CONVERSATIONS_SUCCESS',
    'GET_CONVERSATIONS_ERROR',

    'GET_CONVERSATION_BATCH',
    'GET_CONVERSATION_BATCH_SUCCESS',
    'GET_CONVERSATION_BATCH_ERROR',

    'SET_ACTIVE_CONVERSATION',
    'CLEAR_ACTIVE_CONVERSATION',

    'START_CONVERSATION',
    'START_CONVERSATION_SUCCESS',
    'START_CONVERSATION_ERROR',

    'CREATE_MESSAGE',
    'CREATE_MESSAGE_SUCCESS',
    'CREATE_MESSAGE_ERROR',

    'UPDATE_BLAST',
    'UPDATE_BLAST_SUCCESS',
    'UPDATE_BLAST_ERROR',

    'GET_FAILED_MESSAGES',
    'GET_FAILED_MESSAGES_SUCCESS',
    'GET_FAILED_MESSAGES_ERROR',

    'ENABLE_MESSAGING',
    'ENABLE_MESSAGING_SUCCESS',
    'ENABLE_MESSAGING_ERROR',

    'VERIFY_MESSAGING',
    'VERIFY_MESSAGING_SUCCESS',
    'VERIFY_MESSAGING_ERROR',

    'UPDATE_BLAST_FILTER',
]

actions.creators = ({ types }) => ({
    updateBlastFilter: (payload) => ({
        type: types.UPDATE_BLAST_FILTER,
        payload
    }),
    getBlasts: (payload) => ({
        type: types.GET_BLASTS,
        payload
    }),
    createBlast: (payload) => ({
        type: types.CREATE_BLAST,
        payload
    }),
    getConversation: (payload) => ({
        type: types.GET_CONVERSATION,
        payload
    }),
    getConversations: () => ({
        type: types.GET_CONVERSATIONS,
    }),
    getConversationBatch: (payload) => ({
        type: types.GET_CONVERSATION_BATCH,
        payload
    }),
    setActiveConversation: (payload) => ({
        type: types.SET_ACTIVE_CONVERSATION,
        payload
    }),
    clearActiveConversation: () => ({
        type: types.CLEAR_ACTIVE_CONVERSATION,
    }),
    createConversation: (payload) => ({
        type: types.CREATE_CONVERSATION,
        payload
    }),
    createMessage: (payload) => ({
        type: types.CREATE_MESSAGE,
        payload
    }),
    getFailedMessages: (payload) => ({
        type: types.GET_FAILED_MESSAGES,
        payload
    }),
    enableMessaging: (payload) => ({
        type: types.ENABLE_MESSAGING,
        payload
    }),
    updateBlast: (payload) => ({
        type: types.UPDATE_BLAST,
        payload
    }),

})

export default actions
