import { useSelector } from 'react-redux'

import AccountModule from 'modules/account'

const usePublisherUsername = () => {
    const _publisherUsername = useSelector(AccountModule.selectors.getUsername)

    return _publisherUsername
}

export default usePublisherUsername
