import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import ModalModule from 'modules/modal'
import AccountModule from 'modules/account'

function* inviteStaff({ types, payload }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { status, data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.STAFF,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...payload.values,
                    redirect_url: `https://${process.env.HOSTNAME}/login/reset`
                }
            }
        })

        if (status === 200) {
            yield put({
                type: types.INVITE_STAFF_SUCCESS,
                payload: !data?.isReinvite && { ...payload.values, ...data }
            })
            yield put(
                ModalModule.creators.closeModal({
                    key: 'invite_staff',
                    actionFinished: true
                })
            )
            yield put(
                AppModule.creators.addNotification({
                    message: `Invitation successfully ${data?.isReinvite ? 're-sent' : 'sent'} to ${payload.values.email}`,
                    type: 'success'
                })
            )
        } else {
            throw Error('An uncatched error occured.')
        }
    } catch (error) {
        yield put(
            ModalModule.creators.closeModal({
                key: 'invite_staff',
                actionFinished: true
            })
        )
        yield put(
            AppModule.creators.addNotification({
                message: 'Something went wrong',
                type: 'danger'
            })
        )
        yield put({ type: types.INVITE_STAFF_ERROR, error })
    }
}

export default inviteStaff
