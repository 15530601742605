import { putResolve, put } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'

function* getApplicationFees({ types }) {

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.GET_APPLICATION_FEES,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        })

        if (data.status === 200) {
            yield put({
                type: types.GET_APPLICATION_FEES_SUCCESS,
                payload: {
                    ...data.body
                }
            })
        }

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: types.GET_APPLICATION_FEES_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: types.GET_APPLICATION_FEES_ERROR,
                error
            })
        }
    }
}

export default getApplicationFees
