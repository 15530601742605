import { useCallback, useState } from "react"

import { MessagesModule } from "../dependencies"
import { useDispatch, useSelector } from "react-redux"
import usePopup from "hooks/usePopup"
import BlastTypes from '../components/BlastTypes'
import ComposeBlastMenu from '../components/ComposeBlastMenu'
import { getComposeBlastInput, resetComposeBlastInput, setComposeBlastInput } from "../slice"

const useComposeBlast = () => {
    const dispatch = useDispatch()
    const selectedDraftBlast = useSelector(getComposeBlastInput)

    const {
        isModalOpen,
        setModalOpen,
        Popup,
    } = usePopup()

    const createOrUpdateBlast = useCallback((payload) => {
        if (payload.id) {
            dispatch(MessagesModule.creators.updateBlast(payload))
        } else {
            dispatch(MessagesModule.creators.createBlast(payload))
        }
        dispatch(resetComposeBlastInput())
    }, [dispatch])

    const setDraftValue = useCallback((payload) => {
        dispatch(setComposeBlastInput(payload))
        // setModalOpen(true)
    }, [dispatch])

    const resetDraftValue = useCallback(() => {
        dispatch(resetComposeBlastInput())
    }, [dispatch])

    return {
        createOrUpdateBlast,
        isModalOpen,
        setModalOpen,
        Popup,
        setDraftValue,
        resetDraftValue,
        BlastTypes,
        ComposeBlastMenu,
        selectedDraftBlast
    }
}

export default useComposeBlast
