import { createAsyncSaga, name, endpoints, logger } from '../dependencies'

const createPack = createAsyncSaga(
    `${name}/createPack`,
    function* ({
        payload: {
            publisherId = null,
            quantity = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
    try {
        if (!publisherId) {
            throw new Error('publisherId is required')
        }

        if (!quantity) {
            throw new Error('quantity is required')
        }

        const response = yield request({
            method: 'post',
            url: endpoints.CREATE_PACK,
            headers: {
                publisherid: publisherId,
            },
            data: {
                quantity: quantity,
            },
        })

        const pack = response

        if (!pack) {
            throw new Error('pack could not be found')
        }

        yield fulfilled(pack)
    } catch (error) {
        logger.error(error)
        yield rejected(error)
    }
})

export default createPack
