import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
function* createBlast({
    types,
    payload: {
        payload,
    },
}) {
    const { message, draft = false, type, image_url } = payload || {}

    const newBlast = {
        message,
        draft,
        type,
        image_url
    }

    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        // throw new Error('error')

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'POST',
                url: endpoints.BROADCASTS,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                data: newBlast,
            },
        })

        if (data) {
            yield put({
                type: types.CREATE_BLAST_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('Failed to send')
        }

        yield put(
            AppModule.creators.addNotification({
                message: draft ? 'Draft saved successfully.' : 'Blast sent successfully.',
                type: 'success'
            })
        )

    } catch (e) {
        yield put(
            AppModule.creators.addNotification({
                message: e?.response?.data?.message || 'Something went wrong.',
                type: 'danger'
            })
        )
        yield put({
            type: types.CREATE_BLAST_ERROR,
            error: e
        })
    }
}

export default createBlast
