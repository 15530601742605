import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { OFFER_TYPES, SIGNUP_TYPE, PAYMENT_TYPE, LANDING_PAGES, POPUPS, GROUP_TYPE, STANDARD_TYPE } from 'utils/constants/offers'

export default class OffersService {
    constructor(store, statuses) {
        this.store = store
        this.statuses = statuses
        this.name = 'OffersService'
    }

    getState(state) {
        return state[this.store]
    }

    getStatus(state) {
        return state[this.store].status
    }

    getLoading(state) {
        const status = state[this.store].status
        if (status === this.statuses.LOADING) {
            return true
        } else if (status === this.statuses.INITIATED) {
            return null
        } else {
            return status === this.statuses.LOADING
        }
    }

    calculateConversion(val) {
        if (val.conversions > 0 && val.views === 0) {
            return 1
        }
        return val.conversions / val.views
    }

    _getItems(state, type) {
        const offers = state[this.store]?.[type]
        return this._addConversionRateAndSplitByActiveAndArchived(state, offers)
    }

    _addConversionRateAndSplitByActiveAndArchived(state, offers) {
        const conversionRate = state[this.store]?.conversionRate
        const values = this._addConversionRate(offers, conversionRate)
        const { active, archived } = this._splitByActiveAndArchived(values)
        return {
            active,
            archived
        }
    }

    _addConversionRate(offers = [], conversionRate) {
        const values = offers.map((value) => ({
            ...value,
            conversion_rate: (conversionRate && conversionRate[value.id]) ? this.calculateConversion(conversionRate[value.id]) : 0,
            conversions: conversionRate ? conversionRate[value.id]?.conversions : null
        }))

        return values
    }

    _splitByActiveAndArchived(offers) {
        const { active, archived } = (offers || []).reduce((acc, item) => ({
            active: [...acc.active, ...(item.archived ? [] : [item])],
            archived: [...acc.archived, ...(item.archived ? [item] : [])]
        }), {
            active: [],
            archived: []
        })

        const popup = active?.some(offer => offer.type.includes('popup'))
        const sort = offers =>
            popup
                ? orderBy(offers, ['order'])
                : orderBy(offers, ['updated_at'], ['desc'])

        return {
            active: sort(active),
            archived: sort(archived)
        }
    }

    getLandingPages(state) {
        return this._getItems(state, 'landing_pages')
    }

    getActiveLandingPages(state) {
        const landingPages = this.getLandingPages(state)
        return landingPages.active
    }

    getLandingPageByType(state) {
        const landingPages = state[this.store][LANDING_PAGES.plural]
        const offerTypes = OFFER_TYPES(LANDING_PAGES.value)

        if (isEmpty(landingPages)) return {
            [offerTypes[SIGNUP_TYPE]]: {},
            [offerTypes[PAYMENT_TYPE]]: {},
            [offerTypes[GROUP_TYPE]]: {},
            [offerTypes[STANDARD_TYPE]]: {}
        }

        const landingPagesGrouped = groupBy(landingPages, 'type')

        return {
            [offerTypes[SIGNUP_TYPE]]: this._addConversionRateAndSplitByActiveAndArchived(state, landingPagesGrouped[offerTypes[SIGNUP_TYPE]]),
            [offerTypes[PAYMENT_TYPE]]: this._addConversionRateAndSplitByActiveAndArchived(state, landingPagesGrouped[offerTypes[PAYMENT_TYPE]]),
            [offerTypes[GROUP_TYPE]]: this._addConversionRateAndSplitByActiveAndArchived(state, landingPagesGrouped[offerTypes[GROUP_TYPE]]),
            [offerTypes[STANDARD_TYPE]]: this._addConversionRateAndSplitByActiveAndArchived(state, landingPagesGrouped[offerTypes[STANDARD_TYPE]]),
        }
    }

    getPopups(state) {
        return this._getItems(state, 'popups')
    }

    getConversionRate(state) {
        return state[this.store].conversionRate
    }

    getActivePaymentLandingPages(state) {
        const landingPages = this.getLandingPages(state)
        return landingPages.active.filter((lp) => lp.type === 'payment_landing_page')
    }

    getLandingPageById(state, id) {
        // this means it's new
        if (id === SIGNUP_TYPE || id === PAYMENT_TYPE || id === GROUP_TYPE) {
            // returns either payment_landing_page or signup_landing_page
            return {
                type: OFFER_TYPES(LANDING_PAGES.value)[id]
            }
        }
        const landing_pages = state[this.store]?.landing_pages || []
        return landing_pages.find((lp) => lp.id === id)
    }

    getPopupById(state, id) {
        // this means it's new
        if (id === SIGNUP_TYPE || id === PAYMENT_TYPE) {
            // returns either payment_popup or signup_popup
            return {
                type: OFFER_TYPES(POPUPS.value)[id]
            }
        }
        const popups = state[this.store].popups
        return popups.find((p) => p.id === id)
    }
}
