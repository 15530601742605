import Duck from 'extensible-duck'


export const DASHBOARD_RESET = 'DASHBOARD/RESET'

export default function createDuck({ namespace = 'dashboard', store, initialState = {} }) {
    return new Duck({
        namespace,
        store,
        consts: {
            statuses: [
                'CANCELLED',
                'CREATING',
                'DELETING',
                'EDITING',
                'ERRORED',
                'INITIATED',
                'LOADING',
                'READY',
                'SAVING',
            ]
        },
        types: [
            'RESET'
        ],
        creators: ({ types }) => ({
            reset: () => ({ type: types.RESET })
        }),
        initialState,
        reducer: (state, action, { types }) => {
            switch (action.type) {
                case DASHBOARD_RESET:
                    return {
                        ...initialState,
                    }
                default:
                    return state
            }
        },
        selectors: {
            root: state => state
        }
    })
}
