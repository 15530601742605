import { COUNTRIES, DEVICES, REFERRAL_SOURCES, REGIONS } from './visitor'

export const periodOptions = [
    {
        label: 'week',
        value: 'week'
    },
    {
        label: 'month',
        value: 'month'
    },
    {
        label: 'year',
        value: 'year'
    }
]

export const unlockOptions = {
    conditional: 'conditional',
    all: 'all',
    none: 'none'
}

export const selectOptions = [
    {
        value: 'any',
        label: ' any'
    },
    {
        value: 'all',
        label: 'all'
    }
]

export const CONDITIONS = [
    {
        label: 'Page labels',
        value: 'label'
    },
    {
        label: 'URL',
        value: 'url'
    },
    {
        label: 'Visitor location',
        value: 'country',
        dd_options: COUNTRIES
    },
    {
        label: 'Visitor region',
        value: 'region',
        dd_options: REGIONS
    },
    {
        label: 'Visitor device',
        value: 'device',
        dd_options: DEVICES
    },
    {
        label: 'Ad blocker',
        value: 'adblock'
    },
    {
        label: 'Session source',
        value: 'referral',
        dd_options: REFERRAL_SOURCES
    }
]

export const CONDITION_OPTIONS = {
    label: [
        {
            label: 'exclude',
            value: true
        },
        {
            label: 'include',
            value: false
        }
    ],
    url: [
        {
            label: 'does not contain',
            value: true
        },
        {
            label: 'contains',
            value: false
        }
    ],
    country: [
        {
            label: 'is not in',
            value: true
        },
        {
            label: 'is in',
            value: false
        }
    ],
    region: [
        {
            label: 'is not',
            value: true,
        },
        {
            label: 'is',
            value: false,
        }
    ],
    device: [
        {
            label: 'is not',
            value: true
        },
        {
            label: 'is',
            value: false
        }
    ],
    adblock: [
        {
            label: 'is not detected',
            value: true
        },
        {
            label: 'is detected',
            value: false
        }
    ],
    referral: [
        {
            label: 'is not',
            value: true
        },
        {
            label: 'is',
            value: false
        }
    ],

}
