import constants from "../constants"
import { useFeatureManager } from "../dependencies"

const usePlatformAccessApps = () => {
    const featureManager = useFeatureManager()

    const platformAccessAppTiers = Object
        .entries(constants.app_tiers)
        .reduce((acc, [key, value]) => {
            acc[value] = featureManager.getAppTierByLabel(value)
            return acc
        }, {})

    return platformAccessAppTiers
}

export default usePlatformAccessApps
