import { createAsyncSaga, name, endpoints, logger } from '../dependencies'

const removeTagFromPack = createAsyncSaga(
    `${name}/removeTagFromPack`,
    function* ({
        payload: {
            publisherId = null,
            packId = null,
            tagId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            if (!packId) {
                throw new Error('packId is required')
            }

            if (!tagId) {
                throw new Error('tag is required')
            }

            const response = yield request({
                method: 'delete',
                url: endpoints.REMOVE_TAG_FROM_PACK(packId, tagId),
                headers: {
                    publisherid: publisherId,
                },
            })

            const pack = response

            if (!pack) {
                throw new Error('pack could not be found')
            }

            yield fulfilled(pack)
        } catch (error) {
            console.log(error)
            yield rejected(error)
        }
    }
)

export default removeTagFromPack
