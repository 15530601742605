import vcf from 'vcf'
import Duck from 'extensible-duck'
import dayjs from 'dayjs'
import { every, get, isEmpty, some, sortBy, orderBy } from 'lodash'
import { format } from '@piconetworks/pkg-currency'
import { proratePlanCancelledInDays } from 'utils/lib/money'
import PlansModule from 'modules/plans'
import {
    EX_PASS_HOLDER,
    INDIVIDUAL_SUBSCRIBER,
    INDIVIDUAL_EX_SUBSCRIBER,
    INDIVIDUAL_SUBSCRIBER_TRIAL,
    INDIVIDUAL_SUBSCRIBER_FREE_ACCESS,
    INDIVIDUAL_SUBSCRIBER_GIFT,
    PASS_HOLDER,
    PASS_HOLDER_GIFT,
    PASS_HOLDER_FREE_ACCESS,
    MONTHLY_DONOR,
    GROUP_SUBSCRIBERS,
    NON_PAYING_GROUP_SUBSCRIBERS,
    GROUP_SUBSCRIBERS_NOT_TO_BE_ADDED_TO_GROUP,
} from 'utils/constants/membership'
import { contributionSingularLowerCase, contributionSingularUpperCase } from 'features/ContributionLang'

import logger from 'lib/logger'

// selectedUsers is for what happens prior to a modal opening, ie after selecting user/s checkbox, dictating what shows in the menu
// fetchedUsers is for what happens after a modal opens (more accurate)

const selectors = ({ store, statuses }) => ({
    getState: (state) => state[store],
    getStatus: (state) => state[store].status,
    getLoading: (state) => state[store].status === statuses.LOADING,
    getUserResults: (state) => state[store].data,
    getExportCSV: (state) => state[store].exportCSV,
    getUserImportLoading: (state) => state[store].userImportLoading,
    getExportCSVRequestId: new Duck.Selector((selectors) => (state) => {
        const store = selectors.getState(state)
        const request_id = get(store.exportCSV, 'request_id', null)
        return request_id
    }),
    getExportCSVFinished: new Duck.Selector((selectors) => (state) => {
        const store = selectors.getState(state)
        const finished = get(store.exportCSV, 'finished', false)
        return finished
    }),
    getExportCSVProgress: new Duck.Selector((selectors) => (state) => {
        const store = selectors.getState(state)
        const progress = get(store.exportCSV, 'progress')
        return progress
    }),
    getExportCSVStatus: new Duck.Selector((selectors) => (state) => {
        const store = selectors.getState(state)
        const status = get(store.exportCSV, 'status')
        return status
    }),
    getExportCSVFile: new Duck.Selector((selectors) => (state) => {
        const store = selectors.getState(state)
        const file = get(store.exportCSV, 'file')
        return file
    }),
    getUserTransactions: (state) => (state[store]?.userActivities || []).filter(activity => activity.activity_type === 'transaction'),
    getUserPurchaseStart: (state) => get(state[store].fetchedUsers[0], 'current_purchase.start', null),
    getUserPurchaseEnd: (state) => get(state[store].fetchedUsers[0], 'current_purchase.end', null),
    getUserNewsletters: new Duck.Selector((selectors) => (state) => {
        const store = selectors.getState(state)
        const newsletters = get(store.newsletters, 'newsletters', [])
        return {
            ...store.newsletters,
            newsletters: sortBy(newsletters, ['name'])
        }
    }),
    getUserTransactionsSortedByDate: new Duck.Selector((selectors) => (state) => {
        const userTransactions = selectors.getUserTransactions(state)
        const hasUserTransactions = !isEmpty(userTransactions)
        if (hasUserTransactions) {
            return orderBy(userTransactions, ['timestamp'], ['desc'])
        } else {
            return []
        }
    }),
    getPaginatedUsers: new Duck.Selector((selectors) => (state) => {
        const userResults = selectors.getUserResults(state)
        return {
            count: get(userResults, 'count', null),
            users: get(userResults, 'users', []),
            page: get(userResults, 'page', null),
            pages: get(userResults, 'pages', -1),
            pageSize: get(userResults, 'pageSize', 25)
        }
    }),
    getSingleUser: (state) => state[store]?.user,
    getSingleUserVcard: new Duck.Selector((selectors) => (state) => {
        try {
            const _getSingleUser = selectors.getSingleUser(state)

            if (!_getSingleUser?.user_state_id) {
                return null
            }

            const vcard = new vcf()

            if (_getSingleUser?.name?.value) {
                vcard.set('n', [_getSingleUser.name.value])
                vcard.set('fn', _getSingleUser.name.value)
            }

            if (_getSingleUser?.email?.value) {
                vcard.set('email', _getSingleUser.email.value)
            }

            if (_getSingleUser?.phone?.value) {
                vcard.set('tel', _getSingleUser.phone.value)
            }

            vcard.set('url', `https://${process.env.HOSTNAME}/contacts/${_getSingleUser.user_state_id}`)

            return vcard.toString()
        } catch (error) {
            logger.error('getSingleUserVcard error', {}, error)
            return null
        }
    }),
    getSelectedUsers: (state) => state[store].selectedUsers,
    getUserActivities: (state) => state[store].userActivities,
    getFetchedUsers: (state) => state[store].fetchedUsers,
    getPurchaseType: new Duck.Selector((selectors) => (state) => {
        const hasMultiplePurchaseTypes = selectors.hasMultiplePurchaseTypes(state)
        const selectedUsers = selectors.getSelectedUsers(state)
        if (hasMultiplePurchaseTypes) {
            return null
        } else {
            return selectedUsers[0].current_purchase.type
        }
    }),
    getPurchaseTypePlural: new Duck.Selector((selectors) => (state) => {
        const purchaseType = selectors.getPurchaseType(state)
        switch (purchaseType) {
            case 'subscription':
                return 'subscriptions'
            case 'pass':
                return 'passes'
            case 'donation':
                return 'contributions'
            default:
                return null
        }
    }),
    getError: (state) => state[store].error,
    getModalText: new Duck.Selector((selectors) => (state) => {
        const fetchedUsers = selectors.getFetchedUsers(state)
        const error = selectors.getError(state)
        let header, description, actionButtonText
        if (!fetchedUsers.length) {
            return { header, description, actionButtonText }
        }
        if (error) {
            header =
                fetchedUsers.length > 1
                    ? `One or more of the selected contacts currently has ${error.type} access.`
                    : `This contact currently has ${error.type} access.`
        }
        else {
            header =
                fetchedUsers.length > 1
                    ? `Delete these ${fetchedUsers.length} contacts?`
                    : `Delete the contact ${fetchedUsers?.[0]?.name?.value || fetchedUsers?.[0]?.email?.value}?`
        }
        description =
            fetchedUsers.length > 1
                ? 'Their engagement history will be removed from analytics, and you will lose access to their account information. This cannot be undone.'
                : 'A contact can only be deleted once their free or paid access has canceled.'
        actionButtonText = fetchedUsers.length > 1 ? `Delete ${fetchedUsers.length} contacts` : 'Delete contact'

        return { header, description, actionButtonText }
    }),
    hasMultipleUserSelected: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        return selectedUsers?.length > 1
    }),
    hasUserPurchase: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        const hasComplete = some(selectedUsers, ['membership.status', 'COMPLETE'])
        const hasActive = some(selectedUsers, ['membership.status', 'ACTIVE'])
        const hasCanceled = some(selectedUsers, ['membership.status', 'CANCELED'])
        const hasNext = some(selectedUsers, ['membership.status', 'NEXT'])
        return hasComplete || hasActive || hasCanceled || hasNext
    }),
    hasActiveUserPurchase: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        const hasActive = some(selectedUsers, ['membership.status', 'ACTIVE'])
        const hasCanceled = some(selectedUsers, ['membership.status', 'CANCELED'])
        const hasNext = some(selectedUsers, ['membership.status', 'NEXT'])
        return hasActive || hasCanceled || hasNext
    }),
    hasFailedUserPurchase: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        const hasFailedPayment = some(selectedUsers, 'payment_meta.last_failed_payment')
        const hasCompleted = some(selectedUsers, ['membership.status', 'COMPLETE'])
        const hasFailed = some(selectedUsers, ['membership.status', 'FAILED'])
        return hasFailedPayment && (hasCompleted || hasFailed)
    }),
    hasUserPurchase: new Duck.Selector((selectors) => (state) => {
        const hasActiveUserPurchase = selectors.hasActiveUserPurchase(state)
        const hasFailedUserPurchase = selectors.hasFailedUserPurchase(state)
        return hasActiveUserPurchase || hasFailedUserPurchase
    }),
    anyUserHasCanceledPurchase: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        const hasCanceled = some(selectedUsers, ['membership.status', 'CANCELED'])
        return hasCanceled
    }),
    everyUserHasCanceledPurchase: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        const hasCanceled = every(selectedUsers, ['membership.status', 'CANCELED'])
        return hasCanceled
    }),
    hasRecurringPurchase: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        const hasRecurring = every(selectedUsers, user => user.current_purchase.amount > 0)
        return hasRecurring
    }),
    hasBillingEndDateInTheFuture: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        const billingPeriodEndsInFuture = every(selectedUsers, (user) => user.payment_meta.billing_cycle_end && dayjs(user.payment_meta.billing_cycle_end) > dayjs())
        return billingPeriodEndsInFuture
    }),
    hasMultipleMembershipTypes: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        if (selectedUsers.length === 0) return false
        return !every(selectedUsers, ['membership.type', selectedUsers[0].membership.type])
    }),
    hasMultiplePurchaseTypes: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        if (selectedUsers.length === 0) return false
        return !every(selectedUsers, ['purchase_type', selectedUsers[0].current_purchase.type])
    }),
    hasMultiplePlanIds: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        if (selectedUsers.length === 0) return false
        return !every(selectedUsers, ['product.product_id', selectedUsers[0]?.product?.product_id])
    }),
    canBeGrantedFreeAccess: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        return every(selectedUsers, ['payment_meta.whitelistable', true])
    }),
    publisherHasWhitelistablePlan: new Duck.Selector(() => (state) => {
        const plans = PlansModule.selectors.getWhitelistablePlans(state)
        return !isEmpty(plans)
    }),
    hasFreeAccess: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        return every(selectedUsers, ['payment_meta.is_whitelisted', true])
    }),
    isMemberOfGroup: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        return selectedUsers.every(user => GROUP_SUBSCRIBERS.includes((user?.membership?.type || '')))
    }),
    isNonPayingMemberOfGroup: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        return selectedUsers.every(user => NON_PAYING_GROUP_SUBSCRIBERS.includes((user?.membership?.type || '')))
    }),
    isMemberOfGroupNotToBeAddedToGroup: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        return selectedUsers.every(user => GROUP_SUBSCRIBERS_NOT_TO_BE_ADDED_TO_GROUP.includes((user?.membership?.type || '')))
    }),
    getMenuOptions: new Duck.Selector((selectors) => (state) => {
        // return whether we should show the menu option given the selected users

        const sendEmailReceipt = [
            {
                name: 'hasUserPurchase',
                value: selectors.hasUserPurchase(state)
            },
            {
                name: 'isNotAPayingGroupMember',
                value: !selectors.isNonPayingMemberOfGroup(state)
            },
            {
                name: 'hasFreeAccess',
                value: !selectors.hasFreeAccess(state)
            },
            {
                name: 'notAllowedMultipleUserSelection',
                value: !selectors.hasMultipleUserSelected(state)
            },
        ]

        const exportCsv = [
            {
                name: 'reasonToExportCsv',
                value: true
            }
        ]

        const switchSubscription = [
            {
                name: 'hasUserPurchase',
                value: selectors.hasActiveUserPurchase(state)
            },
        ]

        const changeRenewalDate = [
            {
                name: 'hasPurchasedAndRenewalHappensInTheFuture',
                value: true
            },
        ]

        const addToGroup = [
            {
                name: 'isNotAGroupMember',
                value: !selectors.isMemberOfGroupNotToBeAddedToGroup(state)
            },
            {
                name: 'doesNotHaveMultipleMembershipTypes',
                value: !selectors.hasMultipleMembershipTypes(state)
            },
            {
                name: 'hasNoActivePurchase',
                value: !selectors.hasActiveUserPurchase(state)
            },
        ]

        const grantFreeAccess = [
            {
                name: 'hasNoActivePurchase',
                value: !selectors.hasActiveUserPurchase(state)
            },
            {
                name: 'publisherHasWhitelistablePlan',
                value: selectors.publisherHasWhitelistablePlan(state)
            },
            {
                name: 'canBeGrantedFreeAccess',
                value: selectors.canBeGrantedFreeAccess(state)
            },
            {
                name: 'hasFreeAccess',
                value: !selectors.hasFreeAccess(state)
            },
        ]

        const modifyFreeAccess = [
            {
                name: 'hasFreeAccess',
                value: selectors.hasFreeAccess(state)
            },
        ]

        const cancel = [
            {
                name: 'hasUserPurchase',
                value: selectors.hasUserPurchase(state)
            },
            {
                name: 'doesNotHaveMultipleMembershipTypes',
                value: !selectors.hasMultipleMembershipTypes(state)
            },
            {
                name: 'doesNotHaveCanceledPurchase',
                value: !selectors.anyUserHasCanceledPurchase(state)
            },
        ]

        const reverseCancel = [
            {
                name: 'everyUserHasCanceledPurchase',
                value: selectors.everyUserHasCanceledPurchase(state)
            },
            {
                name: 'hasBillingEndDateInTheFuture',
                value: selectors.hasBillingEndDateInTheFuture(state)
            },
            {
                name: 'hasRecurringPurchase',
                value: selectors.hasRecurringPurchase(state)
            },
        ]

        const deleteUser = [
            {
                name: 'doesNotHaveActiveUserPurchase',
                value: !selectors.hasActiveUserPurchase(state)
            },
        ]

        const menu = {
            sendEmailReceipt: every(sendEmailReceipt, { value: true }),
            exportCsv: every(exportCsv, { value: true }),
            switchSubscription: every(switchSubscription, { value: true }),
            changeRenewalDate: every(changeRenewalDate, { value: true }),
            addToGroup: every(addToGroup, { value: true }),
            grantFreeAccess: every(grantFreeAccess, { value: true }),
            modifyFreeAccess: every(modifyFreeAccess, { value: true }),
            cancel: every(cancel, { value: true }),
            reverseCancel: every(reverseCancel, { value: true }),
            deleteUser: every(deleteUser, { value: true })
        }

        return menu
    }),
    getCancelModalTexts: new Duck.Selector((selectors) => (state) => {
        const fetchedUsers = selectors.getFetchedUsers(state)
        const userType = fetchedUsers?.[0]?.membership?.type
        const moreThanOne = fetchedUsers.length > 1
        let header, description, actionButtonText, refund, refundDescription
        if (!fetchedUsers.length) {
            return { header, description, actionButtonText, refund, refundDescription }
        }

        if (moreThanOne) {
            header = `Cancel ${fetchedUsers.length} memberships?`
            description = 'The selected contacts will immediately lose access to any benefits associated with their membership. This cannot be undone.'
            actionButtonText = 'Cancel memberships'
            return { header, description, actionButtonText }
        } else {
            const latestSuccessfulTransaction = selectors.getMostRecentSuccessfulTransaction(state)
            switch (userType) {
                case PASS_HOLDER:
                    header = 'Cancel pass?'
                    description = 'Canceling this pass will end its benefits immediately and cannot be undone.'
                    actionButtonText = 'Cancel pass'
                    refund = 'Issue refund'
                    refundDescription = 'This payment has already been fully refunded.'
                    return { header, description, actionButtonText, refund, refundDescription }

                case PASS_HOLDER_GIFT:
                case INDIVIDUAL_SUBSCRIBER_GIFT:
                    header = 'Cancel gift?'
                    description = 'Canceling this gift will end its benefits immediately and cannot be undone.'
                    actionButtonText = 'Cancel gift'
                    return { header, description, actionButtonText }

                case INDIVIDUAL_SUBSCRIBER:
                    header = 'Cancel subscription?'
                    description = 'Canceling this subscription will end its benefits immediately and cannot be undone.'
                    actionButtonText = 'Cancel subscription'
                    refund = `Issue refund for last payment made${latestSuccessfulTransaction?.charge_date
                        ? ' on ' + dayjs(latestSuccessfulTransaction.charge_date).format('MM/DD/YYYY')
                        : ''
                        }`
                    refundDescription = 'This payment failed.'
                    return { header, description, actionButtonText, refund, refundDescription }
                case INDIVIDUAL_EX_SUBSCRIBER:
                    header = 'Cancel failed subscription?'
                    description = 'Canceling this failed payment will end attempts to capture the outstanding payment and allow the user to make a purchase in the future.'
                    actionButtonText = 'Cancel failed subscription'
                    return { header, description, actionButtonText }
                case EX_PASS_HOLDER:
                    header = 'Cancel failed pass?'
                    description = 'Canceling this failed payment will allow the user to make a purchase in the future.'
                    actionButtonText = 'Cancel failed pass'
                    refund = `Issue refund for last payment made on ${dayjs(fetchedUsers[0].current_purchase.start).format('MM/DD/YYYY')}`
                    return { header, description, actionButtonText, refund }
                case INDIVIDUAL_SUBSCRIBER_TRIAL:
                    header = 'Cancel subscription?'
                    description = 'Canceling this subscription will end its benefits immediately and cannot be undone.'
                    actionButtonText = 'Cancel subscription'
                    return { header, description, actionButtonText }

                case MONTHLY_DONOR:
                    header = `Cancel recurring ${contributionSingularLowerCase()}?`
                    description = 'This action cannot be undone.'
                    actionButtonText = `Cancel ${contributionSingularLowerCase()}`
                    refund = `Issue refund for last payment made on ${dayjs(fetchedUsers[0].current_purchase.start).format('MM/DD/YYYY')}`
                    refundDescription = 'This payment has already been fully refunded.'
                    return { header, description, actionButtonText, refund, refundDescription }

                case PASS_HOLDER_FREE_ACCESS:
                case INDIVIDUAL_SUBSCRIBER_FREE_ACCESS:
                    header = 'Cancel free access?'
                    description = 'This will immediately end their access to free benefits.'
                    actionButtonText = 'Cancel free access'
                    return { header, description, actionButtonText }

                default:
                    return { header, description, actionButtonText, refund, refundDescription }
            }
        }
    }),

    getReverseCancelModalText: new Duck.Selector((selectors) => (state) => {
        const fetchedUsers = selectors.getFetchedUsers(state)
        const moreThanOne = fetchedUsers.length > 1
        let header, description, actionButtonText, refund, refundDescription
        if (!fetchedUsers.length) {
            return { header, description, actionButtonText, refund, refundDescription }
        }

        if (moreThanOne) {
            header = 'Reverse cancellations?'
            description =
                'Each selected contact’s recurring payment will continue uninterrupted and renew on its originally scheduled renewal date.'
            actionButtonText = 'Reverse cancellations'
            return { header, description, actionButtonText }
        } else {
            const planType = get(fetchedUsers[0], 'current_purchase.type')
            const amount = format({ amount: get(fetchedUsers[0], 'current_purchase.amount'), currencyCode: get(fetchedUsers[0], 'current_purchase.currency'), asNumber: true })
            const purchaseEnd = get(fetchedUsers[0], 'current_purchase.end')
            header = 'Reverse cancellation?'
            description =
                `Their ${planType} will continue uninterrupted and renew for ${amount} on ${dayjs(purchaseEnd).format('MM/DD/YYYY')}.`
            actionButtonText = 'Reverse cancellation'
            return { header, description, actionButtonText }
        }
    }),

    getMenuTexts: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        const userType = get(selectedUsers[0], 'membership.type')
        let cancel
        switch (userType) {
            case PASS_HOLDER:
                cancel = 'Cancel pass plan...'
                return { cancel }
            case INDIVIDUAL_SUBSCRIBER:
                cancel = 'Cancel subscription plan...'
                return { cancel }
            case INDIVIDUAL_EX_SUBSCRIBER:
            case EX_PASS_HOLDER:
                cancel = 'Cancel failed payment'
                return { cancel }
            case MONTHLY_DONOR:
                cancel = `Cancel recurring ${contributionSingularLowerCase} plan...`
                return { cancel }
            case PASS_HOLDER_FREE_ACCESS:
            case INDIVIDUAL_SUBSCRIBER_FREE_ACCESS:
                cancel = 'Cancel free access...'
                return { cancel }
            default:
                cancel = 'Cancel'
                return { cancel }
        }
    }),

    getMembershipTexts: new Duck.Selector((selectors) => (state) => {
        const selectedUsers = selectors.getSelectedUsers(state)
        const userType = get(selectedUsers[0], 'membership.type')
        const isCancelled = get(selectedUsers[0], 'membership.status') === 'CANCELED'
        let cancel
        let reverseCancel
        switch (userType) {
            case PASS_HOLDER:
                cancel = 'Revoke pass'
                return { cancel }
            case INDIVIDUAL_SUBSCRIBER:
                cancel = 'Cancel subscription plan...'
                if (isCancelled) reverseCancel = 'Reactivate Subscription'
                return { cancel, reverseCancel }
            case INDIVIDUAL_EX_SUBSCRIBER:
            case EX_PASS_HOLDER:
                cancel = 'Cancel failed payment'
                return { cancel }
            case MONTHLY_DONOR:
                cancel = `Cancel recurring ${contributionSingularLowerCase()} plan...`
                if (isCancelled) reverseCancel = `Reactivate ${contributionSingularUpperCase()}`
                return { cancel, reverseCancel }
            case PASS_HOLDER_FREE_ACCESS:
            case INDIVIDUAL_SUBSCRIBER_FREE_ACCESS:
                cancel = 'Revoke free access...'
                return { cancel }
            case INDIVIDUAL_SUBSCRIBER_TRIAL:
                cancel = 'Cancel trial...'
                return { cancel }
            case INDIVIDUAL_SUBSCRIBER_GIFT:
                cancel = 'Cancel gift...'
                return { cancel }
            default:
                cancel = 'Cancel'
                return { cancel }
        }
    }),

    getMostRecentSuccessfulTransaction: new Duck.Selector((selectors) => (state) => {
        const transactions = selectors.getUserTransactionsSortedByDate(state)
        return get(transactions, [0], {})
    }),
    getMaxRefundAllowed: new Duck.Selector((selectors) => (state) => {
        const mostRecent = selectors.getMostRecentSuccessfulTransaction(state)
        const amount_refunded = get(mostRecent, 'amount_refunded', 0)
        const amount = get(mostRecent, 'amount', 0)
        return amount - amount_refunded
    }),
    getInfoForRefund: new Duck.Selector((selectors) => (state) => {
        const userPurchaseStart = selectors.getUserPurchaseStart(state)
        const userPurchaseEnd = selectors.getUserPurchaseEnd(state)
        const maxRefund = selectors.getMaxRefundAllowed(state)
        const mostRecent = selectors.getMostRecentSuccessfulTransaction(state)

        // these could be separate selectors - maybe later
        const dateOfLastSuccessfulPurchase = dayjs(get(mostRecent, 'charge_date', null)).format('MM/DD/YYYY')
        const amountRefunded = get(mostRecent, 'amount_refunded', 0)
        const amount = get(mostRecent, 'amount', 0)
        const currency = get(mostRecent, 'currency', 'usd')
        const chargeId = get(mostRecent, 'id', null)
        const { proratedRefund, proratedRefundFormatted } = proratePlanCancelledInDays(
            amount,
            amountRefunded,
            userPurchaseStart,
            userPurchaseEnd,
            dayjs(),
            currency
        )
        return {
            chargeId,
            currency,
            dateOfLastSuccessfulPurchase,
            mostRecent,
            maxRefund,
            maxRefundFormatted: format({ amount: maxRefund, currencyCode: currency, asNumber: true }),
            proratedRefund,
            proratedRefundFormatted,
            userPurchaseEnd: dayjs(userPurchaseEnd).format('MM/DD/YYYY')
        }
    }),
    getSelectedActivity: (state) => state[store].selectedActivity,
    getUsersMeta: (state) => state[store]?.meta,
    getUsersEmailMeta: (state) => state[store]?.meta?.email || {},
    getUsersSmsMeta: (state) => state[store]?.meta?.sms || {},
    getUsersSmsOptIns: (state) => state[store]?.meta?.sms?.opt_ins ?? 0,
    getUsersEmailOptIns: (state) => state[store]?.meta?.email?.opt_ins ?? 0,
    getUsersCount: (state) => state[store]?.meta?.users ?? 0,

    getImportID: (state) => state[store]?.user_import?.import_id ?? null,
    getUserJWT: (state) => state[store]?.user_import?.user_jwt ?? null,
    getTemplateKey: (state) => state[store]?.user_import?.template_key ?? null,
    isImportModalOpen: (state) => state[store]?.user_import?.isImportModalOpen ?? false,
    getUserImportProgress: (state) => state[store]?.user_import?.progress,
    // This is for getting how many imported users have not been notified for each platform
    // { email: 0, phone: 0 }
    getNotifiableImportUserCount: (state) => state[store]?.user_import?.notify?.check

})

export default selectors
