import { putResolve, put, select, delay, call } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import appModule from 'modules/app'
import ModalModule from 'modules/modal'
import ErrorService from 'utils/services/ErrorService'
import AccountModule from 'modules/account'

function* grantFreeAccess({ duck, payload, callback }) {
    const { users, values } = payload
    const publisherid = yield select(AccountModule.selectors.getPublisherId)
    const user_state_ids = users.map(user => user.user_state_id)

    let endpoint_url = `${endpoints.WHITELIST}`

    if (users.length == 1) {
        endpoint_url += `/${users[0].user_state_id}`
    }

    const params = {
        product_option_id: values.plan,
        plan_end: values.date[1],
        user_state_ids
    }

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoint_url,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: params
            }
        })

        yield put({
            type: duck.types.GRANT_FREE_ACCESS_SUCCESS,
            data
        })

        // elastic needs time
        yield delay(2000)

        // refresh the updated users
        yield put({
            type: duck.types.FETCH_SELECTED_USERS,
            payload: user_state_ids
        })

        if (user_state_ids.length === 1) {
            yield put({
                type: duck.types.REINDEX_USER,
                payload: {
                    user_state_id: user_state_ids[0],
                    showNotification: false,
                }
            })
        }

        yield put(
            ModalModule.creators.closeModal({
                key: 'grantFreeAccess',
                actionFinished: true
            })
        )

        yield put(
            appModule.creators.addNotification({
                type: 'success',
                message: 'Free access granted successfully'
            })
        )
    } catch (error) {
        const submissionError = new ErrorService(error).makeANiceSubmissionError()
        yield call(callback, submissionError)

        yield put({
            type: duck.types.GRANT_FREE_ACCESS_ERROR,
            error
        })
    }
}

export default grantFreeAccess
