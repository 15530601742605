import createDuck from '../baseDuck'
import actions from './actions'
import reducer, { initialState } from './reducer'
import sagas from './sagas'
import selectors from './selectors'
import takes from './takes'

export default createDuck({
    store: 'products',
    initialState,
    path: '/products'
}).extend({
    types: actions.types,
    reducer,
    sagas,
    takes,
    selectors,
    creators: actions.creators
})
