import { useEffect } from 'react'
import AnalyticsModule from 'modules/analytics'
import { useRouter } from 'next/router'



let lastTrackedPath
const onLocationChange = ({ url, dispatch }) => {

    if (url === lastTrackedPath) {
        return
    }

    lastTrackedPath = url

    const blacklistedRoutes = ['/login', '/logout', '/']

    if (!blacklistedRoutes.includes(url)) {
        dispatch(
            AnalyticsModule.creators.viewPage({
                page_name: url.slice(1),
            })
        )
    }
}

const Track = ({ store: { dispatch } = {} }) => {
    const router = useRouter()

    useEffect(() => {
        if (router.isReady) {
            onLocationChange({ url: router.asPath, dispatch })
        }
      }, [router.isReady, router.asPath])

    return null

}


export default Track
