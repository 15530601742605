import { createAsyncThunk } from '@reduxjs/toolkit'

/**
 * The fetchGuides async thunk is called with the publisherId
 * and returns a list of guides to the publisher
 * [{
 *      title: 'Setting Up',
 *      description: 'Learn how to set up your first picolink',
 *      read_time: 2,
 *      url: 'https://help.hype.co/hc/en-us/articles/8786868551315',
 * }]
 * @type {Array.<{ title: string, description: string, read_time: number, url: string}>}
 * @name fetchActionEventsThunk
 * @memberof GuidesFeature.GuidesSlice.GuidesThunks
 */
const fetchGuides = createAsyncThunk(
    'guides/fetchGuides',
    async () => {
        const response = {
            data: [{
                title: 'Setting up your Hype Site',
                description: 'Learn how to set up your Hype Site, add new ' +
                    'content blocks, link your social media accounts, and ' +
                    'update your brand appearance.',
                read_time: 5,
                url: 'https://help.hype.co/hc/en-us/articles/8786868551315',
            }, {
                title: 'Owning your audience',
                description: 'When visitors sign up on your Hype Site for your ' +
                    'updates, they become contacts in your account. Learn more ' +
                    'about how growing your contacts list can supercharge your ' +
                    'business.',
                read_time: 2,
                url: 'https://help.hype.co/hc/en-us/articles/8786859673235',
            }]
        }
        return response.data
    }
)

export default fetchGuides
