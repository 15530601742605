import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { usePublisherId } from "../dependencies"

import { 
    generatePortalSession, 
    generateCheckoutSession, 
    generatePortalSessionLoading, 
    generateCheckoutSessionLoading, 
    generateCheckoutSessionSuccess, 
    generatePortalSessionSuccess,
} from "../slice"

import usePlatformAccessPaywall from "./usePlatformAccessPaywall"

const usePlatformAccess = () => {
    const publisherId = usePublisherId()
    const dispatch = useDispatch()

    const _generatePortalSession = useCallback(({ appTierLabel = null }) => {
        dispatch(
            generatePortalSession({
                publisherId,
                appTierLabel,
            })
        )
    }, [dispatch])

    const _generatePortalSessionCancellation = useCallback(() => {
        dispatch(
            generatePortalSession({
                publisherId,
                cancel: true,
            })
        )
    }, [dispatch])

    const _generateCheckoutSession = useCallback(({ appTierLabel }) => {
        dispatch(
            generateCheckoutSession({
                publisherId,
                appTierLabel,
            })
        )
    }, [dispatch])

    const { openPlatformAccessPaywall, Paywall } = usePlatformAccessPaywall()

    const _generatePortalSessionLoading = useSelector(generatePortalSessionLoading)
    const _generateCheckoutSessionLoading = useSelector(generateCheckoutSessionLoading)
    const _generateCheckoutSessionSuccess = useSelector(generateCheckoutSessionSuccess)
    const _generatePortalSessionSuccess = useSelector(generatePortalSessionSuccess)

    return {
        generatePortalSession: _generatePortalSession,
        generateCheckoutSession: _generateCheckoutSession,
        generatePortalSessionCancellation: _generatePortalSessionCancellation,
        openPlatformAccessPaywall,
        Paywall,
        generatingSession: (
            _generatePortalSessionLoading
            || _generateCheckoutSessionLoading
            || _generateCheckoutSessionSuccess
            || _generatePortalSessionSuccess
        ),
    }
}

export default usePlatformAccess
