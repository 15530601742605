import { put, putResolve } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import { FieldSubmitError } from 'utils/errors'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'

function* getMyArticleLockingSettings({ types, payload }) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.ARTICLE_LOCKING,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                timeout: 30000
            }
        })
        if (data.status === 200) {
            yield put({
                type: types.GET_MY_ARTICLE_LOCKING_SETTINGS_SUCCESS,
                payload: {
                    ...data.body
                }
            })
        } else if (data.error) {
            yield put({
                type: types.GET_MY_ARTICLE_LOCKING_SETTINGS_ERROR,
                error: data.error
            })
            yield put(
                AppModule.creators.addNotification({
                    message: 'An error occurred while getting data',
                    type: 'danger'
                })
            )
        } else {
            throw Error('An uncatched error occured.')
        }
    } catch (e) {
        if (e instanceof FieldSubmitError) {
            let fieldErrors = {}
            Object.keys(payload).map((key) => {
                if (e.field === key) {
                    fieldErrors[key] = e.message
                }
            })

            // NOTE!! We are passing SAVING_MY_PROFILE_SUCCESS here because Final Form expects
            // submit errors to come back via a *resolved* promise.

            yield put({ type: types.GET_MY_ARTICLE_LOCKING_SETTINGS_ERROR, payload: fieldErrors })

            return fieldErrors
        }
    }
}

export default getMyArticleLockingSettings
