import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { name, getPublisherId } from '../dependencies'

import fetchEditions from './fetchEditions'
import createEdition from './createEdition'
import updateEdition from './updateEdition'
import fetchEditionById from './fetchEditionById'

const editionsAdapter = createEntityAdapter({
    selectId: (edition: any) => edition?.id,
    sortComparer: (a: any, b: any) => a?.id - b?.id,
})

const initialState = editionsAdapter.getInitialState({
    fetchEditionsError: null,
    fetchEditionsInProgress: false,

    createEditionError: null,
    createEditionSucceeded: null,
    createEditionInProgress: false,

    updateEditionError: null,
    updateEditionSucceeded: null,
    updateEditionInProgress: false,

    fetchEditionByIdError: null,
    fetchEditionByIdInProgress: false,

})

const editionsSlice = createSlice({
    name,
    initialState,
    reducers: {
        upsertOne: editionsAdapter.upsertOne,
    },
    extraReducers: {
        [fetchEditions.pending]: (state: any, { payload }: any) => {
            state.fetchEditionsInProgress = true
            state.fetchEditionsError = null
        },
        [fetchEditions.fulfilled]: (state: any, { payload }: any) => {
            editionsAdapter.setAll(state, payload?.editions || [])
            state.fetchEditionsInProgress = false
            state.fetchEditionsError = null
        },
        [fetchEditions.rejected]: (state: any, { payload: error }: any) => {
            state.fetchEditionsInProgress = false
            state.fetchEditionsError = error
        },

        [fetchEditionById.pending]: (state: any, { payload }: any) => {
            state.fetchEditionByIdInProgress = true
            state.fetchEditionByIdError = null
        },
        [fetchEditionById.fulfilled]: (state: any, { payload }: any) => {
            editionsAdapter.upsertOne(state, payload?.edition || {})
            state.fetchEditionByIdInProgress = false
            state.fetchEditionByIdError = null
        },
        [fetchEditionById.rejected]: (state: any, { payload: error }: any) => {
            state.fetchEditionByIdInProgress = false
            state.fetchEditionByIdError = error
        },

        [createEdition.pending]: (state: any, { payload }: any) => {
            state.createEditionSucceeded = null
            state.createEditionInProgress = true
            state.createEditionError = null
        },
        [createEdition.fulfilled]: (state: any, { payload }: any) => {
            state.createEditionSucceeded = true
            state.createEditionInProgress = false
            state.createEditionError = null
        },
        [createEdition.rejected]: (state: any, { payload: error }: any) => {
            state.createEditionSucceeded = false
            state.createEditionInProgress = false
            state.createEditionError = error
        },
        [updateEdition.pending]: (state: any, { payload }: any) => {
            state.updateEditionSucceeded = null
            state.updateEditionInProgress = true
            state.updateEditionError = null
        },
        [updateEdition.fulfilled]: (state: any, { payload }: any) => {
            state.updateEditionSucceeded = true
            state.updateEditionInProgress = false
            state.updateEditionError = null
        },
        [updateEdition.rejected]: (state: any, { payload: error }: any) => {
            state.updateEditionSucceeded = false
            state.updateEditionInProgress = false
            state.updateEditionError = error
        },
    },
})

const {
    upsertOne: upsertEdition,
} = editionsSlice.actions

// SELECTORS
const {
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
} = editionsAdapter.getSelectors((state: any) => state[name])

// GENERAL
const editionsInitialState = {
    [name]: initialState,
}

const editionsReducer = {
    [name]: editionsSlice.reducer,
}

function* refetchEditions (): any {
    const publisherId = yield select(getPublisherId)

    yield put(
        yield call(fetchEditions, {
            publisherId,
        })
    )
}

const takes = [
    ...fetchEditions.takes,
    ...createEdition.takes,
    ...updateEdition.takes,
    ...fetchEditionById.takes,
    takeEvery(createEdition.fulfilled, refetchEditions),
    takeEvery(updateEdition.fulfilled, refetchEditions),
]

// EXPORTS
export default editionsSlice.reducer

export {
    editionsInitialState as initialState,
    editionsReducer as reducer,
    takes,

    selectAll as editions,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,

    fetchEditions,
    createEdition,
    updateEdition,
    fetchEditionById,

    upsertEdition,
}
