/** @namespace customizations.dependencies */

import endpoints from 'utils/lib/endpoints'

import useStyles from './hooks/useStyles'

const name = 'customizations'

export {
    name,
    endpoints,
    useStyles,
}
