import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
function* reindexUser(action) {
    const { duck, payload: { user_state_id, showNotification = true } } = action
    const publisherid = yield select(AccountModule.selectors.getPublisherId)

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.USERS}/profile/${user_state_id}/sync`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        yield put({
            type: duck.types.REINDEX_USER_SUCCESS,
            data
        })

        if (showNotification) {
            yield put(
                AppModule.creators.addNotification({
                    message: 'Reindexed user successfully',
                    type: 'success'
                })
            )
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.REINDEX_USER_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.REINDEX_USER_ERROR,
                error
            })
        }
    }
}

export default reindexUser
