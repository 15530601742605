import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useFormState } from 'react-final-form'
import cx from 'classnames'
import Picon from '@piconetworks/pkg-picon'
import Loading from '@piconetworks/pkg-loading'

import {
    getAvailableCreditsByType,
    fetchPlanCredits,
} from '../dependencies'

import {
    estimateMessage,
    getEstimateMessageCost,
    getEstimateMessageInProgress,
    getEstimateMessageErrorMessage,
    showCreditsModal,
} from '../slice'

import styles from './MessageEstimate.module.scss'


const MessageEstimate = () => {
    const dispatch = useDispatch()
    const cost = useSelector(getEstimateMessageCost)
    const inProgress = useSelector(getEstimateMessageInProgress)
    const errorMessage = useSelector(getEstimateMessageErrorMessage)
    const { mutators } = useForm()
    const { values } = useFormState()
    const { type } = values

    const availableSmsCredits = useSelector((state) => getAvailableCreditsByType(state, 'sms'))
    const availableEmailCredits = useSelector((state) => getAvailableCreditsByType(state, 'email'))

    let availableCredits = 0

    if (type === 'sms') {
        availableCredits = availableSmsCredits
    } else if (type === 'email') {
        availableCredits = availableEmailCredits
    } else {
        availableCredits = availableSmsCredits + availableEmailCredits
    }

    const notEnoughCredit = cost ? availableCredits === 0 || Number(cost) >= Number(availableCredits) : false
    // const notEnoughCredit = false

    useEffect(() => {
        if (values?.image_url === null && values?.message === '') {
            return
        }

        dispatch(estimateMessage({
            image_url: values.image_url,
            message: values.message,
            type: values.type,
        }))
    }, [values])

    useEffect(() => {
        dispatch(fetchPlanCredits())
    }, [
        dispatch,
    ])

    useEffect(() => {
        if (values.notEnoughCredit !== notEnoughCredit && cost) {
            mutators.setValue('notEnoughCredit', notEnoughCredit)
        }
    }, [notEnoughCredit, values, cost])

    const onClickQuestion = useCallback((e) => {
        e.preventDefault()

        dispatch(showCreditsModal())
    }, [dispatch])

    return (
        <>
            <div className={cx(styles.estimate, { [styles.error]: notEnoughCredit })}>
                <p className={styles.creditsText}>
                    {!cost && type !== 'email' && '0 Texts'}
                    {Number.isInteger(cost) && `${cost} Text${cost === 1 ? '' : 's'}`}

                </p>
                <div className={styles.icon}>
                    <Picon onClick={onClickQuestion} color={notEnoughCredit ? '#FF4440' : '#834EFF'} icon='icon_help' size='16' />
                </div>
            </div>
        </>
    )
}

export default MessageEstimate
