const actions = {}

actions.types = [
    'GET_FILES',
    'GET_FILES_SUCCESS',
    'GET_FILES_ERROR',

    'CREATE_FILE',
    'CREATE_FILE_SUCCESS',
    'CREATE_FILE_ERROR',

    'EDIT_FILE',
    'EDIT_FILE_SUCCESS',
    'EDIT_FILE_ERROR',

    'DELETE_FILE',
    'DELETE_FILE_SUCCESS',
    'DELETE_FILE_ERROR',

    'SET_SELECTED_FILE',

    'GET_FILE_STACK_KEYS',
    'GET_FILE_STACK_KEYS_SUCCESS',
    'GET_FILE_STACK_KEYS_ERROR'

]

actions.creators = ({ types }) => ({
    getFiles: (payload) => ({
        type: types.GET_FILES,
        payload
    }),
    createFile: (payload) => ({
        type: types.CREATE_FILE,
        payload
    }),
    editFile: (payload) => ({
        type: types.EDIT_FILE,
        payload
    }),
    deleteFile: (payload) => ({
        type: types.DELETE_FILE,
        payload
    }),
    setSelectedFile: (payload) => ({
        type: types.SET_SELECTED_FILE,
        payload
    }),
    getFileStackKeys: (payload) => ({
        type: types.GET_FILE_STACK_KEYS,
        payload
    })
})

export default actions
