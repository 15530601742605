import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

export default class FreeAccessRulesService {
    constructor(store, statuses) {
        this.store = store
        this.statuses = statuses
        this.accessRule = null
    }

    getAll(state) {
        return this._getItems(state)
    }

    _getItems(state) {
        const rules = state[this.store].freeAccess?.data || []
        return {
            active: rules.filter(item => !item.archived).sort((a, b) => a.order - b.order),
            archived: rules.filter(item => !!item.archived).sort((a, b) => a.order - b.order)
        }
    }

    getActive(state) {
        return this._getItems(state)?.active
    }

    getActiveAccessRuleById(state, id) {
        const accessRules = this._getItems(state)
        return [...accessRules.active, ...accessRules.archived].find(ar => ar.id === id) || {}
    }

    getLoading(state) {
        return state[this.store].freeAccess.status === this.statuses.LOADING
    }

    getName() {
        return this.accessRule?.name || 'Untitled Free Access Rule'
    }

    getInitialFreeAccess(type = 'anonymous') {
        // populate from fetched offer depending on type

        const defaultVal = {
            cond_enabled: false,
            cond_limitAccess: false,
            cond_onlyUnlock: null,
        }
        if (!isNil(this.accessRule[`${type}_access`])) {
            const returnedValue = this.accessRule[`${type}_access`]
            return {
                ...defaultVal,
                ...returnedValue,
                ...!isNil(returnedValue) && { cond_enabled: true },
                ...!isNil(returnedValue?.limit_quantity) && { cond_limitAccess: true},
                ...returnedValue?.unlock_all == true && { unlock_all: 'all' },
                ...returnedValue?.unlock_all == false && { unlock_all: 'conditional' },
                ...(!isNil(returnedValue?.match_type) && returnedValue?.unlock_all == false && !isEmpty(returnedValue.conditions)) && { cond_onlyUnlock: true },
            }
        } else {
            return {
                ...defaultVal
            }
        }
    }

    getResetFrequency() {
        // !todo fetch from this.accessRule when integrating
        return this.accessRule?.reset_interval || 'session'
    }

    getVisitorConditions() {
        return this.accessRule?.visitor || {
            match_type: 'any',
            conditions: []
        }
    }

    getInitialFormValues(state, id) {
        this.accessRule = this.getActiveAccessRuleById(state, id)

        return {
            name: this.getName(),
            anonymous_access: this.getInitialFreeAccess(),
            authenticated_access: this.getInitialFreeAccess('authenticated'),
            reset_interval: this.getResetFrequency(),
            visitor: this.getVisitorConditions()
        }
    }
}
