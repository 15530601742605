import useBillingError from 'hooks/useBillingError'

const BillingError = () => {
    const billingError = useBillingError()

    return <>{billingError}</>
}


export default BillingError
