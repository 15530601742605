import { call, put, take } from 'redux-saga/effects'

import IntegrationsModule from 'modules/integrations'
import ModalModule from 'modules/modal'

function* openUninstallModal({ payload: { appName } }) {

    yield put(yield call(IntegrationsModule.creators.getIntegrations))

    const fetchIntegrationsResult = yield take([
        IntegrationsModule.types.GET_INTEGRATIONS_SUCCESS,
        IntegrationsModule.types.GET_INTEGRATIONS_ERROR,
    ])

    if (fetchIntegrationsResult.type === IntegrationsModule.types.GET_INTEGRATIONS_ERROR) {
        throw new Error(fetchIntegrationsResult.error)
    }

    yield put(yield call(ModalModule.creators.openModal,{
        key: `uninstall${appName}`,
    }))

    return true
}

export default openUninstallModal
