import Duck from 'extensible-duck'
import get from 'lodash/get'

const selectors = ({ store, statuses }) => ({
    getLoading: (state) => state[store].status === statuses.LOADING,
    getError: (state) => state[store].error,
    getPlans: (state) => state[store].plans,
    getWhitelistedPlans: (state) => get(state[store], 'whitelistable', []),
    getWhitelistablePlans: (state) => get(state[store], 'whitelistable.products', []),
    getWhistablePlans: new Duck.Selector((selectors) => (state) => {
        const plans = selectors.getPlans(state)
        const whitelistedPlans = selectors.getWhitelistedPlans(state)
        return plans || [].filter(plan => whitelistedPlans.includes(plan.id))
    }),
})

export default selectors
