import { createAsyncSaga, name, endpoints, logger } from '../dependencies'

const updateEdition = createAsyncSaga(
    `${name}/updateEdition`,
    function* ({
        payload: {
            publisherId = null,
            editionId = null,
            name = null,
            type = null,
            color = null,
            shopifySku = null,
            engravable = null,
            trial = null,
            salesChannel = null,
            packSize = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            if (!editionId) {
                throw new Error('editionId is required')
            }

            if (!name) {
                throw new Error('name is required')
            }

            if (!type) {
                throw new Error('type is required')
            }

            if (!color) {
                throw new Error('color is required')
            }

            if (!shopifySku) {
                throw new Error('Shopify SKU is required')
            }

            if (!salesChannel) {
                throw new Error('sales channel is required')
            }

            if (!packSize) {
                throw new Error('pack size is required')
            }
            
            const response = yield request({
                method: 'patch',
                url: endpoints.UPDATE_EDITION(editionId),
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    ...(name && {name: name}),
                    ...(type && {type: type}),
                    ...(color && {color: color}),
                    ...(shopifySku && {shopify_sku: shopifySku}),
                    ...(engravable && {engravable: engravable}),
                    ...(trial && {trial: trial}),
                    ...(salesChannel && {sales_channel: salesChannel}),
                    ...(packSize && {pack_size: packSize}),
                },
            })

            const updatedEdition = {
                editionId: response?.id,
            }

            yield fulfilled({ updatedEdition })
        } catch (error) {
            logger.error('updateEdition error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default updateEdition
