import { combineReducers } from 'redux'
import { persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './rootReducer'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const migrations = {
    0: (state) => {
        // migration to add an instagram key to the app module preferences
        return {
            ...state,
            app: {
                ...state.app,
                preferences: {
                    contacts: state.app.preferences.contacts,
                    hello: null,
                    integration: {
                        instagram: {
                            step1: null,
                            step2: null,
                            step3: null
                        }
                    }
                }
            }
        }
    }
}

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        '_network',
        'auth',
        'modal',
        'messages',
        'cartUI',
        'customizations',
        'planCreditSummary',
        'estimateMessage',
        'vCard',
        'cards',
        'editions',
        'packs',
        'verifyWithOtp',
        'registerPhoneNumber',
        'platformAccess',
        'composeBlast',
        'googleDrive'
    ],
    debug: true,
    stateReconciler: autoMergeLevel2,
    migrate: createMigrate(migrations, { debug: true }),
}

const authPersistConfig = {
    key: '_network',
    storage,
    blacklist: ['access_token', 'scope'],
    debug: true
}


const _combinedReducers = {
    ...rootReducer,
    _network: persistReducer(authPersistConfig, rootReducer?._network),
}

export const combinedPersistedReducer = combineReducers(rootReducer ? _combinedReducers : {})

export default persistReducer(persistConfig, combinedPersistedReducer)
