import { useSelector } from 'react-redux'

import SettingsModule from 'modules/settings'

const useUserId = () => {
    const email = useSelector(SettingsModule.selectors.getMeDataEmail)

    return email
}

export default useUserId
