import { contributionSingularUpperCase } from 'features/ContributionLang'
import { isFeatureEnabled } from 'utils/lib/isFeatureEnabled'
import { GROUP_OFFER_TYPE_PROP_TYPES } from './groups'

export const LANDING_PAGES = {
    title: 'Site',
    label: 'page',
    value: 'landing_page',
    plural: 'landing_pages',
    url: '/pages'
}

export const POPUPS = {
    title: 'Popups',
    label: 'popup',
    value: 'popup',
    plural: 'popups',
    url: '/plugin/popups'
}

export const OFFER_FORMATS = {
    LANDING_PAGES,
    POPUPS
}

export const SIGNUP_TYPE = 'signup'
export const PAYMENT_TYPE = 'payment'
export const GROUP_TYPE = 'group'
export const STANDARD_TYPE = 'standard'

export const OFFER_TYPES = (format) => {
    return {
        [SIGNUP_TYPE]: `signup_${format}`,
        [PAYMENT_TYPE]: `payment_${format}`,
        [STANDARD_TYPE]: 'standard_page',
        ...format === LANDING_PAGES.value && {
            [GROUP_TYPE]: `group_${format}`
        }
    }
}

export const SUBSCRIPTION_PRODUCT_BLOCK_SECTION = 'subscription_product_block'
export const TIPJAR_PRODUCT_BLOCK_SECTION = 'donation_product_block'
export const PASS_PRODUCT_BLOCK_SECTION = 'pass_product_block'

export const VCARD_BLOCK_SECTION = 'vcard'

export const PRODUCTS_SECTION = 'product'
export const CUSTOM_TEXT_SECTION = 'custom_text'
export const TEXT_SECTION = 'text'
export const NEWSLETTERS_SECTION = 'newsletters'
export const FIELDS_SECTION = 'field'
export const FIELDS_SECTION_OLD = 'field_old'
export const SIGNUP_METHODS_SECTION = 'signup_methods'
export const LINKS_SECTION = 'link'
export const HEADER_SECTION = 'header'
export const CONSOLIDATED_SECTION = 'consolidated'

// block.type will = SIGNUP_SECTION
// then block.signup_block can be email, sms or consolidated
export const SIGNUP_SECTION = 'signup'
export const NEWSLETTERS_SIGNUP_SECTION = 'email'
export const CONSOLIDATED_SIGNUP_SECTION = 'consolidated'
export const TEXT_SIGNUP_SECTION = 'sms'
export const VCARD_EDITOR_SECTION = 'vcard'

export const SIGNUP_METHODS = {
    email: 'Manual Input',
    facebook: 'Facebook',
    github: 'GitHub',
    google: 'Google',
    linkedin: 'LinkedIn',
    twitter: 'X',
    ...(isFeatureEnabled() && { apple: 'apple' })
}

export const ACK_DEFAULT_PAYMENT_TYPE = 'ack_default_payment'
export const ACK_DEFAULT_SIGNUP_TYPE = 'ack_default_signup'
export const ACK_TRIAL_TYPE = 'ack_trial'
export const ACK_DONATION_SINGLE_TYPE = 'ack_donation_single'
export const ACK_DONATION_RECURRING_TYPE = 'ack_donation_recurring'
export const ACK_PASS_TYPE = 'ack_pass'



const CUSTOM_TEXT_URL = process.env.CUSTOM_TEXT_URL

export const AWS_S3_CUSTOM_TEXT_URL = CUSTOM_TEXT_URL

export const OFFER_STATUS = {
    active: 'active',
    archived: 'archived',
}

export const OFFER_TYPE_PROP_TYPES = [SIGNUP_TYPE, PAYMENT_TYPE, ...GROUP_OFFER_TYPE_PROP_TYPES]

export const makeRichTextFieldName = (type) => {
    if (type === 'product') {
        return 'payment_rich_text'
    }
    return `${type}_rich_text`
}

export const BLOCK_TITLES = {
    [HEADER_SECTION]: 'Header',
    [TEXT_SECTION]: 'Text',
    [CUSTOM_TEXT_SECTION]: 'Text',
    [NEWSLETTERS_SIGNUP_SECTION]: 'Signup',
    [TEXT_SIGNUP_SECTION]: 'Text Signup',
    [PRODUCTS_SECTION]: 'Products',
    [LINKS_SECTION]: 'Link',
    [TEXT_SIGNUP_SECTION]: 'Text Signup',
    [CONSOLIDATED_SECTION]: 'Signup',
    [SUBSCRIPTION_PRODUCT_BLOCK_SECTION]: 'Subscription',
    [TIPJAR_PRODUCT_BLOCK_SECTION]: 'Tip Jar',
    [PASS_PRODUCT_BLOCK_SECTION]: 'Pass',
    [VCARD_BLOCK_SECTION]: 'Contact Info',
}

export const LINK_FORMAT_TEXT_ONLY = 'text-only'
export const LINK_FORMAT_IMAGE_AND_TEXT = 'image-and-text'

export const DEFAULT_LP_IMAGE = 'https://images.pico.tools/defaultCoverPhoto_page.png'
export const DEFAULT_POPUP_IMAGE = 'https://images.pico.tools/defaultCoverPhoto_popup.png'
