const types = [
    'REQUEST_PLANS',
    'REQUEST_PLANS_SUCCESS',
    'REQUEST_PLANS_ERROR',
    'REQUEST_WHITELISTABLE_PLANS',
    'REQUEST_WHITELISTABLE_PLANS_SUCCESS',
    'REQUEST_WHITELISTABLE_PLANS_ERROR'
]

const creators = ({ types }) => ({
    requestPlans: () => ({ type: types.REQUEST_PLANS }),
    requestWhitelistablePlans: (payload) => ({ type: types.REQUEST_WHITELISTABLE_PLANS, payload })
})

export { types, creators }
