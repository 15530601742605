/** @namespace planCreditSummarySlice.slice */

import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

import fetchPlanCredits from './fetchPlanCredits'

const initialState = {
    summary: null,

    fetchPlanCreditsInProgress: false,
    fetchPlanCreditsError: null,
}

const planCreditSummarySlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [fetchPlanCredits.pending]: (state) => {
            state.summary = initialState.summary

            state.fetchPlanCreditsInProgress = true
            state.fetchPlanCreditsError = initialState.fetchPlanCreditsError
        },
        [fetchPlanCredits.fulfilled]: (state, { payload: summary }) => {
            state.summary = summary

            state.fetchPlanCreditsInProgress = false
            state.fetchPlanCreditsError = initialState.fetchPlanCreditsError
        },
        [fetchPlanCredits.rejected]: (state, { payload: error }) => {
            state.summary = initialState.summary

            state.fetchPlanCreditsInProgress = false
            state.fetchPlanCreditsError = error
        },
    },
})

// SELECTORS
const getFullCreditSummary = (state) => state[name]
const getCreditSummaryByType = createDraftSafeSelector(
    [
        getFullCreditSummary,
        (state, type) => type,
    ],
    (planCreditSummary, typeToFind) => {
        return planCreditSummary?.summary?.find(({ type = null } = {}) => type === typeToFind)
    },
)

const getFetchPlanCreditsInProgress = createDraftSafeSelector(
    getFullCreditSummary,
    (planCreditSummary) => planCreditSummary?.fetchPlanCreditsInProgress,
)

const getFetchPlanCreditsError = createDraftSafeSelector(
    getFullCreditSummary,
    (planCreditSummary) => planCreditSummary?.fetchPlanCreditsError,
)

const getTotalCreditsByType = createDraftSafeSelector(
    [
        (state, type) => getCreditSummaryByType(state, type),
    ],
    (planCreditSummary) => {
        return planCreditSummary?.total
    },
)

const getAvailableCreditsByType = createDraftSafeSelector(
    [
        (state, type) => getCreditSummaryByType(state, type),
    ],
    (planCreditSummary) => {
        return planCreditSummary?.available
    },
)

const getUsedCreditsByType = createDraftSafeSelector(
    [
        (state, type) => getTotalCreditsByType(state, type),
        (state, type) => getAvailableCreditsByType(state, type),
    ],
    (total, available) => {
        return total - available
    },
)

const getUsedPlanCreditsByTypeAsPercent = createDraftSafeSelector(
    [
        (state, type) => getTotalCreditsByType(state, type),
        (state, type) => getUsedCreditsByType(state, type),
    ],
    (total, used) => {
        return Math.floor((used / total) * 100)
    },
)

// GENERAL
const takes = [
    ...fetchPlanCredits.takes,
]

const planCreditSummaryInitialState = {
    [name]: initialState,
}

const planCreditSummaryReducer = {
    [name]: planCreditSummarySlice.reducer,
}

// EXPORTS
export default planCreditSummarySlice.reducer

export {
    takes,
    planCreditSummaryInitialState,
    planCreditSummaryReducer,

    fetchPlanCredits,

    getCreditSummaryByType,
    getTotalCreditsByType,
    getAvailableCreditsByType,
    getUsedCreditsByType,
    getUsedPlanCreditsByTypeAsPercent,

    getFetchPlanCreditsInProgress,
    getFetchPlanCreditsError,
}
