const generateShortCode = (length = 8) => {
    const AVAILABLE_CHARACTERS = '23456789abdegjkmnpqrvwxyz'
    const getRandomCharFromSequence = () => AVAILABLE_CHARACTERS.charAt(Math.floor(Math.random() * AVAILABLE_CHARACTERS.length))
    let short_code = ''
    for (let i = 0; i < length; i++) {
        short_code += getRandomCharFromSequence()
    }
    return short_code
}

export default generateShortCode