import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST, SET_SCOPE } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* getMe({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data, status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.ME,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })
        if (status === 200) {
            yield put({
                type: SET_SCOPE,
                scope: data.user_type
            })
            yield put({
                type: types.GET_ME_SUCCESS,
                payload: {
                    ...data
                }
            })
                                  
        } else if (data.error) {
            yield put({
                type: types.GET_ME_ERROR,
                error: data.error
            })
            yield put(
                AppModule.creators.addNotification({
                    message: 'User info could not be fetched successfully.',
                    type: 'danger'
                })
            )
        } else {
            throw Error('An uncaught error occured.')
        }
    } catch (e) {
        yield put({
            type: types.GET_ME_ERROR,
            error: e
        })
    }
}

export default getMe
