import { put, putResolve, select, call } from 'redux-saga/effects'
import { FORM_ERROR } from 'final-form'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'

function* saveMyBusiness({ types, payload, callback, showNotifications = true }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: endpoints.MY_ORGANIZATION,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...payload,
                    update_type: 'business'
                }
            }
        })
        yield put({
            type: types.SAVING_MY_ORGANIZATION_SUCCESS,
            payload: {
                ...data
            }
        })

        if (showNotifications) {
            yield put(
                AppModule.creators.addNotification({
                    message: `${payload.brand ? 'Display name' : 'Business details'} successfully updated`,
                    type: 'success'
                })
            )
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            const submissionError = {
                [FORM_ERROR]: error?.response?.data?.message,
            }
            if (error?.response?.data?.errors) {
                const fieldErrors = Object.assign(submissionError, ...error.response.data.errors)
                yield call(callback, fieldErrors)
            } else {
                yield call(callback, submissionError)
            }
        }
        yield put(
            AppModule.creators.addNotification({
                message: 'An error occurred while saving',
                type: 'danger'
            })
        )
        yield put({
            type: types.SAVING_MY_ORGANIZATION_ERROR,
            error
        })


    }
}

export default saveMyBusiness
