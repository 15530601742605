import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import cx from 'classnames'
import { Flexbox } from '@piconetworks/pkg-box'
import Boutton from '@piconetworks/pkg-boutton'
import Loading from '@piconetworks/pkg-loading'
import AccountModule from 'modules/account'

import styles from './NewBlastForm.module.scss'
import { IPhoneIcon, US } from 'containers/Messages/Icons'
import { formatPhoneNumber } from 'utils/lib/formatString'
import { useRegisterPhoneNumber } from 'features/registerPhoneNumber'
import { fetchPlanCredits } from 'features/planCreditSummary'

const RegisteredPhoneNumber = () => {
    const dispatch = useDispatch()

    const { phoneNumber, requestInProgress, smsEnabled } = useRegisterPhoneNumber()
    
    useEffect(() => {
        if (phoneNumber && !requestInProgress) {
            dispatch(AccountModule.creators.getAccountInfo())
            dispatch(fetchPlanCredits())
        }
    }, [dispatch, phoneNumber, requestInProgress])
    
    if (requestInProgress) {
        return <Loading/>
    }
    return (
        <div
            className={styles.form}
        >
            <Flexbox.Container className={cx(styles.content, styles.claimed)} justifyContent='center' flexDirection='column' alignItems='center' >
                <p className={styles.text}>
                    {smsEnabled ? 'You can now send text messages from Hype! Phone number signups are now enabled on your Hype Site, and all text messages from your account will be sent from the phone number below:' 
                        : 'You can now send text messages from Hype! All text messages from your account will be sent from the phone number below:'}
                </p>
                <div>
                    <IPhoneIcon />
                </div>
                <Flexbox.Container justifyContent='center' alignItems='center' className={styles.phoneNumber}>
                    <US /> <span>{formatPhoneNumber(phoneNumber)}</span>
                </Flexbox.Container>

            </Flexbox.Container>

            <Flexbox.Container justifyContent='between' className={styles.footer}>
                <div></div>
                <Flexbox.Container alignItems='center' >

                    <div className={styles.positionRelative}>
                        <Boutton
                            type='submit'
                            variant='primary'
                            onClick={() => console.log('go to compose view')}
                        >
                            Continue
                        </Boutton>

                    </div>


                </Flexbox.Container>
            </Flexbox.Container>

        </div>
    )
}

RegisteredPhoneNumber.propTypes = {
    setPhoneNumberClaimed: PropTypes.func,
}

export default React.memo(RegisteredPhoneNumber)
