import produce from 'immer'

export const initialState = {
    status: 'INITIATED',
    error: false,
    selectedNewsletter: null,
    all: [],
    free: [],
    paid: [],
    preview: []
}

const reducer = (state, action, { types, statuses }) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case types.GET_NEWSLETTERS:
            case types.CREATE_NEWSLETTER:
            case types.UPDATE_NEWSLETTER:
            case types.DELETE_NEWSLETTER:
            case types.SORT_NEWSLETTERS_ORDER:
                draftState.status = statuses.LOADING
                draftState.error = false
                break

            case types.GET_NEWSLETTERS_SUCCESS:
            case types.CREATE_NEWSLETTER_SUCCESS:
            case types.UPDATE_NEWSLETTER_SUCCESS:
            case types.DELETE_NEWSLETTER_SUCCESS:
            case types.SORT_NEWSLETTERS_ORDER_SUCCESS:
                const { dontUpdateStore = false } = action
                draftState.status = statuses.READY
                draftState.error = false
                if (!dontUpdateStore) {
                    draftState.free = action.payload.filter(newsletter => newsletter.type === 'free')
                    draftState.paid = action.payload.filter(newsletter => newsletter.type === 'paid')
                    draftState.all = action.payload.sort((a, b) => a.newsletter_order - b.newsletter_order)
                }
                break

            case types.GET_NEWSLETTERS_ERROR:
            case types.CREATE_NEWSLETTER_ERROR:
            case types.UPDATE_NEWSLETTER_ERROR:
            case types.DELETE_NEWSLETTER_ERROR:
            case types.SORT_NEWSLETTERS_ORDER_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = action.error
                break

            case types.SET_SELECTED_NEWSLETTER:
                draftState.selectedNewsletter = action.payload
                break

            case types.REMOVE_SELECTED_NEWSLETTER:
                draftState.selectedNewsletter = null
                break

            default:
                return state
        }
    })

export default reducer
