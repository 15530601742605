import Boutton from '@piconetworks/pkg-boutton'

import styles from './Submit.module.scss'

const SubmitButton = ({
    children = null,
    disabled = false,
    variant = 'primary',
    block = true,
    onClick = (e: any) => {},
}) => {
    return (
        <Boutton
            className={styles.button}
            block={block}
            variant={variant}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </Boutton>
    )
}

export default SubmitButton
