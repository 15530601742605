import { putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* updateExportProgress(action) {
    const { selectors, types } = action
    try {
        const exportCSV = yield select(selectors.getExportCSV)
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.USERS + `/export/${exportCSV.request_id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        yield put({
            type: types.UPDATE_EXPORT_PROGRESS_SUCCESS,
            payload: data
        })
    } catch (error) {
        yield put({
            type: types.UPDATE_EXPORT_PROGRESS_ERROR,
            error
        })
    }
}

export default updateExportProgress
