import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getNotifiableImportedUserCount(action) {
    const { duck } = action
    const publisherid = yield select(AccountModule.selectors.getPublisherId)

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.USERS}/import/notify/check`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {},
            }
        })

        yield put({
            type: duck.types.GET_NOTIFIABLE_IMPORTED_USER_COUNT_SUCCESS,
            payload: data
        })

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.GET_NOTIFIABLE_IMPORTED_USER_COUNT_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.GET_NOTIFIABLE_IMPORTED_USER_COUNT_ERROR,
                error
            })
        }
    }
}

export default getNotifiableImportedUserCount
