import { put, putResolve, call } from 'redux-saga/effects'
import { FORM_ERROR } from 'final-form'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'

function* saveHiddenElements({ types, callback }) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.HIDDEN_ELEMENTS,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                timeout: 30000
            }
        })
        yield put({
            type: types.SAVE_HIDDEN_ELEMENTS_SUCCESS,
            payload: {
                ...data.body
            }
        })
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            const submissionError = {
                [FORM_ERROR]: error.response.data.message,
            }
            if (error.response.data.errors) {
                const fieldErrors = Object.assign(submissionError, ...error.response.data.errors)
                yield call(callback, fieldErrors)
            } else {
                yield call(callback, submissionError)
            }
        }

        yield put({
            type: types.SAVE_HIDDEN_ELEMENTS_ERROR,
            error
        })
    }
}

export default saveHiddenElements
