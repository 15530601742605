const homepageExperiment = () => {

    const homepageUrl = '/home'

    return {
        homepageUrl
    }
}

export default homepageExperiment
