import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'


function* getMyContentRestrictionSettings({ types }){
    try{
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const { status, data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.CONTENT_RESTRICTION,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data:{
                    publisher_id: publisherid
                }
            }
        })
        if(status === 200){
            yield put({
                type: types.GET_MY_CONTENT_RESTRICTION_SETTINGS_SUCCESS,
                payload: {
                    ...data
                }
            })
        } else if(data.error){
            yield put({
                type: types.GET_MY_CONTENT_RESTRICTION_SETTINGS_ERROR,
                payload: {
                    ...data.error
                }
            })
            yield put(
                AppModule.creators.addNotification({
                    message: 'An error occurred while getting data',
                    type: 'danger'
                })
            )
        } else {
            throw Error('An uncatched error occured.')
        }

    } catch(error){

        yield put({
            type: types.GET_MY_CONTENT_RESTRICTION_SETTINGS_ERROR,
            payload: {
                error
            }
        })

        yield put(
            AppModule.creators.addNotification({
                message: 'An error occurred while getting data',
                type: 'danger'
            })
        )
    }
}

export default getMyContentRestrictionSettings
