const actions = {}

actions.types = [
    'UPDATE_CUSTOM_PROPERTIES',
    'UPDATE_CUSTOM_PROPERTIES_SUCCESS',
    'UPDATE_CUSTOM_PROPERTIES_ERROR',
    'DELETE_CUSTOM_PROPERTIES',
    'DELETE_CUSTOM_PROPERTIES_SUCCESS',
    'DELETE_CUSTOM_PROPERTIES_ERROR',
    'GET_PROPERTIES',
    'GET_PROPERTIES_SUCCESS',
    'GET_PROPERTIES_ERROR',
    'UPDATE_CUSTOM_PROPERTY_VALUE',
    'UPDATE_CUSTOM_PROPERTY_VALUE_SUCCESS',
    'UPDATE_CUSTOM_PROPERTY_VALUE_ERROR',
    'DELETE_CUSTOM_PROPERTY_VALUE',
    'DELETE_CUSTOM_PROPERTY_VALUE_SUCCESS',
    'DELETE_CUSTOM_PROPERTY_VALUE_ERROR',
]

actions.creators = ({ types }) => ({
    updateCustomProperties: (payload, callback) => ({ type: types.UPDATE_CUSTOM_PROPERTIES, payload, callback }),
    updateCustomPropertyValue: (payload) => ({ type: types.UPDATE_CUSTOM_PROPERTY_VALUE, payload }),
    deleteCustomPropertyValue: (payload) => ({ type: types.DELETE_CUSTOM_PROPERTY_VALUE, payload }),
    deleteCustomProperties: (payload) => ({ type: types.DELETE_CUSTOM_PROPERTIES, payload }),
    getCustomProperties: () => ({ type: types.GET_PROPERTIES }),
})

export default actions
