import { CONDITION_OPTIONS } from 'utils/constants/conditions/default'
import generateShortCode from 'utils/lib/generateShortCode'
import getAndUploadMarkdown, { getAndUploadTextBlockMarkdown } from './getAndUploadMarkdown'
import getDuplicatedName from './getDuplicatedName'

import {
    SIGNUP_SECTION,
    NEWSLETTERS_SIGNUP_SECTION,
    CONSOLIDATED_SIGNUP_SECTION,
    TEXT_SECTION,
    TEXT_SIGNUP_SECTION,
    PRODUCTS_SECTION,
    DEFAULT_LP_IMAGE,
    DEFAULT_POPUP_IMAGE,
    CONSOLIDATED_SECTION,
} from 'utils/constants/offers'

export const getProductTypes = (products = []) => {
    const hasProducts = !!products.length

    const isDonation = hasProducts && products.every(product => product.type === 'donation')

    const hasTrial = hasProducts && products.some(product =>
        product.options.length &&
        product.options.some(product_option => !!product_option.trial_interval)
    )

    const isPass = hasProducts && products.every(product => product.type === 'pass')

    const isDefault = (!isDonation && !hasTrial && !isPass)

    return {
        isDonation,
        hasTrial,
        isPass,
        isDefault,
    }
}

export const getSignUpBlockPayload = ({ result, block, values, signup_rich_text_url, }) => {
    const { signup_block } = block
    const { collect_signups } = values

    const id = result?.signup_block?.id

    if (signup_block.type === CONSOLIDATED_SECTION) {
        result = {
            ...result,
            signup_block: {
                ...(id ? { id } : {}),
                title: values?.signup_title || null,
                subtitle: signup_rich_text_url || values?.signup_subtitle || null,
                marketing_opt_in: values?.marketing_opt_in,
                type: CONSOLIDATED_SECTION,
                ...(values?.default_authentication_method
                    ? { default: values.default_authentication_method }
                    : {}
                ),
            },
            type: SIGNUP_SECTION,
        }
    } else {
        if (signup_block.type === NEWSLETTERS_SIGNUP_SECTION && collect_signups) {
            result = {
                ...result,
                signup_block: {
                    ...(id ? { id } : {}),
                    title: values?.signup_title || null,
                    subtitle: values?.signup_subtitle || null,
                    marketing_opt_in: values?.marketing_opt_in,
                    type: NEWSLETTERS_SIGNUP_SECTION,
                    default: 'email',
                },
                type: SIGNUP_SECTION,
            }
        } else {
            result = {
                ...result,
                signup_block: {
                    ...(id ? { id } : {}),
                    title: values?.text_signup_title || null,
                    subtitle: values?.text_signup_subtitle || null,
                    type: TEXT_SIGNUP_SECTION,
                    default: 'sms',
                },
                type: SIGNUP_SECTION,
            }
        }
    }

    return result
}

export const formatProductBlock = ({ result, block }) => {
    const { product_block } = block

    if (!product_block) {
        return
    }

    return result
}


const formatFormValues = async (values, publisher, hasTypePayment, duplicate) => {
    const name = duplicate && values?.name
        ? getDuplicatedName(values.name)
        : values?.name

    const type = values.type
    const isPopup = type && type.includes('popup')

    const newsletters = values?.newsletters?.options?.reduce?.((acc, nl) => [
        ...acc,
        ...(nl.checked ? [nl.id] : [])
    ], [])

    const triggerValues = values.trigger

    const isTriggerEnabled = !!values?.trigger?.enabled && values.trigger.enabled === 'true'

    let enable_authenticated = hasTypePayment ? true : false
    let enable_anonymous = true
    let enable_member = hasTypePayment ? true : false

    if (isTriggerEnabled && hasTypePayment) {
        if (triggerValues.enable_logged_in) {
            enable_anonymous = false
            enable_authenticated = true
        }
        if (triggerValues.enable_non_paying) {
            enable_member = false
        }
    }

    const trigger = isTriggerEnabled
        ? {
            views: parseInt(triggerValues?.views),
            condition_type: values?.condition_type,
            type: triggerValues?.cond_show && triggerValues?.type
                ? triggerValues?.type
                : null,
            value: parseInt(triggerValues?.value || triggerValues?.scroll) || null,
            start: triggerValues?.cond_start ? triggerValues?.start : null,
            stop: triggerValues?.cond_stop ? parseInt(triggerValues?.stop) : null,
            reset_interval: triggerValues?.cond_reset ? triggerValues?.reset_interval : null,
            enable_authenticated: enable_authenticated,
            enable_anonymous: enable_anonymous,
            enable_member: enable_member,
        }
        // default values to make sure that the first triggers radio is toggled on
        : null

    const short_code = values?.short_code ? values?.short_code?.toLowerCase().replaceAll(' ', '_') : generateShortCode()
    const conditions = isTriggerEnabled ? values.conditions.reduce((acc, c) =>
        [...acc, ...(CONDITION_OPTIONS[c.type]
            ? [{
                type: c.type,
                inverse: c.inverse,
                ...c.type !== 'adblock' && { value: c.value },
                ...c.type === 'adblock' && { value: 'true' }
            }]
            : []
        )], []) : null

    const authentication_methods = Object.keys(values.signup_methods).reduce(
        (acc, k) => [...acc, ...(values.signup_methods[k] ? k !== 'sms' ? [k] : [] : [])], []
    )

    if (values.phone_signup_method) {
        authentication_methods.push('sms')
    }

    const order = values.order

    const products = (isPopup && !values.buttonTriggers) ? values.products.map((product) => ({ ...product, options: product.options.map((option) => ({ ...option, short_code: null })) })) : values.products

    // dont send this if its an images.pico.tools url because the api won't accept urls
    // by sending null, the cover won't update
    const cover_image = values?.enable_cover_image
        ? values?.cover_image
            ? values.cover_image
            : isPopup ? DEFAULT_POPUP_IMAGE : DEFAULT_LP_IMAGE
        : null

    const display_brand_name = values?.display_brand_name
    const display_logo = values?.display_logo
    const display_social_links = values?.display_social_links
    const display_banner_image = isPopup ? !!values?.enable_cover_image : !!cover_image

    const fields = values.fields

    const collect_signups = values.collect_signups

    const default_authentication_method = values.default_authentication_method

    let customText

    // convert rich text values to markdown and upload to aws
    const {
        signup_rich_text_url,
        payment_rich_text_url,
        ack_default_payment_rich_text_url,
        ack_default_signup_rich_text_url,
        ack_trial_rich_text_url,
        ack_donation_single_rich_text_url,
        ack_donation_recurring_rich_text_url,
        ack_pass_rich_text_url,
        ack_billing_admin_rich_text_url,
        ack_group_member_rich_text_url,
        billing_admin_payment_rich_text_url,
        billing_admin_signup_rich_text_url,
        group_member_join_rich_text_url,
        group_member_signup_rich_text_url,
    } = await getAndUploadMarkdown(values, publisher, hasTypePayment, duplicate)

    customText = {
        payment: payment_rich_text_url,
        ack_default_payment: ack_default_payment_rich_text_url || ack_pass_rich_text_url,
        ack_default_signup: ack_default_signup_rich_text_url,
        ack_trial: ack_trial_rich_text_url,
        ack_donation_single: ack_donation_single_rich_text_url,
        ack_donation_recurring: ack_donation_recurring_rich_text_url,
        ack_billing_admin: ack_billing_admin_rich_text_url,
        ack_group_member: ack_group_member_rich_text_url,
        billing_admin_payment: billing_admin_payment_rich_text_url,
        billing_admin_signup: billing_admin_signup_rich_text_url,
        group_member_join: group_member_join_rich_text_url,
        group_member_signup: group_member_signup_rich_text_url,
    }

    // pop up will use custom text for signup
    // landing pages will use this key but in the signup block subtitle instead
    // when the signup-block-description-rich-text is on
    if (isPopup) {
        customText.signup = signup_rich_text_url
    }

    // map through each block, and depending on the type
    // add root level keys from the form as necessary
    const blocks = await ([...values?.blocks]).reduce(async (acc, block, idx) => {
        const { signup_block, order } = block
        const blockOrder = order || idx + 1

        if (
            (signup_block?.type === NEWSLETTERS_SIGNUP_SECTION
                || signup_block?.type === CONSOLIDATED_SIGNUP_SECTION)
            && !collect_signups
        ) {
            return acc
        }

        let result = {
            ...block,
            order: blockOrder
        }

        switch (block.type) {
            case SIGNUP_SECTION:
                result = getSignUpBlockPayload({
                    result,
                    block,
                    values,
                    signup_rich_text_url,
                })
                break

            //!TODO add product block when ready
            case PRODUCTS_SECTION:
                result = formatProductBlock({ result, block })
                break

            // upload markdown and get urls to send to api
            case TEXT_SECTION:
                result = {
                    ...result,
                    text_block: {
                        url: await getAndUploadTextBlockMarkdown(result.text_block.value, publisher)
                    }
                }
                break
        }

        if (result) {
            return [...await acc, result]
        }

        return acc
    }, [])

    return {
        name,
        order,
        type,
        newsletters: newsletters,
        cover_image,
        display_brand_name,
        display_logo,
        display_social_links,
        fields,
        theme_id: values?.theme_id,
        form_customizations: values?.form_customizations,
        products,
        authentication_methods,
        short_code,
        custom_text: customText,
        display_banner_image,
        ...(!isPopup ? {
            collect_signups,
            blocks,
        } : {}),
        ...(isPopup ? {
            ...(default_authentication_method ? { default_authentication_method } : {}),
            trigger: isTriggerEnabled ? {
                ...trigger,
                conditions,
            } : null,
        } : {}),
    }
}

export default formatFormValues
