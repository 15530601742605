export const areaCodes = [
    // TFN Area Codes
    {label: '800', value: 800},
    {label: '833', value: 833},
    {label: '844', value: 844},
    {label: '855', value: 855},
    {label: '866', value: 866},
    {label: '877', value: 877},
    {label: '888', value: 888}
]
