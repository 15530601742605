import { useCallback, useState } from 'react'
import Boutton from '@piconetworks/pkg-boutton'

import {
    useFeatureManager,
    SubmitButton,
    usePublisherId,
    useUserId,
} from '../dependencies'

import constants from '../constants'
import usePlatformAccess from '../hooks/usePlatformAccess'
import useHasActiveSubscription from '../hooks/useHasActiveSubscription'

const SubscribeButton = ({ onClick, disabled = false }) => {
    const _onClick = useCallback((e) => {
        e.stopPropagation()
        e.preventDefault()
        onClick()
    }, [])
    return (
        <SubmitButton disabled={disabled} onClick={_onClick} block={false}>Subscribe</SubmitButton>
    )
}

const UpgradeButton = ({ onClick, disabled = false }) => {
    const _onClick = useCallback((e) => {
        e.stopPropagation()
        e.preventDefault()
        onClick()
    }, [])
    return (
        <SubmitButton disabled={disabled} onClick={_onClick} block={false}>Upgrade</SubmitButton>
    )
}

const DowngradeButton = ({ onClick, disabled = false }) => {
    const _onClick = useCallback((e) => {
        e.stopPropagation()
        e.preventDefault()
        onClick()
    }, [])
    return (
        <Boutton disabled={disabled} variant='secondary' onClick={_onClick}>Downgrade</Boutton>
    )
}

const Manage = ({ onClick, disabled = false }) => {
    const _onClick = useCallback((e) => {
        e.stopPropagation()
        e.preventDefault()
        onClick()
    }, [])
    return (
        <Boutton disabled={disabled} variant='secondary' onClick={_onClick}>Manage</Boutton>
    )
}

const FreeSubscribed = ({ onClick, disabled = false }) => {
    const _onClick = useCallback((e) => {
        e.stopPropagation()
        e.preventDefault()
        onClick()
    }, [])
    return (
        <Boutton disabled={disabled} variant='secondary' onClick={_onClick}>Subscribed</Boutton>
    )
}

const AppTierButton = ({ appTierLabel, disabled = false }) => {
    const publisherId = usePublisherId()
    const userId = useUserId()
    const featureManager = useFeatureManager({
        publisherId,
        userId,
    })

    const platformAccessAppTiers = Object
        .entries(constants.app_tiers)
        .reduce((acc, [key, value]) => {
            acc[value] = featureManager.getAppTierByLabel(value)
            return acc
        }, {})

    const currentlySubscribedAppTiers = Object
        .values(platformAccessAppTiers)
        .find((value) => {
            return value?.['has_access']
        })

    const thisAppTier = platformAccessAppTiers[appTierLabel]
    const isThisAppUpgrade = !!currentlySubscribedAppTiers && currentlySubscribedAppTiers?.['label'] < thisAppTier?.label
    const isThisAppSubscribed = !!currentlySubscribedAppTiers && currentlySubscribedAppTiers?.['label'] === thisAppTier?.label

    const { generatePortalSession, generateCheckoutSession, generatingSession, generatePortalSessionCancellation } = usePlatformAccess()
    const { hasTier00, currentSubscription } = useHasActiveSubscription()

    const _generateTier00DowngradePortalSession = useCallback(() => {
        generatePortalSessionCancellation()
    }, [generatePortalSessionCancellation, currentSubscription?.label])

    const _generatePortalSession = useCallback(() => {
        generatePortalSession({
            appTierLabel: thisAppTier?.label,
        })
    }, [generatePortalSession, thisAppTier?.label])

    const _generateCheckoutSession = useCallback(() => {
        generateCheckoutSession({
            appTierLabel: thisAppTier?.label,
        })
    }, [generateCheckoutSession, thisAppTier?.label])

    if (!thisAppTier) {
        return null
    }

    if (thisAppTier?.label === 'tier-00' && hasTier00) {
        return (
            <FreeSubscribed disabled={true} onClick={_generatePortalSession} />
        )
    } else if (thisAppTier?.label === 'tier-00' && !hasTier00) {
        return (
            <DowngradeButton disabled={generatingSession} onClick={_generateTier00DowngradePortalSession} />
        )
    }

    if (isThisAppSubscribed) {
        return (
            <Manage disabled={generatingSession} onClick={_generatePortalSession} />
        )
    }

    if (isThisAppUpgrade && !hasTier00) {
        return (
            <UpgradeButton disabled={generatingSession} onClick={_generatePortalSession} />
        )
    }

    if (currentlySubscribedAppTiers && !hasTier00) {
        return (
            <DowngradeButton disabled={generatingSession} onClick={_generatePortalSession} />
        )    
    }

    return (
        <SubscribeButton disabled={generatingSession} onClick={_generateCheckoutSession} />
    )
}

export default AppTierButton
