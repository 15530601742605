import Router from 'next/router'
import AccountModule from 'modules/account'

import { getTagsByCreator, updateTag } from '../../entities/cards'

const onClickSetHypeCardPage = (formId, { state, dispatch }) => {
    const publisherId = AccountModule.selectors.getPublisherId(state)
    let tagCount = []

    try {
        tagCount = getTagsByCreator(state, publisherId)
    } catch (error) {}

    if (tagCount.length > 1) {
        return
    }

    if (tagCount.length !== 1) {
        Router.push('/accessories')

        return
    }

    const tagId = tagCount[0]?.id

    dispatch(updateTag({
        publisherId,
        tagId,
        formId,
    }))
}

export default onClickSetHypeCardPage