import { useSelector } from "react-redux"

import SettingsModule from 'modules/settings'

const useUserCount = () => {
    const billing = useSelector(SettingsModule.selectors.getBillingInfo)

    return billing['userCount']
}

export default useUserCount
