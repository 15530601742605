import getPaginatedUsers from './getPaginatedUsers'
import fetchSelectedUsers from './fetchSelectedUsers'
import deleteContacts from './deleteContacts'
import emailReceipt from './emailReceipt'
import cancelPlan from './cancelPlan'
import getSingleUser from './getSingleUser'
import getUserNewsletters from './getUserNewsletters'
import updateUserNewsletters from './updateUserNewsletters'
import updateSingleUser from './updateSingleUser'
import reindexUsers from './reindexUsers'
import reindexUser from './reindexUser'

import grantFreeAccess from './grantFreeAccess'
import modifyFreeAccess from './modifyFreeAccess'
import fetchSelectedUserActivity from './fetchSelectedUserActivity'
import reverseCancellation from './reverseCancellation'
import loginAsUser from './loginAsUser'
import logoutUser from './logoutUser'
import exportCSV from './exportCSV'
import updateExportProgress from './updateExportProgress'
import issueRefund from './issueRefund'
import getUsersMeta from './getUsersMeta'
import importUsers from './importUsers'
import notifyUserImport from './notifyUserImport'
import getNotifiableImportedUserCount from './getNotifiableImportedUserCount'
import startUserImport from './startUserImport'
import checkUserImportStatus from './checkUserImportStatus'
import reIndexSyncStatus from './reIndexSyncStatus'
// we should consistently be using ...payload and ...duck
// todo -RC
const sagas = (duck) => ({
    *updateExportProgress(payload) {
        yield updateExportProgress({ ...payload, ...duck })
    },
    *cancelPlan(payload) {
        yield cancelPlan({ ...payload, ...duck })
    },
    *loginAsUser(payload) {
        yield loginAsUser({ ...payload, ...duck })
    },
    *logoutUser(payload) {
        yield logoutUser({ ...payload, ...duck })
    },
    *deleteContacts(payload) {
        yield deleteContacts({ ...payload, ...duck })
    },
    *emailReceipt(payload) {
        yield emailReceipt({ ...payload, duck })
    },
    *getPaginatedUsers(payload) {
        yield getPaginatedUsers({ ...payload, duck })
    },
    *getUserNewsletters(payload) {
        yield getUserNewsletters({ ...payload, duck })
    },
    *updateUserNewsletters(payload) {
        yield updateUserNewsletters({ ...payload, duck })
    },
    *fetchSelectedUsers(payload) {
        yield fetchSelectedUsers({ ...payload, duck })
    },
    *getSingleUser(payload) {
        yield getSingleUser({ ...payload, duck })
    },
    *updateSingleUser(payload) {
        yield updateSingleUser({ ...payload, duck })
    },
    *reindexUsers() {
        yield reindexUsers({ duck })
    },
    *reindexUser(payload) {
        yield reindexUser({ ...payload, duck })
    },
    *grantFreeAccess(payload) {
        yield grantFreeAccess({ ...payload, duck })
    },
    *modifyFreeAccess(payload) {
        yield modifyFreeAccess({ ...payload, duck })
    },
    *fetchSelectedUserActivity(payload) {
        yield fetchSelectedUserActivity({ ...payload, duck })
    },
    *reverseCancellation(payload) {
        yield reverseCancellation({ ...payload, ...duck })
    },
    *exportCSV(payload) {
        yield exportCSV({ ...payload, duck })
    },
    *issueRefund(payload) {
        yield issueRefund({ ...payload, duck })
    },
    *getUsersMeta() {
        yield getUsersMeta({ ...duck })
    },
    *importUsers(payload) {
        yield importUsers({ ...payload, duck })
    },
    *notifyUserImport(payload) {
        yield notifyUserImport({ ...payload, duck })
    },
    *getNotifiableImportedUserCount(payload) {
        yield getNotifiableImportedUserCount({ ...payload, duck })
    },
    *startUserImport(payload) {
        yield startUserImport({ ...payload, duck })
    },
    *checkUserImportStatus(payload) {
        yield checkUserImportStatus({ ...payload, duck })
    },
    *reIndexSyncStatus() {
        yield reIndexSyncStatus({ duck })
    }
})

export default sagas
