import produce from 'immer'

export const initialState = {}

const reducer = (state, { type }) =>
    produce(state, () => {
        switch (type) {
            default:
                return state
        }
    })

export default reducer
