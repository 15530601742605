import { put, putResolve, select } from 'redux-saga/effects'
import keys from 'lodash/keys'
import pickBy from 'lodash/pickBy'
import concat from 'lodash/concat'
import { REQUEST } from '@piconetworks/pkg-network-middleware'

import AccountModule from 'modules/account'
import AppModule from 'modules/app'
import ModalModule from 'modules/modal'
import UsersModule from 'modules/users'
import endpoints from 'utils/lib/endpoints'
import { TYPES } from 'utils/constants/fields'

function* updateCustomPropertyValue(action) {
    const { payload, duck } = action
    try {
        const user = yield select(UsersModule.selectors.getSingleUser)
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: endpoints.USERS + `/profile/${user.user_state_id}/property/${payload.id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    user_property_value: payload.type === TYPES.CHECKBOX ? concat(keys(pickBy(payload?.values)).filter((val) => !(val === 'other' || val === 'otherValue')), payload?.values?.other ? payload?.values?.otherValue : null).filter((a) => a != null) : payload?.value,
                }
            }
        })
        // yield put(UsersModule.creators.reindexUser({ user_state_id: user.user_state_id }))

        yield put(UsersModule.creators.getUser({ user_state_id: user.user_state_id }))



        yield put({
            type: duck.types.UPDATE_CUSTOM_PROPERTY_VALUE_SUCCESS,
        })

        yield put(
            ModalModule.creators.closeModal({
                key: 'updateCustomValue'
            })
        )

        yield put(
            AppModule.creators.addNotification({
                message: 'Custom property value updated successfully.',
                type: 'success'
            })
        )


    } catch (error) {

        yield put(
            AppModule.creators.addNotification({
                message: 'Something went wrong.',
                type: 'danger'
            })
        )
        yield put({
            type: duck.types.UPDATE_CUSTOM_PROPERTY_VALUE_ERROR,
        })
    }
}

export default updateCustomPropertyValue
