import { put, putResolve, call } from 'redux-saga/effects'
import { FORM_ERROR } from 'final-form'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'

function* saveArticleLocking({ types, payload, callback }) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.ARTICLE_LOCKING,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                timeout: 30000,
                data: {
                    ...payload
                }
            }
        })
        yield put({
            type: types.ARTICLE_LOCKING_SUCCESS,
            payload: {
                ...data.body
            }
        })

        yield put(
            AppModule.creators.addNotification({
                message: 'Article locking settings successfully updated',
                type: 'success'
            })
        )

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            const submissionError = {
                [FORM_ERROR]: error.response.data.message,
            }
            if (error.response.data.errors) {
                const fieldErrors = Object.assign(submissionError, ...error.response.data.errors)
                yield call(callback, fieldErrors)
            } else {
                yield call(callback, submissionError)
            }
        }

        yield put({
            type: types.ARTICLE_LOCKING_ERROR,
            error
        })

    }
}

export default saveArticleLocking
