import { call, select } from 'redux-saga/effects'
import AccountModule from 'modules/account'
import getAnalytics from 'utils/lib/analytics'

import logger from 'lib/logger'

function* viewPage({ payload }) {
    try {
        const publisherId = yield select(AccountModule.selectors.getPublisherId)

        const analytics = yield call(getAnalytics)

        analytics.plugins['analytics-plugin-pico'].initPublisher(publisherId)
    
        const { page_name } = payload
        yield call(
            analytics.page,
            {
                page_name
            }
        )
    } catch (err) {
        logger.error('Error tracking page view', {}, err)
    }
}

export default viewPage
