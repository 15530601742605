import Form from '@piconetworks/pkg-form'
import usePopup from "hooks/usePopup"
import Boutton from '@piconetworks/pkg-boutton'
import { H2 } from '@piconetworks/pkg-heading'
import { Box } from '@piconetworks/ui'
import { PopupContainer } from 'features/cartUI'
import { useCallback, useState } from 'react'

import styles from './FailedDeliveryPopup.module.scss'
import useNfcEditor from './useNfcEditor'

const FailedDeliveryPopup = ({
    tagId = null,
    closePopup = () => null,
}) => {
    const { deliveryFailed } = useNfcEditor(false)
    const [notifyCustomer, setNotifyCustomer] = useState(true)
    const _onSubmit = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()

        deliveryFailed(
            tagId,
            notifyCustomer,
        )
    }, [notifyCustomer])

    const _onClickSetNotifyCustomer = useCallback((e) => {
        setNotifyCustomer(!notifyCustomer)
    }, [setNotifyCustomer, notifyCustomer])

    return (
        <>
            <Box.Flexbox.Container
                alignItems='center'
                justifyContent='between'
                className={styles.popupTitle}
            >
                <H2>Mark delivery as failed</H2>
                <button
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        closePopup()
                    }}
                >
                    <span className={'icon-icon_close'} />
                </button>
            </Box.Flexbox.Container>
            <div className={styles.popupContent}>
                <PopupContainer>
                    <div className={styles.popupBody}>
                        <Box.Flexbox.Container alignItems="center" className={styles.popupBodySection}>
                            <Form.Checkbox
                                input={{
                                    onChange: (e) => e.preventDefault(),
                                    checked: notifyCustomer,
                                    name: 'notify_customer',
                                    onClick: _onClickSetNotifyCustomer,
                                }}
                                meta={{}}
                                text='Notify customer'
                            />
                        </Box.Flexbox.Container>
                    </div>                    
                    <div className={styles.popupFooter}>
                        <Boutton
                            block={true}
                            variant="primary"
                            type='submit'
                            onClick={_onSubmit}
                        >
                            Confirm
                        </Boutton>
                        <Boutton
                            block={true}
                            variant="secondary"
                            onClick={closePopup}
                        >
                            Cancel
                        </Boutton>
                    </div>
                </PopupContainer>
            </div>
        </>
    )
}


const useDeliveryFailedPopup = () => {
    const { Popup:  DeliveryFailedPopup, setModalOpen: setDeliveryFailedPopupOpen } = usePopup()

    return {
        DeliveryFailedPopup: ({ tagId }) => {
            return (
                <DeliveryFailedPopup onAfterClose={() => setDeliveryFailedPopupOpen(false)}>
                    <FailedDeliveryPopup tagId={tagId} closePopup={() => setDeliveryFailedPopupOpen(false)} />
                </DeliveryFailedPopup>
            )
        },
        setDeliveryFailedPopupOpen,
    }
}

export default useDeliveryFailedPopup
