import { put, putResolve } from 'redux-saga/effects'
import Router from 'next/router'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'

function* loginFromOnboarding({ creators, types, payload: { refresh_token, publisher_id, redirectTo } }) {
    try {
        if (!refresh_token || !publisher_id) {
            throw Error('refresh token and publisher id are required')
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.OAUTH.PUBLISHER_TOKEN}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    refreshtoken: refresh_token,
                    publisherid: publisher_id
                },
                timeout: 30000,
            },
        })

        if (!data?.publisher_id || !data.token) {
            Router.push('/logout')
            throw new Error('Invalid token')
        } else {
            yield putResolve(creators.tokenLogin({
                accessToken: data.token,
                refreshToken: data.refresh_token
            }))

            yield put({
                type: types.LOGIN_SUCCESS,
                payload: {
                    ...data
                }
            })

            yield put({
                type: types.LOGIN_FROM_ONBOARDING_SUCCESS,
                payload: {
                    response: data,
                },
            })
            yield put(creators.postLogin())

            Router.push(`/${redirectTo}` || '/')
        }
    } catch (error) {
        Router.push('/logout')
        yield put({
            type: types.LOGIN_FROM_ONBOARDING_ERROR,
            error
        })
    }
}

export default loginFromOnboarding
