import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => [
    takeEvery(types.GET_DASHBOARD_PRICING, sagas.getDashboardPricing),
    takeEvery(types.SAVE_DASHBOARD_PRICING, sagas.saveDashboardPricing),
    takeEvery(types.GET_APPLICATION_FEES, sagas.getApplicationFees),
    takeEvery(types.SAVE_APPLICATION_FEES, sagas.saveApplicationFees),
    takeEvery(types.ADD_CREATOR, sagas.addUnclaimedCreator),
    takeEvery(types.SET_TO_UNCLAIMED, sagas.setToUnclaimed),
    takeEvery(types.TRIGGER_SCRAPING, sagas.triggerScraping),
]

export default takes
