import { putResolve, put, select, all } from 'redux-saga/effects'
import { REQUEST, GET_TOKEN } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import BlocksModule from 'modules/blocks'

function* getOffer({ types, payload: { type, id } }) {
    try {
        let bucketName = process.env.CUSTOM_TEXT_URL.replace(/(.*?)\/(customtext.*)/, "$2")
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        let response
        const { status, data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.OFFERS}/${id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        response = data

        if (data?.custom_text) {
            if (Object.keys(data?.custom_text).length) {
                try {
                    const values = yield all(Object.keys(data?.custom_text)?.map((key) =>
                        putResolve({
                            type: GET_TOKEN,
                            config: {
                                method: 'get',
                                url: data?.custom_text?.[key]?.file_name,
                                headers: {
                                    Accept: 'text/markdown',
                                    'Content-Type': 'text/markdown',
                                    'cache-control': 'no-cache',
                                }
                            }
                        })))

                    const custom_texts = values?.map((val) => val?.data)?.reduce((acc, text, index) => {
                        const key = Object.keys(data?.custom_text)?.[index]
                        const fileNameTokens = data?.custom_text?.[key]?.file_name.split(bucketName)
                        const fileName = fileNameTokens[fileNameTokens.length - 1]
                        const url = data?.custom_text?.[key]?.file_name

                        return {
                            ...acc,
                            [key]: {
                                markdown: text,
                                file_name: fileName,
                                url
                            }
                        }
                    }, {})

                    response = {
                        ...response,
                        custom_text: custom_texts
                    }
                } catch {
                    // Doing nothing since the form custom texts are not that important as getting blocks and offer details.
                }
            }
        }

        if (status === 200) {
            yield put({
                type: types.GET_OFFER_SUCCESS,
                payload: {
                    type,
                    data: response,
                }
            })

            yield put(
                BlocksModule.creators.getBlocks({
                    payload: {
                        scope: 'creator',
                        formId: data.id,
                        publisherId: publisherid
                    }
                })
            )
        }
    } catch (error) {
        if (error?.response?.data?.message) {
            yield put({
                type: types.GET_OFFER_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: types.GET_OFFERS_ERROR,
                error
            })
        }
    }
}

export default getOffer
