class FieldSubmitError extends Error {
    constructor(message, field) {
        super(message)
        this.name = 'FieldSubmitError'
        this.field = field
    }
}

export {
    FieldSubmitError
}
