import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { name, getPublisherId } from '../dependencies'

import createPack from './createPack'
import fetchPack from './fetchPack'
import fetchAllPacks from './fetchAllPacks'
import updatePack from './updatePack'
import addTagToPack from './addTagToPack'
import createPackForTag from './createPackForTag'
import removeTagFromPack from './removeTagFromPack'

const packsAdapter = createEntityAdapter({
    selectId: (pack: any) => pack?.id,
    sortComparer: (a: any, b: any) => a?.id - b?.id,
})

const initialState = packsAdapter.getInitialState({
    createPackInProgress: false,
    createPackError: null,
    createPackSucceeded: null,

    fetchPackInProgress: false,
    fetchPackError: null,
    fetchPackSucceeded: null,

    fetchAllPacksInProgress: false,
    fetchAllPacksError: null,
    fetchAllPacksSucceeded: null,

    updatePackInProgress: false,
    updatePackError: null,
    updatePackSucceeded: null,

    addTagToPackInProgress: false,
    addTagToPackError: null,
    addTagToPackSucceeded: null,

    createPackForTagInProgress: false,
    createPackForTagError: null,
    createPackForTagSucceeded: null,

    removeTagFromPackInProgress: false,
    removeTagFromPackError: null,
    removeTagFromPackSucceeded: null,
})

const packsSlice = createSlice({
    name,
    initialState,
    reducers: {
        upsertOne: packsAdapter.upsertOne,
    },
    extraReducers: {
        [createPack.pending]: (state: any, { payload }: any) => {
            state.createPackInProgress = true
            state.createPackError = null
        },
        [createPack.fulfilled]: (state: any, { payload }: any) => {
            packsAdapter.upsertOne(state, payload?.pack || {})
            state.createPackInProgress = false
            state.createPackError = null
            state.createPackSucceeded = true
        },
        [createPack.rejected]: (state: any, { payload: error }: any) => {
            state.createPackInProgress = false
            state.createPackError = error
            state.createPackSucceeded = false
        },

        [fetchPack.pending]: (state: any, { payload }: any) => {
            state.fetchPackInProgress = true
            state.fetchPackError = null
        },
        [fetchPack.fulfilled]: (state: any, { payload }: any) => {
            packsAdapter.upsertOne(state, payload || {})
            state.fetchPackInProgress = false
            state.fetchPackError = null
            state.fetchPackSucceeded = true
        },
        [fetchPack.rejected]: (state: any, { payload: error }: any) => {
            state.fetchPackInProgress = false
            state.fetchPackError = error
            state.fetchPackSucceeded = false
        },

        [fetchAllPacks.pending]: (state: any, { payload }: any) => {
            state.fetchAllPacksInProgress = true
            state.fetchAllPacksError = null
        },
        [fetchAllPacks.fulfilled]: (state: any, { payload }: any) => {
            packsAdapter.setAll(state, payload || [])
            state.fetchAllPacksInProgress = false
            state.fetchAllPacksError = null
            state.fetchAllPacksSucceeded = true
        },
        [fetchAllPacks.rejected]: (state: any, { payload: error }: any) => {
            state.fetchAllPacksInProgress = false
            state.fetchAllPacksError = error
            state.fetchAllPacksSucceeded = false
        },

        [updatePack.pending]: (state: any, { payload }: any) => {
            state.updatePackInProgress = true
            state.updatePackError = null
        },
        [updatePack.fulfilled]: (state: any, { payload }: any) => {
            packsAdapter.upsertOne(state, payload || {})
            state.updatePackInProgress = false
            state.updatePackError = null
            state.updatePackSucceeded = true
        },
        [updatePack.rejected]: (state: any, { payload: error }: any) => {
            state.updatePackInProgress = false
            state.updatePackError = error
            state.updatePackSucceeded = false
        },

        [addTagToPack.pending]: (state: any, { payload }: any) => {
            state.addTagToPackInProgress = true
            state.addTagToPackError = null
        },
        [addTagToPack.fulfilled]: (state: any, { payload }: any) => {
            packsAdapter.upsertOne(state, payload || {})
            state.addTagToPackInProgress = false
            state.addTagToPackError = null
            state.addTagToPackSucceeded = true
        },
        [addTagToPack.rejected]: (state: any, { payload: error }: any) => {
            state.addTagToPackInProgress = false
            state.addTagToPackError = error
            state.addTagToPackSucceeded = false
        },

        [createPackForTag.pending]: (state: any, { payload }: any) => {
            state.createPackForTagInProgress = true
            state.createPackForTagError = null
        },
        [createPackForTag.fulfilled]: (state: any, { payload }: any) => {
            state.createPackForTagInProgress = false
            state.createPackForTagError = null
            state.createPackForTagSucceeded = true
        },
        [createPackForTag.rejected]: (state: any, { payload: error }: any) => {
            state.createPackForTagInProgress = false
            state.createPackForTagError = error
            state.createPackForTagSucceeded = false
        },
        
        [removeTagFromPack.pending]: (state: any, { payload }: any) => {
            state.removeTagFromPackInProgress = true
            state.removeTagFromPackError = null
        },
        [removeTagFromPack.fulfilled]: (state: any, { payload }: any) => {
            packsAdapter.upsertOne(state, payload || {})
            state.removeTagFromPackInProgress = false
            state.removeTagFromPackError = null
            state.removeTagFromPackSucceeded = true
        },
        [removeTagFromPack.rejected]: (state: any, { payload: error }: any) => {
            state.removeTagFromPackInProgress = false
            state.removeTagFromPackError = error
            state.removeTagFromPackSucceeded = false
        },
    },
})

const {
    selectAll: selectAllPacks,
    selectById: selectPackById,
    selectIds: selectPackIds,
} = packsAdapter.getSelectors((state: any) => state[name])

const pack = (state) => state[name]

const fetchPackInProgress = createDraftSafeSelector(
    pack,
    (pack) => pack?.fetchPackInProgress,
)

// GENERAL
const packsInitialState = {
    [name]: initialState,
}

const packsReducer = {
    [name]: packsSlice.reducer,
}

const takes = [
    ...createPack.takes,
    ...fetchPack.takes,
    ...fetchAllPacks.takes,
    ...updatePack.takes,
    ...addTagToPack.takes,
    ...createPackForTag.takes,
    ...removeTagFromPack.takes,
]

export default packsSlice.reducer

export {
    packsInitialState as initialState,
    packsReducer as reducer,
    takes,

    selectAllPacks,
    selectPackById,
    selectPackIds,

    createPack,
    fetchPack,
    fetchAllPacks,
    updatePack,
    addTagToPack,
    createPackForTag,
    removeTagFromPack,

    fetchPackInProgress,
}
