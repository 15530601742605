import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import SettingsModule from 'modules/settings'
import AccountModule from 'modules/account'
import ModalModule from 'modules/modal'

function* saveBillingInformation({ types, payload }) {
    const publisherid = yield select(AccountModule.selectors.getPublisherId)

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: endpoints.BASE_URL,
                url: endpoints.BILLING_SOURCE,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...payload
                }
            }
        })

        yield put({
            type: types.SAVING_BILLING_INFORMATION_SUCCESS,
            payload: {
                ...data
            }
        })

        yield put(
            ModalModule.creators.closeModal({
                key: 'billingInformation'
            })
        )

        yield put(
            AppModule.creators.addNotification({
                message: 'Billing information successfully updated',
                type: 'success'
            })
        )

        yield put(SettingsModule.creators.getBillingInformation())
    } catch (error) {
        // we won't show to users what error they got
        const errorMessage = 'We were unable to add this payment method. Please try a different debit or credit card.' //error?.response?.data?.message
        // yield put(
        //     AppModule.creators.addNotification({
        //         message: 'Something went wrong!',
        //         type: 'danger'
        //     })
        // )
        yield put({
            type: types.SAVING_BILLING_INFORMATION_ERROR,
            error: errorMessage
        })
    }
}

export default saveBillingInformation
