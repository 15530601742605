import { call } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, getPublisherId } from '../dependencies'

const fetchSegments = createAsyncSaga(
    `${name}/fetchSegments`,
    function* ({
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const publisherId = yield call(getPublisherId)

            const response = yield request({
                method: 'get',
                url: endpoints.SEGMENTS,
                headers: {
                    publisherid: publisherId,
                },
            })

  
            yield fulfilled(response.payload)
        } catch (error) {
            yield rejected({
                error: error.message,
            })
        }
    }
)

export default fetchSegments
