const actions = {}

actions.types = [
    'CANCEL_PLAN',
    'CANCEL_PLAN_SUCCESS',
    'CANCEL_PLAN_ERROR',
    'SEND_EMAIL_RECEIPT',
    'SEND_EMAIL_RECEIPT_SUCCESS',
    'SEND_EMAIL_RECEIPT_ERROR',
    'DELETE_CONTACTS',
    'DELETE_CONTACTS_SUCCESS',
    'DELETE_CONTACTS_ERROR',
    'FETCH_SELECTED_USERS', // as opposed to SET_SELECTED_USERS, this goes and grabs the latest info from the API
    'FETCH_SELECTED_USERS_SUCCESS',
    'FETCH_SELECTED_USERS_ERROR',
    'SET_SELECTED_USERS', // goes through existing list of /users and determines info based on potentially stale data
    'FETCH_SELECTED_USER_ACTIVITY',
    'FETCH_SELECTED_USER_ACTIVITY_SUCCESS',
    'FETCH_SELECTED_USER_ACTIVITY_ERROR',
    'REINDEX',
    'REINDEX_SUCCESS',
    'REINDEX_ERROR',
    'REINDEX_USER',
    'REINDEX_USER_SUCCESS',
    'REINDEX_USER_ERROR',
    'REQUEST_SINGLE_USER',
    'REQUEST_SINGLE_USER_SUCCESS',
    'REQUEST_SINGLE_USER_ERROR',
    'UPDATE_SINGLE_USER',
    'UPDATE_SINGLE_USER_SUCCESS',
    'UPDATE_SINGLE_USER_ERROR',
    'GET_USER_NEWSLETTERS',
    'GET_USER_NEWSLETTERS_SUCCESS',
    'GET_USER_NEWSLETTERS_ERROR',
    'UPDATE_USER_NEWSLETTERS',
    'UPDATE_USER_NEWSLETTERS_SUCCESS',
    'UPDATE_USER_NEWSLETTERS_ERROR',
    'GET_PAGINATED_USERS',
    'GET_PAGINATED_USERS_SUCCESS',
    'GET_PAGINATED_USERS_ERROR',
    'GRANT_FREE_ACCESS',
    'GRANT_FREE_ACCESS_SUCCESS',
    'GRANT_FREE_ACCESS_ERROR',
    'MODIFY_FREE_ACCESS',
    'MODIFY_FREE_ACCESS_SUCCESS',
    'MODIFY_FREE_ACCESS_ERROR',
    'REVERSE_CANCELLATION',
    'REVERSE_CANCELLATION_SUCCESS',
    'REVERSE_CANCELLATION_ERROR',
    'RESET_SELECTED_USERS',
    'LOGIN_AS_USER',
    'LOGIN_AS_USER_SUCCESS',
    'LOGIN_AS_USER_ERROR',
    'LOG_OUT_USER',
    'LOG_OUT_USER_SUCCESS',
    'LOG_OUT_USER_ERROR',
    'EXPORT_CSV',
    'EXPORT_CSV_SUCCESS',
    'EXPORT_CSV_ERROR',
    'UPDATE_EXPORT_PROGRESS',
    'UPDATE_EXPORT_PROGRESS_SUCCESS',
    'UPDATE_EXPORT_PROGRESS_ERROR',
    'RESET_EXPORT',
    'SEND_INDIVIDUAL_MAGIC_LINK',
    'SEND__INDIVIDUAL_MAGIC_LINK_SUCCESS',
    'SEND_INDIVIDUAL_MAGIC_LINK_ERROR',
    'SEND_MAGIC_LINKS',
    'SEND_MAGIC_LINKS_SUCCESS',
    'SEND_MAGIC_LINKS_ERROR',
    'ISSUE_REFUND',
    'ISSUE_REFUND_SUCCESS',
    'ISSUE_REFUND_ERROR',
    'SELECT_ACTIVITY',

    'START_USER_IMPORT',
    'START_USER_IMPORT_SUCCESS',
    'START_USER_IMPORT_ERROR',

    'IMPORT_USERS',
    'IMPORT_USERS_SUCCESS',
    'USER_IMPORT_CANCELLED',
    'IMPORT_USERS_ERROR',

    'NOTIFY_USER_IMPORT',
    'NOTIFY_USER_IMPORT_SUCCESS',
    'NOTIFY_USER_IMPORT_ERROR',

    'GET_NOTIFIABLE_IMPORTED_USER_COUNT',
    'GET_NOTIFIABLE_IMPORTED_USER_COUNT_SUCCESS',
    'GET_NOTIFIABLE_IMPORTED_USER_COUNT_ERROR',

    'CHECK_USER_IMPORT_STATUS',
    'CHECK_USER_IMPORT_STATUS_SUCCESS',
    'CHECK_USER_IMPORT_STATUS_ERROR',

    'REINDEX_SYNC_STATUS',
    'REINDEX_SYNC_STATUS_SUCCESS',
    'REINDEX_SYNC_STATUS_ERROR',

    'IMPORT_MODAL_OPEN',
    'IMPORT_MODAL_CLOSE',

    'GET_USERS_META',
    'GET_USERS_META_SUCCESS',
    'GET_USERS_META_ERROR',
]

actions.creators = ({ types }) => ({
    cancelPlan: (payload, callback) => ({ type: types.CANCEL_PLAN, payload, callback }),
    changeEmailReceipt: (payload, callback) => ({ type: types.SEND_EMAIL_RECEIPT, payload, callback }),
    deleteContacts: (payload, callback) => ({ type: types.DELETE_CONTACTS, payload, callback }),
    requestSingleUser: (payload) => ({ type: types.REQUEST_SINGLE_USER, payload }),
    updateUser: (payload) => ({ type: types.UPDATE_SINGLE_USER, payload }),
    getUserNewsletters: (payload) => ({ type: types.GET_USER_NEWSLETTERS, payload }),
    updateUserNewsletters: (payload) => ({ type: types.UPDATE_USER_NEWSLETTERS, payload }),
    getPaginatedUsers: (payload) => ({ type: types.GET_PAGINATED_USERS, payload }),
    setSelectedUsers: (payload) => ({ type: types.SET_SELECTED_USERS, payload }),
    fetchSelectedUserActivity: (payload) => ({ type: types.FETCH_SELECTED_USER_ACTIVITY, payload }),
    fetchSelectedUsers: (payload) => ({ type: types.FETCH_SELECTED_USERS, payload }),
    reindex: () => ({ type: types.REINDEX }),
    reindexUser: (payload) => ({ type: types.REINDEX_USER, payload }),
    grantFreeAccess: (payload, callback) => ({ type: types.GRANT_FREE_ACCESS, payload, callback }),
    modifyFreeAccess: (payload, callback) => ({ type: types.MODIFY_FREE_ACCESS, payload, callback }),
    reverseCancellation: (payload, callback) => ({ type: types.REVERSE_CANCELLATION, payload, callback }),
    loginAsUser: (payload) => ({ type: types.LOGIN_AS_USER, payload }),
    logoutUser: (payload) => ({ type: types.LOG_OUT_USER, payload }),
    exportCSV: (payload) => ({ type: types.EXPORT_CSV, payload }),
    updateExportProgress: () => ({ type: types.UPDATE_EXPORT_PROGRESS }),
    resetExport: () => ({ type: types.RESET_EXPORT }),
    resetSelectedUsers: () => ({ type: types.RESET_SELECTED_USERS }),
    issueRefund: (payload, callback) => ({ type: types.ISSUE_REFUND, payload, callback }),
    selectActivity: (payload) => ({ type: types.SELECT_ACTIVITY, payload }),
    getUsersMeta: () => ({
        type: types.GET_USERS_META,
    }),
    importUsers: (payload) => ({ type: types.IMPORT_USERS, payload }),
    notifyUserImport: (payload) => ({ type: types.NOTIFY_USER_IMPORT, payload }),
    getNotifiableImportedUserCount: (payload) => ({ type: types.GET_NOTIFIABLE_IMPORTED_USER_COUNT, payload }),
    startUserImport: (payload) => ({ type: types.START_USER_IMPORT, payload }),
    cancelImport: (payload) => ({ type: types.USER_IMPORT_CANCELLED, payload }),
    userImportError: (error) => ({ type: types.IMPORT_USERS_ERROR, error }),
    importModalOpen: () => ({ type: types.IMPORT_MODAL_OPEN }),
    importModalClose: () => ({ type: types.IMPORT_MODAL_CLOSE }),
    checkUserImportStatus: (payload) => ({ type: types.CHECK_USER_IMPORT_STATUS, payload }),
})

export default actions
