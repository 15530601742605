import { put, putResolve, select, call } from 'redux-saga/effects'
import { FORM_ERROR } from 'final-form'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* updateLoginOptions({ payload, types, callback }) {
    const {
        signup_methods: login_options,
        phone_signup_method,
        default_authentication_method,
    } = payload

    const authentication_methods = Object.keys(login_options).reduce(
        (acc, k) => [
            ...acc,
            ...(login_options[k] ? (k !== 'sms' ? [k] : []) : []),
        ],
        []
    )

    if (phone_signup_method) {
        authentication_methods.push('sms')
    }

    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: endpoints.LOGIN_OPTIONS,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                timeout: 30000,
                data: {
                    authentication_methods,
                    default_authentication_method,
                },
            },
        })

        yield put({
            type: types.UPDATE_LOGIN_OPTIONS_SUCCESS,
            payload: {
                ...data,
            },
        })

        yield put(AccountModule.creators.getAccountInfo())
        yield put(
            AppModule.creators.addNotification({
                message: 'Signup Options successfully updated',
                type: 'success',
            })
        )
    } catch (error) {
        if (
            error.response &&
            error.response.data &&
            error.response.data.message
        ) {
            const submissionError = {
                [FORM_ERROR]: error.response.data.message,
            }
            if (error.response.data.errors) {
                const fieldErrors = Object.assign(
                    submissionError,
                    ...error.response.data.errors
                )
                yield call(callback, fieldErrors)
            } else {
                yield call(callback, submissionError)
            }
        }
        yield put(
            AppModule.creators.addNotification({
                message: 'An error occurred while saving',
                type: 'danger',
            })
        )
        yield put({
            type: types.UPDATE_LOGIN_OPTIONS_SUCCESS,
            error,
        })
    }
}

export default updateLoginOptions
