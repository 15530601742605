import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getESPLists({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.INTEGRATIONS_ESP + '/lists',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
            }
        })

        const options = data?.map(list => {
            return {
                label: list.name,
                value: list.id.toString(),
            }
        })

        yield put({
            type: types.GET_ESP_LISTS_SUCCESS,
            payload: {
                lists: options
            }
        })
    } catch (e) {
        yield put({ type: types.GET_ESP_LISTS_ERROR, error:  e?.response?.data?.message })
    }
}

export default getESPLists
