import logger from 'lib/logger'

export const domainTypes = {
    PRIMARY: 'primary',
    STAGING: 'staging',
    DEVELOPMENT: 'development',
}

export const HYPE_PAGE_PREFIX = process.env.HYPE_PAGE_PREFIX || ''

export const getLandingPageBaseUrl = () => {
    return process.env.HYPE_PAGES_URL
}

export const getLandingPageHost = () => {
    try {
        const url = new URL(process.env.HYPE_PAGES_URL)
        return url.host
    } catch (e) {
        logger.error('Error getting landing page host. Make sure to set a valid HYPE_PAGES_URL env var.', {}, e)
        return 'hype.co'
    }
}

export const getCheckoutPageBaseUrl = () => {
    return process.env.CHECKOUT_URL
}

export const getCheckoutPageHost = () => {
    try {
        const url = new URL(process.env.CHECKOUT_URL)
        return url.host
    } catch (e) {
        logger.error('Error getting checkout host. Make sure to set a valid CHECKOUT_URL env var.', {}, e)
        return 'pay.hype.co'
    }
}
