import Router, { useRouter } from "next/router"
import { useSelector } from "react-redux"
import ProductsModule from "modules/products"

const useRelatedProducts = ({ id = null } = {}) => {
    let { query: {
        id: thisProductId,
        type: thisProductTypeQuery,
    } } = useRouter()
    thisProductId = id || thisProductId 

    const thisProduct = useSelector((state) => ProductsModule.selectors.getProductById(state, thisProductId))
    const thisProductType = thisProduct?.type || thisProductTypeQuery

    const relatedSubscriptions = useSelector(ProductsModule.selectors.getSubscriptions)
    const relatedPasses = useSelector(ProductsModule.selectors.getPasses)
    const relatedDonationBoxes = useSelector(ProductsModule.selectors.getDonationBoxes)

    let relatedProducts: any = []

    if (thisProductType === 'subscription' || thisProductType === 'individual') {
        relatedProducts = relatedSubscriptions
    } else if (thisProductType === 'pass') {
        relatedProducts = relatedPasses
    } else if (thisProductType === 'donation') {
        relatedProducts = relatedDonationBoxes
    }

    relatedProducts = relatedProducts.filter(p => p.id !== thisProductId)


    return relatedProducts
}

const getRelatedProducts = (state = {}, id = null) => {
    let thisProductId = Router?.router.query.id
    let thisProductTypeQuery = Router?.router.query.type
    thisProductId = id || thisProductId 

    const thisProduct = ProductsModule.selectors.getProductById(state, thisProductId)
    const thisProductType = thisProduct?.type || thisProductTypeQuery

    const relatedSubscriptions = ProductsModule.selectors.getSubscriptions(state)
    const relatedPasses = ProductsModule.selectors.getPasses(state)
    const relatedDonationBoxes = ProductsModule.selectors.getDonationBoxes(state)

    let relatedProducts: any = []

    if (thisProductType === 'subscription' || thisProductType === 'individual') {
        relatedProducts = relatedSubscriptions
    } else if (thisProductType === 'pass') {
        relatedProducts = relatedPasses
    } else if (thisProductType === 'donation') {
        relatedProducts = relatedDonationBoxes
    }

    relatedProducts = relatedProducts.filter(p => p.id !== thisProductId)


    return relatedProducts
}

export default useRelatedProducts

export {
    getRelatedProducts,
}