import ThemeListing from './components/ThemeListing'

import {
    takes,
    themePickerInitialState,
    themePickerReducer,
    getThemeById,
    getDisplayableThemeById
} from './slice'

export default {
    takes,
    initialState: themePickerInitialState,
    reducer: themePickerReducer,
}

export {
    ThemeListing,
    getThemeById,
    getDisplayableThemeById,
}
