import Modal from '@piconetworks/pkg-modal'
import cx from 'classnames'
import styles from './Popup.module.scss'

const Popup = ({
    children = null,
    className = null,
    onAfterClose = () => {},
}) => {
    return (
        <Modal.Container
            centered={true}
            contentLabel="For screenreaders!"
            scrollable={false}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            setOpen={true}
            onAfterClose={onAfterClose}
            className={cx(styles.popup, className)}
        >
            {children}
        </Modal.Container>
    )
}

export default Popup
