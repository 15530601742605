import { useState } from 'react'

import Popup from './Popup'

const NullFunc = () => null

const usePopup = () => {
    const [isModalOpen, setModalOpen] = useState(false)

    return {
        isModalOpen,
        setModalOpen,
        Popup: isModalOpen
            ? Popup
            : NullFunc,
    }
}

export default usePopup
