import { createAsyncSaga, name, endpoints, logger } from '../dependencies'

const updateCard = createAsyncSaga(
    `${name}/updateCard`,
    function* ({
        payload: {
            publisherId = null,
            tagId = null,
            shippedAt = false,
            activate = null,
            deactivate = null,
            deliveryFailed = null,
            addressUpdated = null,
            newOwnerId = null,
            removeOwner = null,
            formId = null,
            engraving = null,
            sendDeliveryFailedEmail = null,
            tagName = null,
            customUrl = null,
            requestToken = null,
            editionId = null,
            orderNumber = null,
            packagedAt = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            if (!tagId) {
                throw new Error('tagId is required')
            }

            const response = yield request({
                method: 'put',
                url: endpoints.UPDATE_CARD(tagId),
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    ...(shippedAt && { shipped_at: Date.now() }),
                    ...(activate !== null && { deactivated_at: null }),
                    ...(deactivate !== null && { deactivated_at: Date.now() }),
                    ...(deliveryFailed && {
                        shipped_at: null,
                        delivery_failed_at: Date.now(),
                        address_updated_at: null,
                        ...(sendDeliveryFailedEmail && { send_delivery_failed_email: sendDeliveryFailedEmail }),
                    }),
                    ...(addressUpdated && {
                        delivery_failed_at: null,
                        address_updated_at: Date.now(),
                    }),
                    ...(newOwnerId && { publisher_id: newOwnerId }),
                    ...(removeOwner && { publisher_id: null }),
                    ...((formId || formId === '') && {
                        form_id: formId === '' ? null : formId,
                        custom_url: null,
                    }),
                    ...(engraving && { engraving: engraving }),
                    ...(editionId && { nfc_edition_id: editionId }),
                    ...((tagName || tagName === '') && { name: tagName === '' ? null : tagName }),
                    ...((customUrl || customUrl === '') && {
                        custom_url: customUrl === '' ? null : customUrl,
                        form_id: null,
                    }),
                    ...(packagedAt && orderNumber && {
                        packaged_at: Date.now(),
                        order_number: orderNumber,
                    }),
                },
            })

            const _editionId = response?.nfc_edition_id

            yield fulfilled({
                id: response?.id,
                shippedAtDate: response?.shipped_at,
                editionId: _editionId,
                tag: {
                    id: response?.id,
                    editionId: response?.nfc_edition_id,
                    packId: response?.nfc_pack_id,
                    formId: response?.form_id,
                    publisherId: response?.publisher_id,
                    stripeInvoiceId: response?.stripe_invoice_id,
                    tagName: response?.tag_name,
                    gift: response?.gift,
                    customUrl: response?.custom_url,
                    engraving: response?.engraving,
                    shippedDate: response?.shipped_at,
                    deactivatedAt: response?.deactivated_at,
                    packagedAt: response?.packaged_at,
                    orderDate: response?.created_at,
                    updatedAt: response?.updated_at,
                    edition: {
                        id: response?.edition?.id,
                        shopifySku: response?.edition?.shopify_sku,
                        name: response?.edition?.name,
                        type: response?.edition?.type,
                        color: response?.edition?.color,
                        engravable: response?.edition?.engravable,
                        trial: response?.edition?.trial,
                        sold: response?.edition?.sold,
                        salesChannel: response?.edition?.sales_channel,
                    },    
                },
            })
        } catch (error) {
            logger.error('updateCard error', {}, error)

            yield rejected(`(${requestToken}) ${error.message}`)
        }
    }
)

export default updateCard
