import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
import ModalModule from 'modules/modal'

function* updateUserNewsletters(action) {
    const { payload, duck } = action
    const { user_state_id } = payload

    const publisherid = yield select(AccountModule.selectors.getPublisherId)
    const store = yield select(duck.selectors.getUserNewsletters)

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: `${endpoints.USERS}/profile/${user_state_id}/newsletters`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    newsletters: store && store.newsletters.map((newsletter) => ({
                        ...newsletter,
                        subscribed: payload[newsletter.id]
                    }))
                }
            }
        })
        yield put({
            type: duck.types.UPDATE_USER_NEWSLETTERS_SUCCESS,
            data
        })
        yield put(
            ModalModule.creators.closeModal({
                key: 'updateNewsletter'
            })
        )
        yield put(
            AppModule.creators.addNotification({
                message: 'Member newsletters Saved Successfully',
                type: 'success'
            })
        )
    } catch (error) {
        yield put(
            ModalModule.creators.closeModal({
                key: 'updateNewsletter'
            })
        )
        yield put(
            AppModule.creators.addNotification({
                message: 'Failed to update member newsletters',
                type: 'danger'
            })
        )
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.UPDATE_USER_NEWSLETTERS_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.UPDATE_USER_NEWSLETTERS_ERROR,
                error
            })
        }
    }
}

export default updateUserNewsletters
