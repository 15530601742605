import {
    INDIVIDUAL_SUBSCRIPTION_TYPE,
    GROUP_SUBSCRIPTION_TYPE,
    PASS_TYPE,
    DONATION_SUGGESTIONS
} from 'utils/constants/productEdit'

import ProductsModule from 'modules/products'
import FormsModule from 'modules/forms'
import AccountModule from 'modules/account'
import FilesModule from 'modules/files'

import { format, currencies } from '@piconetworks/pkg-currency'
import { isNil } from 'lodash'
import { getRelatedProducts } from 'hooks/useRelatedProducts'

const getKeyOfTrueValue = (vals = {}) => {
    let result
    Object.keys(vals).some(key => {
        if (vals[key]) {
            result = key
            return true
        }
    })
    return result
}

export default class ProductsFormService {
    constructor() {
        this.product = null
        this.publisher = null
        this.properties = null
        this.type = null
        this.files = null
    }

    getFormattedPrice(price) {
        const currencyCode = this.getCurrency().code
        const formattedPrice = format({ amount: price, symbol: false, currencyCode })
        const [, zeroDecimals] = formattedPrice?.split('.')

        return price && formattedPrice
            ? (formattedPrice?.includes('.')
                ? `${formattedPrice}${zeroDecimals?.length < 2 ? '0' : ''}`
                : `${formattedPrice}.00`)
            : null
    }

    isSubscription() {
        return [INDIVIDUAL_SUBSCRIPTION_TYPE, GROUP_SUBSCRIPTION_TYPE].includes(this.type)
    }

    isGroup() {
        return [GROUP_SUBSCRIPTION_TYPE].includes(this.type)
    }

    getTitle() {
        return this.product?.title
    }

    getProductLabel() {
        return this.product?.product_label || ''
    }

    getPledgeMode() {
        return this.product?.pledge_mode
    }

    getDiscordAccess() {
        const integrations = this.product?.integrations
        return integrations && integrations?.find(e => e.type === 'discord' && e.active)
    }

    // Pricing
    getOptions() {
        const { options = [] } = this.product
        switch (this.type) {
            case DONATION_SUGGESTIONS:
                const donationOptions = options.reduce((prices, option) => {
                    const type = option.type === 'single-donation'
                        ? 'oneTime'
                        : option.interval === 'month'
                            ? 'monthly'
                            : 'yearly'
                    prices[type].active = true
                    prices[type].values.push({
                        price: this.getFormattedPrice(option.price),
                        id: option.id
                    })
                    if (option?.default_option) {
                        prices.defaultOption = option.id
                    }
                    return prices
                }, {
                    oneTime: {
                        values: [],
                        active: !options.length,
                    },
                    monthly: {
                        values: [],
                        active: false
                    },
                    yearly: {
                        values: [],
                        active: false
                    }
                })
                const sortedDonationOptions = (values) => values.sort((a, b) => a.price - b.price)
                donationOptions.oneTime.values = sortedDonationOptions(donationOptions.oneTime.values)
                donationOptions.monthly.values = sortedDonationOptions(donationOptions.monthly.values)
                donationOptions.yearly.values = sortedDonationOptions(donationOptions.yearly.values)
                return donationOptions
            case INDIVIDUAL_SUBSCRIPTION_TYPE:

                if (options.length === 0) {
                    return { defaultOptionId: 'month' }
                }
                
                return options.reduce((prices, option) => {
                    switch (option.interval) {
                        case 'month':
                            prices.monthly = true
                            prices.monthlyPrice = this.getFormattedPrice(option.price)
                            prices.monthlyId = option.id
                            if (option?.default_option) {
                                prices.defaultOptionId = 'month'
                            }
                            break
                        case 'year':
                            prices.annual = true
                            prices.annualPrice = this.getFormattedPrice(option.price)
                            prices.annualId = option.id
                            if (option?.default_option) {
                                prices.defaultOptionId = 'year'
                            }
                            break
                    }

                    return prices
                }, {
                    monthly: !options.length,
                    monthlyPrice: null,
                    monthlyId: null,
                    annual: false,
                    annualPrice: null,
                    annualId: null,
                    defaultOptionId: null
                })
            case GROUP_SUBSCRIPTION_TYPE:
                return options.reduce((prices, option) => {
                    prices.auto_renew = option.recurring
                    prices.pricingType = option.per_seat ? 'per_member' : 'flat_rate'
                    switch (option.interval) {
                        case 'month':
                            prices.period = option.interval
                            prices.monthPrice = this.getFormattedPrice(option.price)
                            prices.monthlyId = option.id
                            break
                        case 'year':
                            prices.period = option.interval
                            prices.yearPrice = this.getFormattedPrice(option.price)
                            prices.annualId = option.id
                            break
                    }
                    return prices
                }, {
                    period: 'month',
                    monthPrice: null,
                    yearPrice: null,
                    pricingType: 'flat_rate',
                    auto_renew: false,
                    monthlyId: null,
                    annualId: null,
                })
            case PASS_TYPE:
                const passPricingObj = options[0]
                return {
                    passDurationUnit: passPricingObj?.interval,
                    passDurationValue: passPricingObj?.interval_quantity,
                    passPrice: this.getFormattedPrice(passPricingObj?.price),
                    passId: passPricingObj?.id,
                }
        }
    }

    getMatchType() {
        const { condition_match_type } = this.product
        return {
            match_type: condition_match_type
        }
    }

    getConditions() {
        const { conditions = [] } = this.product
        const domains = conditions.filter((condition) => condition.type === 'domain').map((condition) => condition.value)
        const code = conditions.filter((condition) => condition.type === 'code').map((condition) => condition.value)
        const emails = conditions.filter((condition) => condition.type === 'email').map((condition) => condition.value)
        const size = conditions.filter((condition) => condition.type === 'size').map((condition) => condition.value)

        return {
            hasCode: !!code.length,
            hasEmail: !!emails.length,
            hasDomain: !!domains.length,
            domains,
            groupSize: size?.[0] ? 'limited' : 'unlimited',
            memberSize: size[0],
            code: code?.[0],
            emails
        }

    }

    getCurrency() {
        const { options = [] } = this.product
        const currencyCode = (options[0]?.currency || this.publisher?.default_currency || 'usd').toUpperCase()

        const currency = {
            name: currencies[currencyCode].name,
            flagImage: `https://development-cms-assets.s3.amazonaws.com/icons/flags/flags/flag_${currencies[currencyCode].flag_code}.png`,
            code: currencyCode,
            symbolNative: currencies[currencyCode].symbol_native

        }
        return currency
    }

    getNewsletters() {
        const { newsletter_ids = [] } = this.product
        return newsletter_ids.reduce((ids, newsletter_id) =>
            ({ ...ids, [newsletter_id]: true }), {}
        )
    }

    getFreeNewsletters() {
        const { free_newsletter_ids = [] } = this.product
        return free_newsletter_ids.reduce((ids, newsletter_id) =>
            ({ ...ids, [newsletter_id]: true }), {}
        )
    }

    getFiles() {
        const files = this.files || []

        return files.reduce((ids, file) =>
            ({ ...ids, [file.id]: !!file?.product_attached }), {}
        )
    }

    getFeatureList() {
        const { custom_text = {} } = this.product

        return ({
            list: custom_text?.json?.feature_list?.filter((item) => item?.label).map((item) => ({ label: item?.label, detail: item?.detail, hasDetail: !!item.detail })) || [],
            description: custom_text?.json?.description,
            file_name: custom_text?.file_name
        })
    }

    getAccess() {
        const { access } = this.product
        const hasLimitQuantityAndInterval = !isNil(access?.reset_interval && access?.limit_quantity)
        const hasConditions = access?.conditions?.length > 0
        /* Access */
        const unlockAll = access?.unlock_all === true
        const unlockSome = access?.unlock_all === false && (hasLimitQuantityAndInterval || hasConditions)
        const unlockNone = access?.unlock_all === false && !hasLimitQuantityAndInterval && !hasConditions

        const unlockTypes = {
            'none': unlockNone,
            'all': unlockAll,
            'conditional': unlockSome,
        }
        const cond_limitAccess = access?.limit_quantity && access?.reset_interval && unlockTypes.conditional
        return {
            unlock_all: getKeyOfTrueValue(unlockTypes) || 'none',
            cond_limitAccess,
            ...cond_limitAccess && { limit_quantity: access.limit_quantity, reset_interval: access.reset_interval },
            ...hasConditions && { cond_onlyUnlock: true, conditions: access.conditions }
        }
    }

    getTrial() {
        const { options = [] } = this.product

        let trial = {
            DurationUnit: 'day',
            DurationValue: 1,
            Price: null,
            type: 'no_trial'
        }

        const trialObj = options[0]

        if (trialObj) {
            const noTrial = !trialObj.trial_interval && !trialObj.trial_price && !trialObj.trial_quantity

            const trialTypes = {
                'no_trial': noTrial,
                'paid_trial': !noTrial && !!trialObj.trial_price,
                'free_trial': !noTrial && !trialObj.trial_price,
            }

            trial = {
                DurationUnit: trialObj.trial_interval,
                DurationValue: trialObj.trial_quantity,
                Price: this.getFormattedPrice(trialObj.trial_price),
                type: getKeyOfTrueValue(trialTypes),
            }
        }

        return trial
    }

    getFormFormattedFields() {
        const { fields = [] } = this.product
        return fields.map(field => {
            const connected_property = this.properties.find(property =>
                property.value === field.connected_property.id
            )
            return {
                id: field.id,
                connected_property,
                label: field.label,
                order: field.order,
                required: field.required,
                ...(field?.description && { description: field.description }),
            }
        })
    }

    getInitialFormValues(state, type, id) {
        this.product = ProductsModule.selectors.getProductById(state, id)
        this.publisher = AccountModule.selectors.getAccountInfo(state)
        this.properties = FormsModule.selectors.getAllPropertiesDropdown(state)
        this.files = FilesModule.selectors.getFiles(state)

        this.type = type
        const isNew = !this.product.id

        const savedRelatedProducts = ProductsModule.selectors.productSiblings(state)
        const relatedProductsArray = savedRelatedProducts?.map?.((product) => product?.id)
        const related_products = isNew || !relatedProductsArray ? [] : relatedProductsArray

        return {
            title: this.getTitle(),
            product_label: this.getProductLabel(),
            currency: this.getCurrency(),
            access: this.getAccess(),
            fields: this.getFormFormattedFields(),
            newsletter_ids: this.getNewsletters(),
            free_newsletter_ids: this.getFreeNewsletters(),

            file_ids: this.getFiles(),
            pitch: this.getFeatureList(),
            related_products,
            ...(this.isGroup ? {
                ...this.getConditions(),
                ...this.getMatchType()
            } : {}),
            ...this.getOptions(),
            ...(this.isSubscription() ? {
                pledge_mode: this.getPledgeMode(),
                trial: this.getTrial(),
            } : {}),
            ...(!isNew ? { billing_admin_email: null } : {}),
            discord_access: this.getDiscordAccess(),
        }
    }
}
