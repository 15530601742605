import { put, putResolve, select } from 'redux-saga/effects'
import ModalModule from 'modules/modal'
import AppModule from 'modules/app'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import UsersModule from 'modules/users'
function* deleteCustomPropertyValue(action) {
    const { payload, duck } = action
    try {
        const user = yield select(UsersModule.selectors.getSingleUser)
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const response = yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: endpoints.USERS + `/profile/${user.user_state_id}/property/${payload.id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })
        // we should probably make sure response has a data object being returned
        yield put({
            type: duck.types.DELETE_CUSTOM_PROPERTY_VALUE_SUCCESS,
        })

        yield put(
            ModalModule.creators.closeModal({
                key: 'deleteCustomValue'
            })
        )

        yield put(
            AppModule.creators.addNotification({
                message: 'Custom property value deleted successfully.',
                type: 'success'
            })
        )

        yield put(UsersModule.creators.getUser({ user_state_id: user.user_state_id }))

    } catch (error) {
        yield put(
            AppModule.creators.addNotification({
                message: 'Something went wrong.',
                type: 'danger'
            })
        )
        yield put({
            type: duck.types.DELETE_CUSTOM_PROPERTY_VALUE_ERROR,
        })
    }
}

export default deleteCustomPropertyValue
