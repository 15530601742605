import React, { useEffect, useState } from 'react'
import ScreenSizeContext from './context'
import debounce from 'lodash/debounce'

const ScreenSizeProvider = ({ children }) => {
    const resize = debounce(() => {
        const width = window.innerWidth

        setState({
            isXsUp: width >= 0,
            isSmUp: width >= 576,
            isMdUp: width >= 768,
            isLgUp: width >= 992,
            isXlUp: width >= 1200,
        })
    }, 500)

    const [{
        isXsUp,
        isSmUp,
        isMdUp,
        isLgUp,
        isXlUp
    }, setState] = useState({
        isXsUp: false,
        isSmUp: false,
        isMdUp: false,
        isLgUp: false,
        isXlUp: false,
    })

    useEffect(() => {
        resize()
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [])

    return (
        <ScreenSizeContext.Provider value={{
            isXsUp,
            isSmUp,
            isMdUp,
            isLgUp,
            isXlUp,
        }}>
            {children}
        </ScreenSizeContext.Provider>
    )
}

export default ScreenSizeProvider
