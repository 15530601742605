import produce from 'immer'
import assign from 'lodash/assign'

export const initialState = {
    notifications: [],
    status: 'INITIATED',
    preferences: {
        contacts: {
            filtered: {
                filters: []
            },
            pageSize: 25,
            taxonomy: null
        },
        integrations: {
            instagram: {
                step1: null,
                step2: null,
                step3: null
            }
        }
    },
    error: null
}

const reducer = (state, { type, payload, error }, { types, initialState }) =>
    produce(state, (draftState) => {
        switch (type) {
            case types.UPDATE_NOTIFICATION:
                draftState.notifications.push(payload)
                break

            case types.REMOVE_NOTIFICATION:
                const notifications = state.notifications.filter(
                    (notification) => notification.id !== payload.id
                )
                draftState.notifications = notifications
                break

            case types.CLEAR_NOTIFICATIONS:
                draftState.notifications = []
                break

            case types.UPDATE_PREFERENCES:
                assign(
                    draftState.preferences[payload.key],
                    payload.value
                )
                break

            case types.RESET_SOME:
                assign(
                    draftState,
                    initialState,
                    {
                        preferences: state.preferences
                    }
                )
                draftState.preferences.contacts.filtered = initialState.preferences.contacts.filtered

                break

            case types.RESET_ALL:
                draftState.preferences.contacts.filtered = initialState.preferences.contacts.filtered
                break

            default:
                return state
        }
    })

export default reducer
