import Form from '@piconetworks/pkg-form'
import { DateRange } from '@piconetworks/pkg-date-picker'

const SHORT_ANSWER = 'short_answer'
const PARAGRAPH = 'paragraph'
const BOOLEAN = 'boolean'
const NUMBER = 'number'
const DATE = 'date'
const URL = 'url'
const RADIO = 'radio'
const DROPDOWN = 'dropdown'
const CHECKBOX = 'checkbox'
const ADDRESS = 'address'

export const TYPES = {
    ADDRESS,
    SHORT_ANSWER,
    PARAGRAPH,
    BOOLEAN,
    NUMBER,
    DATE,
    URL,
    RADIO,
    DROPDOWN,
    CHECKBOX,
}

export const CUSTOM_FIELD_OPTIONS = [
    { value: TYPES.SHORT_ANSWER, label: 'Short Answer' },
    { value: TYPES.PARAGRAPH, label: 'Paragraph' },
    { value: TYPES.BOOLEAN, label: 'Boolean' },
    { value: TYPES.NUMBER, label: 'Number' },
    { value: TYPES.DATE, label: 'Date' },
    { value: TYPES.URL, label: 'URL' },
    { value: TYPES.RADIO, label: 'Radio' },
    { value: TYPES.DROPDOWN, label: 'Dropdown' },
    { value: TYPES.CHECKBOX, label: 'Checkbox' },
]

export const CUSTOM_FIELD_INPUT_TYPES = {
    [TYPES.SHORT_ANSWER]: Form.Input,
    [TYPES.PARAGRAPH]: Form.Input,
    [TYPES.BOOLEAN]: Form.Checkbox,
    [TYPES.NUMBER]: Form.Input,
    [TYPES.DATE]: DateRange,
    [TYPES.URL]: Form.Input,
    [TYPES.RADIO]: Form.Dropdown,
    [TYPES.DROPDOWN]: Form.Dropdown,
    [TYPES.CHECKBOX]: Form.Checkbox,
}

export const CUSTOM_PROPERTY_FILTER_TYPES = {
    [TYPES.SHORT_ANSWER]: 'text',
    [TYPES.PARAGRAPH]: 'text',
    [TYPES.BOOLEAN]: 'boolean',
    [TYPES.NUMBER]: 'number',
    [TYPES.DATE]: 'date',
    [TYPES.URL]: 'text',
    [TYPES.RADIO]: 'text',
    [TYPES.DROPDOWN]: 'dropdown',
    [TYPES.CHECKBOX]: 'checkbox'
}
