import { putResolve, put, select, call } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import Router from 'next/router'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* createFreeAccess({ types, payload, url, callback }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: `${endpoints.FREE_ACCESS}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    ...payload
                }
            }
        })

        yield put({
            type: types.CREATE_FREE_ACCESS_SUCCESS,
            payload: data.freeAccess
        })

        Router.push(url)

        yield put(
            AppModule.creators.addNotification({
                message: 'Access rule has been created.',
                type: 'success'
            })
        )
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: types.CREATE_FREE_ACCESS_ERROR,
                error: error.response.data
            })
            call(callback, error.response.data.message)
        } else {
            yield put({
                type: types.CREATE_FREE_ACCESS_ERROR,
                error
            })
        }

        AppModule.creators.addNotification({
            message: 'Access rule could not be created',
            type: 'danger'
        })
    }
}

export default createFreeAccess
