import React, { useState, useEffect, useContext, useRef } from 'react'
import { createPortal } from 'react-dom'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { Boutton } from '@piconetworks/ui'
import useVisible from './useVisible'
import { uniqueId } from 'lodash'
import { PaneRefContext } from 'containers/LoggedInScaffolding'
import ScreenSizeContext from 'utils/contexts/ScreenSize/context'

import styles from './Overflow.module.scss'

function Overflow({
    children,
    style,
    header,
    icon,
    size,
    type,
    variant,
    className,
    menuOpenPosition = 'left',
    menuOpenPositionY = 'bottom',
    inTable,
    menuClass = null
}) {
    // click handlers are lost without a unique id on portal divs
    const [id] = useState(uniqueId())
    const [isOpen, setIsOpen] = useState(false)
    const { ref, isVisible, setIsVisible } = useVisible(false)
    const [[x, y], setPosition] = useState([0, 0])
    const [containerEl, setContainerEl] = useState(null)

    const { isSmUp } = useContext(ScreenSizeContext)
    const paneRef = useContext(PaneRefContext)
    const buttonRef = useRef()

    const overflowElement = document?.getElementById('overflow')

    useEffect(() => {
        if (inTable) {
            const _containerEl = document.createElement('div')
            _containerEl.id = id
            setContainerEl(_containerEl)
        }
    }, [])

    useEffect(() => {
        if (containerEl) {
            overflowElement?.appendChild(containerEl)
            return () => overflowElement.removeChild(containerEl)
        }
    }, [containerEl])

    useEffect(
        () => {
            if (!isVisible) {
                setIsOpen(false)
            }
        },
        [isVisible]
    )

    useEffect(
        () => {
            setIsVisible(isOpen)
            if (paneRef?.current) {
                if (isOpen) {
                    paneRef.current.addEventListener('scroll', handleScroll)
                } else {
                    paneRef.current.removeEventListener('scroll', handleScroll)
                }
            }
        },
        [isOpen]
    )

    const handleScroll = () => {
        if (isOpen) getAndSetPosition()
    }

    const getAndSetPosition = () => {
        const rect = !!buttonRef?.current && buttonRef.current.getBoundingClientRect()
        setPosition([window.innerWidth - rect.right, rect.y + window.scrollY + rect.height + 4])
    }

    const childrenWithProps = React.Children.map(children, (child) => {
        const props = {
            onClick: () => {
                const childClick = child.props.onClick
                if (type !== 'form') {
                    setIsOpen(false)
                }
                if (typeof childClick === 'function') childClick()
            },
        }
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props)
        }
        return child
    })

    const overflowMenu = () =>
        <div
            style={containerEl ? {
                right: !isSmUp ? x : x - 19,
                top: y,
            } : {}}
            className={cx(menuClass, styles.menu,
                [styles[menuOpenPosition]],
                [styles[menuOpenPositionY]],
                { [styles.open]: isOpen })}
        >
            {childrenWithProps}
        </div>

    return (
        <div ref={ref} style={style} className={cx(styles.wrapper, className)}>
            <Boutton
                ref={buttonRef}
                onClick={(e) => {
                    e.stopPropagation()
                    getAndSetPosition()
                    setIsOpen(!isOpen)
                }}
                className={cx(styles.button, variant && styles[variant], { [styles.icon]: !header && icon })}
                {...(variant && { variant: variant })}
                {...(size && { size: size })}
            >
                <span className={cx({ [styles.headerMargin]: !icon })}>{header}</span>
                <span
                    className={cx(styles.arrow, {
                        [styles.open]: isOpen
                    })}
                >
                    {icon || <span className="icon-chevron-down" />}
                </span>
            </Boutton>
            {containerEl ?
                createPortal(overflowMenu(), containerEl) : overflowMenu()
            }
        </div >
    )
}

function Option({ 
    children = null, 
    onClick = (e) => {},
    icon = null,
    style = {}, 
    className = '', 
    type = '', 
    iconClassName = '', 
    ...props
}) {
    return (
        <div
            style={style}
            className={cx(styles.option, { [styles.form]: type === 'form' }, className)}

            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onClick(e)
            }}
            role='menuitem'
            tabIndex='-1'
            {...props}
        >
            {icon && <span className={cx(styles.optionIcon, iconClassName)}>{icon}</span>}
            {children}
        </div>
    )
}

function Hr() {
    return <div className={styles.hr} />
}

Overflow.Option = Option
Overflow.Hr = Hr

Overflow.propTypes = {
    children: PropTypes.any,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    icon: PropTypes.node,
    inTable: PropTypes.bool,
    menuOpenPosition: PropTypes.oneOf(['right', 'left']),
    menuOpenPositionY: PropTypes.oneOf(['top', 'bottom']),
    size: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string,
    variant: PropTypes.string
}

Option.propTypes = {
    children: PropTypes.any,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.element,
    iconClassName: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    type: PropTypes.string
}

export default Overflow
