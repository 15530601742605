import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import qs from 'qs'

// Static parameter for pagination for right now.
const size = 25

function* getBlasts({ payload: { payload }, types, selectors, ...args }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const pagination = yield select(selectors.getBlastsPagination)

        const query = {
            page: pagination.page != null ? pagination.page + 1 : 0,
            size: size,
            draft_filter: payload.filter === "sent" ? 0 : 1
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.BROADCASTS}?${qs.stringify(query)}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                }
            }
        })

        yield put({
            type: types.GET_BLASTS_SUCCESS,
            payload: {...data, filter: payload.filter },
        })
    
    } catch (e) {
        yield put({
            type: types.GET_BLASTS_ERROR,
            error: e
        })
    }
}

export default getBlasts
