import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'
import logger from 'lib/logger'

import { useCards, fetchCards, fetchCardById, setActiveEditionId, setActiveCardId, fetchTagsByCreator } from '../../entities/cards'
import { useEditions, fetchEditions, upsertEdition } from '../../entities/editions'

const name = 'nfcEditor'

export {
    name,

    endpoints,
    createAsyncSaga,
    logger,

    useCards,
    useEditions,

    fetchCards,
    fetchEditions,
    fetchCardById,
    fetchTagsByCreator,

    setActiveEditionId,
    setActiveCardId,

    upsertEdition
}
