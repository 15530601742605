import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => ([
    takeEvery(types.VIEW_PAGE, sagas.viewPage),
    takeEvery(types.COPY_LANDING_PAGE, sagas.copyLandingPage),
    takeEvery(types.SEND_EVENT, sagas.sendEvent),
    takeEvery(types.IDENTIFY_USER, sagas.identifyUser),
])

export default takes
