import { call, put, putResolve, select, take } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* switchAccount({ creators, sagas, newPublisherId, types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                baseURL: endpoints.BASE_URL,
                url: endpoints.OAUTH.PUBLISHER_SWITCH,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    publisher_id: publisherid,
                    new_publisher_id: newPublisherId
                }
            }
        })

        if (data) {

            // write new tokens to store
            yield putResolve(creators.tokenLogin({
                accessToken: data.token,
                refreshToken: data.refresh_token
            }))

            // initiate the preLogout flow
            yield put(creators.preLogout({ payload: { publisher_id: newPublisherId } }))

            // listen prelogout success action
            yield take([
                types.PRE_LOGOUT_SUCCESS
            ])

            yield put(creators.postLogin())

            yield take([
                types.POST_LOGIN_SUCCESS
            ])

            yield put({
                type: types.SWITCH_ACCOUNT_SUCCESS,
                payload: {
                    ...data
                }
            })
        }
    } catch (error) {

        console.log(error)
        yield put({
            type: types.SWITCH_ACCOUNT_ERROR,
            error
        })
    }
}

export default switchAccount
