import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import usePublisherId from 'hooks/usePublisherId'
import SettingsModule from 'modules/settings'
import { getPhoneNumberFromVerify, getRequestError, getRequestInProgress, getSmsEnabledByVerify, requestPhoneNumber } from './slice'
import { unwrapResult } from '@reduxjs/toolkit'

const useRegisterPhoneNumber = (fetch = false) => {
    const dispatch = useDispatch()
    const business = useSelector(SettingsModule.selectors.getMyBusiness)
    const profile = useSelector(SettingsModule.selectors.getMyProfile)
    const phoneNumber = useSelector(getPhoneNumberFromVerify)
    const requestError = useSelector(getRequestError)
    const requestInProgress = useSelector(getRequestInProgress)
    const smsEnabled = useSelector(getSmsEnabledByVerify)
    console.log('phoneNumber', phoneNumber)

    const publisherId = usePublisherId()

    const getBusiness = useCallback(() => {
        return dispatch(SettingsModule.creators.getMyBusiness())
    }, [publisherId])

    const updateBusiness = useCallback((values) => {
        return dispatch(SettingsModule.creators.saveMyBusiness(values, null, false))
    }, [dispatch])

    const getProfile = useCallback(() => {
        return dispatch(SettingsModule.creators.getMyProfile())
    }, [dispatch])

    const updateProfile = useCallback((values) => {
        return dispatch(SettingsModule.creators.saveProfile(values, null, false))
    }, [dispatch])

    const getPhoneNumber = useCallback(async (values) => {
        try {
            const resultAction = await dispatch(requestPhoneNumber(values))
            const promiseResult = unwrapResult(resultAction)
            return promiseResult
        } catch (error) {
            throw error
        }
    }, [dispatch, requestPhoneNumber, unwrapResult])

    useEffect(() => {
        if (!fetch) {
            return
        }

        dispatch(getBusiness())
        dispatch(getProfile())
    }, [
        fetch,
        dispatch,
        publisherId,
    ])

    return {
        business,
        updateBusiness,
        profile,
        updateProfile,
        getPhoneNumber,
        phoneNumber,
        requestError,
        requestInProgress,
        smsEnabled
    }
}

export default useRegisterPhoneNumber
