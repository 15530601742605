import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'
import logger from 'lib/logger'
import { v4 as uuidv4 } from 'uuid'
import faker from 'faker'
import account from 'modules/account'
import SettingsModule from 'modules/settings'
import { fetchCardById } from '../../entities/cards'

const getPublisherId = account.selectors.getPublisherId

const name = 'packs'

export {
    name,

    endpoints,
    createAsyncSaga,

    logger,
    uuidv4,
    faker,
    getPublisherId,
    SettingsModule,
    fetchCardById,
}
