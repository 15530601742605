import vcf from 'vcf'

import { useDispatch, useSelector } from 'react-redux'
import {
    getVcard,
    fetchVcard,
    getVcardCacheString,
    getFetchVcardError,
    getFetchVcardSuccess,
    getUpdateVcardSuccess,
    getUpdateVcardError,
    getUpdateVcardInProgress,
} from '../slice'
import { useCallback } from 'react'

const getParsedVcard = (text) => {
    try {
        const vcard = new vcf().parse(text)
        return vcard
    }
    catch (error) {
        return null
    }
}

const useVcard = () => {
    const text = useSelector(getVcard)
    const _vcard = getParsedVcard(text)
    const _getVcardCacheString = useSelector(getVcardCacheString)
    const _getFetchVcardError = useSelector(getFetchVcardError)
    const _getFetchVcardSuccess = useSelector(getFetchVcardSuccess)
    const _getUpdateVcardSuccess = useSelector(getUpdateVcardSuccess)
    const _getUpdateVcardError = useSelector(getUpdateVcardError)
    const _getUpdateVcardInProgress = useSelector(getUpdateVcardInProgress)

    const dispatch = useDispatch()
    const _fetchVcard = useCallback((vcardUrl) => {
        dispatch(fetchVcard({
            vcardUrl,
        }))
    }, [dispatch])

    return {
        _vcard,
        _fetchVcard,
        _getVcardCacheString,
        _getFetchVcardError,
        _getFetchVcardSuccess,
        _getUpdateVcardSuccess,
        _getUpdateVcardError,
        _getUpdateVcardInProgress,
    }
}

export default useVcard
