import getFiles from './getFiles'
import createFile from './createFile'
import editFile from './editFile'
import deleteFile from './deleteFile'
import getFileStackKeys from './getFileStackKeys'

const sagas = (duck) => ({
    *getFiles(payload) {
        yield getFiles({ ...payload, duck })
    },
    *createFile(payload) {
        yield createFile({ ...payload, duck })
    },
    *editFile(payload) {
        yield editFile({ ...payload, duck })
    },
    *deleteFile(payload) {
        yield deleteFile({ ...payload, duck })
    },
    *getFileStackKeys(payload) {
        yield getFileStackKeys({ ...payload, duck })
    },
})

export default sagas
