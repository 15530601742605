/** @namespace Customizations.Index */

// this was an early attempt at a feature slice that could handle updating the serialized values
// for both the theme and the form. It was abandoned for the sake of time
import {
    customizationsInitialState,
    customizationsReducer,
    setThemeSerializer,
    setFormSerializer,
    resetThemeSerializer,
    resetFormSerializer,
    getThemeSerializer,
    getFormSerializer
} from './slice'

import useStyles from './hooks/useStyles'
import useThemePreviewStyles from './hooks/useThemePreviewStyles'

export default {
    initialState: customizationsInitialState,
    reducer: customizationsReducer,
}

export {
    setThemeSerializer,
    setFormSerializer,
    resetThemeSerializer,
    resetFormSerializer,
    getThemeSerializer,
    getFormSerializer,
    useStyles,
    useThemePreviewStyles
}
