import { put, putResolve, select, call } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
import ModalModule from 'modules/modal'
function* updateGroup({ types, payload: { values, callback } }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)


        // To-Do need integration
        yield putResolve({
            type: REQUEST,
            config: {
                method: 'GET',
                url: endpoints.GROUPS
                ,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                // data: {
                //     ...payload,
                // }
            }
        })

        yield put({
            type: types.UPDATE_GROUP_SUCCESS,
            payload: values
        })

        yield put(
            AppModule.creators.addNotification({
                message: 'Group successfully updated',
                type: 'success'
            })
        )

        yield put(
            ModalModule.creators.closeModal({
                key: 'groupModal'
            })
        )
        yield call(callback.complete)
        yield call(callback.closeAction)

    } catch (error) {
        yield put({ type: types.UPDATE_GROUP_ERROR, error })
    }
}

export default updateGroup
