import produce from 'immer'

export const initialState = {
    status: 'INITIATED',
    postLoginStatus: 'INITIATED',
    isAuthed: false,
    error: null,
    accountInfo: {},
    invitedStaffInformation: null,
    resetSent: false
}

const reducer = (state, { type, payload, error }, { initialState, types, statuses }) => {
    return produce(state, draftState => {
        switch (type) {
            case types.LOGIN:
            case types.SOCIAL_LOGIN:
            case types.LOGIN_FROM_ONBOARDING:
                draftState.isAuthed = initialState.isAuthed
                draftState.error = initialState.error
                draftState.status = statuses.LOADING
                break

            case types.SOCIAL_LOGIN_ERROR:
                draftState.isAuthed = false
                draftState.status = statuses.ERRORED
                draftState.error = error?.error?.output?.payload?.message
                break

            case types.LOGIN_ERROR:
            case types.LOGIN_FROM_ONBOARDING_ERROR:
                draftState.isAuthed = false
                draftState.status = statuses.ERRORED
                draftState.error = error.message
                break

            case types.LOGOUT:
                draftState.status = statuses.LOADING
                draftState.error = initialState.error
                break

            case types.LOGOUT_SUCCESS:
                draftState.status = statuses.READY
                draftState.isAuthed = false
                draftState.accountInfo = initialState.accountInfo
                draftState.error = initialState.error
                break

            case types.LOGOUT_ERROR:
                draftState.status = statuses.ERRORED
                draftState.isAuthed = false
                draftState.accountInfo = initialState.accountInfo
                draftState.error = initialState.error
                break

            case types.SWITCH_ACCOUNT:
                draftState.error = initialState.error
                draftState.status = statuses.LOADING
                break

            case types.SWITCH_ACCOUNT_SUCCESS:
                draftState.isAuthed = true
                draftState.status = statuses.READY
                break

            case types.SWITCH_ACCOUNT_ERROR:
                draftState.status = statuses.ERRORED
                break

            case types.TOKEN_LOGIN:
                draftState.error = initialState.error
                draftState.isAuthed = false
                draftState.status = statuses.LOADING
                break

            case types.SOCIAL_LOGIN_SUCCESS:
            case types.LOGIN_SUCCESS:
                draftState.accountInfo = payload.account_info
                draftState.status = statuses.READY
                break

            case types.USE_REFRESH_TOKEN_SUCCESS:
                draftState.isAuthed = true
                draftState.status = statuses.READY
                break

            case types.TOKEN_LOGIN_SUCCESS:
                draftState.isAuthed = true
                draftState.error = initialState.error
                draftState.status = statuses.READY
                break

            case types.TOKEN_LOGIN_ERROR:
                draftState.isAuthed = false
                draftState.status = statuses.ERRORED
                break

            case types.RESET_FORM:
                draftState.status = initialState.status
                draftState.resetSent = initialState.resetSent
                draftState.error = initialState.error
                break

            case types.RESET_PASSWORD:
                draftState.status = statuses.LOADING
                draftState.resetSent = initialState.resetSent
                draftState.error = initialState.error
                break

            case types.RESET_PASSWORD_SUCCESS:
                draftState.status = statuses.READY
                draftState.resetSent = true
                break

            case types.RESET_PASSWORD_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = error
                break

            case types.CHECK_STAFF_INVITATION:
                draftState.status = statuses.LOADING
                draftState.error = initialState.error
                break

            case types.CHECK_STAFF_INVITATION_SUCCESS:
                draftState.status = statuses.READY
                draftState.invitedStaffInformation = payload
                break

            case types.CHECK_STAFF_INVITATION_ERROR:
                draftState.error = error
                draftState.status = statuses.ERRORED
                break

            case types.CLAIM_RESET_PASSWORD:
            case types.UPDATE_PASSWORD:
                draftState.status = statuses.LOADING
                draftState.error = initialState.error
                break

            case types.CLAIM_RESET_PASSWORD_SUCCESS:
                draftState.status = statuses.READY
                break

            case types.CLAIM_RESET_PASSWORD_ERROR:
            case types.UPDATE_PASSWORD_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = error
                break

            case types.POST_LOGIN:
                draftState.postLoginStatus = statuses.LOADING
                break

            case types.POST_LOGIN_SUCCESS:
                draftState.postLoginStatus = statuses.READY
                break

            case types.POST_LOGIN_ERROR:
                draftState.postLoginStatus = statuses.ERRORED
                draftState.error = error
                break

            default:
                break
        }
    })
}

export default reducer
