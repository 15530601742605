import { REQUEST } from '@piconetworks/pkg-network-middleware'
import {
    dependantSelectors,
    API,
} from '../../dependencies'

/**
 * The fetchActionEvents async thunk is called with the publisherId
 * and returns a list of task events for the publisher
 * [{
 *      slug: 'add-a-link',
 *      completed_at: '2022-03-21 15:20:26',
 *      ignored_at: null,
 * }]
 * @name fetchActionEventsThunk
 * @memberof ProfileActionModule.ProfileActionSlice.ProfileActionSliceThunks
 */
const fetchActionEvents =
    async (_, {
        getState,
        dispatch,
    }) => {
        const publisherid = dependantSelectors.getPublisherId(getState())

        const { status, data } = await dispatch({
            type: REQUEST,
            config: {
                method: 'GET',
                url: API.API_URL,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
            }
        })

        if (status === 200) {
            return data
        } else {
            throw new Error('Fetching profile actions failed')
        }
    }

export default fetchActionEvents
