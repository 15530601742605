import dayjs from 'dayjs'
import { format } from '@piconetworks/pkg-currency'
import _ from 'lodash'

// originally from publisher src/components/crm/profileAccount/utils.js
const refundTypes = {
    CUSTOM: 'custom',
    DONATION: 'donation',
    FULL: 'full',
    PASS: 'pass',
    PAYMENT: 'payment',
    PRORATED: 'prorated',
    RECURRING_DONATION: 'recurring-donation',
    SINGLE_DONATION: 'single-donation',
    SUBSCRIPTION: 'subscription',
    SUCCEEDEDL: 'succeeded'
}

const amountIsZero = (amount) => {
    if(
        amount === '0.00' ||
        amount === '0' ||
        amount === 0 ||
        amount === '0.0'
    ){
        return true
    }
    return false
}

const proratePlanCancelledInDays = (amountPaid, amountAlreadyRefunded, intervalStartTimestamp, intervalEndTimestamp, cancelTimestamp = dayjs(), currency = 'usd') => {
    const start = dayjs(intervalStartTimestamp)
    const end = dayjs(intervalEndTimestamp)
    const canceled = dayjs(cancelTimestamp)

    const daysInBillingInterval = end.diff(start, 'days')
    const daysUsedInBillingInterval = canceled.diff(start, 'days')
    const daysRemainingInBillingInterval = daysInBillingInterval - daysUsedInBillingInterval

    const currentPlanPricePerDay = amountPaid / daysInBillingInterval

    const proratedFullRefund = daysRemainingInBillingInterval * currentPlanPricePerDay
    const proratedRefundLessRefundsAlreadyIssued = proratedFullRefund - amountAlreadyRefunded > 0 ? proratedFullRefund - amountAlreadyRefunded : 0

    return {
        proratedRefund: proratedRefundLessRefundsAlreadyIssued,
        proratedRefundFormatted: format({ amount: proratedRefundLessRefundsAlreadyIssued, currency, asNumber: true })
    }
}

/**
 * Convert given value, via ensuring parseFloat not to be NaN, to a float number for better comparison
 * @param {string|number|null|undefined} value
 * @returns {number}
 * @private
 */
const _floatParser = (value) => {
    if (_.isNil(value)) {
        return 0
    }

    return parseFloat(value)
}

/**
 * Determine if the given prices are equal or not
 * @param {string|number|null|undefined} newPrice
 * @param {string|number|null|undefined} oldPrice
 * @returns {boolean}
 */
const checkPriceChange = (newPrice, oldPrice) => _floatParser(newPrice) !== _floatParser(oldPrice)

export {
    amountIsZero,
    proratePlanCancelledInDays,
    refundTypes,
    checkPriceChange
}
