import { useCallback, useState } from "react"

import PlatformAccess from "../components/PlatformAccess"

const usePlatformAccessPaywall = () => {
    const [paywallOpen, setPaywallOpen] = useState(false)
    const [paywallFeature, setPaywallFeature] = useState(false)
    const openPlatformAccessPaywall = useCallback((featureLabel = null) => {
        setPaywallOpen(true)

        if (featureLabel) {
            setPaywallFeature(featureLabel)
        }
    }, [setPaywallOpen, setPaywallFeature])

    const onAfterClose = useCallback(() => {
        setPaywallOpen(false)
    }, [setPaywallOpen])

    const Paywall = () => {
        if (!paywallOpen) {
            return null
        }

        return (
            <PlatformAccess
                onAfterClose={onAfterClose}
                forceSetModalOpen={true}
                featureLabel={paywallFeature}
            />
        )
    }

    return {
        openPlatformAccessPaywall,
        Paywall,
        PlatformAccess
    }
}

export default usePlatformAccessPaywall
