import currency from 'currency.js'
import cx from 'classnames'
import styles from '../PaymentSummary.module.scss'

const AmountDue = ({
    taxAmount,
    creditAmount,
    appPrice,
}) => {
    const totalCost = currency(appPrice).add(taxAmount).value
    const costMinusCredits = currency(totalCost).subtract(creditAmount)

    let dueToday

    if (costMinusCredits.value < 0) {
        dueToday = currency(0).toString()
    } else {
        dueToday = costMinusCredits.toString()
    }

    return (
        <div className={styles['summary-item']}>
            <div className={styles.name}>
                <span>Due Today</span>
            </div>
            <div className={cx(styles.price, styles.colorGreen)}>${dueToday}</div>
        </div>
    )
}

export default AmountDue
