import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
import ModalModule from 'modules/modal'

function* installESP({ types, payload }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.INTEGRATIONS,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...payload.values
                }
            }
        })

        yield put({
            type: types.INSTALL_ESP_SUCCESS,
        })

        yield put(
            AppModule.creators.addNotification({
                message: 'Esp installed successfully',
                type: 'success'
            })
        )

        yield put(
            ModalModule.creators.closeModal({
                key: 'esp_install_modal_active_campaign'
            })
        )
        yield put(
            ModalModule.creators.openModal({
                key: 'esp_modal_active_campaign'
            })
        )
        yield put(
            ModalModule.creators.closeModal({
                key: 'esp_install_modal_convert_kit'
            })
        )
        yield put(
            ModalModule.creators.openModal({
                key: 'esp_modal_convert_kit'
            })
        )
        yield put({
            type: types.GET_INTEGRATIONS,
        })
    } catch (e) {

        if (e.response && e.response.status === 204) {
            yield put({
                type: types.INSTALL_ESP_SUCCESS,
            })

            yield put(
                AppModule.creators.addNotification({
                    message: 'Esp installed successfully',
                    type: 'success'
                })
            )

            yield put(
                ModalModule.creators.closeModal({
                    key: 'esp_install_modal_active_campaign'
                })
            )
            yield put(
                ModalModule.creators.closeModal({
                    key: 'esp_install_modal_convert_kit'
                })
            )
            yield put({
                type: types.GET_INTEGRATIONS,
            })

            return
        }

        yield put(
            AppModule.creators.addNotification({
                message: 'Error creating ESP integration: ' + e?.response?.data?.message,
                type: 'danger'
            })
        )
        yield put({ type: types.INSTALL_ESP_ERROR, error: e })
    }
}

export default installESP
