import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'

function* saveMailchimpFields({ selectors, types, payload }) {
    const publisherid = yield select(AccountModule.selectors.getPublisherId)
    let list_response
    const esp = yield select(selectors.getESP)
    const listId = esp?.listId

    let fields = []
    payload?.values?.pico_fields?.forEach(field => {
        if (field.inUse) {
            fields.push(field.key)
        }
    })

    try {
        if (listId === null) {
            list_response = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'put',
                    url: endpoints.INTEGRATIONS_ESP + '/lists',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid,
                    },
                    timeout: 30000,
                    data: {
                        list_id: null,
                    },
                },
            })
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: `${endpoints.INTEGRATIONS_ESP}/lists/fields`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                timeout: 30000,
                data: {
                    fields,
                    list_id: listId === 'new' ? list_response.data.list_id : listId,
                },
            },
        })

        yield put({
            type: types.SAVE_ESP_FIELDS_SUCCESS,
            payload: data
        })

        yield put(
            AppModule.creators.addNotification({
                message: `${payload.esp.title} fields successfully updated`,
                type: 'success',
            })
        )

    } catch (e) {
        yield put({ type: types.SAVE_ESP_FIELDS_ERROR, error: e })
        yield put(
            AppModule.creators.addNotification({
                message: e.message,
                type: 'danger',
            })
        )
    }
}

export default saveMailchimpFields
