import { exportedStore } from 'modules/store'

export const getStoreState = () => {
    return exportedStore?.getState()
}

export const getPublisherId = () => getStoreState()?.account?.publisherId || ''

export const getAccessToken = () => getStoreState()?._network?.access_token?.default || ''

export const getRefreshToken = () => getStoreState()?._network?.default?.refresh_token
