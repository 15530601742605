import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => [
    takeEvery(types.UNINSTALL_INTEGRATION, sagas.destroyIntegration),
    takeEvery(types.GET_WEBHOOKS, sagas.getWebhooks),
    takeEvery(types.SAVE_WEBHOOK, sagas.saveWebhooks),
    takeEvery(types.DELETE_WEBHOOK, sagas.deleteWebhook),
    takeEvery(types.SIMULATE_WEBHOOK, sagas.simulateWebhook),
    takeEvery(types.ADD_INTEGRATION, sagas.addIntegration),
    takeEvery(types.GET_INTEGRATIONS, sagas.getIntegrations),
    takeEvery(types.GET_ZAPIER_KEY, sagas.getZapierKey),
    takeEvery(types.GENERATE_ZAPIER_KEY, sagas.generateZapierKey),
    takeEvery(types.GET_ESP_FIELDS, sagas.getEspFields),
    takeEvery(types.SAVE_ESP_FIELDS, sagas.saveEspFields),
    takeEvery(types.GET_ESP_LISTS, sagas.getESPLists),
    takeEvery(types.INSTALL_ESP, sagas.installESP),
    takeEvery(types.OPEN_UNINSTALL_MODAL, sagas.openUninstallModal),
    takeEvery(types.START_STRIPE_EXPRESS, sagas.startStripeExpress),
    takeEvery(types.LOGIN_STRIPE_EXPRESS, sagas.loginStripeExpress),
]

export default takes
