import { takeEvery } from 'redux-saga/effects'

const takes = (duck) => [
    takeEvery(duck.types.GET_MY_APPEARANCE, duck.sagas.getMyAppearance),
    takeEvery(duck.types.SAVING_MY_APPEARANCE, duck.sagas.saveMyAppearance),
    takeEvery(duck.types.SAVING_MY_ORGANIZATION, duck.sagas.saveMyBusiness),
    takeEvery(duck.types.SAVING_WEBSITE, duck.sagas.saveWebsiteOptions),
    takeEvery(duck.types.DOMAINS_HEALTH_CHECK, duck.sagas.domainsHealthCheck),
    takeEvery(duck.types.ARTICLE_LOCKING, duck.sagas.saveArticleLocking),
    takeEvery(duck.types.GET_MY_ORGANIZATION, duck.sagas.getMyBusiness),
    takeEvery(duck.types.GET_MY_PROFILE, duck.sagas.getMyProfile),
    takeEvery(duck.types.GET_ME, duck.sagas.getMe),
    takeEvery(duck.types.GET_SOCIAL_LINKS, duck.sagas.getSocialLinks),
    takeEvery(duck.types.GET_MY_DOMAINS, duck.sagas.getMyDomains),
    takeEvery(duck.types.GET_MY_ARTICLE_LOCKING_SETTINGS, duck.sagas.getMyArticleLockingSettings),
    takeEvery(duck.types.CONTENT_RESTRICTION, duck.sagas.saveContentRestriction),
    takeEvery(duck.types.GET_MY_CONTENT_RESTRICTION_SETTINGS, duck.sagas.getMyContentRestrictionSettings),
    takeEvery(duck.types.GET_BILLING_INFORMATION, duck.sagas.getBillingInformation),
    takeEvery(duck.types.GET_USERNAME, duck.sagas.getUsername),
    takeEvery(duck.types.SAVING_USERNAME, duck.sagas.saveUsername),
    takeEvery(duck.types.SAVING_DEFAULT_LANDING_PAGE, duck.sagas.saveDefaultLandingPage),
    takeEvery(duck.types.SAVING_DEFAULT_PAYMENT_OFFER, duck.sagas.saveDefaultPaymentOffer),
    takeEvery(duck.types.SAVING_MY_PASSWORD, duck.sagas.savePassword),
    takeEvery(duck.types.SAVING_MY_PROFILE, duck.sagas.saveProfile),
    takeEvery(duck.types.CREATE_SOCIAL_LINK, duck.sagas.createSocialLink),
    takeEvery(duck.types.UPDATE_SOCIAL_LINK, duck.sagas.updateSocialLink),
    takeEvery(duck.types.DELETE_SOCIAL_LINK, duck.sagas.deleteSocialLink),
    takeEvery(duck.types.SAVING_BILLING_INFORMATION, duck.sagas.saveBillingInformation),
    takeEvery(duck.types.GET_DOUBLE_OPT_IN, duck.sagas.getDoubleOptIn),
    takeEvery(duck.types.DOUBLE_OPT_IN, duck.sagas.doubleOptIn),
    takeEvery(duck.types.GET_RESTRICTED_ACCESS, duck.sagas.getRestrictedAccess),
    takeEvery(duck.types.UPDATE_RESTRICTED_ACCESS, duck.sagas.updateRestrictedAccess),
    takeEvery(duck.types.GET_STAFF, duck.sagas.getStaff),
    takeEvery(duck.types.INVITE_STAFF, duck.sagas.inviteStaff),
    takeEvery(duck.types.REMOVE_STAFF, duck.sagas.removeStaff),
    takeEvery(duck.types.GET_MY_HIDDEN_ELEMENTS, duck.sagas.getMyHiddenElements),
    takeEvery(duck.types.SAVE_HIDDEN_ELEMENTS, duck.sagas.saveHiddenElements),
    takeEvery(duck.types.GET_TAXONOMIES, duck.sagas.getTaxonomies),
    takeEvery(duck.types.SAVE_TAXONOMIES, duck.sagas.saveTaxonomies),
    takeEvery(duck.types.UPDATE_HELP_LINK, duck.sagas.updateHelpLink),
    takeEvery(duck.types.GET_FREE_ACCESS, duck.sagas.getFreeAccess),
    takeEvery(duck.types.CREATE_FREE_ACCESS, duck.sagas.createFreeAccess),
    takeEvery(duck.types.UPDATE_FREE_ACCESS, duck.sagas.updateFreeAccess),
    takeEvery(duck.types.ARCHIVE_FREE_ACCESS, duck.sagas.archiveFreeAccess),
    takeEvery(duck.types.UNARCHIVE_FREE_ACCESS, duck.sagas.unarchiveFreeAccess),
    takeEvery(duck.types.SORT_FREE_ACCESS_RULES_ORDER, duck.sagas.sortFreeAccessRulesOrder),
    takeEvery(duck.types.SAVE_DEFAULT_OFFERS, duck.sagas.saveDefaultOffers),
    takeEvery(duck.types.GET_ACCESS_TOKENS, duck.sagas.getAccessTokens),
    takeEvery(duck.types.CREATE_ACCESS_TOKEN, duck.sagas.createAccessToken),
    takeEvery(duck.types.ARCHIVE_ACCESS_TOKEN, duck.sagas.archiveAccessToken),
    takeEvery(duck.types.SAVE_ARTICLE_STRUCTURE, duck.sagas.saveArticleStructure),
    takeEvery(duck.types.UPDATE_LOGIN_OPTIONS, duck.sagas.updateLoginOptions),
    takeEvery(duck.types.DELETE_ACCOUNT, duck.sagas.deleteAccount),
]

export default takes
