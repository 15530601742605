import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getCancellationStatus, cancelSubscription } from '../slice'

const useCancellation = ({
    pricingId = null,
    userId = null,
    publisherId = null,
    onCancellationStarted = (e = null) => {},
    onCancellationCompleted = (e = null) => {},
    onCancellationFailed = (e = null) => {},
    onCancellationAborted = (e = null) => {},
} = {}) => {
    const dispatch = useDispatch()
    const cancellationStatus = useSelector(getCancellationStatus)
    const executeCancellation = useCallback(() => {
        dispatch(cancelSubscription({
            pricingId,
            publisherId,
        }))
    }, [dispatch])

    const abortCancellation = useCallback(() => {
        onCancellationAborted()
    }, [onCancellationAborted])

    useEffect(() => {
        onCancellationStarted()
    }, [])

    useEffect(() => {
        if (cancellationStatus === 'success') {
            onCancellationCompleted()
        } else if (cancellationStatus === 'error') {
            onCancellationFailed()
        }
    }, [cancellationStatus])

    return {
        cancellationStatus,
        executeCancellation,
        abortCancellation,
    }
}

export default useCancellation
