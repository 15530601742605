/** @namespace ThemePicker.getTheme */

import { createAsyncSaga, name, endpoints } from '../dependencies'

const getTheme = createAsyncSaga(
    `${name}/getTheme`,
    function* ({
        payload: {
            publisherId = null,
            themeId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('no publisherId provided')
            }

            if (!themeId) {
                throw new Error('no themeId provided')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_THEME(themeId),
                headers: {
                    publisherid: publisherId,
                },
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default getTheme
