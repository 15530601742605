import produce from 'immer'

export const initialState = {
    files: [],
    loading: false,
    selectedFile: null,
    error: false,
    fileStackKeys: null,
    fileStackKeysError: null
}

const reducer = (state, { type, payload, error }, { types, statuses }) =>
    produce(state, (draftState) => {
        switch (type) {

            case types.GET_FILE_STACK_KEYS:
                draftState.fileStackKeys = initialState.fileStackKeys
                break
            case types.GET_FILE_STACK_KEYS_SUCCESS:
                draftState.fileStackKeys = payload
                break
            case types.GET_FILE_STACK_KEYS_ERROR:
                draftState.fileStackKeysError = error
                break
    
               
            case types.GET_FILES:
                draftState.files = initialState.files
                draftState.status = statuses.LOADING
                draftState.error = initialState.error
                break

            case types.GET_FILES_SUCCESS:
                draftState.files = payload
                draftState.status = statuses.READY
                break

            case types.CREATE_FILE:
                draftState.error = initialState.error
                break

            case types.CREATE_FILE_SUCCESS:
                draftState.status = statuses.READY
                draftState.files = [...draftState.files, ...payload]
                break

            case types.SET_SELECTED_FILE:
                draftState.selectedFile = payload
                break

            case types.EDIT_FILE_SUCCESS:
                const indexToUpdate = draftState.files.findIndex(({ id }) =>
                    id === payload.id
                )
                if (indexToUpdate !== -1) {
                    draftState.files[indexToUpdate] = payload
                }
                break

            case types.DELETE_FILE_SUCCESS:
                draftState.files = draftState.files.filter(({ id }) =>
                    id !== payload
                )
                break

            case types.GET_FILES_ERROR:
            case types.CREATE_FILE_ERROR:
            case types.EDIT_FILE_ERROR:
            case types.DELETE_FILE_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = error
                break

            default:
                return state
        }
    })

export default reducer
