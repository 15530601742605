import React from 'react'
import Head from 'next/head'
import cx from 'classnames'
import styles from './AppLayout.module.scss'
import PropTypes from 'prop-types'

import Notifications from 'containers/Notifications'
import { useSubscriptionBanner } from 'components/SubscriptionBanner'

const AppLayout = ({ children, pageTitle, banner }) => {
    const { showSubscriptionBanner } = useSubscriptionBanner()
    return (
        <React.Fragment>
            <Notifications />
            <Head>
                <title>{`${pageTitle} - Hype`}</title>
            </Head>
            <div className={cx(styles.fillHeight, {[styles.showBanner]: showSubscriptionBanner})}>
                {children}
            </div>
        </React.Fragment>
    )
}


AppLayout.propTypes = {
    children: PropTypes.node,
    pageTitle: PropTypes.string
}

export default React.memo(AppLayout)
