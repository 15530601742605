import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getMyProfile({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data, status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.PROFILE,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })
        yield put({
            type: types.GET_MY_PROFILE_SUCCESS,
            payload: {
                ...data
            }
        })
    } catch (e) {
        yield put({
            type: types.GET_MY_PROFILE_ERROR,
            error: e
        })
    }
}

export default getMyProfile
