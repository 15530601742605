import { call, put, putResolve, select } from 'redux-saga/effects'
import Router from 'next/router'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import { PRODUCT_TYPES_NAME, PRODUCT_TYPES } from 'utils/constants/productEdit'

import AccountModule from 'modules/account'
import appModule from 'modules/app'
import { contributionBinPluralSlug } from 'features/ContributionLang'


function* createProduct({ types, payload: { type, values } }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.PRODUCT,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...values,
                }
            }
        })

        const productPath = !!type?.includes('donation') ? yield call(contributionBinPluralSlug) : PRODUCT_TYPES[type]

        Router.push(`/products/${productPath}`)

        yield put(
            appModule.creators.addNotification({
                type: 'success',
                message: `${PRODUCT_TYPES_NAME[type]}: ${data.title} successfully created`
            })
        )

        yield put({ type: types.CREATE_PRODUCT_SUCCESS, payload: data })

    } catch (error) {
        yield put(
            appModule.creators.addNotification({
                type: 'danger',
                message: error.message
            })
        )
        yield put({ type: types.CREATE_PRODUCT_ERROR, error })
    }
}

export default createProduct


