const actions = {}

actions.types = [
    'GET_NEWSLETTERS',
    'GET_NEWSLETTERS_SUCCESS',
    'GET_NEWSLETTERS_ERROR',
    'CREATE_NEWSLETTER',
    'CREATE_NEWSLETTER_SUCCESS',
    'CREATE_NEWSLETTER_ERROR',
    'UPDATE_NEWSLETTER',
    'UPDATE_NEWSLETTER_SUCCESS',
    'UPDATE_NEWSLETTER_ERROR',
    'DELETE_NEWSLETTER',
    'DELETE_NEWSLETTER_SUCCESS',
    'DELETE_NEWSLETTER_ERROR',
    'SET_SELECTED_NEWSLETTER',
    'REMOVE_SELECTED_NEWSLETTER',
    'SORT_NEWSLETTERS_ORDER',
    'SORT_NEWSLETTERS_ORDER_SUCCESS',
    'SORT_NEWSLETTERS_ORDER_ERROR',
]

actions.creators = ({ types }) => ({
    getNewsletters: () => ({ type: types.GET_NEWSLETTERS }),
    createNewsletter: (payload) => ({ type: types.CREATE_NEWSLETTER, payload}),
    updateNewsletter: (payload) => ({ type: types.UPDATE_NEWSLETTER, payload}),
    deleteNewsletter: (payload) => ({ type: types.DELETE_NEWSLETTER, payload}),
    setSelectedNewsletter: (payload) => ({ type: types.SET_SELECTED_NEWSLETTER, payload}),
    removeSelectedNewsletter: () => ({ type: types.REMOVE_SELECTED_NEWSLETTER}),
    sortNewslettersOrder: (payload) => ({ type: types.SORT_NEWSLETTERS_ORDER, payload }),
})

export default actions
