import {
    initialState,
    reducer
} from './slice'
import useRegisterPhoneNumber from './useRegisterPhoneNumber'
import ConfirmBusinessInformation from './components/ConfirmBusinessInformation'
import RegisterPhoneNumber from './components/RegisterPhoneNumber'
import RegisteredPhoneNumber from './components/RegisteredPhoneNumber'
import RegisterPhoneNumberForm from './components/Form'

export default {
    initialState,
    reducer
}

export {
    useRegisterPhoneNumber,
    ConfirmBusinessInformation,
    RegisterPhoneNumber,
    RegisteredPhoneNumber,
    RegisterPhoneNumberForm
}
