import { call, putResolve, put, select } from 'redux-saga/effects'
import Router from 'next/router'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'
import SettingsModule from 'modules/settings'
import ErrorService from 'utils/services/ErrorService'

export const getPublisherMetaFromOffer = (payload, accountInfo) => {
    const { profileImage, backgroundColor, brandName } = payload

    const isNewProfileImg = profileImage && profileImage !== accountInfo?.icon
    const isNewBGColor = backgroundColor && backgroundColor !== accountInfo?.styling?.linkColor
    const isNewBrandName = brandName && brandName !== accountInfo?.name

    return {
        ...(isNewProfileImg ? { icon: profileImage } : {}),
        ...(isNewBGColor ? { widget_link_color: backgroundColor } : {}),
        ...(isNewBrandName ? { name: brandName } : {}),
    }
}

function* createOffer(action) {
    const { types, payload, callback, duplicate } = action

    try {
        // remove form_customizations with blank values
        if (payload?.values?.form_customizations) {
            payload.values.form_customizations = payload.values.form_customizations.filter(({ value }) => value !== null && value !== undefined && value !== '')
        }
        const { values = {} } = payload || {}

        // make sure no ids are sent in the payload in case this form is duplicated
        const payloadWithRemovedIds = JSON.parse(
            JSON.stringify(
                (values),
                (key, val) => (key === 'id' || key === 'short_code') ? undefined : val
            )
        )

        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const accountInfo = yield select(AccountModule.selectors.getAccountInfo)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.OFFERS,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    ...payloadWithRemovedIds,
                    products: values.products,
                }
            }
        })

        const isPopup = data.type.includes('popup')

        yield put({
            type: types.CREATE_OFFER_SUCCESS,
            payload: {
                type: isPopup ? 'popups' : 'landing_pages',
                data
            }
        })

        // only update these as needed
        const publisherMetaPayload = getPublisherMetaFromOffer(payload, accountInfo)

        if (Object.keys(publisherMetaPayload).length) {
            yield put(
                SettingsModule.creators.saveMyAppearance({
                    ...publisherMetaPayload,
                    notification: false
                })
            )
        }

        if (accountInfo.tier === 'tier_1' || duplicate) {
            if (isPopup) {
                Router.push(`/plugin/popups/${data.id}`, null, { shallow: true })
            } else {
                Router.push(`/pages/${data.id}`, null, { shallow: true })
            }
        }

        yield put(
            AppModule.creators.addNotification({
                type: 'success',
                message:
                    `${isPopup ? 'Popup' : 'Page'} ${duplicate ? 'duplicated' : 'created'} successfully.`
            })
        )

        yield call(callback)
    } catch (error) {
        const submissionError = new ErrorService(error).makeANiceSubmissionError()

        yield put(
            AppModule.creators.addNotification({
                message: 'There was a problem saving the offer',
                type: 'danger'
            })
        )

        yield call(callback, submissionError)

        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: types.CREATE_OFFER_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: types.CREATE_OFFER_ERROR,
                error
            })
        }
    }
}

export default createOffer
