import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getRecurringPayments({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { status, data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.METRICS}/payments`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })

        if (status === 200) {
            yield put({
                type: types.GET_RECURRING_PAYMENTS_SUCCESS,
                payload: data

            })
        } else {
            throw Error('An uncatched error occured.')
        }
    } catch (e) {
        yield put({
            type: types.GET_RECURRING_PAYMENTS_ERROR,
            error: e
        })
    }
}

export default getRecurringPayments
