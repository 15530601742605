import { createAsyncSaga, name, endpoints, logger } from '../dependencies'

const createTag = createAsyncSaga(
    `${name}/createTag`,
    function* ({
        payload: {
            publisherId = null,
            editionId = null,
            count = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            const response = yield request({
                method: 'post',
                url: endpoints.CREATE_CARD(),
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    ...(editionId && { edition_id: editionId }),
                    count,
                },
            })

            console.log('response', response)

            if (response?.length > 0 && !response?.id) {
                throw new Error('response?.id could not be found')
            }

            yield fulfilled({
                editionId,
            })
        } catch (error) {
            logger.error('createTag error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default createTag
