import { useSelector } from 'react-redux'

import AccountModule from 'modules/account'

const usePublisherId = () => {
    const publisherId = useSelector(AccountModule.selectors.getPublisherId)

    return publisherId
}

export default usePublisherId
