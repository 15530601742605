/** @namespace ThemePicker.slice */

import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name, themeConstantsMap } from '../dependencies'

import getAllThemes from './getAllThemes'
import getTheme from './getTheme'

const initialState = {
    themes: null,
    getAllThemesInProgress: false,
    getAllThemesError: null,

    theme: null,
    getThemeInProgress: false,
    getThemeError: null,
}

const ThemePickerSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [getAllThemes.pending]: (state) => {
            state.getAllThemesInProgress = true
            state.getAllThemesError = null
        },
        [getAllThemes.fulfilled]: (state, { payload: themes }) => {
            state.themes = themes
            state.getAllThemesInProgress = false
            state.getAllThemesError = null
        },
        [getAllThemes.rejected]: (state, { payload: error }) => {
            state.getAllThemesInProgress = false
            state.getAllThemesError = error
        },
        [getTheme.pending]: (state) => {
            state.getThemeInProgress = true
            state.getThemeError = null
        },
        [getTheme.fulfilled]: (state, { payload: theme }) => {
            state.theme = theme
            state.getThemeInProgress = false
            state.getThemeError = null
        },
        [getTheme.rejected]: (state, { payload: error }) => {
            state.theme = initialState.theme
            state.getThemeInProgress = false
            state.getThemeError = error
        },
    },
})

// SELECTORS
const getThemePicker = (state) => state[name]
const readAllThemes = createDraftSafeSelector(
    getThemePicker,
    (themePicker) => {
        const themes = themePicker?.themes?.map(({name, ...rest}) => {
            return {
                ...themeConstantsMap[name],
                name,
                ...rest,
            }
        })

        return themes
    },
)

const readAllDisplayableThemes = createDraftSafeSelector(
    getThemePicker,
    (themePicker) => {
        const themes = themePicker?.themes?.filter(({name, display, ...rest}) => {
            if (display === true) {
                return {
                    ...themeConstantsMap[name],
                    name,
                    display,
                    ...rest,
                }
            }
        })

        return themes
    },
)

const getAllThemesInProgress = createDraftSafeSelector(
    getThemePicker,
    (themePicker) => themePicker?.getAllThemesInProgress,
)

const getAllThemesError = createDraftSafeSelector(
    getThemePicker,
    (themePicker) => themePicker?.getAllThemesError,
)

const getThemeItem = createDraftSafeSelector(
    getThemePicker,
    (themePicker) => themePicker?.theme,
)

const getThemeInProgress = createDraftSafeSelector(
    getThemePicker,
    (themePicker) => themePicker?.getThemeInProgress,
)

const getThemeError = createDraftSafeSelector(
    getThemePicker,
    (themePicker) => themePicker?.getThemeError,
)

const getThemeById = createDraftSafeSelector(
    [
        readAllThemes,
        (state, id) => id,
    ],
    (themes, id) => themes?.find((theme) => theme?.id === id),
)


const getDisplayableThemeById = createDraftSafeSelector(
    [
        readAllDisplayableThemes,
        (state, id) => id,
    ],
    (themes, id) => themes?.find((theme) => theme?.id === id),
)

// GENERAL
const takes = [
    ...getAllThemes.takes,
    ...getTheme.takes,
]

const themePickerInitialState = {
    [name]: initialState,
}

const themePickerReducer = {
    [name]: ThemePickerSlice.reducer,
}

// EXPORTS
export default ThemePickerSlice.reducer

export {
    takes,
    themePickerInitialState,
    themePickerReducer,

    getAllThemes,
    getTheme,

    readAllThemes,
    readAllDisplayableThemes,
    getAllThemesInProgress,
    getAllThemesError,
    getThemeItem,
    getThemeInProgress,
    getThemeError,
    getThemeById,
    getDisplayableThemeById
}
