import { call, put, putResolve, select } from 'redux-saga/effects'

import { REMOVE_TOKENS, REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from '../../../utils/lib/endpoints'

function* logout({ sagas, types, publisherId }) {
    try {
        const state = yield select()

        yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                baseURL: endpoints.BASE_URL,
                url: endpoints.OAUTH.USER,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    refreshtoken:
                        state && state._network && state._network.refresh_token && state._network.refresh_token.default,
                    publisherid: publisherId
                },
                timeout: 30000
            }
        })

        yield putResolve({
            type: REMOVE_TOKENS
        })

        yield call(sagas.preLogout)

        yield put({
            type: types.LOGOUT_SUCCESS
        })


    } catch (error) {
        yield putResolve({
            type: REMOVE_TOKENS
        })

        yield put({
            type: types.LOGOUT_ERROR,
            error
        })
    }
}

export default logout
