import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

import logger from 'lib/logger'

function* startConversation({
    types,
    payload: {
        userStateId,
        message
    } = {}
}) {

    if (!userStateId) {
        throw new Error('Must pass user_state_id in payload!')
    }

    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'POST',
                url: endpoints.CONVERSATIONS,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                data: {
                    user_state_id: userStateId,
                    message
                }
            },
        })

        if (data) {
            yield put({
                type: types.GET_CONVERSATION_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('Failed to create new conversation')
        }
    } catch (e) {
        logger.error('startConversation error', {}, e)
        yield put({
            type: types.GET_CONVERSATION_ERROR,
            error: e
        })
    }
}

export default startConversation
