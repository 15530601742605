import { call, select } from 'redux-saga/effects'
import AccountModule from 'modules/account'
import getAnalytics from 'utils/lib/analytics'

import logger from 'lib/logger'

function* identifyUser({ payload }) {
    try {
        const eventData = payload?.eventData || {}
        const publisherId = yield select(AccountModule.selectors.getPublisherId)

        if (!publisherId) {
            throw new Error('Publisher id must be defined for analytics calls!')
        }

        const analytics = yield call(getAnalytics)

        // this is to make sure that, if we have switched publishers, the correct
        // publisher_id is still sent with any events
        analytics.plugins['analytics-plugin-pico'].initPublisher(publisherId)

        yield call(
            analytics.identify,
            publisherId,
            {
                ...eventData,
                publisher_id: publisherId
            }
        )
    } catch (err) {
        logger.error('Error tracking dashboard: identify event', {}, err)
    }
}

export default identifyUser
