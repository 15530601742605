import { select } from 'redux-saga/effects'

import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'

import { PlanCreditSummary } from 'features/planCreditSummary'

import AccountModule from 'modules/account'

import { fetchPlanCredits, getAvailableCreditsByType } from 'features/planCreditSummary'

const name = 'estimateMessage'

function* getPublisherId() {
    const publisherId = yield select(AccountModule.selectors.getPublisherId)

    return publisherId
}

export {
    name,

    endpoints,
    createAsyncSaga,

    getPublisherId,
    getAvailableCreditsByType,
    fetchPlanCredits,

    PlanCreditSummary,
}
