import Step from './components/Step'
import Provider, { useWizard } from './Context'

const Context = {
    useWizard,
    Provider,
}

const Wizard = {
    Context,
    Step
}

export default Wizard