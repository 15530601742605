import produce from 'immer'

export const initialState = {
    status: 'INITIATED',
    error: null,
    plans: null,
    whitelistable: null
}
const reducer = (state, { payload, type, error }, { types, statuses }) => {
    return produce(state, (draftState) => {
        switch (type) {
            case types.REQUEST_PLANS:
                draftState.status = statuses.LOADING
                break

            case types.REQUEST_PLANS_SUCCESS:
                draftState.status = statuses.READY
                draftState.plans = payload
                break

            case types.REQUEST_PLANS_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = error
                break

            case types.REQUEST_WHITELISTABLE_PLANS:
                draftState.status = statuses.LOADING
                break

            case types.REQUEST_WHITELISTABLE_PLANS_SUCCESS:
                draftState.status = statuses.READY
                draftState.whitelistable = payload
                break

            case types.REQUEST_WHITELISTABLE_PLANS_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = error
                break

            default:
                return state
        }
    })
}

export default reducer
