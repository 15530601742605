import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Box, Modal } from '@piconetworks/ui'
import styles from './ActionModal.module.scss'

const Body = ({ children = null, className = '', scrollable = true, preset = 'default', ...props }) => {
    return (
        <Modal.Body scrollable={scrollable ? scrollable : undefined}>
            <Box.Flexbox.Container className={cx(
                {
                    [styles.container]: preset === 'default',
                }, 
                className
            )} {...props}>
                <Box.Flexbox.Child flex='1 1 100%'>{children}</Box.Flexbox.Child>
            </Box.Flexbox.Container>
        </Modal.Body>
    )
}

Body.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    preset: PropTypes.oneOf(['default', 'none']),
    scrollable: PropTypes.bool
}

Body.defaultProps = {
    preset: 'default',
    scrollable: true
}

export default Body
