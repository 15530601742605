import AccountModule from 'modules/account'
import endpoints from 'utils/lib/endpoints'

/**
 * Slice dependencies consists of imports that are external to the slice.
 * This is useful for importing modules that are not part of the slice itself.
 * @name Dependencies
 * @memberof ProfileActionModule.ProfileActionSlice
 */
const dependantSelectors = {
    getPublisherId: AccountModule.selectors.getPublisherId,
}

const API_URL = endpoints.PROFILE_ACTION_EVENTS

const API = {
    API_URL,
}

export {
    API,
    dependantSelectors,
}
