import React from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import { useRouter } from 'next/router'
import Link from 'next/link'

import FeatureFlags from 'modules/feature-flags'
import AccountModule from 'modules/account'
import IntegrationsModule from 'modules/integrations'

import AccessControl from 'components/AccessControl'
import { Badge, Box, NavBar } from '@piconetworks/ui'
import Tooltip from 'components/Tooltip'
import { Group, Context, Item } from '@piconetworks/pkg-nav-link'

import { useFeatureManager, constants } from 'features/FeatureManager'
import useLinks from '../../utils/useLinks'

import style from '../../navigation.module.scss'

const MobileMain = ({ accountInfo }) => {
    const router = useRouter()
    const hasPayments = useSelector(IntegrationsModule.selectors.getMonetizationIsInstalled)

    const { hasFeature } = useFeatureManager()
    const hasHypePlugin = hasFeature(constants.GADGET_ACCESS_FEATURE) || hasFeature(constants.WIDGET_FEATURE)

    const paidDownloadsIsEnabled = useSelector((state) =>
        FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'paid-downloads')
    )

    const hasPhoneNumber = useSelector(AccountModule.selectors.hasPhoneNumber)

    const links = useLinks({
        hasHypePlugin,
        accountInfo,
        hasPhoneNumber,
        hasPayments,
        isDesktop: false,
        isMobileLeftNav: false,
        paidDownloadsIsEnabled,
    })

    const renderMobileLink = ({
        link: {
            link: _link,
            title,
            icon,
        },
        isActive,
        index,
    }) => {
        const isPicoLink = title === 'Page'
        const isPicoLinkActive = isPicoLink && (router?.pathname || '').includes('pages')
        const isMessagesActive = router.pathname?.includes('messages') && _link?.includes('messages')
        const isProductsActive = router.pathname?.includes('products') && _link?.includes('products')
        const isAppsActive = router.pathname?.includes('apps') && _link?.includes('apps')

        const isSubpageActive = isAppsActive || isProductsActive || isMessagesActive || isPicoLinkActive


        return (
            <Link key={index} href={_link}>
                <Item
                    active={
                        isSubpageActive ||
                        isActive(_link)
                    }
                    className={cx(style.item, { [style.active]: isSubpageActive || isActive(_link) })}
                >
                    <Tooltip
                        content={title}
                        placement="right"
                        className={cx(
                            'd-none',
                            'd-lg-block',
                            style.itemTooltip
                        )}
                    >
                        <Badge
                            className={
                                style.betaBadge
                            }
                            variant="primary"
                        >
                            {icon}
                        </Badge>
                    </Tooltip>
                </Item>
            </Link>
        )
    }

    return (
        <Group
            active={'/' + router.asPath.split('/')[1]}
            onChange={(e) => {
                router.push('[section]', e, { shallow: true })
            }}
        >
            <Context.Consumer>
                {({ isActive }) => {
                    return (
                        <NavBar
                            className={cx(style.mobileNavbar)}
                            horizontal={false}
                            key="navbar"
                            alignItems="center"
                            justifyContent="between"
                        >
                            <Box.Flexbox.Container
                                flexDirection="row"
                                alignItems="center"
                                className={cx(style.mobileNavbarWrapper)}
                            >
                                {links.map(
                                    (link, index) => {
                                        if (!link) {
                                            return null
                                        }

                                        const {
                                            requiredFeatures,
                                            override,
                                            fallback,
                                        } = link

                                        return (
                                            <AccessControl
                                                key={index}
                                                override={override}
                                                requiredFeatures={requiredFeatures}
                                                fallback={fallback
                                                    ? () => renderMobileLink({
                                                        link: fallback,
                                                        index,
                                                        isActive,
                                                    })
                                                    : null}
                                            >
                                                {renderMobileLink({
                                                    link,
                                                    index,
                                                    isActive,
                                                })}
                                            </AccessControl>
                                        )
                                    }
                                )}
                            </Box.Flexbox.Container>
                        </NavBar>
                    )
                }}
            </Context.Consumer>
        </Group>
    )
}

export default MobileMain
