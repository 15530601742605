export default class BillingService {

    constructor(store, statuses) {
        this.store = store
        this.statuses = statuses
    }

    getAll(state) {
        return state[this.store].billingInformation.data
    }

    getLoading(state) {
        return state[this.store].billingInformation.status === this.statuses.LOADING
    }

    getBillingError(state) {
        return state[this.store].billingInformation.error
    }

    getBillingStatus(state) {
        return state[this.store].billingInformation.data?.status
    }

    getBillingProfileIsActive({ billingProfile, state }) {
        if (state) {
            billingProfile = this.getBillingProfile(state)
        }
        return billingProfile?.active
    }

    getBillingStatusIsPaying({ billingStatus, state }) {
        if (state) {
            billingStatus = this.getBillingStatus(state)
        }
        return billingStatus?.status === 'active'
    }

    getNextPaymentDate({ billingStatus, state }) {
        if (state) {
            billingStatus = this.getBillingStatus(state)
        }
        return billingStatus?.subscription.upcoming_invoice?.next_payment_attempt
    }

    getBillingProfile(state) {
        if (state?.featureFlags?.all_flags?.['creator-billing']) {
            return state[this.store].billingInformation.data
        } else {
            return state[this.store].billingInformation.data.profile
        }
    }

    getDefaultSource({ billingProfile, state }) {
        if (state) {
            billingProfile = this.getBillingProfile(state)
        }
        return billingProfile?.sources?.find((source) => source?.id === billingProfile.default_source)
    }

    getAccountBalance({ billingProfile, state }) {
        if (state) {
            billingProfile = this.getBillingProfile(state)
        }
        return billingProfile?.account_balance
    }

    getUserChunks({ billingProfile, state }) {
        if (state) {
            billingProfile = this.getBillingProfile(state)
        }
        return billingProfile?.user_chunks
    }

    getUserCount({ billingProfile, state }) {
        if (state) {
            billingProfile = this.getBillingProfile(state)
        }
        return billingProfile?.user_count
    }

    getInvoices({ billingStatus, billingProfile, state }) {
        if (state) {
            billingStatus = this.getBillingStatus(state)
        }

        return billingStatus?.subscription && billingStatus?.subscription?.invoices ?
            billingStatus.subscription.invoices :
            billingProfile?.invoices ? billingProfile.invoices : []
    }

    getCurrency({ billingStatus, state }) {
        if (state) {
            billingStatus = this.getBillingStatus(state)
        }
        return billingStatus?.subscription && billingStatus?.subscription?.currency ? billingStatus.subscription.currency : 'usd'
    }

    getInfoForBillingPage(state) {
        const billingProfile = this.getBillingProfile(state)
        const billingStatus = this.getBillingStatus(state)

        return {
            error: this.getBillingError(state),
            loading: this.getLoading(state),
            accountBalance: this.getAccountBalance({ billingProfile }),
            currency: this.getCurrency({ billingStatus }),
            defaultSource: this.getDefaultSource({ billingProfile }),
            isActive: this.getBillingProfileIsActive({ billingProfile }),
            isPaying: this.getBillingStatusIsPaying({ billingStatus }),
            nextPaymentDate: this.getNextPaymentDate({ billingStatus }),
            paymentHistory: this.getInvoices({ billingStatus, billingProfile }),
            userChunks: this.getUserChunks({ billingProfile }),
            userCount: this.getUserCount({ billingProfile }),
        }
    }

    // Creator billing now supports multiple billing statuses,
    // but for now, we only want to fetch the first subscription that has a status: active
    getActiveBillingStatuses(state) {
        const billingStatusActive = state[this.store].billingInformation.data?.statuses?.find((statusItem) => statusItem?.subscription?.status === 'active')
        return billingStatusActive
    }

    getInvoicesAcrossStatuses({ billingStatuses, billingProfile, state }) {
        if (state) {
            billingStatuses = this.getActiveBillingStatuses(state)
        }

        return this.getInvoices({ billingStatus: billingStatuses, billingProfile })
    }

    getInfoForCreatorBillingPage(state) {
        const billingStatuses = this.getActiveBillingStatuses(state)
        const billingProfile = this.getBillingProfile(state)

        return {
            paymentHistory: this.getInvoicesAcrossStatuses({ billingStatuses, billingProfile }),
            error: this.getBillingError(state),
            loading: this.getLoading(state)
        }
    }
}
