import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

import logger from 'lib/logger'

function* updateBlast({
    types,
    payload: {
        payload,
    },
}) {
    const { message, draft = false, type, image_url, id } = payload || {}

    const updateBlast = {
        message,
        draft,
        type,
        ...(image_url && { image_url }),
    }

    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        // throw new Error('error')

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'PUT',
                url: endpoints.BROADCASTS + `/${id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                data: updateBlast,
            },
        })

        if (data) {
            yield put({
                type: types.UPDATE_BLAST_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('Failed to send')
        }
        if (draft === true) {
            yield put(
                AppModule.creators.addNotification({
                    message: 'Blast saved successfully.',
                    type: 'info'
                })
            )
        } else {
            yield put(
                AppModule.creators.addNotification({
                    message: 'Blast sent successfully.',
                    type: 'success'
                })
            )
        }

    } catch (e) {
        logger.error('updateBlast error', {}, e)

        yield put(
            AppModule.creators.addNotification({
                message: e?.response?.data?.message || 'Something went wrong.',
                type: 'danger'
            })
        )
        yield put({
            type: types.UPDATE_BLAST_ERROR,
            error: e
        })
    }
}

export default updateBlast
