import React from 'react'

import { Notification } from '@piconetworks/ui'

import styles from './NotificationsContainer.module.scss'

const Notifications = () => (
    <Notification.Container
        className={styles.container}
    />
)

export default Notifications
