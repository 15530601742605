import { put, delay, putResolve } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'

function* domainsHealthCheck({ types, payload }) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.DOMAINS_HEALTH_CHECK,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                timeout: 30000,
                data: {
                    ...payload
                }
            }
        })

        yield delay(1000)

        yield put({
            type: types.DOMAINS_HEALTH_CHECK_SUCCESS,
            payload: {
                ...data.body
            }
        })
    } catch (e) {
        yield put({
            type: types.DOMAINS_HEALTH_CHECK_ERROR,
            error: e
        })
    }
}

export default domainsHealthCheck
