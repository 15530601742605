import CardBrandImage from '@piconetworks/pkg-card-brand-image'
import Boutton from '@piconetworks/pkg-boutton'

import styles from './PaymentMethodSummary.module.scss'

const PaymentMethodSummary = ({
    defaultSource = null,
    onClickUpdatePaymentMethod = () => {},
}) => {
    const brand = defaultSource?.brand
    const last4 = defaultSource?.last4

    return (
        <div className={styles.summary}>
            {brand !== 'unknown' && <CardBrandImage style={{width: 40, height: 24}} brand={brand} />}

            <span className='ml-2'>{brand} ending in {last4}</span>
            <Boutton variant="link" size="sm" onClick={onClickUpdatePaymentMethod}>Update</Boutton>
        </div>
    )
}

export default PaymentMethodSummary
