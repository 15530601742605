import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'

import AccountModule from 'modules/account'

import endpoints from 'utils/lib/endpoints'

import logger from 'lib/logger'

function* createFile({
    duck,
    payload: {
        files,
    },
}) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: `${endpoints.FILES}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                data: {
                    files,
                }
            }
        })

        if (data) {
            yield put({
                type: duck.types.CREATE_FILE_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('Error creating new file')
        }
    } catch (error) {
        logger.error('createFile error', {}, error)
        yield put({
            type: duck.types.CREATE_FILE_ERROR,
            error,
        })
    }
}

export default createFile
