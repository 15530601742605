import { useEffect, useState } from 'react'
import cx from 'classnames'

import Boutton from '@piconetworks/pkg-boutton'
import { Flexbox } from '@piconetworks/pkg-box'
import { H2 } from '@piconetworks/pkg-heading'

import {
    TitleBar,
    CancelButton,
    PopupContainer,
    useNfcTags,
    useFeatureManager,
} from '../dependencies'
import constants from '../constants'

import usePlatformAccess from '../hooks/usePlatformAccess'

import AppTierButton from './AppTierButton'

import styles from './PlatformAccess.module.scss'

const ColumnHeader = ({
    thisIndex,
    popularIndex,
    currentlySubscribedAppTierIndex,
    minimumTierIndex,
}) => {
    if (thisIndex === (currentlySubscribedAppTierIndex + 2)) {
        return (
            <div className={cx(styles.bubble, styles.activeBg)}>ACTIVE</div>
        )    
    }

    if (thisIndex === (minimumTierIndex + 2)) {
        return (
            <div className={cx(styles.bubble, styles.minimumBg)}>MINIMUM</div>
        )    
    }

    if (thisIndex === (popularIndex + 2)) {
        return (
            <div className={cx(styles.bubble, styles.popularBg)}>POPULAR</div>
        )    
    }

    return null
}

const PricingTable = ({
    id = null,
    app = null,
    relatedApps = [],
    acceptedCtaSessionId = null,
    onAccepted = (args = {}) => {},
    onPresented = (args = {}) => {},
    onDeclined = (args = {}) => {},
} = {}) => {
    useEffect(onPresented, [])
    // this is hard coded for now. We will need to update this to be dynamic so we don't offer them a trial that they have already used.
    const eligibleForTrial = true

    // assuming countTagsByCreator is the number of tags the user has created and are active
    const { activeCardCount, maxTrialLength } = useNfcTags()
    const hasActiveTag = activeCardCount > 0
    const tagTrial = maxTrialLength
    const tagType = 'Card'
    const trialText = hasActiveTag ?
        `Your Hype ${tagType} comes with a ${tagTrial}-day, risk-free trial to any Hype plan.` :
        `Try any plan risk-free for 14 days.`

    const emailIcon = <span className='icon-icon_email' />
    const textIcon = <span className='icon-icon_text_message' />

    const featureManager = useFeatureManager()
    const { generatingSession } = usePlatformAccess()

    const platformAccessAppTiers = Object
        .entries(constants.app_tiers)
        .reduce((acc, [key, value]) => {
            acc[value] = featureManager.getAppTierByLabel(value)
            return acc
        }, {})

    const currentlySubscribedAppTier = Object
        .values(platformAccessAppTiers)
        .find((value) => {
            return (value as { has_access: boolean })?.has_access
        })

    const appTiers = Object.values(platformAccessAppTiers).sort((a, b) => b?.['label'] - a?.['label'])
    const minimumTier = Object.values(relatedApps).sort((a, b) => b?.['label'] - a?.['label'])[relatedApps.length - 1]
    const minimumPlan = minimumTier?.name

    const popularIndex = appTiers.findIndex((appTier) => appTier?.['label'] === 'tier-20')
    const currentlySubscribedAppTierIndex = !currentlySubscribedAppTier ? 999 : appTiers.findIndex((appTier) => appTier?.['label'] === currentlySubscribedAppTier?.['label'])
    const minimumTierIndex = appTiers.findIndex((appTier) => appTier?.['label'] === minimumTier?.['label'])

    const columns = {
        popular: {
            index: (popularIndex + 2 !== 1) ? (popularIndex + 2) : 0,
            color1: '#F9F4FF',
            color2: '#E6DBFF',
        },
        active: {
            index: (currentlySubscribedAppTierIndex + 2 !== 1) ? (currentlySubscribedAppTierIndex + 2) : 0,
            color1: '#f4fff5',
            color2: '#e2ffdb',
        },
        required: {
            index: (minimumTierIndex + 2 !== 1) ? (minimumTierIndex + 2) : 0,
            color1: '#f4feff',
            color2: '#dbf6ff',
        },
    }


    const [showingFeatures, setShowFeatures] = useState(false)

    const toggleShowFeatures = () => {
        setShowFeatures(!showingFeatures)
    }

    return (
        <>
            <style>
                {`
                    .platformAccessPricingTable th:nth-of-type(${columns.popular.index}) {
                        background-color: ${columns.popular.color1};
                    }
                    .platformAccessPricingTable tr:nth-child(odd) td:nth-of-type(${columns.popular.index}) {
                        background-color: ${columns.popular.color2};
                    }
                    .platformAccessPricingTable tr:nth-child(even) td:nth-of-type(${columns.popular.index}) {
                        background-color: ${columns.popular.color1};
                    }
                `}
                {`
                    .platformAccessPricingTable th:nth-of-type(${columns.required.index}) {
                        background-color: ${columns.required.color1};
                    }
                    .platformAccessPricingTable tr:nth-child(odd) td:nth-of-type(${columns.required.index}) {
                        background-color: ${columns.required.color2};
                    }
                    .platformAccessPricingTable tr:nth-child(even) td:nth-of-type(${columns.required.index}) {
                        background-color: ${columns.required.color1};
                    }
                `}
                {`
                    .platformAccessPricingTable th:nth-of-type(${columns.active.index}) {
                        background-color: ${columns.active.color1};
                    }
                    .platformAccessPricingTable tr:nth-child(odd) td:nth-of-type(${columns.active.index}) {
                        background-color: ${columns.active.color2};
                    }
                    .platformAccessPricingTable tr:nth-child(even) td:nth-of-type(${columns.active.index}) {
                        background-color: ${columns.active.color1};
                    }
                `}
            </style>
            <TitleBar>
                <div className={styles.title}>Upgrade your plan</div>
                <CancelButton onClick={onDeclined} block={false} label='Close' />
            </TitleBar>
            <PopupContainer>
                {minimumPlan && <div className={styles.minimumPlan}>This feature requires a subscription to the Hype <b>{minimumPlan}</b> plan or greater.</div>}
                {eligibleForTrial && <div className={styles.freeTrial}>
                    <H2>{trialText}</H2>
                    <p>Switch plans or cancel any time.</p>
                </div>}
                {/* Mobile Layout */}
                <div className={styles.planCard}>
                    <H2>Scale</H2>
                    <p className={styles.messagingAllowance}>{textIcon} 8,000 texts</p>
                    <p className={styles.messagingAllowance}>{emailIcon} Unlimited emails</p>
                    <p className={styles.description}>Never worry about scaling challenges again. Comprehensive tools for large operations, maximizing outreach, and consistent growth.</p>
                    <H2 className={styles.price}>$299</H2>
                    <p className={styles.interval}>/month</p>
                    <AppTierButton disabled={generatingSession} appTierLabel='tier-40' />
                    <Boutton variant="link" size="sm" className={styles.showFeaturesToggle} onClick={() => toggleShowFeatures()}>
                        Show {showingFeatures ? 'less' : 'more'}
                        <span className={cx('icon-chevron-down', styles.chevron, {[styles.open]: showingFeatures})} />
                    </Boutton>
                    {showingFeatures ? <table className={styles.planCardFeatures}>
                        <tbody>
                            <tr>
                                <td>Card Taps</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>URL Redirect Options</td>
                                <td>Any URL</td>
                            </tr>
                            <tr>
                                <td>Site</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Pages</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Analytics</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Design Customizations</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Content Embeds</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Contact Card</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Premium Fonts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Custom Photo/Video Wallpapers</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Contacts (CRM)</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Signup Forms</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Import and Export Contacts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Customer Profile Details</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Advanced Filtering</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Save Contacts to Phone</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Sync Contacts to Third-Party Email Platforms</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Unified Marketing Inbox</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Blasts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>1:1 Conversations</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Toll-Free Business Phone Number</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Texts</td>
                                <td>8000 texts per month</td>
                            </tr>
                            <tr>
                                <td>Emails</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Replies</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Attach Images and Videos</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Advanced Segmentation</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Tip Jars</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Subscriptions</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Advanced Payment Analytics</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Plugin</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Ambassador Program</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Email Support</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Team Management</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                        </tbody>
                    </table> : null}
                </div>
                <div className={styles.planCard}>
                    <H2>Premium</H2>
                    <p className={styles.messagingAllowance}>{textIcon} 2,000 texts</p>
                    <p className={styles.description}>The digital suite you don't want to miss. Packed with exclusive design tools, communication channels, and integrations.</p>
                    <H2 className={styles.price}>$99</H2>
                    <p className={styles.interval}>/month</p>
                    <AppTierButton disabled={generatingSession} appTierLabel='tier-30' />
                    <table className={styles.planCardFeatures}>
                        <tbody>
                            <tr>
                                <td>Card Taps</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>URL Redirect Options</td>
                                <td>Any URL</td>
                            </tr>
                            <tr>
                                <td>Site</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Pages</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Analytics</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Design Customizations</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Content Embeds</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Contact Card</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Premium Fonts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Custom Photo/Video Wallpapers</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Contacts (CRM)</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Signup Forms</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Import and Export Contacts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Customer Profile Details</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Advanced Filtering</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Save Contacts to Phone</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Sync Contacts to Third-Party Email Platforms</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Unified Marketing Inbox</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Blasts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>1:1 Conversations</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Toll-Free Business Phone Number</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Texts</td>
                                <td>2000 texts per month</td>
                            </tr>
                            <tr>
                                <td>Emails</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Replies</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Attach Images and Videos</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Advanced Segmentation</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Tip Jars</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Subscriptions</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Advanced Payment Analytics</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Plugin</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Ambassador Program</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Email Support</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Team Management</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.planCard}>
                    <H2>Standard</H2>
                    <p className={styles.messagingAllowance}>{textIcon} 750 texts</p>
                    <p className={styles.messagingAllowance}>{emailIcon} Unlimited emails</p>
                    <p className={styles.description}>Standard is how businesses enhance digital footprint. More features, seamless transitions, and efficient customer relations.</p>
                    <H2 className={styles.price}>$39</H2>
                    <p className={styles.interval}>/month</p>
                    <AppTierButton disabled={generatingSession} appTierLabel='tier-20' />
                    <table className={styles.planCardFeatures}>
                        <tbody>
                            <tr>
                                <td>Card Taps</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>URL Redirect Options</td>
                                <td>Any URL</td>
                            </tr>
                            <tr>
                                <td>Site</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Pages</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Analytics</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Design Customizations</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Content Embeds</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Contact Card</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Premium Fonts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Custom Photo/Video Wallpapers</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Contacts (CRM)</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Signup Forms</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Import and Export Contacts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Customer Profile Details</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Advanced Filtering</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Save Contacts to Phone</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Sync Contacts to Third-Party Email Platforms</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Unified Marketing Inbox</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Blasts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>1:1 Conversations</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Toll-Free Business Phone Number</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Texts</td>
                                <td>750 texts per month</td>
                            </tr>
                            <tr>
                                <td>Emails</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Replies</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Attach Images and Videos</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Advanced Segmentation</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Tip Jars</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Subscriptions</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Advanced Payment Analytics</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Plugin</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Ambassador Program</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Email Support</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Team Management</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.planCard}>
                    <H2>Essentials</H2>
                    <p className={styles.messagingAllowance}>{textIcon} 150 texts</p>
                    <p className={styles.messagingAllowance}>{emailIcon} Unlimited emails</p>
                    <p className={styles.description}>Where effective brand communication begins. Essentials provides key tools for a budding digital presence.</p>
                    <H2 className={styles.price}>$9</H2>
                    <p className={styles.interval}>/month</p>
                    <AppTierButton disabled={generatingSession} appTierLabel='tier-10' />
                    <table className={styles.planCardFeatures}>
                        <tbody>
                            <tr>
                                <td>Card Taps</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>URL Redirect Options</td>
                                <td>Any Site Page</td>
                            </tr>
                            <tr>
                                <td>Site</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Pages</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>Analytics</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Design Customizations</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Content Embeds</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Contact Card</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Contacts (CRM)</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Signup Forms</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Import and Export Contacts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Customer Profile Details</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Advanced Filtering</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Save Contacts to Phone</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Unified Marketing Inbox</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Blasts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>1:1 Conversations</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Toll-Free Business Phone Number</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Texts</td>
                                <td>150 texts per month</td>
                            </tr>
                            <tr>
                                <td>Emails</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Replies</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Tip Jars</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Subscriptions</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Advanced Payment Analytics</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Ambassador Program</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Email Support</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.planCard}>
                    <H2>Free</H2>
                    <p className={styles.messagingAllowance}>{textIcon} No texts</p>
                    <p className={styles.messagingAllowance}>{emailIcon} No emails</p>
                    <p className={styles.description}>Start your online journey. Finally, the foundation for brand presence without any cost. Perfect for beginners.</p>
                    <H2 className={styles.price}>$0</H2>
                    <p className={styles.interval}>/month</p>
                    <AppTierButton disabled={generatingSession} appTierLabel='tier-00' />
                    <table className={styles.planCardFeatures}>
                        <tbody>
                            <tr>
                                <td>Card Taps</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>URL Redirect Options</td>
                                <td>Site Homepage</td>
                            </tr>
                            <tr>
                                <td>Site</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Pages</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>Analytics</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Design Customizations</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Content Embeds</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Contact Card</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Contacts (CRM)</td>
                                <td>Unlimited</td>
                            </tr>
                            <tr>
                                <td>Signup Forms</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Import and Export Contacts</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Unified Marketing Inbox</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                            <tr>
                                <td>Ambassador Program</td>
                                <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            </tr>
                        </tbody>

                    </table>
                </div>

                {/* Desktop Layout */}
                <table className={styles.planTable + ' platformAccessPricingTable'}>
                    <thead>
                        <th></th>
                        <th>
                            <ColumnHeader
                                thisIndex={2}
                                popularIndex={popularIndex}
                                currentlySubscribedAppTierIndex={currentlySubscribedAppTierIndex}
                                minimumTierIndex={minimumTierIndex}
                            />
                            <Flexbox.Container flexDirection='column' justifyContent='between'>
                                <Flexbox.Child>
                                    <H2>Scale</H2>
                                    <p className={styles.messagingAllowance}>{textIcon} 8,000 texts</p>
                                    <p className={styles.messagingAllowance}>{emailIcon} Unlimited emails</p>
                                    <p className={styles.description}>Never worry about scaling challenges again. Comprehensive tools for large operations, maximizing outreach, and consistent growth.</p>
                                </Flexbox.Child>
                                <Flexbox.Child>
                                    <H2 className={styles.price}>$299</H2>
                                    <p className={styles.interval}>/month</p>
                                    <AppTierButton disabled={generatingSession} appTierLabel='tier-40' />
                                </Flexbox.Child>
                            </Flexbox.Container>
                        </th>
                        <th>
                            <ColumnHeader
                                thisIndex={3}
                                popularIndex={popularIndex}
                                currentlySubscribedAppTierIndex={currentlySubscribedAppTierIndex}
                                minimumTierIndex={minimumTierIndex}
                            />
                            <Flexbox.Container flexDirection='column' justifyContent='between'>
                                <Flexbox.Child>
                                    <H2>Premium</H2>
                                    <p className={styles.messagingAllowance}>{textIcon} 2,000 texts</p>
                                    <p className={styles.messagingAllowance}>{emailIcon} Unlimited emails</p>
                                    <p className={styles.description}>The digital suite you don't want to miss. Packed with exclusive design tools, communication channels, and integrations.</p>
                                </Flexbox.Child>
                                <Flexbox.Child>
                                    <H2 className={styles.price}>$99</H2>
                                    <p className={styles.interval}>/month</p>
                                    <AppTierButton disabled={generatingSession} appTierLabel='tier-30' />
                                </Flexbox.Child>
                            </Flexbox.Container>
                        </th>
                        <th className={styles.popularColumn}>
                            <ColumnHeader
                                thisIndex={4}
                                popularIndex={popularIndex}
                                currentlySubscribedAppTierIndex={currentlySubscribedAppTierIndex}
                                minimumTierIndex={minimumTierIndex}
                            />
                            <Flexbox.Container flexDirection='column' justifyContent='between'>
                                <Flexbox.Child>
                                    <H2>Standard</H2>
                                    <p className={styles.messagingAllowance}>{textIcon} 750 texts</p>
                                    <p className={styles.messagingAllowance}>{emailIcon} Unlimited emails</p>
                                    <p className={styles.description}>Standard is how businesses enhance digital footprint. More features, seamless transitions, and efficient customer relations.</p>
                                </Flexbox.Child>
                                <Flexbox.Child>
                                    <H2 className={styles.price}>$39</H2>
                                    <p className={styles.interval}>/month</p>
                                    <AppTierButton disabled={generatingSession} appTierLabel='tier-20' />
                                </Flexbox.Child>
                            </Flexbox.Container>
                        </th>
                        <th>
                            <ColumnHeader
                                thisIndex={5}
                                popularIndex={popularIndex}
                                currentlySubscribedAppTierIndex={currentlySubscribedAppTierIndex}
                                minimumTierIndex={minimumTierIndex}
                            />
                            <Flexbox.Container flexDirection='column' justifyContent='between'>
                                <Flexbox.Child>
                                    <H2>Essentials</H2>
                                    <p className={styles.messagingAllowance}>{textIcon} 150 texts</p>
                                    <p className={styles.messagingAllowance}>{emailIcon} Unlimited emails</p>
                                    <p className={styles.description}>Where effective brand communication begins. Essentials provides key tools for a budding digital presence.</p>
                                </Flexbox.Child>
                                <Flexbox.Child>
                                    <H2 className={styles.price}>$9</H2>
                                    <p className={styles.interval}>/month</p>
                                    <AppTierButton disabled={generatingSession} appTierLabel='tier-10' />
                                </Flexbox.Child>
                            </Flexbox.Container>
                        </th>
                        <th>
                            <ColumnHeader
                                thisIndex={6}
                                popularIndex={popularIndex}
                                currentlySubscribedAppTierIndex={currentlySubscribedAppTierIndex}
                                minimumTierIndex={minimumTierIndex}
                            />
                            <Flexbox.Container flexDirection='column' justifyContent='between'>
                                <Flexbox.Child>
                                    <H2>Free</H2>
                                    <p className={styles.messagingAllowance}>{textIcon} No texts</p>
                                    <p className={styles.messagingAllowance}>{emailIcon} No emails</p>
                                    <p className={styles.description}>Start your online journey. Finally, the foundation for brand presence without any cost. Perfect for beginners.</p>
                                </Flexbox.Child>
                                <Flexbox.Child>
                                    <H2 className={styles.price}>$0</H2>
                                    <p className={styles.interval}>/month</p>
                                    <AppTierButton disabled={generatingSession} appTierLabel='tier-00' />
                                </Flexbox.Child>
                            </Flexbox.Container>
                        </th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Card Taps</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                        </tr>
                        <tr>
                            <td>URL Redirect Options</td>
                            <td>Any URL</td>
                            <td>Any URL</td>
                            <td>Any URL</td>
                            <td>Any Site Page</td>
                            <td>Site Homepage</td>
                        </tr>
                        <tr>
                            <td>Hype Site</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                        </tr>
                        <tr>
                            <td>Pages</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>2</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>Analytics</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                        </tr>
                        <tr>
                            <td>Design Customizations</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                        </tr>
                        <tr>
                            <td>Content Embeds</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                        </tr>
                        <tr>
                            <td>Contact Card</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                        </tr>
                        <tr>
                            <td>Premium Fonts</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Custom Photo/Video Wallpapers</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Contacts (CRM)</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                        </tr>
                        <tr>
                            <td>Signup Forms</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                        </tr>
                        <tr>
                            <td>Import and Export Contacts</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                        </tr>
                        <tr>
                            <td>Customer Profile Details</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Advanced Filtering</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Save Contacts to Phone</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Sync Contacts to Third-Party Email Platforms</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Unified Marketing Inbox</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                        </tr>
                        <tr>
                            <td>Blasts</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>1:1 Conversations</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Toll-Free Business Phone Number</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Texts</td>
                            <td>8000 texts per month</td>
                            <td>2000 texts per month</td>
                            <td>750 texts per month</td>
                            <td>150 texts per month</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Emails</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Replies</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Attach Images and Videos</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Advanced Segmentation</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Tip Jars</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Subscriptions</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Advanced Payment Analytics</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Plugin</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Ambassador Program</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                        </tr>
                        <tr>
                            <td>Email Support</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Team Management</td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td><span className={cx(styles.checkmark, 'icon-icon_checkmark')} /></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </PopupContainer>
        </>
    )
}

export default PricingTable
