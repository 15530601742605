import NewslettersModule from 'modules/newsletters'
import AccountModule from 'modules/account'
import BlocksModule from 'modules/blocks'

import {
    OFFER_FORMATS,
    SIGNUP_TYPE,
    PAYMENT_TYPE,
    AWS_S3_CUSTOM_TEXT_URL,
    NEWSLETTERS_SIGNUP_SECTION,
    PRODUCTS_SECTION,
    TEXT_SECTION,
    TEXT_SIGNUP_SECTION,
    SIGNUP_SECTION,
    DEFAULT_LP_IMAGE,
    DEFAULT_POPUP_IMAGE,
    CONSOLIDATED_SECTION,
} from 'utils/constants/offers'
import { mdToDraftjs } from 'draftjs-md-converter'

import OffersService from '.'
import generateShortCode from 'utils/lib/generateShortCode'
import { BILLING_ADMIN_PAYMENT, BILLING_ADMIN_SIGNUP, GROUP_MEMBER_JOIN, GROUP_MEMBER_SIGNUP } from 'utils/constants/groups'
import { contributionSingularLowerCase } from 'features/ContributionLang'

export const emptyDraftJs = (type = '') => ({
    'blocks': type === 'signup'
        ? [
            {
                'key': '9pq1k',
                'text': 'Sign up for my updates!',
                'type': 'unstyled',
                'depth': 0,
                'inlineStyleRanges': [
                    {
                        'offset': 0,
                        'length': 23,
                        'style': 'BOLD'
                    }
                ],
                'entityRanges': [],
                'data': {}
            },
            {
                'key': 'a5vjm',
                'text': '',
                'type': 'unstyled',
                'depth': 0,
                'inlineStyleRanges': [],
                'entityRanges': [],
                'data': {}
            },
            {
                'key': 'f7pdj',
                'text': 'Customize this text to tell your followers what kind of content you’ll send them. 👇',
                'type': 'unstyled',
                'depth': 0,
                'inlineStyleRanges': [],
                'entityRanges': [],
                'data': {}
            }
        ]
        : [],
    'entityMap': {}
})

const defaultProfileImage = 'https://development-cms-assets.s3.amazonaws.com/trypico/assets/img/backgrounds/defaultLogo.png'

export const defaultTextSignupTitle = 'Sign up for text updates!'

export default class OfferFormService extends OffersService {
    constructor(store, statuses) {
        super(store, statuses)
        this.name = 'OfferFormService'
        this.store = store
        this.statuses = statuses
    }

    getIsPopup(format) {
        this.isPopup = format === OFFER_FORMATS.POPUPS
        return this.isPopup
    }

    getOffer(state, format, id) {
        this.isPopup = this.getIsPopup(format)
        const getType = this.isPopup ? 'getPopupById' : 'getLandingPageById'
        this.offer = super[getType](state, id)
        return this.offer
    }

    getInitialName(state, isPopup) {
        // if (this.tier === 'tier_1') {
        //     return this.account.username
        // }

        return this.offer?.name
            ? this.offer.name
            : isPopup
                ? 'Untitled Popup'
                : 'Untitled Page'
    }

    getInitialDescription() {
        return this?.offer?.description
    }

    getInitialSignupOffers(state) {
        let email_signup_method = false
        let phone_signup_method = false
        const methods = {
            github: false,
            email: false,
            facebook: false,
            google: false,
            linkedin: false,
            twitter: false,
            sms: false,
        }

        const authentication_methods = state?.account?.info?.authentication_methods || []
        const authMethods = (authentication_methods.length === 0)
            ? ['email']
            : authentication_methods

        // Initials should get auth methods from signup options on settings
        if (this.isNew) {
            const initial_methods = this.account?.authentication_methods || ['email', 'facebook', 'google']
            initial_methods.forEach(m => methods[m] = true)
            phone_signup_method = initial_methods.includes('sms')
            email_signup_method = initial_methods.includes('email')
        } else {
            authMethods.forEach(m => methods[m] = true)
            phone_signup_method = authMethods.includes('sms')
            email_signup_method = authMethods.includes('email')
        }

        return { signup_methods: methods, email_signup_method, phone_signup_method }
    }

    getInitialNewsletters() {
        const { newsletters = [] } = this.offer || {}

        const options = []

        // init free newsletters
        this.freeNewsletters.forEach((nl) => {
            const shouldBeChecked = newsletters.map(n => n.id).includes(nl.id)
            options.push({
                id: nl.id,
                label: nl.name,
                checked: shouldBeChecked,
                description: nl.description,
                order: nl.newsletters_order
            })
        })

        return { options }
    }

    getInitialAutomaticTriggers(isPopup) {
        if (!isPopup) return null

        const { trigger = {} } = this.offer || {}

        let enable_logged_in = false
        let enable_non_paying = true

        if (trigger?.enable_anonymous === false && trigger?.enable_authenticated) {
            enable_logged_in = true
        }
        if (trigger?.enable_member) {
            enable_non_paying = false
        }


        const options = {
            views: trigger?.views,
            cond_reset: (trigger?.reset_interval && trigger?.reset_interval !== null),
            reset_interval: trigger?.reset_interval,
            cond_show: !!trigger?.type,
            // api returns ms
            value: trigger?.type === 'delay' && trigger?.value
                ? trigger?.value
                : null,
            scroll: trigger?.type === 'scroll'
                ? trigger?.value
                : null,
            type: trigger?.type,
            cond_start: !!parseInt(trigger?.start),
            start: parseInt(trigger?.start) || undefined,
            cond_stop: !!parseInt(trigger?.stop),
            stop: parseInt(trigger?.stop) || null,
            enable_logged_in: enable_logged_in,
            enable_non_paying: enable_non_paying,
        }

        Object.keys(options).some(k => {
            if (![null, 0, false, undefined].includes(options[k])) {
                options.enabled = 'true'
                return true
            }
            options.enabled = 'false'
        })

        return options
    }

    getInitialConditions() {
        const { trigger = {} } = this.offer || {}
        const { conditions = [] } = trigger || {}
        return conditions
    }

    getInitialConditionType() {
        const { trigger = {} } = this.offer || {}
        return trigger?.condition_type || 'any'
    }

    // return payment_landing_page, signup_landing_page, payment_popup, signup_popup, group_landing_page
    getInitialType() {
        const { type = '' } = this.offer || {}
        if (['payment_popup', 'signup_popup', 'group_landing_page'].includes(type)) {
            return type
        } else {
            return 'standard_page'
        }
    }

    getShortCode() {
        const { short_code } = this.offer || {}

        if (short_code === null) {
            const code = generateShortCode()
            return code
        }
        return short_code
    }

    getInitialOrder(isPopup) {
        // order is same if editing an offer
        if (!this.isNew) {
            return this.offer.order
        }
        // otherwise its the last position of the current length
        if (isPopup) {
            return this.popups.active.length + 1
        } else {
            return this.landing_pages.active.length + 1
        }
    }

    getInitialCoverImage(isPopup) {
        if (this.isNew) {
            if (this.account?.cover_photo && this.account.cover_photo !== 'https://images.pico.tools/defaultCoverPhoto.png') {
                return this.account.cover_photo
            } else {
                return isPopup ? DEFAULT_POPUP_IMAGE : DEFAULT_LP_IMAGE
            }
        }
        return this.offer?.cover_image
    }

    getInitialProfileImage() {
        return this.account?.icon || defaultProfileImage
    }

    getInitialBrandName() {
        return this.account?.name || ''
    }

    getInitialBrandNameToggle() {
        return this.isNew || this.offer?.display_brand_name
    }

    getInitialLogoToggle() {
        return this.isNew || this.offer?.display_logo
    }

    getInitialSocialLinksToggle() {
        return this.isNew || this.offer?.display_social_links
    }

    getInitialProducts(format, type = PAYMENT_TYPE) {
        const isPopup = format === OFFER_FORMATS.POPUPS
        const products = this.offer?.products || []
        const activeButtonTriggers = this.activeButtonTriggers(isPopup, type)
        if (isPopup) {
            return activeButtonTriggers ?
                products
                :
                products?.map((product) => product && ({
                    ...product,
                    options: product?.options?.map((option) => option && ({
                        ...option,
                        short_code: generateShortCode()
                    }))
                }))

        }
        return products
    }

    getInitialRichText(type = PAYMENT_TYPE) {
        // get draftjs value from the returned markdown
        const fetchedMarkdown = this.offer?.custom_text && this.offer.custom_text[type]?.markdown
        const draftJsValue = fetchedMarkdown ? mdToDraftjs(fetchedMarkdown) : emptyDraftJs()
        return {
            value: draftJsValue,
            filename: (!this.isNew && this.offer?.custom_text)
                ? this.offer?.custom_text[type]?.file_name
                : null,
        }
    }

    static formatRichTextPayload(customText) {
        return !customText ? null : Object.entries(customText).reduce((newCustomText, [type, { file_name }]) => {
            const url = `${AWS_S3_CUSTOM_TEXT_URL}/${file_name}`
            return { ...newCustomText, [type]: url }
        }, {})
    }

    getInitialFields() {
        const defaultFields = [{
            connected_property: 'first_name',
            label: 'First name',
            order: 1,
            type: 'short_answer',
        }, {
            connected_property: 'last_name',
            label: 'Last name',
            order: 2,
            type: 'short_answer',
        }]

        // map and sort fields
        const initialFields = this.isNew
            ? defaultFields
            : (this.offer?.fields || []).map(field => ({
                description: field.description,
                connected_property: field?.connected_property?.id,
                required: field.required,
                order: field.order,
                label: field.label,
            })).sort((a, b) => a.order - b.order)

        return initialFields
    }


    activeButtonTriggers(isPopup, type = PAYMENT_TYPE) {
        if (isPopup && type.includes(SIGNUP_TYPE)) {
            return true
        }
        return this.offer?.products?.[0]?.options?.[0]?.short_code
    }

    getInitialAcknowledgmentText(type = 'default_payment') {

        const defaultText = {
            ack_default_payment: 'Thank you for subscribing. When you see our logo in this corner, you can click on it to manage your subscription.',
            ack_default_signup: 'Thank you for signing up!',
            ack_trial: 'Thank you for trying out a subscription. When you see our logo in this corner, you can click on it to manage your subscription.',
            ack_donation_single: 'Thank you for being a part of our community of supporters.',
            ack_donation_recurring: `Thank you for becoming a sustaining member of our community. When you see our logo in this corner, you can click on it to manage your recurring ${contributionSingularLowerCase()}.`,
            ack_pass: 'Your pass has been successfully activated! When you see our logo in this corner, you can click on it to view your pass.',
            ack_billing_admin: 'Thank you for your purchase. You can now share the original invitation link with those who wish to join the group.',
            ack_group_member: 'Thank you for subscribing. When you see our logo in this corner, you can click on it to manage your account.'
        }

        // api handles pass as ack_default_payment
        const key = `ack_${type === 'pass' ? 'default_payment' : type}`
        const fetchedMarkdown = (this.offer?.custom_text && this.offer.custom_text?.[key])
            ? this.offer.custom_text?.[key]?.markdown
            : defaultText[`ack_${type}`]

        const draftJsValue = fetchedMarkdown
            ? mdToDraftjs(fetchedMarkdown)
            : emptyDraftJs()

        return {
            value: draftJsValue,
            filename: (!this.isNew && this.offer?.custom_text)
                ? this.offer.custom_text?.[key]?.file_name
                : null,
        }
    }

    getInitialBlocks() {
        // instantiate a signup block on new forms
        const newSignupBlock = !this.consolidatedBlock && !this.signupBlocks.find(block => block.signup_block.type === NEWSLETTERS_SIGNUP_SECTION) ?
            {
                type: SIGNUP_SECTION,
                signup_block: {
                    type: CONSOLIDATED_SECTION
                }
            }
            : null

        const nonSignupBlocks = this.blocks.filter(block => block.type !== SIGNUP_SECTION)

        const blocksToOrder = [
            ...(newSignupBlock ? [newSignupBlock] : []),
            ...(this.signupBlocks || []),
            // fetch draftjs values from s3 urls for text blocks
            ...nonSignupBlocks.map((block) => {
                if (block.type === TEXT_SECTION) {
                    return {
                        ...block,
                        text_block: {
                            ...block?.text_block,
                            value: block?.text_block?.markdown
                                ? mdToDraftjs(block.text_block?.markdown)
                                : emptyDraftJs()
                        }
                    }
                }
                return block
            }),
        ]

        // products block is static order since you can't reorder it
        // nor add any blocks on payment form
        const productBlock =
            [...((this.getInitialType() || '').includes(PAYMENT_TYPE)
                ? [{
                    type: PRODUCTS_SECTION,
                }]
                : []),]

        // when signup block is not present, the user may have disable it
        // and api will not return this block
        // in order to render the disabled signup block,
        // we must push it into the correct order based on gap in index
        const initialBlocks = blocksToOrder.reduce((acc, _, index) => {
            const currIndex = index + 1
            let block = blocksToOrder.find(block => block.order === currIndex)

            // signup block if block not found
            if (!block) {
                acc = [
                    ...acc,
                    ...(newSignupBlock
                        ? [newSignupBlock]
                        : []
                    )
                ]
            } else {
                acc.push(block)
            }

            return acc
        }, [])

        // blocks will follow ordering
        // and publishers which has sms & email block but email on second
        // will have different ordering so push the missing signup block to end if it happens
        let blocks = []
        if (initialBlocks.length + 1 === blocksToOrder.length) {
            initialBlocks.push(...this.signupBlocks)
        }

        blocks = initialBlocks

        return [
            ...productBlock,
            ...blocks
        ]
    }

    // pull keys from signup block into root form
    getInitialSignupBlockMeta() {
        const { signup_block } = this.signupBlocks.find(block =>
            block.signup_block.type === NEWSLETTERS_SIGNUP_SECTION
        ) || {}

        const blockWithMeta =  this.consolidatedBlock?.signup_block || signup_block

        const getDraftJSValue = () => {
            const draftJsValue = this.isNew
                ? emptyDraftJs('signup')
                : blockWithMeta?.markdown
                    ? mdToDraftjs(blockWithMeta?.markdown)
                    : emptyDraftJs()

            return {
                value: draftJsValue,
                filename: !this.isNew
                    ? this.offer?.subtitle
                    : null,
            }
        }

        return {
            signup_rich_text: getDraftJSValue(),
            marketing_opt_in: blockWithMeta?.marketing_opt_in || false,
            default_authentication_method: this.account?.default_authentication_method || 'email',
        }
    }

    getInitialTextSignupBlockMeta() {
        const { signup_block } = this.signupBlocks.find(block =>
            block.signup_block.type === TEXT_SIGNUP_SECTION
        ) || {}
        return {
            text_signup_title: signup_block?.title || defaultTextSignupTitle,
            text_signup_subtitle: signup_block?.subtitle,
        }
    }

    getInitialBGColor() {
        return this.account?.styling?.linkColor
    }

    getInitialCollectSignups(isPopup) {
        const { signup_block } = this.signupBlocks.find(block =>
            block.signup_block.type === NEWSLETTERS_SIGNUP_SECTION
        ) || {}
        const isGroup = (this.getInitialType() || '').includes('group')
        const isPayment = (this.getInitialType() || '').includes(PAYMENT_TYPE)
        return !!signup_block || !!this.consolidatedBlock || isPopup || this.isNew || isGroup || isPayment
    }

    getInitialDefaultAuthMethod() {
        return this.account?.default_authentication_method || 'email'
    }

    getInitialFormValues(state, format, id) {
        const isPopup = format.value === OFFER_FORMATS.POPUPS.value

        this.popups = super.getPopups(state)
        this.landing_pages = super.getLandingPages(state)

        const offer = () => {
            if (isPopup) {
                return super.getPopupById(state, id)
            } else {
                return super.getLandingPageById(state, id)
            }
        }

        this.offer = offer()

        this.freeNewsletters = NewslettersModule.selectors.getFreeNewsletters(state)
        const allBlocks = BlocksModule.selectors.getBlocksByFormId(state, this.offer?.id)

        this.blocks = allBlocks.sort((a, b) => a.order - b.order).map((block, index) => ({
            ...block,
            order: index + 1
        })).map((block) => {
            if (block.type === 'product') {
                return ({
                    ...block,
                    product_block: {
                        ...block.product_block,
                        product_id: block?.product_block?.product?.id
                    }
                })
            } else {
                return block
            }
        })

        this.signupBlocks = this.blocks.filter(block =>
            block.type === SIGNUP_SECTION
                ? block?.signup_block?.type === CONSOLIDATED_SECTION
                : [NEWSLETTERS_SIGNUP_SECTION, TEXT_SIGNUP_SECTION].includes(block?.signup_block?.type)
        )
        this.account = AccountModule.selectors.getAccountInfo(state)
        this.isNew = !this.offer?.id
        this.tier = AccountModule.selectors.getAccessTier(state)
        this.consolidatedBlock = this.blocks.find(block => block.type === SIGNUP_SECTION && block.signup_block.type === CONSOLIDATED_SECTION)

        const type = this.getInitialType()
        const short_code = this.getShortCode()
        const richText = {
            [`${BILLING_ADMIN_PAYMENT.TYPE}_rich_text`]: this.getInitialRichText(BILLING_ADMIN_PAYMENT.TYPE),
            [`${BILLING_ADMIN_SIGNUP.TYPE}_rich_text`]: this.getInitialRichText(`${BILLING_ADMIN_SIGNUP.TYPE}`),
            [`${GROUP_MEMBER_JOIN.TYPE}_rich_text`]: this.getInitialRichText(GROUP_MEMBER_JOIN.TYPE),
            [`${GROUP_MEMBER_SIGNUP.TYPE}_rich_text`]: this.getInitialRichText(`${GROUP_MEMBER_SIGNUP.TYPE}`),
            payment_rich_text: this.getInitialRichText(),
            signup_rich_text: this.getInitialRichText(SIGNUP_TYPE),
        }

        const initialFormCustomizationValues = {}
        // setting initial values if they are available
        this?.offer?.form_customizations?.forEach(({ property, value }) => {
            // we should add constants for these at some point somewhere centralized
            switch (property) {
                case 'page-background-type':
                case 'page-background-value':
                case 'block-border-value':
                case 'block-border-style':
                case 'block-border-radius':
                case 'block-border-width':
                case 'block-shadow-value':
                case 'block-shadow-style':
                case 'block-background-value':
                    initialFormCustomizationValues[property] = value
                    break
            }
        })
        return {
            id: this.offer?.id,
            ...(type === 'signup_landing_page' || type === 'standard_page') && {
                theme_customizations: this?.offer?.theme_customizations || [],
                form_customizations: this?.offer?.form_customizations,
                theme_id: this?.offer?.theme_id,
                ...initialFormCustomizationValues
            },
            name: this.getInitialName(state, isPopup),
            description: this.getInitialDescription(),
            type,
            newsletters: this.getInitialNewsletters(),
            ...this.getInitialSignupOffers(state),
            ...type !== 'signup_landing_page' && {
                trigger: this.getInitialAutomaticTriggers(isPopup),
                condition_type: this.getInitialConditionType(),
                conditions: this.getInitialConditions(),
            },
            order: this.getInitialOrder(isPopup),
            short_code_enabled: true,
            short_code,
            enable_cover_image: !!this.getInitialCoverImage(isPopup),
            display_brand_name: this.getInitialBrandNameToggle(),
            display_logo: this.getInitialLogoToggle(),
            display_social_links: this.getInitialSocialLinksToggle(),
            cover_image: this.getInitialCoverImage(isPopup),
            // preview keys are not sent to the api, and used to display file previews on drop as a blob or the current url from fetched offer
            cover_preview: this.getInitialCoverImage(),
            profile_image: this.getInitialProfileImage(),
            profile_image_preview: this.getInitialProfileImage(),
            background_color: this.getInitialBGColor(),
            linkColor: this.getInitialBGColor(),
            brand_name: this.getInitialBrandName(),

            // these arrays are populated with existing data from the GET one
            // as well as newly added products/fields through the editor

            ...type !== 'signup_landing_page' && {
                products: this.getInitialProducts(format, type),
            },
            fields: this.getInitialFields(),
            ...type !== 'signup_landing_page' && {
                buttonTriggers: this.activeButtonTriggers(isPopup, type),
            },
            // blocks
            blocks: isPopup ? [] : this.getInitialBlocks(),

            ...richText,

            // signup blocks
            ...(!isPopup
                ? this.getInitialSignupBlockMeta()
                : {}
            ),

            ack_default_payment: this.getInitialAcknowledgmentText('default_payment'),
            ack_default_signup: this.getInitialAcknowledgmentText('default_signup'),
            ack_trial: this.getInitialAcknowledgmentText('trial'),
            ack_pass: this.getInitialAcknowledgmentText('pass'),
            ack_donation_single: this.getInitialAcknowledgmentText('donation_single'),
            ack_donation_recurring: this.getInitialAcknowledgmentText('donation_recurring'),
            ack_billing_admin: this.getInitialAcknowledgmentText('billing_admin'),
            ack_group_member: this.getInitialAcknowledgmentText('group_member'),

            collect_signups: this.getInitialCollectSignups(isPopup),

            // popup default type comes from root form
            // LP will come from the signup block
            ...(
                isPopup
                    ? {
                        default_authentication_method: this.getInitialDefaultAuthMethod()
                    } : this.getInitialTextSignupBlockMeta()
            ),

            // current value of text block, being edited or created. appends to blocks
            textBlockSelection: {
                value: emptyDraftJs(),
                index: -1,
            },
            //the below could be refactored into their own forms,
            // and simply update the respective products/fields key in the top form on submit
            /*
             * these fields are never submitted to the api
             * only used to create the object necessary to be inserted into the above arrays on clicking done in the editor (right side)
             * the below will also become initialized upon interactions with the preview screen
             *    - (ie) clicking on a product/field in the preview will populate these below values
            */
            // products
            defaultOptionSelection: '',

            ...type !== 'signup_landing_page' && {
                productSelection: { // form data of the product to be added / edited product
                    type: null,
                    selected: null,
                    index: -1 // index of product being edited, -1 if new
                },
                pricingOptions: { // checkboxes on the right for product editor
                    options: [],
                    defaults: []
                },
            },
            // fields
            fieldSelection: { // form data of the field to be added / edited field
                selected: {
                    label: null,
                    description: null,
                    connected_property: null,
                    type: null,
                    required: false,
                    index: -1 // index of field being edited, -1 if new
                },
            },
            linkSelection: {
                block_id: null,
                blockSelection: {
                    id: null,
                    index: -1,
                },
                selected: {
                    url: null,
                    title: null,
                    description: null,
                    image: null,
                    image_preview: null,
                    index: -1,
                    is_rich: false,
                    format_type: null,
                },
            },
            duplicate: false,
        }
    }
}
