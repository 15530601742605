import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

import logger from 'lib/logger'

function* enableMessaging({
    types,
    payload: { payload }
}) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'POST',
                url: endpoints.MESSAGING,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                data: {...payload}

            },
        })

        if (!data?.success) {
            throw new Error('Unable to enable messages')
        }

        yield put(AccountModule.creators.getAccountInfo())

        yield put({
            type: types.ENABLE_MESSAGING_SUCCESS,
            payload: data,
        })

        yield put({
            type: 'planCreditSummary/fetchPlanCredits'
        })
    } catch (e) {
        logger.error('enableMessaging error', {}, e)
        yield put({
            type: types.ENABLE_MESSAGING_ERROR,
            error: e.message,
        })
    }
}

export default enableMessaging
