import {
    initialState,
    reducer,
    takes,
} from './slice'

import useAdminAccess from './hooks/useAdminAccess'
import AdminAccess from './components/AdminAccess'

export default {
    initialState,
    reducer,
    takes,
}

export {
    useAdminAccess,
    AdminAccess,
}
