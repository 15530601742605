import { putResolve, put, call, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import ModalModule from 'modules/modal'
import usersModule from 'modules/users'
import appModule from 'modules/app'
import accountModule from 'modules/account'
import ErrorService from 'utils/services/ErrorService'

function* issueRefund({ duck, payload = {}, callback }) {
    const { values = {}, activityId } = payload

    const { refund_amount } = values

    const selectedUsers = yield select(usersModule.selectors.getSelectedUsers)
    const publisherid = yield select(accountModule.selectors.getPublisherId)

    const { user_state_id } = selectedUsers[0]
    const id = activityId

    if (!id) {
        yield put(
            appModule.creators.addNotification({
                message: 'This user has no charges !',
                type: 'danger'
            })
        )
        return
    }

    try {
        const { status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: `${endpoints.REFUNDS}/${activityId}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    refund_amount,
                    user_state_id
                }
            }
        })

        if (status !== 200) {
            throw Error('Could not issue refund !')
        }

        yield put({
            type: duck.types.ISSUE_REFUND_SUCCESS
        })

        yield put(
            ModalModule.creators.closeModal({
                key: 'issueRefund',
                actionFinished: true
            })
        )

        yield put(
            appModule.creators.addNotification({
                type: 'success',
                message: 'Refund sent successfully'
            })
        )

        yield put(usersModule.creators.requestSingleUser({ user_state_id }))

        yield call(callback, null)
    } catch (error) {
        const submissionError = new ErrorService(error).makeANiceSubmissionError()
        yield call(callback, submissionError)

        yield put({
            type: duck.types.ISSUE_REFUND_ERROR,
            error
        })
    }
}

export default issueRefund
