import { put, putResolve, select } from 'redux-saga/effects'
import isEmpty from 'lodash/isEmpty'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import { getSorted, getFiltered } from './utils'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

const defaultPayload = {
    page: 0,
    pageSize: 25
}

function* getPaginatedUsers(action) {
    const { payload, duck } = action

    const { filtered, page, pageSize, sorted } = payload || defaultPayload

    const params = { page, size: pageSize }

    if (!isEmpty(sorted)) {
        params.sort = getSorted(sorted)
    }

    if (!isEmpty(filtered)) {
        params.filtered = getFiltered(filtered)
    }

    const publisherid = yield select(AccountModule.selectors.getPublisherId)

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: `${endpoints.USERS}/search`,
                timeout: 30000,
                data: params,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        yield put({
            type: duck.types.GET_PAGINATED_USERS_SUCCESS,
            data: {
                users: data.users,
                count: data.count,
                page: data.page,
                pages: data.pages,
                pageSize: params.size,
                debug: `Loading from ${data.page * params.size} to ${data.page * params.size + data.users.length} of ${data.count} on ${data.pages} pages`
            }
        })
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.GET_PAGINATED_USERS_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.GET_PAGINATED_USERS_ERROR,
                error
            })
        }
    }
}

export default getPaginatedUsers
