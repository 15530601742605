import { putResolve, select, put } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* logoutUser(action) {
    const { selectors, types } = action
    const user = yield select(selectors.getSingleUser)
    const publisherid = yield select(AccountModule.selectors.getPublisherId)
    try {
        yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: `${endpoints.USERS}/profile/${user.user_state_id}/token`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })
        yield put({
            type: types.LOG_OUT_USER_SUCCESS
        })
        yield put(
            AppModule.creators.addNotification({
                message: 'User log out successfully',
                type: 'success'
            })
        )
    } catch (error) {
        if(error && error.response && error.response.data){
            yield put(
                AppModule.creators.addNotification({
                    message: error.response.data.message,
                    type: 'danger'
                })
            )
        }
        yield put({
            type: types.LOG_OUT_USER_ERROR,
            error
        })
    }
}

export default logoutUser
