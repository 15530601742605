import React from 'react'
import Head from 'next/head'
import { useSelector } from 'react-redux'
import SettingsModule from 'modules/settings'
import AccountModule from 'modules/account'

const HeadWithTagManager = () => {
    const contentCategories = useSelector(AccountModule.selectors.getContentCategories)
    const createdAt = useSelector(AccountModule.selectors.getCreatedAt)
    const me = useSelector(SettingsModule.selectors.getMe)
    const publisherId = useSelector(AccountModule.selectors.getPublisherId)
    const publisherRole = useSelector(AccountModule.selectors.getRole)
    const publisherName = useSelector(AccountModule.selectors.getPublisherName)
    const publisherUsername = useSelector(AccountModule.selectors.getUsername)
    const { data: userData } = me
    const email = userData.email?.value || userData._email?.value || userData.email
    const godMode = userData.user_type === 'god'
    if(!email) {
        return null
    }

    return (
        <Head>
            <title>Hype</title>
            <style>{`
                iframe#launcher {
                    display: none !important;
                }
            `}
            </style>
            <script
                async
                dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        "event": "dashboard",
                        "publisherId": "${publisherId}",
                        "accountCreationDate": ${Date.parse(createdAt)/1000},
                        "displayName": "${publisherName}",
                        "username": "${publisherUsername}",
                        "contentCategories": "${contentCategories}",
                        "userId": "${userData.user_id}",
                        "email":  "${email}",
                        "godMode": "${godMode}",
                        "firstName": "${userData.first_name}",
                        "lastName": "${userData.last_name}",
                        "role": "${publisherRole}",
                        "siteType": "dashboard",
                    });`,
                }}
            />
            {/* Plausible */}
            { process.env.STAGE === 'production' && <script defer data-domain={process.env.HOSTNAME} src="https://plausible.io/js/script.tagged-events.js"></script>}
            {/* Meta */}
            <script>{`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '445316090450755');
                fbq('track', 'PageView');
            `}</script>
            <noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=445316090450755&ev=PageView&noscript=1"/></noscript>
            {/* TikTok */}
            <script>{`
                !function (w, d, t) {
                    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
                )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                    ttq.load('CDE1R1RC77UDIEHUPP90');
                    ttq.page();
                }(window, document, 'ttq');
            `}</script>
            {/* Paved */}
            <script>{`
                !function(e,t,n,o,p,i,a){e[o]||((p=e[o]=function(){p.process?p.process.apply(p,arguments):p.queue.push(arguments)}).queue=[],p.t=+new Date,(i=t.createElement(n)).async=1,i.src="https://pvdpix.com/pixel.js?t="+864e5*Math.ceil(new Date/864e5),(a=t.getElementsByTagName(n)[0]).parentNode.insertBefore(i,a))}(window,document,"script","pvd"),pvd("init","3b813c32-84ca"),pvd("event","pageload");
            `}</script>
            {/* Iframely */}
            <script src="//cdn.iframe.ly/embed.js" async charset="utf-8" />
        </Head>
    )
}

export default HeadWithTagManager
