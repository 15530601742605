/** @namespace FeatureManager.slice */

import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name, DASHBOARD_RESET } from '../dependencies'

import fetchApps from './fetchApps'
import doAppPurchase from './doAppPurchase'
import cancelSubscription from './cancelSubscription'

const initialState = {
    apps: [],
    appTiers: [],
    fetchAppsInProgress: null,
    fetchAppsError: null,
    purchaseInProgress: false,
    purchaseErrors: null,
    purchaseSuccess: null,
    acceptedSessionCtaId: null,
    cartSessionId: null,
    cancellationStatus: null,
}

const featureManagerSlice = createSlice({
    name,
    initialState,
    reducers: {
        initialize(state) {
            Object.entries(initialState).forEach(([key, val]) => {
                state[key] = val
            })
        },
        setAcceptedCtaSessionId(state, { payload: ctaId }) {
            state.acceptedSessionCtaId = ctaId
        },
        setCartSessionId(state, { payload: cartSessionId }) {
            state.cartSessionId = cartSessionId
        },
    },
    extraReducers: {

        [fetchApps.pending]: (state) => {
            state.fetchAppsInProgress = true
            state.fetchAppsError = null
        },
        [fetchApps.fulfilled]: (state, { payload: apps }) => {
            Object.entries(initialState).forEach(([key, value]) => state[key] = value)
            state.apps = apps
            state.appTiers = apps.reduce((acc, app) => {
                return [
                    ...(acc || []),
                    ...(app?.tiers || []),
                ]
            }, [])

            state.fetchAppsInProgress = false
            state.fetchAppsError = null
        },
        [fetchApps.rejected]: (state, { payload: error }) => {
            Object.entries(initialState).forEach(([key, value]) => state[key] = value)
            state.fetchAppsInProgress = false
            state.fetchAppsError = error
        },
        [doAppPurchase.pending]: (state) => {
            state.purchaseErrors = null
            state.purchaseSuccess = null
            state.purchaseInProgress = true
        },
        [doAppPurchase.fulfilled]: (state, { payload: apps }) => {
            state.apps = apps
            state.appTiers = apps.reduce((acc, app) => {
                return [
                    ...(acc || []),
                    ...(app?.tiers || []),
                ]
            }, [])

            state.purchaseSuccess = true
            state.purchaseErrors = null
            state.purchaseInProgress = false
        },
        [doAppPurchase.rejected]: (state, { payload }) => {
            state.purchaseErrors = payload
            state.purchaseInProgress = false
            state.purchaseSuccess = false
        },
        [doAppPurchase.initialize]: (state) => {
            state.purchaseErrors = initialState.purchaseErrors
            state.purchaseSuccess = initialState.purchaseSuccess
            state.purchaseInProgress = initialState.purchaseInProgress
        },
        [cancelSubscription.pending]: (state) => {
            state.cancellationStatus = 'pending'
        },
        [cancelSubscription.fulfilled]: (state, { payload: apps }) => {
            state.cancellationStatus = 'success'

            state.apps = apps
            state.appTiers = apps.reduce((acc, app) => {
                return [
                    ...(acc || []),
                    ...(app?.tiers || []),
                ]
            }, [])
        },
        [cancelSubscription.rejected]: (state, { payload }) => {
            state.cancellationStatus = 'error'
        },
        [cancelSubscription.initialize]: (state) => {
            state.cancellationStatus = initialState.cancellationStatus
        },
    }
})

const {
    setAcceptedCtaSessionId,
    setCartSessionId,
    initialize,
} = featureManagerSlice.actions

const initializeAppPurchase = doAppPurchase.actions.initialize

// SELECTORS
const getFeatureManager = (state) => state[name]

const getApps = createDraftSafeSelector(
    getFeatureManager,
    (featureManager) => featureManager?.apps,
)

const getAppsTiers = createDraftSafeSelector(
    getFeatureManager,
    (featureManager) => featureManager?.appTiers,
)

const getFeatures = createDraftSafeSelector(
    getAppsTiers,
    (apps) => {
        const features = apps?.reduce((acc, app) => {
            app?.features?.forEach((feature = null) => {
                if (!feature?.id) {
                    return
                }

                // if the featue isn't already stored, store it
                if (!acc[feature?.id]) {
                    acc[feature?.id] = {
                        ...feature,
                        hasAccess: false,
                        appsProvidingAccess: [],
                        relatedApps: [],
                    }
                }

                // feature should have a has_access indicator
                // feature should indicate which apps give it access
                if (app?.has_access) {
                    acc[feature?.id].hasAccess = true
                    acc[feature?.id].appsProvidingAccess.push(app?.id)
                }

                // feature should indicate related apps
                acc[feature?.id].relatedApps.push(app)
            })
            return acc
        }, {}) || {}

        return Object.values(features)
    },
)

const purchaseStatusPending = 'pending'
const purchaseStatusErrors = 'errors'
const purchaseStatusSuccess = 'success'
const purchaseStatusNone = 'none'

const getPurchaseStatus = createDraftSafeSelector(
    getFeatureManager,
    (featureManager) => {
        const purchaseInProgress = featureManager.purchaseInProgress
        const purchaseSuccess = featureManager.purchaseSuccess
        const purchaseErrors = featureManager.purchaseErrors

        if (purchaseInProgress) {
            return purchaseStatusPending
        } else if (purchaseErrors) {
            return purchaseStatusErrors
        } else if (purchaseSuccess) {
            return purchaseStatusSuccess
        } else {
            return purchaseStatusNone
        }
    },
)

const isPurchaseStatusNone = createDraftSafeSelector(
    getPurchaseStatus,
    (purchaseStatus) => purchaseStatus === purchaseStatusNone
)

const isPurchaseStatusSuccess = createDraftSafeSelector(
    getPurchaseStatus,
    (purchaseStatus) => purchaseStatus === purchaseStatusSuccess
)

const isPurchaseStatusErrors = createDraftSafeSelector(
    getPurchaseStatus,
    (purchaseStatus) => purchaseStatus === purchaseStatusErrors
)

const isPurchaseStatusPending = createDraftSafeSelector(
    getPurchaseStatus,
    (purchaseStatus) => purchaseStatus === purchaseStatusPending
)

const getPurchaseErrors = createDraftSafeSelector(
    getFeatureManager,
    (featureManager) => featureManager.purchaseErrors,
)

const getAcceptedCtaSessionId = createDraftSafeSelector(
    getFeatureManager,
    (featureManager) => featureManager?.acceptedSessionCtaId,
)

const getCartSessionId = createDraftSafeSelector(
    getFeatureManager,
    (featureManager) => featureManager?.cartSessionId,
)

const getFetchAppsInProgress = createDraftSafeSelector(
    getFeatureManager,
    (featureManager) => featureManager?.fetchAppsInProgress,
)

const getCancellationStatus = createDraftSafeSelector(
    getFeatureManager,
    (featureManager) => featureManager?.cancellationStatus,
)

// GENERAL
const takes = () => [
    ...fetchApps.takes,
    ...doAppPurchase.takes,
    ...cancelSubscription.takes,
]

const featureManagerInitialState = {
    [name]: initialState,
}

const featureManagerReducer = {
    [name]: featureManagerSlice.reducer,
}

// EXPORTS
export default featureManagerSlice.reducer

export {
    takes,
    featureManagerInitialState,
    featureManagerReducer,

    fetchApps,
    doAppPurchase,
    cancelSubscription,

    initialize,
    getFeatures,
    getApps,
    getAppsTiers,
    getPurchaseStatus,
    getPurchaseErrors,
    isPurchaseStatusNone,
    isPurchaseStatusSuccess,
    isPurchaseStatusErrors,
    isPurchaseStatusPending,
    setAcceptedCtaSessionId,
    getAcceptedCtaSessionId,
    setCartSessionId,
    getCartSessionId,
    getFetchAppsInProgress,
    initializeAppPurchase,
    getCancellationStatus,
}
