import { call, putResolve, put, select, delay } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import ModalModule from 'modules/modal'
import AccountModule from 'modules/account'
import appModule from 'modules/app'
import UsersModule from 'modules/users'
import ErrorService from 'utils/services/ErrorService'
import WarningService from 'utils/services/WarningService'
import {
    INDIVIDUAL_SUBSCRIBER_FREE_ACCESS,
    PASS_HOLDER_FREE_ACCESS,
} from 'utils/constants/membership'

function* cancelPlan({
    payload: { users, values },
    callback = () => { },
    types,
    selectors,
}) {
    const publisherid = yield select(AccountModule.selectors.getPublisherId)
    const userMembershipType = users[0]?.membership?.type
    const user_state_ids = users.map((user) => user.user_state_id)

    const isWhiteList = [
        INDIVIDUAL_SUBSCRIBER_FREE_ACCESS,
        PASS_HOLDER_FREE_ACCESS
    ]?.includes(userMembershipType)

    let endpoint_url = isWhiteList ? endpoints.WHITELIST : endpoints.MEMBERSHIP
    let request_data = values

    if (users.length == 1) {
        endpoint_url += `/${user_state_ids[0]}`
    } else {
        request_data.user_state_ids = user_state_ids
    }

    try {
        const { data, status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: endpoint_url,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: request_data,
            },
        })
        const purchaseType = yield select(selectors.getPurchaseType)
        const purchaseTypes = yield select(selectors.getPurchaseTypePlural)

        if (status === 207) {
            const count = users.length
            const warningObject = {
                status,
                data: data.action_info,
                count,
                label: count > 1 ? `Some ${purchaseTypes || 'purchases'} could not be cancelled` : `The ${purchaseType || 'purchase'} could not be cancelled.`
            }
            const { message, moreInfo } = new WarningService(warningObject).makeItNice()
            if (users.length > 1) {
                yield put(
                    appModule.creators.addNotification({
                        type: 'warning',
                        message,
                        moreInfo
                    })
                )
            } else {
                yield put(
                    appModule.creators.addNotification({
                        type: 'warning',
                        message,
                        moreInfo
                    })
                )
            }
        } else {
            if (users.length === 1) {
                yield put(UsersModule.creators.reindexUser({
                    user_state_id: user_state_ids[0],
                    showNotification: false,
                }))
            }

            yield put({
                type: types.CANCEL_PLAN_SUCCESS,
                payload: data
            })

            yield put(
                appModule.creators.addNotification({
                    type: 'success',
                    message: 'Cancelled successfully.'
                })
            )

            yield call(callback, null)
        }

        // api needs time for updating user.
        yield delay(2000)
        // refresh the updated users
        yield put({
            type: types.FETCH_SELECTED_USERS,
            payload: user_state_ids,
        })

        yield put(
            ModalModule.creators.closeModal({
                key: 'cancelPlan',
                actionFinished: true
            })
        )
    } catch (error) {
        const submissionError = new ErrorService(error).makeANiceSubmissionError()
        yield call(callback, submissionError)

        yield put({
            type: types.CANCEL_PLAN_ERROR,
            error
        })
    }
}

export default cancelPlan
