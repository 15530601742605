/** @namespace FeatureManager.dependencies */

import PaymentsUsage from 'components/PaymentsUsage'
import StripeUsage from 'components/StripeUsage'

import AnalyticsModule from 'modules/analytics'
import SettingsModule from 'modules/settings'
import FeatureFlagsModule from 'modules/feature-flags'

import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'

import usePublisherId from 'hooks/usePublisherId'
import useUserId from 'hooks/useUserId'
import { DASHBOARD_RESET } from 'modules/baseDuck'

const name = 'featureManager'
const sendEvent = AnalyticsModule.creators.sendEvent
const getStatusesArray = SettingsModule.selectors.getStatusesArray
const getFeatureFlagIsEnabled = FeatureFlagsModule.selectors.getFeatureFlagIsEnabled

const getFeatureFlagIsLoading = FeatureFlagsModule.selectors.getLoading

const staticApps = {
    'stripe': {
        name: 'Stripe',
        description: `Create custom membership plans and content passes, set up contribution options for followers, and transfer earnings from Hype to your bank account.`,
        label: "stripe",
        Usage: PaymentsUsage,
        type: 'external',
        installableApps: [
            {
                icon: 'app-stripe',
                label: 'stripe',
                name: 'Stripe',
                ExternalAppUsage: StripeUsage,
            }
        ]
    }
}

export {
    createAsyncSaga,
    endpoints,
    name,
    sendEvent,
    usePublisherId,
    DASHBOARD_RESET,
    useUserId,
    getStatusesArray,
    getFeatureFlagIsEnabled,
    getFeatureFlagIsLoading,
    staticApps,
}
