import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { resetBillingError } from "features/cartUI/dependencies"
import useBillingError from "./useBillingError"

const useResetBilling = () => {
    const dispatch = useDispatch()
    const billingError = useBillingError()

    const _resetBillingError = useCallback(() => {
        if (billingError) {
            dispatch(resetBillingError())
        }
    }, [
        dispatch, billingError
    ])


    return _resetBillingError

}


export default useResetBilling
