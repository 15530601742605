import { delay, putResolve, put } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import app from 'modules/app'

function* saveDashboardPricing({ types, payload }) {

    try {
        yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.GET_DASHBOARD_PRICING,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                data: {
                    ...payload
                }
            }
        })

        yield delay(1000)

        // change to be data once it's no longer a mock
        yield put({
            type: types.SAVE_DASHBOARD_PRICING_SUCCESS,
            payload
        })

        yield put(
            app.creators.addNotification({
                type: 'success',
                message: 'Dashboard pricing updated successfully'
            })
        )
    } catch (error) {
        yield put({
            type: types.SAVE_DASHBOARD_PRICING_ERROR,
            error
        })
    }
}

export default saveDashboardPricing
