import { put, putResolve, select, take } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import Users from '../index'

function* notifyUserImport(action) {
    const { duck, payload: { single, notify_email, notify_phone, user_state_id } } = action
    const publisherid = yield select(AccountModule.selectors.getPublisherId)
    const payload = single ? { notify_email, notify_phone, user_state_id } : { notify_email, notify_phone }

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: single ? `${endpoints.USERS}/import/notify/single` : `${endpoints.USERS}/import/notify/all`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: payload,
            }
        })

        yield put({
            type: duck.types.NOTIFY_USER_IMPORT_SUCCESS,
            payload: data
        })

        yield put(Users.creators.getNotifiableImportedUserCount())
        yield take([
            Users.types.GET_NOTIFIABLE_IMPORTED_USER_COUNT_SUCCESS,
            Users.types.GET_NOTIFIABLE_IMPORTED_USER_COUNT_ERROR,
        ])
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.NOTIFY_USER_IMPORT_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.NOTIFY_USER_IMPORT_ERROR,
                error
            })
        }
    }
}

export default notifyUserImport
