import homepageExperiment from './homepageExperiment'

const useExperiment = (experimentName) => {
    switch (experimentName) {
        case 'homepage':
            return homepageExperiment()

        default:
            return {}
    }
}

export default useExperiment
