import { useSelector } from 'react-redux'
import { OffersModule } from '../dependencies'
import useHasActiveSubscription from './useHasActiveSubscription'
import constants from '../constants'

const useAllowMorePages = () => {
    const { hasActiveSubscription, activeTier } = useHasActiveSubscription()
    let _allowMorePages = null
    const activePages = useSelector(OffersModule.selectors.getActiveLandingPages) as any[]
    const pagesAllowed = constants.pages_allowed[activeTier]
    if (!hasActiveSubscription) {
        _allowMorePages = false
    } else if (pagesAllowed === -1) {
            _allowMorePages = true
    } else {
        _allowMorePages = activePages.length < pagesAllowed
    }
    // console.log(_allowMorePages, activePages.length, pagesAllowed)
    return {
        allowMorePages: _allowMorePages,
        activePages: activePages.length,
        pagesAllowed
    }
}

export default useAllowMorePages
