import { createAsyncSaga, name, endpoints, logger } from '../dependencies'

const fetchPack = createAsyncSaga(
    `${name}/fetchPack`,
    function* ({
        payload: {
            publisherId = null,
            packId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            if (!packId) {
                throw new Error('packId is required')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_PACK_BY_ID(packId),
                headers: {
                    publisherid: publisherId,
                },
            })

            const pack = response

            console.log('pack', pack)

            if (!pack) {
                throw new Error('pack could not be found')
            }

            console.log('pack', pack)

            yield fulfilled(pack)
        } catch (error) {
            logger.error(error)
            yield rejected(error)
        }
    }
)

export default fetchPack
