// slugs match the api db
export const ADD_A_LINK_SLUG = 'add-a-link'
export const ADD_SOCIALS_SLUG = 'add-socials'
export const EDIT_SIGNUP_SLUG = 'edit-sign-up'
export const SHARE_PICOLINK_SLUG = 'share-pico-link'
export const ALL_ACTIONS_DISMISSED_SLUG = 'all-tasks-dismissed'
export const WELCOME_DISMISSED_SLUG = 'welcome-dismissed'

export const IS_VALID_ACTION = (slug) => [
    ADD_A_LINK_SLUG,
    ADD_SOCIALS_SLUG,
    EDIT_SIGNUP_SLUG,
    SHARE_PICOLINK_SLUG,
    ALL_ACTIONS_DISMISSED_SLUG,
    WELCOME_DISMISSED_SLUG
].includes(slug)

export default {
    ADD_A_LINK_SLUG,
    ADD_SOCIALS_SLUG,
    EDIT_SIGNUP_SLUG,
    SHARE_PICOLINK_SLUG,
    ALL_ACTIONS_DISMISSED_SLUG,
    WELCOME_DISMISSED_SLUG
}
