import { putResolve, put, call, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import ModalModule from 'modules/modal'
import usersModule from 'modules/users'
import appModule from 'modules/app'
import accountModule from 'modules/account'
import ErrorService from 'utils/services/ErrorService'

function* emailReceipt(action) {
    const { duck, payload, callback } = action

    const transactions = yield select(usersModule.selectors.getUserTransactions)
    const publisherid = yield select(accountModule.selectors.getPublisherId)
    const { user, activityId, values } = payload
    const { user_state_id } = user
    const id = activityId || transactions[0]?.id
    const { email } = values

    if (!id) {
        yield put(
            appModule.creators.addNotification({
                message: 'This user has no charges.',
                type: 'danger'
            })
        )

        return
    }

    if (!user_state_id) {
        yield put(
            appModule.creators.addNotification({
                message: 'Try closing this modal and trying again.',
                type: 'danger'
            })
        )

        return
    }

    try {
        const { status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: `${endpoints.RECEIPTS}/${user_state_id}/${id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    email
                }
            }
        })
        // we should probably make sure the request has been successful here.... -RC
        yield put({
            type: duck.types.SEND_EMAIL_RECEIPT_SUCCESS
        })

        yield put(
            ModalModule.creators.closeModal({
                key: 'emailReceipt',
                actionFinished: true
            })
        )

        yield put(
            appModule.creators.addNotification({
                type: 'success',
                message: 'Email sent successfully'
            })
        )

        yield call(callback, null)
    } catch (error) {
        const submissionError = new ErrorService(error).makeANiceSubmissionError()
        yield call(callback, submissionError)

        yield put({
            type: duck.types.SEND_EMAIL_RECEIPT_ERROR,
            error
        })
    }
}

export default emailReceipt
