import reducer, { initialState } from './reducer'
import actions from './actions'
import createDuck from '../baseDuck'
import sagas from './sagas'
import takes from './takes'
import selectors from './selectors'

// app store is supposed to be for things that should be 
// persisted only to a user's browser session in local storage
// see readme for more info

export default createDuck({
    store: 'app',
    initialState,
    path: '/app'
}).extend({
    types: actions.types,
    reducer,
    creators: actions.creators,
    sagas,
    takes,
    selectors
})
