import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AccountModule from 'modules/account'
import SettingsModule from 'modules/settings'

import {
    constants,
    useFeatureManager,
    useFeatureManagerMetrics,
} from 'features/FeatureManager'
import { usePopup } from 'features/cartUI'

import { useRouter } from 'next/router'

import styles from './PlatformAccess.module.scss'
import PricingTable from './PricingTable'

const PlatformAccess = ({ children = null, featureLabel = null, forceSetModalOpen = null, onAfterClose = () => {} }) => {
    const { setModalOpen, Popup } = usePopup()

    const dispatch = useDispatch()
    const { push } = useRouter()

    const publisherId = useSelector(AccountModule.selectors.getPublisherId)
    const userId = useSelector(SettingsModule.selectors.getMeDataUserId)

    const metrics = useFeatureManagerMetrics()

    const featureManager = useFeatureManager({
        publisherId,
        userId,
    })

    const thisFeature = featureManager.getFeatureByLabel(featureLabel)
    const relatedApps = thisFeature['relatedApps']

    const ctaOnPresented = useCallback((eventData = {}) => {
        metrics.presentedCallToAction(eventData)
    }, [])

    const ctaOnDeclined = useCallback((eventData = {}) => {
        metrics.declinedCallToAction(eventData)
        setModalOpen(false)
    }, [dispatch])

    const ctaOnAccepted = useCallback((
        eventData = {
            cta_session_id: '',
        }
    ) => {
        metrics.acceptedCallToAction(eventData)
    }, [dispatch])

    useEffect(() => {
        if (forceSetModalOpen === true) {
            setModalOpen(true)
        } else if (forceSetModalOpen === false) {
            setModalOpen(false)
        }
    }, [forceSetModalOpen])

    return (
        <div
            onClick={() => {
                setModalOpen(true)
            }}
        >
            {children}
            <Popup
                className={styles.paywallPopup}
                onAfterClose={() => {
                    setModalOpen(false)
                    onAfterClose()
                }}
            >
                <PricingTable
                    onPresented={() => ctaOnPresented()}
                    onDeclined={() => ctaOnDeclined()}
                    onAccepted={() => ctaOnAccepted()}
                    relatedApps={relatedApps}
                />
            </Popup>
        </div>
    )
}

export default PlatformAccess
