import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

import logger from 'lib/logger'

function* getUsersMeta({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.SEARCH_USERS_META,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                }
            }
        })

        if (data) {
            yield put({
                type: types.GET_USERS_META_SUCCESS,
                payload: data,

            })
        } else {
            throw new Error('Failed to fetch users meta')
        }

    } catch (e) {
        logger.error('getUsersMeta error', {}, e)
        yield put({
            type: types.GET_USERS_META_ERROR,
            error: e
        })
    }
}

export default getUsersMeta
