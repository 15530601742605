import Boutton from '@piconetworks/pkg-boutton'

const CancelButton = ({
    block = true,
    label = 'Cancel',
    onClick = (e = null) => {},
}) => {
    return (
        <Boutton
            block={block}
            variant="secondary"
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                onClick(e)
            }}
        >
            {label}
        </Boutton>
    )
}

export default CancelButton
