import { put, putResolve } from 'redux-saga/effects'

import { GET_TOKEN } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'

function* login({ creators, payload, types }) {
    try {
        const { email, password } = payload

        if (!email) {
            throw Error('email must be provided')
        }

        if (!password) {
            throw Error('password must be provided')
        }

        const { data, error, message } = yield putResolve({
            type: GET_TOKEN,
            config: {
                method: 'post',
                baseURL: endpoints.BASE_URL,
                url: endpoints.OAUTH.PUBLISHER,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                timeout: 30000,
                data: {
                    email,
                    password
                }
            }
        })
        if (error) {
            throw Error(message)
        }

        if (!data.publisher_id) {
            throw Error('No publication associated with your email')
        }

        data.account_info.publisher_id = data.publisher_id

        if (data) {
            yield putResolve(creators.tokenLogin({
                accessToken: data.token,
                refreshToken: data.refresh_token
            }))

            yield put({
                type: types.LOGIN_SUCCESS,
                payload: {
                    ...data
                }
            })

            yield put(creators.postLogin())
        }
    } catch (error) {
        yield put({
            type: types.LOGIN_ERROR,
            error
        })
    }
}

export default login
