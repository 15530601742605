/** @namespace FeatureManager.cancelSubscription */

import { createAsyncSaga, name, endpoints } from '../dependencies'

const cancelSubscription = createAsyncSaga(
    `${name}/cancelSubscription`,
    function* ({
        payload: {
            publisherId = null,
            pricingId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const appCancellation = yield request({
                headers: {
                    publisherid: publisherId,
                },
                method: 'delete',
                url: endpoints.CANCEL_APP(pricingId),
            })

            const apps = yield request({
                headers: {
                    publisherid: publisherId,
                },
                method: 'get',
                url: endpoints.APPS,
            })

            yield fulfilled(apps)
        } catch (error) {
            yield rejected(error.message)
        }
    }
)

export default cancelSubscription
