import Body from './Body'
import Context from './Context'
import Footer from './Footer'
import Header from './Header'
import Wrapper from './Wrapper'

const ActionModal = {
    Body,
    Context,
    Footer,
    Header,
    Wrapper
}

export default ActionModal
