import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import ModalModule from 'modules/modal'
import AppModule from 'modules/app'

function* deleteNewsletters({ types, payload }) {
    try {
        const { payload: id } = payload
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: endpoints.NEWSLETTERS + `/${id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })

        yield put({
            type: types.DELETE_NEWSLETTER_SUCCESS,
            payload: data
        })
        yield put(
            ModalModule.creators.closeModal({
                key: 'deleteNewsletter'
            })
        )
        yield put({
            type: types.REMOVE_SELECTED_NEWSLETTER
        })

        yield put(
            AppModule.creators.addNotification({
                type: 'success',
                message: 'Newsletter deleted successfully.'
            })
        )

    } catch (e) {

        yield put(
            AppModule.creators.addNotification({
                type: 'danger',
                message: 'Something went wrong.'
            })
        )
        yield put({
            type: types.DELETE_NEWSLETTER_ERROR,
            error: e
        })
    }
}

export default deleteNewsletters
