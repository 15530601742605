import {
    ALL_ACTIONS_DISMISSED_SLUG, WELCOME_DISMISSED_SLUG,
} from '../constants'

import { createSelector } from '@reduxjs/toolkit'

/**
 * @param {string} name
 * @returns {Object}
 */
const createProfileActionsSelectors = ({ name }) => {
    /**
     * The functions below are called selectors
     * and allow us to select values from the state.
     * @name Selectors
     * @memberof SetupPicoLinkFeature.SetupPicoLinkSlice
     */
    const selectSelf = (state) => state[name]
    const selectActionEvents = createSelector(
        selectSelf,
        (state) => (state?.data || []).filter(event =>
            !event.ignored_at &&
            event.action_slug !== ALL_ACTIONS_DISMISSED_SLUG
        )
    )
    return {
        selectLoading: createSelector(
            selectSelf,
            (state) => state?.loading
        ),
        selectError: createSelector(
            selectSelf,
            (state) => state?.error
        ),
        // selects all tasks that are not ignored and are not ALL_ACTIONS_DISMISSED_SLUG
        selectActionEvents,
        selectIgnoredActionEvents: createSelector(
            selectSelf,
            (state) => (state?.data || []).filter(event =>
                event.ignored_at
            )
        ),
        selectCompletedActions: createSelector(
            selectActionEvents,
            (events = []) => events.filter(event => !!event.completed_at)
        ),
        selectIsAllActionsDismissed: createSelector(
            selectSelf,
            (state) => (
                !(state?.data || []).length ||
                !!(state?.data || []).find(event =>
                    event.action_slug === ALL_ACTIONS_DISMISSED_SLUG && !!event.completed_at
                )
            )
        ),
        selectWelcomeDismissed: createSelector(
            selectSelf,
            (state) => (
                !(state?.data || []).length ||
                !!(state?.data || []).find(event =>
                    event.action_slug === WELCOME_DISMISSED_SLUG && !!event.completed_at
                )
            )
        ),
    }
}

export default createProfileActionsSelectors