import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Tooltip from '@piconetworks/pkg-tooltip'
import styles from './Tooltip.module.scss'

const TooltipModified = ({
    className = '',
    show = true,
    children = null,
    label = '',
    placement = '',
    ...rest
}) => {
    if (!show) {
        return children
    }
    return (
        <Tooltip
            {...rest}
            className={cx(styles.tooltip, className)}
            label={label}
            placement={placement}
        >
            {children}
        </Tooltip>
    )
}

TooltipModified.propTypes = {
    children: PropTypes.any,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    label: PropTypes.string,
    placement: PropTypes.string,
    show: PropTypes.bool,
}

export default TooltipModified
