import produce from 'immer'

export const initialState = {
    status: 'INITIATED',
    groups: {
        status: 'INITIATED',
        data: [],
        error: false
    },
    selectedProductField: null,
    products: {
        status: 'INITIATED',
        error: false,
        data: [],
    },
    siblings: [],
    getProductSiblingsInProgress: null,
    getProductSiblingsSuccess: null,
    getProductSiblingsError: null,
}

const reducer = (state, action, { types, statuses }) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case types.GET_PRODUCT_SIBLINGS:
                draftState.getProductSiblingsInProgress = true
                draftState.getProductSiblingsSuccess = initialState.getProductSiblingsSuccess
                draftState.getProductSiblingsError = initialState.getProductSiblingsError
                break

            case types.GET_PRODUCT_SIBLINGS_SUCCESS:
                draftState.siblings = action.payload || initialState.siblings
                draftState.getProductSiblingsInProgress = false
                draftState.getProductSiblingsSuccess = true
                draftState.getProductSiblingsError = false
                break

            case types.GET_PRODUCT_SIBLINGS_SUCCESS:
                draftState.getProductSiblingsInProgress = false
                draftState.getProductSiblingsSuccess = false
                draftState.getProductSiblingsError = action.payload
                break    

            case types.DELETE_PRODUCT:
            case types.CREATE_PRODUCT:
            case types.GET_PRODUCT:
            case types.GET_PRODUCTS:
            case types.UPDATE_PRODUCT:
                draftState.products.status = statuses.LOADING
                draftState.products.error = false
                break

            case types.GET_PRODUCTS_SUCCESS:
                draftState.products.error = false
                draftState.products.status = statuses.READY
                draftState.products.data = action.payload.map((product) => ({...product, custom_text: state?.products?.data?.find((p) => p?.id === product?.id)?.custom_text || null}) )
                break

            case types.GET_PRODUCT_SUCCESS:
                draftState.products.error = false
                draftState.products.status = statuses.READY

                // replace the fetched product, or add it
                const idx = draftState.products.data.findIndex(p => p.id === action.payload.id)
                if (idx !== -1) {
                    draftState.products.data[idx] = action.payload
                } else {
                    draftState.products.data = [...draftState.products.data, action.payload]
                }
                break

            case types.GET_PRODUCT_ERROR:
                draftState.products.status = statuses.ERRORED
                draftState.products.error = action.error
                break

            case types.CREATE_PRODUCT_SUCCESS:
                draftState.products.data = [...draftState.products.data, action.payload]
                draftState.products.status = statuses.READY
                draftState.products.error = false
                break

            case types.UPDATE_PRODUCT_SUCCESS:
                draftState.products.status = statuses.READY
                draftState.products.error = false

                // replace the product
                const updatedIdx = draftState.products.data.findIndex(p => p.id === action.payload.id)
                draftState.products.data[updatedIdx] = action.payload
                break

            case types.DELETE_PRODUCT_SUCCESS:
                draftState.products.status = statuses.READY
                draftState.products.error = false
                draftState.products.data = draftState.products.data.filter(p => p.id !== action.payload)
                break

            case types.DELETE_PRODUCT_ERROR:
            case types.UPDATE_PRODUCT_ERROR:
            case types.CREATE_PRODUCT_ERROR:
            case types.GET_PRODUCTS_ERROR:
                draftState.products.status = statuses.ERRORED
                draftState.products.error = action.error
                break

            case types.GET_GROUPS:
                draftState.groups.status = statuses.LOADING
                draftState.groups.error = false
                break

            case types.GET_GROUPS_SUCCESS:
                draftState.groups.error = false
                draftState.groups.status = statuses.READY
                draftState.groups.data = action.payload
                break

            case types.GET_GROUPS_ERROR:
                draftState.groups.status = statuses.ERRORED
                draftState.groups.error = action.error
                break

            case types.SET_SELECTED_PRODUCT_FIELD:
                draftState.selectedProductField = action.payload
                break

            case types.CREATE_GROUP:
                draftState.groups.status = statuses.LOADING
                draftState.groups.error = false
                break

            case types.CREATE_GROUP_SUCCESS:
                draftState.groups.error = false
                draftState.groups.status = statuses.READY
                draftState.groups.data.push(action.payload)
                break

            case types.CREATE_GROUP_ERROR:
                draftState.groups.status = statuses.ERRORED
                draftState.groups.error = action.error
                break

            case types.UPDATE_GROUP:
                draftState.groups.status = statuses.LOADING
                draftState.groups.error = false
                break

            case types.UPDATE_GROUP_SUCCESS:
                draftState.groups.error = false
                draftState.groups.status = statuses.READY
                const updateIndex = draftState.groups.data.findIndex((group) => group.id === action.payload.id)
                draftState.groups.data[updateIndex] = action.payload
                break

            case types.UPDATE_GROUP_ERROR:
                draftState.groups.status = statuses.ERRORED
                draftState.groups.error = action.error
                break

            case types.DELETE_GROUP:
                draftState.groups.status = statuses.LOADING
                draftState.groups.error = false
                break

            case types.DELETE_GROUP_SUCCESS:
                draftState.groups.error = false
                draftState.groups.status = statuses.READY
                const deleteGroup = draftState.groups.data.findIndex((group) => group.id === action.payload.id)
                draftState.groups.data.splice(deleteGroup, 1)
                break

            case types.DELETE_GROUP_ERROR:
                draftState.groups.status = statuses.ERRORED
                draftState.groups.error = action.error
                break

            case types.CHECK_EDIT_PRODUCT:
                draftState.status = statuses.LOADING
                draftState.error = false
                break

            case types.CHECK_EDIT_PRODUCT_SUCCESS:
                draftState.error = false
                draftState.status = statuses.READY
                draftState.effectedUserCount = action.payload
                break

            case types.CHECK_EDIT_PRODUCT_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = action.error
                break

            default:
                return state
        }
    })

export default reducer
