export const BILLING_ADMIN_PAYMENT = {
    LABEL: 'Payment',
    TYPE: 'billing_admin_payment'
}

export const BILLING_ADMIN_SIGNUP = {
    LABEL: 'Signup',
    TYPE: 'billing_admin_signup'
}

export const GROUP_MEMBER_JOIN = {
    LABEL: 'Join',
    TYPE: 'group_member_join'
}

export const GROUP_MEMBER_SIGNUP = {
    LABEL: 'Signup',
    TYPE: 'group_member_signup'
}

export const GROUP_OFFER_TYPE_PROP_TYPES = [BILLING_ADMIN_PAYMENT.TYPE, BILLING_ADMIN_SIGNUP.TYPE, GROUP_MEMBER_JOIN.TYPE, GROUP_MEMBER_SIGNUP.TYPE]

export const ACK_BILLING_ADMIN = 'ack_billing_admin'
export const ACK_GROUP_MEMBER = 'ack_group_member'
