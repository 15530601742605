import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Box, Modal } from '@piconetworks/ui'
import Context from './Context'
import styles from './ActionModal.module.scss'

const Footer = ({ className = null, cancelButton = null, actionButton = null, flexboxContainer = null, leftButton = null, preset = 'default', ...props }) => {
    return (
        <Modal.Footer className={cx(
            {
                [styles.defaultFooter]: preset === 'default',
                [styles.narrowFooter]: preset === 'none',
            },
            className
        )} 
        {...props}
        >
            <Box.Flexbox.Container {...flexboxContainer} className={styles.full}>
                {leftButton && <Box.Flexbox.Child>{leftButton}</Box.Flexbox.Child>}
                {cancelButton && (
                    <Box.Flexbox.Child>
                        <Context.Consumer>
                            {({ onClose }) => {
                                return React.cloneElement(cancelButton, {
                                    onClick: (e) => {
                                        onClose(e)
                                    }
                                })
                            }}
                        </Context.Consumer>
                    </Box.Flexbox.Child>
                )}
                {actionButton && <Box.Flexbox.Child 
                    className={cx(
                        {
                            [styles.leftPadding]: preset === 'none',
                        }, 
                    )}
                >
                    {actionButton}
                </Box.Flexbox.Child>}
            </Box.Flexbox.Container>
        </Modal.Footer>
    )
}

Footer.propTypes = {
    actionButton: PropTypes.node,
    cancelButton: PropTypes.node,
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    flexboxContainer: PropTypes.object,
    leftButton: PropTypes.node,
    preset: PropTypes.oneOf(['default', 'none']),
}

Footer.defaultProps = {
    preset: 'default'
}

export default Footer
