import produce from 'immer'
import assign from 'lodash/assign'
import AccountModule from '../account'
import AppModule from '../app'

export const initialState = {
    status: 'INITIATED',
    myBusiness: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    profile: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    me: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    socialLinks: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    domains: {
        status: 'INITIATED',
        error: false,
        healthCheckLoading: false,
        data: {}
    },
    articleLocking: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    contentRestriction:{
        status: 'INITIATED',
        error: false,
        data: {}
    },
    billingInformation: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    username: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    landingPages: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    doubleOptIn: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    restrictedAccess: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    appearance: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    staff: {
        status: 'INITIATED',
        error: false,
        data: [],
    },
    hiddenElements: {
        status: 'INITIATED',
        error: false,
        data: {}
    },
    taxonomies: {
        status: 'INITIATED',
        error: false,
        data: []
    },
    freeAccess: {
        status: 'INITIATED',
        error: false,
        data: []
    },
    defaultOffers: {
        status: 'INITIATED',
        error: false
    },
    accessTokens: {
        status: 'INITIATED',
        error: false,
        data: []
    },
    articleStructure: {
        status: 'INITIATED',
        error: false
    },
}

// todo: switch all of these stores to use STATUS instead of LOADING
const reducer = (state, { type, payload, error }, { types, initialState, statuses }) =>
    produce(state, (draftState) => {
        let index

        switch (type) {
            case AppModule.types.RESET_ALL:
                assign(draftState, initialState)
                break

            case types.CREATE_ACCESS_TOKEN:
            case types.ARCHIVE_ACCESS_TOKEN:
            case types.GET_ACCESS_TOKENS:
                draftState.accessTokens.status = statuses.LOADING
                draftState.accessTokens.error = false
                break
            case types.GET_ACCESS_TOKENS_ERROR:
            case types.CREATE_ACCESS_TOKEN_ERROR:
            case types.ARCHIVE_ACCESS_TOKENS_ERROR:
                draftState.accessTokens.status = statuses.ERRORED
                draftState.accessTokens.error = error
                break

            case types.GET_ACCESS_TOKENS_SUCCESS:
                draftState.accessTokens.status = statuses.READY
                draftState.accessTokens.error = false
                draftState.accessTokens.data = payload
                break

            case types.CREATE_ACCESS_TOKEN_SUCCESS:
                draftState.accessTokens.status = statuses.READY
                draftState.accessTokens.error = false
                draftState.accessTokens.data.push(payload)
                break

            case types.ARCHIVE_ACCESS_TOKEN_SUCCESS:
                draftState.accessTokens.status = statuses.READY
                draftState.accessTokens.error = false
                draftState.accessTokens.data = state.accessTokens.data.filter(
                    ({ id }) => id !== payload
                )
                break

            case types.SAVE_TAXONOMIES:
            case types.GET_TAXONOMIES:
            case types.SORT_FREE_ACCESS_RULES_ORDER:
                draftState.taxonomies.status = statuses.LOADING
                draftState.taxonomies.error = false
                break

            case AccountModule.types.GET_ACCOUNT_INFO_SUCCESS:
                draftState.appearance.error = false
                draftState.appearance.status = statuses.READY
                draftState.appearance.data = payload
                draftState.taxonomies.status = statuses.READY
                draftState.taxonomies.error = false
                draftState.taxonomies.data = payload.taxonomies
                break

            case types.GET_TAXONOMIES_SUCCESS:
                const { taxonomies } = payload
                draftState.taxonomies.status = statuses.READY
                draftState.taxonomies.error = false
                draftState.taxonomies.data = taxonomies
                break

            case types.SAVE_TAXONOMIES_SUCCESS:
            case types.SORT_FREE_ACCESS_RULES_ORDER_SUCCESS:
                draftState.taxonomies.status = statuses.READY
                draftState.taxonomies.error = false
                draftState.taxonomies.data = payload
                break

            case types.SAVE_TAXONOMIES_ERROR:
            case types.GET_TAXONOMIES_ERROR:
            case types.SORT_FREE_ACCESS_RULES_ORDER_ERROR:
                draftState.taxonomies.status = statuses.ERRORED
                draftState.taxonomies.error = error
                break

            case types.GET_MY_APPEARANCE:
                draftState.appearance.status = statuses.LOADING
                draftState.appearance.error = false
                break

            case types.GET_MY_APPEARANCE_SUCCESS:
                draftState.appearance.error = false
                draftState.appearance.status = statuses.READY
                draftState.appearance.data = payload
                break

            case types.GET_MY_APPEARANCE_ERROR:
                draftState.appearance.status = statuses.ERRORED
                draftState.appearance.error = error
                break


            case types.SAVING_MY_APPEARANCE:
                draftState.appearance.status = statuses.LOADING
                draftState.appearance.error = false
                break

            case types.SAVING_MY_APPEARANCE_SUCCESS:
                draftState.appearance.error = false
                draftState.appearance.status = statuses.READY
                draftState.appearance.data = payload
                break

            case types.SAVING_MY_APPEARANCE_ERROR:
                draftState.appearance.status = statuses.ERRORED
                draftState.appearance.error = error
                break

            case types.GET_MY_ORGANIZATION:
                draftState.myBusiness.status = statuses.LOADING
                draftState.myBusiness.error = false
                break

            case types.GET_MY_ORGANIZATION_SUCCESS:
                draftState.myBusiness.error = false
                draftState.myBusiness.status = statuses.READY
                draftState.myBusiness.data = payload
                break

            case types.GET_MY_ORGANIZATION_ERROR:
                draftState.myBusiness.status = statuses.ERRORED
                draftState.myBusiness.error = error
                break

            case types.GET_MY_PROFILE:
                draftState.profile.status = statuses.LOADING
                draftState.profile.error = false
                break

            case types.GET_MY_PROFILE_SUCCESS:
                draftState.profile.status = statuses.READY
                draftState.profile.error = false
                draftState.profile.data = payload
                break

            case types.GET_MY_PROFILE_ERROR:
                draftState.profile.status = statuses.ERRORED
                draftState.profile.error = error
                break

            case types.GET_ME:
                draftState.me.status = statuses.LOADING
                draftState.me.error = false
                break

            case types.GET_ME_SUCCESS:
                draftState.me.status = statuses.READY
                draftState.me.error = false
                draftState.me.data = payload
                break

            case types.GET_ME_ERROR:
                draftState.me.status = statuses.ERRORED
                draftState.me.error = error
                break

            case types.GET_SOCIAL_LINKS:
                draftState.socialLinks.status = statuses.LOADING
                draftState.socialLinks.error = false
                break

            case types.GET_SOCIAL_LINKS_SUCCESS:
                draftState.socialLinks.status = statuses.READY
                draftState.socialLinks.error = false
                draftState.socialLinks.data = payload
                break

            case types.GET_SOCIAL_LINKS_ERROR:
                draftState.socialLinks.status = statuses.ERRORED
                draftState.socialLinks.error = error
                break

            case types.GET_MY_DOMAINS:
                draftState.domains.status = statuses.LOADING
                draftState.domains.error = false
                break

            case types.GET_MY_DOMAINS_SUCCESS:
                draftState.domains.error = false
                draftState.domains.status = statuses.READY
                draftState.domains.data = payload
                break

            case types.GET_MY_DOMAINS_ERROR:
                draftState.domains.status = statuses.ERRORED
                draftState.domains.error = error
                break

            case types.GET_MY_ARTICLE_LOCKING_SETTINGS:
                draftState.articleLocking.status = statuses.LOADING
                draftState.articleLocking.error = false
                break

            case types.GET_MY_ARTICLE_LOCKING_SETTINGS_SUCCESS:
                draftState.articleLocking.error = false
                draftState.articleLocking.status = statuses.READY
                draftState.articleLocking.data = payload
                break

            case types.GET_MY_ARTICLE_LOCKING_SETTINGS_ERROR:
                draftState.articleLocking.status = statuses.ERRORED
                draftState.articleLocking.error = error
                break

            case types.GET_MY_CONTENT_RESTRICTION_SETTINGS:
                draftState.contentRestriction.status = statuses.LOADING
                draftState.contentRestriction.error = false
                break

            case types.GET_MY_CONTENT_RESTRICTION_SETTINGS_SUCCESS:
                draftState.contentRestriction.error = false
                draftState.contentRestriction.status = statuses.READY
                draftState.contentRestriction.data = payload
                break

            case types.GET_MY_CONTENT_RESTRICTION_SETTINGS_ERROR:
                draftState.contentRestriction.status = statuses.ERRORED
                draftState.contentRestriction.error = error
                break

            case types.CONTENT_RESTRICTION:
                draftState.contentRestriction.status = statuses.LOADING
                draftState.contentRestriction.error = false
                break

            case types.CONTENT_RESTRICTION_SUCCESS:
                draftState.contentRestriction.status = statuses.READY
                draftState.contentRestriction.error = false
                draftState.contentRestriction.data = payload
                break

            case types.CONTENT_RESTRICTION_ERROR:
                draftState.contentRestriction.status = statuses.ERRORED
                draftState.contentRestriction.error = error
                break

            case types.GET_BILLING_INFORMATION:
                draftState.billingInformation.status = statuses.LOADING
                draftState.billingInformation.error = false
                break

            case types.GET_BILLING_INFORMATION_SUCCESS:
                draftState.billingInformation.status = statuses.READY
                draftState.billingInformation.error = false
                draftState.billingInformation.data = payload
                break

            case types.GET_BILLING_INFORMATION_ERROR:
                draftState.billingInformation.status = statuses.ERRORED
                draftState.billingInformation.error = error
                break

            case types.GET_USERNAME:
                draftState.username.status = statuses.LOADING
                draftState.username.error = false
                break

            case types.GET_USERNAME_SUCCESS:
                draftState.username.status = statuses.READY
                draftState.username.error = false
                draftState.username.data = {
                    username: payload.username
                }
                break

            case types.GET_USERNAME_ERROR:
                draftState.username.status = statuses.ERRORED
                draftState.username.error = error
                break

            case types.SAVING_MY_ORGANIZATION:
                draftState.myBusiness.status = statuses.LOADING
                draftState.myBusiness.error = false
                break

            case types.SAVING_MY_ORGANIZATION_SUCCESS:
                draftState.myBusiness.status = statuses.READY
                draftState.myBusiness.error = false
                draftState.myBusiness.data = payload
                break

            case types.SAVING_MY_ORGANIZATION_ERROR:
                draftState.myBusiness.status = statuses.ERRORED
                draftState.myBusiness.error = error
                break

            case types.SAVING_MY_PROFILE:
                draftState.profile.status = statuses.LOADING
                draftState.profile.error = false
                break
            case types.SAVING_MY_PROFILE_SUCCESS:
                draftState.profile.status = statuses.READY
                draftState.profile.error = false
                draftState.profile.data = payload
                break

            case types.SAVING_MY_PROFILE_ERROR:
                draftState.profile.status = statuses.ERRORED
                draftState.profile.error = error
                break

                // case types.SAVING_MY_PASSWORD:
                //     draftState.status = statuses.SAVING
                //     break

                // case types.SAVING_MY_PASSWORD_SUCCESS:
                //     for (let key in payload) state[key] = payload[key]
                //     draftState.status = statuses.READY
                //     break
                // case types.SAVING_MY_PASSWORD_ERROR:
                //     draftState.status = statuses.CANCELLED
                //     break
            case types.SAVING_WEBSITE:
                draftState.domains.status = statuses.LOADING
                draftState.domains.error = false
                break
            case types.SAVING_WEBSITE_SUCCESS:
                draftState.domains.status = statuses.READY
                draftState.domains.error = false
                draftState.domains.data = payload
                break

            case types.SAVING_WEBSITE_ERROR:
                draftState.domains.status = statuses.ERRORED
                draftState.domains.error = error
                break

            case types.DOMAINS_HEALTH_CHECK:
                draftState.domains.healthCheckLoading = true
                draftState.domains.error = false
                break
            case types.DOMAINS_HEALTH_CHECK_SUCCESS:
                draftState.domains.healthCheckLoading = false
                draftState.domains.error = false
                draftState.domains.data = payload
                break

            case types.DOMAINS_HEALTH_CHECK_ERROR:
                draftState.domains.healthCheckLoading = false
                draftState.domains.error = error
                break

            case types.SAVING_BILLING_INFORMATION:
                draftState.billingInformation.status = statuses.LOADING
                draftState.billingInformation.error = false
                break

            case types.SAVING_BILLING_INFORMATION_SUCCESS:
                draftState.billingInformation.status = statuses.READY
                draftState.billingInformation.error = false
                draftState.billingInformation.data = payload
                break

            case types.SAVING_BILLING_INFORMATION_ERROR:
                draftState.billingInformation.status = statuses.ERRORED
                draftState.billingInformation.error = error
                break

            case types.SAVING_USERNAME:
                draftState.username.status = statuses.LOADING
                draftState.username.error = false
                break

            case types.SAVING_USERNAME_SUCCESS:
                draftState.username.status = statuses.READY
                draftState.username.error = false
                draftState.username.data = {
                    username: payload.username
                }
                break

            case types.SAVING_USERNAME_ERROR:
                draftState.username.status = statuses.ERRORED
                draftState.username.error = error
                break

            case types.ARTICLE_LOCKING:
                draftState.domains.status = statuses.LOADING
                draftState.domains.error = false
                break
            case types.ARTICLE_LOCKING_SUCCESS:
                draftState.domains.status = statuses.READY
                draftState.domains.error = false
                draftState.domains.data = payload
                break

            case types.ARTICLE_LOCKING_ERROR:
                draftState.domains.status = statuses.ERRORED
                draftState.domains.error = error
                break

            case types.GET_DOUBLE_OPT_IN:
                draftState.doubleOptIn.status = statuses.LOADING
                draftState.doubleOptIn.error = false
                break
            case types.GET_DOUBLE_OPT_IN_SUCCESS:
                draftState.doubleOptIn.status = statuses.READY
                draftState.doubleOptIn.error = false
                draftState.doubleOptIn.data = payload
                break
            case types.GET_DOUBLE_OPT_IN_ERROR:
                draftState.doubleOptIn.status = statuses.ERRORED
                draftState.doubleOptIn.error = error
                break

            case types.DOUBLE_OPT_IN:
                draftState.doubleOptIn.status = statuses.LOADING
                draftState.doubleOptIn.error = false
                break
            case types.DOUBLE_OPT_IN_SUCCESS:
                draftState.doubleOptIn.status = statuses.READY
                draftState.doubleOptIn.error = false
                draftState.doubleOptIn.data = payload
                break
            case types.DOUBLE_OPT_IN_ERROR:
                draftState.doubleOptIn.status = statuses.ERRORED
                draftState.doubleOptIn.error = error
                break

            case types.UPDATE_RESTRICTED_ACCESS:
                draftState.restrictedAccess.status = statuses.LOADING
                draftState.restrictedAccess.error = false
                break
            case types.UPDATE_RESTRICTED_ACCESS_SUCCESS:
                draftState.restrictedAccess.status = statuses.READY
                draftState.restrictedAccess.error = false
                draftState.restrictedAccess.data = payload
                break
            case types.UPDATE_RESTRICTED_ACCESS_ERROR:
                draftState.restrictedAccess.status = statuses.ERRORED
                draftState.restrictedAccess.error = error
                break

            case types.GET_RESTRICTED_ACCESS:
                draftState.restrictedAccess.status = statuses.LOADING
                draftState.restrictedAccess.error = false
                break
            case types.GET_RESTRICTED_ACCESS_SUCCESS:
                draftState.restrictedAccess.status = statuses.READY
                draftState.restrictedAccess.error = false
                draftState.restrictedAccess.data = payload
                break
            case types.GET_RESTRICTED_ACCESS_ERROR:
                draftState.restrictedAccess.status = statuses.ERRORED
                draftState.restrictedAccess.error = error
                break

            case types.GET_STAFF:
                draftState.staff.status = statuses.LOADING
                draftState.staff.error = false
                break

            case types.GET_STAFF_SUCCESS:
                draftState.staff.status = statuses.READY
                draftState.staff.error = false
                draftState.staff.data = payload
                break

            case types.GET_STAFF_ERROR:
                draftState.staff.status = statuses.ERRORED
                draftState.staff.error = error
                break

            case types.INVITE_STAFF:
                draftState.staff.status = statuses.LOADING
                draftState.staff.error = false
                break

            case types.INVITE_STAFF_SUCCESS:
                draftState.staff.status = statuses.READY
                draftState.staff.error = false
                payload && draftState.staff.data.push(payload)
                break

            case types.INVITE_STAFF_ERROR:
                draftState.staff.status = statuses.ERRORED
                draftState.staff.error = error
                break


            case types.REMOVE_STAFF:
                draftState.staff.status = statuses.LOADING
                draftState.staff.error = false
                break

            case types.REMOVE_STAFF_SUCCESS:
                draftState.staff.status = statuses.READY
                draftState.staff.error = false
                draftState.staff.data = state.staff.data.filter(
                    ({ id }) => id !== payload
                )
                break

            case types.REMOVE_STAFF_ERROR:
                draftState.staff.status = statuses.ERRORED
                draftState.staff.error = error
                break

            case types.GET_MY_HIDDEN_ELEMENTS:
                draftState.hiddenElements.status = statuses.LOADING
                draftState.hiddenElements.error = false
                break

            case types.GET_MY_HIDDEN_ELEMENTS_SUCCESS:
                draftState.hiddenElements.status = statuses.READY
                draftState.hiddenElements.error = false
                draftState.hiddenElements.data = payload
                break

            case types.GET_MY_HIDDEN_ELEMENTS_ERROR:
                draftState.hiddenElements.status = statuses.ERRORED
                draftState.hiddenElements.error = error
                break

            case types.SAVE_HIDDEN_ELEMENTS:
                draftState.hiddenElements.status = statuses.LOADING
                draftState.hiddenElements.error = false
                break

            case types.SAVE_HIDDEN_ELEMENTS_SUCCESS:
                draftState.hiddenElements.status = statuses.READY
                draftState.hiddenElements.error = false
                draftState.hiddenElements.data = payload
                break

            case types.SAVE_HIDDEN_ELEMENTS_ERROR:
                draftState.hiddenElements.status = statuses.ERRORED
                draftState.hiddenElements.error = error
                break

            case types.UPDATE_FREE_ACCESS:
            case types.GET_FREE_ACCESS:
            case types.ARCHIVE_FREE_ACCESS:
            case types.UNARCHIVE_FREE_ACCESS:
                draftState.freeAccess.status = statuses.LOADING
                draftState.freeAccess.error = false
                break

            case types.ARCHIVE_FREE_ACCESS_SUCCESS:
                index = draftState.freeAccess.data.findIndex((freeAccessRule) => freeAccessRule.id === payload.id)

                // because archiving is a delete, we only get success: true from the response
                draftState.freeAccess.data[index] = {
                    ...draftState.freeAccess.data[index],
                    archived: true
                }
                draftState.freeAccess.status = statuses.READY
                break

            case types.UNARCHIVE_FREE_ACCESS_SUCCESS:
                index = draftState.freeAccess.data.findIndex((freeAccessRule) => freeAccessRule.id === payload.id)

                draftState.freeAccess.data[index] = {
                    ...payload
                }
                draftState.freeAccess.status = statuses.READY
                break

            case types.UPDATE_FREE_ACCESS_SUCCESS:
            case types.GET_FREE_ACCESS_SUCCESS:
                draftState.freeAccess.status = statuses.READY
                draftState.freeAccess.error = false
                draftState.freeAccess.data = payload
                break

            case types.UPDATE_FREE_ACCESS_ERROR:
            case types.GET_FREE_ACCESS_ERROR:
            case types.ARCHIVE_FREE_ACCESS_ERROR:
            case types.UNARCHIVE_FREE_ACCESS_ERROR:
                draftState.freeAccess.status = statuses.ERRORED
                draftState.freeAccess.error = error
                break

            case types.SAVE_DEFAULT_OFFERS:
                draftState.defaultOffers.status = statuses.LOADING
                draftState.defaultOffers.error = false
                break

            case types.SAVE_DEFAULT_OFFERS_SUCCESS:
                draftState.defaultOffers.status = statuses.READY
                draftState.defaultOffers.error = false
                break

            case types.SAVE_DEFAULT_OFFERS_ERROR:
                draftState.defaultOffers.status = statuses.ERRORED
                draftState.defaultOffers.error = error
                break

            case types.SAVE_ARTICLE_STRUCTURE:
                draftState.articleStructure.status = statuses.LOADING
                draftState.articleStructure.error = false
                break

            case types.SAVE_ARTICLE_STRUCTURE_SUCCESS:
                draftState.articleStructure.status = statuses.READY
                draftState.articleStructure.error = false
                break

            case types.SAVE_ARTICLE_STRUCTURE_ERROR:
                draftState.articleStructure.status = statuses.ERRORED
                draftState.articleStructure.error = error
                break

            case types.RESET_BILLING_ERROR:
                draftState.billingInformation.error = false
                break

            default:
                return state
        }
    })

export default reducer
