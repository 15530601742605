import produce from 'immer'

export const initialState = {
    status: 'INITIATED',
    error: false,
    pricing: {
        dashboardPricing: {},
        applicationFees: {}
    }
}

const reducer = (state, action, { types, statuses }) =>
    produce(state, (draftState) => {
        switch (action.type) {

            //loading
            case types.GET_DASHBOARD_PRICING:
            case types.SAVE_DASHBOARD_PRICING:
            case types.GET_APPLICATION_FEES:
            case types.SAVE_APPLICATION_FEES:
                draftState.status = statuses.LOADING
                draftState.error = false
                break

                // error
            case types.GET_DASHBOARD_PRICING_ERROR:
            case types.SAVE_DASHBOARD_PRICING_ERROR:
            case types.GET_APPLICATION_FEES_ERROR:
            case types.SAVE_APPLICATION_FEES_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = action.error
                break

                // update dashboard pricing payload
            case types.GET_DASHBOARD_PRICING_SUCCESS:
            case types.SAVE_DASHBOARD_PRICING_SUCCESS:
                draftState.status = statuses.READY
                draftState.pricing.dashboardPricing = action.payload
                break

                // update application fees payload
            case types.GET_APPLICATION_FEES_SUCCESS:
            case types.SAVE_APPLICATION_FEES_SUCCESS:
                draftState.status = statuses.READY
                draftState.pricing.applicationFees = action.payload
                break


            default:
                return state
        }
    })

export default reducer
