/** @namespace FeaturedCreatorsFeature.FeaturedCreatorsSlice */

import { createSlice } from '@reduxjs/toolkit'

import {
    fetchFeaturedCreators,
} from './thunks'

export const name = 'featuredCreators'

export const initialState = {
    data: [],
    loading: false,
    error: null,
}

const featuredCreatorsSlice = createSlice({
    name,
    initialState,
    extraReducers: {
        [fetchFeaturedCreators.pending]: (state) => {
            state.error = null
            state.loading = true
        },
        [fetchFeaturedCreators.fulfilled]: (state, { payload }) => {
            state.data = payload
            state.error = null
            state.loading = false
        },
        [fetchFeaturedCreators.rejected]: (state, { payload }) => {
            state.error = payload
            state.loading = false
        },
    }
})

export const featuredCreatorsSliceInitialState = {
    [name]: initialState,
}

export const featuredCreatorsSliceReducer = {
    [name]: featuredCreatorsSlice.reducer,
}

export default featuredCreatorsSlice.reducer
