import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => [
    takeEvery(types.GET_PRODUCT, sagas.getProduct),
    takeEvery(types.GET_PRODUCTS, sagas.getProducts),
    takeEvery(types.GET_PRODUCT_SIBLINGS, sagas.getProductSiblings),
    takeEvery(types.CREATE_PRODUCT, sagas.createProduct),
    takeEvery(types.UPDATE_PRODUCT, sagas.updateProduct),
    takeEvery(types.DELETE_PRODUCT, sagas.deleteProduct),
    takeEvery(types.GET_GROUPS, sagas.getGroups),
    takeEvery(types.CREATE_GROUP, sagas.createGroup),
    takeEvery(types.UPDATE_GROUP, sagas.updateGroup),
    takeEvery(types.DELETE_GROUP, sagas.deleteGroup),
    takeEvery(types.CHECK_EDIT_PRODUCT, sagas.checkEditProduct)
]

export default takes
