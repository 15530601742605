import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
function* deleteWebhooks({ types, payload }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: endpoints.WEBHOOKS + `/${payload.id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...payload
                }
            }
        })

        yield put({
            type: types.DELETE_WEBHOOK_SUCCESS,
            payload: payload
        })

        yield put(
            AppModule.creators.addNotification({
                message: 'Webhook successfully deleted',
                type: 'success'
            })
        )
    } catch (e) {
        yield put({ type: types.DELETE_WEBHOOK_ERROR, e })
    }
}

export default deleteWebhooks
