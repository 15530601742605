import {
    name,
    endpoints,
    getPublisherId,
} from '../dependencies'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import { createAsyncThunk } from '@reduxjs/toolkit'
import AppModule from '../../../modules/app'

const requestOneTimeCode = createAsyncThunk(
    `${name}/requestOneTimeCode`,
    async (phone: string, thunkAPI) => {
        const { getState, dispatch } = thunkAPI
        try {
            const publisherId = getPublisherId(getState())
            
            if (!publisherId) {
                throw new Error('publisherId not provided')
            }

            if (!phone) {
                throw new Error('phone not provided')
            }
            
            const response = await dispatch({
                type: REQUEST,
                config: {
                    method: 'POST',
                    url: endpoints.REQUEST_CODE,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid: publisherId,
                    },
                    data: {
                        phone,
                        verify_flow_id: 'request_otp'
                    }
                }
            })
            
            const { status, data } = response

            if (status === 200) {
                dispatch(
                    AppModule.creators.addNotification({
                        message: 'The code has been sent successfully',
                        type: 'success'
                    })
                )
                return data
            } else {
                throw new Error('Requesting code failed')
            }
            
        } catch (error) {
            throw new Error(error.response.data.message || error)
        }
    }
)

export default requestOneTimeCode
