export const freeUsers = 500

export const getUserAllowance = (paying, userChunks) => {
    if (!paying || userChunks === 1) {
        return freeUsers
    } else if (userChunks > 100) {
        return 50000 + 2000*(Math.ceil((userChunks-100)/4))
    } else if (userChunks > 10) {
        return 5000 + 1000*(Math.ceil((userChunks-10)/2))
    } else if (userChunks > 1) {
        return 500*userChunks
    } else {
        return freeUsers
    }
}

export const getMonthlyPrice = (userChunks) => {
    if (userChunks > 100) {
        return 275 + 5*(Math.ceil((userChunks-100)/4))
    } else if (userChunks > 10) {
        return 50 + 5*(Math.ceil((userChunks-10)/2))
    } else if (userChunks > 1) {
        return 5*userChunks
    } else {
        return 0
    }
}


export const calculateHypePluginPricing = (contacts) => {
    const contactChunks = Math.ceil(contacts / 500)
    if (contactChunks > 100) {
        return 275 + 5 * Math.ceil((contactChunks - 100) / 4)
    } else if (contactChunks > 10) {
        return 50 + 5 * Math.ceil((contactChunks - 10) / 2)
    } else if (contactChunks > 1) {
        return 5 * contactChunks
    } else {
        return 0
    }
}

export const getUserChunks = (users) => Math.ceil(users/500)
