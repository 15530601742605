import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import style from './Container.module.scss'

import { Box } from '@piconetworks/ui'

const Container = ({ className = null, ...props}) => {
    return (
        <Box.ContentPane.Container className={cx(style.container, className)} {...props}>
            {props.children}
        </Box.ContentPane.Container>
    )
}

Container.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    flexDirection: PropTypes.string,
    id: PropTypes.string,
}

export default Container
