import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ProgressBar from '@piconetworks/pkg-progress-bar'
import Loading from '@piconetworks/pkg-loading'

import styles from './PlanCreditSummary.module.scss'

import {
    fetchPlanCredits,

    getTotalCreditsByType,
    getUsedPlanCreditsByTypeAsPercent,
    getUsedCreditsByType,

    getFetchPlanCreditsInProgress,
    getFetchPlanCreditsError,
} from '../slice'
import MessagingUpgrade from 'containers/Workflows/Links/MessagingUpgrade'

const PlanCreditSummary = ({
    creditType = null,
    showMessagingUpgrade = false,
    notEnoughCredit = false
}) => {
    const dispatch = useDispatch()

    const totalPlanCredits = useSelector((state) => getTotalCreditsByType(state, creditType))
    const usedPlanCreditsAsPercent = useSelector((state) => getUsedPlanCreditsByTypeAsPercent(state, creditType))
    const usedPlanCredits = useSelector((state) => getUsedCreditsByType(state, creditType))

    const fetchPlanCreditsInProgress = useSelector(getFetchPlanCreditsInProgress)
    const fetchPlanCreditsError = useSelector(getFetchPlanCreditsError)

    const showLoading = !(
        Number.isInteger(totalPlanCredits)
        && Number.isInteger(usedPlanCredits)
    ) || fetchPlanCreditsInProgress

    useEffect(() => {
        dispatch(fetchPlanCredits())
    }, [])
    const formattedCreditAmount = Intl.NumberFormat('en-US').format(Number(totalPlanCredits))

    return (
        <div className={styles.planCreditSummary}>

            {showLoading && <Loading />}
            <div className={styles.planCredits}>
                {formattedCreditAmount} {creditType === 'sms' ? 'texts ' : 'credits '}
                per month with your plan
            </div>
            <div className={styles.progressBar}>
                <ProgressBar
                    collection={[
                        {
                            key: 'ok',
                            minPercentage: 0,
                            maxPercentage: 75,
                            message: '',
                            color: '#7A34FF'
                        },
                        {
                            key: 'close',
                            minPercentage: 99,
                            maxPercentage: 75,
                            message: '',
                            color: '#FCAF2F'
                        },
                        {
                            key: 'danger',
                            minPercentage: 100,
                            maxPercentage: 100,
                            message: '',
                            color: '#FF0000'
                        }
                    ]}
                    percentage={usedPlanCreditsAsPercent}
                    showStrength={false}
                />
            </div>
            <div className={styles.planCreditsUsed}>{usedPlanCredits} of {formattedCreditAmount} texts sent</div>
            {fetchPlanCreditsError && <div style={{ color: 'red' }}>{fetchPlanCreditsError}</div>}

            {showMessagingUpgrade && <MessagingUpgrade notEnoughCredit={notEnoughCredit} hasAccess={!!(Number(usedPlanCredits) + Number(totalPlanCredits))} />}
        </div>
    )
}

export default PlanCreditSummary
