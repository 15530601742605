import React from 'react'
import PropTypes from 'prop-types'
import { RegisterPhoneNumber, RegisteredPhoneNumber, ConfirmBusinessInformation } from 'features/registerPhoneNumber'
import Wizard from 'features/wizard'
import { OTP } from 'features/verifyWithOtp'

const RegisterPhoneNumberForm = ({
    setIsOpen,
    handleSubmit
}) => {
    return (
        <Wizard.Context.Provider onSubmit={() => {
            handleSubmit()
        }}>
            <Wizard.Step title='Confirm Business Information' setIsOpen={setIsOpen}>
                <ConfirmBusinessInformation />
            </Wizard.Step>
            <Wizard.Step title='Verify Your Phone Number' setIsOpen={setIsOpen}>
                <OTP />
            </Wizard.Step>
            <Wizard.Step title='Select Preferred Area Code' setIsOpen={setIsOpen}>
                <RegisterPhoneNumber />
            </Wizard.Step>
            <Wizard.Step title='Your Hype Phone Number' setIsOpen={setIsOpen}>
                <RegisteredPhoneNumber />
            </Wizard.Step>
        </Wizard.Context.Provider>
    )
}

RegisterPhoneNumberForm.propTypes = {
    handleSubmit: PropTypes.func,
    setIsOpen: PropTypes.func,
}

export default React.memo(RegisterPhoneNumberForm)
