import { call, select } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, getPublisherId } from '../dependencies'

const updateUnclaimedGym = createAsyncSaga(
    `${name}/updateUnclaimedGym`,
    function* ({
        fulfilled,
        rejected,
        request,
        payload: {
            instagram = null,
            tiktok = null,
            email = null,
            facebook = null
        } = {},
    }) {
        try {
            const publisherId = yield call(getPublisherId)

            if (!publisherId) {
                throw Error('publisherid must be provided')
            }

            const { data } = yield request({
                method: 'put',
                baseURL: endpoints.API_ENDPOINT,
                url: '/sales/unclaimed',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: {
                    ...(instagram && { instagram }),
                    ...(facebook && { facebook }),
                    ...(email && { email }),
                    ...(tiktok && { tiktok }),
                }
            })

            yield fulfilled(fulfilled)

        } catch (error) {
            yield rejected({
                error: error.message,
            })
        }
    }
)

export default updateUnclaimedGym
