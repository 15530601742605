const actions = {}

actions.types = [
    'GET_OFFERS',
    'GET_OFFERS_SUCCESS',
    'GET_OFFERS_ERROR',

    'GET_OFFER',
    'GET_OFFER_SUCCESS',
    'GET_OFFER_ERROR',

    'CREATE_OFFER',
    'CREATE_OFFER_SUCCESS',
    'CREATE_OFFER_ERROR',

    'UPDATE_OFFER',
    'UPDATE_OFFER_SUCCESS',
    'UPDATE_OFFER_ERROR',

    'DELETE_OFFER',
    'DELETE_OFFER_SUCCESS',
    'DELETE_OFFER_ERROR',

    'DUPLICATE_OFFER',
    'DUPLICATE_OFFER_SUCCESS',
    'DUPLICATE_OFFER_ERROR',

    'RESTORE_OFFER',
    'RESTORE_OFFER_SUCCESS',
    'RESTORE_OFFER_ERROR',

    'GET_CONVERSION_RATE',
    'GET_CONVERSION_RATE_SUCCESS',
    'GET_CONVERSION_RATE_ERROR',

    'SORT_ORDER',
    'SORT_ORDER_SUCCESS',
    'SORT_ORDER_ERROR',
]

actions.creators = ({ types }) => ({
    getOffers: (payload) => ({ type: types.GET_OFFERS, payload }),
    // Get single offer with offer id
    getOffer: (payload) => ({ type: types.GET_OFFER, payload }),
    getConversionRate: () => ({ type: types.GET_CONVERSION_RATE }),
    createOffer: (payload, callback, duplicate = false) => ({ type: types.CREATE_OFFER, payload, callback, duplicate }),
    updateOffer: (payload, callback) => ({ type: types.UPDATE_OFFER, payload, callback }),
    deleteOffer: (payload) => ({ type: types.DELETE_OFFER, payload }),
    restoreOffer: (payload, callback) => ({ type: types.RESTORE_OFFER, payload, callback }),
    sortOrder: (payload) => ({ type: types.SORT_ORDER, payload }),
    duplicateOffer: (payload) => ({ type: types.DUPLICATE_OFFER, payload })
})

export default actions
