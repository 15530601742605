import 'glider-js/glider.min.css'

import React, { useEffect } from 'react'
import Glider from 'react-glider'
import { Field } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'

import { Option, themeConstants, usePublisherId } from '../dependencies'
import { getAllThemes, readAllDisplayableThemes } from '../slice'

import LockIcon from 'containers/Offers/LPEditor/DesignTab/LockIcon'

import styles from './ThemeListing.module.scss'

const FillImage = ({ img = null, children = null }) => {
    return (
        <div
            style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                ...(img && { background: `url("${img}") 50% 50% / cover no-repeat` }),
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            {children}
        </div>
    )
}

const ThemeItem = ({
    img = null,
    onClick = () => null,
}) => {
    const hideLock = true

    return (
        <div
            style={{
                width: '100%',
                paddingTop: 'calc(800%/5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
            }}
            onClick={onClick}
        >
            <FillImage img={img} />
            <LockIcon hide={hideLock} />
        </div>
    )
}

const Themes = ({
    onThemeItemClick = (id = null) => {},
    fieldName = 'theme_id',
    value,
}) => {
    const dispatch = useDispatch()

    const displayableThemes = useSelector(readAllDisplayableThemes)

    const publisherId = usePublisherId()

    useEffect(() => {
        dispatch(getAllThemes({ publisherId }))
    }, [dispatch])

    if (!displayableThemes || displayableThemes.length === 0) {
        return null
    }

    return (
        <>
            <div className={styles.themeListing}>
                <Glider
                    draggable
                    slidesToShow={3}
                    slidesToScroll={1}
                    dragVelocity={1.5}
                >
                    {displayableThemes?.map((themeItem, index) => {
                        const { id, name } = themeItem

                        const constantData = themeConstants.find(({ name: n }) => n === name)
                        const imageUrl = constantData?.imageUrl

                        return (
                            <div style={{width: '138px', padding: '4px'}} key={index}>
                                <Field
                                    name={fieldName}
                                    key={index}
                                    type="radio"
                                    value={id}
                                    render={({ input }) => {
                                        return (
                                            <Option disabled={id === value ? false : true} input={input}>
                                                <ThemeItem
                                                    img={imageUrl}
                                                    onClick={() => onThemeItemClick(themeItem)}
                                                />
                                            </Option>
                                        )
                                    }}
                                />
                            </div>
                        )
                    })}
                </Glider>
            </div>
        </>
    )
}

export default Themes
