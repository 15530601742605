import { takeEvery } from 'redux-saga/effects'

const takes = (duck) => ([
    takeEvery(duck.types.GET_NEWSLETTERS, duck.sagas.getNewsletters),
    takeEvery(duck.types.CREATE_NEWSLETTER, duck.sagas.createNewsletter),
    takeEvery(duck.types.UPDATE_NEWSLETTER, duck.sagas.updateNewsletter),
    takeEvery(duck.types.DELETE_NEWSLETTER, duck.sagas.deleteNewsletter),
    takeEvery(duck.types.SORT_NEWSLETTERS_ORDER, duck.sagas.sortNewslettersOrder),
])

export default takes
