import { putResolve, put, select, call } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* fetchSelectedUsers(action) {
    const { payload, duck } = action

    const publisherid = yield select(AccountModule.selectors.getPublisherId)
    const params = {
        filtered: {
            page: 0,
            size: payload.length,
            anyOrAll: 'all',
            filters: [{
                filter_type: 'ids',
                filter_value: payload
            }]
        }
    }

    try {
        let response = []
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: `${endpoints.USERS}/search`,
                timeout: 30000,
                data: params,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })
        payload.forEach((user) => {
            data.users.find((item) => {
                if (item.user_state_id === user) {
                    response.push(item)
                }
            })
        })

        yield put({
            type: duck.types.FETCH_SELECTED_USERS_SUCCESS,
            payload: response
        })

        if (payload.length === 1) {
            yield call(duck.sagas.fetchSelectedUserActivity, { duck,
                payload: {
                    user_state_id: payload[0]
                }
            })
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.FETCH_SELECTED_USERS_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.FETCH_SELECTED_USERS_ERROR,
                error
            })
        }
    }
}

export default fetchSelectedUsers
