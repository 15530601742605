import { useCallback, useState } from "react"

import usePlatformAccessPaywall from "./usePlatformAccessPaywall"
import { MessagesModule, constants, useFeatureManager } from "../dependencies"
import { useDispatch, useSelector } from "react-redux"

const useMessagingPaywall = () => {
    const { getAppTierByLabel, hasFeature } = useFeatureManager()
    const hasMessagingFeature = hasFeature(constants.MESSAGING_FEATURE)

    const hasHypePro = getAppTierByLabel(constants.HYPE_PRO_TIER)?.has_access

    const hasAccessToBlastsForm = (hasMessagingFeature || hasHypePro) || (
        hasFeature(constants.MESSAGE_WRITE_EMAIL_FEATURE)
        || hasFeature(constants.MESSAGE_WRITE_SMS_FEATURE)
    )

    const { openPlatformAccessPaywall, Paywall } = usePlatformAccessPaywall()

    const openPlatformAccessPaywallForMessaging = useCallback((type) => {
        if (type === 'email') {
            openPlatformAccessPaywall(constants.MESSAGE_WRITE_EMAIL_FEATURE)
        } else if (type === 'sms') {
            openPlatformAccessPaywall(constants.MESSAGE_WRITE_SMS_FEATURE)
        } else {
            openPlatformAccessPaywall(constants.MESSAGE_WRITE_EMAIL_FEATURE)
        }
    }, [ openPlatformAccessPaywall])

    return {
        hasAccessToBlastsForm,
        openPlatformAccessPaywallForMessaging,
        PaywallForMessaging: Paywall,
    }
}

export default useMessagingPaywall
