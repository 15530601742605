import {
    initialState,
    reducer,
    takes,
    fetchEditions,
    upsertEdition,
    fetchEditionById,
    selectById,
    editions,
} from './slice'

import useEditions from './useEditions'

export default {
    initialState,
    reducer,
    takes,
}

export {
    useEditions,
    fetchEditions,
    upsertEdition,
    fetchEditionById,
    selectById,
    editions,
}
