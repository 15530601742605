import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

import requestPhoneNumber from './requestPhoneNumber'

const initialState = {
    requestInProgress: false,
    requestError: null,
    phoneNumber: null,
    smsEnabledByVerifyCall: null
}

const registerPhoneNumberSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetRegisterPhoneNumber: (draftState: any) => {
            draftState.requestInProgress = false
            draftState.requestError = null
            draftState.phoneNumber = null
            draftState.smsEnabledByVerifyCall = null
        }
    },
    extraReducers: builder => {
        builder.addCase(requestPhoneNumber.pending, (state) => {
            state.phoneNumber = null
            state.smsEnabledByVerifyCall = null
            state.requestInProgress = true
            state.requestError = null
        })
        .addCase(requestPhoneNumber.fulfilled, (state: any, { payload }: any) => {
            state.phoneNumber = payload?.number
            state.smsEnabledByVerifyCall = payload?.sms_auth_enabled
            state.requestInProgress = false
            state.requestError = null
        })
        .addCase(requestPhoneNumber.rejected, (state: any, { payload: error }: any) => {
            state.phoneNumber = null
            state.smsEnabledByVerifyCall = null
            state.requestInProgress = false
            state.requestError = error
        })
    },
})

// SELECTORS
const getRegisterPhoneNumberState = (state: any) => state[name]
const getRequestError = createDraftSafeSelector(
    getRegisterPhoneNumberState,
    (registerPhoneNumber: any) => registerPhoneNumber?.requestError,
)
const getRequestInProgress = createDraftSafeSelector(
    getRegisterPhoneNumberState,
    (registerPhoneNumber: any) => registerPhoneNumber?.requestInProgress,
)
const getPhoneNumberFromVerify = createDraftSafeSelector(
    getRegisterPhoneNumberState,
    (registerPhoneNumber: any) => registerPhoneNumber?.phoneNumber,
)
const getSmsEnabledByVerify = createDraftSafeSelector(
    getRegisterPhoneNumberState,
    (registerPhoneNumber: any) => registerPhoneNumber?.smsEnabledByVerifyCall
)

// GENERAL

const registerPhoneNumberInitialState = {
    [name]: initialState,
}

const registerPhoneNumberReducer = {
    [name]: registerPhoneNumberSlice.reducer,
}

const registerPhoneNumberActions = {
    ...registerPhoneNumberSlice.actions
}

// EXPORTS
export default registerPhoneNumberSlice.reducer

export {
    registerPhoneNumberInitialState as initialState,
    registerPhoneNumberReducer as reducer,
    registerPhoneNumberActions as actions,
    requestPhoneNumber,
    
    getRequestError,
    getRequestInProgress,
    getPhoneNumberFromVerify,
    getSmsEnabledByVerify
}
