import { put, putResolve, select, all } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

import logger from 'lib/logger'

function* getConversationBatch({
    types,
    payload: {
        conversationIds = []
    } = {}
}) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const responses = yield all(conversationIds.map(function* (conversationId) {
            try {
                const response = yield putResolve({
                    type: REQUEST,
                    config: {
                        method: 'GET',
                        url: `${endpoints.CONVERSATIONS}/thread/${conversationId}`,
                        timeout: 30000,
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            publisherid,
                        },
                    },
                })

                // Example response: {
                //  broadcast_id: "0147e601-c6d2-498e-88bf-a4869afc4157"
                //  id: "004a3a4e-c8c2-46a6-a92a-13ab32ec58cb"
                //  messages: [{
                //  body: "This is a great blast!"
                //  failed_at: null
                //  failed_reason: null
                //  id: "8222da55-dfac-48bf-80c3-43e9ba62d17e"
                //  image_url: null
                //  sent_at: "2022-03-24T19:27:39.000Z"
                //  type: "sms"
                //  user: {
                //      avatar: "https://lh4.googleusercontent.com/-hNpwb1pz9n4/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rfaQKSclI-jp5ZTwOgdpEiON5q_3w/s100/photo.jpg=s256"
                //      first_name: "Inspector"
                //      id: "6ed1abd6-ddaf-11ea-9ce7-4b3e3513dbf9"
                //      last_name: "Gadget"
                //   }
                // }]
                // user_state_id: "74b875fd-50f9-40ce-bd86-53fc81601941"
                return response
            } catch (error) {
                logger.error('getConversationBatch error', {}, error)
                return null
            }
        }))

        yield put({
            type: types.GET_CONVERSATION_BATCH_SUCCESS,
            payload: responses.filter(response => !!response).map(({ data }) => data),
        })
    } catch (e) {
        logger.error('getConversationBatch error', {}, e)
        yield put({
            type: types.GET_CONVERSATION_BATCH_ERROR,
            error: e
        })
    }
}

export default getConversationBatch
