import Duck from 'extensible-duck'
import { createSelector } from 'reselect'
import { HYPE_PAGE_PREFIX, getLandingPageBaseUrl } from 'utils/constants/domainTypes'

const selectors = ({ store, statuses }) => ({
    getAccount: (state) => state[store] || {},
    getLoading: (state) => state[store].status === statuses.LOADING || state[store].status === null,
    getAccountInfo: (state) => state[store]?.info,
    getContentCategories: (state) => state[store].info.content_categories,
    getContributionType: (state) => state[store].info.contribution_type,
    getRole: (state) => state[store].info?.publisher_users?.[0]?.role || null,
    getCreatedAt: (state) => state[store].info.createdAt,
    getPublisherId: (state) => state[store].publisherId,
    getPublisherName: (state) => state[store].info.name,
    getPublisherOwner: (state) => state[store].info.name,
    getAccessTier: (state) => state[store].info.tier,
    getUsername: (state) => state[store].info.username,
    getPageUrl: (state) => {
        return `${getLandingPageBaseUrl()}/${HYPE_PAGE_PREFIX}${state[store].info.username}`
    },
    getUserId: (state) => state[store].info.userid,
    getVcardUrl: (state) => state[store].info.vcard_url,
    getPhoneNumber: new Duck.Selector((selectors) => (state) => {
        const accountInfo = selectors.getAccountInfo(state)
        return accountInfo?.phone_numbers?.[0]?.number
    }),
    hasPhoneNumber: new Duck.Selector((selectors) => (state) => {
        const accountInfo = selectors.getAccountInfo(state)
        return !!accountInfo?.phone_numbers?.[0]?.number
    }),
    getDefaultLandingPageId: new Duck.Selector((selectors) => (state) => {
        const accountInfo = selectors.getAccountInfo(state)
        return accountInfo?.default_landing_page_id
    }),
    getContributionWording: new Duck.Selector((selectors) => (state) => {
        const accountInfo = selectors.getAccountInfo(state)
        return accountInfo?.contribution_type
    }),
    useTips: new Duck.Selector((selectors) => (state) => {
        const _getContributionWording = selectors.getContributionWording(state)
        return _getContributionWording === 'tip'
    }),
    // if creator billing flag is on,
    // uses app monetization data
    // from featureManager hook and will return false
    // otherwise will use tier data
    getIsTierOne: new Duck.Selector(() =>
        createSelector(
            (state) => state[store].info.tier === 'tier_1',
            (state) => (state?.featureFlags?.all_flags || {})['creator-billing'],
            (isTierOne, isCreatorBillingEnabled) => {
                return isCreatorBillingEnabled ? false : isTierOne
            }
        )),
})

export default selectors
