import AccountModule from 'modules/account'
import setupPicoLinkActionsModule from 'modules/setup-pico-link-actions'
import AnalyticsModule from 'modules/analytics'

import endpoints from 'utils/lib/endpoints'
import ErrorBoundary from 'containers/ErrorBoundary'

/**
 * Slice dependencies consists of imports that are external to the slice.
 * This is useful for importing modules that are not part of the slice itself.
 * @name Dependencies
 * @memberof SetupPicoLinkFeature.SetupPicoLinkSlice
 */

const selectors = {
    getPublisherId: AccountModule.selectors.getPublisherId,
    getDefaultLandingPageId: AccountModule.selectors.getDefaultLandingPageId,
    getUsername: AccountModule.selectors.getUsername,
}

const creators = {
    sendEvent: AnalyticsModule.creators.sendEvent,
}

const Components = {
    ErrorBoundary,
}

const API_URL = endpoints.PROFILE_ACTION_EVENTS

const API = {
    API_URL,
}

export {
    API,
    selectors,
    creators,
    Components,
    setupPicoLinkActionsModule
}
