import { put, putResolve } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'

function* getMyHiddenElements({ types }) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.HIDDEN_ELEMENTS,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                timeout: 30000
            }
        })
        yield put({
            type: types.GET_MY_HIDDEN_ELEMENTS_SUCCESS,
            payload: {
                ...data.body
            }
        })
    } catch (e) {
        yield put({ type: types.GET_MY_HIDDEN_ELEMENTS_ERROR, error: e})
    }
}

export default getMyHiddenElements
