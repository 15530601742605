import { tagGenerator } from '@piconetworks/tag-generator'

const useThemePreviewStyles = ({
    theme_id = '',
    isPreview = true,
    isThemePreview = true,
    theme_customizations = [],
    ...rest
}): any => {
    let themePreviewStyles = ''
    let TagsToRender = null
    let rootCssVariables = ''
    let videoTagUrl = ''


    const styleObject = tagGenerator({
        id: theme_id, 
        type: 'theme', 
        customizations: theme_customizations, 
        isPreview, 
        isThemePreview
    })

    themePreviewStyles += styleObject?.styles

    if (styleObject?.videoTagUrl) {
        videoTagUrl = styleObject?.videoTagUrl
    }

    // const themeSerializer = styleObject?.serializer
    // console.log('useThemePreviewStyles themeSerializer', themeSerializer)

    TagsToRender = videoTagUrl.length > 0 ? (
        <>
            <video autoPlay muted loop className='page' webkit-playsInline playsInline>
                <source src={videoTagUrl} type="video/mp4" />
            </video>
            {/* override the editor preview background color and position 
                the video within the theme preview  */}
            <style>
                {
                    `#theme-preview #preview video {
                        position: absolute !important;
                        z-indez: 999;
                    }
                    #theme-preview  .page {
                        background: none !important;
                    }`
                }
            </style>
        </>
    ) : null;

    rootCssVariables = `#theme-preview #preview {
        --block-shadow: none;
        --block-border-style: none;
        --block-border-color: transparent;
        --block-border-width: 0;
        --block-border-radius: 0.5rem;
        --page-font-body: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
        --page-font-heading: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
        --page-background-color: #834EFF;
        --page-contrast-color: #FFFFFF;
        --page-button-contrast-color: #000000;
        --block-background-color: #FFFFFF;
        --block-contrast-color: #000000;
        --block-button-contrast-color: #FFFFFF;
        --blockTextColorRgb: var(--block-contrast-color);
    }`

        return {
            rootCssVariables,
            TagsToRender,
            themePreviewStyles,
        }
}

export default useThemePreviewStyles
