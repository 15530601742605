import produce from 'immer'

export const initialState = {
    loading: null,
    status: 'INITIATED',
    error: false,
    stripe: {},
    stripeExpressConfig: {
        loading: false,
        url: null,
        login: null
    },
    stripeExpress: {},
    esp:{
        status: 'INITIATED',
        lists: [],
        listId: null
    },
    wordpress: {},
    webhooks:{
        loading: false,
        status: 'INITIATED',
        error: false,
        data:[]
    },
    signupModeEnabled: false,
    zapier: {
        loading: false,
        status: 'INITIATED',
        error: false,
        data:{}
    }
}

const reducer = (state, { type, payload, error }, { initialState, types, statuses }) => {
    return produce(state, draftState => {
        switch (type) {
            case types.UNINSTALL_INTEGRATION:
                draftState.loading = true
                draftState.status = statuses.LOADING
                draftState.error = false
                break
            case types.UNINSTALL_INTEGRATION_SUCCESS:
                draftState[payload.type] = initialState[payload.type]
                draftState.status = statuses.READY
                draftState.loading = false
                break
            case types.UNINSTALL_INTEGRATION_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = error
                break

            case types.GET_INTEGRATIONS:
                draftState.loading = true
                draftState.status = statuses.LOADING
                draftState.error = false
                break

            case types.GET_INTEGRATIONS_SUCCESS:
                draftState.loading = false
                draftState.status = statuses.READY
                draftState.error = false
                draftState.stripe = payload.stripe
                draftState.stripeExpress = {
                    ...payload.stripe_express
                }
                draftState.stripeExpressConfig = initialState.stripeExpressConfig
                draftState.esp = {
                    ...state.esp,
                    ...payload.esp,
                    listId: payload?.esp?.list_id || state.esp.listId
                }
                draftState.signupModeEnabled = payload.signupModeEnabled
                draftState.wordpress = payload.wordpress
                draftState.discord = payload.discord
                draftState.twitter = payload.twitter
                draftState.youtube = payload.youtube
                draftState.instagram = payload.instagram
                break

            case types.GET_INTEGRATIONS_ERROR:
                draftState.loading = false
                draftState.status = statuses.ERRORED
                draftState.error = error
                break

            case types.GET_WEBHOOKS:
            case types.SAVE_WEBHOOK:
            case types.DELETE_WEBHOOK:
                draftState.webhooks.loading = true
                draftState.webhooks.status = statuses.LOADING
                draftState.webhooks.error = false
                break

            case types.GET_WEBHOOKS_SUCCESS:
                draftState.webhooks.loading = false
                draftState.webhooks.error = false
                draftState.webhooks.status = statuses.READY
                draftState.webhooks.data = payload
                break

            case types.GET_WEBHOOKS_ERROR:
            case types.SAVE_WEBHOOK_ERROR:
            case types.DELETE_WEBHOOK_ERROR:
                draftState.webhooks.loading = false
                draftState.webhooks.status = statuses.ERRORED
                draftState.webhooks.error = error
                break

            case types.SAVE_WEBHOOK_SUCCESS:
                draftState.webhooks.status = statuses.READY
                draftState.webhooks.loading = false
                draftState.webhooks.error = false

                const index = draftState.webhooks.data.webhooks.findIndex((webhook) => payload.id === webhook.id)

                if (draftState.webhooks.data.webhooks[index]) {
                    draftState.webhooks.data.webhooks[index] = payload
                } else {
                    draftState.webhooks.data.webhooks.push(payload)
                }
                break

            case types.DELETE_WEBHOOK_SUCCESS:
                draftState.webhooks.loading = false
                draftState.webhooks.status = statuses.READY
                draftState.webhooks.error = false
                draftState.webhooks.data.webhooks.splice(draftState.webhooks.data.webhooks.findIndex((webhook) => payload.id === webhook.id), 1)
                break

            case types.GET_ZAPIER_KEY:
                draftState.zapier.loading = true
                draftState.zapier.status = statuses.LOADING
                draftState.zapier.error = false
                break

            case types.GET_ZAPIER_KEY_SUCCESS:
                draftState.zapier.loading = false
                draftState.zapier.status = statuses.READY
                draftState.zapier.error = false
                draftState.zapier.data = payload
                break

            case types.GET_ZAPIER_KEY_ERROR:
                draftState.zapier.loading = false
                draftState.zapier.status = statuses.ERRORED
                draftState.zapier.error = error
                break

            case types.GENERATE_ZAPIER_KEY:
                draftState.zapier.loading = true
                draftState.zapier.status = statuses.LOADING
                draftState.zapier.error = false
                break

            case types.GENERATE_ZAPIER_KEY_SUCCESS:
                draftState.zapier.loading = false
                draftState.zapier.status = statuses.READY
                draftState.zapier.error = false
                draftState.zapier.data = payload
                break

            case types.GENERATE_ZAPIER_KEY_ERROR:
                draftState.zapier.loading = false
                draftState.zapier.status = statuses.ERRORED
                draftState.zapier.error = error
                break

            case types.GET_ESP_LISTS:
                draftState.esp.status = statuses.LOADING
                draftState.error = false
                break

            case types.GET_ESP_LISTS_SUCCESS:
                draftState.esp.status = statuses.READY
                draftState.error = false
                draftState.esp.lists = payload.lists
                break

            case types.GET_ESP_LISTS_ERROR:
                draftState.esp.status = statuses.ERRORED
                draftState.error = error
                break

            case types.GET_ESP_FIELDS:
                draftState.esp.status = statuses.LOADING
                draftState.error = false
                break

            case types.GET_ESP_FIELDS_SUCCESS:
                draftState.esp.status = statuses.READY
                draftState.error = false
                draftState.esp.fields = payload.fields
                draftState.esp.listId = payload.listId
                break

            case types.GET_ESP_FIELDS_ERROR:
                draftState.esp.status = statuses.ERRORED
                draftState.error = error
                break

            case types.SAVE_ESP_FIELDS:
                draftState.esp.status = statuses.LOADING
                draftState.error = false
                break

            case types.SAVE_ESP_FIELDS_SUCCESS:
                draftState.esp.status = statuses.READY
                draftState.error = false
                draftState.esp.fields = payload
                break

            case types.SAVE_ESP_FIELDS_ERROR:
                draftState.esp.status = statuses.ERRORED
                draftState.error = error
                break

            case types.OPEN_UNINSTALL_MODAL:
                draftState.loading = true
                draftState.status = statuses.LOADING
                draftState.error = false
                break
                
            case types.START_STRIPE_EXPRESS:
                draftState.loading = true
                draftState.status = statuses.LOADING
                draftState.error = false
                draftState.stripeExpressConfig = {
                    loading: true
                }
                break

            case types.START_STRIPE_EXPRESS_SUCCESS:
                draftState.loading = false
                draftState.status = statuses.READY
                draftState.error = false
                draftState.stripeExpressConfig = {
                    ...draftState.stripeExpressConfig,
                    url: payload.url,
                    loading: false
                }
                break

            case types.START_STRIPE_EXPRESS_ERROR:
                draftState.loading = false
                draftState.status = statuses.ERRORED
                draftState.error = error
                draftState.stripeExpressConfig.loading = false
                break

            case types.LOGIN_STRIPE_EXPRESS:
                draftState.loading = true
                draftState.status = statuses.LOADING
                draftState.error = false
                draftState.stripeExpressConfig = {
                    loading: true
                }
                break

            case types.LOGIN_STRIPE_EXPRESS_SUCCESS:
                draftState.loading = false
                draftState.status = statuses.READY
                draftState.error = false
                draftState.stripeExpressConfig = {
                    ...draftState.stripeExpressConfig,
                    login: payload.url,
                    loading: false
                }
                break

            case types.LOGIN_STRIPE_EXPRESS_ERROR:
                draftState.loading = false
                draftState.status = statuses.ERRORED
                draftState.error = error
                draftState.stripeExpressConfig.loading = false
                break

            default:
                return state
        }
    })
}

export default reducer
