import Duck from 'extensible-duck'
import ProductsService from 'utils/services/Products'
import ProductsFormService from 'utils/services/Products/form'

const selectors = ({ store, statuses }) => {
    try {
        const Products = new ProductsService(store, statuses)
        const ProductsForm = new ProductsFormService()
        const getAllProducts = (state) => Products.getAll(state)
        return {
            getState: (state) => state[store],
            getStatus: (state) => state[store].status,
            getLoading: (state) => state[store].status === statuses.LOADING,
            getGroups: (state) => state[store].groups,
            productSiblings: (state) => state[store].siblings,
            getProductSiblingsInProgress: (state) => state[store].getProductSiblingsInProgress,
            getProductSiblingsSuccess: (state) => state[store].getProductSiblingsSuccess,
            getProductSiblingsError: (state) => state[store].getProductSiblingsError,
            getGroupsLoading: (state) => state[store].groups.status === statuses.LOADING,
            getEffectedUserBehaviors: (state, id) => Products.getEffectedUserBehaviours(state, id),
            // products
            getAllProducts,
            getAllProductsMap: (state) => {
                const allProducts = getAllProducts(state)
    
                return allProducts.reduce((acc, product) => {
                    acc[product.id] = product
                    return acc
                }, {})
            },
            getProductById: (state, id) => Products.getProductById(state, id),
            getSubscriptions: (state) => Products.getSubscriptions(state),
            getPasses: (state) => Products.getPasses(state),
            getDonationBoxes: (state) => Products.getDonationBoxes(state),
            getProductsInitiated: (state) => Products.getProductsInitiated(state),
            getProductsLoading: (state) => Products.getProductsLoading(state),
    
            getSelectedProduct: new Duck.Selector(() => (state, id) => {
                const products = Products.getAll(state)
                const selectedProduct = products.find((product) => product.id === id)
                return selectedProduct
            }),
    
            getInitialFormValues: (state, type, id) => ProductsForm.getInitialFormValues(state, type, id)
        }    
    } catch (e) {
        return {}
    }

}

export default selectors
