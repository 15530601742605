import ErrorBoundary from 'containers/ErrorBoundary'
import welcomeActionsModule from 'modules/welcome-actions'

const Components = {
    ErrorBoundary,
}

export {
    Components,
    welcomeActionsModule
}
