import { call, putResolve, put, select, take } from 'redux-saga/effects'
import Router from 'next/router'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'
import formatFormValues from 'containers/Offers/FormEditorWrapper/utils/formatFormValues'
import BlocksModule from 'modules/blocks'

function* duplicateOffer(action) {
    const { types, payload, selectors, creators } = action

    try {
        const { id } = payload

        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const accountInfo = yield select(AccountModule.selectors.getAccountInfo)

        yield put(creators.getOffer({ id, type: 'landing_pages' }))

        yield take(types.GET_OFFER_SUCCESS)

        yield take(BlocksModule.types.GET_BLOCKS_SUCCESS)

        const offer = yield select((state) => selectors.getInitialFormValues(state, 'landing_page', id))

        const { products, ...offerDup } = yield call(formatFormValues, {...offer }, accountInfo, false, true )

        // make sure no ids are sent in the payload in case this form is duplicated
        const payloadWithRemovedIds = JSON.parse(
            JSON.stringify(
                (offerDup),
                (key, val) => (key === 'id' || key === 'short_code') ? undefined : val
            )
        )

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.OFFERS,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    ...payloadWithRemovedIds,
                    products: products
                }
            }
        })

        const isPopup = data.type.includes('popup')

        yield put({
            type: types.CREATE_OFFER_SUCCESS,
            payload: {
                type: isPopup ? 'popups' : 'landing_pages',
                data
            }
        })


        yield put(
            AppModule.creators.addNotification({
                type: 'success',
                message:
                    `${isPopup ? 'Popup' : 'Page'} duplicated successfully.`
            })
        )

        if (isPopup) {
            yield call(Router.push, `/plugin/popups/${data.id}`, null, { shallow: true })
        } else {
            yield call(Router.push,`/pages/${data.id}`, null, { shallow: true })
        }

    } catch (error) {

        yield put(
            AppModule.creators.addNotification({
                message: 'There was a problem saving the offer',
                type: 'danger'
            })
        )

        if (error?.response?.data?.message) {
            yield put({
                type: types.CREATE_OFFER_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: types.CREATE_OFFER_ERROR,
                error
            })
        }
    }
}

export default duplicateOffer
