import {
    put, putResolve, select, call
} from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import { PICO_API_URL } from '@piconetworks/pkg-endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
import { FORM_ERROR } from 'final-form'

import logger from 'lib/logger'

function* triggerScraping({ payload, callback }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { instagram, tiktok, instagram_options=[], tiktok_options=[] } = payload

        if (!instagram && !tiktok) {
            throw Error('Either instagram or tiktok must be provided')
        }

        let instagram_job
        let tiktok_job
        if (instagram) {
            instagram_job = {
                social_handle: instagram,
                social_platform: 'instagram',
                ...(instagram_options).reduce((acc, val) => ({...acc, [val]: true}), {})
            }
        }
        if (tiktok) {
            tiktok_job = {
                social_handle: tiktok,
                social_platform: 'tiktok',
                ...(tiktok_options).reduce((acc, val) => ({...acc, [val]: true}), {})
            }
        }

        if (instagram_job) {
            yield putResolve({
                type: REQUEST,
                config: {
                    method: 'post',
                    baseURL: PICO_API_URL(),
                    url: '/scrapeyard',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid: publisherid,
                    },
                    timeout: 30000,
                    data: instagram_job,
                },
            })
        }

        if (tiktok_job) {
            yield putResolve({
                type: REQUEST,
                config: {
                    method: 'post',
                    baseURL: PICO_API_URL(),
                    url: '/scrapeyard',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid: publisherid,
                    },
                    timeout: 30000,
                    data: tiktok_job,
                },
            })
        }

        yield put(
            AppModule.creators.addNotification({
                type: 'success',
                message: 'Scraping jobs triggered successfully.'
            })
        )

    } catch (error) {
        console.log({error})
        if (error?.response?.data?.message) {
            yield put(
                AppModule.creators.addNotification({
                    type: 'danger',
                    message: 'Something went wrong during the scraping job trigger.'
                })
            )
            const submissionError = {
                [FORM_ERROR]: error.response.data.message,
            }
            if (error.response.data.errors) {
                const fieldErrors = Object.assign(submissionError, ...error.response.data.errors)
                yield call(callback, fieldErrors)
            } else {
                yield call(callback, submissionError)
            }
        } else {
            yield call(callback, {
                [FORM_ERROR]: error.message,
            })
        }

        logger.error('triggerScraping error', {}, error)
    }
}

export default triggerScraping
