import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
import UsersModule from '../index'

function* importUsers(action) {
    const { duck } = action
    const publisherid = yield select(AccountModule.selectors.getPublisherId)

    const payload = action.payload.data
    const importID = action.payload.importID

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: `${endpoints.USERS}/import`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    payload,
                    import_id: importID,
                },
            }
        })

        yield put({
            type: duck.types.IMPORT_USERS_SUCCESS,
            data
        })

        yield put(
            AppModule.creators.addNotification({
                message: 'Importing your contacts...',
                type: 'success'
            })
        )

    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put(
                AppModule.creators.addNotification({
                    message: error.response.data.message,
                    type: 'danger'
                })
            )
            yield put(UsersModule.creators.userImportError(error.response.data))
        } else {
            yield put(
                AppModule.creators.addNotification({
                    message: error.message,
                    type: 'danger',
                    dismissAfter: 10000
                })
            )
            yield put(UsersModule.creators.userImportError(error))
        }
    }
}

export default importUsers
