import { put, putResolve, select  } from 'redux-saga/effects'
import ModalModule from 'modules/modal'
import AppModule from 'modules/app'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* deleteCustomProperties(action) {
    const { payload, duck } = action
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: endpoints.CUSTOM_PROPERTIES + `/${payload?.value?.id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        yield put({
            type: duck.types.DELETE_CUSTOM_PROPERTIES_SUCCESS,
            payload: payload?.value.id
        })

        yield put(
            ModalModule.creators.closeModal({
                key: 'customProperty',
                actionFinished: true
            })
        )

        yield put(
            ModalModule.creators.closeModal({
                key: 'editCustomProperty'
            })
        )

        yield put(
            AppModule.creators.addNotification({
                message: 'Custom property deleted successfully.',
                type: 'success'
            })
        )
    } catch (error) {
        yield put({
            type: duck.types.DELETE_CUSTOM_PROPERTIES_ERROR,
            error
        })
    }
}

export default deleteCustomProperties
