import { useEffect, useCallback } from 'react'
import styles from './ImportStatusButton.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import Users from 'modules/users'
import AccountModule from 'modules/account'
import usePopup from 'hooks/usePopup'
import ImportNotify from '../popups/ImportNotify'
import PhoneNumberRequiredNotify from '../popups/PhoneNumberRequiredNotify'

const ImportStatusButton = () => {
    const dispatch = useDispatch()
    const getProgress = useSelector(Users.selectors.getUserImportProgress)
    const notifiableImportedUserCount = useSelector(Users.selectors.getNotifiableImportUserCount)
    const hasPhoneNumber = useSelector(AccountModule.selectors.hasPhoneNumber)

    const request_id = getProgress?.request_id
    const status = getProgress?.status

    const { setModalOpen: setImportNotifyModalOpen, Popup: ImportNotifyPopup } = usePopup()
    const importNotifyModalClose = useCallback(() => {
        setImportNotifyModalOpen(false)
    }, [setImportNotifyModalOpen])

    const { setModalOpen: setPhoneNumberRequiredNotifyModalOpen, Popup: PhoneNumberRequiredNotifyPopup } = usePopup()
    const phoneNumberRequiredNotifyModalClose = useCallback(() => {
        setPhoneNumberRequiredNotifyModalOpen(false)
        setImportNotifyModalOpen(false)
    }, [setPhoneNumberRequiredNotifyModalOpen, setImportNotifyModalOpen])

    useEffect(() => {
        if (request_id) {
            dispatch(
                Users.creators.checkUserImportStatus({
                    request_id
                })
            )
        }
    }, [dispatch, request_id])

    useEffect(() => {
        if (status === 'completed' && (notifiableImportedUserCount?.email !== 0 || notifiableImportedUserCount?.phone !== 0)) {
            setImportNotifyModalOpen(true)
        }
    }, [status, notifiableImportedUserCount, setImportNotifyModalOpen])

    const notify = useCallback((payload) => {
        if (!hasPhoneNumber && notifiableImportedUserCount?.phone !== 0) {
            setPhoneNumberRequiredNotifyModalOpen(true)
        } else {
            dispatch(
                Users.creators.notifyUserImport({ single: false, ...payload })
            )
            importNotifyModalClose()
        }
    }, [dispatch, hasPhoneNumber, notifiableImportedUserCount, importNotifyModalClose])

    return (
        <>
            <PhoneNumberRequiredNotifyPopup
                onAfterClose={phoneNumberRequiredNotifyModalClose}
            >
                <PhoneNumberRequiredNotify afterRedirect={phoneNumberRequiredNotifyModalClose} cancel={phoneNumberRequiredNotifyModalClose}/>
            </PhoneNumberRequiredNotifyPopup>
            <ImportNotifyPopup
                onAfterClose={importNotifyModalClose}
            >
                <ImportNotify notify={notify} cancel={importNotifyModalClose}/>
            </ImportNotifyPopup>
            {request_id && (
                <div className={styles.button}>
                    <p>{getProgress?.message}</p>
                    <span className={`${styles.icon} icon-settings`} />
                </div>
            )}
        </>
    )
}


export default ImportStatusButton
