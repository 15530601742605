import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form as ReactFinalForm, Field } from 'react-final-form'

import { Box, Form, Text, Heading } from '@piconetworks/ui'
import Boutton from '@piconetworks/pkg-boutton'
import SettingsModule from 'modules/settings'
import styles from './Support.module.scss'

import ContentSection from 'components/ContentSection'

const Support = () => {
    const dispatch = useDispatch()
    const { data }: {[key: string]: any} = useSelector(SettingsModule.selectors.getMyBusiness)

    return (
        <ReactFinalForm
            onSubmit={(values, _formApi, callback) =>
                dispatch(
                    SettingsModule.creators.saveMyBusiness(values, callback)
                )
            }
            initialValues={data}
            mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                },
            }}
            render={({
                form,
                handleSubmit,
                pristine,
                submitting,
                values,
                submitError,
            }) => {
                if (
                    values.same_as_community_name &&
                    values.company_name !== values.name
                ) {
                    form.mutators.setValue('company_name', values.name)
                }
                return (
                    <form onSubmit={handleSubmit}>
                        <Box.Flexbox.Container flexDirection="column">
                            <Box.Flexbox.Container>
                                {submitError && (
                                    <Form.Feedback
                                        variant="danger"
                                        className="mb-4"
                                    >
                                        {submitError}
                                    </Form.Feedback>
                                )}
                            </Box.Flexbox.Container>
                            <Heading.H2>Support Settings</Heading.H2>
                            <Box.Grid.Row>
                                <Box.Grid.Col>
                                    <Field
                                        component={Form.Input}
                                        name="support_email"
                                        placeholder="support@email.com"
                                        required
                                        type="email"
                                        label="Support Email"
                                    />
                                    <Text.Small
                                        className={styles.small}
                                    >
                                        Replies to payment receipts are
                                        directed to this email address.
                                    </Text.Small>

                                    <Box.Flexbox.Container
                                        alignItems="center"
                                        className={styles.switchContainer}
                                    >
                                        <Field
                                            component={Form.Switch}
                                            name="send_receipt"
                                            handleDiameter={24}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="rgba(0, 0, 0, 0.06) 0px 1px 2px, rgba(0, 0, 0, 0.16) 0px 2px 4px"
                                            activeBoxShadow="rgba(0,0,0,0)"
                                            height={18}
                                            width={48}
                                            onColor="#28BF6F"
                                        />
                                        <Text.P
                                            className={styles.labelText}
                                        >
                                            Receipts
                                        </Text.P>
                                    </Box.Flexbox.Container>
                                    <Text.Small
                                        className={styles.small}
                                    >
                                        Hype can automatically email
                                        receipts to your customers.
                                        Alternatively, you may disable this
                                        service and send custom receipts
                                        through a third-party email service.
                                    </Text.Small>
                                </Box.Grid.Col>
                            </Box.Grid.Row>
                        </Box.Flexbox.Container>
                        <Box.Flexbox.Container>
                            <Box.ContentPane.Child
                                noLeftPadding
                                noRightPadding
                                noBottomPadding
                                noTopPadding
                            >
                                <ContentSection.Footer
                                    className={styles.footer}
                                >
                                    <Box.Grid.Row className="justify-content-md-between align-items-md-center">
                                        <Box.Grid.Col md={5}>
                                            <Boutton
                                                type="submit"
                                                variant="primary"
                                                disabled={
                                                    submitting || pristine
                                                }
                                            >
                                                Update support settings
                                            </Boutton>
                                        </Box.Grid.Col>
                                    </Box.Grid.Row>
                                </ContentSection.Footer>
                            </Box.ContentPane.Child>
                        </Box.Flexbox.Container>
                    </form>
                )
            }}
        />
    )
}

export default Support