import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import {
    name,
} from '../dependencies'

import generateClaim from './generateClaim'
import redeemClaim from './redeemClaim'
import generatePortalSession from './generatePortalSession'
import generateCheckoutSession from './generateCheckoutSession'

const initialState = {
    generateClaimLoading: false,
    generateClaimError: null,
    generateClaimSuccess: null,

    redeemClaimLoading: false,
    redeemClaimError: null,
    redeemClaimSuccess: null,

    generatePortalSessionLoading: false,
    generatePortalSessionError: null,
    generatePortalSessionSuccess: null,

    generateCheckoutSessionLoading: false,
    generateCheckoutSessionError: null,
    generateCheckoutSessionSuccess: null,

    globalPaywallOpen: false,
}

const platformAccessSlice = createSlice({
    name,
    initialState,
    reducers: {
        openGlobalPaywall: (state: any) => {
            state.globalPaywallOpen = true
        },
        closeGlobalPaywall: (state: any) => {
            state.globalPaywallOpen = false
        },
    },
    extraReducers: {
        [generateClaim.pending]: (state: any, { payload }: any) => {
            state.generateClaimLoading = true
            state.generateClaimError = initialState.generateClaimError
            state.generateClaimSuccess = initialState.generateClaimSuccess
        },
        [generateClaim.fulfilled]: (state: any, { payload }: any) => {
            state.generateClaimLoading = false
            state.generateClaimError = false
            state.generateClaimSuccess = true
        },
        [generateClaim.rejected]: (state: any, { payload: error }: any) => {
            state.generateClaimLoading = false
            state.generateClaimError = error
            state.generateClaimSuccess= false
        },

        [redeemClaim.pending]: (state: any, { payload }: any) => {
            state.redeemClaimLoading = true
            state.redeemClaimError = initialState.redeemClaimError
            state.redeemClaimSuccess = initialState.redeemClaimSuccess
        },
        [redeemClaim.fulfilled]: (state: any, { payload }: any) => {
            state.redeemClaimLoading = false
            state.redeemClaimError = false
            state.redeemClaimSuccess = true
        },
        [redeemClaim.rejected]: (state: any, { payload: error }: any) => {
            state.redeemClaimLoading = false
            state.redeemClaimError = error
            state.redeemClaimSuccess= false
        },

        [generatePortalSession.pending]: (state: any, { payload }: any) => {
            state.generatePortalSessionLoading = true
            state.generatePortalSessionError = initialState.generatePortalSessionError
            state.generatePortalSessionSuccess = initialState.generatePortalSessionSuccess
        },
        [generatePortalSession.fulfilled]: (state: any, { payload }: any) => {
            state.generatePortalSessionLoading = false
            state.generatePortalSessionError = false
            state.generatePortalSessionSuccess = true
        },
        [generatePortalSession.rejected]: (state: any, { payload: error }: any) => {
            state.generatePortalSessionLoading = false
            state.generatePortalSessionError = error
            state.generatePortalSessionSuccess= false
        },

        [generateCheckoutSession.pending]: (state: any, { payload }: any) => {
            state.generateCheckoutSessionLoading = true
            state.generateCheckoutSessionError = initialState.generateCheckoutSessionError
            state.generateCheckoutSessionSuccess = initialState.generateCheckoutSessionSuccess
        },
        [generateCheckoutSession.fulfilled]: (state: any, { payload }: any) => {
            state.generateCheckoutSessionLoading = false
            state.generateCheckoutSessionError = false
            state.generateCheckoutSessionSuccess = true
        },
        [generateCheckoutSession.rejected]: (state: any, { payload: error }: any) => {
            state.generateCheckoutSessionLoading = false
            state.generateCheckoutSessionError = error
            state.generateCheckoutSessionSuccess= false
        },
    },
})

const {
    openGlobalPaywall,
    closeGlobalPaywall,
} = platformAccessSlice.actions

// SELECTORS
const getPlatformAccess = (state: any) => state[name]

const generateClaimLoading = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.generateClaimLoading,
)
const generateClaimError = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.generateClaimError,
)
const generateClaimSuccess = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.generateClaimSuccess,
)

const redeemClaimLoading = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.redeemClaimLoading,
)
const redeemClaimError = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.redeemClaimError,
)
const redeemClaimSuccess = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.redeemClaimSuccess,
)

const generatePortalSessionLoading = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.generatePortalSessionLoading,
)
const generatePortalSessionError = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.generatePortalSessionError,
)
const generatePortalSessionSuccess = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.generatePortalSessionSuccess,
)

const generateCheckoutSessionLoading = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.generateCheckoutSessionLoading,
)
const generateCheckoutSessionError = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.generateCheckoutSessionError,
)
const generateCheckoutSessionSuccess = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.generateCheckoutSessionSuccess,
)

const globalPaywallOpen = createDraftSafeSelector(
    getPlatformAccess,
    (platformAccess: any) => platformAccess.globalPaywallOpen,
)

// GENERAL
const platformAccessInitialState = {
    [name]: initialState,
}

const platformAccessReducer = {
    [name]: platformAccessSlice.reducer,
}

const takes = [
    ...generateClaim.takes,
    ...redeemClaim.takes,
    ...generatePortalSession.takes,
    ...generateCheckoutSession.takes,
]

// EXPORTS
export default platformAccessSlice.reducer

export {
    platformAccessInitialState as initialState,
    platformAccessReducer as reducer,

    takes,

    generateClaim,
    generateClaimLoading,
    generateClaimError,
    generateClaimSuccess,

    redeemClaim,
    redeemClaimLoading,
    redeemClaimError,
    redeemClaimSuccess,

    generatePortalSession,
    generatePortalSessionLoading,
    generatePortalSessionError,
    generatePortalSessionSuccess,

    generateCheckoutSession,
    generateCheckoutSessionLoading,
    generateCheckoutSessionError,
    generateCheckoutSessionSuccess,

    globalPaywallOpen,
    openGlobalPaywall,
    closeGlobalPaywall,
}
