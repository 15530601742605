import React from 'react'
import propTypes from 'prop-types'

import logger from 'lib/logger'

class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error) {
        logger.error('getDerivedStateFromError', {}, error)
        return { hasError: true }
    }

    constructor(props){
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, errorInfo) {
        logger.error(error.message, errorInfo, error)
    }

    render() {
        const { hasError } = this.state
        const { children, RenderError } = this.props

        if (hasError) {
            return (
                <RenderError />
            )
        }

        return children
    }
}

ErrorBoundary.defaultProps = {
    RenderError: () => null,
}

ErrorBoundary.propTypes = {
    children: propTypes.node,
    RenderError: propTypes.func,
}

export default ErrorBoundary
