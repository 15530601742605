import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import addTestId from 'utils/lib/addTestId'

import { Form as ReactFinalForm, Field } from 'react-final-form'
import { Text, Boutton as Button } from '@piconetworks/ui'
import Form from '@piconetworks/pkg-form'

import ActionModal from 'components/ActionModal'
import UsersModule from 'modules/users'

import styles from './Modals.module.scss'

class EmailReceipt extends React.PureComponent {

    render() {
        const { dispatch, users, activityId } = this.props
        const user = users[0]
        const error = users.length > 1
        return (
            <ReactFinalForm
                initialValues={{ email: user && user.email.value }}
                onSubmit={(values, _formApi, callback) => {
                    dispatch(UsersModule.creators.changeEmailReceipt({ values, activityId, user }, callback))
                }}
                render={({ handleSubmit, submitting, submitError, form }) =>
                    <ActionModal.Wrapper
                        type={error ? 'danger' : 'info'}
                        actionKey='emailReceipt'
                    >
                        <form onSubmit={async e => {
                            await handleSubmit(e)
                            form.reset()
                        }}>
                            <ActionModal.Header header="Email Receipt" />
                            <ActionModal.Body>
                                {submitError && (
                                    <Form.Feedback
                                        variant="danger"
                                        className="mb-4"
                                    >
                                        {submitError}
                                    </Form.Feedback>
                                )}
                                <Text.P>
                                    {error ? (
                                        'You cannot edit multiple contacts at same time!'
                                    ) : (
                                        'Email the receipt for this transaction to the following email address'
                                    )}
                                </Text.P>
                                {!error && <Field autoFocus component={Form.Input} name="email" type="text" />}
                            </ActionModal.Body>
                            <ActionModal.Footer
                                flexboxContainer={{
                                    justifyContent: error ? 'end' : 'center',
                                    alignItems: 'center',
                                    flexDirection: !error ? 'column-reverse' : undefined
                                }}
                                className={cx(styles.footer, { [styles.error]: error })}
                                cancelButton={
                                    <Button
                                        block={!error}
                                        className={cx(styles.cancel, { [styles.link]: !error })}
                                        variant={error ? 'secondary' : 'link'}
                                        data-testid={addTestId('send-receipt-cancel-button')}
                                    >
                                        {error ? 'Got It' : 'Cancel'}
                                    </Button>
                                }
                                actionButton={
                                    !error && (
                                        <Button
                                            block
                                            type="submit"
                                            variant="primary"
                                            disabled={submitting}
                                            data-testid={addTestId('send-receipt-submit-button')}
                                        >
                                            Send receipt
                                        </Button>
                                    )
                                }
                            />
                        </form>
                    </ActionModal.Wrapper>

                }
            />
        )
    }
}

EmailReceipt.propTypes = {
    activityId: PropTypes.string,
    dispatch: PropTypes.func,
    users: PropTypes.array
}

const mapStateToProps = (state) => ({
    activityId: UsersModule.selectors.getSelectedActivity(state),
    users: UsersModule.selectors.getSelectedUsers(state),
})

export default connect(mapStateToProps)(EmailReceipt)
