import constants from '../constants'
import { useFeatureManager } from '../dependencies'

const useHasActiveSubscription = () => {
    const { getAppTierByLabel } = useFeatureManager()
    const tier00 = getAppTierByLabel(constants.app_tiers.TIER_00)
    const hasTier00 = tier00?.has_access
    const tier10 = getAppTierByLabel(constants.app_tiers.TIER_10)
    const hasTier10 = tier10?.has_access
    const tier20 = getAppTierByLabel(constants.app_tiers.TIER_20)
    const hasTier20 = tier20?.has_access
    const tier30 = getAppTierByLabel(constants.app_tiers.TIER_30)
    const hasTier30 = tier30?.has_access
    const tier40 = getAppTierByLabel(constants.app_tiers.TIER_40)
    const hasTier40 = tier40?.has_access
    const hypePluginTier = getAppTierByLabel(constants.HYPE_PLUGIN_TIER)
    const hasHypePluginTier = hypePluginTier?.has_access
    
    const hasActiveSubscription = hasTier10 || hasTier20 || hasTier30 || hasTier40 || hasHypePluginTier

    const currentSubscription = [tier10, tier20, tier30, tier40, hypePluginTier].find((tier) => !!tier?.has_access)
    const currentSubscriptionTrialEnd = currentSubscription?.billingData?.trialEnd
    const currentSubscriptionHasTrial = parseInt(currentSubscriptionTrialEnd) > 0
    const currentSubscriptionTrialEndsIn72Hours = currentSubscriptionHasTrial && (currentSubscriptionTrialEnd - Math.round(Date.now()/1000) <= 72 * 60 * 60)

    const activeTier = currentSubscription?.label

    return {
        hasActiveSubscription,
        currentSubscription,
        currentSubscriptionTrialEndsIn72Hours,
        activeTier,
        hasTier00,
    }
}

export default useHasActiveSubscription
