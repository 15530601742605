export const ACTIVE = 'ACTIVE'
export const CANCELED = 'CANCELED'
export const COMPLETE = 'COMPLETE'
export const FAILED = 'FAILED'

export const INDIVIDUAL_SUBSCRIBER = 'Individual Subscriber'
export const INDIVIDUAL_SUBSCRIBER_TRIAL = 'Individual Subscriber (Trial)'
export const INDIVIDUAL_SUBSCRIBER_GIFT = 'Individual Subscriber (Gift)'
export const INDIVIDUAL_SUBSCRIBER_FREE_ACCESS = 'Individual Subscriber (Free Access)'

export const INDIVIDUAL_EX_SUBSCRIBER = 'Ex- Individual Subscriber'
export const INDIVIDUAL_EX_SUBSCRIBER_FREE_ACCESS = 'Ex- Individual Subscriber (Free Access)'
export const INDIVIDUAL_EX_SUBSCRIBER_GIFT = 'Ex- Individual Subscriber (Gift)'

export const PASS_HOLDER = 'Pass Holder'
export const PASS_HOLDER_GIFT = 'Pass Holder (Gift)'
export const PASS_HOLDER_FREE_ACCESS = 'Pass Holder (Free Access)'

export const EX_PASS_HOLDER = 'Ex- Pass Holder'
export const EX_PASS_HOLDER_FREE_ACCESS = 'Ex- Pass Holder (Free Access)'
export const EX_PASS_HOLDER_GIFT = 'Ex- Pass Holder (Gift)'

export const DONOR = 'Donor'
export const MONTHLY_DONOR = 'Monthly Donor'
export const EX_MONTHLY_DONOR = 'Ex- Monthly Donor'

const GROUP_ADMIN_EX_SUBSCRIBER = 'Ex- Group Subscriber (Admin)'
const GROUP_ADMIN_SUBSCRIBER = 'Group Subscriber (Admin)'
const GROUP_ADMIN = 'Group Admin'
const EX_GROUP_ADMIN = 'Ex- Group Admin'
const GROUP_SUBSCRIBER = 'Group Subscriber'
const GROUP_EX_SUBSCRIBER = 'Ex- Group Subscriber'

export const GROUP_SUBSCRIBERS = [GROUP_ADMIN_EX_SUBSCRIBER, GROUP_ADMIN_SUBSCRIBER, GROUP_ADMIN, EX_GROUP_ADMIN, GROUP_SUBSCRIBER, GROUP_EX_SUBSCRIBER]
export const NON_PAYING_GROUP_SUBSCRIBERS = [GROUP_SUBSCRIBER, GROUP_EX_SUBSCRIBER]
export const GROUP_SUBSCRIBERS_NOT_TO_BE_ADDED_TO_GROUP = [GROUP_ADMIN_EX_SUBSCRIBER, GROUP_ADMIN_SUBSCRIBER, GROUP_SUBSCRIBER, GROUP_EX_SUBSCRIBER]