import { putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'

function* restoreOffer(action) {
    const { types, payload } = action
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const id = payload.id

        const { status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'PATCH',
                url: `${endpoints.OFFERS}/${id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    archived: false,
                }
            },
        })
        if (status === 200) {
            const isPopup = payload.type.includes('popup')

            yield put({
                type: types.RESTORE_OFFER_SUCCESS,
                payload: {
                    type: isPopup ? 'popups' : 'landing_pages',
                    data: id
                }
            })
            yield put(
                AppModule.creators.addNotification({
                    type: 'success',
                    message:
                        `${isPopup ? 'Popup' : 'Page'} restored successfully.`
                })
            )
        } else {
            throw Error('An uncaught error occured.')
        }
    } catch (error) {
        AppModule.creators.addNotification({
            type: 'danger',
            message: error.message
        })
        if (error?.response?.data?.message) {
            yield put({
                type: types.RESTORE_OFFER_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: types.RESTORE_OFFER_ERROR,
                error
            })
        }
    }
}

export default restoreOffer
