import React, { Component } from 'react'
import style from './AppBar.module.scss'
import PropTypes from 'prop-types'

import { Heading, NavBar } from '@piconetworks/ui'

import Navigations from 'features/navigations'
import TimeDropdown from 'components/Performance/TimeDropdown'
import cx from 'classnames'
import ScreenSizeContext from 'utils/contexts/ScreenSize/context'

class AppBar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showSwitchAccount: false
        }
    }

    renderTimeDropdown = ({ className }) => {
        const {
            pageTitle,
        } = this.props

        if (pageTitle === 'Performance') {
            return <TimeDropdown className={className} />
        }
    }

    renderMobileCtas = () => {
        let cta


        return (
            <div className={style.mobileCta}>
                {cta}
            </div>
        )
    }

    renderNavigation = ({ isLgUp }) => {
        const {
            pageTitle,
            isUserProfile,
        } = this.props
        const heading = () => (isUserProfile ? pageTitle : <div className={cx(style.heading)}><Heading.H1>{pageTitle}</Heading.H1></div>)

        if (isLgUp) {
            return (
                <NavBar
                    horizontal
                    className={style.mainNavBar}
                    flexWrap='wrap'
                    heading={heading()}
                >
                    {this.renderTimeDropdown({ className: 'd-none d-lg-block' })}
                </NavBar>
            )
        } else {
            return (
                <React.Fragment>
                    <NavBar
                        horizontal
                        className={style.mainNavBar}
                        flexWrap='wrap'
                        brandIcon={<Navigations.Component.MobileMenuButton />}
                        heading={heading()}
                    >
                        {this.renderMobileCtas()}
                    </NavBar>
                    {this.renderTimeDropdown({ className: 'd-xs-block d-lg-none' })}
                </React.Fragment>
            )
        }

    }

    render() {
        return (
            <ScreenSizeContext.Consumer>
                {({ isLgUp }) => this.renderNavigation({ isLgUp })}
            </ScreenSizeContext.Consumer>
        )
    }
}

AppBar.propTypes = {
    accountName: PropTypes.string,
    avatar: PropTypes.string,
    communityName: PropTypes.string,
    email: PropTypes.string,
    handleSwitch: PropTypes.func,
    isUserProfile: PropTypes.bool,
    loading: PropTypes.bool,
    pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    publishersAvailable: PropTypes.array,
    optIns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default AppBar
