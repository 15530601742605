import qs from 'qs'
import { call, put, take } from 'redux-saga/effects'
import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
} from '../dependencies'
import generateClaim from './generateClaim'

const generateCheckoutSession = createAsyncSaga(
    `${name}/generateCheckoutSession`,
    function* ({
        payload: {
            publisherId = null,
            appTierLabel = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            yield put(yield call(generateClaim, {
                publisherId,
            }))

            yield take(generateClaim.pending)
            const response = yield take([generateClaim.fulfilled, generateClaim.rejected])

            const claim = response?.payload?.claim

            if (!claim) {
                throw new Error('no claim returned')
            }

            const queryParams = qs.stringify({
                claim,
                publisher_id: publisherId,
                label: appTierLabel,
                redirect_to: window.location.href.replace(`${window.location.origin}/`, ''),
            })

            const portalSessionUrl = `${endpoints.API_ENDPOINT}/apps/checkout/begin?${queryParams}`
        
            yield fulfilled({
                portalSessionUrl,
            })

            window.location.href = portalSessionUrl
        } catch (error) {
            logger.error('generateCheckoutSession error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default generateCheckoutSession
