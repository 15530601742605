import Overflow from "components/Overflow"
import BlastTypes from "./BlastTypes"
import styles from './ComposeBlastMenu.module.scss'

const ComposeBlastMenu = ({ header, className, menuClass, handleClickValue }) => (
    <Overflow
        icon={<></>}
        header={header}
        menuOpenPosition="right"
        menuOpenPositionY="bottom"
        className={className}
        menuClass={menuClass}
    >
        {BlastTypes.map((type) => (
            <Overflow.Option
                key={type.value}
                className={styles.option}
                icon={type.icon}
                onClick={() => handleClickValue(type.value)}
            >
                {type.label}
            </Overflow.Option>
        ))}
    </Overflow>
)
export default ComposeBlastMenu