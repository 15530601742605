import { call, put, putResolve } from 'redux-saga/effects'
import Router from 'next/router'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import exchangeClaim from 'modules/claim/exchangeClaim'

function* resetClaim({ claim, creators, types, token, refresh_token, publisher_id, team }) {
    try {
        if (!claim && (!token || !refresh_token || !publisher_id)) {
            throw Error('claim, token, refresh token, and publisher id are required')
        }
        let accessToken = token
        let refreshToken = refresh_token

        let responseData
        if (claim) {
            responseData = yield call(exchangeClaim, claim, team)
            if (responseData?.token && responseData?.refresh_token) {
                accessToken = responseData?.token
                refreshToken = responseData?.refresh_token
            }
        } else {
            const { data } = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'get',
                    url: `${endpoints.OAUTH.PUBLISHER_TOKEN}`,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        refreshtoken: refreshToken,
                        authorization: `Bearer ${accessToken}`,
                        publisherid: publisher_id
                    },
                    timeout: 30000,
                },
            })
            responseData = data
            accessToken = data?.token
            refreshToken = data?.refresh_token
        }

        yield putResolve(creators.tokenLogin({
            accessToken,
            refreshToken
        }))

        yield put({
            type: types.LOGIN_SUCCESS,
            payload: {
                ...responseData
            }
        })

        yield put({
            type: types.CLAIM_RESET_PASSWORD_SUCCESS,
            payload: {
                response: responseData,
            },
        })

        Router.push('/login/reset/password')
    } catch (error) {
        yield put({
            type: types.CLAIM_RESET_PASSWORD_ERROR,
            error
        })
    }
}

export default resetClaim
