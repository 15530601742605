/** @namespace FeatureManager.doAppPurchase */

import { createAsyncSaga, name, endpoints } from '../dependencies'

const doAppPurchase = createAsyncSaga(
    `${name}/doAppPurchase`,
    function* ({
        payload: {
            publisherId = null,
            appProductId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const appPurchase = yield request({
                headers: {
                    publisherid: publisherId,
                },
                method: 'post',
                url: endpoints.PURCHASE_APP(appProductId),
            })

            const apps = yield request({
                headers: {
                    publisherid: publisherId,
                },
                method: 'get',
                url: endpoints.APPS,
            })

            yield fulfilled(apps)
        } catch (error) {
            const defaultErrorMessage = error?.response?.data?.message || error.message
            const zipCodeError = defaultErrorMessage.includes('zip')
            const taxError = defaultErrorMessage.includes('tax')
            const productPricingIdError = defaultErrorMessage.includes('pricing ID')

            let finalErrorMessage

            if (zipCodeError) {
                const zipCode = defaultErrorMessage?.split?.('(')?.[1]?.split?.(')')?.[0] || 'unknown'

                finalErrorMessage = `We were unable to validate the zip code ${zipCode}. Please update your payment method with the correct zip code or try a different card.`
            } else if (taxError) {
                finalErrorMessage = 'We were unable to calculate your sales tax rate. Please update your payment method or try a different card.'
            } else if (productPricingIdError) {
                finalErrorMessage = 'We could not find the product you are trying to purchase. Please try purchasing a different product.'
            } else {
                finalErrorMessage = defaultErrorMessage
            }

            yield rejected(finalErrorMessage)
        }
    }
)

export default doAppPurchase
