/** @namespace FeatureManager.fetchCredits */

import currency from 'currency.js'
import { select } from 'redux-saga/effects'
import { createAsyncSaga, name, getStripeAccountBalance } from '../dependencies'

const fetchCredits = createAsyncSaga(
    `${name}/fetchCredits`,
    function* ({
        fulfilled,
        rejected,
    }) {
        try {
            let accountBalance = yield select(getStripeAccountBalance)
            // This is because of stripe sending it without division
            accountBalance = currency(accountBalance).divide(100)

            if (accountBalance < 0) {
                accountBalance = currency(accountBalance).multiply(-1)
            }

            yield fulfilled(accountBalance)
        } catch (error) {
            yield rejected({
                error: error.message,
            })
        }
    }
)

export default fetchCredits
