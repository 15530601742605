import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePublisherId } from '../dependencies'

import {
    giveAccess,
    getAccess,

    getGiveAccessInProgress,
    getGiveAccessSuccess,
    getGiveAccessError,

    getGetAccessInProgress,
    getGetAccessSuccess,
    getGetAccessError,
} from '../slice'

import AdminAccess from '../components/AdminAccess'

const useAdminAccess = () => {
    const publisherId = usePublisherId()
    const dispatch = useDispatch()

    const _giveAccess = useCallback((tierPricing) => {
        dispatch(giveAccess({
            publisherId,
            tierPricing,
        }))
    }, [dispatch, publisherId])

    const _getAccess = useCallback(() => {
        dispatch(getAccess({
            publisherId,
        }))
    }, [dispatch, publisherId])

    const _getGiveAccessInProgress = useSelector(getGiveAccessInProgress)
    const _getGiveAccessSuccess = useSelector(getGiveAccessSuccess)
    const _getGiveAccessError = useSelector(getGiveAccessError)
    const _getGetAccessInProgress = useSelector(getGetAccessInProgress)
    const _getGetAccessSuccess = useSelector(getGetAccessSuccess)
    const _getGetAccessError = useSelector(getGetAccessError)

    return {
        giveAccess: _giveAccess,
        getAccess: _getAccess,
    
        getGiveAccessInProgress: _getGiveAccessInProgress,
        getGiveAccessSuccess: _getGiveAccessSuccess,
        getGiveAccessError: _getGiveAccessError,
    
        getGetAccessInProgress: _getGetAccessInProgress,
        getGetAccessSuccess: _getGetAccessSuccess,
        getGetAccessError: _getGetAccessError,
    }
}

export default useAdminAccess
