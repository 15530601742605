/** @namespace CartUI.slice */

import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

import fetchCredits from './fetchCredits'
import fetchTaxes from './fetchTaxes'

const initialState = {
    isVisible: false,
    currentPopup: null,

    credits: null,
    fetchCreditsInProgress: false,
    fetchCreditsError: null,

    taxes: null,
    fetchTaxesInProgress: false,
    fetchTaxesError: null,
}

const cartUISlice = createSlice({
    name,
    initialState,
    reducers: {
        showAddPaymentMethod(state) {
            state.currentPopup = 'AddPaymentMethod'
            state.isVisible = true
        },
        showUpdatePaymentMethod(state) {
            state.currentPopup = 'UpdatePaymentMethod'
            state.isVisible = true
        },
        showSubscribe(state) {
            state.currentPopup = 'Subscribe'
            state.isVisible = true
        },
        showUpgrade(state) {
            state.currentPopup = 'Upgrade'
            state.isVisible = true
        },
        showCancelApp(state) {
            state.currentPopup = 'Cancellation'
            state.isVisible = true
        },
        showKeepApp(state) {
            state.currentPopup = 'Keep'
            state.isVisible = true
        },
        hideCartUI(state) {
            state.isVisible = false
        },
    },
    extraReducers: {
        [fetchCredits.pending]: (state) => {
            state.fetchCreditsInProgress = true
            state.fetchCreditsError = null
        },
        [fetchCredits.fulfilled]: (state, { payload: credits }) => {
            state.credits = credits
            state.fetchCreditsInProgress = false
            state.fetchCreditsError = null
        },
        [fetchCredits.rejected]: (state, { payload: error }) => {
            state.fetchCreditsInProgress = false
            state.fetchCreditsError = error
        },
        [fetchTaxes.pending]: (state) => {
            state.taxes = initialState.taxes
            state.fetchTaxesInProgress = true
            state.fetchTaxesError = null
        },
        [fetchTaxes.fulfilled]: (state, { payload: taxes }) => {
            state.taxes = taxes
            state.fetchTaxesInProgress = false
            state.fetchTaxesError = null
        },
        [fetchTaxes.rejected]: (state, { payload: error }) => {
            state.taxes = initialState.taxes
            state.fetchTaxesInProgress = false
            state.fetchTaxesError = error
        },
    },
})

const {
    showAddPaymentMethod,
    showUpdatePaymentMethod,
    showSubscribe,
    showUpgrade,
    showCancelApp,
    showKeepApp,
    hideCartUI,
} = cartUISlice.actions

// SELECTORS
const getCartUI = (state) => state[name]
const getIsVisible = createDraftSafeSelector(
    getCartUI,
    (cartUI) => cartUI?.isVisible,
)

const getCurrentPopup = createDraftSafeSelector(
    getCartUI,
    (cartUI) => cartUI?.currentPopup,
)

const getCredits = createDraftSafeSelector(
    getCartUI,
    (cartUI) => cartUI?.credits,
)

const getFetchCreditsInProgress = createDraftSafeSelector(
    getCartUI,
    (cartUI) => cartUI?.fetchCreditsInProgress,
)

const getFetchCreditsError = createDraftSafeSelector(
    getCartUI,
    (cartUI) => cartUI?.fetchCreditsError,
)

const getTaxes = createDraftSafeSelector(
    getCartUI,
    (cartUI) => cartUI?.taxes,
)

const getFetchTaxesInProgress = createDraftSafeSelector(
    getCartUI,
    (cartUI) => cartUI?.fetchTaxesInProgress,
)

const getFetchTaxesError = createDraftSafeSelector(
    getCartUI,
    (cartUI) => cartUI?.fetchTaxesError,
)

// GENERAL
const takes = [
    ...fetchCredits.takes,
    ...fetchTaxes.takes,
]

const cartUIInitialState = {
    [name]: initialState,
}

const cartUIReducer = {
    [name]: cartUISlice.reducer,
}

// EXPORTS
export default cartUISlice.reducer

export {
    takes,
    cartUIInitialState,
    cartUIReducer,

    showAddPaymentMethod,
    showUpdatePaymentMethod,
    showSubscribe,
    showUpgrade,
    showCancelApp,
    showKeepApp,
    hideCartUI,
    fetchCredits,
    fetchTaxes,

    getIsVisible,
    getCurrentPopup,
    getCredits,
    getFetchCreditsInProgress,
    getFetchCreditsError,
    getTaxes,
    getFetchTaxesInProgress,
    getFetchTaxesError,
}
