/** @namespace composeBlast.slice */

import {
    createAction,
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

const initialState = {
    input: {}
}

const setComposeBlastInput = createAction(`${name}/SET_INPUT`)
const resetComposeBlastInput = createAction(`${name}/RESET_INPUT`)

const composeBlastSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setComposeBlastInput, (state, { payload }) => {
                state.input = payload
            })
            .addCase(resetComposeBlastInput, (state) => {
                state.input = initialState.input
            })
    },
})

// SELECTORS
const getComposeBlast = (state) => state[name]
const getComposeBlastInput = createDraftSafeSelector(
    getComposeBlast,
    (composeBlast) => composeBlast?.input,
)

const composeBlastInitialState = {
    [name]: initialState,
}

const composeBlastReducer = {
    [name]: composeBlastSlice.reducer,
}

// EXPORTS
export default composeBlastSlice.reducer

export { 
    composeBlastInitialState as initialState,
    composeBlastReducer as reducer,
    setComposeBlastInput,
    resetComposeBlastInput,
    getComposeBlastInput,
}
