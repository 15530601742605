import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'


function* getWhitelistablePlans({ duck, payload: user_state_ids }) {
    let endpoint_url = `${endpoints.WHITELIST}`
    let method = 'patch'
    const params = { user_state_ids }

    if (user_state_ids.length == 1) {
        endpoint_url += `/${user_state_ids[0]}`
        method = 'get'
    }

    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method,
                url: endpoint_url,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: params,
                timeout: 30000
            }
        })

        if (data) {
            yield put({
                type: duck.types.REQUEST_WHITELISTABLE_PLANS_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({ type: duck.types.REQUEST_WHITELISTABLE_PLANS_ERROR, e })
    }
}

export default getWhitelistablePlans
