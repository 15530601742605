const actions = {}

actions.types = [
    'VIEW_PAGE',
    'COPY_LANDING_PAGE',
    'SEND_EVENT',
    'IDENTIFY_USER',
]

actions.creators = ({ types }) => ({
    viewPage: (payload) => ({ type: types.VIEW_PAGE, payload }),
    copyLandingPage: (payload) => ({ type: types.COPY_LANDING_PAGE, payload }),
    sendEvent: (payload) => ({ type: types.SEND_EVENT, payload }),
    identifyUser: (payload) => ({ type: types.IDENTIFY_USER, payload }),
})

export default actions
