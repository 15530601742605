import { putResolve, put, select, delay, call } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import ModalModule from 'modules/modal'
import accountModule from 'modules/account'
import appModule from 'modules/app'
import Router from 'next/router'
import ErrorService from 'utils/services/ErrorService'

function* deleteContacts({ payload, types, callback }) {

    const user_state_ids = payload.users.map(u => u.user_state_id)
    const publisherid = yield select(accountModule.selectors.getPublisherId)
    const { router } = Router
    // We keep using states for the bulk action because delete /users is used by deleteAccount
    const route = user_state_ids.length === 1 ? `profile/${user_state_ids[0]}` : ''
    const endpoint = `${endpoints.USERS}/${route}`

    try {
        yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: endpoint,
                data: user_state_ids.length === 1 ? undefined : { user_state_ids },
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        yield put({
            type: types.DELETE_CONTACTS_SUCCESS,
            payload: payload.users
        })

        yield put(
            ModalModule.creators.closeModal({
                key: 'deleteAction',
                actionFinished: true
            })
        )

        yield put(
            appModule.creators.addNotification({
                type: 'success',
                message: `${payload.users.length === 1 ? 'Contact' : 'Contacts'} deleted successfully`
            })
        )
        if (router && router.query && router.query.contact) {
            yield delay(2000)
            Router.push('/contacts')
        }
    } catch (error) {
        const submissionError = new ErrorService(error).makeANiceSubmissionError()
        yield call(callback, submissionError)

        yield put({
            type: types.DELETE_CONTACTS_ERROR,
            error
        })

    }
}

export default deleteContacts
