
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const toUTC = (value) => dayjs.utc(value)

const dateOrNotAvailable = (value, nullText = null) => {
    if (value) {
        if (typeof value === 'string' || typeof value === 'object') {
            return dayjs(value).format('MMM D, YYYY')
        }
        return dayjs.unix(value).format('MMM D, YYYY')
    } else {
        return nullText
    }
}

export { dateOrNotAvailable, toUTC }
