import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import AccountModule from 'modules/account'
import endpoints from 'utils/lib/endpoints'

function* generateZapierKey({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.KEYS,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })

        yield put({
            type: types.GENERATE_ZAPIER_KEY_SUCCESS,
            payload: { access_key: data.api_key }
        })

    } catch (error) {
        yield put({
            type: types.GENERATE_ZAPIER_KEY_ERROR,
            error
        })
    }
}

export default generateZapierKey
