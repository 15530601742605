import { useDefaultSource } from '../../../dependencies'
import { useSelector } from 'react-redux'

import TitleBar from '../../sections/TitleBar'
import PaymentSummary from '../../sections/PaymentSummary'
import PaymentMethodSummary from '../../sections/PaymentMethodSummary'
import StatusBar from '../../sections/StatusBar'
import Terms from '../../sections/Terms'
import SubmitButton from '../../sections/SubmitButton'
import CancelButton from '../../sections/CancelButton'
import PopupContainer from '../../sections/PopupContainer'
import { getCredits } from '../../../slice'
import PurchaseError from 'features/FeatureManager/components/PurchaseError'
const Subscribe = ({
    app = null,
    productPricingId = null,
    showUpdatePaymentMethod = () => {},
    subscribe = () => {},
    cancel = () => {},
    purchaseStatus = null,
}) => {
    const appName = app?.name
    const defaultSource = useDefaultSource()
    const creditAmount = useSelector(getCredits)

    const inProgress = purchaseStatus === 'pending'

    return (
        <>
            <TitleBar>Subscribe to {appName}</TitleBar>
            <PopupContainer loading={inProgress}>
                <PaymentSummary
                    app={app}
                    productPricingId={productPricingId}
                />
                <PaymentMethodSummary
                    onClickUpdatePaymentMethod={showUpdatePaymentMethod}
                    defaultSource={defaultSource}
                />
                <Terms>
                    {creditAmount && 'Your subscription payments will be withdrawn from your Hype credits until the credits are fully depleted. '}
                    You can cancel your subscription at any time. <a target="_blank" href="https://hype.co/terms">See terms</a>.
                </Terms>
                <StatusBar>
                    <PurchaseError />
                </StatusBar>
                <SubmitButton disabled={inProgress} onClick={subscribe}>Subscribe</SubmitButton>
                <CancelButton onClick={cancel}/>
            </PopupContainer>
        </>
    )
}

export default Subscribe
