const actions = {}

actions.types = [
    'GET_PRODUCTS',
    'GET_PRODUCTS_SUCCESS',
    'GET_PRODUCTS_ERROR',

    'CREATE_PRODUCT',
    'CREATE_PRODUCT_SUCCESS',
    'CREATE_PRODUCT_ERROR',

    'UPDATE_PRODUCT',
    'UPDATE_PRODUCT_SUCCESS',
    'UPDATE_PRODUCT_ERROR',

    'DELETE_PRODUCT',
    'DELETE_PRODUCT_SUCCESS',
    'DELETE_PRODUCT_ERROR',

    'GET_GROUPS',
    'GET_GROUPS_SUCCESS',
    'GET_GROUPS_ERROR',

    'CREATE_GROUP',
    'CREATE_GROUP_SUCCESS',
    'CREATE_GROUP_ERROR',

    'UPDATE_GROUP',
    'UPDATE_GROUP_SUCCESS',
    'UPDATE_GROUP_ERROR',

    'DELETE_GROUP',
    'DELETE_GROUP_SUCCESS',
    'DELETE_GROUP_ERROR',

    'GET_PRODUCT',
    'GET_PRODUCT_SUCCESS',
    'GET_PRODUCT_ERROR',

    'GET_PRODUCT_SIBLINGS',
    'GET_PRODUCT_SIBLINGS_SUCCESS',
    'GET_PRODUCT_SIBLINGS_ERROR',

    'CHECK_EDIT_PRODUCT',
    'CHECK_EDIT_PRODUCT_SUCCESS',
    'CHECK_EDIT_PRODUCT_ERROR'
]

actions.creators = ({ types }) => ({
    // Groups
    getGroups: () => ({ type: types.GET_GROUPS }),
    createGroup: (payload) => ({ type: types.CREATE_GROUP, payload }),
    updateGroup: (payload) => ({ type: types.UPDATE_GROUP, payload }),
    deleteGroup: (payload) => ({ type: types.DELETE_GROUP, payload }),

    getProduct: (payload) => ({ type: types.GET_PRODUCT, payload }),
    getProducts: () => ({ type: types.GET_PRODUCTS }),
    getProductSiblings: (payload) => ({ type: types.GET_PRODUCT_SIBLINGS, payload }),

    createProduct: (payload) => ({ type: types.CREATE_PRODUCT, payload }),
    updateProduct: (payload) => ({ type: types.UPDATE_PRODUCT, payload }),
    deleteProduct: (payload) => ({ type: types.DELETE_PRODUCT, payload }),

    checkEditProduct: (payload) => ({ type: types.CHECK_EDIT_PRODUCT, payload })
})

export default actions
