import produce from 'immer'
import omit from 'lodash/omit'

export const initialState = {
    status: 'INITIATED',
    error: false,
    landing_pages: [],
    popups: [],
    conversionRate: null
}

const reducer = (state, action, { types, statuses }) =>
    produce(state, (draftState) => {
        switch (action.type) {
            //loading
            case types.GET_OFFERS:
            case types.GET_OFFER:
            case types.CREATE_OFFER:
            case types.UPDATE_OFFER:
            case types.DELETE_OFFER:
            case types.RESTORE_OFFER:
            case types.GET_CONVERSION_RATE:
            case types.SORT_ORDER:
                draftState.status = statuses.LOADING
                draftState.error = false
                break

            case types.SORT_ORDER_SUCCESS:
                draftState.status = statuses.READY
                draftState.error = false
                draftState[action.payload.type] = action.payload.data
                break

            case types.GET_OFFERS_SUCCESS:
                draftState.status = statuses.READY
                draftState.error = false
                const productsRemoved = action.payload.data.map(o => {
                    return {
                        ...omit(o, ['products', 'authentication_methods', 'newsletters', 'fields'])
                    }
                })
                draftState[action.payload.type] = productsRemoved
                break

            case types.CREATE_OFFER_SUCCESS:
            case types.UPDATE_OFFER_SUCCESS:
            case types.GET_OFFER_SUCCESS:
                draftState.status = statuses.READY
                draftState.error = false
                const type = action.payload.type
                // replace the fetched product, or add it
                const idx = draftState[type].findIndex(o =>
                    o.id === action.payload.data.id
                )

                if (idx !== -1) {
                    draftState[type][idx] = action.payload.data
                } else {
                    draftState[type] = [...draftState[type], action.payload.data]
                }
                break

            case types.RESTORE_OFFER_SUCCESS:
                draftState.status = statuses.READY
                draftState.error = false

                const restoredType = action.payload.type
                // replace the fetched product, or add it
                const restoredIdx = draftState[restoredType].findIndex(o =>
                    o.id === action.payload.data
                )
                if (deletedIdx !== -1) {
                    draftState[restoredType][restoredIdx] = {
                        ...draftState[restoredType][restoredIdx],
                        archived: false
                    }
                }
                break
            case types.DELETE_OFFER_SUCCESS:
                draftState.status = statuses.READY
                draftState.error = false

                const deletedType = action.payload.type
                // replace the fetched product, or add it
                const deletedIdx = draftState[deletedType].findIndex(o =>
                    o.id === action.payload.data
                )
                if (deletedIdx !== -1) {
                    draftState[deletedType][deletedIdx] = {
                        ...draftState[deletedType][deletedIdx],
                        archived: true
                    }
                }
                break

            case types.GET_CONVERSION_RATE_SUCCESS:
                draftState.status = statuses.READY
                draftState.error = false
                draftState.conversionRate = action.payload
                break

                // error
            case types.GET_CONVERSION_RATE_ERROR:
            case types.GET_OFFERS_ERROR:
            case types.SORT_ORDER_ERROR:
            case types.GET_OFFER_ERROR:
            case types.CREATE_OFFER_ERROR:
            case types.UPDATE_OFFER_ERROR:
            case types.DELETE_OFFER_ERROR:
            case types.RESTORE_OFFER_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = action.error
                break

            default:
                return state
        }
    })

export default reducer
