import React, { createContext, useState, useContext, Children, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form as RFF } from 'react-final-form'
import cx from 'classnames'
import { Flexbox } from '@piconetworks/pkg-box'
import styles from './Wizard.module.scss'

const WizardContext = createContext({})

const Provider = ({ children, onSubmit }) => {
    const [currentStep, setStep] = useState(1)
    const activePage = Children.toArray(children)[currentStep - 1]
    const lastPage = Children.count(children)
    const isLastPage = currentStep === lastPage
    
    const goToPreviousStep = useCallback(() => {
        setStep(currentStep - 1)
    }, [currentStep, setStep])

    const goToNextStep = useCallback(() => {
        setStep(currentStep + 1)
    }, [currentStep, setStep])

    const validateStep = useCallback((values) => {
        return activePage?.props?.validate ? activePage?.props?.validate(values) : {}
    }, [activePage])

    const handleSubmit = useCallback(async (values, _formApi, callback) => {
        if (isLastPage) {
            await onSubmit(values, _formApi, callback)
        } else {
            await goToNextStep()
        }
    }, [isLastPage, goToNextStep, onSubmit])
    
    return (
        <WizardContext.Provider
            value={{
                currentStep,
                activePage,
                isLastPage,
                goToPreviousStep,
                goToNextStep,
                validateStep
            }}
        >
            <Flexbox.Container flexDirection='column' className={cx(styles.container, styles.modal)}>
                <div className={styles.form}>
                    <RFF
                        validate={((values) => validateStep(values))}
                        onSubmit={handleSubmit}
                        mutators={{
                            setError: (args, state) => {
                                // console.log('setError', args, state)
                                if (args && args[0]) {
                                    state.formState.lastSubmittedValues = state.formState.values
                                    state.formState.submitFailed = true
                                    state.formState.submitSucceeded = false
                                    state.formState.submitError = args[0]
                                }
                            },
                            unsetError: (args, state) => {
                                state.formState.submitFailed = false
                                state.formState.submitError = undefined
                            },
                        }}
                    >
                        {({ handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Flexbox.Container flexDirection='column' className={styles.form}>
                                        {activePage}
                                    </Flexbox.Container>
                                </form>
                            )
                        }}
                    </RFF>
                </div>
            </Flexbox.Container>
        </WizardContext.Provider>
    )
}

Provider.propTypes = {
    children: PropTypes.any,
}

const useWizard = () => useContext(WizardContext)

export { Provider as default, useWizard }
