import currency from 'currency.js'
import styles from '../PaymentSummary.module.scss'

const TaxSummary = ({ taxAmount, taxRate }) => {
    return (
        <div className={styles['summary-item']}>
            <div className={styles.name}>
                <span>Sales Tax</span>
                {taxRate && (
                    <small>
                        {taxRate}%
                    </small>
                )}
            </div>
            <div className={styles.price}>${taxAmount}</div>
        </div>
    )
}

export default TaxSummary
