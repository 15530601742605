import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'
import isValidURL from 'utils/lib/isValidURL'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

const getVcardUrl = AccountModule.selectors.getVcardUrl
const addNotification = AppModule.creators.addNotification
const getAccountInfo = AccountModule.creators.getAccountInfo
const GET_ACCOUNT_INFO_SUCCESS = AccountModule.types.GET_ACCOUNT_INFO_SUCCESS
const GET_ACCOUNT_INFO_ERROR = AccountModule.types.GET_ACCOUNT_INFO_ERROR

const name = 'vCard'

export {
    name,

    endpoints,
    createAsyncSaga,
    isValidURL,

    getVcardUrl,
    addNotification,

    getAccountInfo,
    GET_ACCOUNT_INFO_SUCCESS,
    GET_ACCOUNT_INFO_ERROR,
}
