import { createAsyncSaga, name, endpoints, logger } from '../dependencies'

const createEdition = createAsyncSaga(
    `${name}/createEdition`,
    function* ({
        payload: {
            publisherId = null,
            name = null,
            type = null,
            color = null,
            shopifySku = null,
            engravable = null,
            trial = null,
            salesChannel = null,
            packSize = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            if (!name) {
                throw new Error('name is required')
            }

            if (!type) {
                throw new Error('type is required')
            }

            if (!color) {
                throw new Error('color is required')
            }

            if (!shopifySku) {
                throw new Error('Shopify SKU is required')
            }

            if (!salesChannel) {
                throw new Error('sales channel is required')
            }

            if (!packSize) {
                throw new Error('pack size is required')
            }

            const response = yield request({
                method: 'post',
                url: endpoints.CREATE_EDITION,
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    name: name,
                    type: type,
                    color: color,
                    shopify_sku: shopifySku,
                    engravable: engravable,
                    trial: trial,
                    sales_channel: salesChannel,
                    pack_size: packSize,
                },
            })

            const editionId = response?.id

            if (!editionId) {
                throw new Error('edition could not be created')
            }

            yield fulfilled({ editionId })
        } catch (error) {
            logger.error('createEdition error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default createEdition
