import produce from 'immer'

export const initialState = {
    status: 'INITIATED',
    error: false,
    all: [],
    blasts: {
        type: null,
        filter: 'sent',
        sent: {
            isLoading: false,
            page: null,
            pages: null,
            data: [],
            count: null
        },
        draft: {
            isLoading: false,
            page: null,
            pages: null,
            data: [],
            count: null
        },
    },
    messages: {
        status: 'INITIATED',
        error: false,
        all: [],
    },
    conversations: {
        status: 'INITIATED',
        error: false,
        all: {},
        active: null
    },
    messagingEnabled: false,
    fetchMessagingEnabledInProgress: false,
    messagingEnabledError: null,
}

const reducer = (state, {
    type,
    error,
    payload,
}, { types, statuses }) =>
    produce(state, (draftState) => {
        switch (type) {
            case types.UPDATE_BLAST_FILTER:
                draftState.blasts.filter = payload.filter
                break
            case types.GET_BLASTS:
                draftState.blasts.filter = payload.filter
                draftState.blasts[payload.filter]['isLoading'] = true
                draftState.status = statuses.LOADING
                draftState.error = false
                break

            case types.GET_CONVERSATIONS:
                draftState.conversations.status = statuses.LOADING
                draftState.conversations.error = false
                break

            case types.CREATE_BLAST:
                draftState.status = statuses.LOADING
                draftState.error = false
                break

            case types.UPDATE_BLAST:
                draftState.status = statuses.LOADING
                draftState.error = false
                break


            case types.GET_BLASTS_SUCCESS:
                draftState.blasts.filter = payload.filter

                if (payload.filter === 'sent') {
                    if (draftState.blasts.sent.page !== payload.page) {
                        draftState.blasts.sent.isLoading = false
                        draftState.blasts.sent.page = payload.page
                        draftState.blasts.sent.pages = payload.pages
                        draftState.blasts.sent.count = payload.count
                        draftState.blasts.sent.data = [...draftState.blasts.sent.data, ...payload.payload]
                    }
                } else {
                    if (draftState.blasts.draft.page !== payload.page) {
                        draftState.blasts.draft.isLoading = false
                        draftState.blasts.draft.page = payload.page
                        draftState.blasts.draft.pages = payload.pages
                        draftState.blasts.draft.count = payload.count
                        draftState.blasts.draft.data = [...draftState.blasts.draft.data, ...payload.payload]
                    }
                }
                draftState.status = statuses.READY
                draftState.error = false
                break

            case types.GET_CONVERSATIONS_SUCCESS:
                draftState.conversations.all = payload
                draftState.conversations.status = statuses.READY
                draftState.conversations.error = false
                break


            case types.GET_CONVERSATION_BATCH_SUCCESS:
                const formattedMessages = payload.reduce((groupedMessages, convo) => {
                    const { id: userId } = convo.user
                    const groupingCreated = !!groupedMessages?.[userId]

                    // from the ordering, if it has broadcast_id
                    // the blast message will be the last one
                    if (convo.broadcast_id) {
                        convo.messages.reverse()[0].from_broadcast = true
                    }

                    const messageGroup = {
                        user: convo.user,
                        messages: convo.messages
                    }

                    return {
                        ...groupedMessages,
                        [userId]: groupingCreated ? {
                            ...groupedMessages[userId],
                            messages: [...groupedMessages[userId].messages, ...messageGroup.messages]
                        } : messageGroup
                    }
                }, {})

                Object.keys(formattedMessages).forEach((key) => {
                    const convo = formattedMessages[key]
                    convo.messages = [...convo.messages].sort((a, b) => a.sent_at?.localeCompare(b?.sent_at))
                })

                draftState.conversations.all = formattedMessages
                draftState.conversations.status = statuses.READY
                draftState.conversations.error = false
                break

            case types.SET_ACTIVE_CONVERSATION:
                const activeConversation = draftState.conversations.all?.[payload.id]
                draftState.conversations.active = activeConversation
                break

            case types.CLEAR_ACTIVE_CONVERSATION:
                draftState.conversations.active = null
                break

            case types.CREATE_BLAST_SUCCESS:
                draftState.all.push(payload)
                if (payload.draft) {
                    draftState.blasts.draft.data.unshift(payload)
                } else {
                    draftState.blasts.sent.data.unshift(payload)
                }
                draftState.status = statuses.READY
                draftState.error = false
                break

            case types.UPDATE_BLAST_SUCCESS:
                const isDraft = payload.draft
                if (isDraft) {
                    // it is a draft that has been saved
                    const draftIndex = draftState.blasts.draft.data.findIndex(item => item.id === payload.id)
                    if (draftIndex !== -1) {
                        draftState.blasts.draft.data[draftIndex] = payload
                    } else {
                        draftState.blasts.draft.data.push(payload)
                    }
                } else {
                    // it has been sent
                    const draftIndexToRemove = draftState.blasts.draft.data.findIndex(item => item.id === payload.id)
                    if (draftIndexToRemove !== -1) {
                        draftState.blasts.draft.data.splice(draftIndexToRemove, 1)
                    }
                    const sentIndex = draftState.blasts.sent.data.findIndex(item => item.id === payload.id)
                    if (sentIndex !== -1) {
                        draftState.blasts.sent.data[sentIndex] = payload
                    } else {
                        draftState.blasts.sent.data.push(payload)
                    }
                }

                const index = draftState.all.findIndex(item => item.id === payload.id)
                if (index !== -1) {
                    draftState.all[index] = payload
                } else {
                    draftState.all.push(payload)
                }
                draftState.status = statuses.READY
                draftState.error = false
                break

            case types.GET_FAILED_MESSAGES:
                draftState.messages.status = statuses.LOADING
                draftState.messages.error = false
                break

            case types.GET_FAILED_MESSAGES_SUCCESS:
                draftState.messages.status = statuses.READY
                draftState.messages.all = payload
                draftState.messages.error = false
                break

            case types.GET_FAILED_MESSAGES_ERROR:
                draftState.messages.status = statuses.ERRORED
                draftState.messages.error = error
                break

            case types.UPDATE_BLAST_ERROR:
            case types.GET_BLASTS_ERROR:
            case types.CREATE_BLAST_ERROR:
                draftState.blasts.isLoading = false
                draftState.status = statuses.ERRORED
                draftState.error = error
                break

            case types.ENABLE_MESSAGING:
                draftState.messagingEnabled = initialState.messagingEnabled
                draftState.fetchMessagingEnabledInProgress = true
                draftState.messagingEnabledError = initialState.messagingEnabledError
                break

            case types.ENABLE_MESSAGING_SUCCESS:
                draftState.messagingEnabled = true
                draftState.fetchMessagingEnabledInProgress = false
                draftState.messagingEnabledError = initialState.messagingEnabledError
                break

            case types.ENABLE_MESSAGING_ERROR:
                draftState.messagingEnabled = false
                draftState.fetchMessagingEnabledInProgress = false
                draftState.messagingEnabledError = error
                break

            case types.VERIFY_MESSAGING:
                draftState.messagingEnabled = initialState.messagingEnabled
                draftState.fetchMessagingEnabledInProgress = true
                draftState.messagingEnabledError = initialState.messagingEnabledError
                break

            case types.VERIFY_MESSAGING_SUCCESS:
                draftState.messagingEnabled = true
                draftState.fetchMessagingEnabledInProgress = false
                draftState.messagingEnabledError = initialState.messagingEnabledError
                break

            case types.VERIFY_MESSAGING_ERROR:
                draftState.messagingEnabled = false
                draftState.fetchMessagingEnabledInProgress = false
                draftState.messagingEnabledError = error
                break

            default:
                return state
        }
    })

export default reducer
