import { name, endpoints, getPublisherId } from '../dependencies'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import { createAsyncThunk } from '@reduxjs/toolkit'

const confirmOneTimeCode = createAsyncThunk(
    `${name}/confirmOneTimeCode`,
    async (payload, thunkAPI) => {
        const { getState, dispatch } = thunkAPI
        try {
            const { code, phone } = payload
            const publisherId = getPublisherId(getState())

            if (!code) {
                throw Error('code must be provided')
            }

            if (!phone) {
                throw new Error('phone not provided')
            }

            const response = await dispatch({
                type: REQUEST,
                config: {
                    method: 'POST',
                    url: endpoints.CONFIRM_CODE,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid: publisherId,
                    },
                    data: {
                        phone,
                        code
                    }
                }
            })        
            const { status, data } = response

            if (status === 200) {
                return data
            } else {
                throw new Error('Confirming code failed')
            }
        } catch (error) {
            console.log('error', error)
            throw new Error(error.response.data.message || error)
        }
    }
)

export default confirmOneTimeCode
