import { putResolve, select, put } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* loginAsUser(action) {
    const { payload, selectors, types } = action
    const { user_state_id } = yield select(selectors.getSingleUser)
    const publisherid = yield select(AccountModule.selectors.getPublisherId)
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.USERS}/profile/${user_state_id}/token?page=${payload.page}&domain=${payload.domain}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })
        yield put({
            type: types.LOGIN_AS_USER_SUCCESS
        })
        window.open(data.redirect_url)
    } catch (error) {
        yield put({
            type: types.LOGIN_AS_USER_ERROR,
            error
        })
    }
}

export default loginAsUser
