import { takeEvery, takeLatest } from 'redux-saga/effects'

const takes = ({ types, sagas }) => [
    takeEvery(types.CANCEL_PLAN, sagas.cancelPlan),
    takeEvery(types.SEND_EMAIL_RECEIPT, sagas.emailReceipt),
    takeEvery(types.DELETE_CONTACTS, sagas.deleteContacts),
    takeEvery(types.FETCH_SELECTED_USERS, sagas.fetchSelectedUsers),
    takeLatest(types.FETCH_SELECTED_USER_ACTIVITY, sagas.fetchSelectedUserActivity),
    takeEvery(types.REINDEX, sagas.reindexUsers),
    takeEvery(types.REINDEX_USER, sagas.reindexUser),
    takeEvery(types.REQUEST_SINGLE_USER, sagas.getSingleUser),
    takeEvery(types.UPDATE_SINGLE_USER, sagas.updateSingleUser),
    takeEvery(types.GET_USER_NEWSLETTERS, sagas.getUserNewsletters),
    takeEvery(types.UPDATE_USER_NEWSLETTERS, sagas.updateUserNewsletters),
    takeEvery(types.GET_PAGINATED_USERS, sagas.getPaginatedUsers),
    takeEvery(types.GRANT_FREE_ACCESS, sagas.grantFreeAccess),
    takeEvery(types.MODIFY_FREE_ACCESS, sagas.modifyFreeAccess),
    takeEvery(types.REVERSE_CANCELLATION, sagas.reverseCancellation),
    takeEvery(types.LOGIN_AS_USER, sagas.loginAsUser),
    takeEvery(types.LOG_OUT_USER, sagas.logoutUser),
    takeEvery(types.EXPORT_CSV, sagas.exportCSV),
    takeEvery(types.UPDATE_EXPORT_PROGRESS, sagas.updateExportProgress),
    takeEvery(types.ISSUE_REFUND, sagas.issueRefund),
    takeEvery(types.GET_USERS_META, sagas.getUsersMeta),
    takeEvery(types.IMPORT_USERS, sagas.importUsers),
    takeEvery(types.NOTIFY_USER_IMPORT, sagas.notifyUserImport),
    takeEvery(types.GET_NOTIFIABLE_IMPORTED_USER_COUNT, sagas.getNotifiableImportedUserCount),
    takeEvery(types.START_USER_IMPORT, sagas.startUserImport),
    takeEvery(types.CHECK_USER_IMPORT_STATUS, sagas.checkUserImportStatus),
    takeEvery(types.REINDEX_SYNC_STATUS, sagas.reIndexSyncStatus)

]

export default takes
