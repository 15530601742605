import React from 'react'
import { useSelector } from 'react-redux'

import { useRouter } from 'next/router'
import Link from 'next/link'

import FeatureFlags from 'modules/feature-flags'
import AccountModule from 'modules/account'

import { Badge } from '@piconetworks/ui'
import Tooltip from 'components/Tooltip'
import AccessControl from 'components/AccessControl'
import { Item } from '@piconetworks/pkg-nav-link'
import IntegrationsModule from 'modules/integrations'

import { useFeatureManager, constants } from 'features/FeatureManager'

import useLinks from '../../utils/useLinks'

import cx from 'classnames'
import style from '../../navigation.module.scss'

const Links = ({
    showTitle = true,
    isActive,
    accountInfo,
    isDesktop,
}) => {
    const router = useRouter()

    const hasPayments = useSelector(IntegrationsModule.selectors.getMonetizationIsInstalled)

    const { hasFeature, apps } = useFeatureManager()
    const hasHypePlugin = hasFeature(constants.GADGET_ACCESS_FEATURE) || hasFeature(constants.WIDGET_FEATURE)

    const paidDownloadsIsEnabled = useSelector((state) =>
        FeatureFlags.selectors.getFeatureFlagIsEnabled(state, 'paid-downloads')
    )

    const hasPhoneNumber = useSelector(AccountModule.selectors.hasPhoneNumber)

    const links = useLinks({
        hasHypePlugin,
        accountInfo,
        hasPhoneNumber,
        isDesktop,
        isMobileLeftNav: !isDesktop,
        hasPayments,
        paidDownloadsIsEnabled,
    })

    const renderDesktopLink = ({
        link: _link,
        title,
        icon,
        badge,
    }, index) => {
        const isSite = title === 'Site'
        const isSiteActive = isSite && router?.pathname.includes('pages')
        const isMessagesActive = router?.pathname?.includes('messages') && _link?.includes('messages')
        const isProductsActive = router?.pathname?.includes('products') && _link?.includes('products')
        const isIntegrationsActive = router?.pathname?.includes('integrations') && _link?.includes('integrations')

        const isSubpageActive = isIntegrationsActive || isProductsActive || isMessagesActive || isSiteActive
        return (
            <Link key={index} href={_link} passHref>
                <Item
                    active={isSubpageActive || isActive(_link)}
                    className={cx(style.item, {
                        [style.active]: isSubpageActive || isActive(_link),
                    })}
                >
                    <Tooltip
                        content={title}
                        placement="right"
                        className={cx('d-none', 'd-lg-block', style.itemTooltip)}
                    >
                        <Badge className={style.betaBadge} bottomLabel={badge} variant="primary">
                            {icon}
                            {showTitle && (
                                <div className={cx('d-xs-block', 'd-lg-none', style.mobileTitle)}>{title}</div>
                            )}
                        </Badge>
                    </Tooltip>
                </Item>
            </Link>
        )
    }

    return links?.map((link, index) => {
        if (!link) return null

        const {
            requiredFeatures,
            override,
            fallback,
        } = link

        return (
            <AccessControl
                key={index}
                override={override}
                requiredFeatures={requiredFeatures}
                fallback={fallback ? () => renderDesktopLink(fallback, index) : null}
            >
                {renderDesktopLink(link, index)}
            </AccessControl>
        )
    })
}

export default Links
