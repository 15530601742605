import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form as ReactFinalForm, Field } from 'react-final-form'
import { Boutton as Button, Text } from '@piconetworks/ui'
import Form from '@piconetworks/pkg-form'
import ActionModal from 'components/ActionModal'
import AccountModule from 'modules/account'
import { getLandingPageBaseUrl, HYPE_PAGE_PREFIX} from 'utils/constants/domainTypes'
import styles from './Modals.module.scss'

class ViewSiteAsUser extends React.PureComponent {
    render() {
        const { info, dispatch, action } = this.props

        const options = info?.primary_urls.map(primary_url => ({
            value: primary_url,
            label: primary_url,
        }))

        options && options.push({
            value: 'landing_page',
            label: `${getLandingPageBaseUrl}/${HYPE_PAGE_PREFIX}${info.username}`,
        })

        return (
            <ReactFinalForm
                initialValues={{ redirect_url: options[0]?.value || '' }}
                onSubmit={(values) => {
                    dispatch(
                        action({
                            page: values.redirect_url === 'landing_page' ? 'landing_page' : 'primary_domain',
                            domain: values.redirect_url
                        })
                    )
                }}
                render={({ handleSubmit, form }) => {
                    return (
                        <ActionModal.Wrapper
                            type="info"
                            actionKey="viewSiteAsUser"
                            closeAction={form.reset}
                        >
                            <form onSubmit={handleSubmit}>
                                <ActionModal.Header header={'Log in as user'} />
                                <ActionModal.Body className={styles.dropdownScroll}>
                                    <Text.P>Choose where you'd like to log in as this user.</Text.P>
                                    <Field
                                        label="Domain"
                                        name="redirect_url"
                                        options={options}
                                        component={Form.Dropdown}
                                        insideOfModal={true}
                                    />
                                </ActionModal.Body>
                                <ActionModal.Footer
                                    flexboxContainer={{
                                        justifyContent: 'end',
                                        alignItems: 'center'
                                    }}
                                    cancelButton={
                                        <Button block variant="secondary">
                                            Cancel
                                        </Button>
                                    }
                                    actionButton={
                                        <Button
                                            type="submit"
                                            block
                                            variant="primary"
                                        >
                                            Login as a user
                                        </Button>
                                    }
                                />
                            </form>
                        </ActionModal.Wrapper>
                    )
                }}
            />
        )
    }
}

ViewSiteAsUser.propTypes = {
    dispatch: PropTypes.func,
    info: PropTypes.object,
    menuLink: PropTypes.string
}

const mapStateToProps = (state) => ({
    info: AccountModule.selectors.getAccountInfo(state)
})

export default connect(mapStateToProps)(ViewSiteAsUser)
