import { call, put, putResolve } from 'redux-saga/effects'

import exchangeClaim from 'modules/claim/exchangeClaim'

function* socialLogin({ claim, token, refresh_token, creators, account_info, publisher_id, types }) {
    try {
        let accessToken = token
        let refreshToken = refresh_token

        if (claim) {
            const claimResponse = yield call(exchangeClaim, claim)

            if (claimResponse?.token && claimResponse?.refresh_token) {
                accessToken = claimResponse?.token
                refreshToken = claimResponse?.refresh_token
            }
        }

        yield putResolve(creators.tokenLogin({
            accessToken,
            refreshToken,
        }))

        yield put({
            type: types.SOCIAL_LOGIN_SUCCESS,
            payload: {
                publisher_id,
                account_info: {
                    publisher_id,
                    name: account_info?.name,
                    email: account_info?.email,
                    publication: account_info?.publication
                }
            }
        })

        yield put(creators.postLogin())
        
    } catch (error) {
        yield put({
            type: types.SOCIAL_LOGIN_ERROR,
            error
        })
    }
}

export default socialLogin
