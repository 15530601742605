import { call, putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import ModalModule from 'modules/modal'
import accountModule from 'modules/account'
import appModule from 'modules/app'
import UsersModule from 'modules/users'
import ErrorService from 'utils/services/ErrorService'

function* reverseCancellation({ payload: user_state_ids, callback = () => {}, types }) {
    let endpoint_url = endpoints.REACTIVATE_PLANS
    const publisherid = yield select(accountModule.selectors.getPublisherId)

    if (user_state_ids.length == 1) {
        endpoint_url = `${endpoint_url}/${user_state_ids[0]}`
    }

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: endpoint_url,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    user_state_ids
                }
            }
        })

        yield put({
            type: types.REVERSE_CANCELLATION_SUCCESS,
            payload: data
        })

        yield put({
            type: types.FETCH_SELECTED_USERS,
            payload: user_state_ids
        })

        if (user_state_ids.length === 1) {
            yield put(UsersModule.creators.reindexUser({
                user_state_id: user_state_ids[0],
                showNotification: false,
            }))
        }

        yield put(
            ModalModule.creators.closeModal({
                key: 'reverseCancellation',
                actionFinished: true
            })
        )

        yield put(
            appModule.creators.addNotification({
                type: 'success',
                message: `Recurring payment${user_state_ids.length > 1 ? 's' : ''} reactivated.`
            })
        )

        yield call(callback, null)
    } catch (error) {

        const submissionError = new ErrorService(error).makeANiceSubmissionError()
        yield call(callback, submissionError)

        yield put({
            type: types.REVERSE_CANCELLATION_ERROR,
            error
        })
    }
}

export default reverseCancellation
