import currency from 'currency.js'
import dayjs from 'dayjs'
import styles from '../PaymentSummary.module.scss'

const AppSummary = ({ appName = null, appPrice = null, startDate = null, endDate = null }) => {
    return (
        <div className={styles['summary-item']}>
            <div className={styles.name}>
                <span>{appName}</span>
                {startDate && endDate && (
                    <small>
                        {dayjs(startDate).format('MMM DD, YYYY')} - {dayjs(endDate).format('MMM DD, YYYY')}
                    </small>
                )}
            </div>
            <div className={styles.price}>${currency(appPrice).toString()}</div>
        </div>
    )
}

export default AppSummary
