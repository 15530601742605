import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'

import AccountModule from 'modules/account'

import endpoints from 'utils/lib/endpoints'

function* getFileStackKeys({
    duck,
    payload: {
        bucket
    }
}) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.FILESTACK_SIGN,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                data: {
                    bucket,
                }
            }
        })

        yield put({
            type: duck.types.GET_FILE_STACK_KEYS_SUCCESS,
            payload: data,
        })

    } catch (error) {
        yield put({
            type: duck.types.GET_FILE_STACK_KEYS_ERROR,
            error,
        })
    }
}

export default getFileStackKeys
