import { takeEvery, takeLatest } from 'redux-saga/effects'

const takes = (duck) => ([
    takeLatest(duck.types.GET_BLASTS, duck.sagas.getBlasts),
    takeEvery(duck.types.GET_CONVERSATIONS, duck.sagas.getConversations),
    takeEvery(duck.types.GET_CONVERSATION_BATCH, duck.sagas.getConversationBatch),
    takeEvery(duck.types.CREATE_BLAST, duck.sagas.createBlast),
    takeEvery(duck.types.GET_FAILED_MESSAGES, duck.sagas.getFailedMessages),
    takeEvery(duck.types.ENABLE_MESSAGING, duck.sagas.enableMessaging),
    takeEvery(duck.types.UPDATE_BLAST, duck.sagas.updateBlast),

    // Side Effects of Create Blast Action
    takeEvery(duck.types.CREATE_BLAST_SUCCESS, duck.sagas.blastSent),
    takeEvery(duck.types.CREATE_BLAST_ERROR, duck.sagas.blastErrored),

])

export default takes
