import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Box, Heading, Modal } from '@piconetworks/ui'
import Context from './Context'

import styles from './ActionModal.module.scss'

const Header = ({ className = '', header = '', small = false, preset = 'default', center =  false, scrollable = true, ...props }) => {
    return (
        <Context.Consumer>
            {({ type }) => {
                return (
                    <Modal.Header 
                        scrollable={scrollable}
                        className={cx(
                            styles.fullWidth,
                            { [styles.small]: small },
                            { [styles.danger]: type() === 'danger', [styles.info]: type() === 'info' },
                            className
                        )}
                        {...props}
                    >
                        <Modal.Padding 
                            className={cx(
                                {
                                    [styles.noPadding]: preset === 'none',
                                }, 
                            )}
                        >
                            <Box.Flexbox.Container 
                                alignItems="center" 
                                className={cx(
                                    {
                                        [styles.header]: preset === 'default',
                                    }, 
                                    {[styles.center]: center})}
                            >
                                {preset === 'default' &&
                                    <Heading.H1 className={cx(
                                        {
                                            [styles.head]: preset === 'default',
                                        }, 
                                    )}>
                                        {header}
                                    </Heading.H1>
                                }
                                {preset === 'none' &&
                                    header
                                }
                                
                            </Box.Flexbox.Container>
                        </Modal.Padding>
                    </Modal.Header>
                )
            }}
        </Context.Consumer>
    )
}

Header.propTypes = {
    center: PropTypes.bool,
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    header: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
    preset: PropTypes.oneOf(['default', 'none']),
    scrollable: PropTypes.bool,
    small: PropTypes.bool,
}

Header.defaultProps = {
    center: false,
    preset: 'default',
    scrollable: true
}

export default Header
