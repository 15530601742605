import produce from 'immer'

export const initialState = {
    data: {
        users: null,
        page: null,
        pages: null,
        pageSize: null,
        count: null
    },
    fetchedUsers: [],
    selectedUsers: [],
    status: 'INITIATED',
    error: false,
    user: {},
    newsletters: null,
    exportCSV: {
        loading: false,
        request_id: null,
        file: null,
        progress: 0,
        status: 'INITIATED',
        finished: false
    },
    selectedActivity: null,
    userActivities: [],
    meta: {},
    user_import: {
        isImportModalOpen: false,
        error: null,
        import_id: null,
        user_jwt: null,
        template_key: null,
        progress: null,
        notify: {
            check: {
                email: 0,
                phone: 0
            },
            error: null
        },
    },
    userImportLoading: false
}

const reducer = (state, action, { types, statuses }) =>
    produce(state, (draftState) => {
        switch (action.type) {
            // loading
            case types.LOGIN_AS_USER:
            case types.UPDATE_USER_NEWSLETTERS:
            case types.REVERSE_CANCELLATION:
            case types.FETCH_SELECTED_USERS:
            case types.CANCEL_PLAN:
            case types.DELETE_CONTACTS:
            case types.GET_PAGINATED_USERS:
            case types.GRANT_FREE_ACCESS:
            case types.UPDATE_SINGLE_USER:
            case types.FETCH_SELECTED_USER_ACTIVITY:
            case types.GET_USERS_META:
                draftState.status = statuses.LOADING
                draftState.error = false
                break

            // success sans payload
            case types.LOGIN_AS_USER_SUCCESS:
            case types.CANCEL_PLAN_SUCCESS:
            case types.REVERSE_CANCELLATION_SUCCESS:
            case types.GRANT_FREE_ACCESS_SUCCESS:
                draftState.status = statuses.READY
                break

            // errors
            case types.LOGIN_AS_USER_ERROR:
            case types.REINDEX_USER_ERROR:
            case types.GET_PAGINATED_USERS_ERROR:
            case types.UPDATE_USER_NEWSLETTERS_ERROR:
            case types.GRANT_FREE_ACCESS_ERROR:
            case types.GET_USER_NEWSLETTERS_ERROR:
            case types.REVERSE_CANCELLATION_ERROR:
            case types.FETCH_SELECTED_USER_ACTIVITY_ERROR:
            case types.FETCH_SELECTED_USERS_ERROR:
            case types.CANCEL_PLAN_ERROR:
            case types.DELETE_CONTACTS_ERROR:
            case types.UPDATE_SINGLE_USER_ERROR:
            case types.REQUEST_SINGLE_USER_ERROR:
            case types.GET_USERS_META_ERROR:
                draftState.status = statuses.ERRORED
                draftState.error = action.error
                break

            case types.GET_PAGINATED_USERS_SUCCESS:
                draftState.data = action.data
                // draftState.selectedUsers = []
                draftState.fetchedUsers = []
                draftState.status = statuses.READY
                break

            case types.SET_SELECTED_USERS:
                // goes through existing fetched list of users and determines info based on potentially stale data
                let selectedUsers = []
                action.payload.forEach((user) => {
                    state.data.users.find((item) => {
                        if (item.user_state_id === user) {
                            selectedUsers.push(item)
                        }
                    })
                })
                draftState.fetchedUsers = []
                draftState.selectedUsers = selectedUsers
                break

            case types.DELETE_CONTACTS_SUCCESS:
            case types.RESET_SELECTED_USERS:
                // draftState.status = statuses.READY
                draftState.selectedUsers = []
                draftState.fetchedUsers = []
                break

            case types.FETCH_SELECTED_USER_ACTIVITY_SUCCESS:
                draftState.status = statuses.READY
                draftState.userActivities = action.payload
                break

            case types.FETCH_SELECTED_USERS_SUCCESS:
                // as opposed to SET_SELECTED_USERS, this goes and grabs the latest info from the API
                draftState.fetchedUsers = action.payload
                draftState.status = statuses.READY
                break

            case types.REINDEX_USER:
            case types.REQUEST_SINGLE_USER:
                draftState.error = false
                draftState.status = statuses.LOADING
                break

            case types.REINDEX_USER_SUCCESS:
            case types.REQUEST_SINGLE_USER_SUCCESS:
                draftState.status = statuses.READY
                draftState.user = action.data
                draftState.fetchedUsers = [action.data]
                draftState.selectedUsers = [action.data]
                break

            case types.UPDATE_SINGLE_USER_SUCCESS:
                draftState.user.email.value = action.data.email
                draftState.user.name.last = action.data.last_name
                draftState.user.name.first = action.data.first_name
                draftState.user.name.value = action.data.full_name
                draftState.status = statuses.READY
                break

            case types.GET_USER_NEWSLETTERS:
                draftState.status = statuses.LOADING
                draftState.error = false
                draftState.newsletters = null
                break

            case types.UPDATE_USER_NEWSLETTERS_SUCCESS:
            case types.GET_USER_NEWSLETTERS_SUCCESS:
                draftState.status = statuses.READY
                draftState.newsletters = action.data
                break

            case types.EXPORT_CSV:
                draftState.exportCSV.loading = statuses.LOADING
                break

            case types.EXPORT_CSV_SUCCESS:
                draftState.exportCSV.loading = statuses.READY
                draftState.exportCSV.request_id = action.payload.request_id
                break

            case types.EXPORT_CSV_ERROR:
                draftState.exportCSV.loading = statuses.READY
                draftState.exportCSV.request_id = null
                draftState.error = action.error
                break

            case types.UPDATE_EXPORT_PROGRESS:
                draftState.exportCSV.loading = statuses.LOADING
                draftState.exportCSV.finished = false
                break

            case types.UPDATE_EXPORT_PROGRESS_SUCCESS:
                draftState.exportCSV.loading = statuses.READY
                draftState.exportCSV.progress = action.payload.progress
                draftState.exportCSV.status = action.payload.status
                draftState.exportCSV.finished = action.payload.progress == 100
                draftState.exportCSV.file = action.payload.progress == 100 && action.payload.file
                break

            case types.UPDATE_EXPORT_PROGRESS_ERROR:
                draftState.exportCSV.loading = statuses.READY
                draftState.exportCSV.error = action.error
                break

            case types.RESET_EXPORT:
                draftState.exportCSV = initialState.exportCSV
                break

            case types.SELECT_ACTIVITY:
                draftState.selectedActivity = action.payload
                break

            case types.GET_USERS_META_SUCCESS:
                draftState.meta = action.payload
                break

            case types.IMPORT_USERS:
                break

            case types.IMPORT_USERS_SUCCESS:
                draftState.importedUsers = action.payload
                draftState.user_import.isImportModalOpen = false
                draftState.user_import.import_id = null
                draftState.user_import.user_jwt = null
                draftState.user_import.template_key = null
                draftState.user_import.progress = action.data
                break

            case types.START_USER_IMPORT:
                draftState.userImportLoading = true
                break

            case types.START_USER_IMPORT_SUCCESS:
                draftState.user_import.import_id = action.payload.import_id
                draftState.user_import.user_jwt = action.payload.user_jwt
                draftState.user_import.template_key = action.payload.template_key
                draftState.user_import.isImportModalOpen = false

                // we are gonna remove loading when IMPORT_MODAL_OPEN action fire
                draftState.userImportLoading = true

                break

            case types.START_USER_IMPORT_ERROR:
                draftState.user_import.error = action.error
                draftState.user_import.isImportModalOpen = false
                draftState.user_import.import_id = null
                draftState.user_import.user_jwt = null
                draftState.user_import.template_key = null
                draftState.userImportLoading = false
                break
            case types.USER_IMPORT_CANCELLED:
                draftState.user_import.import_id = null
                draftState.user_import.user_jwt = null
                draftState.user_import.template_key = null
                draftState.user_import.isImportModalOpen = false
                draftState.userImportLoading = false
                break

            case types.IMPORT_USERS_ERROR:
                draftState.user_import.error = action.error

                draftState.user_import.import_id = null
                draftState.user_import.user_jwt = null
                draftState.user_import.template_key = null
                draftState.user_import.isImportModalOpen = false
                draftState.userImportLoading = false

                break

            case types.NOTIFY_USER_IMPORT:
                break

            case types.NOTIFY_IMPORT_USER_CHECK:
                draftState.user_import.notify = {}
                draftState.user_import.notify.check = {}
                draftState.user_import.notify.check.email = 0
                draftState.user_import.notify.check.phone = 0
                draftState.user_import.notify.error = null
                break

            case types.GET_NOTIFIABLE_IMPORTED_USER_COUNT_SUCCESS:
                draftState.user_import.notify = {}
                draftState.user_import.notify.check = {}
                draftState.user_import.notify.check.email = action.payload?.not_notified_email_user_count
                draftState.user_import.notify.check.phone = action.payload?.not_notified_sms_user_count
                draftState.user_import.notify.error = null
                break

            case types.GET_NOTIFIABLE_IMPORTED_USER_COUNT_ERROR:
                draftState.user_import.notify = {}
                draftState.user_import.notify.check = {}
                draftState.user_import.notify.check.email = 0
                draftState.user_import.notify.check.phone = 0
                draftState.user_import.notify.error = action.payload
                break

            case types.IMPORT_MODAL_OPEN:
                draftState.user_import.isImportModalOpen = true
                draftState.userImportLoading = false
                break

            case types.IMPORT_MODAL_CLOSE:
                draftState.user_import.isImportModalOpen = false
                break

            case types.CHECK_USER_IMPORT_STATUS_SUCCESS: 
                draftState.user_import.progress = action.payload
                break

            case types.CHECK_USER_IMPORT_STATUS_ERROR: 
                draftState.user_import.error = action.error
                draftState.user_import.progress = null
                break

            default:
                return state
        }
    })

export default reducer
