import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { FORM_ERROR } from 'final-form'
import {
    oneTimeCodeError,
    oneTimeCodeInProgress,
    confirmOneTimeCodeInProgress,
    oneTimeCodeSent,
    requestOneTimeCode,
    oneTimeCodeConfirmed,
    confirmOneTimeCode,
    actions
} from './slice'

const useOtp = () => {
    const dispatch = useDispatch()
    const _oneTimeCodeError = useSelector(oneTimeCodeError)
    const _oneTimeCodeInProgress = useSelector(oneTimeCodeInProgress)
    const _confirmOneTimeCodeInProgress = useSelector(confirmOneTimeCodeInProgress)
    const _oneTimeCodeSent = useSelector(oneTimeCodeSent)
    const _oneTimeCodeConfirmed = useSelector(oneTimeCodeConfirmed)

    const sendOtp = useCallback(async(phone) => {
        try {
            const resultAction = await dispatch(requestOneTimeCode(phone))
            const promiseResult = unwrapResult(resultAction)
            return promiseResult

        } catch (error) {
            throw error
        }
    }, [dispatch])

    const confirmOtp = useCallback(async ({ phone, code = null }) => {
        try {
            const resultAction = await dispatch(confirmOneTimeCode({
                code,
                phone
            }))
            console.log('resultAction', resultAction)
            const promiseResult = unwrapResult(resultAction)
            return promiseResult
        } catch (error) {
            throw error
        }
    }, [dispatch])

    return {
        oneTimeCodeError: _oneTimeCodeError,
        oneTimeCodeSent: _oneTimeCodeSent,
        oneTimeCodeInProgress: _oneTimeCodeInProgress,
        confirmOneTimeCodeInProgress: _confirmOneTimeCodeInProgress,
        oneTimeCodeConfirmed: _oneTimeCodeConfirmed,
        resetOTP: actions.resetOTP,
        sendOtp,
        confirmOtp,
    }
}

export default useOtp
