import {
    initialState,
    reducer,
    takes,
} from './slice'

import useNfcEditor from './useNfcEditor'
import useNfcTags from './useNfcTags'
import onClickSetHypeCardPage from './onClickSetHypeCardPage'
import useDeliveryFailedPopup from './useDeliveryFailedPopup'

export default {
    initialState,
    reducer,
    takes,
}

export {
    useNfcEditor,
    useNfcTags,
    onClickSetHypeCardPage,
    useDeliveryFailedPopup,
}
