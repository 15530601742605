/** @namespace FeatureManager.useCart */

import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import {
    doAppPurchase,
    getPurchaseStatus,
    isPurchaseStatusSuccess,
    isPurchaseStatusErrors,
    isPurchaseStatusPending,
    initializeAppPurchase,
} from '../slice'
import usePurchaseErrors from './usePurchaseError'



const useCart = ({
    ctaId = null,
    ctaSessionId = null,
    userId = null,
    publisherId = null,
    onCartStarted = (props = {}) => {},
    onComplete = (props = {}) => {},
    onCartDismissed = (props = {}) => {},
    onCartFailed = (props = {}) => {},
} = {}) => {
    const purchaseStatus = useSelector(getPurchaseStatus)
    const purchaseErrors = usePurchaseErrors()

    const failed = useSelector(isPurchaseStatusErrors)
    const completed = useSelector(isPurchaseStatusSuccess)
    const inProgress = useSelector(isPurchaseStatusPending)

    const dispatch = useDispatch()

    const [isVisible, setIsVisible] = useState(false)
    const [cartSessionId, setCartSessionId] = useState(null)

    const present = useCallback(() => {
        setIsVisible(true)
    }, [setIsVisible])

    const hide = useCallback(() => {
        setIsVisible(false)
    }, [setIsVisible])

    const checkout = useCallback(
        (appProductId) => {
            dispatch(
                doAppPurchase({
                    appProductId,
                    publisherId,
                })
            )
        },
        [dispatch, publisherId]
    )

    useEffect(() => {
        const uuid = uuidv4()

        setCartSessionId(uuid)

        onCartStarted({
            user_id: userId,
            publisher_id: publisherId,
            cta_id: ctaId,
            cta_session_id: ctaSessionId,
            cart_session_id: uuid,
        })
        present()
        return () => {
            dispatch(initializeAppPurchase())
        }
    }, [setCartSessionId])

    useEffect(() => {
        if (completed) {
            onComplete({
                user_id: userId,
                publisher_id: publisherId,
                cta_id: ctaId,
                cta_session_id: ctaSessionId,
                cart_session_id: cartSessionId,
            })
        }
    }, [completed, cartSessionId])

    useEffect(() => {
        if (failed) {
            onCartFailed({
                user_id: userId,
                publisher_id: publisherId,
                cta_id: ctaId,
                cta_session_id: ctaSessionId,
                cart_session_id: cartSessionId,
                cart_error: purchaseErrors,
            })
        }
    }, [failed, cartSessionId])

    const dismissCart = useCallback(() => {
        onCartDismissed({
            user_id: userId,
            publisher_id: publisherId,
            cta_id: ctaId,
            cta_session_id: ctaSessionId,
            cart_session_id: cartSessionId,
        })
    }, [userId, ctaId, cartSessionId])

    const response = {
        purchaseStatus,
        purchaseErrors,
        usePurchaseErrors,
        completed,
        failed,
        inProgress,
        isVisible,
        present,
        hide,
        checkout,
        dismissCart,
        userId,
        publisherId,
    }

    return response
}

export default useCart
