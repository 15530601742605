const themeConstants = [
    {
        name: 'Aura',
        imageUrl: 'https://assets.hype.co/images/theme%3DAura.png',
        bgImageUrl: 'https://assets.hype.co/images/Aura.png',
        free: true,
    },
    {
        name: 'Bauhaus',
        imageUrl: 'https://assets.hype.co/images/theme%3DBauhaus.png',
        bgImageUrl: 'https://assets.hype.co/images/Bauhaus.png',
        free: true,
    },
    {
        name: 'Candy Floss',
        imageUrl: 'https://assets.hype.co/images/theme%3DCandy-Floss.png',
        bgImageUrl: 'https://assets.hype.co/images/Candy-Floss.png',
        free: true,
    },
    {
        name: 'Ghost',
        imageUrl: 'https://assets.hype.co/images/theme%3DGhost.png',
        bgImageUrl: 'https://assets.hype.co/images/Ghost.png',
        free: true,
    },
    {
        name: 'Headliner Dark',
        imageUrl: 'https://assets.hype.co/images/theme%3DHeadliner-Dark.png',
        bgImageUrl: 'https://assets.hype.co/images/Headliner-Dark.png',
        free: true,
    },
    {
        name: 'Headliner Light',
        imageUrl: 'https://assets.hype.co/images/theme%3DHeadliner-Light.png',
        bgImageUrl: 'https://assets.hype.co/images/Headliner-Light.png',
        free: true,
    },
    {
        name: 'Honeybee',
        imageUrl: 'https://assets.hype.co/images/theme%3DHoneybee.png',
        bgImageUrl: 'https://assets.hype.co/images/Honeybee.png',
        free: true,
    },
    {
        name: 'Limewire',
        imageUrl: 'https://assets.hype.co/images/theme%3DLimewire.png',
        bgImageUrl: 'https://assets.hype.co/images/Limewire.png',
        free: true,
    },
    {
        name: 'Linen',
        imageUrl: 'https://assets.hype.co/images/theme%3DLinen.png',
        bgImageUrl: 'https://assets.hype.co/images/Linen.png',
        free: true,
    },
    {
        name: 'Marrakech',
        imageUrl: 'https://assets.hype.co/images/theme%3DMarrakech.png',
        bgImageUrl: 'https://assets.hype.co/images/Marrakech.png',
        free: true,
    },
    {
        name: 'Matrix',
        imageUrl: 'https://assets.hype.co/images/theme%3DMatrix.png',
        bgImageUrl: 'https://assets.hype.co/images/Matrix.png',
        free: true,
    },
    {
        name: 'Prince',
        imageUrl: 'https://assets.hype.co/images/theme%3DPrince.png',
        bgImageUrl: 'https://assets.hype.co/images/Prince.png',
        free: true,
    },
    {
        name: 'Spotlight',
        imageUrl: 'https://assets.hype.co/images/theme%3DSpotlight.png',
        bgImageUrl: 'https://assets.hype.co/images/Spotlight.png',
        free: true,
    },
    {
        name: 'Watermelon',
        imageUrl: 'https://assets.hype.co/images/theme%3DWatermelon.png',
        bgImageUrl: 'https://assets.hype.co/images/Watermelon.png',
        free: true,
    },
    {
        name: 'Wednesday',
        imageUrl: 'https://assets.hype.co/images/theme%3DWednesday.png',
        bgImageUrl: 'https://assets.hype.co/images/Wednesday.png',
        free: true,
    },
    {
        name: 'Alta',
        imageUrl: 'https://assets.hype.co/images/Alta.png',
        bgImageUrl: 'https://assets.hype.co/images/Alta.png',
        free: true,
    }
]

const themeConstantsMap = themeConstants?.reduce((acc, { name, ...rest }) => {
    acc[name] = {
        name,
        ...rest,
    }

    return acc
}, {})

export {
    themeConstants,
    themeConstantsMap,
}
