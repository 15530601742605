import { put, putResolve, select, call } from 'redux-saga/effects'
import { FORM_ERROR } from 'final-form'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* deleteSocialLink({ payload, types, callback }) {
    const { social_links } = payload

    try {
        const publisherid = yield select(
            AccountModule.selectors.getPublisherId
        )

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: endpoints.SOCIAL_LINKS,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                timeout: 30000,
                data: social_links,
            },
        })

        yield put({
            type: types.DELETE_SOCIAL_LINK_SUCCESS,
            payload: {
                ...data,
            },
        })
        yield put({
            type: types.GET_SOCIAL_LINKS,
        })

        yield put(
            AppModule.creators.addNotification({
                message: 'Social media profiles successfully updated',
                type: 'success',
            })
        )
    } catch (error) {
        if (
            error.response &&
            error.response.data &&
            error.response.data.message
        ) {
            const submissionError = {
                [FORM_ERROR]: error.response.data.message,
            }
            if (error.response.data.errors) {
                const fieldErrors = Object.assign(
                    submissionError,
                    ...error.response.data.errors
                )
                yield call(callback, fieldErrors)
            } else {
                yield call(callback, submissionError)
            }
        }
        yield put(
            AppModule.creators.addNotification({
                message: 'An error occurred while saving',
                type: 'danger',
            })
        )
        yield put({
            type: types.DELETE_SOCIAL_LINK_ERROR,
            error,
        })
    }
}

export default deleteSocialLink
