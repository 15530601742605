/** @namespace FeatureManager */

import {
    featureManagerInitialState,
    featureManagerReducer,
    takes,
    fetchApps,
    initialize,
} from './slice'

import useFeatureManager from './hooks/useFeatureManager'

import useCallToAction from './hooks/useCallToAction'
import useCart from './hooks/useCart'
import useFeatureManagerMetrics from './hooks/useFeatureManagerMetrics'
import useCancellation from './hooks/useCancellation'

import withFeatureManager from './HOCs/withFeatureManager'

import * as constants from './constants'

import Example from './components/Example'

export default {
    featureManagerInitialState,
    featureManagerReducer,
    takes,
}

export {
    initialize,
    useFeatureManager,
    useCallToAction,
    useCart,
    useFeatureManagerMetrics,
    useCancellation,
    Example,
    constants,
    fetchApps,
    withFeatureManager,
}
