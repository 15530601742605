import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* removeStaff({ types, payload }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: `${endpoints.STAFF}/${payload.id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
            }
        })

        if (status === 200) {
            yield put({
                type: types.REMOVE_STAFF_SUCCESS,
                payload: payload.id
            })
        } else {
            throw Error('An uncatched error occured.')
        }
    } catch (error) {
        // Show api error if there is in the error response if doesnt exists show generic error
        // if (!has_access) {
        // List of api error messages
        // You don't have access to modify this user'
        //'Publisher user not found'
        //'You cannot remove yourself from the team'
        yield put(
            AppModule.creators.addNotification({
                message: error?.response?.data?.message || 'Something went wrong',
                type: 'danger'
            })
        )
        yield put({ type: types.REMOVE_STAFF_ERROR, error })
    }
}

export default removeStaff
