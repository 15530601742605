import getMetrics from './getMetrics'
import getRecurringPayments from './getRecurringPayments'
import setTimeDimension from './setTimeDimension'

const sagas = (duck) => ({
    *getMetrics(payload) {
        yield getMetrics({ ...payload, ...duck })
    },
    *getRecurringPayments(payload) {
        yield getRecurringPayments({ ...payload, ...duck })
    },
    *setTimeDimension(payload) {
        yield setTimeDimension({ ...payload, ...duck })
    },
})

export default sagas
