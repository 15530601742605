const actions = {}

actions.types = [
    'GET_MY_APPEARANCE',
    'GET_MY_APPEARANCE_SUCCESS',
    'GET_MY_APPEARANCE_ERROR',
    'GET_MY_ORGANIZATION',
    'GET_MY_ORGANIZATION_SUCCESS',
    'GET_MY_ORGANIZATION_ERROR',
    'GET_MY_PROFILE',
    'GET_MY_PROFILE_SUCCESS',
    'GET_MY_PROFILE_ERROR',
    'GET_ME',
    'GET_ME_SUCCESS',
    'GET_ME_ERROR',
    'GET_SOCIAL_LINKS',
    'GET_SOCIAL_LINKS_SUCCESS',
    'GET_SOCIAL_LINKS_ERROR',
    'GET_MY_DOMAINS',
    'GET_MY_DOMAINS_SUCCESS',
    'GET_MY_DOMAINS_ERROR',
    'GET_MY_ARTICLE_LOCKING_SETTINGS',
    'GET_MY_ARTICLE_LOCKING_SETTINGS_SUCCESS',
    'GET_MY_ARTICLE_LOCKING_SETTINGS_ERROR',
    'GET_MY_CONTENT_RESTRICTION_SETTINGS',
    'GET_MY_CONTENT_RESTRICTION_SETTINGS_SUCCESS',
    'GET_MY_CONTENT_RESTRICTION_SETTINGS_ERROR',
    'GET_MY_PAYMENT_HISTORY',
    'GET_MY_PAYMENT_HISTORY_SUCCESS',
    'GET_MY_PAYMENT_HISTORY_ERROR',
    'GET_BILLING_INFORMATION',
    'GET_BILLING_INFORMATION_SUCCESS',
    'GET_BILLING_INFORMATION_ERROR',
    'GET_USERNAME',
    'GET_USERNAME_SUCCESS',
    'GET_USERNAME_ERROR',
    'GET_FREE_ACCESS',
    'GET_FREE_ACCESS_SUCCESS',
    'GET_FREE_ACCESS_ERROR',
    'CREATE_FREE_ACCESS',
    'CREATE_FREE_ACCESS_SUCCESS',
    'CREATE_FREE_ACCESS_ERROR',
    'UPDATE_FREE_ACCESS',
    'UPDATE_FREE_ACCESS_SUCCESS',
    'UPDATE_FREE_ACCESS_ERROR',
    'ARCHIVE_FREE_ACCESS',
    'ARCHIVE_FREE_ACCESS_SUCCESS',
    'ARCHIVE_FREE_ACCESS_ERROR',
    'UNARCHIVE_FREE_ACCESS',
    'UNARCHIVE_FREE_ACCESS_SUCCESS',
    'UNARCHIVE_FREE_ACCESS_ERROR',
    'SAVING_MY_APPEARANCE',
    'SAVING_MY_APPEARANCE_SUCCESS',
    'SAVING_MY_APPEARANCE_ERROR',
    'SAVING_MY_ORGANIZATION',
    'SAVING_MY_ORGANIZATION_SUCCESS',
    'SAVING_MY_ORGANIZATION_ERROR',
    'SAVING_MY_PROFILE',
    'SAVING_MY_PROFILE_SUCCESS',
    'SAVING_MY_PROFILE_ERROR',
    'CREATE_SOCIAL_LINK',
    'CREATE_SOCIAL_LINK_SUCCESS',
    'CREATE_SOCIAL_LINK_ERROR',
    'UPDATE_SOCIAL_LINK',
    'UPDATE_SOCIAL_LINK_SUCCESS',
    'UPDATE_SOCIAL_LINK_ERROR',
    'DELETE_SOCIAL_LINK',
    'DELETE_SOCIAL_LINK_SUCCESS',
    'DELETE_SOCIAL_LINK_ERROR',
    'SAVING_MY_PASSWORD',
    'SAVING_MY_PASSWORD_SUCCESS',
    'SAVING_MY_PASSWORD_ERROR',
    'SAVING_WEBSITE',
    'SAVING_WEBSITE_SUCCESS',
    'SAVING_WEBSITE_ERROR',
    'DOMAINS_HEALTH_CHECK',
    'DOMAINS_HEALTH_CHECK_SUCCESS',
    'DOMAINS_HEALTH_CHECK_ERROR',
    'SAVING_EMAIL',
    'SAVING_EMAIL_SUCCESS',
    'SAVING_EMAIL_ERROR',
    'SAVING_BILLING_INFORMATION',
    'SAVING_BILLING_INFORMATION_SUCCESS',
    'SAVING_BILLING_INFORMATION_ERROR',
    'RESET_BILLING_ERROR',
    'SAVING_DEFAULT_LANDING_PAGE',
    'SAVING_DEFAULT_LANDING_PAGE_SUCCESS',
    'SAVING_DEFAULT_LANDING_PAGE_ERROR',
    'SAVING_DEFAULT_PAYMENT_OFFER',
    'SAVING_DEFAULT_PAYMENT_OFFER_SUCCESS',
    'SAVING_DEFAULT_PAYMENT_OFFER_ERROR',
    'SAVING_USERNAME',
    'SAVING_USERNAME_SUCCESS',
    'SAVING_USERNAME_ERROR',
    'ARTICLE_LOCKING',
    'ARTICLE_LOCKING_SUCCESS',
    'ARTICLE_LOCKING_ERROR',
    'CONTENT_RESTRICTION',
    'CONTENT_RESTRICTION_SUCCESS',
    'CONTENT_RESTRICTION_ERROR',
    'SUBMIT',
    'SUBMIT_SUCCESS',
    'SUBMIT_ERROR',
    'UPDATE_RESTRICTED_ACCESS',
    'UPDATE_RESTRICTED_ACCESS_SUCCESS',
    'UPDATE_RESTRICTED_ACCESS_ERROR',
    'GET_RESTRICTED_ACCESS',
    'GET_RESTRICTED_ACCESS_SUCCESS',
    'GET_RESTRICTED_ACCESS_ERROR',
    'DOUBLE_OPT_IN',
    'DOUBLE_OPT_IN_SUCCESS',
    'DOUBLE_OPT_IN_ERROR',
    'GET_DOUBLE_OPT_IN',
    'GET_DOUBLE_OPT_IN_SUCCESS',
    'GET_DOUBLE_OPT_IN_ERROR',
    'GET_STAFF',
    'GET_STAFF_SUCCESS',
    'GET_STAFF_ERROR',
    'INVITE_STAFF',
    'INVITE_STAFF_SUCCESS',
    'INVITE_STAFF_ERROR',
    'REMOVE_STAFF',
    'REMOVE_STAFF_SUCCESS',
    'REMOVE_STAFF_ERROR',
    'GET_MY_HIDDEN_ELEMENTS',
    'GET_MY_HIDDEN_ELEMENTS_SUCCESS',
    'GET_MY_HIDDEN_ELEMENTS_ERROR',
    'SAVE_HIDDEN_ELEMENTS',
    'SAVE_HIDDEN_ELEMENTS_SUCCESS',
    'SAVE_HIDDEN_ELEMENTS_ERROR',
    'GET_WEBHOOKS',
    'GET_WEBHOOKS_SUCCESS',
    'GET_WEBHOOKS_ERROR',
    'DELETE_WEBHOOK',
    'DELETE_WEBHOOK_SUCCESS',
    'DELETE_WEBHOOK_ERROR',
    'SIMULATE_WEBHOOK',
    'SIMULATE_WEBHOOK_ERROR',
    'GET_ZAPIER_KEY',
    'GET_ZAPIER_KEY_SUCCESS',
    'GET_ZAPIER_KEY_ERROR',
    'GENERATE_ZAPIER_KEY',
    'GENERATE_ZAPIER_KEY_SUCCESS',
    'GENERATE_ZAPIER_KEY_ERROR',
    'GET_TAXONOMIES',
    'GET_TAXONOMIES_SUCCESS',
    'GET_TAXONOMIES_ERROR',
    'SAVE_TAXONOMIES',
    'SAVE_TAXONOMIES_SUCCESS',
    'SAVE_TAXONOMIES_ERROR',
    'UPDATE_HELP_LINK',
    'UPDATE_HELP_LINK_SUCCESS',
    'UPDATE_HELP_LINK_ERROR',
    'SAVE_DEFAULT_OFFERS',
    'SAVE_DEFAULT_OFFERS_SUCCESS',
    'SAVE_DEFAULT_OFFERS_ERROR',
    'SAVE_ARTICLE_STRUCTURE',
    'SAVE_ARTICLE_STRUCTURE_SUCCESS',
    'SAVE_ARTICLE_STRUCTURE_ERROR',
    'SORT_FREE_ACCESS_RULES_ORDER',
    'SORT_FREE_ACCESS_RULES_ORDER_SUCCESS',
    'SORT_FREE_ACCESS_RULES_ORDER_ERROR',
    'GET_ACCESS_TOKENS',
    'GET_ACCESS_TOKENS_SUCCESS',
    'GET_ACCESS_TOKENS_ERROR',
    'CREATE_ACCESS_TOKEN',
    'CREATE_ACCESS_TOKEN_SUCCESS',
    'CREATE_ACCESS_TOKEN_ERROR',
    'ARCHIVE_ACCESS_TOKEN',
    'ARCHIVE_ACCESS_TOKEN_SUCCESS',
    'ARCHIVE_ACCESS_TOKEN_ERROR',
    'GET_LOGIN_OPTIONS',
    'UPDATE_LOGIN_OPTIONS',
    'UPDATE_LOGIN_OPTIONS_SUCCESS',
    'UPDATE_LOGIN_OPTIONS_ERROR',
    'DELETE_ACCOUNT',
    'DELETE_ACCOUNT_SUCCESS',
    'DELETE_ACCOUNT_ERROR'
]

actions.creators = ({ types }) => ({
    getAccessTokens: () => ({ type: types.GET_ACCESS_TOKENS }),
    createAccessToken: (payload, callback) => ({ type: types.CREATE_ACCESS_TOKEN, payload, callback }),
    archiveAccessToken: (payload, callback) => ({ type: types.ARCHIVE_ACCESS_TOKEN, payload, callback }),

    getTaxonomies: () => ({ type: types.GET_TAXONOMIES }),
    updateHelpLink: (payload, callback) => ({ type: types.UPDATE_HELP_LINK, payload, callback }),
    saveTaxonomies: (payload, callback) => ({ type: types.SAVE_TAXONOMIES, payload, callback }),

    getMyAppearance: () => ({ type: types.GET_MY_APPEARANCE }),
    saveMyAppearance: (payload, callback) => ({ type: types.SAVING_MY_APPEARANCE, payload, callback }),

    getMyBusiness: () => ({ type: types.GET_MY_ORGANIZATION }),
    getMyBusinessSuccess: (payload) => ({ type: types.GET_MY_ORGANIZATION_SUCCESS, payload }),
    getMyBusinessError: (error) => ({ type: types.GET_MY_ORGANIZATION_ERROR, error }),

    getMyDomains: () => ({ type: types.GET_MY_DOMAINS }),
    getMyDomainsSuccess: (payload) => ({ type: types.GET_MY_DOMAINS_SUCCESS, payload }),
    getMyDomainsError: (error) => ({ type: types.GET_MY_DOMAINS_ERROR, error }),

    getMyProfile: () => ({ type: types.GET_MY_PROFILE }),
    getMyProfileSuccess: (payload) => ({ type: types.GET_MY_PROFILE_SUCCESS, payload }),
    getMyProfileError: (error) => ({ type: types.GET_MY_PROFILE_ERROR, error }),

    getMe: () => ({ type: types.GET_ME }),
    getMeSuccess: (payload) => ({ type: types.GET_ME_SUCCESS, payload }),
    getMeError: (error) => ({ type: types.GET_ME_ERROR, error }),

    getSocialLinks: () => ({ type: types.GET_SOCIAL_LINKS }),
    getSocialLinksSuccess: (payload) => ({ type: types.GET_SOCIAL_LINKS_SUCCESS, payload }),
    getSocialLinksError: (error) => ({ type: types.GET_SOCIAL_LINKS_ERROR, error }),

    getMyArticleLockingSettings: () => ({ type: types.GET_MY_ARTICLE_LOCKING_SETTINGS }),
    getMyArticleLockingSettingsSuccess: (payload) => ({ type: types.GET_MY_ARTICLE_LOCKING_SETTINGS_SUCCESS, payload }),
    getMyArticleLockingSettingsError: (error) => ({ type: types.GET_MY_ARTICLE_LOCKING_SETTINGS_ERROR, error }),

    getMyContentRestrictionSettings: () => ({ type: types.GET_MY_CONTENT_RESTRICTION_SETTINGS }),
    getMyContentRestrictionSettingsSuccess: (payload) => ({ type: types.GET_MY_CONTENT_RESTRICTION_SETTINGS_SUCCESS, payload }),
    getMyContentRestrictionSettingsError: (error) => ({ type: types.GET_MY_CONTENT_RESTRICTION_SETTINGS_ERROR, error }),

    getBillingInformation: () => ({ type: types.GET_BILLING_INFORMATION }),
    getBillingInformationSuccess: (payload) => ({ type: types.GET_BILLING_INFORMATION_SUCCESS, payload }),
    getBillingInformationError: (error) => ({ type: types.GET_BILLING_INFORMATION_ERROR, error }),

    getUsername: () => ({ type: types.GET_USERNAME }),
    saveUsername: (payload, callback) => ({ type: types.SAVING_USERNAME, payload, callback }),
    saveDefaultLandingPage: (payload, callback) => ({ type: types.SAVING_DEFAULT_LANDING_PAGE, payload, callback }),
    saveDefaultPaymentOffer: (payload, callback) => ({ type: types.SAVING_DEFAULT_PAYMENT_OFFER, payload, callback }),

    saveMyBusiness: (payload, callback, showNotifications = true) => ({ type: types.SAVING_MY_ORGANIZATION, payload, callback, showNotifications }),
    settingsUpdated: (payload) => ({ type: types.SAVING_MY_ORGANIZATION_SUCCESS, payload }),
    saveMyBusinessError: (error) => ({ type: types.SAVING_MY_ORGANIZATION_ERROR, error }),

    saveProfile: (payload, callback, showNotifications = true) => ({ type: types.SAVING_MY_PROFILE, payload, callback, showNotifications }),
    saveProfileSuccess: (payload) => ({ type: types.SAVING_MY_PROFILE_SUCCESS, payload }),
    saveProfileError: (error) => ({ type: types.SAVING_MY_PROFILE_ERROR, error }),

    createSocialLink: (payload, callback) => ({ type: types.CREATE_SOCIAL_LINK, payload, callback }),
    createSocialLinkSuccess: (payload) => ({ type: types.CREATE_SOCIAL_LINK_SUCCESS, payload }),
    createSocialLinkError: (error) => ({ type: types.CREATE_SOCIAL_LINK_ERROR, error }),

    updateSocialLink: (payload, callback) => ({ type: types.UPDATE_SOCIAL_LINK, payload, callback }),
    updateSocialLinkSuccess: (payload) => ({ type: types.UPDATE_SOCIAL_LINK_SUCCESS, payload }),
    updateSocialLinkError: (error) => ({ type: types.UPDATE_SOCIAL_LINK_ERROR, error }),

    deleteSocialLink: (payload, callback) => ({ type: types.DELETE_SOCIAL_LINK, payload, callback }),
    deleteSocialLinkSuccess: (payload) => ({ type: types.DELETE_SOCIAL_LINK_SUCCESS, payload }),
    deleteSocialLinkError: (error) => ({ type: types.DELETE_SOCIAL_LINK_ERROR, error }),

    savePassword: (payload, callback) => ({ type: types.SAVING_MY_PASSWORD, payload, callback }),
    savePasswordSuccess: (payload) => ({ type: types.SAVING_MY_PASSWORD_SUCCESS, payload }),
    savePasswordError: (error) => ({ type: types.SAVING_MY_PASSWORD_ERROR, error }),

    saveBillingInformation: (payload) => ({ type: types.SAVING_BILLING_INFORMATION, payload }),
    saveBillingInformationSuccess: (payload) => ({ type: types.SAVING_BILLING_INFORMATION_SUCCESS, payload }),
    saveBillingInformationError: (error) => ({ type: types.SAVING_BILLING_INFORMATION_ERROR, error }),

    saveWebsiteOptions: (payload, callback) => ({ type: types.SAVING_WEBSITE, payload, callback }),
    saveArticleLocking: (payload, callback) => ({ type: types.ARTICLE_LOCKING, payload, callback }),
    saveContentRestrictionSettings: (payload, callback) => ({ type: types.CONTENT_RESTRICTION, payload, callback }),
    domainsHealthCheck: () => ({ type: types.DOMAINS_HEALTH_CHECK }),

    getDoubleOptIn: () => ({ type: types.GET_DOUBLE_OPT_IN }),
    doubleOptIn: (payload) => ({ type: types.DOUBLE_OPT_IN, payload }),

    getRestrictedAccess: () => ({ type: types.GET_RESTRICTED_ACCESS }),
    updateRestrictedAccess: (payload) => ({ type: types.UPDATE_RESTRICTED_ACCESS, payload }),

    getStaff: () => ({ type: types.GET_STAFF }),
    inviteStaff: (payload) => ({ type: types.INVITE_STAFF, payload }),
    removeStaff: (payload) => ({ type: types.REMOVE_STAFF, payload }),

    getMyHiddenElements: () => ({ type: types.GET_MY_HIDDEN_ELEMENTS }),
    saveHiddenElements: (payload, callback) => ({ type: types.SAVE_HIDDEN_ELEMENTS, payload, callback }),
    getFreeAccess: () => ({ type: types.GET_FREE_ACCESS }),
    createFreeAccess: (payload, url, callback) => ({ type: types.CREATE_FREE_ACCESS, payload, url, callback }),
    updateFreeAccess: (payload, url, callback) => ({ type: types.UPDATE_FREE_ACCESS, payload, url, callback }),
    archiveFreeAccess: (payload) => ({ type: types.ARCHIVE_FREE_ACCESS, payload }),
    unarchiveFreeAccess: (payload) => ({ type: types.UNARCHIVE_FREE_ACCESS, payload }),
    saveDefaultOffers: (payload) => ({ type: types.SAVE_DEFAULT_OFFERS, payload }),
    saveArticleStructure: (payload) => ({ type: types.SAVE_ARTICLE_STRUCTURE, payload }),
    sortFreeAccessRulesOrder: (payload) => ({ type: types.SORT_FREE_ACCESS_RULES_ORDER, payload }),

    updateLoginOptions: (payload) => ({ type: types.UPDATE_LOGIN_OPTIONS, payload }),
    updateLoginOptionsSuccess: (payload) => ({ type: types.UPDATE_LOGIN_OPTIONS_SUCCESS, payload }),
    updateLoginOptionsError: (error) => ({ type: types.UPDATE_LOGIN_OPTIONS_ERROR, error }),

    resetBillingError: () => ({ type: types.RESET_BILLING_ERROR }),
    deleteAccount: (payload) => ({ type: types.DELETE_ACCOUNT, payload })
})

export default actions
