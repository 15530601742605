import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import style from './Footer.module.scss'

const Footer = (props) => {
    return <div className={cx(props.className, style.footer)}>{props.children}</div>
}

Footer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Footer
