/** @namespace CartUI.dependencies */

import { select } from 'redux-saga/effects'

import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'

import AccountModule from 'modules/account'

const name = 'Segments'

function* getPublisherId() {
    const publisherId = yield select(AccountModule.selectors.getPublisherId)

    return publisherId
}

export {
    name,

    endpoints,
    createAsyncSaga,

    getPublisherId,
}
