import updateCustomProperties from './updateCustomProperties'
import deleteCustomProperties from './deleteCustomProperties'
import getCustomProperties from './getCustomProperties'
import updateCustomPropertyValue from './updateCustomPropertyValue'
import deleteCustomPropertyValue from './deleteCustomPropertyValue'

const sagas = (duck) => ({
    *updateCustomProperties(payload) {
        yield updateCustomProperties({ ...payload, duck })
    },
    *updateCustomPropertyValue(payload) {
        yield updateCustomPropertyValue({ ...payload, duck })
    },
    *deleteCustomPropertyValue(payload) {
        yield deleteCustomPropertyValue({ ...payload, duck })
    },
    *deleteCustomProperties(payload) {
        yield deleteCustomProperties({ ...payload, duck })
    },
    *getCustomProperties(payload) {
        yield getCustomProperties({ ...payload, duck })
    },
})

export default sagas
