import { call, put, select, take } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, isValidURL, getVcardUrl, addNotification, getAccountInfo, GET_ACCOUNT_INFO_SUCCESS, GET_ACCOUNT_INFO_ERROR } from '../dependencies'
import { fetchVcard, getVcardCacheString } from './index'

const updateVcard = createAsyncSaga(
    `${name}/updateVcard`,
    function* ({
        payload: {
            publisherId = null,
            vcardObject = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            if (!vcardObject) {
                throw new Error('vcardObject is required')
            }

            const response = yield request({
                method: 'put',
                url: endpoints.UPDATE_VCARD,
                headers: {
                    publisherid: publisherId,
                },
                data: vcardObject,
                timeout: 15000,
            })

            if (!isValidURL(response)) {
                const error = response?.error
                const defaultError = 'We were unable to update your vCard. Please try again later.'
                throw new Error(error || defaultError)
            }

            yield put(yield call(getAccountInfo))
            const getAccountInfoResult = yield take([GET_ACCOUNT_INFO_SUCCESS, GET_ACCOUNT_INFO_ERROR])

            console.log('getAccountInfoResult', getAccountInfoResult)
        
            const _getVcardUrl = yield select(getVcardUrl)
            const _getVcardCacheString = yield select(getVcardCacheString)
            const vcardUrl = `${_getVcardUrl}${_getVcardCacheString}`
        
            yield put(yield call(fetchVcard, {
                    vcardUrl,
                }        
            ))
        
            const fetchVcardResult = yield take([fetchVcard.fulfilled, fetchVcard.rejected])
            
            if (fetchVcardResult.type === fetchVcard.rejected) {
                throw new Error(fetchVcardResult.payload.error)
            }

            yield put(yield call(addNotification, {
                    message: 'Contact info saved!',
                    type: 'success'
                }
            ))
        
            yield fulfilled()
        } catch (error) {
            yield put(yield call(addNotification, {
                    message: `Contact info not saved - ${error?.message}`,
                    type: 'danger'
                }        
            ))

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default updateVcard
