import { call, put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getSingleUser(action) {
    const { payload, duck } = action
    const { user_state_id } = payload

    const publisherid = yield select(AccountModule.selectors.getPublisherId)
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.USERS}/profile/${user_state_id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        yield put({
            type: duck.types.REQUEST_SINGLE_USER_SUCCESS,
            data
        })


        yield call(duck.sagas.fetchSelectedUserActivity, { duck, payload: data })

        yield put({
            type: duck.types.GET_USER_NEWSLETTERS,
            payload: {
                user_state_id: data.user_state_id
            }
        })
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: duck.types.REQUEST_SINGLE_USER_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: duck.types.REQUEST_SINGLE_USER_ERROR,
                error
            })
        }
    }
}

export default getSingleUser
