import { put } from 'redux-saga/effects'
import AnalyticsModule from 'modules/analytics'

function* setTimeDimension({ payload }) {

    yield put({
        type: AnalyticsModule.types.SEND_EVENT,
        payload: {
            eventType: 'performance_filter',
            eventData: {
                filter: payload.value
            }
        }
    })
 
}

export default setTimeDimension
