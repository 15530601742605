import { put, putResolve, select } from 'redux-saga/effects'
import Router from 'next/router'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* deleteAccount({ payload, types }) {
    const { cancellationType, otherReason, diffReason } = payload

    try {
        const publisherid = yield select(
            AccountModule.selectors.getPublisherId
        )

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.DELETE_ACCOUNT,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                timeout: 30000,
                data: {
                    cancellation_type: cancellationType,
                    other_reason: otherReason,
                    diff_reason: diffReason
                },
            },
        })

        yield put({
            type: types.DELETE_ACCOUNT_SUCCESS,
            payload: {
                ...data,
            },
        })
        yield put(
            AppModule.creators.addNotification({
                message: 'Profile Deleted Successfully',
                type: 'success',
            })
        )
        // TODO when users module imported it is not working on the tests. Need to fix it.
        yield put({
            type: 'dashboard/users/LOG_OUT_USER'
        })
        Router.push('/logout')
    } catch (error) {
        yield put(
            AppModule.creators.addNotification({
                message: 'An error occurred while deleting Account',
                type: 'danger',
            })
        )
        yield put({
            type: types.DELETE_ACCOUNT_ERROR,
            error,
        })
    }
}

export default deleteAccount
