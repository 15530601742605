import usePublisherId from 'hooks/usePublisherId'
import usePublisherUsername from 'hooks/usePublisherUsername'
import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'
import { fetchApps } from 'features/FeatureManager'

import { usePlatformAccessApps } from 'features/platformAccess'

const name = 'adminAccess'

export {
    name,

    endpoints,
    createAsyncSaga,

    usePublisherId,
    usePlatformAccessApps,
    usePublisherUsername,
    fetchApps,
}
