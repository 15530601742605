import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Badge, Box, Boutton, NavBar, Avatar, Text, Spacing } from '@piconetworks/ui'
import Form from '@piconetworks/pkg-form'

import Picon from '@piconetworks/pkg-picon'

import { Group, Context, Item } from '@piconetworks/pkg-nav-link'

import Tooltip from 'components/Tooltip'
import Navigations from '../../index'
import LinksV2 from '../Links/LinksV2'
import AccountMenu from 'components/AccountMenu'
import Overflow from 'components/Overflow'

import SettingsModule from 'modules/settings'
import useExperiment from 'hooks/useExperiment'

import ScreenSizeContext from 'utils/contexts/ScreenSize/context'

import cx from 'classnames'
import style from '../../navigation.module.scss'

const Main = ({ accountInfo, ...props }) => {
    const [showSwitchAccount, setShowSwitchAccount] = useState(false)
    const { isOpenMobileMenu, setIsOpenMobileMenu } = Navigations.context.useMenu()

    const { homepageUrl: homeLink } = useExperiment('homepage')
    const godMode = useSelector(SettingsModule.selectors.isGodmode)

    const router = useRouter()

    const handleUsersnap = () => {
        if (window && window.Usersnap) {
            window.Usersnap.logEvent('nav_help')
        } else {
            console.log('Usersnap is not present in this environment.')
        }
    }

    useEffect(() => {
        if (document) {
            if (isOpenMobileMenu) {
                document.body.style.setProperty('overflow', 'hidden')
            } else {
                document.body.style.removeProperty('overflow')
            }
        }
    }, [isOpenMobileMenu])

    const handleOpenSwitchAccount = () => setShowSwitchAccount(true)

    return (
        <ScreenSizeContext.Consumer>
            {({ isLgUp }) => {
                return (
                    <Group
                        active={'/' + router.asPath.split('/')[1]}
                        onChange={(e) => {
                            router.push('[section]', e, { shallow: true })
                        }}
                    >
                        {isOpenMobileMenu && (
                            <div
                                onClick={() => {
                                    setIsOpenMobileMenu(false)
                                    setShowSwitchAccount(false)
                                }}
                                className={style.overflowElement}
                            />
                        )}
                        <Context.Consumer>
                            {({ isActive }) => {
                                return (
                                    <NavBar
                                        className={cx(style.leftNavbar, {
                                            [style.isOpen]: isOpenMobileMenu,
                                        })}
                                        horizontal={false}
                                        key="navbar"
                                        alignItems="center"
                                        justifyContent="between"
                                    >
                                        <Box.Flexbox.Container
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            className={cx(style.fullWidth)}
                                        >
                                            {isLgUp && (
                                                <Link href={homeLink}>
                                                    <Box.Flexbox.Container
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className={style.brandIcon}
                                                    >
                                                        <Picon icon="hype-brandmark" color="#120a20" size="55" />

                                                    </Box.Flexbox.Container>
                                                </Link>
                                            )}
                                            {showSwitchAccount ? (
                                                <Box.Flexbox.Container flexDirection="column">
                                                    <Box.Flexbox.Container
                                                        justifyContent="start"
                                                        alignItems="center"
                                                        className={style.switchAccountHeader}
                                                    >
                                                        <span
                                                            className={cx(style.backIcon, 'icon-left')}
                                                            onClick={() => setShowSwitchAccount(false)}
                                                        />

                                                        <Text.P className={style.text}>Switch account</Text.P>
                                                    </Box.Flexbox.Container>

                                                    <Form.Typeahead.Sync
                                                        id="publications"
                                                        className={style.searchWrapper}
                                                        autoFocus={true}
                                                        labelKey={(option) => `${option.name} ${option.username}`}
                                                        minLength={1}
                                                        options={props.publishersAvailable}
                                                        maxResults={500}
                                                        stayOpen={true}
                                                        placeholder="Search accounts"
                                                        handleRenderMenu={(options) => {
                                                            return (
                                                                <Box.Flexbox.Container
                                                                    flexDirection="column"
                                                                    className={style.optionWrapper}
                                                                >
                                                                    <Spacing.HorizontalDivider
                                                                        withLine
                                                                        className={style.divider}
                                                                    />
                                                                    <div className={style.scrollableArea}>
                                                                        {options.map((option, index) => (
                                                                            <div className={style.optionWrapper} key={index}>
                                                                                <Overflow.Option
                                                                                    key={option.id}
                                                                                    icon={
                                                                                        <Avatar
                                                                                            size="36px"
                                                                                            src={option.icon}
                                                                                            className={style.optionAvatar}
                                                                                        />
                                                                                    }
                                                                                    iconClassName={style.optionIcon}
                                                                                    className={style.option}
                                                                                    onClick={() =>
                                                                                        props.handleSwitch(option.id)
                                                                                    }
                                                                                >
                                                                                    <Box.Flexbox.Container flexDirection="column">
                                                                                        <Text.P
                                                                                            className={
                                                                                                style.switchAccountName
                                                                                            }
                                                                                        >
                                                                                            {option?.name}
                                                                                        </Text.P>
                                                                                        <Text.Span
                                                                                            className={
                                                                                                style.switchAccountUsername
                                                                                            }
                                                                                        >
                                                                                            @{option?.username}
                                                                                        </Text.Span>
                                                                                    </Box.Flexbox.Container>
                                                                                </Overflow.Option>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Box.Flexbox.Container>
                                                            )
                                                        }}
                                                    />
                                                </Box.Flexbox.Container>
                                            ) : (
                                                <>
                                                    <Box.Flexbox.Container
                                                        alignItems="center"
                                                        justifyContent="between"
                                                        className={cx('d-xs-block', 'd-lg-none', style.mobileHeader)}
                                                    >
                                                        <Box.Flexbox.Child className={style.icon}>
                                                            <Box.Flexbox.Container
                                                                className={cx(style.account, 'w-100')}
                                                                flexDirection="row"
                                                                justifyContent="start"
                                                                alignItems="center"
                                                            >
                                                                <Box.Flexbox.Child>
                                                                    <Avatar
                                                                        className={cx(style.avatar)}
                                                                        size="40px"
                                                                        initials={props.communityName}
                                                                        src={props.avatar}
                                                                    />
                                                                </Box.Flexbox.Child>
                                                                <Box.Flexbox.Child
                                                                    className={cx(
                                                                        'd-xs-block',
                                                                        'd-lg-none',
                                                                        style.marginLeft
                                                                    )}
                                                                >
                                                                    <Box.Flexbox.Container
                                                                        flexDirection="column"
                                                                        justifyContent="center"
                                                                        alignItems="start"
                                                                    >
                                                                        <Box.Flexbox.Child>
                                                                            <Text.P className={style.communityName}>
                                                                                {props.communityName}
                                                                            </Text.P>
                                                                        </Box.Flexbox.Child>
                                                                        <Box.Flexbox.Child>
                                                                            <Text.P
                                                                                className={style.accountName}
                                                                            >{`@${accountInfo?.username}`}</Text.P>
                                                                        </Box.Flexbox.Child>
                                                                    </Box.Flexbox.Container>
                                                                </Box.Flexbox.Child>
                                                            </Box.Flexbox.Container>
                                                        </Box.Flexbox.Child>
                                                        <Box.Flexbox.Child className={style.closeContainer}>
                                                            <Boutton
                                                                className={style.closeButton}
                                                                onClick={() => setIsOpenMobileMenu(false)}
                                                            >
                                                                <Picon icon="icon_close" size="18" />
                                                            </Boutton>
                                                        </Box.Flexbox.Child>
                                                    </Box.Flexbox.Container>
                                                    <Box.Flexbox.Container
                                                        className={style.links}
                                                        flexDirection="column"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <span
                                                            className={cx('d-xs-block', 'd-lg-none', {
                                                                [style.isOpen]: isOpenMobileMenu,
                                                            })}
                                                            onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}
                                                        />
                                                        <LinksV2
                                                            isDesktop={isLgUp}
                                                            isActive={isActive}
                                                            accountInfo={accountInfo}
                                                        />
                                                        {!isLgUp && (
                                                            <>
                                                                {godMode && (
                                                                    <Link href="/admin/pricing">
                                                                        <Item
                                                                            active={router?.asPath === '/admin/pricing'}
                                                                            className={cx(
                                                                                style.item,
                                                                                style['item--fixSize'],
                                                                                style['item--anchor'],
                                                                                {
                                                                                    [style.active]:
                                                                                        router?.asPath ===
                                                                                        '/admin/pricing',
                                                                                }
                                                                            )}
                                                                        >
                                                                            <Tooltip
                                                                                content={'Admin'}
                                                                                placement="right"
                                                                                className={cx('d-none', 'd-lg-block', style.itemTooltip)}
                                                                            >
                                                                                <Badge className={style.badge}>
                                                                                    <span className="icon-admin"></span>
                                                                                    <div
                                                                                        className={cx(
                                                                                            'd-xs-block',
                                                                                            'd-lg-none',
                                                                                            style.mobileTitle
                                                                                        )}
                                                                                    >
                                                                                        Admin
                                                                                    </div>
                                                                                </Badge>
                                                                            </Tooltip>
                                                                        </Item>
                                                                    </Link>
                                                                )}
                                                                <Item
                                                                    href="#"
                                                                    onClick={handleUsersnap}
                                                                    className={cx(
                                                                        style.item,
                                                                        style['item--fixSize'],
                                                                        style['item--anchor']
                                                                    )}
                                                                >
                                                                    <Tooltip
                                                                        content={'Help'}
                                                                        placement="right"
                                                                        className={cx('d-none', 'd-lg-block', style.itemTooltip)}
                                                                    >
                                                                        <Badge className={style.badge}>
                                                                            <span className="icon-help"></span>{' '}
                                                                            <div
                                                                                className={cx(
                                                                                    'd-xs-block',
                                                                                    'd-lg-none',
                                                                                    style.mobileTitle
                                                                                )}
                                                                            >
                                                                                Help
                                                                            </div>
                                                                        </Badge>
                                                                    </Tooltip>
                                                                </Item>

                                                                <Spacing.HorizontalDivider
                                                                    withLine
                                                                    className={style.divider}
                                                                />

                                                                {props.publishersAvailable &&
                                                                    props.publishersAvailable.length > 1 && (
                                                                    <Item
                                                                        href="#"
                                                                        onClick={handleOpenSwitchAccount}
                                                                        className={cx(
                                                                            style.item,
                                                                            style['item--fixSize'],
                                                                            style['item--anchor']
                                                                        )}
                                                                    >
                                                                        <Tooltip
                                                                            content={'Switch account'}
                                                                            placement="right"
                                                                            className={cx('d-none', 'd-lg-block', style.itemTooltip)}
                                                                        >
                                                                            <Badge className={style.badge}>
                                                                                <span className="icon-switch-account"></span>
                                                                                <div
                                                                                    className={cx(
                                                                                        'd-xs-block',
                                                                                        'd-lg-none',
                                                                                        style.mobileTitle
                                                                                    )}
                                                                                >
                                                                                        Switch account
                                                                                </div>
                                                                            </Badge>
                                                                        </Tooltip>{' '}
                                                                    </Item>
                                                                )}
                                                                <Link href="/logout">
                                                                    <Item
                                                                        active={isActive('/logout')}
                                                                        className={cx(
                                                                            style.item,
                                                                            {
                                                                                [style.active]: isActive('/logout'),
                                                                            },
                                                                            style['item--fixSize'],
                                                                            style['item--anchor']
                                                                        )}
                                                                    >
                                                                        <Tooltip
                                                                            content={'Log out'}
                                                                            placement="right"
                                                                            className={cx('d-none', 'd-lg-block', style.itemTooltip)}
                                                                        >
                                                                            <Badge className={style.badge}>
                                                                                <span className="icon-logout"></span>
                                                                                <Box.Flexbox.Container flexDirection="column">
                                                                                    <div
                                                                                        className={cx(
                                                                                            'd-xs-block',
                                                                                            'd-lg-none',
                                                                                            style.mobileTitle
                                                                                        )}
                                                                                    >
                                                                                        Log out
                                                                                    </div>
                                                                                    <Text.P
                                                                                        className={cx(
                                                                                            style.accountName,
                                                                                            style.email
                                                                                        )}
                                                                                    >
                                                                                        {props.email}
                                                                                    </Text.P>
                                                                                </Box.Flexbox.Container>
                                                                            </Badge>
                                                                        </Tooltip>{' '}
                                                                    </Item>
                                                                </Link>
                                                            </>
                                                        )}
                                                    </Box.Flexbox.Container>
                                                </>
                                            )}
                                        </Box.Flexbox.Container>
                                        {isLgUp && <AccountMenu accountInfo={accountInfo} {...props} />}{' '}
                                    </NavBar>
                                )
                            }}
                        </Context.Consumer>
                    </Group>
                )
            }}
        </ScreenSizeContext.Consumer>
    )
}

export default Main
