import React from 'react'
import PropTypes from 'prop-types'

import usePlatformAccessPaywall from '../hooks/usePlatformAccessPaywall'
import { useFeatureManager } from '../dependencies'

function PlatformAccessPaywallWrapper(props) {
    const children = props?.children

    const { hasFeature }= useFeatureManager()
    const { openPlatformAccessPaywall, Paywall } = usePlatformAccessPaywall()

    const childrenWithProps = React.Children.map(children, (child) => {
        const newProps = {
            ...props,
            openPlatformAccessPaywall,
            hasFeature
        }
        if (React.isValidElement(child)) {
            return React.cloneElement(child, newProps)
        }
        return child
    })


    return (
        <>
            {childrenWithProps}
            <Paywall />
        </>
    )
}

function withPlatformAccessPaywall(WrappedComponent) {
    return (props) => (
        <PlatformAccessPaywallWrapper {...props}>
            <WrappedComponent />
        </PlatformAccessPaywallWrapper>
    )
}

withPlatformAccessPaywall.propTypes = {
    hasFeature: PropTypes.func,
    openPlatformAccessPaywall: PropTypes.func,
}

export default withPlatformAccessPaywall
