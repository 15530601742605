import { put, putResolve, select, delay } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* reIndexSyncStatus(action) {
    const { duck } = action
    const publisherid = yield select(AccountModule.selectors.getPublisherId)

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.USERS}/sync/status`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        yield put({
            type: duck.types.REINDEX_SYNC_STATUS_SUCCESS,
            payload: data
        })

        yield delay(5000)

        if (data.remaining === 0) {
            yield put({
                type: duck.types.GET_PAGINATED_USERS
            })
            yield put({
                type: duck.types.GET_USERS_META
            })
        } else {
            yield put({
                type: duck.types.REINDEX_SYNC_STATUS,
            })

        }

    } catch (error) {
        yield put({
            type: duck.types.REINDEX_SYNC_STATUS_ERROR,
            error: error.message,
        })

        yield put(
            AppModule.creators.addNotification({
                type: 'danger',
                message: error.message,
                dismissAfter: 20000
            })
        )


    }
}

export default reIndexSyncStatus
