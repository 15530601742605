import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => [
    takeEvery(types.UPDATE_CUSTOM_PROPERTIES, sagas.updateCustomProperties),
    takeEvery(types.DELETE_CUSTOM_PROPERTIES, sagas.deleteCustomProperties),
    takeEvery(types.GET_PROPERTIES, sagas.getCustomProperties),
    takeEvery(types.UPDATE_CUSTOM_PROPERTY_VALUE, sagas.updateCustomPropertyValue),
    takeEvery(types.DELETE_CUSTOM_PROPERTY_VALUE, sagas.deleteCustomPropertyValue),
]

export default takes
