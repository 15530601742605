import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'

import account from 'modules/account'
import AppModule from 'modules/app'
import SettingsModule from 'modules/settings'

const getPublisherId = account.selectors.getPublisherId
const isGodmode = SettingsModule.selectors.isGodmode

const addNotification = AppModule.creators.addNotification
const name = 'verifyWithOtp'

export {
    name,
    getPublisherId,
    endpoints,
    createAsyncSaga,

    addNotification
}
