import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
    uuidv4,
    faker,
    editionTypes,
} from '../dependencies'

const fetchEditions = createAsyncSaga(
    `${name}/fetchEditions`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_EDTIONS,
                headers: {
                    publisherid: publisherId,
                },
            })

            const editions = response

            if (!editions) {
                throw new Error('editions could not be found')
            }

            const formattedEditions = editions.map((edition: any) => ({
                id: edition?.id,
                shopifySku: edition?.shopify_sku,
                name: edition?.name,
                type: edition?.type,
                color: edition?.color,
                engravable: edition?.engravable,
                trial: edition?.trial,
                sold: edition?.sold,
                salesChannel: edition?.sales_channel,
                packSize: edition?.pack_size,
            }))

            yield fulfilled({ editions: formattedEditions })
        } catch (error) {
            logger.error('fetchEditions error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default fetchEditions
