import { put, putResolve, select, call } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'
import AnalyticsModule from 'modules/analytics'

function* saveUsername({ types, payload, callback }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: endpoints.USERNAME,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...payload,
                    update_type: 'username'
                }
            }
        })

        yield put({
            type: types.SAVING_USERNAME_SUCCESS,
            payload: data
        })

        yield put(
            AppModule.creators.addNotification({
                message: 'Username successfully updated',
                type: 'success'
            })
        )

        yield put({
            type: AnalyticsModule.types.SEND_EVENT,
            payload: {
                eventType: 'username_update',
                eventData: {
                    new_username: data.username,
                }
            }
        })

        yield call(callback, null)

    } catch (e) {
        if (e?.response?.data?.errors) {
            const fieldErrors = Object.assign({}, ...e?.response?.data?.errors)
            yield call(callback, fieldErrors)
        }
        yield put({ type: types.SAVING_USERNAME_ERROR, payload: e?.response?.data?.errors })
    }
}

export default saveUsername
