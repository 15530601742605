import React from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useCallback } from 'react'
import cx from 'classnames'

import { Flexbox, Grid } from '@piconetworks/pkg-box'
import Form from '@piconetworks/pkg-form'
import Boutton from '@piconetworks/pkg-boutton'
import styles from './ConfirmBusinessInformation.module.scss'
import { countries, stateCodes as states } from 'utils/lib/countriesAndStates'
import { composeValidators, fieldValidations } from 'utils/lib/validators'
import { useRegisterPhoneNumber } from 'features/registerPhoneNumber'
import { useOtp } from 'features/verifyWithOtp'
import PhoneField from './PhoneField'

const DropdownOptionCondition = ({ when, is, isNotUKorUS, children }) => {
    if (isNotUKorUS) {
        return (
            <Field name={when} subscription={{ value: true }}>
                {({ input: { value } }) =>
                    !/^((UK)|(US))$/i.test(value) ? children : null
                }
            </Field>
        )
    }
    return (
        <Field name={when} subscription={{ value: true }}>
            {({ input: { value } }) => (value === is ? children : null)}
        </Field>
    )
}
const ConfirmBusinessInformation = () => {
    const { submit, mutators } = useForm()
    const { values, hasValidationErrors, submitting, hasSubmitErrors, dirtySinceLastSubmit } = useFormState()
    const { business, profile, updateBusiness, updateProfile } = useRegisterPhoneNumber(true)
    const { sendOtp } = useOtp()

    const handleSubmit = useCallback(async() => {
        try {
            const phoneValue =  `${values?.country_code}${values?.phone}`
            updateProfile({
                first_name: values.first_name,
                last_name: values.last_name
            })
            updateBusiness({
                country: values.country,
                address1: values.address1,
                address2: values.address2,
                city: values.city,
                state: values.state,
                post_code: values.post_code
            })
            await sendOtp(phoneValue)
            return submit()
                
        } catch (error) {
            mutators.setError(error.message)
        }
    }, [updateBusiness, updateProfile, sendOtp, mutators, submit, values])
    return (
        <Flexbox.Container flexDirection='column'>
            <p className={styles.text}>Your business information is needed to register a phone number for sending text blasts. This information will not be shared publicly.</p>
            <Grid.Row>
                <Grid.Col sm={12} md={6}>
                    <h3>Business Address</h3>

                    <Field
                        name='business_name'
                        initialValue={business.data?.company_name}
                        component='input'
                        type='hidden'
                    />
                    <Field
                        name='country'
                        component={Form.Dropdown}
                        label='Country'
                        options={countries}
                        initialValue={business.data?.country || countries[0].value}
                        required
                        validate={composeValidators(
                            fieldValidations.required
                        )}
                        isSearchable={true}
                        fieldProps={{
                            className: styles.field
                        }}
                    />
                    <Field
                        component={Form.Input}
                        name='address1'
                        initialValue={business.data?.address1}
                        required
                        type='text'
                        label='Address Line 1'
                        validate={composeValidators(
                            fieldValidations.required
                        )}
                        fieldProps={{
                            className: styles.field
                        }}
                    />
                    <Field
                        component={Form.Input}
                        name='address2'
                        initialValue={business.data?.address2}
                        type='text'
                        label='Address Line 2'
                        fieldProps={{
                            className: styles.field
                        }}
                    />
                    <Grid.Row>
                        <DropdownOptionCondition
                            when='country'
                            is='US'
                        >
                            <Grid.Col lg={4}>
                                <Field
                                    name='city'
                                    component={
                                        Form.Input
                                    }
                                    label='City'
                                    initialValue={business.data?.city}
                                    required
                                    validate={composeValidators(
                                        fieldValidations.required
                                    )}
                                    fieldProps={{
                                        className: styles.field
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col xs={6} sm={4} md={6} lg={4} className='pl-lg-0'>
                                <Field
                                    name='state'
                                    component={
                                        Form.Dropdown
                                    }
                                    label='State'
                                    options={
                                        states
                                    }
                                    initialValue={business.data.state || states[0].value}
                                    required
                                    validate={composeValidators(
                                        fieldValidations.required,
                                        fieldValidations.state,
                                    )}
                                    isSearchable={true}
                                    fieldProps={{
                                        className: styles.field
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col xs={6} sm={4} md={6} lg={4} className='pl-lg-0'>
                                <Field
                                    name='post_code'
                                    component={
                                        Form.Input
                                    }
                                    label='Zip Code'
                                    initialValue={business.data?.post_code}
                                    required
                                    validate={composeValidators(
                                        fieldValidations.required,
                                        fieldValidations.zipCode
                                    )}
                                    type='text'
                                    maxLength={'5'}
                                    fieldProps={{
                                        className: styles.field
                                    }}
                                />
                            </Grid.Col>
                        </DropdownOptionCondition>
                    </Grid.Row>
                    <Grid.Row>
                        <DropdownOptionCondition
                            when='country'
                            is='UK'
                        >
                            <Grid.Col lg={6}>
                                <Field
                                    name='city'
                                    component={
                                        Form.Input
                                    }
                                    label='Town / City'
                                    initialValue={business.data?.city}
                                    required
                                    validate={composeValidators(
                                        fieldValidations.required
                                    )}
                                    fieldProps={{
                                        className: styles.field
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col lg={6} className='pl-0'>
                                <Field
                                    name='post_code'
                                    component={
                                        Form.Input
                                    }
                                    label='Postal Code'
                                    initialValue={business.data?.post_code}
                                    required
                                    validate={composeValidators(
                                        fieldValidations.required
                                    )}
                                />
                            </Grid.Col>
                        </DropdownOptionCondition>
                    </Grid.Row>
                    <Grid.Row>
                        <DropdownOptionCondition
                            when='country'
                            isNotUKorUS='UK,US'
                        >
                            <Grid.Col lg={4}>
                                <Field
                                    name='city'
                                    component={
                                        Form.Input
                                    }
                                    label='Town / City'
                                    validate={composeValidators(
                                        fieldValidations.required
                                    )}
                                    initialValue={business.data?.city}
                                    fieldProps={{
                                        className: styles.field
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col xs={6} sm={4} md={6} lg={4} className='pl-lg-0'>
                                <Field
                                    name='state'
                                    component={
                                        Form.Input
                                    }
                                    label='Region / State'
                                    initialValue={business.data?.state}
                                    validate={composeValidators(
                                        fieldValidations.required
                                    )}
                                    fieldProps={{
                                        className: styles.field
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col xs={6} sm={4} md={6} lg={4} className='pl-0'>
                                <Field
                                    name='post_code'
                                    component={
                                        Form.Input
                                    }
                                    label='Postal Code'
                                    initialValue={business.data?.post_code}
                                    validate={composeValidators(
                                        fieldValidations.required
                                    )}
                                    fieldProps={{
                                        className: styles.field
                                    }}
                                />
                            </Grid.Col>
                        </DropdownOptionCondition>
                    </Grid.Row>
                </Grid.Col>
                <Grid.Col>
                    <h3>Your Personal Details</h3>
                    <Field
                        component={Form.Input}
                        name='first_name'
                        required
                        type='text'
                        initialValue={profile.data?.first_name}
                        label='First Name'
                        validate={composeValidators(
                            fieldValidations.required
                        )}
                        fieldProps={{
                            className: cx(styles.field, styles.companyNameField)
                        }}
                    />

                    <Field
                        component={Form.Input}
                        name='last_name'
                        required
                        type='text'
                        label='Last Name'
                        validate={composeValidators(
                            fieldValidations.required
                        )}
                        initialValue={profile.data?.last_name}
                        fieldProps={{
                            className: cx(styles.field, styles.companyNameField)
                        }}
                    />
                    <Field
                        component={Form.Input}
                        name='email'
                        required
                        initialValue={profile.data?.email}
                        type='email'
                        validate={composeValidators(
                            fieldValidations.required,
                            fieldValidations.email
                        )}
                        label='Email Address'
                                                        
                    />
                    <PhoneField fieldContainerProps={
                        {
                            label: 'Phone Number'
                        }
                    }/>
                </Grid.Col>
            </Grid.Row>
            <Flexbox.Child alignSelf='end'>
                <Boutton 
                    type='button' 
                    variant='primary' 
                    onClick={handleSubmit}
                    disabled={hasValidationErrors || submitting || (hasSubmitErrors && dirtySinceLastSubmit === false)}
                >
                    Confirm
                </Boutton>
            </Flexbox.Child>
        </Flexbox.Container>
    )
}

export default ConfirmBusinessInformation
