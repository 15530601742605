import { put } from 'redux-saga/effects'
import AnalyticsModule from 'modules/analytics'

import logger from 'lib/logger'

function* blastSent({ payload }) {
    try {
        yield put(
            AnalyticsModule.creators.sendEvent({
                eventType: 'blast_sent',
                eventData: {
                    blast_id: payload.id,
                    sent_at: payload.sent_at,
                },
            })
        )
    } catch (e) {
        logger.error('blastSent error', {}, e)
    }
}

export default blastSent
