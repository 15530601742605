import { putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getCustomProperties(action) {
    const { duck } = action
    const publisherid = yield select(AccountModule.selectors.getPublisherId)

    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.CUSTOM_PROPERTIES,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })
        yield put({
            type: duck.types.GET_PROPERTIES_SUCCESS,
            payload: data
        })
    } catch (error) {
        yield put({
            type: duck.types.GET_PROPERTIES_ERROR,
            error
        })
    }
}

export default getCustomProperties
