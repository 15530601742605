import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Boutton from '@piconetworks/pkg-boutton'
import Picon from '@piconetworks/pkg-picon'
import { P } from '@piconetworks/pkg-text'

import IntegrationsModule from 'modules/integrations'
import SettingsModule from 'modules/settings'

import styles from './StripeUsage.module.scss'
import endpoints from 'utils/lib/endpoints'
import qs from 'qs'

const StripeUsage = () => {
    const dispatch = useDispatch()
    const godMode = useSelector(SettingsModule.selectors.getUserType) === 'god'
    const stripe: {
        id: string,
    } = useSelector(IntegrationsModule.selectors.getStripe)
    const stripeId = stripe?.id

    const stripeExpress = useSelector(IntegrationsModule.selectors.getStripeExpress)
    const stripeExpressLoginUrl = useSelector(IntegrationsModule.selectors.getStripeExpressLoginUrl)

    const goToLink = useCallback(() => {
        if ((stripeExpress as { api_key: string }).api_key) {
            if (stripeExpress.is_healthy) {
                dispatch(IntegrationsModule.creators.loginStripeExpress())
                if (stripeExpressLoginUrl) {
                    window.open(
                        stripeExpressLoginUrl as string,
                        '_blank'
                    )
                }
            } else {
                const query = {
                    source: "dashboard",
                    integration_id: stripeExpress?.id
                }
                window.open(
                    endpoints.STRIPE_EXPRESS_RESUME + "?" + qs.stringify(query),
                    '_blank'
                )
            }
        } else {
            window.open(
                godMode
                    ? `https://dashboard.stripe.com/${stripeId}`
                    : 'https://dashboard.stripe.com/dashboard',
                '_blank'
            )
        }
    }, [godMode, stripeExpress, stripeId, stripeExpressLoginUrl])

    if (!stripeId) {
        return null
    }
    return (
        <div className={styles.stripeUsage}>
            {Object.keys(stripeExpress).length != 0 && !stripeExpress?.is_healthy && (
                <p className={styles.stripeExpressIssue}>
                    We couldn't finish your stripe integration with Hype, please login to stripe and fix the existing issues.
                </p>
            )}
            <Boutton
                className={styles.gotoDashboard}
                icon={<Picon icon="icon_open" size="20" color="#834eff" />}
                onClick={goToLink}
                variant="link"
            >
                Go to Stripe Dashboard
            </Boutton>
            <P>
                Hype currently manages active subscriptions in your Stripe account. If you wish to disconnect your Stripe account, please
                {' '}
                <a
                    href="mailto:support@hype.co"
                    target="_blank"
                    rel="noreferrer"
                >
                    Contact Hype support
                </a>.
            </P>
        </div>
    )
}

export default StripeUsage
