import { all, takeEvery } from 'redux-saga/effects'

import AccountModule from './account'
import AppModule from './app'
import AdminModule from './admin'
import AuthModule from './auth'
// removed pre launch -RC
// import BillingModule from './billing'
import ModalModule from './modal'
import SettingsModule from './settings'
import UsersModule from './users'
import PlansModule from './plans'
import PerformanceModule from './performance'
import OffersModule from './offers'
import ProductsModule from './products'
import FormsModule from './forms'
import IntegrationsModule from './integrations'
import NewslettersModule from './newsletters'
import TextModule from './text'
import FeatureFlagModule from './feature-flags'
import LinksModule from './links'
import BlocksModule from './blocks'
import FilesModule from './files'
import AnalyticsModule from './analytics'
import IFramelyModule from './iframely'
import MessagesModule from './messages'
import ClaimModule from './claim'
import FeatureManager from 'features/FeatureManager'
import cartUI from 'features/cartUI'
import planCreditSummary from 'features/planCreditSummary'
import estimateMessage from 'features/estimateMessage'
import themePicker from 'features/themePicker'
import segments from 'features/segments'
import vcard from 'features/vcard'
import cards from '../entities/cards'
import editions from '../entities/editions'
import packs from '../entities/packs'
import nfcEditor from 'features/nfcEditor'
import platformAccess, { checkToOpenPaywall } from 'features/platformAccess'
import tokenLogin from './auth/sagas/tokenLogin'
import adminAccess from 'features/adminAccess'
import salesTools from 'features/salesTools'
import googleDrive from 'features/googleDrive'

export default function* rootSagas() {
    yield all([
        ...AccountModule.takes,
        ...AdminModule.takes,
        ...AppModule.takes,
        ...AuthModule.takes,
        ...FormsModule.takes,
        ...ModalModule.takes,
        ...SettingsModule.takes,
        ...UsersModule.takes,
        ...PlansModule.takes,
        ...PerformanceModule.takes,
        ...OffersModule.takes,
        ...ProductsModule.takes,
        ...IntegrationsModule.takes,
        ...NewslettersModule.takes,
        ...TextModule.takes,
        ...FeatureFlagModule.takes,
        ...LinksModule.takes,
        ...BlocksModule.takes,
        ...FilesModule.takes,
        ...AnalyticsModule.takes,
        ...IFramelyModule.takes,
        ...MessagesModule.takes,
        ...ClaimModule.takes,
        ...FeatureManager.takes(),
        ...cartUI.takes,
        ...planCreditSummary.takes,
        ...estimateMessage.takes,
        ...googleDrive.takes,
        ...themePicker.takes,
        ...segments.takes,
        ...vcard.takes,
        ...cards.takes,
        ...editions.takes,
        ...nfcEditor.takes,
        ...platformAccess.takes,
        ...adminAccess.takes,
        takeEvery(AuthModule.types.POST_LOGIN_SUCCESS, checkToOpenPaywall),
        ...packs.takes,
        ...salesTools.takes
    ])
}
