import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import Form from '@piconetworks/pkg-form'
import { timeframeTypes } from 'modules/performance/reducer'
import styles from './TimeDropdown.module.scss'
import PerformanceModule from 'modules/performance'
import PropTypes from 'prop-types'


export const timeDimensionOptions = [
    { value: timeframeTypes.TODAY, label: 'Today', granularity: 'hour' },
    { value: timeframeTypes.THIS_WEEK, label: 'This Week', granularity: 'day' },
    { value: timeframeTypes.THIS_MONTH, label: 'This Month', granularity: 'week' },
    { value: timeframeTypes.THIS_YEAR, label: 'This Year', granularity: 'month' },
    { value: timeframeTypes.LAST_7_DAYS, label: 'Last 7 Days', granularity: 'day' },
    { value: timeframeTypes.LAST_30_DAYS, label: 'Last 30 Days', granularity: 'week' },
    { value: timeframeTypes.LAST_90_DAYS, label: 'Last 90 Days', granularity: 'month' },
    { value: timeframeTypes.LAST_365_DAYS, label: 'Last 365 Days', granularity: 'month' },
    { value: timeframeTypes.ALL_TIME, label: 'All Time', granularity: 'year' }
]

const TimeDropdown = ({ className }) => {
    const dispatch = useDispatch()

    const timeDimension = useSelector(
        PerformanceModule.selectors.getTimeDimension
    )

    useEffect(() => {
        dispatch(PerformanceModule.creators.getMetrics({ value: timeDimension }))
    }, [timeDimension, dispatch])

    const onChangeTimeDimension = (value) => {
        dispatch(PerformanceModule.creators.setTimeDimension({ value }))
    }

    return (
        <div className={cx(styles.form, className)}>
            <Form.Dropdown
                fieldProps={{
                    className: styles.field
                }}
                input={{
                    onChange: onChangeTimeDimension,
                    onBlur: () => { },
                    value: timeDimension
                }}
                meta={{
                    error: null
                }}
                name="timeframe"
                options={timeDimensionOptions}
            />
        </div>
    )
}

TimeDropdown.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default TimeDropdown
