import React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import { NavBar } from '@piconetworks/ui'
import AccessControl from 'components/AccessControl'
import { constants } from 'features/FeatureManager'
import { Group, Context, Item } from '@piconetworks/pkg-nav-link'

import style from '../../navigation.module.scss'

const Sub = ({ menuItems, accessPath, requiredFeatures, routePath }) => {
    const router = useRouter()
    return (
        <div className="d-md-block w-100">
            <Group
                active={router.asPath}
                onChange={(e) => {
                    router.push(`/${routePath}/[section]`, e, { shallow: true })
                }}
            >
                <Context.Consumer>
                    {({ isActive, setActive }) => (
                        <NavBar horizontal className={style.menu}>
                            {menuItems.map((item) => {
                                const key = item.toLowerCase().replace(/\s/g, '')
                                return (
                                    <AccessControl
                                        key={key}
                                        override={accessPath ? false : true}
                                        requiredFeatures={requiredFeatures}
                                    >
                                        <Item
                                            key={key}
                                            active={isActive(`/${routePath}/${key}`)}
                                            onClick={() => setActive(`/${routePath}/${key}`)}
                                            className={style.submenuItem}
                                        >
                                            {item}
                                        </Item>
                                    </AccessControl>
                                )
                            })}
                        </NavBar>
                    )}
                </Context.Consumer>
            </Group>
        </div>
    )
}

Sub.propTypes = {
    accessPath: PropTypes.string,
    menuItems: PropTypes.arrayOf(PropTypes.string),
    // requiredFeatures: PropTypes.arrayOf(PropTypes.oneOf(Object.values(constants))),
    requiredFeatures: PropTypes.array,
    router: PropTypes.object
}

export default Sub
