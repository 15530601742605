import createAsyncSaga from 'utils/lib/createAsyncSaga'
import endpoints from 'utils/lib/endpoints'

import account from 'modules/account'
import AppModule from 'modules/app'
import SettingsModule from 'modules/settings'
import { fetchPlanCredits } from 'features/planCreditSummary'

const getPublisherId = account.selectors.getPublisherId
const name = 'registerPhoneNumber'

export {
    name,
    getPublisherId,
    endpoints,
    createAsyncSaga,
    fetchPlanCredits
}
