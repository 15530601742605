import { createAsyncSaga, name, endpoints, logger } from '../dependencies'

const updatePack = createAsyncSaga(
    `${name}/updatePack`,
    function* ({
        payload: {
            publisherId = null,
            packId = null,
            quantity = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            if (!packId) {
                throw new Error('packId is required')
            }

            if (!quantity) {
                throw new Error('quantity is required')
            }

            const response = yield request({
                method: 'put',
                url: endpoints.UPDATE_PACK(packId),
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    quantity: quantity,
                },
            })

            const pack = response

            if (!pack) {
                throw new Error('pack could not be found')
            }

            yield fulfilled(pack)
        } catch (error) {
            logger.error(error)
            yield rejected(error)
        }
    }
)

export default updatePack
