import React, { Component } from 'react'
import { connect } from 'react-redux'
import Router from 'next/router'
import PropTypes from 'prop-types'
import cx from 'classnames'
import style from './AccountMenu.module.scss'
import Overflow from 'components/Overflow'
import SettingsModule from 'modules/settings'
import { Avatar, Box, Text } from '@piconetworks/ui'
import Form from '@piconetworks/pkg-form'
import addTestId from 'utils/lib/addTestId'

class AccountMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showSwitchAccount: false
        }
    }
    render() {
        const { accountName, avatar, communityName, godMode, handleSwitch, publishersAvailable, accountInfo, salesMode } =
            this.props
        const { showSwitchAccount } = this.state
        return (
            <Overflow
                data-testid={addTestId('account-menu-nav-overflow')}
                className={cx(style.wrapper)}
                header={
                    <Box.Flexbox.Container
                        className={cx(style.account, 'w-100')}
                        flexDirection="row"
                        justifyContent="start"
                        alignItems="center"
                    >
                        <Box.Flexbox.Child>
                            <Avatar className={cx(style.avatar)} size="40px" initials={communityName} src={avatar} />
                        </Box.Flexbox.Child>
                        <Box.Flexbox.Child className={cx('d-xs-block', 'd-lg-none', style.marginLeft)}>
                            <Box.Flexbox.Container flexDirection="column" justifyContent="center" alignItems="start">
                                <Box.Flexbox.Child>
                                    <Text.P className={style.communityName}>{communityName}</Text.P>
                                </Box.Flexbox.Child>
                                <Box.Flexbox.Child>
                                    <Text.P className={style.accountName}>{accountName}</Text.P>
                                </Box.Flexbox.Child>
                            </Box.Flexbox.Container>
                        </Box.Flexbox.Child>
                    </Box.Flexbox.Container>
                }
                menuOpenPosition="right"
                menuOpenPositionY="top"
            >
                <Box.Flexbox.Container flexDirection="row" alignItems="center">
                    <Avatar
                        className={cx(style.avatar, style.menuAvatar)}
                        size="40px"
                        initials={communityName}
                        src={avatar}
                    />
                    <Box.Flexbox.Container
                        className={cx(style.account, 'd-none', 'd-lg-block')}
                        flexDirection="column"
                        justifyContent="end"
                        alignItems="start"
                    >
                        <Box.Flexbox.Child>
                            <Text.P className={style.communityName}>{communityName}</Text.P>
                        </Box.Flexbox.Child>
                        <Box.Flexbox.Child>
                            <Text.P className={style.accountName}>@{accountInfo.username}</Text.P>
                        </Box.Flexbox.Child>
                    </Box.Flexbox.Container>
                </Box.Flexbox.Container>
                <Overflow.Option
                    className={style.option}
                    icon={<span className={cx('icon-settings', style.bigIcon)} />}
                    onClick={() => Router.push('/settings/account')}
                >
                    Settings
                </Overflow.Option>
                <Overflow.Option
                    className={style.option}
                    icon={<span className={cx('icon-billing', style.bigIcon)} />}
                    onClick={() => Router.push('/settings/billing')}
                >
                    Billing
                </Overflow.Option>
                {godMode && (
                    <Overflow.Option
                        className={style.option}
                        icon={<span className={cx('icon-admin', style.bigIcon)} />}
                        onClick={() => Router.push('/admin/creators')}
                    >
                        Admin
                    </Overflow.Option>
                )}
                {(salesMode || godMode) && (
                    <Overflow.Option
                        className={style.option}
                        icon={<span className={cx('icon-admin', style.bigIcon)} />}
                        onClick={() => Router.push('/sales')}
                    >
                        Alta
                    </Overflow.Option>
                )}
                <Overflow.Option
                    className={style.option}
                    icon={<span className={cx('icon-help', style.bigIcon)} />}
                    onClick={() => {
                        if (window && window.Usersnap) {
                            window.Usersnap.logEvent('nav_help')
                        } else {
                            console.log('Usersnap is not present in this environment.')
                        }
                    }}
                >
                    Help
                </Overflow.Option>
                {publishersAvailable && publishersAvailable.length > 1 && Router.route !== '/pages/[id]' && (
                    <React.Fragment>
                        <Overflow.Option
                            className={cx(style.positionRelative, style.option, {
                                [style.activeSwitch]: showSwitchAccount,
                            })}
                            icon={<span className={cx('icon-switch-account', style.bigIcon)} />}
                            onClick={() => this.setState({ showSwitchAccount: !showSwitchAccount })}
                        >
                            Switch account
                        </Overflow.Option>
                        {showSwitchAccount && (
                            <Box.Flexbox.Container className={style.switchAccountWrapper}>
                                <Form.Typeahead.Sync
                                    id="publications"
                                    className={style.searchWrapper}
                                    autoFocus={true}
                                    labelKey={(option) => `${option.name} ${option.username} ${option.id}`}
                                    minLength={1}
                                    options={publishersAvailable}
                                    maxResults={500}
                                    stayOpen={true}
                                    handleRenderMenu={(options) => (
                                        <Box.Flexbox.Container flexDirection="column" className={style.optionWrapper}>
                                            {options.map((option) => (
                                                <Overflow.Option
                                                    key={option.id}
                                                    className={style.searchOption}
                                                    icon={
                                                        <Avatar
                                                            size="20px"
                                                            src={option.icon}
                                                            className={style.optionAvatar}
                                                        />
                                                    }
                                                    onClick={() => handleSwitch(option.id)}
                                                >
                                                    <Box.Flexbox.Container flexDirection="column">
                                                        <Text.P className={style.switchAccountName}>
                                                            {option.name}
                                                        </Text.P>
                                                        <Text.Span className={style.switchAccountUsername}>
                                                            @{option.username}
                                                        </Text.Span>
                                                    </Box.Flexbox.Container>{' '}
                                                </Overflow.Option>
                                            ))}
                                        </Box.Flexbox.Container>
                                    )}
                                />
                            </Box.Flexbox.Container>
                        )}
                    </React.Fragment>
                )}

                <Overflow.Option
                    icon={<span className={cx('icon-logout', style.bigIcon)} />}
                    onClick={() => Router.push('/logout')}
                    className={style.option}
                >
                    Log out
                </Overflow.Option>
            </Overflow>
        )
    }
}

const mapStateToProps = (state) => ({
    godMode: SettingsModule.selectors.getUserType(state) === 'god',
    salesMode: SettingsModule.selectors.getUserType(state) === 'sales',
})

AccountMenu.propTypes = {
    accountName: PropTypes.string,
    avatar: PropTypes.string,
    communityName: PropTypes.string,
    email: PropTypes.string,
    godMode: PropTypes.bool.isRequired,
    salesMode: PropTypes.bool.isRequired,
    handleSwitch: PropTypes.func,
    loading: PropTypes.bool,
    publishersAvailable: PropTypes.array,
}

export default connect(mapStateToProps)(AccountMenu)
