import Duck from 'extensible-duck'

const selectors = ({ store }) => ({
    getState: (state) => state[store],
    getStatus: (state) => state[store].status,
    getError: (state) => state[store].error,
    getAllProperties: (state) => state[store].properties,
    getAllPropertiesDropdown: new Duck.Selector((selectors) => (state) => {
        const options = selectors.getAllProperties(state).map((prop) => {
            return {
                section: prop.section || 'Custom',
                label: prop.label,
                value: prop.id || prop.value,
                type: prop.filterType || prop.type
            }
        })
        return options
    }),
    getLatestCustomProperty: new Duck.Selector((selectors) => (state) => {
        const latestAddedProperty = selectors.getCustomProperties(state).filter((prop) => prop.isNew === true)
        return latestAddedProperty[0].value
    }),
    getCustomProperties: new Duck.Selector((selectors) => (state) => {
        const customProperties = selectors.getAllProperties(state).filter((prop) => !!prop.elastic_reference_id)
        return customProperties
    }),
    getSelectedCustomProperty: new Duck.Selector((selectors) => (state, { value }) => {
        const property = selectors.getCustomProperties(state).find((custom) => custom.value === value)
        return property
    })
})

export default selectors
