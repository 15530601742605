import { put, putResolve, select, delay, take } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'
import Users from '../index'

function* checkUserImportStatus(action) {
    const { duck } = action
    const publisherid = yield select(AccountModule.selectors.getPublisherId)

    const request_id = action?.payload?.request_id

    try {
        if (request_id) {
            const { data } = yield putResolve({
                type: REQUEST,
                config: {
                    method: 'get',
                    url: `${endpoints.USERS}/import/${request_id}`,
                    timeout: 30000,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        publisherid
                    }
                }
            })

            yield put({
                type: duck.types.CHECK_USER_IMPORT_STATUS_SUCCESS,
                payload: data
            })

            yield delay(5000)

            if (data.status === 'inprogress') {
                yield put({
                    type: duck.types.CHECK_USER_IMPORT_STATUS,
                    payload: data,
                })
            }

            if (data.status === 'completed') {
                yield put(Users.creators.getNotifiableImportedUserCount())

                yield take([
                    Users.types.GET_NOTIFIABLE_IMPORTED_USER_COUNT_SUCCESS,
                    Users.types.GET_NOTIFIABLE_IMPORTED_USER_COUNT_ERROR,
                ])

                // send null payload to reducer to stop the loop
                yield put({
                    type: duck.types.CHECK_USER_IMPORT_STATUS_SUCCESS,
                    payload: null,
                })

                yield put({
                    type: duck.types.REINDEX_SYNC_STATUS,
                })
            }

            // because of we are using redis, api is not returning error code.
            if (data.status === 'failed') {

                yield put({
                    type: duck.types.CHECK_USER_IMPORT_STATUS_ERROR,
                    error: data.error,
                })

                yield put(
                    AppModule.creators.addNotification({
                        type: 'danger',
                        message: data.message,
                        dismissAfter: 20000
                    })
                )

            }
        }
    } catch (error) {
        yield put({
            type: duck.types.CHECK_USER_IMPORT_STATUS_ERROR,
            error: error.message,
        })

        yield put(
            AppModule.creators.addNotification({
                type: 'danger',
                message: error.message,
                dismissAfter: 20000
            })
        )


    }
}

export default checkUserImportStatus
