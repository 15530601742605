import UpgradeButton from './MessagingUpgrade.Button'
import { usePlatformAccess } from 'features/platformAccess'

const MessagingUpgrade = ({ hasAccess, notEnoughCredit }) => {
    const { openPlatformAccessPaywall, Paywall } = usePlatformAccess()
    return (
        <>
            <UpgradeButton
                notEnoughCredit={notEnoughCredit}
                onClick={openPlatformAccessPaywall}
            />
            <Paywall />
        </>
    )
}

export default MessagingUpgrade
