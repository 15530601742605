import { takeEvery } from 'redux-saga/effects'

const takes = ({ types, sagas }) => [
    takeEvery(types.GET_OFFERS, sagas.getOffers),
    takeEvery(types.GET_OFFER, sagas.getOffer),
    takeEvery(types.CREATE_OFFER, sagas.createOffer),
    takeEvery(types.DELETE_OFFER, sagas.deleteOffer),
    takeEvery(types.UPDATE_OFFER, sagas.updateOffer),
    takeEvery(types.RESTORE_OFFER, sagas.restoreOffer),
    takeEvery(types.GET_CONVERSION_RATE, sagas.getConversionRate),
    takeEvery(types.SORT_ORDER, sagas.sortOrder),
    takeEvery(types.DUPLICATE_OFFER, sagas.duplicateOffer),
]

export default takes
