import Duck from 'extensible-duck'
import { createSelector } from 'reselect'

const selectors = ({ store, statuses }) => ({
    messages: new Duck.Selector(() => (state) => state[store]),
    conversations: new Duck.Selector((selectors) => createSelector(
        selectors.messages,
        ({ conversations = {} }) => conversations
    )),
    getBlastFilter: new Duck.Selector((selectors) => createSelector(
        selectors.messages,
        ({ blasts = {} }) => blasts.filter
    )),
    getBlastType: new Duck.Selector((selectors) => createSelector(
        selectors.messages,
        ({ blasts = {} }) => blasts.type
    )),
    getBlastsPagination: new Duck.Selector((selectors) => createSelector(
        selectors.messages,
        ({ blasts } = {}) => blasts[blasts.filter]
    )),
    getBlasts: new Duck.Selector((selectors) => createSelector(
        selectors.messages,
        ({ blasts } = {}) => {
            const values = blasts[blasts.filter]
            return {
                data: values.data,
                hasBlast: !!values.data?.length,
                count: values.count,
                page: values.page,
                pages: values.pages,
                isLoading: values.isLoading
            }
        }
    )),
    getConversations: new Duck.Selector((selectors) => createSelector(
        selectors.conversations,
        ({ all } = {}) => all
    )),
    getActiveConversation: new Duck.Selector((selectors) => createSelector(
        selectors.conversations,
        ({ active } = {}) => active
    )),
    getLoading: new Duck.Selector((selectors) => createSelector(
        selectors.messages,
        ({ status } = {}) => status === statuses.LOADING
    )),
    getError: new Duck.Selector((selectors) => createSelector(
        selectors.messages,
        ({ error } = {}) => error
    )),
    getFailedMessages: new Duck.Selector((selectors) => createSelector(
        selectors.messages,
        ({ messages } = {}) => messages?.all
    )),
    messagingEnabled: new Duck.Selector((selectors) => createSelector(
        selectors.messages,
        (messages) => messages?.messagingEnabled
    )),
    fetchMessagingEnabledInProgress: new Duck.Selector((selectors) => createSelector(
        selectors.messages,
        (messages) => messages?.fetchMessagingEnabledInProgress
    )),
    messagingEnabledError: new Duck.Selector((selectors) => createSelector(
        selectors.messages,
        (messages) => messages?.messagingEnabledError
    )),
})

export default selectors
