import Duck from 'extensible-duck'

const selectors = ({ store, statuses }) => ({
    getState: (state) => state[store],
    getIntegrations: new Duck.Selector((selectors) => (state) => {
        return selectors.getState(state)
    }),
    selectIntegrations: new Duck.Selector((selectors) => (state) => {
        return selectors.getState(state)
    }),
    getESP: (state) => state[store].esp,
    getESPListId: new Duck.Selector((selectors) => (state) => {
        const esp = selectors.getESP(state)
        return esp.listId
    }),
    getESPLists: new Duck.Selector((selectors) => (state) => {
        const esp = selectors.getESP(state)
        return esp.lists
    }),
    getESPFields: new Duck.Selector((selectors) => (state) => {
        let pico_fields = []
        const esp = selectors.getESP(state).fields
        esp?.pico_fields?.forEach((field) => {
            let val
            if(esp?.current_fields.find((curr) => curr.key === field.key)){
                val = {...field, inUse: true}
            }else{
                val = {...field, inUse: false}
            }
            pico_fields.push(val)
        })

        return {...esp, pico_fields}
    }),
    getESPLoading:new Duck.Selector((selectors) => (state) => {
        const esp = selectors.getESP(state)
        return esp.status === statuses.LOADING
    }),
    getStripe: (state) => state[store].stripe || state[store].stripeExpress,
    getStripeExpress: (state) => state[store].stripeExpress,
    getStripeExpressConfig: (state) => state[store].stripeExpressConfig,
    getStripeExpressInstallUrl: (state) => state[store].stripeExpressConfig?.url,
    getStripeExpressLoginUrl: (state) => state[store].stripeExpressConfig?.login,
    getStripeExpressConfigLoading: (state) => state[store].stripeExpressConfig?.loading,
    getMonetizationIsInstalled: new Duck.Selector((selectors) => (state) => {
        const stripe = selectors.getStripe(state)
        const stripeIsInstalled = stripe && stripe.api_key
        return stripeIsInstalled
    }),
    getMonetizationHasPurchases: new Duck.Selector((selectors) => (state) => {
        const stripe = selectors.getStripe(state)
        const stripeHasPurchases = stripe && stripe.has_user_purchases
        return stripeHasPurchases
    }),
    getESPIsInstalled: (state) => state[store].esp?.listId !== null ,
    getIntegrationsLoading: (state) => state[store].status === statuses.LOADING,
    getIntegrationsLoadingOrInitiated: (state) => state[store].status === statuses.LOADING || state[store].status === statuses.INITIATED,
    getWordpress: (state) => state[store].wordpress,
    getDiscord: (state) => state[store].discord,
    getTwitter: (state) => state[store].twitter,
    getYouTube: (state) => state[store].youtube,
    getInstagram: (state) => state[store].instagram,
    getWebhooks: (state) => state[store].webhooks.data.webhooks,
    getWebhookLoading: (state) => state[store].webhooks.loading,
    getWebhooksWithFormat: (state) => {
        const webhooks = state[store].webhooks && state[store].webhooks.data && state[store].webhooks.data.webhooks && state[store].webhooks.data.webhooks.filter((webhook) => {
            const url = new URL(webhook.url).hostname
            if (url !== 'www.zapier.com') {
                return true
            }
        })
        return webhooks
    },
    getZapier: (state) => state[store].zapier,
    getError: (state) => state[store].error
})

export default selectors
