import { _network } from '@piconetworks/pkg-network-middleware'

import AccountModule from './account'
import AppModule from './app'
import AuthModule from './auth'
// removed pre launch -RC
// import BillingModule from './billing'
import ModalModule from './modal'
import SettingsModule from './settings'
import UsersModule from './users'
import PlansModule from './plans'
import PerformanceModule from './performance'
import FormsModule from './forms'
import IntegrationsModule from './integrations'
import AdminModule from './admin'
import NewslettersModule from './newsletters'
import OffersModule from './offers'
import ProductsModule from './products'
import TextModule from './text'
import LinksModule from './links'
import FeatureFlagsModule from './feature-flags'
import DemoSlice from './demo'
import BlocksModule from './blocks'
import FilesModule from './files'
import IFramelyModule from './iframely'
import MessagesModule from './messages'
import ClaimModule from './claim'
// console.log('ClaimModule', ClaimModule)
import { setupPicoLinkSliceReducer, setupPicoLinkSliceInitialState } from 'features/setupPicoLink/slice'
import { featuredCreatorsSliceReducer, featuredCreatorsSliceInitialState } from 'features/featuredCreators/slice'
import { guidesCreatorsSliceReducer, guidesSliceInitialState } from 'features/guides/slice'
import { welcomeSliceReducer, welcomeSliceInitialState } from 'features/welcome/slice'
import FeatureManager from 'features/FeatureManager'
import cartUI from 'features/cartUI'
import planCreditSummary from 'features/planCreditSummary'
import estimateMessage from 'features/estimateMessage'
import themePicker from 'features/themePicker'
import customizations from 'features/customizations'
import segments from 'features/segments'
import vcard from 'features/vcard'
import cards from '../entities/cards'
import editions from '../entities/editions'
import packs from '../entities/packs'
import nfcEditor from 'features/nfcEditor'
import verifyWithOtp from '../features/verifyWithOtp'
import registerPhoneNumber from 'features/registerPhoneNumber'
import platformAccess from 'features/platformAccess'
import composeBlast from 'features/composeBlast'
import adminAccess from 'features/adminAccess'
import salesTools from 'features/salesTools'
import googleDrive from 'features/googleDrive'

export const initialAppState = {
    account: AccountModule.initialState,
    admin: AdminModule.initialState,
    app: AppModule.initialState,
    auth: AuthModule.initialState,
    forms: FormsModule.initialState,
    modal: ModalModule.initialState,
    plans: PlansModule.initialState,
    performance: PerformanceModule.initialState,
    offers: OffersModule?.initialState || {},
    products: ProductsModule?.initialState || {},
    settings: SettingsModule.initialState,
    users: UsersModule?.initialState || {},
    integrations: IntegrationsModule.initialState,
    newsletters: NewslettersModule.initialState,
    text: TextModule.initialState,
    links: LinksModule.initialState,
    featureFlags: FeatureFlagsModule.initialState,
    blocks: BlocksModule.initialState,
    demo: DemoSlice.initialState,
    files: FilesModule.initialState,
    iframely: IFramelyModule.initialState,
    messages: MessagesModule.initialState,
    claim: ClaimModule.initialState,
    ...setupPicoLinkSliceInitialState,
    ...featuredCreatorsSliceInitialState,
    ...guidesSliceInitialState,
    ...welcomeSliceInitialState,
    ...FeatureManager.featureManagerInitialState,
    ...cartUI.cartUIInitialState,
    ...planCreditSummary.planCreditSummaryInitialState,
    ...estimateMessage.initialState,
    ...themePicker.initialState,
    ...customizations.initialState,
    ...segments.initialState,
    ...vcard.initialState,
    ...cards.initialState,
    ...editions.initialState,
    ...nfcEditor.initialState,
    ...verifyWithOtp.initialState,
    ...registerPhoneNumber.initialState,
    ...platformAccess.initialState,
    ...composeBlast.initialState,
    ...adminAccess.initialState,
    ...packs.initialState,
    ...salesTools.initialState,
    ...googleDrive.initialState
}

const rootReducer = {
    _network,
    [AccountModule.store]: AccountModule.reducer,
    [AdminModule.store]: AdminModule.reducer,
    [AppModule.store]: AppModule.reducer,
    [AuthModule.store]: AuthModule.reducer,
    [FormsModule.store]: FormsModule.reducer,
    [ModalModule.store]: ModalModule.reducer,
    [PlansModule.store]: PlansModule.reducer,
    [PerformanceModule.store]: PerformanceModule.reducer,
    [OffersModule.store]: OffersModule.reducer,
    ...(ProductsModule?.store && {[ProductsModule.store]: ProductsModule.reducer}),
    [SettingsModule.store]: SettingsModule.reducer,
    ...(UsersModule?.store && {[UsersModule.store]: UsersModule.reducer}),
    [IntegrationsModule.store]: IntegrationsModule.reducer,
    [NewslettersModule.store]: NewslettersModule.reducer,
    [TextModule.store]: TextModule.reducer,
    [LinksModule.store]: LinksModule.reducer,
    [FeatureFlagsModule.store]: FeatureFlagsModule.reducer,
    [DemoSlice.name]: DemoSlice.reducer,
    [BlocksModule.store]: BlocksModule.reducer,
    [DemoSlice.name]: DemoSlice.reducer,
    [FilesModule.store]: FilesModule.reducer,
    [IFramelyModule.store]: IFramelyModule.reducer,
    [MessagesModule.store]: MessagesModule.reducer,
    [ClaimModule.store]: ClaimModule.reducer,
    ...setupPicoLinkSliceReducer,
    ...featuredCreatorsSliceReducer,
    ...guidesCreatorsSliceReducer,
    ...welcomeSliceReducer,
    ...FeatureManager.featureManagerReducer,
    ...cartUI.cartUIReducer,
    ...planCreditSummary.planCreditSummaryReducer,
    ...estimateMessage.reducer,
    ...themePicker.reducer,
    ...customizations.reducer,
    ...segments.reducer,
    ...vcard.reducer,
    ...cards.reducer,
    ...editions.reducer,
    ...nfcEditor.reducer,
    ...verifyWithOtp.reducer,
    ...registerPhoneNumber.reducer,
    ...platformAccess.reducer,
    ...composeBlast.reducer,
    ...adminAccess.reducer,
    ...packs.reducer,
    ...salesTools.reducer,
    ...googleDrive.reducer
}

export default rootReducer
