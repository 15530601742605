import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
    uuidv4,
    faker,
} from '../dependencies'

const fetchCards = createAsyncSaga(
    `${name}/fetchCards`,
    function* ({
        payload: {
            publisherId = null,
            editionId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            if (!editionId) {
                throw new Error('editionId is required')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_EDTION_BY_ID(editionId),
                headers: {
                    publisherid: publisherId,
                },
            })

            const cards = response?.tags

            if (!cards) {
                throw new Error('cards could not be found')
            }

            const formattedCards = cards.map((card: any) => {
                const addressUpdatedAt = card?.address_updated_at

                const addressDetails = {
                    address1: card?.address1,
                    address2: card?.address2,
                    city: card?.city,
                    state: card?.state,
                    zip: card?.zip,
                    country: card?.country,
                }

                const promoCode = card?.acquisition?.promo_code

                return ({
                    editionId,
                    id: card?.id,
                    tagName: card?.tag_name,
                    packId: card?.nfc_pack_id,
                    publisherId: card?.publisher_id,
                    username: card?.username,
                    orderDate: card?.created_at,
                    name: card?.name,
                    email: card?.email,
                    engraving: card?.engraving,
                    shippedDate: card?.shipped_at,
                    stripeInvoiceId: card?.stripe_invoice_id,
                    stripeCustomerId: card?.stripe_customer_id,
                    deactivatedDate: card?.deactivated_at,
                    deliveryFailedAt: card?.delivery_failed_at,
                    addressUpdatedAt,
                    ...addressDetails,
                    promoCode,
                })
            })

            yield fulfilled({ editionId, cards: formattedCards })
        } catch (error) {
            logger.error('fetchCards error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default fetchCards
