import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    useEmail,
    saveBillingInformation,
    useDefaultSource,
    getBillingLoading,
    saveBillingInformationError,
} from '../../../dependencies'

import TitleBar from '../../sections/TitleBar'
import CreditCardEntry from '../../sections/CreditCardEntry'
import StatusBar from '../../sections/StatusBar'
import SubmitButton from '../../sections/SubmitButton'
import CancelButton from '../../sections/CancelButton'
import PopupContainer from '../../sections/PopupContainer'
import BillingError from 'components/Settings/BillingError'
import useResetBilling from 'hooks/useResetBillingError'

import logger from 'lib/logger'

const UpdatePaymentMethod = ({
    onCancel = () => {},
    onChange = (defaultSource = null) => {},
    // TODO: onError is being passed in a lot of places, but it's not being used anywhere. We need to insert it in the right place in this component.
    onError = (error?: any) => {},
}) => {
    let currentPaymentMethod
    const [formFulFilled, setFormFulFilled] = useState(false)
    const resetBillingError = useResetBilling()

    const loading: boolean = useSelector(getBillingLoading)
    const [saveCreditCardForm, setSaveCreditCardForm] = useState(null)

    const dispatch = useDispatch()
    const email = useEmail()

    const onCreateToken = useCallback((data) => {
        if (data?.token?.id) {
            dispatch(saveBillingInformation({
                token: data.token.id,
                email,
            }))
        }
    }, [email, dispatch, saveBillingInformation])

    const onStripeValidationError = useCallback(({ error }) => {
        if (error) {
            dispatch(saveBillingInformationError(error.message))
            logger.error('Stripe Validation Error', {}, error)
        }
    }, [email, dispatch, saveBillingInformationError])

    const onClickSubmit = useCallback(() => {
        saveCreditCardForm.current.click()
    }, [saveCreditCardForm])


    const _onChange = useCallback(
        (data) => {
            if (!data) {
                return
            }

            if (!currentPaymentMethod) {
                currentPaymentMethod = data
            }

            if (currentPaymentMethod?.id !== data?.id) {
                onChange(data)
            }
        },
        [currentPaymentMethod]
    )

    const defaultSource = useDefaultSource({
        onChange: _onChange,
    })

    useEffect(() => {
        resetBillingError()
    }, [])

    return (
        <>
            <TitleBar>Change Payment Method</TitleBar>
            <PopupContainer loading={loading}>
                <CreditCardEntry
                    onCreateToken={onCreateToken}
                    setSaveCreditCardForm={setSaveCreditCardForm}
                    defaultSource={defaultSource}
                    setFormFulFilled={setFormFulFilled}
                    onStripeValidationError={onStripeValidationError}
                />
                <StatusBar>
                    <BillingError />
                </StatusBar>
                <SubmitButton onClick={onClickSubmit} disabled={!!formFulFilled}>Update payment method</SubmitButton>
                <CancelButton onClick={onCancel} />
            </PopupContainer>
        </>
    )
}

export default UpdatePaymentMethod
