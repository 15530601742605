import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { Heading } from '@piconetworks/ui'
import style from './Header.module.scss'

const Header = (props) => {
    return <Heading.H2 className={cx(style.header, props.className)}>{props.children}</Heading.H2>
}

Header.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ])
}

export default Header
