import createPack from './createPack'
import { createAsyncSaga, name, endpoints, logger, fetchCardById } from '../dependencies'
import addTagToPack from './addTagToPack'
import { call, put, take } from 'redux-saga/effects'
import fetchPack from './fetchPack'

const createPackForTag = createAsyncSaga(
    `${name}/createPackForTag`,
    function* ({
        payload: {
            tagId = null,
            publisherId = null,
            quantity = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            yield put(yield call(createPack, {
                publisherId,
                quantity,
            }))

            yield take(createPack.pending)
            const createPackResponse = yield take([createPack.fulfilled, createPack.rejected])
            const pack = createPackResponse?.payload

            yield put(yield call(addTagToPack, {
                publisherId,
                packId: pack.id,
                tagId,
            }))

            yield take(addTagToPack.pending)
            yield take([addTagToPack.fulfilled, addTagToPack.rejected])

            yield put(yield call(fetchPack, {
                publisherId,
                packId: pack.id,
            }))

            yield put(yield call(fetchCardById, {
                publisherId,
                tagId,
            }))

            yield take(fetchCardById.pending)
            const fetchCardByIdResponse = yield take([fetchCardById.fulfilled, fetchCardById.rejected])

            yield fulfilled(fetchCardByIdResponse?.payload)
        } catch (error) {
            logger.error(error)
            yield rejected(error)
        }
    }
)

export default createPackForTag
