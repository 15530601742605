import { putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getOffers({ types, payload }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { status, data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: `${endpoints.OFFERS}?type=${payload.offerType}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        if (status === 200) {
            yield put({
                type: types.GET_OFFERS_SUCCESS,
                payload: {
                    type: payload.offerType,
                    data
                }
            })
        }

    } catch (error) {
        if (error?.response?.data?.message) {
            yield put({
                type: types.GET_OFFERS_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: types.GET_OFFERS_ERROR,
                error
            })
        }
    }
}

export default getOffers
