import { call, put, take } from 'redux-saga/effects'
import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
    fetchEditions,
    fetchCards,
    fetchCardById,
    setActiveEditionId,
    setActiveCardId,
} from '../dependencies'

const fetchAndSetActiveCardAndEdition = createAsyncSaga(
    `${name}/fetchAndSetActiveCardAndEdition`,
    function* ({
        payload: {
            publisherId = null,
            tagId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            if (!tagId) {
                throw new Error('tagId is required')
            }

            // fetch the edition for this card
            yield put(yield call(fetchCardById, {
                publisherId,
                tagId,
            }))

            yield take(fetchCardById.pending)
            const response = yield take([fetchCardById.fulfilled, fetchCardById.rejected])

            const editionId = response?.payload?.card?.editionId

            yield put(yield call(setActiveEditionId, editionId))

            // fetch all cards for this edition
            yield put(yield call(fetchCards, {
                publisherId,
                editionId,
            }))

            yield take(fetchCards.pending)
            const fetchCardsResponse = yield take([fetchCards.fulfilled, fetchCards.rejected])
            const cards = fetchCardsResponse?.payload?.cards

            const card = cards.find((card: any) => card.id === tagId)

            yield put(yield call(setActiveCardId, card.id))

            yield fulfilled({ card })
        } catch (error) {
            logger.error('fetchAndSetActiveCardAndEdition error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default fetchAndSetActiveCardAndEdition
