import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
    uuidv4,
    faker,
} from '../dependencies'

const fetchNoEditionTags = createAsyncSaga(
    `${name}/fetchNoEditionTags`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_CARDS(),
                headers: {
                    publisherid: publisherId,
                },
            })

            const tags = response?.tags

            if (!tags) {
                throw new Error('tags could not be found')
            }

            const formattedTags = tags.map((tag: any) => {
                const addressUpdatedAt = tag?.address_updated_at

                const addressDetails = {
                    address1: tag?.address1,
                    address2: tag?.address2,
                    city: tag?.city,
                    state: tag?.state,
                    zip: tag?.zip,
                    country: tag?.country,
                }

                const promoCode = tag?.acquisition?.promo_code

                return ({
                    id: tag?.id,
                    tagName: tag?.tag_name,
                    publisherId: tag?.publisher_id,
                    username: tag?.username,
                    orderDate: tag?.created_at,
                    name: tag?.name,
                    email: tag?.email,
                    engraving: tag?.engraving,
                    shippedDate: tag?.shipped_at,
                    stripeInvoiceId: tag?.stripe_invoice_id,
                    stripeCustomerId: tag?.stripe_customer_id,
                    deactivatedDate: tag?.deactivated_at,
                    deliveryFailedAt: tag?.delivery_failed_at,
                    addressUpdatedAt,
                    ...addressDetails,
                    promoCode,
                })
            })

            yield fulfilled({ tags: formattedTags })
        } catch (error) {
            logger.error('fetchNoEditionTags error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default fetchNoEditionTags
