import { put, putResolve, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'

import AccountModule from 'modules/account'

import endpoints from 'utils/lib/endpoints'

import logger from 'lib/logger'

function* getFiles({
    duck,
    payload: {
        product_id = null
    } = {}
}) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        let url = `${endpoints.FILES}`

        if (product_id) {
            url += `/${product_id}`
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                }
            }
        })

        if (data) {
            yield put({
                type: duck.types.GET_FILES_SUCCESS,
                payload: data,
            })
        } else {
            throw new Error('Error fetching files')
        }
    } catch (error) {
        logger.error('getFiles error', {}, error)
        yield put({
            type: duck.types.GET_FILES_ERROR,
            error,
        })
    }
}

export default getFiles
