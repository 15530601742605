import generateAction from '@piconetworks/pkg-generate-action'

const getBlocks = generateAction({
    baseActionCreatorName: 'getBlocks',
    baseActionTypeName: 'GET_BLOCKS',
})

const types = [
    ...getBlocks.types,
    'UPSERT_BLOCK'
]

const creators = (duck) => ({
    ...getBlocks.creators(duck),
})

export {
    types,
    creators,
}
