import { call, put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import { FORM_ERROR } from 'final-form'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'

function* updatePassword({ payload, callback, types }) {
    try {

        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                baseURL: endpoints.BASE_URL,
                url: endpoints.OAUTH.PUBLISHER_UPDATE_PASSWORD,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid,
                },
                timeout: 30000,
                data: {
                    ...payload
                }
            }
        })

        if (data) {
            yield put({
                type: types.UPDATE_PASSWORD_SUCCESS,
                payload: {
                    ...data
                }
            })

            window.location.pathname = '/contacts'
            yield put(
                AppModule.creators.addNotification({
                    type: 'success',
                    message: 'Password updated successfully',
                    dismissAfter: 20000
                })
            )

        }

    } catch (error) {

        const submissionError = {
            [FORM_ERROR]: error.message
        }

        yield call(callback, submissionError)

        yield put({
            type: types.UPDATE_PASSWORD_ERROR,
            error
        })
    }
}

export default updatePassword
