import { putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* archiveFreeAccess({ types, payload }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: `${endpoints.FREE_ACCESS}/${payload.id}`,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        yield put({
            type: types.ARCHIVE_FREE_ACCESS_SUCCESS,
            payload: {
                id: payload.id
            }
        })

        yield put(
            AppModule.creators.addNotification({
                message: 'Access rule has been archived.',
                type: 'success'
            })
        )
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: types.ARCHIVE_FREE_ACCESS_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: types.ARCHIVE_FREE_ACCESS_ERROR,
                error
            })
        }

        AppModule.creators.addNotification({
            message: 'Access rule could not be archived.',
            type: 'danger'
        })
    }
}

export default archiveFreeAccess
