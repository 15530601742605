import Main from './components/Main'
import Sub from './components/Sub'
import MobileMenuButton from './components/MobileMenuButton'
import MenuProvider, { useMenu } from './context/Provider'
import MobileMainNavigationV2 from './components/MobileMain/MobileMainV2'

const context = {
    useMenu,
    MenuProvider,
}

const Component = {
    Main,
    Sub,
    MobileMenuButton,
    MobileMainNavigationV2,
    MobileMainNavigation: MobileMainNavigationV2,
}

export default {
    context,
    Component,
}
