import {
    initialState,
    reducer,
} from './slice'

import useOtp from './useOtp'
import OTP from './components/OTP'

export default {
    initialState,
    reducer,
}

export {
    useOtp,
    OTP
}
