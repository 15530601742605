import getProduct from './products/getProduct'
import getProducts from './products/getProducts'
import createProduct from './products/createProduct'
import updateProduct from './products/updateProduct'
import deleteProduct from './products/deleteProduct'
import checkEditProduct from './products/checkEditProduct'
import getProductSiblings from './products/getProductSiblings'
import getGroups from './groups/getGroups'
import createGroup from './groups/createGroup'
import updateGroup from './groups/updateGroup'
import deleteGroup from './groups/deleteGroup'

const sagas = (duck) => ({
    *getProduct(payload) {
        yield getProduct({ ...payload, ...duck })
    },
    *getProducts(payload) {
        yield getProducts({ ...payload, ...duck })
    },
    *createProduct(payload) {
        yield createProduct({ ...payload, ...duck })
    },
    *updateProduct(payload) {
        yield updateProduct({ ...payload, ...duck })
    },
    *deleteProduct(payload) {
        yield deleteProduct({ ...payload, ...duck })
    },
    *getGroups(payload) {
        yield getGroups({ ...payload, ...duck })
    },
    * createGroup(payload) {
        yield createGroup({ ...payload, ...duck })
    },
    * updateGroup(payload) {
        yield updateGroup({ ...payload, ...duck })
    },
    * deleteGroup(payload) {
        yield deleteGroup({ ...payload, ...duck })
    },
    *checkEditProduct(payload) {
        yield checkEditProduct({ ...payload, ...duck })
    },
    *getProductSiblings(payload) {
        yield getProductSiblings({ ...payload, ...duck })
    },
})

export default sagas
