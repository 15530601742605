import { put, putResolve, select, call } from 'redux-saga/effects'
import ModalModule from 'modules/modal'
import AppModule from 'modules/app'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import { TYPES } from 'utils/constants/fields'
import ErrorService from 'utils/services/ErrorService'

function* updateCustomProperties(action) {
    const { payload, duck, callback } = action
    try {
        const isNew = !payload.id
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const optionableTypes = [TYPES.DROPDOWN, TYPES.RADIO, TYPES.CHECKBOX]
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'put',
                url: endpoints.CUSTOM_PROPERTIES,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                data: {
                    id: isNew ? null : payload?.id,
                    type: payload?.type,
                    ...(optionableTypes.includes(payload.type) && {options: payload.options.map((option) => ({ label: option?.value, value: option?.value }))}),
                    label: payload?.label
                }
            }
        })

        yield put({
            type: duck.types.UPDATE_CUSTOM_PROPERTIES_SUCCESS,
            payload: {...data, isNew}
        })

        yield put(
            AppModule.creators.addNotification({
                message: `Custom property ${isNew ? 'added' : 'updated'} successfully.`,
                type: 'success'
            })
        )

        yield put(
            ModalModule.creators.closeModal({
                key: 'customProperty',
                actionFinished: true
            })
        )

        yield put(
            ModalModule.creators.closeModal({
                key: 'editCustomProperty'
            })
        )
        if(payload?.onCreate){
            yield call(payload.onCreate, data)
        }
    } catch (error) {
        const submissionError = new ErrorService(error).makeANiceSubmissionError()
        yield call(callback, submissionError)

        yield put({
            type: duck.types.UPDATE_CUSTOM_PROPERTIES_ERROR,
            error
        })
    }
}

export default updateCustomProperties
