import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import AppModule from 'modules/app'

function* archiveAccessToken({ types, payload }) {
    const { id } = payload

    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { status } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'delete',
                url: `${endpoints.KEYS}/${id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })

        yield put({
            type: types.ARCHIVE_ACCESS_TOKEN_SUCCESS,
            payload: id
        })
        yield put(
            AppModule.creators.addNotification({
                message: 'Revoked token successfully.',
                type: 'success'
            })
        )
    } catch (e) {
        yield put({
            type: types.ARCHIVE_ACCESS_TOKEN_ERROR,
            error: e
        })
    }
}

export default archiveAccessToken
