import viewPage from './viewPage'
import copyLandingPage from './copyLandingPage'
import sendEvent from './sendEvent'
import identifyUser from './identifyUser'

const sagas = (duck) => ({
    * viewPage(payload) {
        yield viewPage({ ...payload, ...duck })
    },
    * copyLandingPage(payload) {
        yield copyLandingPage({ ...payload, ...duck })
    },
    * sendEvent(payload) {
        yield sendEvent({ ...payload, ...duck })
    },
    * identifyUser(payload) {
        yield identifyUser({ ...payload, ...duck })
    }
})

export default sagas
