import { exportedStore } from 'modules/store'
import AccountModule from 'modules/account'

const defaultUseTips = true

// ------------------------------
// ------------------------------
// Contribution Bin Slugs

// ------------------------------
// Plural

// Lowercase
const tipJarPluralSlug = 'tip-jars'
const donationBoxPluralSlug = 'donation-boxes'
const contributionBinPluralSlug = () => {
    const state = exportedStore?.getState?.() || {}
    const _useTips = AccountModule.selectors.useTips(state)

    return _useTips ? tipJarPluralSlug : donationBoxPluralSlug
}

// ------------------------------
// Singular

// Lowercase
const tipJarSingularSlug = 'tip-jar'
const donationBoxSingularSlug = 'donation'
const contributionBinSingularSlug = () => {
    const state = exportedStore?.getState?.() || {}
    const _useTips = AccountModule.selectors.useTips(state)

    return _useTips ? tipJarSingularSlug : donationBoxSingularSlug
}

// ------------------------------
// ------------------------------
// Contribution Bin

// ------------------------------
// Plural

// Uppercase
const tipJarPluralUpperCase = 'Tip Jars'
const donationBoxPluralUpperCase = 'Donation Boxes'
const contributionBinPluralCapitalized = () => {
    const state = exportedStore?.getState?.() || {}
    const _useTips = AccountModule.selectors.useTips(state)

    return _useTips ? tipJarPluralUpperCase : donationBoxPluralUpperCase
}

// Lowercase
const tipJarPluralLowerCase = 'tip jars'
const donationBoxPluralLowerCase = 'donation boxes'
const contributionBinPluralLowerCase = () => {
    const state = exportedStore?.getState?.() || {}
    const _useTips = AccountModule.selectors.useTips(state)

    return _useTips ? tipJarPluralLowerCase : donationBoxPluralLowerCase
}

// ------------------------------
// Singular

// Uppercase
const tipJarSingularUpperCase = 'Tip Jar'
const donationBoxSingularUpperCase = 'Donation Box'
const contributionBinSingularUpperCase = () => {
    const state = exportedStore?.getState?.() || {}
    const _useTips = AccountModule.selectors.useTips(state)

    return _useTips ? tipJarSingularUpperCase : donationBoxSingularUpperCase
}

// Lowercase
const tipJarSingularLowerCase = 'tip jar'
const donationBoxSingularLowerCase = 'donation'
const contributionBinSingularLowerCase = () => {
    const state = exportedStore?.getState?.() || {}
    const _useTips = AccountModule.selectors.useTips(state)

    return _useTips ? tipJarSingularLowerCase : donationBoxSingularLowerCase
}


// ------------------------------
// ------------------------------
// Contribution

// ------------------------------
// Plural

// Uppercase
const tipPluralUpperCase = 'Tips'
const donationPluralUpperCase = 'Donations'
const contributionPluralCapitalized = () => {
    const state = exportedStore?.getState?.() || {}
    const _useTips = AccountModule.selectors.useTips(state)

    return _useTips ? tipPluralUpperCase : donationPluralUpperCase
}

// Lowercase
const tipPluralLowerCase = 'tips'
const donationPluralLowerCase = 'donations'
const contributionPluralLowerCase = () => {
    const state = exportedStore?.getState?.() || {}
    const _useTips = AccountModule.selectors.useTips(state)

    return _useTips ? tipPluralLowerCase : donationPluralLowerCase
}

// ------------------------------
// Singular

// Uppercase
const tipSingularUpperCase = 'Tip'
const donationSingularUpperCase = 'Donation'
const contributionSingularUpperCase = () => {
    const state = exportedStore?.getState?.() || {}
    const _useTips = AccountModule.selectors.useTips(state)

    return _useTips ? tipSingularUpperCase : donationSingularUpperCase
}

// Lowercase
const tipSingularLowerCase = 'tip'
const donationSingularLowerCase = 'donation'
const contributionSingularLowerCase = () => {
    const state = exportedStore?.getState?.() || {}
    const _useTips = AccountModule.selectors.useTips(state)

    return _useTips ? tipSingularLowerCase : donationSingularLowerCase
}

// ------------------------------

const useContributionLang = () => {
    const _contributionPluralLowerCase = contributionPluralLowerCase()
    const _contributionSingularLowerCase = contributionSingularLowerCase()

    const _contributionPluralCapitalized = contributionPluralCapitalized()
    const _contributionSingularUpperCase = contributionSingularUpperCase()

    const _contributionBinPluralCapitalized = contributionBinPluralCapitalized()
    const _contributionBinSingularUpperCase = contributionBinSingularUpperCase()

    const _contributionBinPluralLowerCase = contributionBinPluralLowerCase()
    const _contributionBinSingularLowerCase = contributionBinSingularLowerCase()

    return {
        contributionPluralLowerCase: _contributionPluralLowerCase,
        contributionSingularLowerCase: _contributionSingularLowerCase,
    
        contributionPluralCapitalized: _contributionPluralCapitalized,
        contributionSingularUpperCase: _contributionSingularUpperCase,
    
        contributionBinPluralCapitalized: _contributionBinPluralCapitalized,
        contributionBinSingularUpperCase: _contributionBinSingularUpperCase,
    
        contributionBinPluralLowerCase: _contributionBinPluralLowerCase,
        contributionBinSingularLowerCase: _contributionBinSingularLowerCase,
    }
}

export {
    contributionPluralLowerCase,
    contributionSingularLowerCase,

    contributionPluralCapitalized,
    contributionSingularUpperCase,

    contributionBinPluralCapitalized,
    contributionBinSingularUpperCase,

    contributionBinPluralLowerCase,
    contributionBinSingularLowerCase,

    contributionBinPluralSlug,
    contributionBinSingularSlug,
}
