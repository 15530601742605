import Loading from '@piconetworks/pkg-loading'

import styles from './PopupContainer.module.scss'

const PopupContainer = ({ children = null, loading = false }) => {
    return (
        <div className={styles.popupContainer}>
            {loading && <Loading />}
            {children}
        </div>
    )
}

export default PopupContainer
