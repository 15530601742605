import {
    createAsyncSaga,
    name,
    endpoints,
    logger,
} from '../dependencies'

const fetchEditionById = createAsyncSaga(
    `${name}/fetchEditionById`,
    function* ({
        payload: {
            editionId = null,
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!editionId) {
                throw new Error('editionId is required')
            }

            if (!publisherId) {
                throw new Error('publisherId is required')
            }

            const response = yield request({
                method: 'get',
                url: endpoints.GET_EDTION_BY_ID(editionId),
                headers: {
                    publisherid: publisherId,
                },
            })

            const edition = response

            if (!edition?.id) {
                throw new Error('editions could not be found')
            }

            const formattedEdition = {
                id: edition?.id,
                shopifySku: edition?.shopify_sku,
                name: edition?.name,
                type: edition?.type,
                color: edition?.color,
                engravable: edition?.engravable,
                trial: edition?.trial,
                sold: edition?.sold,
                salesChannel: edition?.sales_channel,
                packSize: edition?.pack_size,
            }

            yield fulfilled({ edition: formattedEdition })
        } catch (error) {
            logger.error('fetchEditionById error', {}, error)

            yield rejected({
                error: error.message,
            })
        }
    }
)

export default fetchEditionById
