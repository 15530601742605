import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AppModule from 'modules/app'
import AccountModule from 'modules/account'

function* getAccessTokens({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.KEYS,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })

        yield put({
            type: types.GET_ACCESS_TOKENS_SUCCESS,
            payload: data
        })
    } catch (e) {
        yield put({
            type: types.GET_ACCESS_TOKENS_ERROR,
            error: e
        })
    }
}

export default getAccessTokens
