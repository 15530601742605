import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'

import endpoints from 'utils/lib/endpoints'

function* getAccess({ selectors, types }) {
    try {
        const publisherid = yield select(selectors.getPublisherId)
        let endpoint

        // to make it seem like different publishers have different tier access
        switch (publisherid) {
            case '4bd336d6-8362-4ab3-b631-67491a4b2e05': // universal headlines local
            case 'c9298a6f-566d-4507-9def-116384264538': // national headlines staging global headlines local
                endpoint = endpoints.ACCESS.BEST
                break

            case 'fdf466e8-93c6-4dea-b1ff-8f452f08003d': // inspector gadget local
            case '8fa3947a-5fd9-48b8-bd29-cbfe279ffafc': // madonna staging
                endpoint = endpoints.ACCESS.BETTER
                break

            default:
                endpoint = endpoints.ACCESS.FREE
                break
        }

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoint,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000
            }
        })
        yield put({
            type: types.GET_ACCESS_SUCCESS,
            payload: {
                tier: data.body.access,
                updatedAt: Date.now()
            }
        })
    } catch (error) {
        yield put({
            type: types.GET_ACCESS_ERROR,
            error
        })
    }
}

export default getAccess
