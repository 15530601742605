import getAccountInfo from './getAccountInfo'
import getAccess from './getAccess'

const sagas = (duck) => ({
    * getAccountInfo(payload) {
        yield getAccountInfo({ ...payload, ...duck })
    },
    * getAccess() {
        yield getAccess({ ...duck })
    }
})

export default sagas
