import { put, putResolve } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import AppModule from 'modules/app'
import endpoints from 'utils/lib/endpoints'

function* doubleOptIn({ types, payload }) {
    try {
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.DOUBLE_OPT_IN,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                timeout: 30000,
                data: {
                    ...payload
                }
            }
        })
        yield put({
            type: types.DOUBLE_OPT_IN_SUCCESS,
            payload: {
                ...data.body
            }
        })

        yield put(
            AppModule.creators.addNotification({
                message: `Double opt-in successfully turned ${payload.doubleOptIn ? 'on' : 'off'}`,
                type: 'success'
            })
        )
    } catch (e) {
        yield put(
            AppModule.creators.addNotification({
                message: `Double opt-in couldn't turned ${payload.doubleOptIn ? 'on' : 'off'}`,
                type: 'danger'
            })
        )
        yield put({
            type: types.DOUBLE_OPT_IN_ERROR,
            error: e
        })
    }
}

export default doubleOptIn
