import { put, putResolve, select } from 'redux-saga/effects'

import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'
import ModalModule from 'modules/modal'
import AppModule from 'modules/app'

function* createNewsletters({ types, payload }) {
    // updating the store with the new newsletters
    // will reset the editor form to the default initial values
    // so we utilize the prop dontUpdateStore to prevent this
    // and onCreate which will be a form mutator function to update the form values
    const { payload: { dontUpdateStore = false, onCreate = () => {}, ...rest } = {} } = payload
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)
        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'post',
                url: endpoints.NEWSLETTERS,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                },
                timeout: 30000,
                data: {
                    ...rest
                }
            }
        })

        yield put({
            type: types.CREATE_NEWSLETTER_SUCCESS,
            payload: data,
            dontUpdateStore,
        })

        if(dontUpdateStore) {
            onCreate(data)
        }

        yield put(
            ModalModule.creators.closeModal({
                key: 'newsletterForm',
            })
        )

        yield put(
            AppModule.creators.addNotification({
                type: 'success',
                message: 'Newsletter created successfully.'
            })
        )
    } catch (e) {
        yield put(
            AppModule.creators.addNotification({
                type: 'danger',
                message: 'Something went wrong.'
            })
        )

        yield put({
            type: types.CREATE_NEWSLETTER_ERROR,
            error: e
        })
    }
}

export default createNewsletters
