import { call } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, getPublisherId } from '../dependencies'

const estimateMessage = createAsyncSaga(
    `${name}/estimateMessage`,
    function* ({
        fulfilled,
        rejected,
        request,
        payload: {
            message = null,
            type = null,
            image_url = null,
        } = {},
    }) {
        try {
            if (!message || !type) {
                throw new Error('Both message and type must be provided')
            }

            const publisherId = yield call(getPublisherId)

            const response = yield request({
                method: 'post',
                url: endpoints.GET_MESSAGE_ESTIMATE,
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    message,
                    type,
                    ...(image_url && { image_url }),
                    segment_id: 'selected'
                },
            })

            if (!response?.cost) {
                throw new Error('cost not provided')
            }

            yield fulfilled(response.cost)
        } catch (error) {
            yield rejected({
                error: error.message,
            })
        }
    }
)

export default estimateMessage
