import isEmpty from 'lodash/isEmpty'
import { tagGenerator } from '@piconetworks/tag-generator'
import { colors } from '@piconetworks/pkg-functions'
import { getThemeById } from 'features/themePicker'
import { useSelector, useDispatch } from 'react-redux'
import { setFormSerializer, setThemeSerializer } from '../slice'

const useStyles = ({
    linkColor: _linkColor,
    landingPageId,
    theme_id,
    theme_customizations,
    form_customizations,
    isPreview = true,
    isThemePreview = false
}) => {
    try {
        let linkColor = _linkColor || ''
        let customStyles = ''
        let videoTagUrl = ''

        // console.groupCollapsed('theme customizations')
        // console.log('theme_id', theme_id)
        // console.log(theme_customizations)
        // console.groupEnd()
        
        // console.groupCollapsed('form customizations')
        // console.log(form_customizations)
        // console.groupEnd()

        const theme = useSelector((state) => getThemeById(state, theme_id))

        const dispatch = useDispatch()
        let serializers = {
            theme: null,
            form: null,
        }

        // theme styles get set in here
        if (theme_id) {
            const themeStyleObject = tagGenerator({
                id: theme_id, 
                type: 'theme', 
                customizations: theme?.customizations,
                isPreview, 
                isThemePreview
            })
            customStyles += themeStyleObject?.styles
            if (themeStyleObject?.videoTagUrl) {
                videoTagUrl = themeStyleObject?.videoTagUrl
            }

            // i don't think we're using this but it's here if we need it
            dispatch(setThemeSerializer({
                serializer: themeStyleObject?.serializer,
                id: theme_id,
                status: 'success',
            }))

            serializers.theme = themeStyleObject?.serializer
        }

        // form styles get set in here
        if (!isEmpty(form_customizations)) {
            const memo = landingPageId || 'new'

            const formStyleObject = tagGenerator({
                id: memo, 
                type: 'form', 
                customizations: form_customizations || [], 
                isPreview, 
                isThemePreview
            })
            customStyles += formStyleObject.styles
            if (formStyleObject?.videoTagUrl) {
                videoTagUrl = formStyleObject?.videoTagUrl
            }

            // i don't think we're using this but it's here if we need it
            dispatch(setFormSerializer({
                serializer: formStyleObject?.serializer,
                id: memo,
                status: 'success',
            }))

            serializers.form = formStyleObject?.serializer
        }

        const hasCustomizations = theme_id || !isEmpty(form_customizations)

        const StyleContainer = hasCustomizations ? (
            <style jsx global>{customStyles}</style>
            ) : (
            <div style={{
                backgroundColor: linkColor,
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
                zIndex: 1
            }} />
        )

        const TagsToRender = videoTagUrl.length > 0 ? (
            <>
                <video autoPlay muted loop className='page' webkit-playsInline playsInline>
                    <source src={videoTagUrl} type="video/mp4" />
                </video>
                {/* override the editor preview background color and position 
                    the video within the theme preview  */}
                    <style>
                    {`#preview video {
                        position: absolute !important;
                        height: calc(100% + 3rem) !important;
                    }
                    `}
                </style>
            </>
        ) : null
        
        let contrastColor = colors.getContrastColor(linkColor)
        const { r, g, b } = colors.hexToRgb(linkColor)
        const rgbColorValue = `${r}, ${g}, ${b}`

        // console.groupCollapsed('theme serializer in useStyles')
        // console.log('theme_id', theme_id)
        // console.log(serializers.theme)
        // console.groupEnd()
        
        // console.groupCollapsed('form serializer in useStyles')
        // console.log(serializers.form)
        // console.groupEnd()

        // If page has theme or custom background color, linkColor should
        // be #000000 to render account menu buttons and links correctly.
        if (theme_id || serializers.form?.getByApiPropertyName('page-background-value').getHasBeenModified()) { 
            linkColor = '#000000' 
            contrastColor = '#FFFFFF'
        }
        

        const RootCssVariables = (<style>
            {`
                :root{
                    --block-shadow: none;
                    --block-border-style: none;
                    --block-border-color: transparent;
                    --block-border-width: 0;
                    --block-border-radius: 0.5rem;
                    --page-font-body: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
                    --page-font-heading: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
                    --page-background-color: #834EFF;
                    --block-background-color: #FFFFFF;
                    --block-contrast-color: #000000;
                    --linkColor: ${linkColor};
                    --contrastColor: ${contrastColor};
                    --rgbColorValue: ${rgbColorValue};
            `}
            </style>
        )
            

        return {
            contrastColor,
            linkColor,
            RootCssVariables,
            StyleContainer,
            TagsToRender
        }
    } catch (error) {
        console.error(error)
        return {
            TagsToRender: '',
            StyleContainer: () => <div></div>,
            linkColor: '',
            contrastColor: '',
            customStyles: '',
            RootCssVariables: '',
        }
    }
}

export default useStyles
