export default class WarningService {
    constructor(warning) {
        this.warning = warning
        this.status = warning.status
        this.data = warning.data
        this.message = warning.label
        this.date = new Date()
    }

    makeItNice() {
        return {
            message: this.message,
            moreInfo: this.data,
            date: this.date
        }
    }
}
