import React, { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types'

const MenuContext = createContext({})

const MenuProvider = ({ children }) => {
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)

    return (
        <MenuContext.Provider
            value={{
                isOpenMobileMenu,
                setIsOpenMobileMenu,
            }}
        >
            {children}
        </MenuContext.Provider>
    )
}

MenuProvider.propTypes = {
    children: PropTypes.node,
}


const useMenu = () => useContext(MenuContext)

export { MenuProvider as default, useMenu }
