import { PICO_API_URL } from '@piconetworks/pkg-endpoints'
import { useDispatch } from 'react-redux'
import qs from 'qs'
import { setTokens } from '../slice'
import { Boutton, SocialLoginButton } from '@piconetworks/ui'

const getGoogleIcon = () => (
    <span className={'icon-icon_social_google'}>
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
        <span className="path4" />
    </span>
)

export const getPopupUrl = (publisherId, redirect) => {
    const query = {
        publisher_id: publisherId,
        redirect,
        origin: process.env.HOSTNAME,
    }
    return `${PICO_API_URL()}/connect/google/gdrive_integration?${qs.stringify(query)}`
}

const GoogleDriveSyncButton = () => {
    const dispatch = useDispatch()
    return (

        <SocialLoginButton
            onLogin={(val) => dispatch(setTokens(val))}
            onFailure={(error) => console.log(error)}
            type="google"
            scope="gdrive_integration"
            button={
                <Boutton block variant="google" icon={getGoogleIcon()}>
                    Log in with Google
                </Boutton>
            }
            api_url={PICO_API_URL()}
            params={{ dashboard: true }}
        />

    )
}


export default GoogleDriveSyncButton