/** @namespace CartUI.Index */

import {
    cartUIInitialState,
    cartUIReducer,
    takes,

    showAddPaymentMethod,
    showUpdatePaymentMethod,
    showSubscribe,
    showUpgrade,
    showCancelApp,
    showKeepApp,
    hideCartUI,

    getCurrentPopup,
} from './slice'

import AddPaymentMethod from './components/popups/AddPaymentMethod'
import Cancellation from './components/popups/Cancellation'
import Keep from './components/popups/Keep'
import Subscribe from './components/popups/Subscribe'
import UpdatePaymentMethod from './components/popups/UpdatePaymentMethod'
import Upgrade from './components/popups/Upgrade'

import {
    CancelButton,
    CreditCardEntry,
    Guide,
    PaymentMethodSummary,
    PaymentSummary,
    PopupContainer,
    StatusBar,
    SubmitButton,
    Terms,
    TitleBar
} from './components/sections'

import usePopup from './hooks/usePopup'

import Example from './components/examples/Example'

export default {
    cartUIInitialState,
    cartUIReducer,
    takes,
}

export {
    Example as CartExample,

    showAddPaymentMethod,
    showUpdatePaymentMethod,
    showSubscribe,
    showUpgrade,
    showCancelApp,
    showKeepApp,
    hideCartUI,

    usePopup,

    getCurrentPopup,

    AddPaymentMethod,
    Cancellation,
    Keep,
    Subscribe,
    UpdatePaymentMethod,
    Upgrade,

    CancelButton,
    CreditCardEntry,
    Guide,
    PaymentMethodSummary,
    PaymentSummary,
    PopupContainer,
    StatusBar,
    SubmitButton,
    Terms,
    TitleBar,
}
