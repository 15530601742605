import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

import estimateMessage from './estimateMessage'

const initialState = {
    cost: null,

    inProgress: false,
    errorMessage: null,

    creditsModalVisible: false,
}

const slice = createSlice({
    name,
    initialState,
    reducers: {
        showCreditsModal(state) {
            state.creditsModalVisible = true
        },
        hideCreditsModal(state) {
            state.creditsModalVisible = false
        },
    },
    extraReducers: {
        [estimateMessage.pending]: (state) => {
            state.cost = initialState.cost

            state.inProgress = true
            state.errorMessage = initialState.errorMessage
        },
        [estimateMessage.fulfilled]: (state, { payload: cost }) => {
            state.cost = cost

            state.inProgress = false
            state.errorMessage = initialState.errorMessage
        },
        [estimateMessage.rejected]: (state, { payload: error }) => {
            state.cost = initialState.cost

            state.inProgress = false
            state.errorMessage = error.error
        },
    },
})

// SELECTORS
const getEstimateMessage = (state) => state[name]

const getEstimateMessageInProgress = createDraftSafeSelector(
    getEstimateMessage,
    (estimateMessage) => estimateMessage?.inProgress,
)

const getEstimateMessageErrorMessage = createDraftSafeSelector(
    getEstimateMessage,
    (estimateMessage) => estimateMessage?.errorMessage,
)

const getEstimateMessageCost = createDraftSafeSelector(
    getEstimateMessage,
    (estimateMessage) => estimateMessage?.cost,
)

const getCreditsModalVisible = createDraftSafeSelector(
    getEstimateMessage,
    (estimateMessage) => estimateMessage?.creditsModalVisible,
)

// GENERAL
const takes = [
    ...estimateMessage.takes,
]

const _initialState = {
    [name]: initialState,
}

const _reducer = {
    [name]: slice.reducer,
}

// EXPORTS
export default slice.reducer

const {
    showCreditsModal,
    hideCreditsModal,
} = slice.actions

export {
    takes,
    _initialState as initialState,
    _reducer as reducer,

    estimateMessage,

    showCreditsModal,
    hideCreditsModal,

    getEstimateMessage,
    getEstimateMessageInProgress,
    getEstimateMessageErrorMessage,
    getEstimateMessageCost,
    getCreditsModalVisible,
}
