import currency from 'currency.js'
import styles from '../PaymentSummary.module.scss'

const CreditSummary = ({
    taxAmount = null,
    creditAmount = null,
    appPrice,
}) => {
    const totalCost = currency(appPrice).add(taxAmount).value
    const costMinusCredits = currency(totalCost).subtract(creditAmount).value

    let remainingCredits

    if (costMinusCredits < 0) {
        remainingCredits = currency(costMinusCredits).multiply(-1).toString()
    } else {
        remainingCredits = currency(0).toString()
    }


    return (
        <div className={styles['summary-item']}>
            <div className={styles.name}>
                <span>Hype Credits</span>
                <small>${remainingCredits} will remain</small>
            </div>
            <div className={styles.price}>(${currency(creditAmount).toString()})</div>
        </div>
    )
}

export default CreditSummary
