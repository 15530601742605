import getPlans from './getPlans'
import getWhitelistablePlans from './getWhitelistablePlans'

const sagas = (duck) => ({
    *getPlans() {
        yield getPlans({ ...duck })
    },
    *getWhitelistablePlans(payload) {
        yield getWhitelistablePlans({ ...payload, duck })
    }
})

export default sagas
