import {
    initialState,
    reducer,
    takes,
} from './slice'

export default {
    initialState,
    reducer,
    takes,
}