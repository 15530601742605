// apps have tiers
// tiers have features associated to them
// until we are using the API as the source of truth for features, we are calling these 'tiers'
// apps => tiers => features

export const HYPE_PRO = 'hype-pro'
export const MULTIPLE_PAGES = 'unlimited-pages'
export const MESSAGING_PLANS = 'messages'
export const PLATFORM_ACCESS = 'platform-access'

export const HYPE_PRO_TIER = 'hype-pro-tier'
export const MULTIPLE_PAGES_TIER = 'unlimited-pages-tier'
export const HYPE_PLUGIN_TIER = 'hype-plugin-tier'
export const TIER_00 = 'tier-00' // free
export const TIER_10 = 'tier-10' // essentials
export const TIER_20 = 'tier-20' // standard
export const TIER_30 = 'tier-30' // premium
export const TIER_40 = 'tier-40' // scale

export const PLATFORM_FEE_FEATURE = 'platform-fee'
export const POPUPS_ACCESS_CODE_FEATURE = 'popups-access-code'
export const HYPE_PLUGIN_TIER_CONSOLIDATED_FEATURES = 'hype-plugin-tier-consolidated-features'
export const GADGET_ACCESS_FEATURE = 'gadget-access'
export const MESSAGING_FEATURE = 'messaging'

export const DUPLICATE_PAGES_FEATURE = 'duplicate-pages'
export const PAGES_ACCESS_CODE_FEATURE = 'pages-access-code'
export const GROUP_PAGES_FEATURE = 'group-pages'
export const EDIT_FONTS_FEATURE = 'edit-fonts'
export const EDIT_BACKGROUND_COLOR_FEATURE = 'edit-background-color'
export const EDIT_BORDER_STYLE_FEATURE = 'edit-border'
export const EDIT_BACKGROUND_IMAGE_FEATURE = 'edit-background-image'
export const EDIT_BACKGROUND_VIDEO_FEATURE = 'edit-background-video'
export const EDIT_HOVER_EFFECTS_FEATURE = 'edit-hover-effects'
export const EDIT_BIO_FEATURE = 'edit-bio'
export const EDIT_HEADER_ALIGNMENT_FEATURE = 'edit-header-alignment'
export const EDIT_BLOCK_BACKGROUND_FEATURE = 'edit-block-background'
export const EDIT_SHADOW_FEATURE = 'edit-shadow'
export const THEME_HONEYBEE_FEATURE = 'theme-honeybee'
export const THEME_SPOTLIGHT_FEATURE = 'theme-spotlight'
export const THEME_WEDNESDAY_FEATURE = 'theme-wednesday'
export const THEME_CANDY_FLOSS_FEATURE = 'theme-candy-floss'
export const THEME_MARRAKECH_FEATURE = 'theme-marrakech'
export const THEME_BAUHAUS_FEATURE = 'theme-bauhaus'
export const THEME_MATRIX_FEATURE = 'theme-matrix'
export const THEME_PRINCE_FEATURE = 'theme-prince'
export const THEME_GHOST_FEATURE = 'theme-ghost'
export const THEME_HEADLINER_LIGHT_FEATURE = 'theme-headliner-light'
export const THEME_HEADLINER_DARK_FEATURE = 'theme-headliner-dark'
export const THEME_AURA_FEATURE = 'theme-aura'
export const THEME_LINEN_FEATURE = 'theme-linen'
export const THEME_WATERMELON_FEATURE = 'theme-watermelon'

export const NFC_TAP_COUNT_FEATURE = 'nfc-tap-count'
export const NFC_URL_REDIRECT_FEATURE = 'nfc-url-redirect'
export const NFC_FORM_REDIRECT_FEATURE = 'nfc-form-redirect'
export const FORMS_FEATURE = 'forms'
export const FORM_ANALYTICS_FEATURE = 'form-analytics'
export const FORM_STYLING_FEATURE = 'form-styling'
export const FORM_EMBEDS_FEATURE = 'form-embeds'
export const FORM_VCF_FEATURE = 'form-vcf'
export const FORM_PREMIUM_FONTS_FEATURE = 'form-premium-fonts'
export const FORM_CUSTOM_PHOTO_BG_FEATURE = 'form-custom-photo-bg'
export const FORM_CUSTOM_VIDEO_BG_FEATURE = 'form-custom-video-bg'
export const USER_STATE_COUNT_FEATURE = 'user-state-count'
export const FORM_SIGNUP_FEATURE = 'form-signup'
export const USER_STATE_IMPORT_FEATURE = 'user-state-import'
export const USER_STATE_EXPORT_FEATURE = 'user-state-export'
export const USER_STATE_DATA_FEATURE = 'user-state-data'
export const USER_STATE_FILTERING_FEATURE = 'user-state-filtering'
export const USER_STATE_VCF_FEATURE = 'user-state-vcf'
export const USER_STATE_ESP_FEATURE = 'user-state-esp'
export const MESSAGE_READ_ACCESS_FEATURE = 'message-read-access'
export const MESSAGE_WRITE_BLAST_FEATURE = 'message-write-blast'
export const MESSAGE_WRITE_DM_FEATURE = 'message-write-dm'
export const MESSAGE_WRITE_SMS_FEATURE = 'message-write-sms'
export const MESSAGE_SMS_ALLOWANCE_FEATURE = 'message-sms-allowance'
export const MESSAGE_WRITE_EMAIL_FEATURE = 'message-write-email'
export const MESSAGE_ATTACHMENT_IMAGE_FEATURE = 'message-attachment-image'
export const MESSAGE_ATTACHMENT_VIDEO_FEATURE = 'message-attachment-video'
export const MESSAGE_SEGMENTS_FEATURE = 'message-segments'
export const MESSAGE_RECEIVE_SMS_FEATURE = 'message-receive-sms'
export const MESSAGE_RECEIVE_EMAIL_FEATURE = 'message-receive-email'
export const PRODUCT_TIP_JARS_FEATURE = 'product-tip-jars'
export const PRODUCT_SUBSCRIPTIONS_FEATURE = 'product-subscriptions'
export const PRODUCT_ANALYTICS_FEATURE = 'product-analytics'
export const WIDGET_FEATURE = 'widget'
export const AMBASSADOR_FEATURE = 'ambassador'
export const EMAIL_SUPPORT_FEATURE = 'email-support'
export const TEAMS_FEATURE = 'teams'