import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import { Loading, Modal } from '@piconetworks/ui'
import styles from './ActionModal.module.scss'
import Context from './Context'
import ModalModule from 'modules/modal'

const Wrapper = ({
    actionKey,
    children,
    className,
    closeAction,
    type,
    narrow,
    shouldDispatchAfterOpen,
    shouldDispatchOnClose,
    scrollable,
    size,
    preset,
    overlayClass,
}) => {
    const dispatch = useDispatch()
    const loading = useSelector(ModalModule.selectors.actionPending)
    const isOpen = useSelector((state) => ModalModule.selectors.isOpen(state, { actionKey }))

    const actionContext = (value) => {
        return {
            type: () => {
                return value.type
            },
            onClose: () => {
                dispatch(ModalModule.creators.closeModal({ key: actionKey }))
                shouldDispatchOnClose && dispatch(shouldDispatchOnClose)
                closeAction && closeAction()
            }
        }
    }

    const appElement = document.getElementById('__next')

    return (
        <Context.Provider value={actionContext({ type, actionKey })}>
            <Modal.Container
                isOpen={isOpen}
                contentLabel="ModalModules"
                appElement={appElement}
                className={cx(
                    {
                        [styles.wrapper]: preset === 'default',
                    },
                    {
                        [styles.loading]: loading
                    },
                    {
                        [styles.narrow]: narrow
                    },
                    className
                )}
                size={size}
                scrollable={scrollable}
                onAfterOpen={() => {
                    shouldDispatchAfterOpen && dispatch(shouldDispatchAfterOpen)
                }}
                onRequestClose={() => {
                    dispatch(ModalModule.creators.closeModal({ key: actionKey }))
                    shouldDispatchOnClose && dispatch(shouldDispatchOnClose)
                    closeAction && closeAction()
                }}
                overlayClass={overlayClass}
            >
                <Modal.Margin className={styles.noMargin}>
                    {loading ? <Loading /> : children}
                </Modal.Margin>
            </Modal.Container>
        </Context.Provider>
    )
}

Wrapper.propTypes = {
    actionKey: PropTypes.string.isRequired,
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    closeAction: PropTypes.func,
    dispatch: PropTypes.func,
    isOpen: PropTypes.bool,
    loading: PropTypes.bool,
    narrow: PropTypes.bool,
    preset: PropTypes.oneOf(['default', 'none']),
    scrollable: PropTypes.bool,
    shouldDispatchAfterOpen: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    shouldDispatchOnClose: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    size: PropTypes.string,
    type: PropTypes.oneOf(['info', 'danger', 'success']),
    overlayClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

Wrapper.defaultProps = {
    type: 'info',
    isOpen: false,
    scrollable: true,
    size: 'default',
    preset: 'default'
}

export default Wrapper
