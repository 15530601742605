import { call } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints, getPublisherId } from '../dependencies'

const bulkCreateGyms = createAsyncSaga(
    `${name}/bulkCreateGyms`,
    function* ({
        fulfilled,
        rejected,
        request,
        payload
    }) {
        try {
            const publisherId = yield call(getPublisherId)

            const { access_token, refresh_token, sheetId } = payload
            const { data } = yield request({
                method: 'post',
                baseURL: endpoints.API_ENDPOINT,
                url: '/sales/bulk/create',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid: publisherId,
                },
                timeout: 30000,
                data: {
                    access_token, refresh_token, sheetId

                }
            })

            yield fulfilled(data)
        } catch (error) {
            console.log(error)
            yield rejected({
                error: error.message,
            })
        }
    }
)

export default bulkCreateGyms
