import React from 'react'

const BlastTypes = [
    {
        label: 'SMS & Email', 
        value: 'any',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.7337 2.26629C21.936 2.46853 22.023 2.74236 21.9948 3.00621C21.9868 3.08176 21.9693 3.15649 21.9423 3.22849L15.5853 21.3912C15.4622 21.7431 15.1368 21.9841 14.7643 21.9993C14.3919 22.0145 14.0479 21.8008 13.8965 21.4601L10.4022 13.5978L2.53988 10.1035C2.19926 9.9521 1.98557 9.60813 2.00076 9.23569C2.01595 8.86326 2.25696 8.53783 2.60878 8.4147L20.7716 2.0577C20.8428 2.03107 20.9166 2.01366 20.9912 2.00548C21.0462 1.99941 21.1013 1.99841 21.1559 2.00234C21.3664 2.01733 21.5728 2.10532 21.7337 2.26629ZM17.6312 5.08318L5.37371 9.3733L10.8896 11.8248L17.6312 5.08318ZM12.1752 13.1104L18.9168 6.36883L14.6267 18.6263L12.1752 13.1104Z" fill="currentColor" />
            </svg>
        )
    },
    {
        label: 'SMS',
        value: 'sms',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.1163 4.55814C7.1163 3.81118 7.61492 3.39535 8.01331 3.39535H15.9867C16.3851 3.39535 16.8837 3.81118 16.8837 4.55814V19.4419C16.8837 20.1888 16.3851 20.6047 15.9867 20.6047H8.01331C7.61492 20.6047 7.1163 20.1888 7.1163 19.4419V4.55814ZM8.01331 2C6.65025 2 5.72095 3.25009 5.72095 4.55814V19.4419C5.72095 20.7499 6.65025 22 8.01331 22H15.9867C17.3498 22 18.2791 20.7499 18.2791 19.4419V4.55814C18.2791 3.25009 17.3498 2 15.9867 2H8.01331ZM12 16.8837C11.6147 16.8837 11.3023 17.1961 11.3023 17.5814C11.3023 17.9667 11.6147 18.2791 12 18.2791H12.0093C12.3946 18.2791 12.707 17.9667 12.707 17.5814C12.707 17.1961 12.3946 16.8837 12.0093 16.8837H12Z" fill="currentColor" />
            </svg>
        )
    },
    {
        label: 'Email',
        value: 'email',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M6 5C3.79086 5 2 6.79086 2 9V16C2 18.2091 3.79086 20 6 20H18C20.2091 20 22 18.2091 22 16V9C22 6.79086 20.2091 5 18 5H6ZM5.63059 8.32486C4.97708 7.79388 4 8.25894 4 9.10097V16C4 17.1046 4.89543 18 6 18H18C19.1046 18 20 17.1046 20 16V9.10097C20 8.25894 19.0229 7.79387 18.3694 8.32486L13.2612 12.4753C12.5264 13.0723 11.4736 13.0723 10.7388 12.4753L5.63059 8.32486Z" fill="currentColor" />
            </svg>
        )
    },
]

export default BlastTypes