/** @namespace Customizations.slice */

import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

const initialState = {
    theme: {
        status: null,
        id: null,
        error: null,
        serializer: null,
        lastUpdated: null,
    },
    form: {
        status: null,
        id: null,
        error: null,
        serializer: null,
        lastUpdated: null,
    },
}

const CustomizationsSlice = createSlice({
    name,
    initialState,
    reducers: {
        setThemeSerializer(state, { payload }) {
            state.theme.serializer = payload.serializer
            state.theme.id = payload.id
            state.theme.lastUpdated = Date.now()
            state.theme.status = payload.status
            state.theme.error = null
        },
        setFormSerializer(state, { payload }) {
            state.form.serializer = payload.serializer
            state.form.id = payload.id
            state.form.lastUpdated = Date.now()
            state.form.status = payload.status
            state.form.error = null
        },
        resetThemeSerializer(state) {
            state.theme = initialState.theme
        },
        resetFormSerializer(state) {
            state.form = initialState.form
        },
    },
})

const {
    setThemeSerializer,
    setFormSerializer,
    resetThemeSerializer,
    resetFormSerializer
} = CustomizationsSlice.actions

// SELECTORS
const getcustomizations = (state) => state[name]
const getThemeSerializer = createDraftSafeSelector(
    getcustomizations,
    (customizations) => customizations.theme.serializer,
)
const getFormSerializer = createDraftSafeSelector(
    getcustomizations,
    (customizations) => customizations.form.serializer,
)


const customizationsInitialState = {
    [name]: initialState,
}

const customizationsReducer = {
    [name]: CustomizationsSlice.reducer,
}

// EXPORTS
export default CustomizationsSlice.reducer

export {
    customizationsInitialState,
    customizationsReducer,
    setThemeSerializer,
    setFormSerializer,
    resetThemeSerializer,
    resetFormSerializer,
    getThemeSerializer,
    getFormSerializer
}
