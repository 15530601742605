import { putResolve, put, select } from 'redux-saga/effects'
import { REQUEST } from '@piconetworks/pkg-network-middleware'
import endpoints from 'utils/lib/endpoints'
import AccountModule from 'modules/account'

function* getFreeAccess({ types }) {
    try {
        const publisherid = yield select(AccountModule.selectors.getPublisherId)

        const { data } = yield putResolve({
            type: REQUEST,
            config: {
                method: 'get',
                url: endpoints.FREE_ACCESS,
                timeout: 30000,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    publisherid
                }
            }
        })

        yield put({
            type: types.GET_FREE_ACCESS_SUCCESS,
            payload: data
        })
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({
                type: types.GET_FREE_ACCESS_ERROR,
                error: error.response.data
            })
        } else {
            yield put({
                type: types.GET_FREE_ACCESS_ERROR,
                error
            })
        }
    }
}

export default getFreeAccess
