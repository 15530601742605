import { delay, put } from 'redux-saga/effects'
import { store } from 'react-notifications-component'

function* dismissNotification({ types, payload }) {
    const { dismissAfter, id } = payload

    yield delay(dismissAfter)

    // remove from the store that is not part of redux
    store.removeNotification(id)

    // keep redux in sync
    yield put({
        type: types.REMOVE_NOTIFICATION,
        payload: {
            id
        }
    })
}

export default dismissNotification
