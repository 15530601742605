import { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import {
    useEmail,
    saveBillingInformation,
    useDefaultSource,
    getBillingLoading,
    saveBillingInformationError,
} from '../../../dependencies'

import TitleBar from '../../sections/TitleBar'
import Guide from '../../sections/Guide'
import CreditCardEntry from '../../sections/CreditCardEntry'
import StatusBar from '../../sections/StatusBar'
import SubmitButton from '../../sections/SubmitButton'
import CancelButton from '../../sections/CancelButton'
import PopupContainer from '../../sections/PopupContainer'
import BillingError from 'components/Settings/BillingError'
import useResetBilling from 'hooks/useResetBillingError'

import logger from 'lib/logger'

const AddPaymentMethod = ({
    onCancel = (e = null) => {},
    onSuccess = (defaultSource = null) => {},
    // TODO: onError is being passed in a lot of places, but it's not being used anywhere. We need to insert it in the right place in this component.
    onError = (error?: any) => {},
}) => {
    const dispatch = useDispatch()
    const resetBillingError = useResetBilling()

    const email = useEmail()
    const loading: boolean = useSelector(getBillingLoading)

    const onCreateToken = useCallback((data) => {
        if (data?.token?.id) {
            dispatch(saveBillingInformation({
                token: data?.token?.id,
                email,
            }))
        }
        }, [email, dispatch, saveBillingInformation])

    const onStripeValidationError = useCallback(({ error }) => {
        if (error) {
            dispatch(saveBillingInformationError(error.message))
            logger.error('Stripe Validation Error', {}, error)
        }
    }, [email, dispatch, saveBillingInformationError])

    const [saveCreditCardForm, setSaveCreditCardForm] = useState(null)
    const [formFulFilled, setFormFulFilled] = useState(false)

    const onClickSubmit = useCallback(() => {
        saveCreditCardForm.current?.click()
    }, [saveCreditCardForm])

    const defaultSource = useDefaultSource()

    useEffect(() => {
        if (!!defaultSource) {
            onSuccess(defaultSource)
        }
    }, [defaultSource])

    useEffect(() => {
        resetBillingError()
    }, [])

    if (defaultSource?.id) {
        return null
    }

    return (
        <>
            <TitleBar>Add Payment Method</TitleBar>
            <PopupContainer loading={loading}>
                <Guide>
                    Adding a payment method is required to subscribe to an app.
                </Guide>
                <CreditCardEntry
                    onCreateToken={onCreateToken}
                    setSaveCreditCardForm={setSaveCreditCardForm}
                    setFormFulFilled={setFormFulFilled}
                    onStripeValidationError={onStripeValidationError}
                />
                <StatusBar>
                    <BillingError />
                </StatusBar>
                <SubmitButton onClick={onClickSubmit} disabled={!!formFulFilled}>Add payment method</SubmitButton>
                <CancelButton onClick={onCancel} />
            </PopupContainer>
        </>
    )
}

export default AddPaymentMethod
